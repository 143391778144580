const debug = require("debug")("atman.components.cart"); // eslint-disable-line

import sampleCart from "./sample_cart";

export default {
  label: "Cart",
  type: "cart",
  description: "Displays a Cart field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],

  templates: [
    {
      id: "default",
      description: "The default Cart Field",
      label: "Default",
      value: {
        apis: {
          submit: {
            data: "default",
            params: {
              action: "update_fields",
            },
            type: "post",
            url: "{_context->path}",
          },
        },
        name: "default",
        format: "detailed",
        label: "Cart",
        type: "cart",

        value: sampleCart,
      },
    },
    {
      id: "mini_cart",
      description: "Mini display format for cart",
      label: "Mini Cart",
      value: {
        apis: {
          submit: {
            data: "default",
            params: {
              action: "update_fields",
            },
            type: "post",
            url: "{_context->path}",
          },
        },
        name: "mini_cart",
        format: "mini",
        label: "Cart",
        type: "cart",
        value: sampleCart,
      },
    },
  ],
};
