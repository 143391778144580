<template>
  <v-footer aut-footer padless v-if="displayFooter" class="transparent">
    <v-card flat tile width="100%" class="lighten-1 text-center transparent">
      <v-card-text>
        <div
          aut-footer-content
          v-if="displayInsecureContent"
          v-html="footerInsecureContent"
        ></div>
        <div
          aut-footer-secure-content
          v-if="displaySecureContent"
          v-html="footerSecureContent"
        ></div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { isUnAuthenticated } from "@/util";
const debug = require("debug")("atman.components.footer");
export default {
  name: "Footer",
  computed: {
    ...mapState(["skin", "preferences"]),
    displayFooter() {
      return this?.skin.footer?.display;
    },
    footerInsecureContent() {
      return this?.skin?.footer?.text?.content;
    },
    footerSecureContent() {
      return this?.skin?.footer?.text?.secure_content;
    },
    displayInsecureContent() {
      const result = this.footerInsecureContent && !this.displaySecureContent;
      debug(`displayInsecureContent`, result);
      return result;
    },
    displaySecureContent() {
      const result = !isUnAuthenticated(this) && !!this.footerSecureContent;
      debug(`displaySecureContent`, result);
      return result;
    },
  },
  mounted: function () {
    debug("In mounted");
    this.fetchSkin();
  },
  methods: {
    ...mapActions(["fetchSkin"]),
  },
};
</script>
