<template>
  <iframe
    v-if="isDisplayMode"
    :src="fieldValue"
    :frameborder="frameborder"
    :height="height"
    :width="width"
  ></iframe>

  <div v-else>
    <AddIframe v-if="dialog" @close="dialog = false" @update="updateValue" />
    <FieldLabel
      v-if="!fieldValue"
      :definition="value"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
    </FieldLabel>

    <div class="behavior_iframe_container" v-if="fieldValue">
      <iframe
        :src="fieldValue"
        :frameborder="frameborder"
        :height="height"
        :width="width"
      ></iframe>

      <v-btn
        v-if="!isDisabled && fieldValue"
        class="behavior_change_iframe"
        aut-change-iframe
        @click.stop="dialog = true"
        icon
        ><v-icon>mdi-upload</v-icon></v-btn
      >
    </div>

    <div v-else>
      <v-tooltip top :label="label" :attributes="labelAttributes">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            aut-add-iframe
            text
            outlined
            :color="displayAttributes.add_iframe_button_color"
            @click.stop="dialog = true"
            v-bind="Object.assign(attrs, buttonAttributes)"
            v-on="on"
          >
            <v-icon
              :class="iconClasses"
              class="behavior_action_icon"
              :aut-action-icon="value.icon"
              >{{ value.icon || "mdi-file-upload" }}</v-icon
            >
            {{ addIFrameLabel }}
          </v-btn>
        </template>
        {{ addIFrameLabel }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import AddIframe from "./AddIframe.vue";
import definition from "./definition";

const debug = require("debug")("atman.components.iframe_field"); //eslint-disable-line

export default {
  name: "IframeField",
  mixins: [fieldMixin],
  props: {},
  components: {
    AddIframe,
  },
  data() {
    return {
      dialog: false,
      definition,
    };
  },
  computed: {
    buttonAttributes() {
      let attributes = {};
      if (this.isSmallScreen) {
        attributes.block = true;
      }
      return attributes;
    },
    addIFrameLabel() {
      let result = "Add";

      result = `${result} ${this.label}`;
      return result;
    },
    width() {
      const result = this?.displayAttributes?.width;
      debug(`width`, result);
      return result;
    },
    height() {
      const result = this?.displayAttributes?.height;
      debug(`height`, result);
      return result;
    },
    frameborder() {
      const result = this?.displayAttributes?.frameborder;
      debug(`frameborder`, result);
      return result;
    },
  },
  methods: {
    updateValue(value) {
      this.$set(this, "fieldValue", value);
      debug(`updated value`, this.fieldValue);
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_iframe_container {
  position: relative;

  &:hover {
    .behavior_change_iframe {
      display: initial;
    }
  }

  .behavior_change_iframe {
    display: none;
    position: absolute;
    color: var(--v-primary-base);
    bottom: 0px;
    left: 0px;
    background-color: var(--v-primary-lighten5);
  }
}
</style>
