<template>
  <div v-if="shouldDisplay" class="d-flex">
    <v-menu
      offset-y
      :nudge-left="14"
      :z-index="1000"
      v-for="(menuItem, mIndex) in effectiveMenus"
      :key="mIndex"
      content-class="header_menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom :activator="`#menu${mIndex}`">
          <span>{{ menuItem.title }}</span>
        </v-tooltip>
        <!-- There is a bug here. Any menu that has a child cannot be clicked on -->
        <v-btn
          :id="`menu${mIndex}`"
          class="mx-2"
          v-bind="attrs"
          v-on="menuItem.child && on"
          exact
          icon
          :to="!menuItem.child ? menuItem.href : ''"
          :color="menuItem.color || menuColor"
          :aut-header-menu="menuItem.title"
        >
          <v-icon dark>{{ menuItem.icon }}</v-icon>
        </v-btn>
      </template>
      <v-list dense max-width="200" aut-header-menu-list>
        <v-list-item v-for="(item, index) in menuItem.child" :key="index" dense>
          <v-btn
            plain
            block
            :aut-header-menu-item="item.title"
            :to="item.href"
            class="text-left menu_button"
          >
            <v-icon left v-if="item.icon">{{ item.icon }}</v-icon>
            <v-list-item-title class="behavior_overflow">
              {{ item.title }}
            </v-list-item-title>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "HeaderMenuOptions",
  async mounted() {
    if (this.shouldDisplay) {
      this.fetchMenu();
    }
  },
  computed: {
    ...mapGetters(["isClassicLayout", "menu"]),
    ...mapState(["skin"]),
    effectiveMenus() {
      const menu = this.menu || [];
      const menuCount = this.isLargeScreen ? 3 : 0;
      if (menu.length <= menuCount) {
        return menu;
      }
      const firstThree = menu.slice(0, menuCount);
      const remaining = menu.slice(menuCount);
      return [
        ...firstThree,
        {
          title: "More",
          child: remaining,
          icon: "mdi-dots-vertical",
        },
      ];
    },
    shouldDisplay() {
      return this.isFeatureEnabled("header.menu.display");
    },
    menuColor() {
      return this.getFeatureValue("header.menu.color") || "";
    },
  },
  methods: {
    ...mapActions(["fetchMenu"]),
  },
};
</script>
<style lang="scss" scoped>
.header_menu .menu_button {
  max-width: 100%;
  height: inherit;
  :first-child {
    max-width: 100%;
    .behavior_overflow {
      width: 100%;
    }
  }
}
</style>
