import application_home_form from "./forms/application_home_form";
import application_update_form from "./forms/application_update";

const menuCards = {
  title: "",
  type: "list",
  display: {
    attributes: {
      "disable-filtering": true,
    },
  },
  definition: {
    mode: "cards",
    fields: [
      {
        name: "menu",
        type: "application_menu",
        click_action: {
          type: "navigation",
          url: "{_data->[i].href}",
        },
      },
    ],
    actions: [],
  },
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
      field: "menu.child",
    },
  },
};

const forms = [
  {
    id: "application_update_form",
    label: "Application Update Form",
    value: Object.assign({ type: "form" }, application_update_form),
  },
  {
    id: "application_home_form",
    label: "Application Home Form",
    value: application_home_form,
  },
];

const pages = [
  {
    id: "home",
    label: "Application Home Page",
    value: {
      type: "page",
      id: "new_application",
      content: [application_home_form, menuCards],
    },
  },
  {
    id: "application_update_page",
    label: "Application: Edit/Update",
    value: {
      type: "page",
      id: "update",
      title: "Update",
      subtitle: "",
      content: [application_update_form],
    },
  },
];

export { forms, pages };
