export default {
  id: "developer_home_page",
  title: "Developer",
  classes: "behavior-no-fav behavior-no-pin behavior-no-editor",
  subtitle: "",
  content: [
    {
      display: {
        attributes: {
          "disable-filtering": true,
        },
      },
      definition: {
        actions: [],
        fields: [
          {
            click_action: {
              type: "navigation",
              url: "{_data->[i].href}",
            },
            name: "menu",
            type: "application_menu",
          },
        ],
        mode: "cards",
      },
      data: [
        {
          title: "Tutorial",
          href: "/developer/tutorial_page",
        },
        {
          href: "/developer/workflow_editor_page",
          title: "Workfow Editor Example",
        },
        {
          href: "/developer/page_editor_page",
          title: "Page Editor Example",
        },
        {
          href: "/developer/feature_demos?page=home",
          title: "Feature Demos",
        },
        {
          href: "/feature_flags",
          title: "Feature Flags",
        },
      ],
      title: "Developer",
      type: "list",
    },
  ],
};
