<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-add-page-dialog>
      <DialogTitle @close="$emit('close')" title="Add a new Page" />
      <v-card-subtitle>
        You can create a page here directly or for a child entity
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Current Path"
                v-model="currentPath"
                hint="This is the current context/path you are on. "
                :disabled="pathModifiable"
                persistent-hint
                :rules="pathRules"
                aut-field-parent-context
              >
              </v-text-field>
            </v-col>
            <v-col lg="12" cols="12" aut-entity-field>
              <v-text-field
                :label="contextLabel"
                v-model="context"
                aut-field-context
                :rules="contextRules"
                hint="If required, add the entity for which you are creating a page (ex: customers/employees)"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column flex-md-row"
              v-if="publicPagesFeature"
            >
              <span class="mt-md-2 mr-2">Private</span>
              <v-switch
                dense
                aut-switch-public
                v-model="isPublic"
                hint="Is a public page"
                persistent-hint
                @change="togglePublic"
                class="mt-0"
              ></v-switch>
              <span class="mt-md-2">Public</span>
            </v-col>
            <v-col cols="12" lg="5">
              <v-autocomplete
                :disabled="templatePageToggle || isUser"
                aut-field-page-template
                hide-selected
                clearable
                item-text="label"
                item-value="id"
                :items="pages"
                hint="Choose an existing template to create a page from"
                persistent-hint
                v-model="template"
                label="Template"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="2" class="d-flex">
              <v-spacer />
              <div>
                <p class="mb-0 pb-0 ml-2">OR</p>
                <v-switch
                  dense
                  :disabled="isUser"
                  aut-page-toggle
                  v-model="templatePageToggle"
                  class="mt-0 pt-0"
                ></v-switch>
              </div>
              <v-spacer />
            </v-col>
            <v-col cols="12" lg="5">
              <v-text-field
                :disabled="!templatePageToggle"
                aut-field-page
                clearable
                hint="Create your own custom page from scratch"
                persistent-hint
                v-model="page"
                :rules="pageRules"
                label="Page"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mt-3">
              <label>Effective URL: {{ path }} </label>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-add-page-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isButtonDisabled"
          @click="process"
          aut-action-add-page
        >
          {{ buttonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { fetchTemplates, navigateTo, rules } from "@/util.js";
import { PAGES } from "@/constants.js";

const debug = require("debug")("atman.components.add_page_dialog");
debug("add_page_dialog");

export default {
  name: "AddPageDialog",
  mixins: [dialogMixin],
  data() {
    return {
      isPublic: false,
      search: null,
      pages: [],
      page: "",
      pathModifiable: !this.getFeatureValue(
        "editor.page_creation.allow_path_modification"
      ),
      templatePageToggle: false,
      display: true,
      toggleValue: false,
      lookupKey: 1,
      template: "",
      context: "",
      currentPath: "",
      pageRules: [rules.mandatory(), rules.entity_name()],
      pathRules: [rules.mandatory(), rules.entity_path()],
      isUser: false,
      templateSelectionDisabled: false,
    };
  },
  props: {
    contextObj: {
      type: Object,
    },
  },
  components: {},
  watch: {
    isUser() {
      if (this.isUser) {
        this.templatePageToggle = false;
        this.template = PAGES.CREATE_USER;
      }
    },
  },
  computed: {
    contextRules() {
      let result = [rules.entity_name()];
      // Context is mandatory if the user is currently in the root of an application
      if (
        this.getFeatureValue("editor.page_creation.entity_mandatory") === false
      ) {
        return result;
      }
      if (this.contextObj.application_path == this.contextObj.path) {
        result = [...result, rules.mandatory()];
      }
      return result;
    },
    contextLabel() {
      let result = "Entity";
      // Context is mandatory if the user is currently in the root of an application
      if (
        this.getFeatureValue("editor.page_creation.entity_mandatory") === false
      ) {
        return result;
      }
      if (this.contextObj.application_path == this.contextObj.path) {
        result += ` *`;
      }
      return result;
    },
    publicPagesFeature() {
      return this.isFeatureEnabled("public_pages");
    },
    path() {
      let path = `${this.currentPath}${
        this.context ? "/" + this.context : ""
      }?page=`;
      if (this.templatePageToggle) {
        path = `${path}${this.page}`;
      } else if (this.template) {
        path = `${path}${this.template}`;
      }
      return path;
    },

    isButtonDisabled() {
      if (!this.currentPath) {
        return true;
      }
      return this.templatePageToggle ? !this.page : !this.template;
    },
    buttonLabel() {
      return "Add Page";
    },
  },
  async created() {
    this.pages = await fetchTemplates("page");
  },
  mounted() {
    this.currentPath = this.contextObj.path;
  },
  methods: {
    togglePublic() {
      if (this.isPublic) {
        this.currentPath = `/public${this.context}`;
      } else {
        this.currentPath = this.contextObj.path;
      }
    },
    process() {
      this.addPage();
    },
    addPage() {
      const path = encodeURIComponent(this.path);
      const params = [`page=${path}`, `operation=create`];
      const paramsAsString = params.join("&");
      navigateTo(this.$router, `/editor_page?${paramsAsString}`); // eslint-disable-line
    },
  },
};
</script>
