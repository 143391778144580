<template>
  <v-row dense>
    <v-col cols="12">
      <v-autocomplete
        aut-field-tutorial-type
        required
        dense
        :items="fieldTypes"
        label="Type"
        item-value="id"
        item-text="label"
        v-model="fieldType"
        @change="changeFieldType"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12">
      <FieldDefinition
        v-if="fieldType"
        aut-field-definition
        :type="fieldType"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  getFieldDefinitions,
  FIELDS_FILTER_TUTORIAL,
} from "@/components/fields/util.js";
import { fieldMixin } from "@/components/mixin.js";
import FieldDefinition from "@/components/fields/Field/FieldDefinition";
const debug = require("debug")("atman.components.field_tutorial");
debug("field_tutorial");
export default {
  name: "FieldTutorial",
  mixins: [fieldMixin],
  data() {
    /* Don't show hidden fields in production tutorials */
    const fieldTypes =
      process.env.NODE_ENV != "development"
        ? getFieldDefinitions(FIELDS_FILTER_TUTORIAL)
        : getFieldDefinitions();
    return {
      fieldTypes,
      fieldType: null,
    };
  },
  components: {
    FieldDefinition,
  },
  mounted() {
    this.fieldType = this?.$route?.query?.fieldType || this?.fieldTypes[0]?.id;
  },
  methods: {
    changeFieldType() {
      debug(`in changeFieldType`);
      this.$router.push({
        path: this.$route.path,
        query: {
          fieldType: this.fieldType,
        },
      });
    },
  },
};
</script>
