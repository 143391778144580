const eventTypes = [
  {
    id: "display_question",
    label: "Display Question",
  },
  {
    id: "trigger_event",
    label: "Trigger Event",
  },
  {
    id: "assign",
    label: "Assign Value",
  },
  {
    id: "display_summary",
    label: "Display Summary ",
  },
  {
    id: "display_message",
    label: "Display Message ",
  },
];

const getEventDescription = (event) => {
  let result = "This event will";
  let temp;
  switch (event.type) {
    case "trigger_event":
      temp = `inturn trigger the following event(s) [${event.params.id}]`;
      break;
    case "assign":
      temp = `assign the value ${JSON.stringify(
        event.params.value
      )} to the variable ${event.params.target}`;
      break;
    case "display_question":
      temp = `display a question`;
      break;
    case "display_summary":
      temp = `first process all the configured rules and then display the summary`;
      break;
    case "display_message":
      temp = `displays the configured message`;
      break;
    default:
  }
  result = `${result} ${temp}.`;
  if (event.type == "trigger_event") {
    result = `${result} Subsequent events (if available), will be ${
      event?.params?._is_exclusive ? "aborted" : "triggered"
    }.`;
  }
  return result;
};

export { eventTypes, getEventDescription };
