<template>
  <!-- The If condition is important - otherwise all the notifications get marked as read -->
  <v-dialog
    v-if="notifications.display"
    v-model="notifications.display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-notifications-dialog>
      <DialogTitle @close="$emit('close')" title="Notifications" />
      <v-card-text class="timeline">
        <v-timeline dense>
          <v-slide-x-reverse-transition group hide-on-leave>
            <!-- :color="item.color" -->
            <v-timeline-item
              v-for="item in notifications.content"
              :key="item.id"
              small
              fill-dot
            >
              <!-- :color="item.color"
                :icon="item.icon"
                 -->
              <v-row class="pt-1" v-intersect.quiet="markRead(item)">
                <v-col cols="12" lg="3">
                  {{ formattedDate(item.timestamp) }}
                </v-col>
                <v-col>
                  <v-alert :type="item.type">
                    {{ item.notification }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <!-- <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-edit-form-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="updateDefinition"
          aut-action-edit-content-update
        >
          OK
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { isUnAuthenticated } from "@/util";
import { mapState, mapMutations, mapActions } from "vuex";
import { format, parseISO } from "date-fns";

const debug = require("debug")("atman.components.notifications"); // eslint-disable-line

export default {
  name: "Notifications",
  mixins: [dialogMixin],
  computed: {
    ...mapState(["notifications"]),
    display: {
      get() {
        return this.notifications.display;
      },
      set() {
        this.toggleNotificationDisplay();
      },
    },
  },
  watch: {
    $route() {
      const component = this;
      component.setupNotifications();
    },
  },
  async mounted() {
    const component = this;
    await this.fetchSkin();
    debug(`Mounted of Notifications`);
    component.setupNotifications();
  },
  methods: {
    ...mapMutations([
      "markNotificationRead",
      "markNotificationsRead",
      "toggleNotificationDisplay",
    ]),
    ...mapActions(["fetchNotifications", "fetchSkin"]),
    formattedDate(dateValue) {
      return format(parseISO(dateValue), "yyyy-MM-dd HH:mm:ss z");
    },
    setupNotifications() {
      const component = this;
      const functionalityIsEnabled = this.isFeatureEnabled(
        "notifications.enabled",
        false
      );
      if (!functionalityIsEnabled) {
        debug(`Notifications functionality is not enabled. Aborting`);
        return;
      }
      const isAuthenticated = !isUnAuthenticated(component);
      component.intervals = component.intervals || [];
      const intervals = component.intervals;
      if (!isAuthenticated) {
        debug(`Unauthenticated. Removing all notification intervals`);
        if (intervals.length) {
          (intervals || []).forEach((interval) => {
            debug(`Clearing interval`);
            clearInterval(interval);
          });
        }
        return;
      }

      if (component.intervals.length == 0) {
        debug(`Registering interval for fetching notifications`);
        component.intervals.push(
          setInterval(() => {
            debug(`Triggering fetchInterval`);
            component.fetchNotifications();
          }, this.getFeatureValue("notifications.interval") || 10000)
        );
      }
    },
    markRead(input) {
      const item = input;
      /* Next tick being used to avoid the warning: 
       "You may have an infinite update loop in a component render
      function." */
      this.$nextTick(() => {
        this.markNotificationRead(item);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  max-height: 400px;
  overflow-y: scroll;
}
</style>
