import { customiseAttribute, color } from "@/components/fields/attributes.js";

export default {
  label: "ApplicationMenuField",
  description: "Used to display an application menu",
  type: "application_menu",
  filters: ["form_editor", "list_editor", "field_tutorial"],
  templates: [
    {
      id: "default",
      description: "The default Application Menu Field",
      label: "Default",
      variables: {
        data: {
          default: {
            href: "{_context->path}/documents?page=list",
            icon: "mdi-cart-arrow-down",
            title: "Courses",
          },
        },
      },
      value: {
        name: "default",
        label: "Title",
        type: "application_menu",
      },
    },
  ],
  properties: ["name", "type", "display"],
  attributes: [
    customiseAttribute("elevation", { value: 6 }),
    {
      name: "hover_elevation",
      type: "number",
      hint: "Control the Hover elevation",
      value: 18,
      min: 0,
      max: 24,
    },
    customiseAttribute("min_height", { value: "150px" }),
    customiseAttribute("min_width", { value: "100px" }),
    color,
    { name: "hovercolor", type: "color", value: "primary" },
    {
      name: "large-screen-width",
      type: "text",
      value: "200px",
    },
    {
      name: "mobile-screen-width",
      type: "text",
      value: "30vw",
    },
    { name: "title-color", type: "color", value: "white" },
    { name: "icon-color", type: "color", value: "white" },
    {
      name: "hover_effect",
      type: "switch",
      hint: "Card Hover Effect",
      value: false,
    },
  ],
  classes: ["ma-3", "d-inline-flex", "flex-column", "justify-center"],
};
