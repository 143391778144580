<template>
  <v-col cols="12" lg="5" class="d-flex" aut-key-value-pair-value>
    <span class="pt-2 mx-2">:</span>
    <Field :definition="definition" :context="context" v-on="$listeners" />
  </v-col>
</template>
<script>
export default {
  name: "ValueField",
  props: {
    definition: {},
    context: {
      type: String,
    },
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
};
</script>
