<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-select-template-dialog>
      <DialogTitle @close="$emit('close')" title="Choose a Content Template" />
      <v-card-text>
        <atman-tabs v-model="tabModel" @change="tabModel = $event">
          <v-tab aut-tab-forms>Forms</v-tab>
          <v-tab aut-tab-lists>Lists</v-tab>
        </atman-tabs>
        <atman-tabs-items v-model="tabModel">
          <v-tab-item aut-tab-item-form>
            <ContentTemplates type="form" @select="selectTemplate" />
          </v-tab-item>
          <v-tab-item aut-tab-item-list>
            <ContentTemplates type="list" @select="selectTemplate" />
          </v-tab-item>
        </atman-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import ContentTemplates from "./ContentTemplates.vue";
const debug = require("debug")("atman.components.choose template dialog");
debug(`choose template dialog`);
export default {
  name: "ChooseTemplateDialog",
  components: {
    ContentTemplates,
  },
  mixins: [dialogMixin],
  data() {
    return {
      tabModel: this.type == "list" ? 1 : 0,
      display: true,
    };
  },
  props: {
    type: {
      type: String,
    },
  },
  created() {
    debug(`In created of ChooseTemplateDialog`);
  },
  methods: {
    selectTemplate(template) {
      this.$emit("select", template);
      this.$emit("close");
    },
  },
};
</script>
