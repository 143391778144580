<template>
  <v-row dense>
    <v-col cols="12" class="smaller mb-0 pb-0">
      <v-text-field
        outlined
        hide-details
        dense
        prepend-icon="mdi-magnify"
        aut-role-search
        placeholder="Search"
        clearable
        v-model="searchValue"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-simple-table fixed-header height="400px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value {{ list ? "(Example)" : "" }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, j) in filteredVariables" :key="j">
              <td>
                <copiable-text :content="item.name"> </copiable-text>
              </td>
              <td>
                <copiable-text :content="item.value" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "VariablesList",
  components: {
    CopiableText: () =>
      import("@/components/editor/CopiableText/CopiableText.vue"),
  },
  data() {
    return {
      searchValue: null,
    };
  },
  props: {
    variables: {
      type: Array,
    },
    list: {
      type: Boolean,
    },
  },
  computed: {
    filteredVariables() {
      let result = [...this.variables];
      if (this.searchValue) {
        // TODO lowercase
        result = result.filter(
          (variable) =>
            JSON.stringify(variable)
              .toLowerCase()
              .indexOf(this.searchValue.toLowerCase()) != -1
        );
      }
      return result;
    },
  },
};
</script>
