import ClientSidePage from "@/views/ClientSidePage.vue";

export default [
  {
    path: "/roles",
    name: "Roles",
    component: ClientSidePage,
    meta: { unauthenticated: false, page_key: "role_list" },
  },
];
