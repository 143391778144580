<template>
  <div>
    <v-slider
      aut-slider
      v-if="!isDisplayMode"
      v-model="fieldValue"
      class="align-center"
      :label="label"
      :max="displayAttributes.max || 0"
      :min="displayAttributes.min || 0"
      hide-details
      dense
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
    >
      <template v-slot:append>
        <v-text-field
          v-if="displayValue"
          aut-slider-text
          v-model="fieldValue"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 60px"
        ></v-text-field>
      </template>
    </v-slider>
    <div v-if="isDisplayMode">
      <label v-if="label" class="text-left"> {{ label }}: </label>
      <span class="text-left" aut-slider-value>{{ fieldValue }}</span>
    </div>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.slider");
debug("slider");
export default {
  name: "Slider",
  mixins: [fieldMixin],
  computed: {
    displayValue() {
      return this.displayAttributes?.behaviour_display_value == true;
    },
  },
  data() {
    return {
      definition,
    };
  },
};
</script>
