<template>
  <v-container class="pa-0">
    <div v-if="variant">
      <div v-for="(menu, index) in menus" :key="index">
        <v-list-item
          v-if="menu || menu.child"
          :to="getMenuTarget(menu)"
          active-class="active-item"
          class="menu-item px-0"
          :aut-menu="menu.title"
          exact
          @click="clickAction(menu, $event)"
        >
          <div class="">
            <v-list-item-icon v-if="menu.icon" class="behavior_full_width mb-0">
              <v-icon class="menu-icon behavior_centered" aut-icon medium>
                {{ menu.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="behavior_full_width menu-title mt-0 px-0 text-sidebar text-wrap"
                aut-title
              >
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
      </div>
    </div>
    <div v-else>
      <div v-for="(menu, index) in menus" :key="index">
        <v-list-item
          v-if="menu && !menu.child"
          :to="getMenuTarget(menu)"
          active-class="active-item"
          class="menu-item"
          :aut-menu="menu.title"
          exact
          @click="clickAction(menu, $event)"
        >
          <v-list-item-icon v-if="menu.icon" class="behavior-icon-box mr-2">
            <v-icon class="menu-icon" aut-icon small>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="`menu-title  ${menu.disabled ? 'text--disabled' : ''}`"
              aut-title
            >
              {{ menu.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          no-action
          v-if="menu && menu.child"
          @click="navigateTo(menu)"
          active-class="active-group"
          class="menu-group"
          :aut-group-menu="menu.title"
        >
          <template v-slot:activator>
            <v-list-item-icon v-if="menu.icon" class="behavior-icon-box mr-2">
              <v-icon class="menu-icon" aut-icon small>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="py-0 my-0">
              <v-list-item-title
                :class="`menu-title  ${menu.disabled ? 'text--disabled' : ''}`"
                aut-title
              >
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <MenuOptions :menus="menu.child" :disabled="disabled" />
        </v-list-group>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "MenuOptions",
  props: {
    menus: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
    variant: {
      type: Boolean,
    },
  },
  methods: {
    getMenuTarget(menu) {
      if (!menu.href || menu.disabled) {
        return;
      }
      return menu.href;
    },
    navigateTo(menu) {
      if (this.disabled || menu.disabled) {
        return;
      }
      const href = menu.href;
      const currentPath = this.$route.path;
      if (href && href != currentPath) {
        this.$router.push(href);
      }
    },
    clickAction(menu, event) {
      if (menu.disabled) {
        return;
      }
      const eventName = menu.click;
      if (typeof eventName == "string") {
        this.$emit(`click:${eventName}`, event);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_full_width {
  &.menu-title {
    text-align: center;
  }
}
.text-sidebar {
  font-size: 0.65rem;
}
.menu-item {
  .menu-title,
  .menu-icon {
    color: var(--v-sidebar-text-base);
  }

  &.active-item {
    // background-color: var(--v-primary-base) !important;
    .menu-title,
    .menu-icon {
      color: var(
        --v-sidebar-active-text-base,
        var(--v-primary-base)
      ) !important;
    }
  }
}

.menu-group {
  .menu-title,
  .menu-icon,
  ::v-deep .v-list-group__header__append-icon .v-icon {
    color: var(--v-sidebar-text-base);
  }

  ::v-deep .active-group {
    .menu-title,
    .menu-icon,
    .v-list-group__header__append-icon .v-icon {
      color: var(--v-sidebar-active-group-base, var(--v-primary-base));
    }
  }
}
::v-deep .v-list-item {
  min-height: 20px;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
</style>
