import EditReport from "./EditReport.vue";
import CreateReport from "./CreateReport.vue";

const routes = [
  {
    path: "/edit_report/:id",
    name: "Edit Report",
    component: EditReport,
    meta: { unauthenticated: false },
  },
  {
    path: "/create_report/:id",
    name: "Create Report",
    component: CreateReport,
    meta: { unauthenticated: false },
  },
];

export default routes;
