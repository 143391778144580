import { defaultsDeep } from "lodash";
import AddressInput from "./AddressInput.vue";
const debug = require('debug')('address_mixin')  // eslint-disable-line

export default {
  components: {
    AddressInput,
  },
  computed: {
    derivedProperties() {
      return defaultsDeep({}, this.fieldProperties || {}, this.defaults || {});
    },
    label() {
      return this.derivedProperties.label;
    },
    isMandatory() {
      return this.derivedProperties.mandatory;
    },
    isTextType() {
      return this.derivedProperties?.type == "text";
    },
    isHidden() {
      if (!this.definition?.address_fields) {
        return false;
      }
      debug(`this.fieldProperties`, this.fieldProperties);
      return this.derivedProperties?.mode == "hidden";
    },
    width() {
      return this.derivedProperties?.display?.width || "12";
    },
    countryHasId() {
      return (this.value?.country?.length || 3) < 3;
    },
  },
  methods: {
    _getValue(key) {
      const result =
        this.value?.[key] ||
        this.derivedProperties?.value ||
        this.definition?.value?.[key];
      return result;
    },
  },
};
