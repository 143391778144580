<template>
  <DatePicker
    v-if="propsData"
    v-bind="propsData"
    :key="renderKey"
    v-on="$listeners"
    @change="updateValue"
  />
</template>
<script>
import { defaultsDeep } from "lodash";
import { isBefore, subDays, addDays } from "date-fns";
import { clone } from "@/util";

import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.due_date_picker"); // eslint-disable-line

const getColor = (inputDate, limit) => {
  if (!inputDate) {
    return "";
  }
  let dueDate;
  try {
    dueDate = new Date(inputDate);
  } catch (e) {
    console.error(`Not a valid date`);
    return "error";
  }

  const today = new Date();

  if (isBefore(dueDate, today)) {
    return "error";
  }
  const threshold = subDays(dueDate, limit);
  if (isBefore(threshold, today)) {
    return "warning";
  }
  return "success";
};

export default {
  name: "DueDatePicker",
  components: {
    DatePicker: () => import("@/components/fields/DatePicker/DatePicker"),
  },
  mixins: [fieldMixin],
  methods: {
    _derivePropsData() {
      const propsData = clone(this.$props);
      let limit = 2;
      const limitInConfig = this?.value?.near_limit || "2";
      if (!isNaN(limitInConfig * 1)) {
        limit = limitInConfig;
      }
      const originalDisplayAttributes =
        this?.$props?.value?.display?.attributes || {};

      let newDisplayAttributes = {};
      let shouldShowOverdue = this._getDynamicValue(
        this.value,
        "show_overdue",
        true
      );
      if (typeof shouldShowOverdue == "undefined") {
        shouldShowOverdue = true;
      }
      if (shouldShowOverdue) {
        newDisplayAttributes = {
          color: getColor(this.fieldValue, limit),
          field: {
            color: getColor(this.fieldValue, limit),
          },
        };
      }
      propsData.value.display.attributes = defaultsDeep(
        {},
        newDisplayAttributes,
        originalDisplayAttributes,
        propsData.value.display.attributes
      );
      let value = this.fieldValue;
      const noValueIncrement = this.displayAttributes?.no_value_increment;
      if (!value && noValueIncrement) {
        value = addDays(new Date(), noValueIncrement).toISOString();
      }
      propsData.value.value = value;
      // TODO is overwriting rules. Instead ADD future rule
      propsData.value.rules = [
        {
          future_date: true,
        },
      ];
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
      debug(this.propsData);
    },
  },
};
</script>
