<template>
  <div
    class="behavior_editor_action behavior_dont_include_in_email"
    aut-editor-actions
    :class="wrapperClass"
    v-if="displayEditorActions"
  >
    <div class="behavior_editor_actions" :class="`${parentClass} ${classes}`">
      <v-card>
        <div class="px-2 d-flex">
          <EditorAction
            v-for="action in actions"
            :key="action.id"
            :context="context"
            :action="action"
            v-on="$listeners"
          />
          <v-spacer />
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import EditorAction from "./EditorAction";

import { isUnAuthenticated } from "@/util";
const debug = require("debug")("atman.components.EditorActions");
debug(`EditorActions`);
export default {
  name: "EditorActions",
  props: {
    actions: {
      type: Array,
    },
    classes: {
      type: String,
    },
    design: {
      type: Boolean,
      default: true,
    },
    context: {
      type: String,
    },
  },
  computed: {
    displayEditorActions() {
      return !isUnAuthenticated(this) && this.actions.length;
    },
  },
  components: {
    EditorAction,
  },
  mounted() {
    if (this.isFeatureEnabled("editor.actions.position", false)) {
      this.$el.querySelector(".behavior_editor_actions").classList.add("left");
    }
  },
  data() {
    let wrapperClass = "behavior_editor_actions";
    let parentClass = "";
    if (this.design) {
      wrapperClass += " designAction";
      parentClass += " designActions";
    }
    return {
      wrapperClass,
      parentClass,
    };
  },
};
</script>
<style lang="css" scoped>
.behavior_editor_actions {
  z-index: 2;
  position: absolute;
  top: 0px;
}
.behavior_editor_actions:not(.left) {
  right: 0px;
}
.behavior_editor_actions.left {
  left: 0px;
  top: -16px;
}

.behavior_editor_action {
  position: relative;
}

.designMode > .designAction {
  display: none !important;
}
.designMode:hover > .designAction {
  display: inherit !important;
}

.behaviour_action_container .behavior_editor_actions {
  display: none !important;
}
.behaviour_action_container:hover .behavior_editor_actions {
  display: inherit !important;
}
</style>
