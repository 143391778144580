<template>
  <v-tooltip top v-if="displayCloseButton">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        aut-dialog-close
        v-bind:class="classes"
        icon
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    Close Dialog
  </v-tooltip>
</template>
<script>
const debug = require("debug")("atman.dialog.close_button"); // eslint-disable-line
export default {
  name: "CloseButton",
  props: {
    classes: {},
  },
  computed: {
    displayCloseButton() {
      if (
        this.isSmallScreen &&
        this.isFeatureEnabled("dialog.close_button.mobile..display")
      ) {
        return true;
      }
      return this.isFeatureEnabled("dialog.close_button.display");
    },
  },
  mounted() {
    debug(`Will display close button`, this.displayCloseButton);
  },
};
</script>
