<template>
  <div></div>
</template>
<script>
import { createContext } from "@/util.js";
import { store, STORE_CONSTS } from "@/components/fields/store";
const debug = require('debug')('atman.api_handler')  // eslint-disable-line

export default {
  name: "APIHandler",
  data() {
    return {
      storeKey: "passthrough",
      context: "",
    };
  },
  mounted() {
    const component = this;
    component.context = createContext(this.$route.fullPath);
    component._createStore(this.storeKey, store);
    component.initialiseStore();
    component.triggerAction();
  },
  methods: {
    async triggerAction() {
      const component = this;
      const payload = {
        url: this.$route.fullPath,
        type: "post",
      };
      debug(`payload`, payload);
      try {
        await component.$store.dispatch(`${component.storeKey}/triggerAction`, {
          actionDefinition: {
            value: payload,
          },
          isDialog: false,
        });
      } catch (e) {
        console.error(e);
        if (typeof e == "string") {
          component.displayErrorMessage(e);
        } else {
          component.displayErrorMessage(`Operation unsuccessful`, e);
        }
      }
    },
    initialiseStore() {
      const component = this;
      const storeKey = component.storeKey;
      component.$store.commit(`${storeKey}/${STORE_CONSTS.ID}`, storeKey);
      component.$store.commit(
        `${storeKey}/${STORE_CONSTS.CONTEXT}`,
        component.context
      );
    },
  },
};
</script>
