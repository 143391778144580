<template>
  <v-hover v-slot="{ hover }">
    <v-card
      raised
      ripple
      :elevation="hover ? 12 : 2"
      :class="{ 'on-hover': hover }"
      class="ma-2 pa-0 d-flex flex-column justify-space-between behaviour_centered transparent"
      width="150px"
    >
      <div class="cardImage d-flex flex-row" v-bind="containerAttributes">
        <v-img
          :lazy-src="no_image"
          :src="frontCover"
          v-bind="imageAttributes"
          contain
          class="behaviour_centered behavior_clickable ma-0 pa-0 align-self-end"
          @click="$emit('show_details')"
        />
      </div>
      <v-card-subtitle class="py-2 pl-0">
        <div class="cardTitle">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="text-left font-weight-bold text-capitalize white--text line-clamp-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ title }}
              </div>
            </template>
            <span>{{ title }}</span>
          </v-tooltip>
        </div>
        <Field
          v-if="displayCost && costDefinition"
          class="behaviour_centered mt-n4"
          :definition="costDefinition"
          :context="context"
        />
      </v-card-subtitle>
    </v-card>
  </v-hover>
</template>
<script>
import bookMixin from "./bookMixin";
export default {
  name: "MinimalBook",
  mixins: [bookMixin],
  computed: {
    containerAttributes() {
      const containerHeight = (this.imageAttributes?.width || 200) * 0.6;
      let style = `height: ${containerHeight}px;`;
      return {
        style,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card {
  opacity: 1;
  &:not(.on-hover) {
    opacity: 0.9;
  }

  .cardTitle {
    .line-clamp-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    height: 50px;
    line-height: 15px; // Ensures wrapped lines appear related and together
  }
}
</style>
