<template>
  <div v-if="systemPageKey">
    <LoginPage v-if="systemPageKey == 'login'" />
    <RegisterPage v-else-if="systemPageKey == 'registration'" />
    <MyProfile v-else-if="systemPageKey == 'profile'" />
    <ResetPassword v-else-if="systemPageKey == 'reset_password'" />
  </div>
  <div aut-home-page v-else>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="page"
      :page="page"
      :key="pageKey"
      :context="currentContext"
      :access_control="accessControl"
      v-on="$listeners"
    />
    <v-row dense no-gutters>
      <DynamicPages aut-home-panel :width="12" />
    </v-row>
  </div>
</template>

<script>
import { omit, isEqual } from "lodash";
import { EMAIL_PAGE } from "@/constants.js";
import { pageMixin } from "@/components/mixin.js";
import { mapState, mapActions } from "vuex";
import { createContext } from "@/util.js";
import { DISPLAY_PAGE_EVENT, EMAIL_PAGE_EVENT } from "@/constants";

const debug = require("debug")("atman.view.home");
import { systemPages } from "@/settings";

export default {
  name: "Home",
  components: {
    DynamicPages: () =>
      import("@/components/pageContent/DynamicPages/DynamicPages.vue"),
    LoginPage: () => import("@/views/user/LoginPage.vue"),
    RegisterPage: () => import("@/views/user/RegisterPage.vue"),
    MyProfile: () => import("@/views/user/MyProfile/MyProfile.vue"),
    ResetPassword: () => import("@/views/user/ResetPassword.vue"),
  },

  mixins: [pageMixin],
  computed: {
    ...mapState(["menu"]),
  },
  data() {
    return {
      systemPageKey: null,
    };
  },
  watch: {
    $route(from, to) {
      let shouldReloadFlow = false;
      // If the path is Not the same reload
      if (from.path != to.path) {
        shouldReloadFlow = true;
      } else {
        // IF the only difference is in search criteria, don't reload the page
        const fromQueryParams = omit(from?.query || {}, ["filter", "search"]);
        const toQueryParams = omit(to?.query || {}, ["filter", "search"]);
        if (!isEqual(fromQueryParams, toQueryParams)) {
          shouldReloadFlow = true;
        }
      }
      if (shouldReloadFlow) {
        debug("route change. Triggering flow");
        this.handleRouteChange();
      }
    },
  },
  mounted() {
    debug("in mounted");
    this.handleRouteChange();
    this.registerToEvents();
  },
  methods: {
    ...mapActions(["fetchSkin"]),
    printPage() {
      const component = this;
      component.$store.commit(
        EMAIL_PAGE,
        component.$el.querySelector("[aut-page]").innerHTML
      );
    },

    registerToEvents() {
      const component = this;
      component.unsubscribe = [];
      const unsubscribeFn = component.$store.subscribeAction((action) => {
        debug(`action.type is [${action.type}]`);
        switch (action.type) {
          case DISPLAY_PAGE_EVENT: {
            debug(`action.type is ${DISPLAY_PAGE_EVENT}}`);
            debug("in home", action.payload);
            component.loadFlow({ inputPage: action.payload.page });
            return;
          }
          case EMAIL_PAGE_EVENT: {
            debug(`action.type is [${EMAIL_PAGE_EVENT}]`);
            this.printPage();
            return;
          }
          default: {
            debug("Bypassing event");
          }
        }
      });
      this._subscribe(unsubscribeFn);
    },
    async loadCustomPage(systemPageConfig) {
      debug(
        `Attempting to load System page for [${systemPageConfig.custom_page}]`
      );
      const customPage = this.getFeatureValue(
        `custom_pages.${systemPageConfig.custom_page}`
      );
      if (customPage) {
        debug(
          `custom page  found [${customPage}] for [${systemPageConfig.custom_page}]`
        );
        if (systemPageConfig.behavior == "replace" && systemPageConfig.key) {
          debug(`Replacing`);
          await this.loadFlow({ inputPath: customPage });
        } else {
          debug(`Redirecting`);
          this.$router.push(customPage);
        }
      } else {
        debug(
          `No custom page  found for [${systemPageConfig.custom_page}]. Loading system page`
        );
        if (systemPageConfig.key) {
          this.systemPageKey = systemPageConfig.key;
        } else {
          await this.loadFlow();
        }
      }
    },
    async handleRouteChange() {
      const component = this;
      debug(component.$route);
      if (!this.isPassThrough) {
        await component.fetchSkin();
        component.systemPageKey = null;
        const systemPage = systemPages[component.$route.path];
        if (systemPage) {
          component.loadCustomPage(systemPage);
          return;
        }
      }
      await component.loadFlow();
    },
    async loadFlow(inputs = {}) {
      const { inputPage, inputPath = "" } = inputs || {};
      const path = inputPath || this.$route.fullPath;
      const context = createContext(path);
      debug(`loadFlow invoked`, context);
      // Set instance variable to avoid loops
      this.currentContext = context;
      this.page = null;
      this.$nextTick(async () => {
        if (inputPage) {
          this.page = inputPage;
          return;
        }
        this.fetchFlowDetails(path);
      });
    },
  },
};
</script>
