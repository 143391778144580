export default {
  label: "JSON Editor",
  filters: ["list_editor"],
  type: "json_editor",
  description: "Displays a JSON editor",
  templates: [
    {
      id: "default",
      description: "The default JSON Editor.",
      label: "Default",
      value: {
        name: "some_name",
        label: "JSON",
        type: "json_editor",
        value: {
          a: "b",
          c: "d",
        },
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
};
