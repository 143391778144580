export default {
  id: "id-0",
  name: "Rodriguez, Gislason and Robel",
  product: {
    "id-9": {
      quantity: 6,
      _url: "/applications/shop_application/products/id-9",
    },
    "id-3": {
      quantity: 1,
      _url: "/applications/shop_application/products/id-3",
    },
  },
  _url: "/applications/shop_application/cart/id-0",
};
