<template>
  <div>
    <ImageFile v-if="isImage" :fieldValue="fieldValue" :value="value" />
    <BinaryFile
      v-if="!isImage && supportsEmbed"
      :fieldValue="fieldValue"
      :value="value"
    />
  </div>
</template>
<script>
import ImageFile from "./ImageFile";
import BinaryFile from "./BinaryFile";
import fileMixin from "../mixin";
export default {
  name: "EmbeddedFile",
  mixins: [fileMixin],
  components: {
    ImageFile,
    BinaryFile,
  },
  props: {
    fieldValue: {},
    value: {
      type: Object,
    },
  },
};
</script>
