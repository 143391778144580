export default {
  start: {
    type: "trigger_event",
    params: {
      id: ["general-1"],
    },
  },
  categories: {
    general: {
      display: {
        classes: [],
        attributes: {
          color: "#C3DFE0",
        },
      },
      label: "General",
      events: {
        "general-1": {
          type: "display_question",
          question: "Eplilepsy question",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-2"],
              },
            },
          ],
        },
        "general-2": {
          type: "display_question",
          question: "Dementia question",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
        },
      },
    },
  },
  events: {
    display_summary: {
      type: "display_summary",
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-1",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: "<p>User has Epilepsy</p>",
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-2",
              },
            ],
          },
          event: {
            type: "update",
            params: {
              target: "outcome",
              value: "<p>User has Dementia</p>",
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-1",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "variable_1",
              value: "EPILEPSY",
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                value: "yes",
                path: "$.general-2",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "variable_2",
              value: "DEMENTIA",
            },
          },
        },
      ],
    },
  },

  summary: {
    title: "Diagnosis",
  },
};
