<template>
  <v-row dense>
    <v-text-field
      dense
      aut-calendar-label
      name="format"
      label="Format"
      hint="Choose a format"
      persistent-hint
      v-model="fieldDefinition.format"
      @change="updateDefinition"
      required
    ></v-text-field>
  </v-row>
</template>
<script>
import { clone } from "@/util";
const debug = require("debug")("atman.components.calendar_designer");
debug(`calendar_designer`);
export default {
  name: "CalendarDesigner",
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      fieldDefinition: clone(this.definition),
    };
  },
  methods: {
    updateDefinition() {
      debug(`DropdownDesigner updateDefinition`, this.fieldDefinition);
      this.$emit("update:definition", this.fieldDefinition);
    },
  },
};
</script>
