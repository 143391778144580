<template>
  <v-list class="workflowPalette py-1" aut-workflow-pallete>
    <v-list-group
      v-for="item in categories"
      :key="item.label"
      multiple
      :value="true"
    >
      <template v-slot:activator>
        <v-list-item-content class="ma-0 pa-0">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        :aut-workflow-pallete-item="child.name"
        class="ma-0 pa-0"
        v-for="(child, i) in getItemsForCategory(item.label)"
        :key="i"
        link
        @click="$emit('add-item', child.name)"
      >
        <v-list-item-content class="ma-0 pt-0">
          <v-card
            elevation="1"
            outlined
            height="60"
            width="150"
            :color="child.display.background_color"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :class="`float-right pt-1 pr-1 ${child.display.text_color}--text`"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle</v-icon
                >
              </template>
              {{ child.display.description }}
            </v-tooltip>

            <div class="d-flex pa-1">
              <v-icon
                :class="`${child.display.text_color}--text`"
                v-if="child.display.icon"
                >{{ child.display.icon }}</v-icon
              >
              <v-img
                v-else
                :src="child.display.image"
                contain
                min-width="20"
                width="20"
                max-width="20"
              />
              <span
                :class="`ml-2 text-body-2 text-left ${child.display.text_color}--text`"
                >{{ child.display.label }}</span
              >
              <v-spacer />
            </div>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import { uniqBy } from "lodash";
const debug = require("debug")("atman.components.workflow_pallette");
export default {
  name: "WorkflowPallete",
  props: {
    blocks: {
      type: Array,
    },
  },
  computed: {
    categories() {
      return uniqBy(
        this.blocks.map((block) => ({
          label: block.display.category,
        })),
        "label"
      );
    },
  },
  methods: {
    getItemsForCategory(filtercategory) {
      const filteredItems = this.blocks.filter(
        ({ display }) => display.category == filtercategory
      );
      debug("Found", filteredItems, "for", filtercategory);
      return filteredItems;
    },
  },
};
</script>

<style lang="scss" scoped></style>
