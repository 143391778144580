<template>
  <Field
    v-if="tableDefinition"
    :key="refreshKey"
    :definition="tableDefinition"
    :context="context"
  />
</template>

<script>
import { clone, registerToEvents, makeServerCall } from "@/util";
import detailedCart from "./detailed_cart";
import miniCart from "./mini_cart";
import { fieldMixin } from "@/components/mixin.js";
import { defaultsDeep, isPlainObject } from "lodash";
import definition from "./definition";

const debug = require("debug")("atman.components.cart"); // eslint-disable-line

export default {
  name: "Cart",
  mixins: [fieldMixin],
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  props: {},
  data() {
    return {
      definition,
      refreshKey: 1,
      tableDefinition: null,
      items: [],
    };
  },
  created() {},
  async mounted() {
    await this.fetchItems();
    this.determineDisplayValue();
    this.registerEvents();
  },
  methods: {
    async _afterFieldValueUpdate() {
      const component = this;
      await component.fetchItems();
      component.determineDisplayValue();
    },
    determineDisplayValue() {
      const component = this;
      debug(`Field value updated`, component.fieldValue);
      let cartTableDefinition = detailedCart;
      if (this.value.format == "mini") {
        cartTableDefinition = miniCart;
      }
      const tableDefinitionWithData = defaultsDeep(
        {
          definition: { data: component.items },
        },
        cartTableDefinition
      );
      debug(`tableDefinitionWithData`, tableDefinitionWithData);
      component.tableDefinition = clone(tableDefinitionWithData);

      component.refreshKey++;
    },
    registerEvents() {
      const component = this;
      const events = [
        {
          name: "delete_list_item",
        },
        {
          name: "update_list_item",
        },
        {
          name: "add_to_cart",
        },
      ];
      const callBackFn = (triggeredEvent, payload) => {
        debug(`In cart`, triggeredEvent, payload);
        switch (triggeredEvent.name) {
          case "delete_list_item": {
            const fieldValue = clone(component.fieldValue);
            delete fieldValue.product[payload.value.id];
            component.$set(component, "fieldValue", fieldValue);
            debug(`after deletion`, component.fieldValue);
            break;
          }
          case "update_list_item": {
            const fieldValue = clone(component.fieldValue);
            const product = fieldValue.product[payload.value.id];
            product.quantity = payload.value.quantity * 1;
            component.$set(component, "fieldValue", fieldValue);
            debug(`after update`, component.fieldValue);
            break;
          }
          case "add_to_cart": {
            const currentValue = clone(component.fieldValue || {});
            const product = currentValue?.product?.[payload.value.id];

            const newProduct = {
              _url: payload.value?._url || "",
            };

            if (product) {
              newProduct.quantity =
                payload.value.quantity * 1 + (product?.quantity || 0) * 1;
            } else {
              newProduct.quantity = payload.value?.quantity * 1;
            }

            const fieldValue = defaultsDeep(
              {},
              {
                product: {
                  [payload.value.id]: newProduct,
                },
              },
              currentValue
            );

            component.$set(component, "fieldValue", fieldValue);
            debug(`after update`, component.fieldValue);
            break;
          }
          default: {
            console.error(`Unhandled event`);
          }
        }
      };
      component.unsubscribe = registerToEvents({
        $store: component.$store,
        events,
        callBackFn,
      });
    },
    async fetchItems() {
      const products = this.fieldValue?.product || {};
      const results = [];

      for (const key in products) {
        const value = products[key];

        if (!value?._url) {
          continue;
        }

        let data = {};
        try {
          const response = await makeServerCall({
            url: value._url,
            type: "get",
          });
          data = response?.data;
        } catch (e) {
          debug(`Could not get entity details`, e);
        }

        if (!isPlainObject(data)) {
          continue;
        }

        results.push(defaultsDeep({ id: key }, value, { product: data }));
      }
      this.items = results;
    },
  },
};
</script>
