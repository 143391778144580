<template>
  <v-row class="workflowEditor" dense no-gutters>
    <v-col cols="12" lg="2" class="pallette mx-0">
      <WorkflowPalette @add-item="addItem" :blocks="validBlocks" />
    </v-col>
    <v-col cols="12" lg="10" class="canvas mx-0">
      <WorkflowCanvas
        v-if="blocks.length && scene"
        ref="container"
        :blocksContent="blocks"
        :scene.sync="scene"
        class="blocksContainer"
      />
    </v-col>
  </v-row>
</template>
<script>
import WorkflowPalette from "./WorkflowPalette";
import WorkflowCanvas from "./WorkflowCanvas";
import { pick } from "lodash";
import { clone, makeServerCall } from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/WorkflowEditor/store.js";
const debug = require("debug")("atman.components.workflow_steps");
debug(`workflow_steps`);

const convertToScene = (linkedList) => {
  const scene = {
    blocks: [],
    links: [],
    container: {
      scale: 1,
    },
  };
  let i = 0;
  Object.keys(linkedList).forEach((key) => {
    const value = linkedList[key];
    const clonedValue = JSON.parse(JSON.stringify(value));
    delete clonedValue.links;

    scene.blocks.push(
      Object.assign(
        {
          id: `${key}`,
        },
        clonedValue
      )
    );
    (value.links || []).forEach((link) => {
      scene.links.push({
        id: `${i++}`,
        originID: key,
        originSlot: link.originSlot,
        targetID: link.targetID,
        targetSlot: link.targetSlot,
      });
    });
  });
  return scene;
};

const convertToWorkflow = (scene) => {
  const steps = {};
  scene.blocks.forEach((block) => {
    steps[`${block.id}`] = {
      name: block.name,
      display: {
        x: block.display.x,
        y: block.display.y,
        label: block.display.label,
      },
      data: block.data,
    };
  });
  scene.links.forEach((link) => {
    steps[link.originID].links = steps[link.originID].links || [];
    steps[link.originID].links.push(
      pick(link, ["originID", "originSlot", "targetID", "targetSlot"])
    );
  });
  return steps;
};

export default {
  name: "WorkflowSteps",
  data() {
    return {
      blocks: [],
    };
  },
  components: {
    WorkflowPalette,
    WorkflowCanvas,
  },
  computed: {
    validBlocks() {
      return this.blocks.filter((block) => {
        let conditionsSatisfied = true;
        const rules = block.display.rules || [];
        for (let i = 0; i < rules.length; i++) {
          const rule = rules[i];
          if (
            typeof rule.multiple != "undefined" &&
            !rule.multiple &&
            this.scene.blocks.find(({ name }) => name == block.name)
          ) {
            conditionsSatisfied = false;
            break;
          }
        }
        return conditionsSatisfied;
      });
    },
    scene: {
      get: function () {
        if (!this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE]) {
          return null;
        }
        const workflow =
          this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
            STORE_CONSTS.WORKFLOW
          ];
        let scene = convertToScene(workflow.steps || {});
        scene = Object.assign({}, scene, {
          container: this.container || {},
        });
        debug("scene in getter", scene);
        return scene;
      },
      set: function (newValue) {
        const scene = convertToWorkflow(newValue);
        debug(`Setting scene`, scene);
        this.$store.commit(
          `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.UPDATE_NODE}`,
          { key: "steps", value: scene }
        );
        this.container = newValue.container;
      },
    },
    workflowDefinition: {
      get() {
        return this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.WORKFLOW
        ];
      },
      set(newValue) {
        debug(`Setting workflowDefinition`);
        this.$store.commit(
          `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.WORKFLOW}`,
          newValue
        );
      },
    },
  },
  created() {},
  mounted() {
    debug(`In mounted of WorkflowSteps`);
    this.fetchPallete();
  },
  methods: {
    async fetchPallete() {
      const component = this;
      const definition =
        component.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.DEFINITION
        ];
      const apiDefinition = clone(definition.apis.workflow_pallette);
      apiDefinition.type = "get";
      debug("Going to make a server call to", apiDefinition);
      const results = (await makeServerCall(apiDefinition)).data;
      this.blocks = results.map((item) => {
        item.name = item.id;
        return item;
      });
    },
    addItem(name) {
      this.$refs.container.addNewBlock(name);
    },
  },
};
</script>
<style lang="scss" scoped>
.workflowEditor {
  border: 1px solid black;
  height: 70vh;
  .pallette {
    border-right: 1px solid black;
    max-height: 100%;
    overflow-y: scroll;
  }
  .blocksContainer {
    width: 100%;
    height: 100%;
  }
}
</style>
