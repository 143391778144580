<template>
  <v-dialog v-model="display" width="400" persistent>
    <v-card aut-terms-dialog class="pa-2 pt-6">
      <v-card-text>
        <div v-html="content"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="rejectTandC" aut-reject-tc>
          Reject
        </v-btn>
        <v-btn color="primary" aut-accept-tc @click="acceptTandC" class="ml-2"
          >Accept</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { set } from "lodash";
import { rules } from "@/util.js";
import { mapState, mapActions } from "vuex";

const debug = require("debug")("atman.components.terms_and_conditions"); // eslint-disable-line

export default {
  name: "TermsAndConditions",
  props: {
    display: {
      type: Boolean,
    },
  },
  watch: {
    display() {
      this.displayDialog = this.display;
    },
  },
  data() {
    return {
      agreed: false,
      rules: [...[rules.mandatory({ debugKey: "t_and_c" })]],
    };
  },
  computed: {
    ...mapState(["skin"]),
    content() {
      return this.getFeatureValue("terms_and_conditions.content");
    },
  },
  async mounted() {
    await this.fetchSkin();
  },
  methods: {
    ...mapActions("user", ["logout"]),
    ...mapActions(["fetchSkin", "saveSettingsToDomain"]),
    ...mapActions("preferences", ["savePreferences"]),
    acceptTandC() {
      const settings = {};
      set(settings, "terms_and_conditions.status", "agreed");
      this.savePreferences(settings);
      this.$emit("close");
    },
    rejectTandC() {
      this.logout();
      this.$emit("close");
      this.$router.push("/login");
    },
  },
};
</script>
