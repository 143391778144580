const routes = [
  {
    path: "/create_form",
    name: "Create Form",
    component: () => import("./CreateForm"),
    meta: { unauthenticated: false },
  },
  {
    path: "/edit_form/:id",
    name: "Edit Form",
    component: () => import("./EditForm"),
    meta: { unauthenticated: false },
  },
  {
    path: "/forms",
    name: "Forms",
    component: () => import("./ListForms"),
    meta: { unauthenticated: false },
  },
];

export default routes;
