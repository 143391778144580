const debug = require("debug")("atman.components.filtered_list"); // eslint-disable-line
export default {
  label: "FilteredList",
  type: "filtered_list",
  description: "Displays a Filtered List",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      label: "Default",
      value: {
        type: "filtered_list",
        name: "users",
        left: {
          options: {
            value: [
              {
                id: "f4fc4f70-c3db-5287-88e4-89866a2e4742",
                name: "lapbovge@ehzo.cm",
              },
              {
                id: "d9744247-34ef-596d-b319-a128f27e61df",
                name: "nat@eb.su",
              },
              {
                id: "cc71f20d-2fbc-55de-9296-04549c145f51",
                name: "vetmer@rohpub.bs",
              },
              {
                id: "436f34a8-66e0-54b6-921c-5c9e6052a289",
                name: "useut@seas.dk",
              },
            ],
          },
        },
        right: {
          options: {
            value: [
              {
                id: "bf6f7a62-b599-5194-802c-58ea816c3c2a",
                name: "ikla@liw.sh",
              },
              {
                id: "33ad0651-565f-52fd-bdd6-673acadc40bb",
                name: "ec@ciwnoj.mn",
              },
              {
                id: "f2ae628c-b7d8-506a-9150-1a1731eae8e9",
                name: "giab@wa.ng",
              },
              {
                id: "4eb34de0-5083-5f48-8236-70b42658f697",
                name: "ebocudleh@nutcihfa.sh",
              },
            ],
          },
        },
      },
    },
    {
      id: "dynamic_values",
      label: "Dynamic",
      value: {
        type: "filtered_list",
        name: "users",
        left: {
          options: {
            apis: {
              data: {
                url: "/users",
                type: "get",
              },
            },
          },
        },
        right: {
          options: {
            apis: {
              data: {
                url: "/users",
                type: "get",
              },
            },
          },
        },
      },
    },
  ],
};
