<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      aut-address-city
      :context="context"
      :label="label"
      v-model="city"
      :mandatory="isMandatory"
      @change="$emit('update', { city: $event })"
    />
  </v-col>
</template>
<script>
import address_mixin from "./address_mixin";
const debug = require('debug')('atman.components.fields.address.city')  // eslint-disable-line
export default {
  name: "AddressCity",
  mixins: [address_mixin],
  props: {
    context: {},
    value: {},
    definition: {},
  },
  watch: {
    "value.city"() {
      debug(`Updating city`, this.value.city);
      this.city = this.value.city;
    },
  },
  data() {
    return {
      city: this._getValue("city"),
    };
  },
  computed: {
    fieldProperties() {
      const configuration = this.definition?.address_fields?.city || {};
      return configuration;
    },
  },
  mounted() {
    debug(this.definition, this.value);
  },
  created() {
    this.defaults = {
      label: "City",
      display: {
        width: "6",
      },
    };
  },
};
</script>
