import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
const debug = require("debug")("atman.components.quarter_picker"); // eslint-disable-line

export default {
  label: "Quarter Picker",
  type: "quarter",
  description: "Displays a quarter picker",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "value",
    "disabled",
  ],
  templates: [
    {
      id: "default",
      description: "The default Quarter Picker.",
      label: "Default",
      value: {
        name: "some_name",
        label: "Quarter",
        type: "quarter",
      },
    },
    {
      id: "default_display",
      description:
        'Use <code>"mode": "display"</code> to display selected quarter.',
      label: "Display",
      value: {
        name: "some_name",
        label: "Quarter",
        value: "2021-1",
        mode: "display",
        type: "quarter",
      },
    },
  ],
  attributes: [no_value_text, no_value_behavior],
};
