var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-0 d-flex align-center mb-4",attrs:{"aut-nav-bar":"","color":_vm.bgColorAppBar}},[_c('div',[(_vm.displayMenuButton)?_c('v-app-bar-nav-icon',{staticClass:"mr-n2",attrs:{"aut-menu":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSidebar.apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"behavior_action_icon",attrs:{"color":_vm.menuButtonColor}},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,false,4135151675)},[_vm._v(" Toggle Sidebar ")])],1):_vm._e(),(_vm.displayApplicationHome)?_c('v-app-bar-nav-icon',{staticClass:"mr-n2",on:{"click":function($event){$event.stopPropagation();return _vm.goToApplicationHome.apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"behavior_action_icon",attrs:{"color":_vm.applicationHomeColor}},'v-icon',attrs,false),on),[_vm._v("mdi-home")])]}}],null,false,4072616599)},[_vm._v(" Application Home ")])],1):_vm._e(),(_vm.displayBackButton)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.goBack.apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"behavior_action_icon",attrs:{"color":_vm.backButtonColor}},'v-icon',attrs,false),on),[_vm._v("mdi-backburger")])]}}],null,false,2059168430)},[_vm._v(" Back ")])],1):_vm._e()],1),(!_vm.isSmallScreen && _vm.displayMenus)?_c('div',{staticClass:"px-1",attrs:{"flat":"","nav":"","expand":""}},[_c('MenuOptions',{attrs:{"menus":_vm.menu,"color":_vm.menuTextColor}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }