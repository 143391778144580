<template>
  <div>
    <v-btn @click="addIntegration">Add</v-btn>
  </div>
</template>
<script>
import { makeServerCall } from "@/util.js";
export default {
  name: "ZoomIntegration",
  methods: {
    async addIntegration() {
      const url = "/integration/zoom?action=add";
      return (await makeServerCall({ url })).data;
    },
  },
};
</script>
