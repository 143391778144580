<template>
  <div>
    <div v-if="isDisplayModeImage">
      <ImageFile
        :context="context"
        v-if="imageObject"
        :fieldValue="imageObject"
        :value="value"
        :displayAttributes="displayAttributes"
      />
    </div>
    <div v-if="!isDisplayMode">
      <AddImageDialog
        v-if="dialog"
        :modes="modes || []"
        :dialog="dialog"
        :placeholder="placeholder"
        @close="dialog = false"
        @upload_image="uploadImage"
        @update="imageUpdated"
        :fieldValue="fieldValue"
        :rules="applicableRules"
        :classes="displayClasses"
        v-model="value"
        :source="source"
      />
      <FieldLabel
        :definition="value"
        mode="display"
        :label="label"
        :attributes="labelAttributes"
      >
      </FieldLabel>
      <p v-if="value.hint" class="text-caption">{{ value.hint }}</p>
      <div class="behavior_image_container" v-if="hasImage || placeholder">
        <ImageFile
          :fieldValue="imageObject"
          :value="value"
          :displayAttributes="displayAttributes"
          @click:image="showUploadDialog"
          :class="{ behavior_clickable: isUploadOnImage && !isDisabled }"
        >
        </ImageFile>
        <v-btn
          v-if="!isDisabled && !isUploadOnImage"
          class="behavior_change_image"
          aut-change-image
          @click.stop="dialog = true"
          icon
          ><v-icon>mdi-upload</v-icon></v-btn
        >
      </div>
      <div v-else>
        <v-tooltip top :label="label" :attributes="labelAttributes">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              aut-add-image
              text
              outlined
              color="primary"
              @click.stop="dialog = true"
              v-bind="Object.assign(attrs, buttonAttributes)"
              v-on="on"
            >
              <v-icon
                :class="iconClasses"
                class="behavior_action_icon"
                :aut-action-icon="value.icon"
                >{{ value.icon || "mdi-file-upload" }}</v-icon
              >
              {{ addImageLabel }}
            </v-btn>
          </template>
          {{ addImageLabel }}
        </v-tooltip>
        <br />
        <template v-if="isMultiple && multipleImages.length">
          <v-chip
            color="primary"
            class="ml-1 mt-2"
            outlined
            aut-image-chip
            close
            @click:close="removeImage(index)"
            v-for="(imageName, index) in multipleImages"
            :key="index"
          >
            <v-icon left> mdi-image </v-icon>
            {{ imageName }}
          </v-chip>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AddImageDialog from "./AddImageDialog.vue";
import { fieldMixin } from "@/components/mixin.js";
import { fetchRandomValue, getFileSrc } from "@/util.js";
import { isEqual } from "lodash";
import definition from "./definition";

const debug = require("debug")("atman.components.image");
debug("image_field");
export default {
  name: "ImageField",
  props: {
    modes: {},
  },
  components: {
    AddImageDialog,
    ImageFile: () =>
      import("@/components/fields/FileField/EmbeddedFile/ImageFile.vue"),
  },
  mixins: [fieldMixin],
  data() {
    return {
      dialog: false,
      definition,
      placeholder: "",
    };
  },
  watch: {
    "displayAttributes.placeholder"(from, to) {
      if (!isEqual(from, to)) {
        this.setPlaceholderValue();
      }
    },
  },
  computed: {
    buttonAttributes() {
      let attributes = {};
      if (this.isSmallScreen) {
        attributes.block = true;
      }
      return attributes;
    },
    addImageLabel() {
      let result = "";
      switch (this.source) {
        case "file": {
          result = `Upload `;
          break;
        }
        default: {
          result = `Add `;
          break;
        }
      }
      result = `${result} ${this.label}`;
      return result;
    },
    isDisplayModeImage() {
      // If this is not display mode, just return false
      if (!this.isDisplayMode) {
        return false;
      }

      // IF source is present, return true
      if (this.imageSrc) {
        return true;
      }

      // IF there is no source, check the `if_missing` attribute

      switch (this.displayAttributes?.if_missing) {
        case "placeholder": {
          return true;
        }
        case "character": {
          return true;
        }
        case "hidden": {
          return false;
        }
        default: {
          return false;
        }
      }
    },
    imageSrc() {
      let link;
      if (this.source == "link" || typeof this.fieldValue == "string") {
        link = this.fieldValue;
      } else if (typeof this.fieldValue?._file == "string") {
        link = this.fieldValue?._file;
      } else if (this.fieldValue instanceof File) {
        link = this.getURL(this.fieldValue);
      }
      debug("imageSrc link", link);
      return link;
    },
    hasImage() {
      return !!this.imageSrc;
    },
    imageObject() {
      if (this.displayAttributes?.if_missing == "character") {
        return { link: this.imageSrc };
      }
      return {
        link: this.imageSrc || this.placeholder || "/no_image.jpg",
      };
    },
    source() {
      let result = this?.value?.source;
      debug(`source`, result);
      return result;
    },
    isMultiple() {
      return this.displayAttributes?.multiple == true;
    },
    multipleImages() {
      if (!Array.isArray(this.fieldValue)) {
        return [];
      }

      const result = this.fieldValue.map((imageFile) => {
        if (imageFile instanceof File || typeof imageFile?._file == "string") {
          return imageFile?.id || imageFile?.name || "";
        }

        return "";
      });

      return result;
    },
    isUploadOnImage() {
      return this.displayAttributes?.upload_on_image == true;
    },
  },
  mounted() {
    this.setPlaceholderValue();
  },
  methods: {
    imageUpdated(value) {
      this.$set(this, "fieldValue", value);
      debug(`imageUpdated`, this.fieldValue);
    },
    async uploadImage() {
      const component = this;
      if (!this.displayAttributes?.allow_submit) {
        debug(
          `Not configured to allow individual submit. Form submission will upload`
        );
        this.dialog = false;
        return;
      }
      debug(`In uploadImage`, component.value.value);
      try {
        await component.$store.dispatch(`${component.context}/uploadFile`, {
          definition: this.value,
          customFunctions: this.replaceIndex(),
        });
        this.dialog = false;
      } catch (e) {
        console.error(e);
        component.displayErrorMessage(`Operation unsuccessful`, e);
      }
    },
    getURL(file) {
      try {
        const url = URL.createObjectURL(file);
        URL.revokeObjectURL(file);
        return url;
      } catch (e) {
        debug(`Failed to preview file`, e, file);
        return "";
      }
    },
    removeImage(index) {
      if (!Array.isArray(this.fieldValue)) {
        return;
      }

      this.fieldValue.splice(index, 1);
    },
    async setPlaceholderValue() {
      const placeholder = this.displayAttributes?.placeholder || {};

      if (typeof placeholder == "string") {
        this.placeholder = placeholder;
        return;
      }

      const result = await fetchRandomValue({
        store: this.$store,
        context: this.context,
        url: placeholder?.url,
        field: placeholder?.field,
        customFunction: getFileSrc,
      });

      this.placeholder = result;
    },
    showUploadDialog() {
      if (this.isUploadOnImage && !this.isDisabled) {
        this.dialog = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_image_container {
  position: relative;
  &:hover {
    .behavior_change_image {
      display: initial;
    }
  }
  .behavior_change_image {
    display: none;
    position: absolute;
    color: var(--v-primary-base);
    bottom: 0px;
    left: 0px;
    background-color: var(--v-primary-lighten5);
  }
}
</style>
