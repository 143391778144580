<template>
  <div v-if="mode != 'display'" aut-constrain-value>
    <v-autocomplete
      v-if="constraintValueType == 'select'"
      v-model="definition.constraint.value"
      :items="constraintValues"
      :key="refreshKey"
      item-text="name"
      item-value="id"
      label="Value*"
      required
      @blur="$emit('update', definition.constraint.value)"
      @change="$emit('update', definition.constraint.value)"
      :rules="valueAccessRules"
    />
    <v-text-field
      v-else-if="constraintValueType == 'text'"
      v-model="definition.constraint.value"
      label="Value*"
      type="number"
      required
      @blur="$emit('update', definition.constraint.value)"
      v-on:keydown.enter.stop.prevent="
        $emit('update', definition.constraint.value)
      "
      :rules="valueLimitRules"
    />
  </div>
  <span v-else>
    <span v-if="type != 'access'">
      {{ displayValue }}
    </span>
    <span v-else>
      <v-icon small color="green" v-if="definition.constraint.value == 'yes'">
        mdi-check
      </v-icon>
      <v-icon small color="error" v-else> mdi-close </v-icon>
    </span>
  </span>
</template>
<script>
import { rules } from "@/rules.js";
import { clone } from "@/util";
const debug = require('debug')('atman.components.field.feature.constraint_value')  // eslint-disable-line
export default {
  name: "ConstraintValue",
  props: {
    value: {
      type: Object,
    },
    type: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      constraintValues: [],
      refreshKey: 1,
      definition: clone(this.value),
      constraintValueType: "select",
    };
  },
  watch: {
    type() {
      this.updateConstraintValues();
    },
  },
  created() {
    this.valueAccessRules = [rules.mandatory()];
    this.valueLimitRules = [rules.mandatory(), rules.min_value({ min: 1 })];
  },
  mounted() {
    this.updateConstraintValues();
  },
  computed: {
    displayValue() {
      const constraintObject = this.constraintValues.find(
        (item) => item.id == this.definition.constraint.value
      );
      if (!constraintObject) {
        return this.definition.constraint.value;
      }
      return constraintObject.name;
    },
  },
  methods: {
    updateConstraintValues() {
      let result = [];
      if (this.type == "access") {
        this.constraintValueType = "select";
        result = [
          { id: "yes", name: "Yes" },
          { id: "no", name: "No" },
        ];
        debug(`this.constraintValues`, this.constraintValues);
        this.constraintValues = result;
      } else {
        this.constraintValueType = "text";
      }
      this.refreshKey++;
    },
  },
};
</script>
