<template>
  <v-container
    class="d-flex justify-center align-center behaviour_video_container pa-0"
  >
    <v-sheet
      class="d-flex justify-center align-center behaviour_video pa-3"
      :width="effectiveWidth"
      :height="effectiveHeight"
      v-if="videoSource"
    >
      <video controls :width="effectiveWidth" aut-video-embed>
        <source :src="videoSource" :type="mimeType" />

        Sorry, your browser doesn't support embedded videos.
      </video>
      <div></div>
      <v-btn
        v-if="!isDisplayMode"
        icon
        @click="$refs.fileinput.click()"
        class="behaviour_video_controls"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet
      class="d-flex justify-center align-center behaviour_no_video pa-3"
      :width="effectiveWidth"
      :height="effectiveHeight"
      v-else-if="!isDisplayMode"
    >
      <v-chip color="primary" outlined disabled>
        {{ fieldValue && fieldValue.name ? fieldValue.name : "Upload a Video" }}
      </v-chip>
      <v-btn
        icon
        @click="$refs.fileinput.click()"
        class="behaviour_video_controls"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>
    </v-sheet>
    <input
      type="file"
      ref="fileinput"
      accept="video/*"
      @click.stop
      style="display: none"
      @change="uploadVideo"
    />
  </v-container>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import fileMixin from "@/components/fields/FileField/mixin.js";
const debug = require("debug")("atman.components.video");
debug("video_field");
export default {
  name: "VideoField",
  mixins: [fieldMixin, fileMixin],
  data() {
    return {};
  },
  computed: {
    videoSource() {
      return this.fieldValue?._file;
    },
  },
  mounted() {},
  methods: {
    uploadVideo(event) {
      const file = event.target.files[0];
      this.fieldValue = file;
    },
  },
};
</script>
<style lang="scss" scoped>
$control_background: rgba(0, 0, 0, 0.6);
.behaviour_video:hover {
  .behaviour_video_controls {
    background: $control_background;
    color: white;
  }
}
.behaviour_no_video {
  outline: 2px dashed var(--v-primary-base);
  outline-offset: -1em;
}
.behaviour_video_container {
  .behaviour_no_video,
  .behaviour_video {
    position: relative;
    .behaviour_video_controls {
      width: 5em;
      height: 5em;
      position: absolute;
      top: 0em;
      left: 0em;
      &:hover {
        background: $control_background;
        color: white;
      }
    }
  }
}
</style>
