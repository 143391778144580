<template>
  <div class="checklist_designer" aut-code-editor-text>
    <KeyValueList :data="items" @update="triggerEvent" label="Label" />
  </div>
</template>

<script>
import { clone } from "@/util";
const debug = require("debug")("atman.components.checklist_designer"); // eslint-disable-line
export default {
  name: "ChecklistFieldDesigner",
  props: {
    definition: {
      type: Object,
    },
  },
  components: {
    KeyValueList: () =>
      import(
        "@/components/fields/PageEditor/SeedDataDesigner/KeyValueList.vue"
      ),
  },
  data() {
    return {
      items: clone(this.definition.items).map((item) => {
        item.name = item.label;
        return item;
      }),
    };
  },
  mounted() {},
  methods: {
    triggerEvent(items) {
      const fieldDefinition = clone(this.definition);
      fieldDefinition.items = clone(items).map((item) => {
        item.label = item.name;
        return item;
      });
      this.$emit("update:definition", fieldDefinition);
    },
  },
};
</script>
