import {
  SHOW_CONFIRMATION,
  CONFIRMATION_CANCEL,
  CONFIRMATION_CONFIRM,
} from "@/store";

export default {
  methods: {
    showConfirmation(message, confirmCallBack, cancelCallBack) {
      const component = this;
      component.$store.commit(SHOW_CONFIRMATION, message);
      const unsubscribe = component.$store.subscribe((mutation) => {
        if (
          mutation.type == CONFIRMATION_CONFIRM ||
          mutation.type == CONFIRMATION_CANCEL
        ) {
          unsubscribe();
          component.unsubscribe.shift();
          if (
            typeof confirmCallBack == "function" &&
            mutation.type == CONFIRMATION_CONFIRM
          ) {
            confirmCallBack();
          } else if (
            typeof cancelCallBack == "function" &&
            mutation.type == CONFIRMATION_CANCEL
          ) {
            cancelCallBack();
          }
        }
      });
      component._subscribe(unsubscribe);
    },
  },
};
