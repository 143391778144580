<template>
  <div>
    <div v-if="isDisplayMode">
      <label v-if="label">{{ label }}: </label>
      <span>{{ displayValue }}</span>
    </div>
    <v-menu
      v-else
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="displayValue"
          :label="label"
          readonly
          aut-quarter-field-value
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-bind:class="displayClasses"
          v-on="on"
        ></v-text-field>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <v-btn
            aut-prev-year
            @click="decrementYear"
            aria-label="Previous year"
            :icon="true"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <label>{{ year }}</label>
          <v-btn
            aut-next-year
            @click="incrementYear"
            disabled
            aria-label="Next year"
            :icon="true"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" lg="3" v-for="(quarter, i) in quarters" :key="i">
              <v-chip
                @click="changeQuarter(quarter)"
                :aut-quarter="quarter"
                :color="colorValue(quarter)"
                text-color="white"
                >{{ quarter }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="menu = false" aut-quarter-picker-cancel>
            Cancel
          </v-btn>
          <v-btn @click="updateValue" aut-quarter-picker-ok> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { addYears, getYear, subYears, getQuarter } from "date-fns";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.quarter_picker"); // eslint-disable-line

const SEPARATOR = "-";

export default {
  name: "QuarterPicker",
  mixins: [fieldMixin],
  mounted() {
    debug(`fieldValue`, this.fieldValue);
  },
  watch: {
    fieldValue() {
      if (!this.fieldValue) {
        return;
      }
      const items = this.fieldValue.split(SEPARATOR);
      if (items.length == 2) {
        (this.year = items[0]), (this.quarter = items[1]);
      }
    },
  },
  data() {
    debug(`this.year`, this.year);
    return {
      menu: false,
      quarters: ["1", "2", "3", "4"],
      year: getYear(new Date()),
      quarter: getQuarter(new Date()),
    };
  },
  computed: {
    displayValue() {
      const items = (this.fieldValue || "").split(SEPARATOR);
      if (items.length == 2) {
        return `${items[0]}-Q${items[1]}`;
      }
      return "";
    },
  },
  methods: {
    updateValue() {
      this.fieldValue = `${this.year}-${this.quarter}`;
      this.menu = false;
    },
    colorValue(input) {
      return input == this.quarter ? "primary" : "";
    },
    incrementYear() {
      debug(`incrementYear`);
      this.year = getYear(addYears(new Date(this.year, 1, 1), 1));
    },
    decrementYear() {
      this.year = getYear(subYears(new Date(this.year, 1, 1), 1));
    },
    changeQuarter(quarter) {
      this.quarter = quarter;
    },
  },
};
</script>
