<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :scrollable="false"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-add-iframe-dialog>
      <DialogTitle @close="$emit('close')" title="Add IFrame link" />
      <v-card-text>
        <div>
          <v-text-field
            prepend-inner-icon="mdi-web"
            aut-iframe-link-input
            label="Iframe Source"
            v-model="linkValue"
            placeholder="Add the link you want to display in the iFrame"
            persistent-hint
            hint="Enter the source url"
            :required="true"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="$emit('close')"
          aut-action-add-iframe-cancel
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          aut-add-iframe-link
          @click="$emit('update', linkValue)"
          class="ml-2"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
  const debug = require("debug")("atman.components.field.iframe.add_iframe"); // eslint-disable-line

export default {
  name: "addIframe",
  data() {
    return {
      linkValue: "",
      dialog: true,
    };
  },
  mixins: [dialogMixin],
};
</script>
