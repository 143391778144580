<template>
  <div class="ma-1 pa-1 px-3 text-body-1 blue darken-1 white--text primary">
    {{ title }}
  </div>
</template>
<script>
export default {
  name: "ListTitle",
  props: {
    title: {
      type: String,
    },
  },
};
</script>
