<template>
  <div v-if="!isAvatar">
    <v-img
      aut-image-embed
      v-if="canDisplay"
      @dblclick.stop="openImage"
      @click="$emit('click:image')"
      :src="effectiveSource"
      v-bind="effectiveAttributes"
      @error="defaultImage"
      :lazy-src="no_image"
      v-bind:class="classes"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>

  <div v-else class="behavior_centered">
    <v-avatar
      color="primary"
      :class="avatarClasses"
      @click="$emit('click:image')"
      :size="effectiveSize"
      aut-image-avatar
    >
      <v-img
        v-if="effectiveSource"
        alt="Avatar"
        :src="effectiveSource"
        @error="defaultImage"
        :lazy-src="no_image"
      />
      <span v-else-if="charValue" class="white--text text-h5" aut-user-char>
        {{ charValue }}
      </span>
    </v-avatar>
  </div>
</template>
<script>
import { defaultsDeep } from "lodash";
import { getSafeDimensions, isChildOf, getDynamicValue } from "@/util";
import fileMixin from "../mixin";
import { mapState } from "vuex";
const debug = require("debug")("image_file"); // eslint-disable-line
export default {
  name: "ImageFile",
  mixins: [fileMixin],
  data() {
    return {
      maxHeight: null,
      charValue: "",
      effectiveAttributes: null,
    };
  },
  props: {
    fieldValue: {
      type: Object,
    },
    value: {
      type: Object,
    },
    displayAttributes: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  watch: {
    displayAttributes() {
      this.determineEffectiveAttributes();
    },
  },
  computed: {
    ...mapState(["no_image"]),

    canDisplay() {
      if (this.displayAttributes?.hide_mobile && this.isSmallScreen) {
        return false;
      }
      return true;
    },
    isAvatar() {
      return this.value?.display?.mode === "avatar";
    },
    avatarClasses() {
      return this.displayAttributes?.avatar?.classes;
    },
    effectiveSource() {
      const result = this.fieldValue?._file || this.fieldValue?.link;
      debug("effectiveSource", result);
      return result;
    },
    effectiveSize() {
      let avatarSize = this.value?.display?.attributes?.avatar?.width;
      const result = getSafeDimensions(avatarSize, "px", "36px");
      debug("effectiveSize", result);
      return result;
    },
  },
  mounted() {
    debug(`displayAttributes`, this.displayAttributes);
    this.resizeContent();
    if (!this.effectiveSource) {
      this.getCharValue("character");
    }
    this.determineEffectiveAttributes();
  },
  methods: {
    determineEffectiveAttributes() {
      const defaultAttributes = {
        contain: true,
        position: "top",
      };
      let overrides = {};
      if (this.effectiveWidth) {
        overrides.width = this.effectiveWidth;
      }
      if (this.maxHeight) {
        overrides["max-height"] = this.maxHeight;
      }
      const result = defaultsDeep(
        overrides,
        this.displayAttributes || this.attributes,
        defaultAttributes
      );
      debug(`effectiveAttributes`, result);
      this.effectiveAttributes = result;
    },
    async getCharValue(key) {
      let methodDebug = debug.extend(`getCharValue${key ? "_" + key : ""}`); // eslint-disable-line
      try {
        let value = await getDynamicValue({
          field: { character: this.displayAttributes?.character },
          key,
          context: this.context,
          store: this.$store,
        });
        debug(`got value`, value);
        const char = (value || " ").charAt(0);
        this.charValue = char.toUpperCase();
      } catch (e) {
        debug(`Unable to resolve value`, e);
      }
    },
    defaultImage(e) {
      if (e?.target?.src) {
        e.target.src = "/no_image.jpg";
      }
    },
    openImage() {
      window.open(this.effectiveSource);
    },
    resizeContent() {
      // Buggy. Card can be anywhere in the heirarchy
      const isInACard = isChildOf(this.$el, ".behavior_list_card");
      const isInATable = isChildOf(this.$el, ".v-data-table");
      const isInAList = isChildOf(this.$el, ".behavior-list-item");
      let maxHeight = this.displayAttributes?.["max-height"] || "";
      debug(`maxHeight in attributes: [${maxHeight}]`);
      if (isInAList) {
        this.effectiveWidth =
          this.getFeatureValue("image_width_in_list") || "150px";
      } else if (isInATable) {
        this.effectiveWidth =
          this.getFeatureValue("image_width_in_table") || "150px";
      } else if (isInACard) {
        this.effectiveWidth =
          this.getFeatureValue("image_width_in_card") || "50%";
        maxHeight =
          maxHeight || this.getFeatureValue("cards.image.height") || "150px";
      }
      if (maxHeight) {
        this.maxHeight = maxHeight;
      }
    },
  },
};
</script>
