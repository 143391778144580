<template>
  <v-container>
    <v-card aut-add-report>
      <v-card-title> Add a new Report </v-card-title>
      <v-card-text>
        <AtmanWizard :steps="steps" @submit="process" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
let i = 1;
const steps = [
  {
    id: i++,
    label: "Name",
    component: () => import("./wizard/ReportName"),
  },
  {
    id: i++,
    label: "Query",
    component: () => import("./wizard/ReportQuery"),
  },
  {
    id: i++,
    label: "List",
    component: () => import("./wizard/ReportList"),
  },
  {
    id: i++,
    label: "Summary",
    component: () => import("./wizard/ReportSummary"),
  },
  {
    id: i++,
    label: "Schedule",
    component: () => import("./wizard/ReportSchedule"),
  },
];
import { STORE_CONSTS, store } from "@/views/applications/report/store";
import { rules, makeServerCall } from "@/util.js";
const debug = require("debug")("atman.components.create_report"); // eslint-disable-line
export default {
  components: {
    AtmanWizard: () => import("@/components/editor/AtmanWizard"),
  },
  name: "CreateReport",
  data() {
    return {
      mandatory: [rules.mandatory()],
      valid: false,
      report: "",
      steps,
    };
  },
  created() {
    const component = this;
    component._createStore(STORE_CONSTS.REPORT_EDITOR_STORE, store);
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async process() {
      const applicationID = (this.application = this.$route.params.id);
      await makeServerCall({
        url: `/applications/${applicationID}?lookup=${this.lookup}`,
        type: "post",
        params: {
          action: "update_fields",
          data: this.lookupValues,
        },
      });
      this.displaySuccessMessage("Lookup created");
      this.$router.go(-1);
    },
  },
};
</script>
