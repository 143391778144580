import { subDays, addDays } from "date-fns";
const debug = require("debug")("atman.compiler"); //eslint-disable-line

const operations = ["+", "-", "/", "%", "*", "AND", "OR", "||", "&&"];
const comparators = ["==", "!=", "<=", "<", ">=", ">"];

const isDate = (input) => {
  const regex =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/g;
  let outcome = regex.test(input);
  debug(`dateValue`, `[${input}]`, `[${outcome}]`);
  return outcome;
};

const isOperator = (input) => operations.includes(input);
const isComparator = (input) => comparators.includes(input);

const hasOperator = (expressionStatement) => {
  const elementsInExpression = expressionStatement.split(" ");
  return elementsInExpression.filter(isOperator).length > 0;
};

const hasComparator = (expressionStatement) => {
  const elementsInExpression = expressionStatement.split(" ");
  return elementsInExpression.filter(isComparator).length > 0;
};

const getOperator = (expressionStatement) => {
  if (!hasOperator(expressionStatement)) {
    return null;
  }
  return expressionStatement.split(" ").filter((expressionElement) => {
    return isOperator(expressionElement);
  })[0];
};

const getComparator = (expressionStatement) => {
  if (!hasComparator(expressionStatement)) {
    return null;
  }
  return expressionStatement.split(" ").filter((expressionElement) => {
    return isComparator(expressionElement);
  })[0];
};

const performComparison = (expressionStatement) => {
  const comparator = getComparator(expressionStatement);
  const elementsInExpression = expressionStatement.split(comparator);
  debug(
    `In performComparison`,
    `expressionStatement: [${expressionStatement}]`,
    `comparator: [${comparator}]`,
    elementsInExpression
  );

  let lhs = evaluate(elementsInExpression[0]);
  let rhs = evaluate(elementsInExpression[1]);

  if (!isNaN(lhs)) {
    lhs = Number(lhs);
  }
  if (!isNaN(rhs)) {
    rhs = Number(rhs);
  }

  switch (comparator) {
    case "==": {
      return lhs == rhs;
    }
    case "!=": {
      return lhs != rhs;
    }
    case "<=": {
      return lhs <= rhs;
    }
    case "<": {
      return lhs < rhs;
    }
    case ">=": {
      return lhs >= rhs;
    }
    case ">": {
      return lhs > rhs;
    }
  }
};

const isADateOperation = (lhs, rhs) => {
  const lhsIsDate = isDate(lhs);
  const rhsIsDate = isDate(rhs);
  const numberValue = lhsIsDate ? rhs * 1 : lhs * 1;
  debug(
    `isADateOperation`,
    `${lhs} is date: ${lhsIsDate}`,
    `${rhs} is date: ${rhsIsDate}`,
    `numberValue: ${numberValue}`
  );
  return (lhsIsDate || rhsIsDate) && !isNaN(numberValue);
};

const performDateAddition = (lhs, rhs) => {
  const lhsIsDate = isDate(lhs);
  const dateValue = lhsIsDate ? new Date(lhs) : new Date(rhs);
  const incrementValue = (lhsIsDate ? rhs : lhs) * 1;
  if (isNaN(incrementValue)) {
    return null;
  }
  return addDays(dateValue, incrementValue).toISOString();
};

const performDateSubtraction = (lhs, rhs) => {
  const lhsIsDate = isDate(lhs);
  const dateValue = lhsIsDate ? new Date(lhs) : new Date(rhs);
  const decrementValue = (lhsIsDate ? rhs : lhs) * 1;
  if (isNaN(decrementValue)) {
    return null;
  }
  return subDays(dateValue, decrementValue).toISOString();
};

const isANumericalOperation = (lhs, rhs) => {
  // If both are numbers, perform the numerical operation
  lhs = lhs * 1;
  rhs = rhs * 1;
  return !isNaN(lhs) && !isNaN(rhs);
};

const getLHS = (expressionStatement = "", operator) => {
  const elementsInExpression = expressionStatement.split(" " + operator + " ");
  return (elementsInExpression[0] || "").trim();
};

const getRHS = (expressionStatement = "", operator) => {
  const elementsInExpression = expressionStatement.split(" " + operator + " ");
  return (elementsInExpression[1] || "").trim();
};

const performOperation = (expressionStatement) => {
  const operator = getOperator(expressionStatement);
  let lhs = getLHS(expressionStatement, operator);
  let rhs = getRHS(expressionStatement, operator);
  if (lhs == "" || rhs == "") {
    return null;
  }

  switch (operator) {
    case "+": {
      debug(`operator`, "+");

      if (isADateOperation(lhs, rhs)) {
        return performDateAddition(lhs, rhs);
      }

      if (isANumericalOperation(lhs, rhs)) {
        return lhs * 1 + rhs * 1;
      }
      debug(`In operation +. Datatype is invalid. Returning null`);
      return null;
    }
    case "-": {
      debug(`operator`, "-");

      if (isADateOperation(lhs, rhs)) {
        return performDateSubtraction(lhs, rhs);
      }

      if (isANumericalOperation(lhs, rhs)) {
        return lhs * 1 - rhs * 1;
      }
      debug(`In operation -. Datatype is invalid. Returning null`);
      return null;
    }
    case "*": {
      debug(`operator`, "*");
      if (isANumericalOperation(lhs, rhs)) {
        return lhs * 1 * (rhs * 1);
      }
      debug(`In operation *. Datatype is invalid. Returning null`);
      return null;
    }
    case "/": {
      debug(`operator`, "/");
      if (!isANumericalOperation(lhs, rhs)) {
        debug(`In operation *. Datatype is invalid. Returning null`);
        return null;
      }
      if (rhs * 1 === 0) {
        return Infinity;
      }
      return (lhs * 1) / (rhs * 1);
    }
    case "%": {
      debug(`operator`, "%");
      if (!isANumericalOperation(lhs, rhs)) {
        debug(`In operation *. Datatype is invalid. Returning null`);
        return null;
      }
      return (lhs * 1) % (rhs * 1);
    }
    case "AND":
    case "&&": {
      debug(`operator`, "&&");
      lhs = evaluate(lhs);
      rhs = evaluate(rhs);

      return lhs && rhs ? true : false;
    }
    case "OR":
    case "||": {
      debug(`operator`, "||");
      lhs = evaluate(lhs);
      rhs = evaluate(rhs);

      return lhs || rhs ? true : false;
    }
  }
};

const getInnerExpressions = (expressionStatement) => {
  // https://regex101.com/r/1OW4HD/2
  let regex = /(?<expression>\((?:[\s+\-*\/\%\d\w]+|\([\s+\-*\/\%\d\w]+\))*\))/g; // eslint-disable-line
  let matches = expressionStatement.match(regex);
  return matches;
};

const cleanStatement = (input = "") => {
  let expressionStatement = input;
  // Remove spaces on either side
  expressionStatement = expressionStatement.trim();

  // IF the statement is "(statement)", make it "statement"
  const re = /^\(.*\)$/;
  const matches = expressionStatement.match(re);
  if (matches && matches.length) {
    expressionStatement = expressionStatement.substring(
      1,
      expressionStatement.length - 1
    );
  }
  // Replace more than one space with a single space
  expressionStatement = expressionStatement.replace(/\s\s+/g, " ");
  const result = expressionStatement.trim();

  return result;
};

const splitCompoundStatements = (expressionStatement) => {
  const regex = /(?<expression>\([\w\s!=+\-*]+(?!\()\))/g;
  const matches = expressionStatement.match(regex);
  return matches;
};

const evaluate = (rawInput, debugKey) => {
  let methodDebug = debug.extend(`evaluate${debugKey ? '_' + debugKey :''}`); // eslint-disable-line
  methodDebug(`About to evaluate: [${rawInput}]`);
  let expressionStatement = rawInput;
  let innerExpressions = splitCompoundStatements(expressionStatement);
  if (innerExpressions && innerExpressions.length > 1) {
    innerExpressions.forEach((innerExpression) => {
      expressionStatement = expressionStatement.replace(
        innerExpression,
        evaluate(innerExpression)
      );
    });
    methodDebug(`reduced expression: [${expressionStatement}]`);
    return evaluate(expressionStatement);
  }

  expressionStatement = cleanStatement(rawInput);
  methodDebug(
    `after cleanStatement`,
    `rawInput: [${rawInput}]`,
    `input: [${expressionStatement}]`
  );

  methodDebug(`expressionStatement`, expressionStatement);

  let result;

  innerExpressions = getInnerExpressions(expressionStatement);
  methodDebug(`innerExpressions`, innerExpressions);
  if (innerExpressions && innerExpressions.length) {
    methodDebug(`Evaluating inner expressions`);
    innerExpressions.forEach((innerExpression) => {
      expressionStatement = expressionStatement
        .split(innerExpression)
        .join(evaluate(innerExpression));
    });
  }

  // IF the statement contains a comparison, perform it
  if (hasComparator(expressionStatement)) {
    result = performComparison(expressionStatement);
    methodDebug(`Evaluating comparison`, result);
  }
  // IF the statement contains an operation, perform it
  else if (hasOperator(expressionStatement)) {
    result = performOperation(expressionStatement);
    methodDebug(`Evaluating operation`, result);
  } else {
    // IF neither, just return the expression statement as is
    switch (expressionStatement) {
      case "true": {
        result = true;
        break;
      }
      case "false": {
        result = false;
        break;
      }
      default:
        result = expressionStatement;
    }
  }
  methodDebug(`returning`, result);
  return result;
};

const isAnExpression = (input = "") => {
  if (typeof input != "string" || input.indexOf("->") != -1) {
    return false;
  }
  return input[0] == "{" && input[input.length - 1] == "}";
};

const getExpression = (input = "") => {
  if (!isAnExpression(input)) {
    return null;
  }
  return input.substring(1, input.length - 1);
};

export {
  isDate,
  isOperator,
  isComparator,
  hasOperator,
  hasComparator,
  getOperator,
  getComparator,
  evaluate,
  getInnerExpressions,
  cleanStatement,
  performComparison,
  performOperation,
  isAnExpression,
  getExpression,
  splitCompoundStatements,
};
