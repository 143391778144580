<template>
  <v-card aut-application-editor-page>
    <v-card-title> Add a new Application </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              aut-application-name
              label="Name *"
              hint="The Application Name"
              persistent-hint
              v-model="application"
              required
              :rules="mandatory"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              aut-application-description
              label="Description"
              v-model="description"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="cancel" aut-action-add-application-cancel> Cancel </v-btn>
      <v-btn
        color="primary"
        :disabled="!valid"
        @click="process"
        aut-action-add-application
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { STORE_CONSTS } from "./store";
import application_mixin from "../application_mixin";
export default {
  name: "AWStep1",
  mixins: [application_mixin],
  methods: {
    async process() {
      const response = await this.createApplication();
      this.$store.commit(
        `${STORE_CONSTS.APPLICATION_WIZARD_STORE}/${STORE_CONSTS.MUTATIONS.DEFINITION}`,
        response.data
      );
      this.$emit("next");
    },
  },
};
</script>
