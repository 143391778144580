import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
export default {
  label: "Month",
  type: "month",
  description: "Displays a month field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description: "The default Month field.",
      label: "Default",
      value: {
        name: "month",
        label: "Month",
        type: "month",
      },
    },
  ],
  attributes: [no_value_text, no_value_behavior],
};
