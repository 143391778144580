<template>
  <v-container fluid style="width: 65%">
    <BareBonesApplicationVue v-if="isSimple" />
    <ApplicationWizard v-else-if="isWizard" />
    <BareBonesApplicationVue mode="file" v-else-if="isFile" />
  </v-container>
</template>
<script>
import { set } from "lodash";
import BareBonesApplicationVue from "./BareBonesApplication.vue";
import ApplicationWizard from "./application_wizard/ApplicationWizard.vue";
import { mapActions } from "vuex";

const debug = require("debug")("atman.view.application_creator"); // eslint-disable-line

export default {
  name: "ApplicationCreator",
  components: {
    BareBonesApplicationVue,
    ApplicationWizard,
  },
  computed: {
    isFile() {
      return this.$route?.query?.mode == "file";
    },
    isSimple() {
      return (
        this.$route?.query?.mode != "wizard" &&
        this.$route?.query?.mode != "file"
      );
    },
    isWizard() {
      return this.$route?.query?.mode == "wizard";
    },
  },
  mounted() {
    this.markAsDisplayed();
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    markAsDisplayed() {
      const settings = {};
      set(settings, "application_creation.shown", true);
      this.saveSettingsToDomain(settings);
    },
  },
};
</script>
