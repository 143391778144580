<template>
  <div>
    <v-autocomplete
      v-if="isInputMode"
      aut_icon_input
      dense
      small
      :items="icons"
      label="Icon"
      hint="Choose an icon"
      persistent-hint
      item-value="name"
      item-text="name"
      @keydown.enter.prevent="dummyMethod"
      v-model="icon"
      @change="$emit('change', fieldValue)"
    >
      <template v-slot:item="{ item }">
        <span>{{ item.name }}</span>
        <v-icon>mdi-{{ item.name }}</v-icon>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ item.name }}</span>
        <v-icon>mdi-{{ item.name }}</v-icon>
      </template>
    </v-autocomplete>
    <v-icon
      v-if="fieldValue && fieldValue.icon && isDisplayMode"
      :color="fieldValue.color"
    >
      {{ fieldValue.icon }}
    </v-icon>
  </div>
</template>

<script>
import icons from "./icons.json";
import { defaultsDeep } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.icon");
debug("icon");
export default {
  name: "IconField",
  mixins: [fieldMixin],
  created() {
    this.icons = icons;
  },
  computed: {
    icon: {
      get() {
        if (!this?.fieldValue?.icon) {
          return "";
        }
        return this.fieldValue.icon
          .split(" ")
          .filter((icon) => icon != "mdi")
          .join("")
          .split("mdi-")[1];
      },
      set(value) {
        this.fieldValue = defaultsDeep(
          {},
          { icon: `mdi-${value}` },
          this.fieldValue
        );
      },
    },
  },
  mounted() {
    if (this.fieldValue == "") {
      this.fieldValue = {};
    }
  },
  methods: {
    dummyMethod() {
      debug("dummy method");
    },
  },
};
</script>
