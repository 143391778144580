<template>
  <div aut-administration-home-page aut-home-page>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails && currentContext"
      :page="flowDetails"
      :context="currentContext"
    />
  </div>
</template>

<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";
import home_page from "./home_page.js";
const debug = require("debug")("atman.components.administration_home_page"); // eslint-disable-line
export default {
  name: "AdministrationHome",
  mixins: [systemPageMixin],
  data() {
    return {};
  },
  mounted() {
    this.renderPage();
  },
  methods: {
    renderPage() {
      const component = this;
      const url = `/administration?page=home`;
      component.currentContext = createContext(url);
      component.flowDetails = clone(home_page);
    },
  },
};
</script>
