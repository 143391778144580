<template>
  <div>
    <v-menu
      ref="menu"
      v-if="!isDisplayMode"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="month"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="displayMonth"
          aut-month-field
          :label="value.label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="month"
        type="month"
        no-title
        scrollable
        @input="$refs.menu.save(month)"
        :aut-month-picker="value.name"
      >
      </v-date-picker>
    </v-menu>
    <div v-if="isDisplayMode" class="text-left">
      <label v-if="label" class="text-left"> {{ label }}: </label>
      <span class="text-left">{{ displayMonth }}</span>
    </div>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
const months = Array.from({ length: 12 }, (item, i) => {
  return new Date(0, i).toLocaleString("default", { month: "short" });
});

export default {
  name: "MonthPicker",
  mixins: [fieldMixin],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    month: {
      get() {
        if (this.fieldValue) {
          try {
            return new Date(this.fieldValue).toISOString().substring(0, 7);
          } catch (e) {
            console.error("Invalid Date", e);
            return new Date().toISOString().substring(0, 7);
          }
        }
        return new Date().toISOString().substring(0, 7);
      },
      set(selectedMonth) {
        this.fieldValue = new Date(selectedMonth).toISOString();
      },
    },
    displayMonth() {
      if (!this.fieldValue) {
        return "";
      }
      const [year, monthIndex] = this.month.split("-");
      return `${months[parseInt(monthIndex) - 1]} ${year}`;
    },
  },
};
</script>
