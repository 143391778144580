<template>
  <v-card
    :aut-workflow-node="block.name"
    :color="block.display.background_color"
    elevation="1"
    height="100"
    :width="width"
    class="workflowNode"
    :class="{ selected: block.selected }"
    :style="style"
  >
    <header :style="headerStyle">
      <div class="pt-2 d-flex px-1">
        <v-icon
          :class="`${block.display.text_color}--text`"
          v-if="block.display.icon"
          >mdi {{ block.display.icon }}</v-icon
        >
        <v-img
          v-if="block.display.image"
          :src="block.display.image"
          contain
          min-width="20"
          width="20"
          max-width="20"
        />
        <span :class="`ml-1 text-left ${block.display.text_color}--text`">{{
          block.display.label
        }}</span>
        <v-spacer />
        <div class="float-right grey--text mt-n1">
          <EditorAction :action="designAction" @remove_action="deleteBlock" />
        </div>
      </div>
    </header>
    <v-divider />
    <InputSlots
      :inputs="block.inputs"
      :display="block.display"
      @linking-stop="linkingStop"
      @linking-break="linkingBreak"
    />
    <OutputSlots
      :display="block.display"
      :outputs="block.outputs"
      @linking-start="linkingStart"
    />
  </v-card>
</template>

<script>
import EditorAction from "@/components/editor/EditorActions/EditorAction.vue";
import InputSlots from "../InputSlots";
import OutputSlots from "../OutputSlots";
const debug = require("debug")("atman.components.rectangular_node");
debug(`rectangular_node`);
export default {
  name: "RectangularNode",
  props: {
    block: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  components: {
    InputSlots,
    OutputSlots,
    EditorAction,
  },
  created() {
    this.mouseX = 0;
    this.mouseY = 0;

    this.lastMouseX = 0;
    this.lastMouseY = 0;

    this.linking = false;
    this.dragging = false;
    this.designAction = {
      icon: "mdi-delete",
      id: "remove_action",
      confirmation: "Are you sure you want to continue?",
      label: "Remove Action",
      event: "remove_action",
    };
  },
  mounted() {
    document.documentElement.addEventListener(
      "mousemove",
      this.handleMove,
      true
    );
    document.documentElement.addEventListener(
      "mousedown",
      this.handleDown,
      true
    );
    document.documentElement.addEventListener("mouseup", this.handleUp, true);
  },
  beforeDestroy() {
    document.documentElement.removeEventListener(
      "mousemove",
      this.handleMove,
      true
    );
    document.documentElement.removeEventListener(
      "mousedown",
      this.handleDown,
      true
    );
    document.documentElement.removeEventListener(
      "mouseup",
      this.handleUp,
      true
    );
  },
  data() {
    return {
      width: this.block.display.width,
      hasDragged: false,
    };
  },
  methods: {
    handleMove(e) {
      this.mouseX = e.pageX || e.clientX + document.documentElement.scrollLeft;
      this.mouseY = e.pageY || e.clientY + document.documentElement.scrollTop;

      if (this.dragging && !this.linking) {
        let diffX = this.mouseX - this.lastMouseX;
        let diffY = this.mouseY - this.lastMouseY;

        this.lastMouseX = this.mouseX;
        this.lastMouseY = this.mouseY;

        this.moveWithDiff(diffX, diffY);

        this.hasDragged = true;
      }
    },
    handleDown(e) {
      this.mouseX = e.pageX || e.clientX + document.documentElement.scrollLeft;
      this.mouseY = e.pageY || e.clientY + document.documentElement.scrollTop;

      this.lastMouseX = this.mouseX;
      this.lastMouseY = this.mouseY;

      const target = e.target || e.srcElement;
      if (this.$el.contains(target) && e.which === 1) {
        this.dragging = true;

        this.$emit("select");

        if (e.preventDefault) e.preventDefault();
      }
    },
    handleUp() {
      if (this.dragging) {
        this.dragging = false;

        if (this.hasDragged) {
          this.save();
          this.hasDragged = false;
        }
      }

      if (this.linking) {
        this.linking = false;
      }
    },
    // Slots
    linkingStart(index) {
      this.linking = true;
      this.$emit("linkingStart", index);
    },
    linkingStop(index) {
      this.$emit("linkingStop", index);
    },
    linkingBreak(index) {
      this.linking = true;
      this.$emit("linkingBreak", index);
    },
    save() {
      this.$emit("update");
    },
    deleteBlock() {
      this.$emit("delete");
    },
    moveWithDiff(diffX, diffY) {
      this.block.display.x = this.block.display.x + diffX / this.options.scale;
      this.block.display.y = this.block.display.y + diffY / this.options.scale;

      this.$emit("update:block", this.block);
      // this.$emit("update:y", top);
    },
  },
  computed: {
    imageHeight() {
      return this.block.display.titleHeight / 2;
    },
    style() {
      return {
        top:
          this.options.center.y +
          this.block.display.y * this.options.scale +
          "px",
        left:
          this.options.center.x +
          this.block.display.x * this.options.scale +
          "px",
        width: this.width + "px",
        transform: "scale(" + (this.options.scale + "") + ")",
        transformOrigin: "top left",
      };
    },
    headerStyle() {
      return {
        height: this.block.display.titleHeight + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style.scss";
</style>
