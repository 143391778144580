<template>
  <div>
    <div v-if="isDisplayMode" class="text-left">
      <label v-if="label"> {{ label }}: </label>
      <span aut-field-value>{{ fieldValue | password }}</span>
    </div>
    <div v-else>
      <v-text-field
        aut-field-value
        aut-password-1
        :append-icon="showContent ? 'mdi-eye' : 'mdi-eye-off'"
        :name="value.name"
        @focus="onFocus"
        @blur="[_onChange($event)]"
        v-model="fieldValue"
        :error="hasError"
        :error-messages="errorMessage"
        :label="label"
        :type="showContent ? 'text' : 'password'"
        :rules="applicableRules"
        counter
        v-bind="displayAttributes"
        v-bind:class="displayClasses"
        :hint="value.hint"
        :required="value.mandatory"
        @click:append="showContent = !showContent"
      ></v-text-field>
      <v-text-field
        v-if="isConfirmMode"
        @focus="onFocus"
        @blur="[_onChange($event)]"
        aut-password-confirm
        :append-icon="showContent2 ? 'mdi-eye' : 'mdi-eye-off'"
        :name="`${this.value.name}_confirm_password`"
        v-model="confirmPassword"
        label="Confirm Password *"
        :type="showContent2 ? 'text' : 'password'"
        :rules="confirmPasswordRules"
        v-bind="displayAttributes"
        v-bind:class="displayClasses"
        :hint="value.hint"
        :required="value.mandatory"
        @click:append="showContent2 = !showContent2"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import { rules } from "@/util";
import definition from "./definition";
const debug = require("debug")("atman.components.password");
debug("password");

function getRandomInt() {
  const min = Math.ceil(10);
  const max = Math.floor(15);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export default {
  filters: {
    password: function (value) {
      if (!value) return "";
      let result = "";
      const pwdLength = getRandomInt();
      for (let i = 0; i < pwdLength; i++) {
        result += "*";
      }
      return result;
    },
  },
  name: "PasswordField",
  mixins: [fieldMixin],
  data() {
    return {
      definition,
      showContent: false,
      showContent2: false,
      confirmPassword: "",
      confirmKey: 0,
      confirmPasswordRules: [
        rules.mandatory(),
        (val) => val == this.fieldValue || "Mismatch between passwords",
      ],
    };
  },
  computed: {
    isConfirmMode() {
      return this.displayAttributes?.mode == "confirm";
    },
  },
  methods: {
    savePassword(password) {
      this.fieldValue = password;
    },
  },
};
</script>
