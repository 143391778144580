<template>
  <div>
    <v-row v-if="showEditDialog == false">
      <div class="d-flex">
        <div>{{ getCheckDescription(check.fact, check.path) }}</div>
        <div class="ml-1">{{ getOperatorLabel(check.operator) }}</div>
        <div class="ml-1">{{ getCheckValue(check.fact, check.value) }}</div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="showEditDialog = true"
              >mdi-pencil</v-icon
            >
          </template>
          Edit
        </v-tooltip>
      </div>
    </v-row>
    <v-dialog v-model="showEditDialog" @click:outside="showEditDialog = false">
      <v-card>
        <DialogTitle @close="showEditDialog = false" title="Edit Check" />
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="3">
              <v-autocomplete
                dense
                label="Fact"
                item-text="label"
                item-value="id"
                hint="Type of fact"
                persistent-hint
                clearable
                v-model="check.fact"
                :items="factTypes"
                @blur="updateCheck(check)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                label="Variable"
                clearable
                hint="Path to variable"
                persistent-hint
                v-model="check.path"
                @blur="updateCheck(check)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                dense
                label="Operator"
                item-text="label"
                item-value="id"
                hint="Type of comparison"
                persistent-hint
                clearable
                v-model="check.operator"
                :items="comparatorTypes"
                @blur="updateCheck(check)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                dense
                label="Value"
                hint="Value to compare against"
                persistent-hint
                :type="check.fact == 'stats' ? 'number' : 'text'"
                clearable
                v-model="check.value"
                @blur="updateCheck(check)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="showEditDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";

const debug = require("debug")(
  "atman.components.questionnaire.designer.rule_check"
); // eslint-disable-line
export default {
  name: "RuleCheck",
  mixins: [dialogMixin],
  props: {
    check: {},
  },
  data() {
    return {
      showEditDialog: false,
    };
  },
  created() {
    this.factTypes = [
      {
        id: "questionnaire",
        label: "Questionnaire",
      },
      {
        id: "stats",
        label: "Stats",
      },
    ];
    this.comparatorTypes = [
      {
        id: "equal",
        label: "equals",
        hint: "[String/Number] ",
      },
      {
        id: "notEqual",
        label: "does not equal",
        hint: "[String/Number]",
      },
      {
        id: "lessThan",
        label: "is less than",
        hint: "[Number]",
      },
      {
        id: "lessThanInclusive",
        label: "is less than (inclusive)",
        hint: "[Number] Less than (inclusive)",
      },
      {
        id: "greaterThanInclusive",
        label: "is greater than (inclusive)",
        hint: "[Number] Greater than (Inclusive)",
      },
      {
        id: "greaterThan",
        label: "greater than",
        hint: "[Number] Greater than",
      },
      {
        id: "in",
        label: "is included in",
        hint: "[Array] must be included in",
      },
      {
        id: "notIn",
        label: "is not included in",
        hint: "[Array] must not be included in",
      },
      {
        id: "contains",
        label: "contains",
        hint: "[Array] must contain",
      },
      {
        id: "doesNotcontain",
        label: "does not contain",
        hint: "[Array] must not contain",
      },
    ];
  },
  methods: {
    updateCheck() {
      debug(`in updateCheck`);
    },
    getCheckValue(fact, value) {
      let result = "";
      if (fact == "questionnaire") {
        result = `"${value}"`;
      } else {
        result = `${value}`;
      }
      return result;
    },
    getCheckDescription(fact, path) {
      let effectivePath = path.replace("$", fact);
      let result = "";
      if (fact == "questionnaire") {
        result = `The answer to question "${effectivePath}"`;
      } else {
        result = `The value of the stat "${effectivePath}"`;
      }
      return result;
    },
    getOperatorLabel(operator) {
      return this.comparatorTypes.find(({ id }) => id == operator).label;
    },
  },
};
</script>
