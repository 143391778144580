<template>
  <div v-if="media">
    <div v-for="(content, i) in media" :key="'questionMedia' + i">
      <v-img
        v-if="content.type == 'image'"
        v-bind="content.display && content.display.attributes"
        :src="content.src"
        fill
      ></v-img>

      <video
        v-if="content.type == 'video'"
        controls
        v-bind="content.display && content.display.attributes"
        aut-video-embed
      >
        <source :src="content.src" />

        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmbeddedMedia",
  props: {
    media: {},
  },
};
</script>
