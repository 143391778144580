<template>
  <v-dialog
    v-model="display"
    width="400"
    @keydown.esc.stop.prevent="cancel"
    @click:outside="cancel"
  >
    <v-card aut-confirmation-dialog>
      <v-card-title>Confirmation</v-card-title>
      <v-card-text aut-confirmation-text>
        {{ confirmationContent }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" aut-confirmation-cancel> Cancel </v-btn>
        <v-btn color="primary" @click="confirm" aut-confirmation-confirm>
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import { CONFIRMATION_CANCEL, CONFIRMATION_CONFIRM } from "@/store";
const debug = require("debug")("atman.components.confirmation_dialog"); // eslint-disable-line

export default {
  name: "ConfirmationDialog",
  props: {
    action: {
      type: Object,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  watch: {
    displayConfirmation() {
      debug("displayConfirmation updated");
      this.display = this.displayConfirmation;
    },
  },
  computed: {
    ...mapState(["confirmationContent", "displayConfirmation"]),
  },
  methods: {
    cancel() {
      this.$store.commit(CONFIRMATION_CANCEL);
    },
    confirm() {
      this.$store.commit(CONFIRMATION_CONFIRM);
    },
  },
};
</script>
