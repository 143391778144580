<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      aut-state
      :context="context"
      v-if="isTextType"
      :label="label"
      v-model="state"
      :mandatory="isMandatory"
      @change="$emit('update', { state: $event })"
    />
    <AddressInput
      :context="context"
      v-else
      aut-state
      label="State"
      inputType="select"
      :mandatory="isMandatory"
      :items="states"
      noDataText="Please select a Country first"
      v-model="state"
      @change="$emit('update', { state: $event })"
    />
  </v-col>
</template>
<script>
import { getStates } from "@/assets/jsons/countries.js";
import address_mixin from "./address_mixin";
const debug = require('debug')('atman.component.fields.address.state')  // eslint-disable-line
export default {
  name: "AddressState",
  mixins: [address_mixin],
  props: {
    context: {},
    value: {},
    definition: {},
  },
  data() {
    return {
      selectedCountry: "",
      state: this._getValue("state"),
      states: [],
    };
  },
  watch: {
    "value.country"() {
      debug(`in watch`);
      this.determineStates();
    },
    "value.state"() {
      this.state = this.value.state;
    },
  },
  computed: {
    fieldProperties() {
      const configuration = this.definition?.address_fields?.state || {};
      return configuration;
    },
  },
  created() {
    this.defaults = {
      label: "State",
      display: {
        width: "4",
      },
    };
  },
  mounted() {
    this.determineStates();
  },
  methods: {
    determineStates() {
      const selectedCountry = this.value?.country || "";
      if (this.selectedCountry && selectedCountry != this.selectedCountry) {
        this.state = "";
        this.$emit("update", { state: "" });
      }
      this.selectedCountry = selectedCountry;
      if (this.countryHasId) {
        debug(`country has ID`);
        this.states = getStates({ countryCode2: selectedCountry });
      } else {
        debug(`country does not have ID`);
        this.states = getStates({ countryName: selectedCountry });
      }
      debug(`in determine states`, this.states);
    },
  },
};
</script>
