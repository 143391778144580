<template>
  <div aut-applications-page>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails"
      :page="flowDetails"
      :context="currentContext"
      :access_control="access_control"
    />
  </div>
</template>
<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";
import applicationListPage from "./application_list_page.js";
const debug = require("debug")("atman.components.applications_list"); // eslint-disable-line

export default {
  name: "ApplicationsList",
  mixins: [systemPageMixin],
  mounted() {
    this.url = "/applications?page=home";
    this.currentContext = createContext(this.url);
    this.flowDetails = clone(applicationListPage);
  },
};
</script>
