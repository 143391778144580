<template>
  <v-img
    class="behavior_clickable"
    :max-width="logoWidth"
    :lazy-src="no_image"
    @click="goToHomePage"
    :max-height="max_height"
    v-if="displayImage"
    cover
    :src="logoSrc"
    aut-logo
  ></v-img>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { isUnAuthenticated } from "@/util";

const debug = require("debug")("atman.components.site_logo_left"); // eslint-disable-line

export default {
  name: "SiteLogoLeft",
  props: {
    max_height: {},
    container: {
      type: String,
    },
  },
  computed: {
    ...mapState(["skin", "no_image"]),
    logo() {
      if (this.isSmallScreen) {
        return (
          this?.skin?.mobile?.logo || this?.skin?.mobileLogo || this?.skin?.logo
        );
      }
      return this?.skin?.logo;
    },
    favicon() {
      return this?.skin?.favicon || this.logo;
    },
    logoSrc() {
      return this.logo && this?.logo?.image;
    },
    faviconSrc() {
      debug("favicon", this.favicon);
      return this?.favicon?.image;
    },
    logoWidth() {
      const result = this?.logo?.width || "";
      if (this.isSmallScreen) {
        return result || 40;
      }
      return result;
    },
    displayImage() {
      if (!this.logoSrc) {
        debug(`No logo configured`);
        return false;
      }
      if (!this.isSmallScreen) {
        debug(`Not mobile. Will display logo`);
        return true;
      }
      const logoContainerInMobileMode = this.getFeatureValue(
        "logo.mobile.position"
      );
      debug(`mobile. Will display logo in [${logoContainerInMobileMode}]`);
      const result = logoContainerInMobileMode == this.container;
      return result;
    },
  },
  async mounted() {
    debug(`Mounted of Site Logo. props`, this.$props);
    await this.fetchSkin();
    this.updateFavIcon();
  },
  methods: {
    ...mapActions(["fetchSkin"]),
    goToHomePage() {
      if (isUnAuthenticated(this)) {
        return;
      }
      if (this.$route.path != "/") {
        this.$router.push({ path: "/" });
      }
    },
    updateFavIcon() {
      if (!this.faviconSrc) {
        return;
      }
      try {
        document
          .querySelector("#atmanFavIcon")
          .setAttribute("href", this.faviconSrc);
      } catch (e) {
        debug(`could not update favicon`, e);
      }
    },
  },
};
</script>
