import Vue from "vue";
import { format, parseISO } from "date-fns";

const dateFormat = "yyyy-MM-dd";

const safeFunc = (doFun) => {
  try {
    return doFun();
  } catch (e) {
    return "";
  }
};

const formatDate = (date, date_format) => {
  return safeFunc(() => {
    return date_format ? format(date, date_format) : format(date, dateFormat);
  });
};

Vue.filter("date", (value, options = {}) => {
  const { today, date_format } = options || {};

  if (!value) {
    return today ? formatDate(new Date()) : "";
  }

  value = value.toString();
  let result = "";

  result = formatDate(parseISO(value));
  if (!result) {
    result = formatDate(new Date(value));
    if (!result && today) {
      result = formatDate(new Date());
    }
  } else if (date_format) {
    result = formatDate(parseISO(value), date_format);
  }
  return result;
});
