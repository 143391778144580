<template>
  <div v-if="isTabMode">
    <atman-tabs v-model="tab" @change="tab = $event" grow show-arrows>
      <v-tab
        v-for="(field, i) in filteredFields"
        :key="i"
        class="behaviour_tab"
        :aut-tab="field.name"
        :ref="`tab_${field.name}`"
      >
        {{ field.label }}
      </v-tab>
    </atman-tabs>

    <atman-tabs-items v-model="tab">
      <v-tab-item v-for="(field, index) in filteredFields" :key="field.name">
        <v-container>
          <v-row
            dense
            class="mt-0 d-flex flex-shrink-1 flex-grow-1"
            v-if="filteredFields.length"
          >
            <v-col cols="12">
              <slot
                v-bind:field="getDefinitionForField(field)"
                v-bind:index="index"
              ></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </atman-tabs-items>
  </div>
  <v-expansion-panels
    v-else-if="isPanelMode"
    v-model="panel"
    multiple
    aut-panel
  >
    <v-expansion-panel
      v-for="(field, index) in filteredFields"
      :key="index"
      :ref="`panel_${field.name}`"
    >
      <v-expansion-panel-header>
        <span
          :aut-panel-label="field.label"
          class="font-weight-bold primary--text"
          >{{ field.label }}</span
        >
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot
          v-bind:field="getDefinitionForField(field)"
          v-bind:index="index"
        ></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card
    v-else-if="isCardMode"
    :class="attributes.classes"
    v-bind="attributes"
  >
    <v-card-title>
      <FieldLabel
        v-if="label"
        :mode="isDisplayMode ? 'display' : 'input'"
        :label="label"
        :attributes="labelAttributes"
      />
    </v-card-title>
    <v-card-text>
      <v-row
        dense
        class="mt-0 d-flex flex-shrink-1 flex-grow-1"
        v-if="fields.length"
      >
        <v-col
          :class="getClassesForField(field)"
          :cols="getColumnWidth(field)"
          v-for="(field, index) in filteredFields"
          :key="field.name"
        >
          <slot
            v-bind:field="getDefinitionForField(field)"
            v-bind:index="index"
          ></slot>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <div v-else>
    <FieldLabel
      v-if="showLabel"
      :mode="isDisplayMode ? 'display' : 'input'"
      :label="label"
      :attributes="labelAttributes"
    >
    </FieldLabel>
    <v-row
      dense
      class="mt-0 d-flex flex-shrink-1 flex-grow-1"
      v-if="fields.length"
    >
      <v-col
        :class="getClassesForField(field)"
        :cols="getColumnWidth(field)"
        v-for="(field, index) in filteredFields"
        :key="field.name"
      >
        <slot
          v-bind:field="getDefinitionForField(field)"
          v-bind:index="index"
        ></slot>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { isChildOf } from "@/util.js";
import { getWidth } from "@/components/fields/util.js";
const debug = require("debug")("atman.components.field.object.layout"); // eslint-disable-line
export default {
  name: "ObjectLayout",
  props: {
    value: {
      type: Object,
    },
    attributes: {
      type: Object,
    },
    fields: {
      type: Array,
    },
    label: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      tab: null,
      hiddenFields: [],
      panel: [],
    };
  },
  components: {},
  computed: {
    showLabel() {
      // Avoid duplicate label displayed for an object nested inside an expansion panel
      return (
        this.label &&
        this.$el &&
        !isChildOf(this.$el, ".behavior_object_expansion_panel")
      );
    },
    filteredFields() {
      // HACK. rendering hidden fields causes a heavy lag
      return this.fields.filter((field) => field.mode != "ignored");
    },
    isDisplayMode() {
      return this.mode == "display";
    },
    isTabMode() {
      return this.attributes?.layout == "tab";
    },
    isCardMode() {
      return this.attributes?.layout == "card";
    },
    isPanelMode() {
      return this.attributes?.layout == "panel";
    },
    labelAttributes() {
      return this.attributes?.label || {};
    },
  },
  mounted() {
    this.expandPanel();
  },
  methods: {
    hideField(field) {
      if (this.isTabMode) {
        this.hideTab(field);
      }
      if (this.isPanelMode) {
        this.hidePanel(field);
      }
    },
    hideTab(field) {
      debug(`in hideTab`, field.name);
      this.$refs[`tab_${field.name}`][0].$el.classList.add("hidden");
    },
    hidePanel(field) {
      debug(`in hidePanel`, field.name, this.$refs[`panel_${field.name}`]);
      this.$refs[`panel_${field.name}`][0].$el.classList.add("hidden");
    },
    showTab(field) {
      debug(`in showTab`, field.name);
      this.$refs[`tab_${field.name}`][0].$el.classList.remove("hidden");
    },
    showPanel(field) {
      debug(`in showPanel`, field.name, this.$refs[`panel_${field.name}`]);
      this.$refs[`panel_${field.name}`][0].$el.classList.remove("hidden");
    },
    getDefinitionForField(field) {
      const objectValue = this.value?.value;
      const fieldValue = objectValue?.[field.name];
      let result = field;
      if (fieldValue) {
        result = Object.assign({}, field, {
          value: fieldValue,
        });
      }
      debug(`getDefinitionForField`, JSON.stringify(result));
      return result;
    },
    getClassesForField(field) {
      const width = this.getColumnWidth(field);
      if (width == "12") {
        return "pa-0";
      }
      return "pr-1";
    },
    expandPanel() {
      let panelArray = [];
      if (this.isPanelMode) {
        const fields = this.value?.fields;
        let fieldLength = Object.keys(fields).length;
        if (this.attributes?.expanded_panel == "first") {
          fieldLength = 1;
        } else if (this.attributes?.expanded_panel == "none") {
          fieldLength = 0;
        }
        for (let i = 0; i < fieldLength; i++) {
          panelArray.push(i);
        }
        this.panel = panelArray;
        return this.panel;
      }
      return [];
    },
    isBlockMode(field) {
      return field?.display?.block;
    },
    getColumnWidth(field) {
      const isAMobileFormFactor = this.isSmallScreen;
      const isBlockDisplay = this.isBlockMode(field);

      debug(
        `isAMobileFormFactor`,
        isAMobileFormFactor,
        `isBlockDisplay`,
        isBlockDisplay
      );
      if (isAMobileFormFactor || isBlockDisplay) {
        return 12;
      }
      const isInsideATableCell = this.$el && isChildOf(this.$el, "td");
      const closestTableCell = this.$el && this.$el.closest("td");
      if (
        isInsideATableCell &&
        !closestTableCell.classList.contains("behavior-table-footer")
      ) {
        return true;
      }
      return getWidth(field);
    },
  },
};
</script>
<style scoped>
.behaviour_tab {
  font-size: 14px;
}
</style>
