import {
  customiseAttribute,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";
import { defaultsDeep, forOwn, isPlainObject, mapValues, pickBy } from "lodash";
const debug = require('debug')('atman.field.address:definition')  // eslint-disable-line

const hasFields = (definition) => {
  const fields = definition.address_fields || {};
  return !!Object.keys(fields).length;
};

const getValue = (definition, data = {}) => {
  if (!hasFields(definition)) {
    return data[definition.name];
  }
  let result = {};
  forOwn(definition.address_fields, (value, key) => {
    let path = value?.name || key;
    let keyValue = data?.[path];
    if (keyValue) {
      result[key] = keyValue;
    }
  });
  return result;
};

export { hasFields };

export default {
  label: "Address",
  type: "address",
  description: "Displays an Address",
  properties: ["name", "label", "type", "mode", "display", "block"],
  search: ({ key, searchData, resultObj }) => {
    debug(key, searchData, resultObj);

    let meetsCriteria = true;
    const searchValue = searchData[key];

    if (isPlainObject(searchValue)) {
      const trimmedValues = mapValues(searchValue, (value) => {
        if (typeof value != "string") {
          return "";
        }

        return value.trim();
      });

      const searchableValue = pickBy(trimmedValues, (value) => value.length);

      const keys = Object.keys(searchableValue);
      if (keys.length) {
        const currentValues = resultObj[key];

        if (isPlainObject(currentValues)) {
          meetsCriteria = keys.every((key) => {
            const termToSearch = searchableValue[key].toLowerCase();
            const searchIn = (currentValues[key] || "").toLowerCase();
            if (!searchIn) {
              return true;
            }

            return searchIn.includes(termToSearch);
          });
        }
      }
    }
    return meetsCriteria;
  },
  searchField: (col, data = {}) => {
    const result = defaultsDeep(
      {
        mode: "input",
        label: col.label,
        display: {
          classes: ["pt-1"],
          attributes: {
            is_search: true,
          },
          width: "12",
        },
        address_fields: {
          city: {
            display: {
              width: 3,
              attributes: {
                clearable: true,
              },
            },
          },
          district: {
            display: {
              width: 3,
            },
          },
          state: {
            display: {
              width: 3,
            },
          },
          pin_code: {
            display: {
              width: 3,
              attributes: {
                clearable: true,
              },
            },
          },
          country: {
            mode: "hidden",
            value: "India",
          },
        },
      },
      col
    );
    delete result.address_fields.address_line_1;
    delete result.address_fields.address_line_2;
    result.value = getValue(result, data);
    debug(`address search field `, result);

    return result;
  },
  templates: [
    {
      id: "default",
      description: "The default Address field",
      label: "Default",
      variables: {
        data: {
          default: {
            /* address_line_1: "#111, Est consectetur et est velit qui et",
            address_line_2: "Dolore aute euesse laborum officia esse",
            city: "Bengaluru",
            district: "Bengaluru",
            state: "KA",
            pin_code: "560023",
            country: "India", */
          },
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "default",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
      },
    },
    {
      id: "display_address",
      description:
        "Display mode for Address field. Use label attributes and set <code>mode</code> to <code>hidden</code> to hide label.",
      label: "Display",
      variables: {
        data: {
          display_address: {
            address_line_1: "#111, Est consectetur et est velit qui et",
            address_line_2: "Dolore aute euesse laborum officia esse",
            city: "Bengaluru",
            district: "Bengaluru",
            state: "KA",
            pin_code: "560023",
            country: "India",
          },
        },
      },
      value: {
        label: "Address",
        type: "address",
        name: "display_address",
        mode: "display",
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
        },
      },
    },
    {
      id: "address_from_fields",
      description: `Address field constructed form other fields. Use <code>"fields"</code> object to define the fields`,
      keyword: ["tutorial", "bug"],
      label: "From Fields",
      variables: {
        data: {
          add_line_1: "#111, Est consectetur et est velit qui et",
          add_line_2: "Dolore aute euesse laborum officia esse",
          city: "Bengaluru",
          district: "Bidar",
          state: "Karnataka",
          code: "560023",
          country: "IN",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_from_fields",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "add_line_1",
          },
          address_line_2: {
            name: "add_line_2",
          },
          city: {
            name: "city",
          },
          district: {
            name: "district",
          },
          state: {
            name: "state",
          },
          pin_code: {
            name: "code",
          },
          country: {
            name: "country",
          },
        },
      },
    },
    {
      id: "a2625320-dc61-5a3d-a5ac-cb1bce8f4887display_address",
      description: "Long text will automatically wrap",
      label: "Display (very long text)",
      keyword: ["tutorial", "bug"],
      variables: {
        data: {
          "a2625320-dc61-5a3d-a5ac-cb1bce8f4887display_address": {
            address_line_1:
              "#111, Est consectetur et est velit qui et Cupidatat in amet amet tempor esse est.Consectetur dolore ut veniam mollit nisi do deserunt commodo voluptate adipisicing.",
            address_line_2:
              "Dolore aute euesse laborum officia esse Minim nulla sunt id officia excepteur. Ipsum fugiat velit et incididunt sit magna nisi cillum ea cillum velit dolor et aliquip.",
            city: "Bengaluru",
            state: "KA",
            pin_code: "560023",
            country: "India",
          },
        },
      },
      value: {
        label: "Address",
        type: "address",
        name: "a2625320-dc61-5a3d-a5ac-cb1bce8f4887display_address",
        mode: "display",
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          width: "4",
        },
      },
    },

    {
      id: "nested_value",
      description: "Address from nested fields.",
      label: "Nested Value",
      keyword: ["tutorial", "bug"],
      variables: {
        data: {
          nested_value: null,
          nadd_line_2: "Dolore aute euesse laborum officia esse",
          ncity: "Chennai",
          ncode: "650023",
          ndetail: {
            add_line_1: "#111, Est consectetur et est velit qui et",
            state: "Tamil Nadu",
            country: "IN",
          },
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "nested_value",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "ndetail.add_line_1",
          },
          address_line_2: {
            name: "nadd_line_2",
          },
          city: {
            name: "ncity",
          },
          state: {
            name: "ndetail.state",
          },
          pin_code: {
            name: "ncode",
          },
          country: {
            name: "ndetail.country",
          },
        },
      },
    },
    {
      id: "address_text_input",
      description: `Address field with text inputs`,
      keyword: ["tutorial", "bug"],
      label: "Text Inputs",
      variables: {
        data: {
          taddress_text_input: null,
          tadd_line_1: "#111, Est consectetur et est velit qui et",
          tadd_line_2: "Dolore aute euesse laborum officia esse",
          tcity: "Bengaluru",
          tstate: "KA",
          tcode: "560023",
          tcountry: "India",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_text_input",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: "tadd_line_1",
          address_line_2: "tadd_line_2",
          city: "tcity",
          state: "tstate",
          pin_code: "tcode",
          country: {
            name: "tcountry",
            type: "text",
          },
        },
      },
    },
    {
      id: "background_display_address",
      description:
        "Use display attributes and classes to style the address card",
      label: "Display with Background",
      variables: {
        data: {
          background_display_address: {
            address_line_1: "#111, Est consectetur et est velit qui et",
            address_line_2: "Dolore aute euesse laborum officia esse",
            city: "Bengaluru",
            state: "KA",
            pin_code: "560023",
            country: "India",
          },
        },
      },
      value: {
        label: "Address",
        type: "address",
        name: "background_display_address",
        mode: "display",
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
            background_color: "light-green",
          },
        },
      },
    },
    {
      id: "address_with_default",
      description: `Use <code>value</code> to specify default value`,
      label: "With default values",
      variables: {
        data: {
          address_with_default: null,
          defadd_line_1: "#111, Est consectetur et est velit qui et",
          defadd_line_2: "Dolore aute euesse laborum officia esse",
          defcity: "Bengaluru",
          defstate: "KA",
          defcode: "560023",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_with_default",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: "defadd_line_1",
          address_line_2: "defadd_line_2",
          city: "defcity",
          state: "defstate",
          pin_code: "defcode",
          country: {
            name: "defcountry",
            value: "India",
          },
        },
      },
    },
    {
      id: "address_with_default 2",
      description: `Use <code>value</code> to specify default value`,
      label: "With default values (without mapping)",
      variables: {
        data: {
          address_with_default2: null,
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_with_default2",
        mode: "input",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {},
          address_line_2: {},
          city: {},
          state: {},
          pin_code: {},
          country: {
            value: "India",
          },
        },
      },
    },
    {
      id: "address_without_district",
      label: `Address without district`,
      keyword: ["tutorial"],
      variables: {
        data: {
          add_line_1: "#111, Est consectetur et est velit qui et",
          add_line_2: "Dolore aute euesse laborum officia esse",
          city: "Bengaluru",
          district: "Bidar",
          state: "Karnataka",
          code: "560023",
          country: "IN",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_without_district",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "add_line_1",
          },
          address_line_2: {
            name: "add_line_2",
          },
          city: {
            name: "city",
          },
          state: {
            name: "state",
          },
          pin_code: {
            name: "code",
          },
          country: {
            name: "country",
          },
        },
      },
    },
    {
      id: "address_without_line1",
      label: `Address without Line 1`,
      keyword: ["tutorial"],
      variables: {
        data: {
          add_line_1: "#111, Est consectetur et est velit qui et",
          add_line_2: "Dolore aute euesse laborum officia esse",
          city: "Bengaluru",
          district: "Bidar",
          state: "Karnataka",
          code: "560023",
          country: "IN",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",

        name: "address_without_line1",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_2: {
            name: "add_line_2",
          },
          city: {
            name: "city",
          },
          state: {
            name: "state",
          },
          pin_code: {
            name: "code",
          },
          country: {
            name: "country",
          },
        },
      },
    },
    {
      id: "address_without_line2",
      label: `Address without Line 2`,
      keyword: ["tutorial"],
      variables: {
        data: {
          add_line_1: "#111, Est consectetur et est velit qui et",
          add_line_2: "Dolore aute euesse laborum officia esse",
          city: "Bengaluru",
          district: "Bidar",
          state: "Karnataka",
          code: "560023",
          country: "IN",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_without_line2",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "add_line_1",
          },
          city: {
            name: "city",
          },
          state: {
            name: "state",
          },
          pin_code: {
            name: "code",
          },
          country: {
            name: "country",
          },
        },
      },
    },
    {
      id: "address_with_default_value",
      label: `Address with default value`,

      variables: {
        data: {
          add_line_1: "#111, Est consectetur et est velit qui et",
          add_line_2: "Dolore aute euesse laborum officia esse",
          city: "Bengaluru",
          district: "Bidar",
          state: "Karnataka",
          code: "560023",
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        keyword: ["tutorial"],
        name: "address_with_default_value",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "add_line_1",
          },
          address_line_2: {
            name: "add_line_2",
          },
          city: {
            name: "city",
          },
          district: {
            name: "district",
          },
          state: {
            name: "state",
          },
          pin_code: {
            name: "code",
          },
          country: {
            name: "country",
            value: "India",
          },
        },
      },
    },
    {
      id: "address_with_mandatory_fields",
      description: `Mandatory fields in address`,
      label: "Mandatory fields",
      variables: {
        data: {
          address_with_mandatory_fields: null,
        },
      },
      value: {
        label: "<h2>Address</h2>",
        type: "address",
        name: "address_with_mandatory_fields",
        display: {
          attributes: {
            label: {
              raw_html: true,
            },
          },
        },
        address_fields: {
          address_line_1: {
            name: "address_with_mandatory_fields.add_line1",
            mandatory: true,
          },
          address_line_2: {
            name: "address_with_mandatory_fields.add_line2",
          },
          city: {
            name: "address_with_mandatory_fields.city",
            mandatory: true,
          },
          district: {
            name: "address_with_mandatory_fields.district",
          },
          state: {
            name: "address_with_mandatory_fields.state",
            mandatory: true,
          },
          pin_code: {
            name: "address_with_mandatory_fields.code",
            mandatory: true,
          },
          country: {
            name: "address_with_mandatory_fields.country",
            mandatory: true,
          },
        },
      },
    },
  ],
  attributes: [
    customiseAttribute("flat", { value: true }),
    no_value_text,
    no_value_behavior,
  ],
};
