import {
  no_value_text,
  no_value_behavior,
  icon_classes,
} from "@/components/fields/attributes";
export default {
  label: "HyperLink",
  type: "link",
  description: "Displays a hyperlink",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
    {
      key: "label",
      mandatory: false,
      description: "The label to be displayed",
      affects_display: "yes",
    },
    {
      key: "href",
      mandatory: true,
      description: "The link to be navigated to",
      affects_display: "yes",
    },
  ],
  templates: [
    {
      id: "default",
      label: "Default",
      description: "The default Link field.",
      variables: {
        data: {
          some_link: {
            href: "http://www.google.com",
            title: "lorem ipsum",
            icon: "mdi-access-point",
          },
        },
      },
      value: {
        name: "some_link",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "input_mode",
      label: "Input Mode",
      description: "The Input Link field with no data.",
      variables: {
        data: null,
      },
      value: {
        name: "input_link",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "display_mode",
      label: "Display Mode (Icon)",
      description: "An example of Link field in display mode.",
      variables: {
        data: {
          display_mode: {
            href: "http://www.google.com",
            title: "lorem ipsum",
            icon: "mdi-access-point",
          },
        },
      },
      value: {
        display: {
          attributes: {
            icon: {
              classes: ["red--text"],
            },
          },
        },
        mode: "display",
        name: "display_mode",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "a316f922-3d01-517d-bbd8-896a7f87ade1",
      label: "Display Mode (Very long link)",
      description: "An example of a very long Link field in display mode.",
      keyword: ["tutorial", "bug"],
      variables: {
        data: {
          "a316f922-3d01-517d-bbd8-896a7f87ade1": {
            href: "http://www.somelongnameNullacupidatateiusmodnonquisadipisicingsitduisnostrudconsequatullamcodolore.com",
            icon: "mdi-access-point",
          },
        },
      },
      value: {
        mode: "display",
        display: {
          width: "4",
        },
        name: "a316f922-3d01-517d-bbd8-896a7f87ade1",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "display_mode_no_value",
      label: "Display Mode without a value",
      keyword: ["tutorial"],
      description: "An example of Link field in display mode without a value",
      value: {
        mode: "display",
        name: "some_link_no_value",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "display_invalid_value",
      label: "Display Mode with an invalid value",
      keyword: ["tutorial"],
      description:
        "An example of Link field in display mode with an invalid value",
      variables: {
        data: {
          some_link_invalid_value: "N.A.",
        },
      },
      value: {
        mode: "display",
        name: "some_link_invalid_value",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "display_mode_image",
      label: "Display Mode (Image)",
      description: "An example of Link field in display mode with an image",
      variables: {
        data: {
          some_link3: {
            href: "http://www.google.com",
            title: "lorem ipsum",
            image: "/test_images/image_640x480.jpeg",
          },
        },
      },
      value: {
        mode: "display",
        name: "some_link3",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "other_fields",
      label: "Dynamic",
      description: "An example of Link field created from other fields",
      variables: {
        data: {
          href: "http://www.google.com",
          title: "lorem ipsum",
          image: "/test_images/image_640x480.jpeg",
        },
      },
      value: {
        mode: "display",
        name: "some_link4",
        label: "Link",
        type: "link",
        value: {
          href: "{_data->href}",
          title: "{_data->title}",
          image: "{_data->image}",
        },
      },
    },
    {
      id: "link_text",
      label: "Link as Text",
      keyword: ["tutorial"],
      description: "An example of Link field as Text input.",
      variables: {
        data: {
          some_link5: "http://www.google.com",
        },
      },
      value: {
        name: "some_link5",
        label: "Link",
        type: "link",
      },
    },
    {
      id: "link_text_display",
      label: "Link as Text (display)",
      keyword: ["tutorial"],
      description: "An example of Link field as Text input.",
      variables: {
        data: {
          some_link6: "http://www.google.com",
        },
      },
      value: {
        name: "some_link6",
        label: "Link",
        type: "link",
        mode: "display",
      },
    },
    {
      id: "attributes_icon_target_hidden",
      label: "Hide Icon and Target",
      keyword: ["tutorial"],
      description: "An example of Link field as Text input.",
      variables: {
        data: {
          attributes_icon_target_hidden: {
            href: "http://www.google.com",
            title: "lorem ipsum",
            image: "/test_images/image_640x480.jpeg",
          },
        },
      },
      value: {
        name: "attributes_icon_target_hidden",
        label: "Link",
        type: "link",
        display: {
          attributes: {
            hide_target: true,
            hide_icon: true,
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "title.color",
      type: "color",
      value: "secondary",
    },
    {
      name: "href.color",
      type: "color",
      value: "success",
    },
    {
      name: "hide_target",
      type: "switch",
      hint: "hide target",
      value: false,
    },
    {
      name: "hide_icon",
      type: "switch",
      hint: "hide icon",
      value: false,
    },
    {
      name: "style_link",
      type: "switch",
      hint: "Style Link",
      value: false,
    },
    {
      name: "no_value_space",
      type: "switch",
      hint: "If link is invalid, don't display the title either",
      value: false,
    },
    {
      name: "image_icon_width",
      type: "number",
      hint: "Width of image when it is used in a link",
      value: 30,
    },
    {
      name: "target",
      type: "select",
      hint: "Choose type of target",
      value: "",
      options: ["external"],
    },
    no_value_text,
    no_value_behavior,
    icon_classes,
  ],
};
