export default {
  label: "",
  type: "table",
  definition: {
    definition: {
      actions: null,
      fields: [
        {
          display: {
            width: 4,
          },
          label: " Name",
          mode: "display",
          name: "product.name",
          type: "text",
        },
        {
          display: {
            width: 2,
          },
          label: "Quantity",
          mode: "input",
          name: "quantity",
          type: "text",
          apis: {
            submit: {
              type: "event",
              name: "update_list_item",
              params: {
                quantity: "{_row->quantity}",
                id: "{_row->product->id}",
              },
            },
          },
        },
        {
          display: {
            width: 2,
          },
          label: "Cost/Item",
          mode: "display",
          name: "product.price",
          type: "cost",
        },
        {
          display: {
            width: 2,
          },
          label: "Cost",
          mode: "display",
          is_dynamic: true,
          rule: {
            operation: "ITEM_COST",
            cost_per_item: "[i].product.price",
            quantity: "[i].quantity",
          },
          name: "item_cost",
          type: "cost",
        },
        {
          display: {
            width: 1,
          },
          label: "Actions",
          mode: "display",
          name: "actions",
          type: "actions",
          value: [
            {
              confirmation: "Are you sure?",
              display: {
                attributes: {
                  color: "error",
                },
              },
              hint: "Delete",
              icon: "mdi-delete",
              label: "",
              name: "delete",
              type: "action",
              value: {
                type: "event",
                name: "delete_list_item",
                params: {
                  id: "{_row->id}",
                },
              },
            },
          ],
        },
      ],
      footers: {
        fields: [
          {
            type: "label",
            name: "total_label",
            value: "Total",
          },
          {
            label: "",
            mode: "display",
            is_dynamic: true,
            depends_on: "[[0-9]*].quantity", // eslint-disable-line no-useless-escape
            rule: {
              operation: "TOTAL",
              field: "quantity",
            },
            name: "total_quantity",
            type: "label",
          },
          {
            type: "spacer",
            name: "spacer",
          },
          {
            label: "",
            mode: "display",
            is_dynamic: true,
            depends_on: "[[0-9]*].item_cost", // eslint-disable-line no-useless-escape
            rule: {
              operation: "TOTAL",
              field: "item_cost",
            },
            name: "total_cost",
            type: "cost",
          },
          {
            type: "spacer",
            name: "spacer",
          },
        ],
      },
      mode: "display",
    },
    display: {
      attributes: {
        "disable-filtering": true,
        "disable-pagination": true,
        "disable-sort": true,
        "hide-default-footer": true,
        "items-per-page": -1,
      },
      classes: ["mb-7"],
      width: "12",
    },
    id: "list",
    title: "",
    type: "list",
  },
};
