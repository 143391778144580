<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <p class="pb-0 mb-0">Rule - {{ index + 1 }}</p>
          <TipText :content="ruleDescription" />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="12">
            <RuleConditionDesigner :condition="rule.conditions" />
          </v-col>
          <v-col cols="12">
            <TriggerEventDesigner :event="rule.event" />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RuleConditionDesigner from "./RuleConditionDesigner.vue";
import TriggerEventDesigner from "../events/TriggerEventDesigner.vue";
import TipText from "../TipText.vue";

const debug = require('debug')('atman.components.questionnaire.designer.rule_designer')  // eslint-disable-line

export default {
  name: "RuleDesigner",
  props: {
    rule: {},
    index: {},
  },
  components: { RuleConditionDesigner, TriggerEventDesigner, TipText },
  computed: {
    ruleDescription() {
      let result =
        "The event(s) associated with this rule will be triggered if ";
      const conditionType = (
        Object.keys(this.rule.conditions || {})[0] || ""
      ).toUpperCase();
      result = `${result} ${conditionType} of its conditions are met`;
      return result;
    },
  },
  mounted() {
    debug(`rule`, this.rule);
  },
};
</script>
