<template>
  <v-row dense>
    <v-col>
      <v-card aut-theme-control class="transparent pa-2 elevation-1">
        <v-card-text>
          <v-row v-show="activeControl == 'application_themes'">
            <ApplicationThemes
              :themes="allowedThemes"
              @change="theme = $event"
              :activeTheme="theme.name || ''"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ApplicationThemes from "./ApplicationThemes.vue";
import { getColorSet } from "@/store/preferences/api.js";
const debug = require("debug")("atman.theme_control"); // eslint-disable-line

const options = [
  {
    label: "Application Themes",
    id: "application_themes",
  },
];

export default {
  name: "ThemeControl",
  components: {
    ApplicationThemes,
  },
  data() {
    return {
      colors: [],
      themeControls: options,
      activeControl: options[0].id,
    };
  },
  computed: {
    ...mapGetters("preferences", ["activeTheme"]),
    ...mapGetters(["domainFeatures"]),
    domainThemes() {
      debug(`domain features`, this.domainFeatures);
      return this.domainFeatures?.allowed_themes || [];
    },
    allowedThemes() {
      if (!this.domainThemes || !this.domainThemes.length) {
        return this.colors;
      }
      const filteredList = this.colors.filter(({ name }) => {
        return this.domainThemes.includes(name);
      });
      debug(this.colors, this.domainThemes, filteredList);

      return filteredList;
    },
    activeLabel() {
      return options.find(({ id }) => id == this.activeControl).label;
    },
    theme: {
      get() {
        return this.colors.find(({ name }) => name == this.activeTheme) || {};
      },
      set(color) {
        this.setActiveColor(color?.name || "");
      },
    },
  },
  async mounted() {
    this.colors = await getColorSet();
  },
  methods: {
    ...mapMutations("preferences", ["setActiveColor"]),
  },
};
</script>
