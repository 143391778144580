<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-bind="displayAttributes"
      :elevation="
        hover ? displayAttributes.hover_elevation : displayAttributes.elevation
      "
      :color="
        hover
          ? displayAttributes.hovercolor
          : fieldValue.color || displayAttributes.color
      "
      :class="displayClasses"
      :width="effectiveWidth"
      ripple
      v-if="fieldValue && menuMode"
    >
      <v-img v-if="fieldValue.img" min-height="100" :src="fieldValue.img" fill>
      </v-img>
      <v-card-title
        v-if="(fieldValue.title || fieldValue.icon) && (!hover || !hoverEffect)"
      >
        <v-container>
          <v-row justify="center">
            <v-icon :class="iconClasses" v-if="fieldValue.icon" large>
              {{ fieldValue.icon }}
            </v-icon>
          </v-row>
          <v-row justify="center" :class="applicationMenuTitleClasses">
            {{ fieldValue.title }}
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text v-if="hover && hoverEffect" :class="DescClasses">
        {{ fieldValue.description || fieldValue.title }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import { getDynamicValue } from "@/util.js";

const debug = require("debug")("atman.components.application_menu");
debug("application_menu");
export default {
  name: "ApplicationMenuField",
  mixins: [fieldMixin],
  computed: {
    applicationMenuTitleClasses() {
      let result = "behavior_break_word text-center ";
      let textColor =
        this.fieldValue.textcolor ||
        this.displayAttributes?.["title-color"] + "--text" ||
        "white--text";
      result += this.fieldValue?.disabled ? " grey--text" : textColor;
      return result;
    },
    iconClasses() {
      let result = "mr-2 ";
      let iconColor =
        this.fieldValue.iconcolor ||
        this.displayAttributes?.["icon-color"] + "--text" ||
        "white--text";
      result += this.fieldValue?.disabled ? " grey--text" : iconColor;
      return result;
    },
    effectiveWidth() {
      if (this.isSmallScreen) {
        return this.displayAttributes?.["mobile-screen-width"] || "20vw";
      }
      return this.displayAttributes?.["large-screen-width"] || "20vw";
    },
    hoverEffect() {
      return this.displayAttributes?.["hover_effect"] || false;
    },
    classes() {
      let result = this.labelAttributes?.classes || [];

      result.push("text-center");
      result.push("behavior_break_word");

      return result;
    },
    DescClasses() {
      let result = this.classes.join(" ");
      // IF the label and value are shown side-by-side then adding some padding before the value
      debug(`DescClasses: `, result);
      return result;
    },
  },
  data() {
    return {
      definition,
      menuMode: true,
    };
  },
  mounted() {
    debug(`In Application Menu Field`, this.fieldValue, this.mode);
    this._determineMode();
  },
  methods: {
    async _determineMode() {
      if (!this?.fieldValue?.mode) {
        return;
      }
      this.menuMode = false;
      const debugKey = "_determineMode";
      const component = this;

      let payload = {
        field: this.fieldValue,
        key: "mode",
        defaultValue: "display",
        design: false,
        store: component.$store,
        context: component.context,
        debugKey,
        customFunctions: component.replaceIndex(),
        HACK_useFn: false,
      };
      try {
        const mode = await getDynamicValue(payload);
        debug(`mode resolved to [${mode}]`);
        this.menuMode = mode == "display";
      } finally {
        payload = null;
      }
    },
  },
};
</script>
