<template>
  <div>
    <div class="slideshow-container">
      <div
        class="mySlides fade"
        v-for="i in imgdata.length"
        :key="i"
        @click="currentSlide(i)"
      >
        <img
          :src="imgdata[i - 1]"
          v-bind:style="{ width: '100%', height: imgheight }"
        />
      </div>

      <a class="prev" @click="prev">❮</a>
      <a class="next" @click="next">❯</a>
    </div>
    <br />

    <div style="text-align: center">
      <span
        class="dot"
        v-for="i in imgdata.length"
        :key="i"
        @click="currentSlide(i)"
      ></span>
    </div>
  </div>
</template>

<script>
import definition from "./definition";
import { fieldMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.imageslider");

export default {
  name: "ImageSlider",
  mixins: [fieldMixin],
  data() {
    return {
      definition: definition,
      slideIndex: 1,
    };
  },

  mounted: function () {
    this.showSlides(this.slideIndex);
  },

  methods: {
    prev: function () {
      this.showSlides((this.slideIndex -= 1));
    },
    next: function () {
      this.showSlides((this.slideIndex += 1));
    },

    currentSlide: function (n) {
      this.showSlides((this.slideIndex = n));
    },
    showSlides: function (n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    },
  },

  computed: {
    imgheight() {
      let result = this.displayAttributes?.["img-height"];
      debug(`imgheight: ${result}`);

      return result;
    },

    imgdata() {
      const result = this?.value?.img_data;
      debug(`imgdata: ${result}`);
      return result;
    },
  },
};
</script>

<style lang="css" scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: rgb(232, 126, 70);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text
.text {
  color: #121111;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
} */

/* Number text (1/3 etc) */
.numbertext {
  color: #181717;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next {
    font-size: 11px;
  }
}
</style>
