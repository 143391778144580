export default {
  start: {
    type: "trigger_event",
    params: {
      id: ["general-1"],
    },
  },
  categories: {
    general: {
      display: {
        classes: [],
        attributes: {
          color: "#C3DFE0",
        },
      },
      label: "General",
      events: {
        "general-1": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from loss of consciousness",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-1",
                  },
                ],
              },
              event: {
                type: "display_message",
                params: {
                  message: "Some Message",
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-1",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["display_summary"],
                },
              },
            },
          ],
        },
      },
    },
  },
  events: {
    display_summary: {
      type: "display_summary",
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 1,
                path: "$.general.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: {
                id: "some outcome",
                label: "some outcome",
              },
            },
          },
        },
      ],
    },
  },
  summary: {
    title: "Diagnosis",
  },
};
