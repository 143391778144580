import { defaultsDeep } from "lodash";
import { clone, uniqueID } from "@/util.js";

const debug = require("debug")("atman.component.field:component_designer");

export default {
  components: {
    Field: () => import("@/components/fields/Field"),
    EditableField: () => import("@/components/editor/EditableField"),
    EditorActions: () => import("@/components/editor/EditorActions"),
    CloneFieldDialog: () => import("@/components/editor/CloneFieldDialog"),
    EditorAction: () =>
      import("@/components/editor/EditorActions/EditorAction"),
    AddFieldDialog: () => import("@/components/fields/Field/AddFieldDialog"),
  },
  props: {
    displayAttributes: {
      default() {
        return {};
      },
    },
    displayClasses: {
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      definitionUpdated: 1,
      fieldToClone: null,
      typeToClone: null,
    };
  },
  computed: {
    formFields: {
      get() {
        const component = this;
        const componentDefinition =
          component.$store.state[component.context].definition;
        const componentType = componentDefinition.type;
        const formDefinition =
          component.$store.state[component.context].definition.definition;
        let mode = componentType == "list" ? "display" : "input";
        const formMode = formDefinition?.mode;
        if (formMode) {
          mode = formMode == "readonly" ? "input" : formMode;
        }
        const defaultProps = {
          mode,
          display: {},
        };
        if (formMode == "readonly") {
          defaultProps.display.attributes = { readonly: true };
        }
        const fields = (formDefinition.fields || []).map((fieldDefinition) => {
          const field = {};
          defaultsDeep(field, clone(fieldDefinition), defaultProps);
          return field;
        });
        debug("computed formFields", fields);
        return clone(fields);
      },
      set(value) {
        this.updateOrder(value);
      },
    },
    effectiveTitle() {
      const component = this;
      if (!component.definition.title && !this.default_title) {
        if (this.isDesignMode) {
          return "No Title";
        }
        return "";
      }
      let result = "";
      if (!component.definition.title && this.default_title) {
        result = this.default_title;
      } else {
        result = component.$store.getters[`${component.context}/dynamicText`]({
          url: component.definition.title,
        });
      }
      debug(`Emitting component Title`, result);
      this.$emit(`component_title`, result);
      return result;
    },
    definition: {
      get() {
        const component = this;
        if (!component?.$store?.state[component?.context]) {
          debug(`no definition found for context`);
          return {};
        }
        const formDefinition =
          component?.$store?.state[component?.context]?.definition || {};
        debug(`formDefinition`, formDefinition);
        return formDefinition;
      },
      set(value) {
        this.$emit("refresh_content", value);
      },
    },
    isDesignMode() {
      return this.$store.state[this.context].design;
    },
  },
  methods: {
    updateOrder(formFields) {
      const definition = this.definition;
      definition.definition.fields = formFields;
      this.$emit("refresh_content", definition);
    },
    updateActionsOrder(actions) {
      const definition = clone(this.definition);
      definition.definition.actions = actions;
      this.definitionUpdated++;
      this.$emit("refresh_content", definition);
    },
    removeField(index) {
      const component = this;
      debug(`Form: removeField invoked`);
      const definition = clone(
        component.$store.state[component.context].definition
      );
      definition.definition.fields.splice(index, 1);
      this.$emit("refresh_content", definition);
    },
    editField(field, index) {
      debug(`Form: in editField`, field, index);
      const component = this;
      const definition = clone(
        component.$store.state[component.context].definition
      );

      definition.definition.fields.splice(index, 1, field);
      this.$emit("refresh_content", definition);
      debug(
        "form fields",
        component.formFields,
        component.$store.state[component.context].definition
      );
      return definition;
    },
    updateFieldPermissions(field, index) {
      const definition = this.editField(field, index);
      debug("emitting update:permissions from form/list", definition);
      this.$emit("update:permissions", definition);
    },
    updateNode(field, value) {
      const component = this;
      const definition = clone(
        component.$store.state[component.context].definition
      );
      definition[field] = value;
      this.$emit("refresh_content", definition);
    },
    addField(fieldDefinition) {
      debug("addField invoked", fieldDefinition);
      const component = this;

      const fieldName = fieldDefinition.name;

      const definition = clone(
        component.$store.state[component.context].definition
      );

      if (fieldName) {
        const fieldExists = !!definition.definition.fields.find(({ name }) => {
          return name == fieldName;
        });

        if (fieldExists) {
          this.displayWarningMessage(
            `A Field with the name ${fieldName} already exists. Please review`
          );
        }
      }

      definition.definition.fields.push(fieldDefinition);
      // Ensure "Actions" is always displayed last
      definition.definition.fields.sort((field1, field2) => {
        let returnValue = 0;
        if (field1.type == "actions" && field2.type != "actions") {
          returnValue = 1;
        } else if (field1.type != "actions" && field2.type == "actions") {
          returnValue = -1;
        }
        debug(`${field1.type} - ${field2.type} = ${returnValue}`);
        return returnValue;
      });
      this.$emit("refresh_content", definition);
    },
    copyField(fieldDefinition) {
      this.typeToClone = "field";
      this.fieldToClone = clone(fieldDefinition);
    },
    copyAction(actionDefinition) {
      this.typeToClone = "action";
      const action = clone(actionDefinition);
      action.id = uniqueID();
      this.fieldToClone = action;
    },
    updateAction(action) {
      debug(`updateAction invoked`, action);
      const actions = this.actions;
      const index = actions.findIndex(({ id, name }) => {
        if (id && action.id) {
          return action.id == id;
        }
        return action.name == name;
      });
      actions.splice(index, 1, clone(action));
      this.actions = actions;
      return actions;
    },
    updateActionPermissions(action) {
      const actions = this.updateAction(action);
      const definition = clone(this.definition);
      definition.definition.actions = actions;
      this.$emit("update:permissions", definition);
    },
    removeAction(actionToBeRemoved) {
      debug(`removeAction invoked`, actionToBeRemoved);
      let actions = this.actions;
      actions = actions.filter((action) => {
        if (actionToBeRemoved.id && action.id) {
          return action.id != actionToBeRemoved.id;
        }
        return action.name != actionToBeRemoved.name;
      });
      this.actions = actions;
    },
  },
};
