<template>
  <div class="bookContainer pl-10" v-resize="resetControls">
    <Flipbook
      v-if="pages"
      class="flipbook"
      :pages="pages"
      ref="flipbook"
      :zooms="zooms"
      :flipDuration="flipDuration"
      :startPage="currentPage"
      @flip-left-end="changePageAfterFlip"
      @flip-right-end="changePageAfterFlip"
    >
    </Flipbook>
    <ClickNavigator
      :page="currentPage"
      @go-to-prev-page="goToPrevPage"
      @go-to-next-page="goToNextPage"
      :flipbook="$refs.flipbook"
    />
    <NavigationSlider
      class="mt-4"
      :pages="pages && pages.length"
      :page="currentPage"
      @slide="currentPage = $event"
      v-if="showControls"
    />
    <ZoomSlider :flipbook="$refs.flipbook" v-if="showControls" />
    <ShowControlsToggle v-model="showControls" />
  </div>
</template>
<script>
import Flipbook from "flipbook-vue";
import { createContext } from "@/util.js";
import { store, STORE_CONSTS } from "@/components/fields/store";

const debug = require("debug")("eblish.views.readbook"); // eslint-disable-line

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const ESCAPE_KEY = 27;
// const PAGE_READ_SECONDS = 5000;

const preventRightClick = (e) => {
  e.preventDefault();
};

export default {
  name: "ReadBook",
  components: {
    Flipbook,
    NavigationSlider: () => import("./NavigationSlider.vue"),
    ZoomSlider: () => import("./ZoomSlider.vue"),
    ShowControlsToggle: () => import("./ShowControlsToggle.vue"),
    ClickNavigator: () => import("./ClickNavigator.vue"),
  },
  data: function () {
    return {
      pages: null,
      flipDuration: 2000,
      zooms: null,
      hasMouse: true,
      currentZoom: 1,
      storeKey: null,
      currentPage: 0,
      showControls: false,
    };
  },
  computed: {},
  mounted: async function () {
    this.toggleEventListeners("add");
    this.startReading();
  },

  beforeDestroy: function () {
    this.removeBackground();
    this.toggleEventListeners("remove");
  },
  methods: {
    resetControls() {},
    createStore() {
      const component = this;
      this.storeKey = `book_${this.context.id}`;
      component._createStore(this.storeKey, store);
      component.$store.commit(
        `${this.storeKey}/${STORE_CONSTS.ID}`,
        this.storeKey
      );
      component.$store.commit(
        `${this.storeKey}/${STORE_CONSTS.CONTEXT}`,
        this.context
      );
    },
    async fetchPages() {
      try {
        let path =
          process.env.NODE_ENV == "development"
            ? `/applications/eblisher`
            : `{_context->path}`;

        const response = await this.$store.dispatch(
          `${this.storeKey}/makeServerCall`,
          {
            params: {
              query: "LIST_NODES",
            },
            url: `${path}/pages`,
            type: "get",
          }
        );
        let pages = response?.data || [];
        if (!Array.isArray(pages)) {
          debug(`No pages found for this book`);
          return;
        }
        pages = pages.filter((item) => item != null);
        this.pages = pages.map((item) => item.image._file);
      } catch (e) {
        console.error(e);
      }
    },
    startReading() {
      this.setBackground();
      let path = this.$route.fullPath;
      const routeConst = "read_book/";
      path = path.substring(routeConst.length + 1, path.length);
      this.context = createContext(path);
      debug(`in startReading`, path, this.context);
      this.createStore();
      this.fetchPages();
    },
    goToNextPage() {
      const flipbook = this.$refs.flipbook;
      if (!flipbook) return;
      if (flipbook.canFlipRight) {
        flipbook.flipRight();
      }
    },
    goToPrevPage() {
      const flipbook = this.$refs.flipbook;
      if (!flipbook) return;
      if (flipbook.canFlipLeft) {
        flipbook.flipLeft();
      }
    },
    toggleEventListeners: function (toggleValue) {
      const functionToExecute =
        toggleValue == "add"
          ? window.addEventListener
          : window.removeEventListener;

      /* Prevent right clicks */
      functionToExecute("contextmenu", preventRightClick, false);
      /* keyboard based navigation */
      functionToExecute("keydown", this.addKeyboardSupport, false);

      /* Watch for the user pressing escape to exit full screen */
      functionToExecute("webkitfullscreenchange", this.exitHandler, false);
      functionToExecute("mozfullscreenchange", this.exitHandler, false);
      functionToExecute("fullscreenchange", this.exitHandler, false);
      functionToExecute("MSFullscreenChange", this.exitHandler, false);
    },
    goBack() {
      this.removeBackground();
      this.$router.go(-1);
    },
    exitHandler: function () {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        this.goBack();
      }
    },
    setBackground: function () {
      document.getElementById("app").classList.add("bookBackground");
      document.getElementsByTagName("body")[0].classList.add("bookBackground");
    },
    removeBackground: function () {
      document.getElementById("app").classList.remove("bookBackground");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("bookBackground");
    },
    addKeyboardSupport: function (ev) {
      switch (ev.keyCode) {
        case ESCAPE_KEY:
          this.goBack();
          return;
        case LEFT_ARROW:
          this.goToPrevPage();
          return;
        case RIGHT_ARROW:
          this.goToNextPage();
          return;
      }
    },
    changePageAfterFlip(pageIndex) {
      this.currentPage = pageIndex;
    },
  },
};
</script>

<style>
:root {
  --flipbook-min-width: 85vw;
  --flipbook-min-height: 85vh;
  --flipbook-top-margin: 10vh;
}

.imageCache {
  position: absolute;
  z-index: -1000;
}
.imageCache img {
  position: absolute;
  max-height: 10px;
  min-height: 10px;
  opacity: 0;
}
/* Important. Don't make this scss. We need to override several styles inside the library */
/* .bookContainer {
  padding: 2.5vh 2.5vw;
} */
.flipbook .viewport {
  min-width: var(--flipbook-min-width) !important;
  min-height: var(--flipbook-min-height) !important;
  margin-top: var(--flipbook-top-margin) !important;
}

.flipbook .page.fixed {
  background: white;
}

.flipbook .bounding-box {
  box-shadow: 5px 5px 20px #000000;
}
/* The flipbook library has an internal container class which conflicts with vuetify */
.flipbook .container {
  margin: 0 !important;
  padding: 0 !important;
}

/* This media query ensures the book is centered correctly on wide monitors, 4k monitors etc */
@media (orientation: landscape) {
  #app.bookBackground .container {
    max-width: 95% !important;
    padding-left: 20px;
  }
}
/* This media query ensures the book is centered correctly on mobile devices in portrait mode */
@media (orientation: portrait) {
  #app.bookBackground .container {
    max-width: 95% !important;
  }
}
/* This ensures the background is across the whole screen */
#app.bookBackground {
  background-image: url("/book/wooden-plank.jpg");
  background-size: cover;
}
</style>
