<template>
  <v-container
    fluid
    aut-page
    :data-aut-page="page.name || page.id"
    :class="effectiveClasses"
    v-mutate.attr="getActions"
  >
    <!-- IMPORTANT. The two attributes "classes" & "class" here are not the same -->
    <EditorWrapper
      v-if="storeKey"
      :classes="editorActionClasses"
      :class="`${effectiveDesignActions.length ? 'pa-md-2' : ''}`"
      :definition="page"
      :context="storeKey"
      :actions="effectiveDesignActions"
      :is_design_mode="design"
      @show-editor="displayPageEditor"
      @show-page="goBackToPage"
      @add-page="addPage"
      @edit_application="goToApplicationEdit"
      @remove_page="removePage"
      @update:definition="$emit('update:page', $event)"
      @add-menu="showAddMenuDialog = true"
      @edit_permissions="permissionDialogToggle = true"
      @edit_page="displayEditDialog = true"
      v-on="$listeners"
    >
      <EditorActions
        :classes="editorActionClasses"
        :context="storeKey"
        v-if="!design && actions.length"
        :actions="actions"
        @show-editor="displayPageEditor"
        @toggle_favourite="toggleFavourite"
        @toggle_pin="togglePinned"
        @toggle_pin_display="togglePins"
        @show-page="goBackToPage"
        @edit_permissions="permissionDialogToggle = true"
        @show_permissions="showPermissions"
        @resize_widget="showResizeWidget = true"
        @add-page="addPage"
      />

      <EditPageDialog
        v-if="displayEditDialog"
        :context="storeKey"
        :definition="page"
        @update:definition="$emit('update:page', $event)"
        @close="displayEditDialog = false"
      />
      <PermissionEditor
        v-if="permissionDialogToggle"
        :context="storeKey"
        mode="page"
        :definition="page"
        @close="permissionDialogToggle = false"
        @update="updatePagePermissions"
      />
      <AddMenuDialog
        v-if="showAddMenuDialog"
        :url="absoluteURL(url)"
        :title="effectiveTitle"
        :favorite="isFavourite"
        @close="closeAddMenuDialog"
      />
      <PinPageDialog
        v-if="displayPinPageDialog"
        :url="pagePinPath"
        @close="displayPinPageDialog = false"
      />
      <AddPageDialog
        v-if="addPageDialogFlag"
        :contextObj="context"
        @close="addPageDialogFlag = false"
      />

      <ResizeWidget
        v-if="showResizeWidget"
        :contextObj="context"
        @close="showResizeWidget = false"
        @update="widgetResized"
      />

      <div>
        <ContentTitle
          v-if="isTabMode && !pageTitleFeature"
          class="mb-0 pb-0"
          :title="effectiveTitle"
          aut-page-title-value
          :classes="pageTitleClasses"
        />

        <div v-if="pageTitleFeature">
          <EditableField
            v-if="design || !!page.title"
            class="pageTitle"
            aut-page-field="title"
            :design="design"
            :text="effectiveTitle"
            :definition="{
              label: 'Page Title',
              hint: 'Optional. Leave blank to skip',
            }"
            @update:text="$emit(`update:definition`, `title`, $event)"
          >
            <ContentTitle
              class="mb-0 pb-0"
              :title="effectiveTitle"
              aut-page-title-value
              :classes="pageTitleClasses"
            />
          </EditableField>

          <EditableField
            :design="design"
            v-if="design || page.subtitle"
            aut-page-field="subTitle"
            :text.sync="page.subtitle"
            :definition="{
              label: 'Page Sub Title',
              hint: 'Optional. Leave blank to skip',
            }"
            @update:text="$emit(`update:definition`, `subtitle`, $event)"
          >
            <p class="my-0 pt-0 pb-1 text-body-2" aut-pagesubtitle>
              {{ effectiveSubTitle }}
            </p>
          </EditableField>
        </div>
        <div v-if="isTabMode">
          <atman-tabs v-model="tabModel" @change="handleTabChange">
            <v-tab
              :aut-page-tab="component.id"
              :aut-page-tab-text="component.title"
              v-for="(component, i) in pageContent"
              :key="`${contentsKey}-${i}`"
            >
              {{ component.title || "No Title" }}
            </v-tab>
          </atman-tabs>
          <atman-tabs-items v-model="tabModel" class="behavior_page_tab">
            <v-tab-item
              v-for="(component, i) in pageContent"
              :key="`${contentsKey}-${i}`"
            >
              <AtmanComponent
                class="pt-4"
                :aut-content="component.id"
                :context="context"
                :definition="component"
                :design="design"
                @component_title="componentTitle = $event"
                @update:definition="
                  $emit(`update:definition`, `content[${i}]`, $event)
                "
                @remove="$emit(`remove_node`, i)"
                @update:permissions="updatePermissions(i, $event)"
              />
            </v-tab-item>
          </atman-tabs-items>
        </div>
        <div v-else-if="isWizardMode">
          <v-stepper :non-linear="wizardIsNonLinear" v-model="stepperModel">
            <v-stepper-header>
              <template v-for="(step, i) in pageContent">
                <v-stepper-step
                  :aut-wizard-step="i"
                  :key="i"
                  :complete="stepperModel > i"
                  :step="i + 1"
                  :editable="wizardIsNonLinear"
                >
                  {{ step.title || "No Title" }}
                </v-stepper-step>

                <v-divider
                  v-if="i < pageContent.length - 1"
                  :key="`${i}-divider`"
                ></v-divider>
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <template v-for="(component, i) in pageContent">
                <v-stepper-content :step="i + 1" :key="i">
                  <AtmanComponent
                    class="pt-4"
                    :aut-content="component.id"
                    :context="context"
                    :definition="component"
                    :design="design"
                    @component_title="componentTitle = $event"
                    @update:definition="
                      $emit(`update:definition`, `content[${i}]`, $event)
                    "
                    @remove="$emit(`remove_node`, i)"
                    @update:permissions="updatePermissions(i, $event)"
                  />
                  <v-row dense>
                    <v-col cols="12" class="d-flex">
                      <v-spacer />
                      <!-- <v-btn aut-wizard-next-btn color="primary" @click="nextStep(i)">
                  {{ buttonLabel(i) }}
                </v-btn> -->
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </template>
            </v-stepper-items>
          </v-stepper>
        </div>
        <v-row dense v-else>
          <v-col
            v-for="(component, i) in pageContent"
            :cols="getWidth(component)"
            :key="`${contentsKey}-${i}`"
          >
            <AtmanComponent
              class="behavior_atman_component"
              :aut-content="component.id"
              :context="context"
              :definition="component"
              :default_title="pageTitleFeature ? '' : page.title"
              :design="design"
              @component_title="componentTitle = $event"
              @update:definition="
                $emit(`update:definition`, `content[${i}]`, $event)
              "
              @update:permissions="updatePermissions(i, $event)"
              @remove="$emit(`remove_node`, i)"
            />
          </v-col>
        </v-row>
        <v-row v-if="design && pageContent.length == 0" justify="center">
          <v-btn @click="$emit('add_item')">Add Content</v-btn>
        </v-row>
      </div>
    </EditorWrapper>
  </v-container>
</template>
<script>
import { defaultsDeep } from "lodash";
import EditorWrapper from "@/components/editor/EditorWrapper";
import EditableField from "@/components/editor/EditableField";
import AddPageDialog from "@/components/editor/AddPageDialog";
import ResizeWidget from "@/components/editor/ResizeWidget";
import AddMenuDialog from "@/components/editor/AddMenuDialog";
import PinPageDialog from "@/components/editor/PinPageDialog";
import EditPageDialog from "./EditPageDialog";
import { mapActions, mapMutations } from "vuex";
import permissionsMixin from "@/components/permissionsMixin.js";

import { getComponentWidth } from "@/components/fields/util.js";
import { store } from "./store";

import { CLOSE_DIALOG } from "@/constants";
import ContentTitle from "@/components/pageContent/ContentTitle.vue";
import { getMenuDetails } from "@/components/editor/util.js";
import { isPlainObject } from "lodash";
import {
  clone,
  navigateTo,
  makeServerCall,
  absoluteURL,
  evaluateConditions,
  isAnApplicationPath,
  isChildOf,
  uniqueID,
  goToApplicationEdit,
  registerToEvents,
  doProcessing,
  replacePlaceholders,
  isDefaultValidKey,
} from "@/util.js";

const debug = require("debug")("atman.components.page"); // eslint-disable-line

export default {
  name: "Page",
  mixins: [permissionsMixin],
  components: {
    EditorActions: () => import("@/components/editor/EditorActions"),
    EditorWrapper,
    EditableField,
    AddPageDialog,
    PinPageDialog,
    ResizeWidget,
    AddMenuDialog,
    EditPageDialog,
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
    ContentTitle,
  },
  data() {
    return {
      effectiveTitle: null,
      displayPinPageDialog: false,
      showResizeWidget: false,
      widgetActionsVisible: false,
      isFavourite: false,
      actions: [],
      displayEditDialog: false,
      edit_title: true,
      contentsKey: 1,
      addPageDialogFlag: false,
      showAddMenuDialog: false,
      storeKey: null,
      componentTitle: null,
      tabModel: (this?.$route?.query?.tab || 0) * 1,
      stepperModel: (this?.$route?.query?.step || 1) * 1,
    };
  },
  props: {
    nonDesignActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    page: {
      type: Object,
    },
    access_control: {
      type: Object,
    },
    context: {
      type: Object,
    },
    design: {
      type: Boolean,
    },
    url: {
      type: String,
    },
    editor: {
      type: Boolean,
    },
  },
  computed: {
    pageTitleClasses() {
      const component = this;
      return component.page?.title?.display?.classes || [];
    },
    wizardIsNonLinear() {
      return (
        this.design || this?.page?.display?.attributes?.wizard?.editable == true
      );
    },
    effectiveClasses() {
      // Apply the padding only on large screens
      const spacingSmAndDown = this.isSmallScreen ? "ma-0 pa-0 " : "";
      let pageClasses = this.page?.classes || "ma-0 pa-0 ";
      if (typeof pageClasses == "string") {
        pageClasses = [pageClasses];
      }
      const result = [spacingSmAndDown, ...pageClasses];
      return result;
    },
    pagePinPath() {
      let result;
      if (!isChildOf(this.$el, ".behavior_dynamic_page")) {
        result = this.$route.fullPath;
      } else {
        result = this.context.full_path;
      }
      debug(`pagePinPath`, result);
      return result;
    },
    isDialog() {
      const result = this.$el && isChildOf(this.$el, ".behaviour_dialog");
      return result;
    },
    pageTitleFeature() {
      return this.isFeatureEnabled("page.title");
    },
    editorActionClasses() {
      let classes = "";
      if (this.pageTitleFeature || this.isTabMode || this.isDialog) {
        classes += "pa-1";
      } else {
        classes += "pa-1 mt-n5";
      }
      return classes;
    },
    isWidget() {
      let routePath = this.$route?.fullPath;
      // HACK: The context full path does not contain all the parameters
      if (routePath.indexOf("&") != -1) {
        routePath = routePath.substring(0, routePath.indexOf("&"));
      }
      const contextPath = this.context?.full_path;
      const result = routePath != contextPath;
      debug(`isWidgetCheck`, routePath, contextPath, result);
      return result;
    },
    effectiveSubTitle() {
      if (!this.page.subtitle) {
        if (this.design) {
          return "No Subtitle";
        }
        return "";
      }
      return this.page.subtitle;
    },
    isTabMode() {
      return this?.page?.display?.mode == "tab" || !!this?.page?.display?.tab;
    },
    isWizardMode() {
      return this?.page?.display?.mode == "wizard";
    },
    pageContent() {
      let contents = this?.page?.content || [];
      // IMPORTANT. Retain this if condition. IF not, the editor keeps thinking the page has been modified.
      if (this.editor) {
        return contents;
      }
      contents = contents.map((content) => {
        defaultsDeep(content, {
          display: {
            classes: ["px-2"],
          },
        });
        return content;
      });
      const result = contents.filter(
        (content) => !this.isContentHidden(content)
      );
      return result;
    },
    effectiveDesignActions() {
      let result = [];
      if (isAnApplicationPath()) {
        result.push({
          id: "edit_application",
          label: "Edit Application",
          icon: "mdi-wrench",
          event: "edit_application",
          permission: "edit_pages",
          param: "",
        });
      }
      result = [
        ...result,
        {
          id: "edit-page",
          label: "Edit Page",
          icon: "mdi-pencil",
          event: "edit_page",
          param: "",
        },
        {
          id: "show_permissions",
          label: "Toggle Permissions",
          icon: "mdi-key",
          event: "edit_permissions",
        },
        {
          id: "add_item",
          label: "Add Content",
          icon: "mdi-text-box-plus",
          event: "add_item",
          param: "form",
        },
        {
          id: "remove-page",
          label: "Remove Page",
          icon: "mdi-delete",
          confirmation: "Are you sure you want to continue?",
          event: "remove_page",
          permission: "delete_pages",
        },
        {
          id: "add-menu",
          label: "Add/Update Menu",
          icon: "mdi-file-tree",
          event: "add-menu",
        },
        ...(this.designActions || []),
      ];
      return result;
    },
  },
  watch: {
    page() {
      this.contentsKey++;
    },
    $route() {
      this.getActions();
    },
    widgetActionsVisible() {
      this.$emit("editing:pin", this.widgetActionsVisible);
    },
    stepperModel(newValue) {
      this.handleStepChange(newValue);
    },
  },
  created() {
    const component = this;
    component.goToApplicationEdit = goToApplicationEdit;
    debug(`In created of Page.`, component.page);
    component.getWidth = getComponentWidth;
    component.absoluteURL = absoluteURL;
  },
  mounted() {
    const component = this;
    const outcome = this.checkPermissions();
    if (!outcome) {
      return;
    }
    debug(`Registering a store`);
    const storeKey = (component.storeKey = `page_${uniqueID()}`);
    component._createStore(storeKey, store);
    if (this.access_control) {
      this.$store.commit(`${storeKey}/setAccessControl`, this.access_control);
    }
    this.getActions();
    this.registerToEvents();
    this.getEffectiveTitle();
  },
  methods: {
    ...mapMutations("preferences", ["removeFavourite", "addFavourite"]),
    ...mapActions("preferences", ["removePinned", "addPinned"]),
    async fetchPageTitle() {
      const component = this;
      let value = "";
      const titleDefinition = component.page?.title?.data;
      let url = titleDefinition?.url;
      if (!url) {
        return;
      }
      const variables = Object.assign({}, { _context: component.context });
      debug(`variables`, variables);

      url = replacePlaceholders(url, variables, {
        isValidKey: (key) => {
          let result = isDefaultValidKey(key);
          if (result) {
            return result;
          }
          return false;
        },
        debugKey: "fetchPageTitle",
      });

      const payload = {
        url,
        type: "get",
      };
      try {
        const response = await makeServerCall(payload);
        const processors = titleDefinition?.post_processors;
        const methodInputs = {
          processors,
          data: response.data,
          debugKey: "fetchPageTitle",
        };
        if (Array.isArray(processors)) {
          value = await doProcessing(methodInputs);
        }
        console.log("fetchPageTitle", value);
      } catch (e) {
        console.error(e);
      }
      return value;
    },
    async getEffectiveTitle() {
      const component = this;
      let result = "";
      if (component.pageTitleFeature) {
        if (component.page.title && !isPlainObject(component.page.title)) {
          result = component.page?.title || "";
          if (!result && component.design) {
            result = "No Title";
          }
        } else {
          result = await component.fetchPageTitle();
        }
      }
      component.effectiveTitle = result;
      console.log(`page title is ${component.effectiveTitle}`);
    },
    checkPermissions() {
      const methodDebug = debug.extend("checkPermission");
      const permissionsObject = this.page?._permissions || {};
      if (!permissionsObject?.enabled) {
        methodDebug(
          `No permission configured or permissions check are disabled`
        );
        return true;
      }
      const permissionName = permissionsObject.permission?.value;
      if (!permissionName) {
        methodDebug(`No Permission name available`);
        return true;
      }
      const isAllowed =
        this.$store.getters[`user/canPerformAction`](permissionName);

      if (isAllowed) {
        methodDebug(`User can perform action [${permissionName}]`);
        return true;
      }
      const url = permissionsObject?.permission?.url;

      if (url) {
        methodDebug(`URL: [${url}] has been configured. Redirecting`);
        this.$router.push({ path: url });
      } else {
        methodDebug(`No URL has been configured. Going back in history`);
        this.$router.go(-1);
        this.displayErrorMessage("Insufficient permissions to view page");
      }
      return false;
    },
    handleStepChange(step) {
      this.stepperModel = step;
      if (this.$route.query.step == this.stepperModel) {
        return;
      }
      const queryParams = Object.assign({}, clone(this.$route.query), {
        step: step,
      });
      this.$router.replace({ query: queryParams });
    },
    updatePagePermissions(updatedDefinition) {
      const page = clone(this.page);
      page._permissions = clone(updatedDefinition._permissions);
      debug(`updatePagePermissions`, updatedDefinition, page._permissions);
      this.invokePermissionsUpdate(page);
    },
    async invokePermissionsUpdate(page) {
      if (this.design) {
        this.$emit("update:page", page);
        return;
      }
      const payload = {
        url: this.context.full_path,
        type: "post",
        params: {
          action: "update_permissions",
          data: page,
        },
      };
      debug(`invokePermissionsUpdate`, payload);
      try {
        await makeServerCall(payload);
      } catch (e) {
        console.error(e);
        this.displayErrorMessage(`Permissions update failed`);
      }
      this.displaySuccessMessage("Permissions updated");
    },
    showPermissions() {
      this.$el.classList.toggle("behavior_show_permissions");
    },
    updatePermissions(i, updatedContent) {
      const page = clone(this.page);
      page.content[i] = updatedContent;
      this.invokePermissionsUpdate(page);
    },
    widgetResized() {
      this.showResizeWidget = false;
      this.$emit("widget_resized");
    },
    getActions() {
      const methodDebug = debug.extend("getActions");
      let result = [...this.nonDesignActions];
      if (result.length > 0) {
        methodDebug(`nonDesignActions found. Returning`, result);
        this.actions = result;
        return;
      }
      const isFav = this.$store.getters["preferences/isAFavourite"](
        this.pagePinPath
      );
      methodDebug(`isFav`, isFav);
      const isPinned = !!this.$store.getters["preferences/isPinned"](
        this.pagePinPath
      );
      methodDebug(`isPinned`, isPinned);

      const pageElement = this.$el;
      const isAWidgetContainer = pageElement.classList.contains(
        "behavior-pin-container"
      );
      methodDebug(`isAWidgetContainer`, isAWidgetContainer);
      let dontShowEditorActions =
        pageElement.classList.contains("behavior-no-editor");
      methodDebug(`dontShowEditorActions`, dontShowEditorActions);

      const actionsShouldBeDisplayedInMobile = !this.isSmallScreen
        ? true
        : this.isFeatureEnabled("editor.mobile.display");

      if (!dontShowEditorActions && this.isSmallScreen) {
        dontShowEditorActions = !actionsShouldBeDisplayedInMobile;
      }
      methodDebug(
        `dontShowEditorActions after Mobile check`,
        dontShowEditorActions
      );

      const dontShowFavActions =
        pageElement.classList.contains("behavior-no-fav") ||
        !this.isFeatureEnabled("favourite.enabled");
      methodDebug(`dontShowFavActions`, dontShowFavActions);

      const dontShowPinActions =
        pageElement.classList.contains("behavior-no-pin");
      methodDebug(`dontShowPinActions`, dontShowPinActions);

      let dontShowPermissionToggle = pageElement.classList.contains(
        "behavior-no-permission"
      );
      methodDebug(`dontShowPermissionToggle`, dontShowPermissionToggle);

      if (!dontShowPermissionToggle && this.isSmallScreen) {
        dontShowPermissionToggle = !actionsShouldBeDisplayedInMobile;
      }
      methodDebug(
        `dontShowPermissionToggle after smallscreen check`,
        dontShowPermissionToggle
      );

      let showReorderPin = pageElement.classList.contains(
        "behavior-dynamic-page"
      );
      methodDebug(`showReorderPin `, showReorderPin);

      if (showReorderPin && this.isSmallScreen) {
        showReorderPin = actionsShouldBeDisplayedInMobile;
      }
      methodDebug(`showReorderPin after mobile`, showReorderPin);

      const canPinToDomainPreferenes =
        this.$store.getters["user/canPerformAction"]("pin_pages");
      methodDebug(`canPinToDomainPreferenes `, canPinToDomainPreferenes);

      const canPinToUserPreferences =
        this.$store.getters["user/canPerformAction"]("user_pin_pages");
      methodDebug(`canPinToUserPreferences `, canPinToUserPreferences);

      const showTogglePins =
        isAWidgetContainer &&
        (canPinToDomainPreferenes || canPinToUserPreferences);
      methodDebug(`showTogglePins `, showTogglePins);

      if (showTogglePins) {
        result.push({
          id: "toggle_pin_display",
          label: "Toggle Pins",
          icon: "mdi-pin",
          classes: this.widgetActionsVisible ? "green--text" : "",
          event: "toggle_pin_display",
        });
      }

      if (!dontShowEditorActions) {
        result.push({
          id: "add-page",
          label: "Add Page",
          icon: "mdi-book-plus",
          event: "add-page",
          permission: "create_pages",
        });
        result.push({
          id: "show-editor",
          label: "Toggle Editor",
          icon: "mdi-book-edit",
          event: "show-editor",
          permission: "edit_pages",
        });
      }
      const permissionsDisplayed = this.$el.classList.contains(
        "behavior_show_permissions"
      );
      const permissionsFunctionalityEnabled = this.isFeatureEnabled(
        "permissions.non_editor.enabled"
      );

      methodDebug(
        `permissionsDisplayed: [${permissionsDisplayed}], permissionsFunctionalityEnabled: [${permissionsFunctionalityEnabled}]`
      );
      if (permissionsFunctionalityEnabled && !dontShowPermissionToggle) {
        result.push({
          id: "show_permissions",
          label: "Toggle Permissions",
          icon: "mdi-key",
          event: "show_permissions",
          classes: permissionsDisplayed ? "green--text" : "",
          permission: "change_permissions",
        });
        // const fieldPermissionsObj = this.page?._permissions || {};

        result = [...result, ...this.permissionsActions];
      }

      if (!dontShowFavActions) {
        result.push({
          id: "fav-page",
          label: "Toggle Favourite",
          icon: "mdi-star",
          classes: isFav ? "green--text" : "",
          event: "toggle_favourite",
          // permission: "add_favourite",
        });
      }

      const isPinnedToDomain = !!this.$store.getters["isPinned"](
        this.pagePinPath
      );

      let enablePin = false;
      if (canPinToDomainPreferenes) {
        enablePin = true;
      } else if (!isPinnedToDomain && canPinToUserPreferences) {
        enablePin = true;
      }

      if (!dontShowPinActions && enablePin) {
        result.push({
          id: "toggle_pin",
          label: "Toggle Pinned",
          icon: "mdi-pin",
          classes: isPinned ? "green--text" : "",
          event: "toggle_pin",
        });
        if (showReorderPin) {
          result.push({
            id: "reorder-pin",
            label: "Reorder Pin",
            icon: "mdi-drag",
            classes: "dragger",
          });
        }
        if (this.isWidget) {
          result.push({
            id: "resize_widget",
            label: "Resize widget",
            icon: "mdi-arrow-split-vertical",
            classes: "",
            event: "resize_widget",
          });
        }
      }
      methodDebug(`actions`, result);
      this.actions = result;
    },
    async toggleFavourite() {
      const component = this;
      const result = component.$store.getters["preferences/isAFavourite"](
        component.pagePinPath
      );
      if (result) {
        component.removeFavourite(component.pagePinPath);
        component.displaySuccessMessage("Favourite removed successfully");
        component.getActions();
        return;
      }

      const showAddFavDialog = this.isFeatureEnabled("favourite.show_dialog");
      if (showAddFavDialog) {
        this.showAddMenuDialog = true;
        this.isFavourite = true;
        return;
      }
      const menuDetails = await getMenuDetails(component.pagePinPath);
      let favTitle =
        menuDetails?.label ||
        component?.effectiveTitle ||
        component?.componentTitle;

      if (!favTitle) {
        const home = this.$el;
        const titleField = home.querySelectorAll(
          '[aut-field-name="title"] [aut-field-value]'
        );
        favTitle = titleField?.[0]?.innerText;
      }
      if (!favTitle) {
        this.showAddMenuDialog = true;
        this.isFavourite = true;
        return;
      }
      const fav = {
        href: component.pagePinPath,
        title: favTitle,
        icon: "mdi-star",
      };
      component.addFavourite(fav);
      component.displaySuccessMessage("Favourite updated");
    },
    togglePins() {
      const home = document.body;
      this.widgetActionsVisible = !this.widgetActionsVisible;
      const dynamicPages = home.querySelectorAll(".behavior-dynamic-page");
      dynamicPages.forEach((element) => {
        const actionsHidden = element.classList.contains("behavior-no-pin");
        if (this.widgetActionsVisible) {
          if (actionsHidden) {
            element.classList.remove("behavior-no-pin");
          }
        } else {
          if (!actionsHidden) {
            element.classList.add("behavior-no-pin");
          }
        }
      });
      this.getActions();
    },
    togglePinned() {
      let pathToPin = this.pagePinPath;
      const result = !!this.$store.getters["preferences/isPinned"](pathToPin);
      if (result) {
        const canPinToSkin =
          this.$store.getters["user/canPerformAction"]("pin_pages");
        if (canPinToSkin) {
          this.displayPinPageDialog = true;
        } else {
          this.removePinned({ url: pathToPin });
          if (this.isWidget) {
            const dynamicPage = this.$el.closest("[aut-dynamic-page]");
            dynamicPage.parentElement.removeChild(dynamicPage);
          }
        }
        this.displaySuccessMessage("Page unpinned");
      } else {
        const canPinToSkin =
          this.$store.getters["user/canPerformAction"]("pin_pages");
        if (canPinToSkin) {
          this.displayPinPageDialog = true;
        } else {
          this.addPinned({ url: pathToPin });
          this.displaySuccessMessage("Page pinned");
        }
      }
      this.getActions();
    },
    closeAddMenuDialog() {
      this.showAddMenuDialog = false;
      this.isFavourite = false;
      this.getActions();
    },
    handleTabChange(index) {
      this.tabModel = index;
      if (this.$route.query.tab == this.tabModel) {
        return;
      }
      const queryParams = Object.assign({}, clone(this.$route.query), {
        tab: index,
      });
      this.$router.replace({ query: queryParams });
    },
    goBackToPage() {
      this.$store.dispatch(`triggerNavigation`, { url: "BACK" });
    },
    displayPageEditor() {
      let path = this.context.full_path;
      if (path.indexOf("?page=") == -1) {
        path = path + "?page=home";
      }
      this.$store.commit(CLOSE_DIALOG, null, { root: true });
      navigateTo(this.$router, `/editor_page?page=${encodeURIComponent(path)}`);
    },
    addPage() {
      this.addPageDialogFlag = true;
    },
    async removePage() {
      const pagePath = this.context.full_path;
      debug(`Removing ${pagePath}`);
      await makeServerCall({
        url: pagePath,
        type: "delete",
      });
      const application = (
        await makeServerCall({
          url: `${this.context.application_path}`,
          type: "get",
        })
      ).data;
      const menus = (application?.menu?.child || []).filter((menu) => {
        return menu.href != pagePath;
      });
      application.menu.child = menus;
      await makeServerCall({
        url: `${this.context.application_path}`,
        type: "post",
        params: {
          action: "update_fields",
          data: application,
        },
      });
      navigateTo(this.$router, `/`);
    },
    isContentHidden(content) {
      const component = this;
      let hidden = content?.display?.hidden || false;
      if (content.mode == "ignored" || content.mode == "hidden") {
        return true;
      }
      if (typeof hidden == "boolean") {
        return hidden;
      }
      const conditions = hidden?.conditions;
      const outcome = evaluateConditions(conditions, {
        _context: component.context,
      });

      return outcome ? outcome.result : false;
    },
    registerToEvents() {
      const component = this;
      const unsubscribe = registerToEvents({
        $store: component.$store,
        events: [
          {
            name: "_wizard_next",
          },
          {
            name: "_wizard_finish",
          },
        ],
        callBackFn: (triggeredEvent, payload) => {
          debug(`In wizard`, triggeredEvent, payload);
          if (triggeredEvent.name == "_wizard_next") {
            this.stepperModel++;
          }
        },
      });
      component._subscribe(unsubscribe);
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_atman_component {
  padding-top: 20px;
}

.behaviour_equal_content ::v-deep {
  .behaviour_atman_component {
    height: 100%;
  }
}
</style>
<style>
.behaviour_dialog .behavior_atman_component {
  padding-top: 0px !important; /* No padding necessary in dialogs */
}
</style>
