<template>
  <v-row dense>
    <v-col cols="12" class="d-flex">
      <v-switch
        dense
        v-model="fieldDefinition.is_container"
        label="Is a container"
        class="mt-0"
        hint="If true, the field is treated as a container. The values themselves need not be nested"
        persistent-hint
        @change="updateDefinition"
      ></v-switch>
    </v-col>
  </v-row>
</template>
<script>
const debug = require("debug")("atman.components.object_designer");
import { clone } from "@/util.js";
import { defaults } from "lodash";
debug(`object_designer`);
export default {
  name: "ObjectFieldDesigner",
  components: {},
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      fieldDefinition: defaults(clone(this.definition), {
        min: 0,
        max: 100,
      }),
    };
  },
  methods: {
    updateDefinition() {
      debug(`ObjectFieldDesigner updateDefinition`, this.fieldDefinition);
      this.$emit("update:definition", this.fieldDefinition);
    },
  },
};
</script>
