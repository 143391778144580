<template>
  <v-row dense>
    <v-col cols="12">
      <v-text-field
        aut-workflow-name
        dense
        name="name"
        label="Name"
        hint="Give this workflow a name"
        required
        :rules="applicableRules"
        v-model="workflowDefinition.name"
        @change="updateDefinition"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-textarea
        aut-workflow-description
        name="description"
        :rows="1"
        auto-grow
        v-model="workflowDefinition.description"
        label="Description"
        @change="updateDefinition"
        hint="Type a description to help understand the purpose of this workflow"
      ></v-textarea>
    </v-col>
  </v-row>
</template>
<script>
import { rules } from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/WorkflowEditor/store.js";
const debug = require("debug")("atman.components.workflow_name");
debug(`workflow_name`);
export default {
  name: "WorkflowName",
  data() {
    return {
      applicableRules: [rules.mandatory()],
    };
  },
  computed: {
    workflowDefinition() {
      if (!this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE]) {
        return {};
      }
      return this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
        STORE_CONSTS.WORKFLOW
      ];
    },
  },
  created() {
    debug(`In created of WorkflowName`);
  },
  mounted() {
    debug(`In mounted of WorkflowName`);
  },
  methods: {
    updateDefinition() {
      debug(`in updateDefinition`, this.workflowDefinition);
      this.$store.commit(
        `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.WORKFLOW}`,
        this.workflowDefinition
      );
    },
  },
};
</script>
