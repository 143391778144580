<template>
  <v-card flat transparent class="transparent">
    <div class="relative">
      <EditorActions
        v-if="isInputMode && removeBtn"
        :actions="designActions"
        @remove="$emit('remove')"
      />
    </div>
    <v-card-text :class="definition.mode != 'input' ? 'pa-0 ma-0' : 'pa-2'">
      <Field
        :path="effectivePath"
        :definition="arrayFieldDefinition"
        :context="context"
        :index="index"
        v-on="$listeners"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import { defaultsDeep } from "lodash";
import { clone } from "@/util";
import EditorActions from "@/components/editor/EditorActions";
const debug = require("debug")("atman.components.field.array.item");

const designActions = [
  {
    id: "remove",
    label: "Remove",
    icon: "mdi-delete",
    event: "remove",
  },
];

export default {
  name: "ArrayFieldItem",
  props: {
    value: {},
    definition: {
      type: Object,
    },
    path: {
      type: String,
    },
    removeBtn: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
    context: {
      type: String,
    },
  },
  components: {
    Field: () => import("@/components/fields/Field"),
    EditorActions,
  },
  created() {
    this.designActions = designActions;
  },
  computed: {
    isInputMode() {
      return this.definition.mode == "input";
    },
    effectivePath() {
      const result = `${this.path}[${this.index}]`;
      debug(`getPath`, result);
      return result;
    },
    arrayFieldDefinition() {
      const field = clone(this.definition.field);
      const result = defaultsDeep(
        {
          name: this.definition.name,
          mode: this.definition.mode || "input",
        },
        field
      );
      if (this.value) {
        result.value = this.value;
      }
      debug(`arrayFieldDefinition`, JSON.stringify(result));
      return result;
    },
  },
};
</script>
