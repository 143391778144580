<template>
  <div>
    <FieldLabel
      v-if="isDisplayMode"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
      <span aut-link v-if="effectiveTitle" class="d-flex">
        <v-icon
          aut-link-icon
          v-if="effectiveIcon"
          class="pr-1"
          :class="iconClasses"
          >{{ effectiveIcon }}</v-icon
        >
        <div v-if="effectiveImage" :style="`width: ${imageAsIconWidth}px;`">
          <v-img
            aut-link-img
            class="pr-1"
            :src="effectiveImage"
            contain
          ></v-img>
        </div>
        <a
          v-if="effectiveLink && isExternalLink"
          :target="target"
          :class="linkClasses"
          aut-link-href
          :href="effectiveLink"
          class="behavior_break_word"
          >{{ effectiveTitle }}</a
        >
        <router-link
          :class="linkClasses"
          aut-link-href
          v-else-if="effectiveLink && !isExternalLink"
          :to="effectiveLink"
          class="behavior_break_word"
          >{{ effectiveTitle }}</router-link
        >

        <span v-else aut-invalid-link>{{ effectiveTitle }}</span>
      </span>
      <span v-else-if="noValueSpace" class="nolink my-1"></span>
    </FieldLabel>
    <div v-if="!isDisplayMode">
      <v-row class="flex-wrap">
        <v-col
          cols="12"
          lg="3"
          class="mt-4"
          v-if="displayAttributes && displayAttributes.hide_icon == false"
        >
          <IconField
            v-if="propsData"
            v-bind="propsData"
            :key="renderKey"
            @change="updateIcon"
          />
        </v-col>
        <v-col cols="12" v-if="effectiveValue" :lg="titleWidth">
          <v-text-field
            aut-field-value="title"
            name="title"
            v-if="effectiveValue"
            v-model="effectiveValue.title"
            label="Title"
            clearable
            outline
            :loading="loading"
            required="true"
            v-bind="titleAttributes"
            @change="$emit('change', effectiveValue)"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          v-if="
            effectiveValue &&
            displayAttributes &&
            displayAttributes.hide_target == false
          "
        >
          <v-text-field
            aut-field-value="target"
            name="href"
            v-if="effectiveValue"
            v-model="effectiveValue.href"
            label="Target"
            :disabled="disable_url"
            clearable
            :rules="linkRules"
            :loading="loading"
            required="true"
            v-bind="hrefAttributes"
            @change="$emit('change', effectiveValue)"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { rules } from "@/rules";
import { fieldMixin } from "@/components/mixin.js";
import { isEqual, defaultsDeep } from "lodash";
import { clone, isExternalLink, cleanupLink } from "@/util.js";
import definition from "./definition";

const debug = require("debug")("atman.components.hyper_link"); // eslint-disable-line

const defaultValue = {
  icon: "",
  title: "",
  href: "",
};

export default {
  name: "Hyperlink",
  mixins: [fieldMixin],
  components: {
    IconField: () => import("@/components/fields/IconField/IconField"),
  },
  props: {
    disable_url: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      effectiveValue: clone(defaultValue),
      definition,
    };
  },
  watch: {
    effectiveValue: {
      deep: true,
      handler() {
        debug(`watching for change`, this.effectiveValue, this.fieldValue);
        if (
          isEqual(this.effectiveValue, this.fieldValue) ||
          isEqual(this.effectiveValue, defaultValue)
        ) {
          return;
        }
        this.fieldValue = clone(this.effectiveValue);
        this._derivePropsData();
      },
    },
  },
  computed: {
    titleWidth() {
      let width = 4;
      if (this.displayAttributes?.hide_icon) {
        width += 4;
      }
      if (this.displayAttributes?.hide_target) {
        width += 4;
      }
      return width;
    },
    isExternalLink() {
      return (
        isExternalLink(this.effectiveLink) ||
        this.displayAttributes?.target == "external"
      );
    },
    target() {
      let result = "";

      if (
        isExternalLink(this.effectiveLink) ||
        this.displayAttributes?.target == "external"
      ) {
        result = "_blank";
      }
      return result;
    },
    linkClasses() {
      const style_link = this.displayAttributes?.style_link == true;
      return style_link ? "behaviour_hyper_link" : "";
    },
    imageAsIconWidth() {
      return this.displayAttributes?.image_icon_width; //Will externalize some day
    },

    effectiveLink() {
      const component = this;
      const debugKey = `effectiveLink_${component.value?.name}`;
      const methodDebug = debug.extend(debugKey);
      let result = component?.effectiveValue?.href;
      if (!result) {
        methodDebug(`no href node. Returning empty`);
        return "";
      }
      let payload = {
        url: result,
        customFunctions: component.replaceIndex(),
        debugKey,
      };
      try {
        result =
          component.$store.getters[`${component.context}/dynamicText`](payload);
        methodDebug(`after replacing variables`, result);
      } finally {
        payload = null;
      }
      const isValidLink = rules.is_link()(result);
      if (isValidLink !== true) {
        methodDebug(`Not a valid link. returning empty`);
        return "";
      }
      result = cleanupLink(result);
      methodDebug(`after cleanup`, result);

      return result;
    },
    effectiveIcon() {
      const component = this;
      let result = component?.effectiveValue?.icon;
      if (!result) {
        debug(`No icon present`);
        return "";
      }
      let payload = {
        url: result,
        customFunctions: component.replaceIndex(),
      };
      try {
        result =
          component.$store.getters[`${component.context}/dynamicText`](payload);
      } finally {
        payload = null;
      }
      debug(`effectiveIcon`, result);
      return result;
    },
    effectiveImage() {
      const component = this;
      let result = component?.effectiveValue?.image;
      if (!result) {
        return "";
      }
      let payload = {
        url: result,
        customFunctions: component.replaceIndex(),
      };
      try {
        result =
          component.$store.getters[`${component.context}/dynamicText`](payload);
      } finally {
        payload = null;
      }
      return result;
    },
    effectiveTitle() {
      const component = this;
      let result = component?.effectiveValue?.title;

      if (!result) {
        return this.effectiveLink;
      }
      let payload = {
        url: result,
        customFunctions: component.replaceIndex(),
      };
      try {
        result =
          component.$store.getters[`${component.context}/dynamicText`](payload);
      } finally {
        payload = null;
      }
      return result;
    },
    linkRules() {
      return [rules.mandatory()];
    },
    hyperlink() {
      return this.effectiveValue || {};
    },
    iconToAppend() {
      return this?.effectiveValue?.icon;
    },
    titleAttributes() {
      return this.displayAttributes?.title;
    },
    hrefAttributes() {
      return this.displayAttributes?.href;
    },
    noValueSpace() {
      return this.displayAttributes?.no_value_space == true;
    },
  },
  mounted() {
    this.determineEffectiveValue();
  },
  methods: {
    _afterFieldValueUpdate() {
      this.determineEffectiveValue();
    },
    updateIcon(value) {
      const newIcon = typeof value == "string" ? value : value?.icon;
      if (newIcon != this.effectiveValue?.icon) {
        this.effectiveValue.icon = newIcon;
        this.$emit("change", this.effectiveValue);
      }
    },
    determineEffectiveValue() {
      debug(`fieldValue`, this.fieldValue);
      const fieldValue = this.fieldValue;
      if (typeof fieldValue === "string") {
        this.effectiveValue = {
          icon: "",
          title: fieldValue,
          href: fieldValue,
        };
        return;
      }
      this.effectiveValue = fieldValue
        ? defaultsDeep({}, fieldValue, {
            icon: "",
            title: "",
            href: "",
          })
        : {
            icon: "",
            title: "",
            href: "",
          };
      debug("effectiveValue", this.effectiveValue);
    },
    _derivePropsData() {
      const propsData = {
        value: {
          name: `${this.value.name}_icon`,
          mode: this.mode,
          type: "icon",
          value: {
            icon: this.effectiveValue?.icon,
          },
        },
      };
      this.propsData = clone(propsData);
      debug(`propsData`, propsData, this.propsData);
    },
  },
};
</script>
<style lang="scss" scoped>
a.behaviour_hyper_link {
  &:link {
    text-decoration: none;
    color: inherit;
  }
  &:visited {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
}
.nolink {
  height: 1em;
}
</style>
