<template>
  <div>
    <v-chip
      aut-availability
      class="ma-2"
      :color="color"
      text-color="white"
      v-if="fieldValue"
    >
      <span aut-available>{{ fieldValue.available }}</span>
      &nbsp;<span>/</span>&nbsp;
      <span aut-total>{{ fieldValue.total }}</span>
    </v-chip>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.availability");
debug(`availability`);
export default {
  name: "Availability",
  mixins: [fieldMixin],
  data() {
    return {
      definition,
    };
  },
  computed: {
    color() {
      const { primary, warning, error } = this.displayAttributes?.colors || {};

      let result = primary;
      if (this.fieldValue.available == 0) {
        result = error;
      } else if (this.fieldValue.available < 0.25 * this.fieldValue.total) {
        result = warning;
      }
      return result;
    },
  },
};
</script>
