<template>
  <div>
    <v-row dense v-if="showEditDialog == false">
      <v-col cols="12">
        <div class="d-flex">
          <span>
            {{ eventDescription }}
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-1"
                v-bind="attrs"
                v-on="on"
                @click="showEditDialog = true"
                >mdi-pencil</v-icon
              >
            </template>
            Edit
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showEditDialog" @click:outside="showEditDialog = false">
      <v-card>
        <DialogTitle @close="showEditDialog = false" title="Edit Event" />
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                label="ID"
                hint="ID of event"
                persistent-hint
                clearable
                v-model="event.id"
                @blur="updateEvent(event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                dense
                label="Type"
                hint="Type of event"
                item-text="label"
                item-value="id"
                persistent-hint
                clearable
                v-model="event.type"
                :items="eventTypes"
                @blur="updateEvent(event)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <QuestionEventDesigner
            v-if="event.type == 'display_question'"
            :event="event"
          />
          <v-row dense>
            <RulesDesigner :rules="event.rules" />
          </v-row>
          <v-row dense>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Events ({{
                    event.next ? +event.next.length : "None"
                  }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="12" v-if="!event.next || !event.next.length">
                      <v-btn>Add Event</v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      v-for="(nextEvent, i) in event.next"
                      :key="'next_event' + i"
                    >
                      <TriggerEventDesigner
                        :fixed_type="true"
                        :event="nextEvent"
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { eventTypes, getEventDescription } from "../util";
import QuestionEventDesigner from "./QuestionEventDesigner.vue";
import TriggerEventDesigner from "./TriggerEventDesigner.vue";
import RulesDesigner from "../rules/RulesDesigner.vue";
import { dialogMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.questionnaire.designer.event"); // eslint-disable-line
export default {
  name: "EventDesigner",
  mixins: [dialogMixin],
  props: {
    event: {},
  },
  data() {
    return {
      showEditDialog: false,
    };
  },
  components: {
    QuestionEventDesigner,
    TriggerEventDesigner,
    RulesDesigner,
  },
  computed: {
    eventDescription() {
      return getEventDescription(this.event);
    },
  },
  created() {
    this.eventTypes = eventTypes;
  },
  mounted() {
    debug(`In event designer`, this.event);
  },
  methods: {
    updateEvent(event) {
      debug(`updateEvent Invoked`, event);
    },
  },
};
</script>
