<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-resize-widget-dialog>
      <DialogTitle @close="$emit('close')" title="Resize Widget" />
      <v-card-text aut-width-slider>
        <v-slider
          ticks="always"
          :tick-size="5"
          v-model="width"
          class="align-center"
          label="Width"
          :max="12"
          :min="3"
          dense
        >
          <template v-slot:append>
            <v-text-field
              v-model="percentage"
              disabled
              class="mt-0 pt-0"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-resize-widget-cancel>
          Cancel
        </v-btn>
        <v-btn color="primary" @click="updateWidth" aut-action-resize-widget>
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.resize_widget"); // eslint-disable-line

export default {
  name: "ResizeWidget",
  mixins: [dialogMixin],
  data() {
    return {
      display: true,
      width: 50,
    };
  },
  computed: {
    percentage() {
      return `${Math.round((this.width / 12) * 100)}%`;
    },
  },
  props: {
    contextObj: {
      type: Object,
    },
  },
  mounted() {
    const widget = this.$store.getters["preferences/isPinned"](
      this.contextObj.full_path
    );
    if (widget) {
      this.width = widget.width == "auto" ? 6 : widget.width;
    }
  },
  methods: {
    updateWidth() {
      const width = this.width;
      this.$store.dispatch("preferences/updatePinned", {
        url: this.contextObj.full_path,
        width,
      });
      this.$emit("update");
    },
  },
};
</script>
