<template>
  <v-container fluid>
    <v-row class="flex-wrap" v-if="featureList.length">
      <v-text-field
        label="Search"
        class="smaller mb-0 pb-0"
        prepend-icon="mdi-magnify"
        v-model="searchCriteria"
        clearable
      ></v-text-field>
    </v-row>
    <v-row class="flex-wrap" v-if="filteredFeatureList.length">
      <FeatureSwitch
        v-for="feature in filteredFeatureList"
        :key="feature.name"
        :feature="feature"
        v-on="$listeners"
      />
    </v-row>
  </v-container>
</template>
<script>
import FeatureSwitch from "./FeatureSwitch";
import { defaultsDeep } from "lodash";
const debug = require('debug')('feature_switch')  // eslint-disable-line
export default {
  name: "FeatureSwitches",
  data() {
    return {
      searchCriteria: "",
    };
  },
  props: {
    features: {
      type: Object,
    },
  },
  components: {
    FeatureSwitch,
  },
  computed: {
    filteredFeatureList() {
      let result = [];
      if (!this.searchCriteria) {
        result = this.featureList;
      } else {
        result = this.featureList.filter((feature) => {
          return (
            JSON.stringify(feature)
              .toLowerCase()
              .indexOf(this.searchCriteria.toLowerCase()) != -1
          );
        });
      }
      debug(`filteredFeatureList`, result);
      return result;
    },
    featureList() {
      const list = Object.keys(this.features).map((key) => {
        const value = this.getFeatureValue(key);
        return defaultsDeep(
          {
            name: key,
            value,
          },
          this.features[key]
        );
      });
      debug(`list`, list);
      return list;
    },
  },
};
</script>
