<template>
  <v-row class="pt-2">
    <v-col cols="12">
      <v-autocomplete
        dense
        label="Condition Type"
        v-model="conditionType"
        item-text="label"
        item-value="id"
        :items="conditionTypes"
      />
    </v-col>
    <v-col cols="12">
      <RuleChecks :checks="checks" />
    </v-col>
  </v-row>
</template>
<script>
import RuleChecks from "./checks/RuleChecks.vue";
export default {
  name: "RuleConditionDesigner",
  components: {
    RuleChecks,
  },
  props: {
    condition: {},
  },
  data() {
    const conditionType = Object.keys(this.condition)[0];
    return {
      conditionType,
      checks: this.condition[conditionType],
    };
  },
  created() {
    this.conditionTypes = [
      {
        id: "any",
        label: "Any",
      },
      {
        id: "all",
        label: "All",
      },
    ];
  },
};
</script>
