export default {
  id: "roles_list_page",
  title: "Roles",
  _permissions: {
    enabled: true,
    permission: {
      value: "list_roles",
    },
  },
  classes: "behavior-no-fav behavior-no-pin behavior-no-editor",
  subtitle: "",
  content: [
    {
      apis: {},
      definition: {
        actions: [],
        fields: [
          {
            name: "permission",
            type: "permissions_manager",
            label: "Permissions",
          },
        ],
        title: "",
      },
      display: {
        width: "12",
      },
      id: "role_manager",
      title: "Roles",
      type: "form",
    },
  ],
};
