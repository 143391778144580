<template>
  <div>
    <div class="d-flex">
      <v-checkbox
        dense
        class="mt-4"
        aut-mode-condition-checkbox
        @change="toggleConditions"
        v-model="hasConditions"
      >
        <template v-slot:label>
          <span class="text-caption">Conditions</span>
        </template>
      </v-checkbox>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mt-5"
            v-bind="attrs"
            v-on="on"
            :disabled="!hasConditions"
            x-small
            @click="showConditionsDialog = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        Add/Maintain conditions
      </v-tooltip>
    </div>
    <v-dialog
      v-model="showConditionsDialog"
      aut-conditions-dialog
      @keydown.esc.stop.prevent="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card class="pa-2">
        <DialogTitle @close="closeDialog" title="Conditions" />
        <v-card-text>
          <v-btn small @click="addCondition" aut-add-condition text>
            Add Condition</v-btn
          >

          <v-row dense>
            <v-col
              cols="12"
              v-for="(condition, i) in conditions"
              :key="i"
              class=""
            >
              <div class="relative">
                <EditorActions
                  :actions="designActions"
                  @remove="removeCondition(i)"
                />
              </div>
              <v-text-field label="Rule" v-model="condition.rule" />
              <v-autocomplete
                v-if="showValueAsDropdown"
                label="Value"
                v-model="condition.value"
                clearable
                item-text="label"
                item-value="value"
                :hint="getHint(condition.value)"
                persistent-hint
                :items="options"
              />
              <v-text-field v-else label="Value" v-model="condition.value" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="updateConditions">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import EditorActions from "@/components/editor/EditorActions";
const debug = require("debug")("atman.components.conditions_editor"); // eslint-disable-line

const designActions = [
  {
    id: "remove",
    label: "Remove Condition",
    icon: "mdi-delete",
    event: "remove",
  },
];

export default {
  name: "ConditionsEditor",
  mixins: [dialogMixin],
  props: {
    name: {
      type: String,
    },
    value: {},
    options: {
      type: Array,
    },
  },
  components: {
    EditorActions,
  },
  data() {
    return {
      showConditionsDialog: false,
    };
  },
  computed: {
    showValueAsDropdown() {
      return Array.isArray(this.options);
    },
    conditions() {
      return this.value?.conditions;
    },
    hasConditions: {
      get() {
        let result = Array.isArray(this.value?.conditions);
        debug(`hasConditions`, this.value, result);
        return result;
      },
      set(val) {
        debug(`Setting hasConditions to [${val}]`);
      },
    },
  },
  created() {
    this.designActions = designActions;
  },
  mounted() {
    debug(`props`, this.value, this.options);
  },
  methods: {
    getHint(currentValue) {
      const option = (this.options || []).find(
        ({ value }) => currentValue == value
      );
      debug(`getHint`, currentValue, option);
      return option?.hint;
    },
    closeDialog() {
      this.showConditionsDialog = false;
    },
    addCondition() {
      debug(`In addCondition`);
      this.value.conditions = this.value.conditions || [];
      this.value.conditions.push({
        rule: "{_<variable>||<default_value>} == <some_value>",
        value: null,
      });
    },
    emitUpdate(updatedVal) {
      this.$emit("update", updatedVal);
    },
    toggleConditions() {
      debug(`In toggleConditions`);
      if (this.hasConditions) {
        this.emitUpdate("");
      } else {
        this.emitUpdate({
          conditions: [],
        });
      }
    },
    removeCondition(index) {
      this.value.conditions.splice(index, 1);
    },
    updateConditions() {
      this.emitUpdate(this.value);
      this.closeDialog();
    },
  },
};
</script>
