<template>
  <Field :definition="bookDefinition" :context="context" />
</template>
<script>
export default {
  name: "BookContainer",
  props: {
    book: {},
    context: {},
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  data() {
    return {
      bookDefinition: {
        type: "book",
        value: this.book,
        display: {
          attributes: {
            cost: {
              display: true,
            },
            on_click: {
              type: "navigation",
              url: "{_context->path}/books",
            },
          },
        },
      },
    };
  },
};
</script>
