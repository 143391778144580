<template>
  <div>
    <v-radio-group
      v-if="!isDisplayMode"
      v-model="fieldValue"
      :label="label"
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
      :rules="applicableRules"
      dense
      :hint="value.hint"
      class="mt-0"
    >
      <v-radio
        aut-radio
        v-for="(option, i) in value.seedData"
        :key="i"
        :label="option.name"
        :color="displayAttributes.color"
        :value="option.id"
      ></v-radio>
    </v-radio-group>
    <div v-if="isDisplayMode">
      <FieldLabel
        v-if="isDisplayMode"
        mode="display"
        :label="label"
        :attributes="labelAttributes"
        :definition="value"
      >
        <FieldDisplayValue :value="displayValue" :definition="value" />
      </FieldLabel>
    </div>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.radio_button"); // eslint-disable-line 

export default {
  name: "RadioButton",
  mixins: [fieldMixin],
  data() {
    return {
      definition,
    };
  },
  computed: {
    displayValue() {
      const component = this;
      const value = component.value;

      if (typeof component.fieldValue == "undefined" || !value.seedData) {
        return "";
      }
      const selectedItem = value.seedData.find(({ id }) => {
        return `${id}` == `${component.fieldValue}`;
      });
      return selectedItem ? selectedItem.name : "";
    },
  },
};
</script>
