<template>
  <v-dialog
    v-model="displayDialog"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-workflow-dialog>
      <v-card-title class="headline grey lighten-2" aut-workflow-task-name>
        {{ blockDefinition.display.label }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle class="text-left">
        {{ blockDefinition.display.description }}
      </v-card-subtitle>
      <v-card-text>
        <WorkflowSaveAsDialog
          :block="blockDefinition"
          v-if="saveAsDialog"
          @close="saveAsDialog = false"
        />
        <v-row dense>
          <v-col
            cols="12"
            v-if="blockDefinition.data.step_type == 'display_form'"
          >
            <LookUpField v-model="stepDefinition" @update="updateLookup" />
          </v-col>
          <v-col
            cols="12"
            v-if="blockDefinition.data.step_type == 'invoke_api'"
          >
            <APIDesigner
              @update:definition="updateAPI"
              :definition.sync="apiDefinition"
              type="submit"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="blockDefinition.data.step_type == 'invoke_logic'"
          >
            <CodeEditor
              v-model="codeDefinition"
              :context="context"
              @update="updateCode"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-wfnode-cancel> Cancel </v-btn>
        <v-btn @click="saveAsDialog = true" aut-action-wfnode-save>
          Save As Template
        </v-btn>
        <v-btn
          color="primary"
          @click="$emit('update:block', blockDefinition)"
          aut-action-edit-wfnode-update
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { pick, defaultsDeep } from "lodash";
import LookUpField from "@/components/fields/LookupField";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";
import CodeEditor from "@/components/fields/CodeEditor";
import WorkflowSaveAsDialog from "./WorkflowSaveAsDialog";
import { clone, uniqueID } from "@/util.js";
const debug = require("debug")("atman.components.workflow_dialog");
debug(`workflow_dialog`);

const step_types = [
  { label: "Display Form", value: "display_form" },
  { label: "Invoke API", value: "invoke_api" },
  { label: "Invoke Logic", value: "invoke_logic" },
];

export default {
  name: "WorkflowDialog",
  props: {
    block: {
      type: Object,
    },
  },
  components: {
    LookUpField: LookUpField.control,
    APIDesigner,
    CodeEditor: CodeEditor.control,
    WorkflowSaveAsDialog,
  },
  data() {
    debug(`block`, this.block);
    const blockDefinition = {};
    defaultsDeep(blockDefinition, clone(this.block), {
      data: {
        step_type: "",
        step_details: {},
      },
    });

    return {
      blockDefinition,
      displayDialog: true,
      context: uniqueID(),
      saveAsDialog: false,
    };
  },
  computed: {
    stepDefinition() {
      if (this.blockDefinition.data.step_type != "display_form") {
        return null;
      }
      const formStepDefinition = {
        name: "form",
        value: this?.blockDefinition?.data?.step_details?.form,
        label: "Form",
        options: {
          apis: {
            data: {
              url: `workflow_editor/forms`,
            },
          },
        },
      };
      return formStepDefinition;
    },
    apiDefinition: {
      get() {
        if (this.blockDefinition.data.step_type != "invoke_api") {
          return;
        }
        // eslint-disable-next-line
        this.blockDefinition.data.step_details.api = this?.blockDefinition?.data
          ?.step_details?.api || {
          type: "",
          url: "",
        };
        return this.blockDefinition.data.step_details.api;
      },
      set(value) {
        this.blockDefinition.data.step_details.api = value;
      },
    },
    codeDefinition() {
      if (this.blockDefinition.data.step_type != "invoke_logic") {
        return;
      }
      // eslint-disable-next-line
      this.blockDefinition.data.step_details.code =
        this.blockDefinition.data.step_details.code || "";
      return {
        type: "code_editor",
        value: this.blockDefinition.data.step_details.code,
        mode: "input",
      };
    },
  },
  created() {
    this.step_types = step_types;
    debug(`In created of WorkflowDialog`);
  },
  mounted() {
    debug(`In mounted of WorkflowDialog`);
  },
  methods: {
    updateLookup(value) {
      this.blockDefinition.data.step_details.form = value;
      this.blockDefinition.data.step_details = pick(
        this.blockDefinition.data.step_details,
        "form"
      );
    },
    updateAPI() {
      this.blockDefinition.data.step_details = pick(
        this.blockDefinition.data.step_details,
        "api"
      );
    },
    updateCode(value) {
      this.blockDefinition.data.step_details.code = value;
      this.blockDefinition.data.step_details = pick(
        this.blockDefinition.data.step_details,
        "code"
      );
    },
  },
};
</script>
