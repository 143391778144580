<template>
  <v-img
    class="behavior_clickable"
    :max-width="logoWidth"
    :lazy-src="no_image"
    :max-height="max_height"
    v-if="displayImage"
    cover
    :src="logoSrc"
    aut-logo-right
  ></v-img>
</template>
<script>
import { mapState, mapActions } from "vuex";

const debug = require("debug")("atman.components.site_logo_right"); // eslint-disable-line
export default {
  name: "SiteLogoRight",
  props: {
    max_height: {},
  },
  computed: {
    ...mapState(["skin", "no_image"]),
    logo() {
      let logo = this?.skin?.logoRight || this?.skin?.logo2;
      if (this.isSmallScreen) {
        return (
          this?.skin?.mobile?.logoRight || this?.skin?.mobileLogoRight || logo
        );
      }
      return logo;
    },
    logoSrc() {
      return this.logo && this?.logo?.image;
    },

    logoWidth() {
      const result = this?.logo?.width || "";
      return result || 40;
    },
    displayImage() {
      return !!this.logoSrc;
    },
  },
  async mounted() {
    debug(`Mounted of Site Logo Right. props`, this.$props);
    await this.fetchSkin();
  },
  methods: {
    ...mapActions(["fetchSkin"]),
  },
};
</script>
