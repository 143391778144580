<template>
  <PermissionRolesManager :context="context" />
</template>

<script>
import PermissionRolesManager from "./PermissionRolesManager";
const debug = require("debug")("atman.components.permissions_context_manager"); // eslint-disable-line

export default {
  name: "PermissionContextManager",
  props: {
    context: {
      type: String,
    },
  },
  data() {
    return {};
  },
  components: {
    PermissionRolesManager,
  },
};
</script>
