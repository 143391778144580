export default {
  type: "book_page_manager",
  label: "Pages",
  filters: ["list_editor", "field_tutorial", "form_editor"],
  description: "Displays Pages",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Page Manager",
      label: "Default",
      variables: {
        data: {
          default: [],
        },
      },
      value: {
        apis: {
          data: {
            type: "get",
            url: "/applications/eblisher/books/id_0/pages",
          },
        },
        name: "default",
        label: "Pages",
        type: "book_page_manager",
      },
    },
  ],
};
