<template>
  <v-card
    class="transparent"
    flat
    max-height="400px"
    height="400px"
    style="overflow-y: scroll"
  >
    <v-card-text>
      <div
        v-if="category.tooltip"
        class="behaviour_metadata"
        v-html="category.tooltip"
      ></div>
      <v-btn @click="clear" v-if="model">Remove</v-btn>
      <v-radio-group v-model="model" dense @change="updateClass">
        <v-radio
          v-for="target in category.category"
          :key="target.id"
          :label="target.label"
          :value="target.id"
        >
          <template v-slot:label>
            <div :class="target.id">{{ target.label }}</div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>
<script>
import { intersection } from "lodash";
import { removeIncompatibleClasses } from "./util";
const debug = require('debug')('atman.field_settings.class_control')  // eslint-disable-line

export default {
  name: "ClassControl",
  mounted() {
    this.getCurrentClass(this.category.category);
  },
  props: {
    field: {},
    category: {},
  },
  data() {
    return {
      model: null,
    };
  },
  methods: {
    clear() {
      let classes = this.field?.display?.classes || [];
      classes = removeIncompatibleClasses(classes, this.model);
      debug(`Emitting update`, classes);
      this.$emit("update", classes);
    },
    getCurrentClass(list) {
      const classes = intersection(
        list.map(({ id }) => id),
        this.field?.display?.classes || []
      );
      debug(`field`, classes);
      this.model = classes[0];
    },
    updateClass() {
      let classes = this.field?.display?.classes || [];
      classes = removeIncompatibleClasses(classes, this.model);
      classes.push(this.model);
      debug(`Emitting update`, classes);
      this.$emit("update", classes);
    },
  },
};
</script>
