<template>
  <v-row dense>
    <v-col cols="12">
      <v-switch
        dense
        aut-edit-field-multiple
        name="multiple"
        v-model="definition.multiple"
        label="Multiple"
        persistent-hint
        hint="Choose if the dropdown is a multi-select"
        @change="$emit('update:definition', definition)"
      ></v-switch>
    </v-col>
    <SeedDataDesigner
      :definition="definition"
      @update:definition="updateDefinition"
    />
  </v-row>
</template>
<script>
const debug = require("debug")("atman.components.dropdown_designer");

debug(`dropdown_designer`);
export default {
  name: "DropdownDesigner",
  components: {
    SeedDataDesigner: () =>
      import("@/components/fields/PageEditor/SeedDataDesigner"),
  },
  props: {
    definition: {
      type: Object,
    },
  },
  methods: {
    updateDefinition(definition) {
      debug(`DropdownDesigner updateDefinition`, definition);
      this.$emit("update:definition", definition);
    },
  },
};
</script>
