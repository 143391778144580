import {
  validate_on_blur,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  border_color,
  customiseAttribute,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Password",
  type: "password",
  description: "Displays a password field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description:
        "The default Password field with constraints mentioned under <code>rules</code>.",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        label: "Password",
        type: "password",
        rules: [
          { min_length: "2" },
          { max_length: "20" },
          { has_numbers: true },
          { has_upper_case: true },
          { has_lower_case: true },
          { has_special_characters: true },
        ],
      },
    },
    {
      id: "display_password",
      description: "The display mode for Password",
      label: "Display",
      variables: {
        data: {
          display_password: "abcde",
        },
      },
      value: {
        name: "display_password",
        label: "Password",
        type: "password",
        mode: "display",
        rules: [
          { min_length: "2" },
          { max_length: "20" },
          { has_numbers: true },
          { has_upper_case: true },
          { has_lower_case: true },
          { has_special_characters: true },
        ],
      },
    },
    {
      id: "confirm_password",
      description: `Use display attribute <code>"mode"="confirm"</code> to add confirm password input`,
      label: "Confirm Password",
      variables: {
        data: {
          confirm_password: null,
        },
      },
      value: {
        name: "confirm_password",
        label: "Password",
        type: "password",
        mode: "input",
        rules: [
          { min_length: "2" },
          { max_length: "20" },
          { has_numbers: true },
          { has_upper_case: true },
          { has_lower_case: true },
          { has_special_characters: true },
        ],
        display: {
          attributes: {
            mode: "confirm",
          },
        },
      },
    },
    {
      id: "password_delayed",
      description:
        "The field can be configured to delay validation till after the user has finished typing",
      label: "Delayed validation",
      variables: {
        data: {
          password_delayed: null,
        },
      },
      value: {
        name: "password_delayed",
        label: "Password",
        type: "password",
        rules: [{ min_length: "2" }, { has_numbers: true }],
        display: {
          attributes: {
            validate_on_blur: true,
          },
        },
      },
    },
  ],
  attributes: [
    validate_on_blur,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    no_value_text,
    no_value_behavior,
    dense,
    customiseAttribute("color", { value: "secondary" }),
    border_color,
    {
      name: "mode",
      type: "select",
      hint: "Show a password confirmation prompt",
      value: "",
      options: ["confirm"],
    },
  ],
  classes: [],
};
