<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="800"
      :scrollable="false"
      @keydown.esc.stop.prevent="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card aut-add-embed-dialog :aut-dialog="value.name">
        <DialogTitle @close="closeDialog" title="Embed Content" />
        <v-card-text>
          <v-textarea
            :rows="2"
            auto-grow
            :aut-textarea="value.name"
            :name="value.name"
            v-model="fieldValue"
            :label="label"
            :hint="value.hint"
            v-bind="displayAttributes"
            v-bind:class="displayClasses"
            :rules="applicableRules"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FieldLabel
      :definition="value"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
    </FieldLabel>
    <div v-if="!isDisplayMode && !fieldValue">
      <v-btn
        v-if="!dialog"
        aut-add-embed
        text
        outlined
        color="primary"
        @click="dialog = true"
      >
        <v-icon class="behavior_action_icon" :aut-action-icon="value.icon">{{
          value.icon || "mdi-crop-square"
        }}</v-icon>
        {{ buttonLabel }}
      </v-btn>
    </div>
    <div
      v-else-if="fieldValue"
      class="behavior_embed_container"
      aut-embedded-content
    >
      <div v-html="fieldValue" class="d-flex justify-center"></div>
      <v-btn
        v-if="!isDisabled && !isDisplayMode"
        class="behavior_change_embed"
        @click="dialog = true"
        aut-change-embed
        icon
        ><v-icon>mdi-upload</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import { fieldMixin, dialogMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.components.embed_widget"); // eslint-disable-line

export default {
  name: "EmbedWidget",
  mixins: [fieldMixin, dialogMixin],
  data() {
    return {
      dialog: false,
      definition,
    };
  },
  computed: {
    buttonLabel() {
      return this.displayAttributes?.button_label;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_embed_container {
  > div {
    max-width: 100%;
    overflow-x: auto;
  }
  position: relative;
  &:hover {
    .behavior_change_embed {
      display: initial;
    }
  }
  .behavior_change_embed {
    display: none;
    position: absolute;
    color: var(--v-primary-base);
    bottom: 0px;
    left: 0px;
    background-color: var(--v-primary-lighten5);
  }
}
</style>
