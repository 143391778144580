export default {
  type: "lookup",
  label: "Lookup",
  description: "Displays a Lookup",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      label: "Default",
      value: {
        name: "some_name",
        label: "Age",
        type: "lookup",
        value: "some_value",
        options: {
          apis: {
            data: {
              url: "/developer/navigation",
            },
          },
        },
      },
    },
    {
      id: "static",
      label: "Static",
      value: {
        name: "some_name",
        label: "Age",
        type: "lookup",
        value: "some_value",
        options: {
          value: {
            name: "some_name",
            label: "Age",
            type: "tree_view",
            value: {
              id: "calendar",
              name: "Calendar",
              icon: "mdi-calendar",
              rat: {
                name: "rat",
                icon: "mdi-rodent",
                canada: {
                  icon: "mdi-leaf-maple",
                  name: "canada",
                  rocket: {
                    icon: "mdi-rocket",
                    name: "rocket",
                  },
                  launch: {
                    icon: "mdi-rocket-launch",
                    name: "rocket launch",
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      id: "path_example",
      label: "Path Selector",
      value: {
        name: "path",
        label: "Geneology",
        type: "lookup",
        path_separator: "/",
        value: "",
        options: {
          value: {
            id: "got",
            name: "got",
            stark: {
              name: "Starks",
              rickard_stark: {
                name: "Rickard Stark",
                "eddard-stark": {
                  name: "Eddard Stark",
                  robb: {
                    name: "Robb Stark",
                  },
                  sansa: {
                    name: "Sansa Stark",
                  },
                },
              },
              lyanna_stark: {
                name: "Lyanna Stark",
                jon: {
                  name: "Eddard Stark",
                },
              },
            },
            targaryen: {
              name: "Targaryen",
              aerys_ii: {
                name: "Aerys II",
                rhaegar: {
                  name: "Rhaegar",
                  rhaenys: {
                    name: "Rhaenys",
                  },
                  aegon: {
                    name: "Aegon",
                  },
                },
                lyanna: {
                  name: "Lyanna",
                },
                viserys: {
                  name: "Viserys",
                },
                daenerys: {
                  name: "Daenerys",
                },
              },
            },
          },
        },
      },
    },
  ],
};
