<template>
  <v-row dense>
    <SeedDataDesigner
      :definition="definition"
      @update:definition="updateDefinition"
    />
  </v-row>
</template>
<script>
const debug = require("debug")("atman.components.radio_designer");

debug(`radio_designer`);
export default {
  name: "RadioDesigner",
  components: {
    SeedDataDesigner: () =>
      import("@/components/fields/PageEditor/SeedDataDesigner"),
  },
  props: {
    definition: {
      type: Object,
    },
  },

  methods: {
    updateDefinition(definition) {
      debug(`RadioDesigner updateDefinition`, definition);
      this.$emit("update:definition", definition);
    },
  },
};
</script>
