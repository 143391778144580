export default {
  categories: {
    dementia: {
      display: {
        attributes: {
          color: "#E0E2DB",
        },
        classes: [],
      },
      events: {
        "dementia-1": {
          answers: [],
          question: "Dementia (TBD)",
          type: "display_question",
        },
      },
      label: "Dementia",
    },
    epilepsy: {
      display: {
        attributes: {
          color: "#FBD1A2",
        },
        classes: [],
      },
      events: {
        "epilepsy-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-2",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Did they pass urine or stool in his/her clothes during an episode?",
          type: "display_question",
        },
        "epilepsy-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Did they ever injure himself/herself or have tongue bite during an episode?",
          type: "display_question",
        },
        "epilepsy-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Was there frothing from mouth during an episode?",
          type: "display_question",
        },
        "epilepsy-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Did they have such an episode while asleep?",
          type: "display_question",
        },
        "epilepsy-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Was the patient completely unconscious during the episode?",
          type: "display_question",
        },
        "epilepsy-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Has an episode ever occurred WITHOUT preceding emotional or mental stress",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.epilepsy.yes",
                    value: 4,
                  },
                ],
              },
              event: {
                params: {
                  message: "Probably Generalised Seizure",
                },
                type: "display_message",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.epilepsy.yes",
                    value: 4,
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-age",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-age": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Is the patient below 10 years old?",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-age",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-fever",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-age",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-duration",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-duration": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Does the patient have more than 1 unprovoked seizure 24 hours apart?",
          type: "display_question",
        },
        "epilepsy-fever": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Did the patient have fever?",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-no-fever-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-yes-fever-1",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-fever-age": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question: "Is the patient below 5 years old?",
          type: "display_question",
        },
        "epilepsy-no-fever-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Were the events brief (less than a few seconds)",
          type: "display_question",
        },
        "epilepsy-no-fever-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Symptoms: Blinking/Staring",
          type: "display_question",
        },
        "epilepsy-no-fever-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Symptoms: Multiple per day",
          type: "display_question",
        },
        "epilepsy-no-fever-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-5",
              },
              type: "trigger_event",
            },
          ],
          question: "Precipitated at bedside by hyperventilation",
          type: "display_question",
        },
        "epilepsy-no-fever-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-6",
              },
              type: "trigger_event",
            },
          ],
          question: "No developmental delay/regression",
          type: "display_question",
        },
        "epilepsy-no-fever-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question: "Onset after 3 years",
          type: "display_question",
        },
        "epilepsy-yes-fever-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Generalised Convulsive?",
          type: "display_question",
        },
        "epilepsy-yes-fever-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Single Episode?",
          type: "display_question",
        },
        "epilepsy-yes-fever-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Lasts <5 minutes?",
          type: "display_question",
        },
        "epilepsy-yes-fever-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-5",
              },
              type: "trigger_event",
            },
          ],
          question: "No post ictal weakness?",
          type: "display_question",
        },
        "epilepsy-yes-fever-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-6",
              },
              type: "trigger_event",
            },
          ],
          question: "No developmental delay/regression?",
          type: "display_question",
        },
        "epilepsy-yes-fever-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-fever-age",
              },
              type: "trigger_event",
            },
          ],
          question: "Regains consciouness completely within 5 min?",
          type: "display_question",
        },
      },
      label: "Epilepsy",
    },
    general: {
      display: {
        attributes: {
          color: "#C3DFE0",
        },
        classes: [],
      },
      events: {
        "general-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from loss of consciousness",
          type: "display_question",
        },
        "general-10": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "temporary",
              name: "Temporary (for a few hours)",
            },
            {
              id: "permanent",
              name: "Permanent",
            },
          ],
          next: [
            {
              params: {
                id: "general-11",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Drooping of eyelids",
          type: "display_question",
        },
        "general-11": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-12",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Episodes of severe facial pain",
          type: "display_question",
        },
        "general-12": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "unilateral",
              name: "Unilateral",
            },
            {
              id: "flashing_lights",
              name: "Associated with flashes of light or circles",
            },
            {
              id: "vomiting",
              name: "Associated with vomiting",
            },
          ],
          next: [
            {
              params: {
                id: "general-13",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Headache?",
          type: "display_question",
        },
        "general-13": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-14",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Any recent change in behavior or personality",
          type: "display_question",
        },
        "general-14": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-15",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family experienced Slowness of movement",
          type: "display_question",
        },
        "general-15": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-16",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family experienced Tremors",
          type: "display_question",
        },
        "general-16": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-17",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Weakness or loss of sensation on one side of body with deviation of face to one side",
          type: "display_question",
        },
        "general-17": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-18",
              },
              type: "trigger_event",
            },
          ],
          question: "Impaired consciouness",
          type: "display_question",
        },
        "general-18": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-19",
              },
              type: "trigger_event",
            },
          ],
          question: "Speech difficulty",
          type: "display_question",
        },
        "general-19": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-20",
              },
              type: "trigger_event",
            },
          ],
          question: "Slurred Speech",
          type: "display_question",
        },
        "general-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from sudden episode of loss of contact with surroundings",
          type: "display_question",
        },
        "general-20": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-21",
              },
              type: "trigger_event",
            },
          ],
          question: "Difficulty in speaking or understanding",
          type: "display_question",
        },
        "general-21": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-22",
              },
              type: "trigger_event",
            },
          ],
          question: "Loss of balance or incoordination while walking",
          type: "display_question",
        },
        "general-22": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-23",
              },
              type: "trigger_event",
            },
          ],
          question: "Dizziness",
          type: "display_question",
        },
        "general-23": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-24",
              },
              type: "trigger_event",
            },
          ],
          question: "Swallowing problems",
          type: "display_question",
        },
        "general-24": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-25",
              },
              type: "trigger_event",
            },
          ],
          question: "Double vision",
          type: "display_question",
        },
        "general-25": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Vision impairment",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-3",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "epilepsy-1",
                    "epilepsy-2",
                    "epilepsy-3",
                    "epilepsy-4",
                    "epilepsy-5",
                    "epilepsy-6",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "unilateral",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: "headache-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: "dementia-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.general.all",
                    value: 1,
                  },
                ],
              },
              event: {
                params: {
                  id: "display_summary",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "general-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from uncontrollable shaking of head, arms or legs",
          type: "display_question",
        },
        "general-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Abnormal speech",
          type: "display_question",
        },
        "general-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Facial paralysis with sideways deviation of the mouth",
          type: "display_question",
        },
        "general-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Weakness or Paralysis of arms or legs ",
          type: "display_question",
        },
        "general-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-8",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Difficulty in walking (exclude individuals with pain or swelling of joints of lower limbs)",
          type: "display_question",
        },
        "general-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-9",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Any loss of sensation or any abnormal sensation in the face, arms and legs",
          type: "display_question",
        },
        "general-9": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-10",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Have you or any other member of your family suffered from Deterioration of memory within the last five years (applicable to subjects above 30 years of age)",
          type: "display_question",
        },
      },
      label: "General",
    },
    headache: {
      display: {
        attributes: {
          color: "#BCD979",
        },
        classes: [],
      },
      events: {
        "headache-1": {
          answers: [],
          question: "Headache (TBD)",
          type: "display_question",
        },
      },
      label: "Headache",
    },
  },
  events: {
    display_summary: {
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                path: "$.general.no",
                value: 25,
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "no_issues",
                label: "Inconclusive",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            any: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-4",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-5",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-6",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-7",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-8",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-16",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-17",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-18",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-19",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-20",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-21",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-22",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-23",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-24",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.general-25",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "stroke",
                label: "Stroke",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "lessThan",
                path: "$.epilepsy.yes",
                value: 3,
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "Uncertain for seizure",
                label: "Uncertain for seizure",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                path: "$.epilepsy.yes",
                value: 3,
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "Probably Focal Seizure",
                label: "Probably Focal Seizure",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "uncertain_for_epilepsy",
                label: "Uncertain for Epilepsy",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "epilepsy_diagonosis",
                label: "Epilepsy Diagonosis",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever-age",
                    value: "yes",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "typical_febrile_seizure",
                label: "Typical Febrile Seizure",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-1",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-2",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-3",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-4",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-5",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-6",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-fever-age",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "atypical_febrile_seizure",
                label: "Atypical Febrile Seizure",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            any: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-no-fever-1",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-no-fever-2",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-no-fever-5",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-no-fever-6",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "typical_absence_seizures",
                label: "Typical Absence Seizures",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "yes",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "probably_absence_seizures",
                label: "Probably Absence Seizures",
              },
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "general_seizure",
                label: "Generalised Seizure",
              },
            },
            type: "assign",
          },
        },
      ],
      type: "display_summary",
    },
  },
  start: {
    params: {
      id: [
        "general-1",
        "general-2",
        "general-3",
        "general-4",
        "general-5",
        "general-6",
        "general-7",
        "general-8",
      ],
    },
    type: "trigger_event",
  },
  summary: {
    title: "Diagnosis",
  },
};
