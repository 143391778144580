<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        >Events
        {{
          eventsList.length ? "(" + eventsList.length + ")" : ""
        }}</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-tabs
          v-model="panelModel"
          aut-events-panel
          vertical
          show-arrows="always"
        >
          <div style="max-height: 300px; overflow: auto">
            <v-tab v-for="event in eventsList" :key="event.id + 'tab'">
              {{ event.id }}
            </v-tab>
          </div>
          <v-tab-item v-for="event in eventsList" :key="event.id + 'tab'">
            <EventDesigner :event="event" :multiple="false" />
          </v-tab-item>
        </v-tabs>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { eventTypes } from "../util";
import EventDesigner from "./EventDesigner.vue";
import { isPlainObject, defaultsDeep, forOwn } from "lodash";
const debug = require('debug')('atman.components.questionnaire.designer.events')  // eslint-disable-line
export default {
  name: "EventsDesigner",
  components: { EventDesigner },
  props: {
    events: {},
  },
  data() {
    return {
      panelModel: 0,
    };
  },
  computed: {
    eventsList: {
      get() {
        let result = [];
        if (isPlainObject(this.events)) {
          forOwn(this.events, (val, key) => {
            result.push(defaultsDeep({ id: key }, val));
          });
          // Ensure the events are displayed in the right order
          result = result.sort((event1, event2) => {
            if (event1.id.indexOf("-") == -1 || event2.id.indexOf("-") == -1) {
              return 0;
            }
            let event1ID = event1.id.split("-")[1] * 1;
            let event2ID = event2.id.split("-")[1] * 1;
            return event1ID - event2ID;
          });
        } else if (Array.isArray(this.events)) {
          result = [...this.events];
        }
        return result;
      },
      set(val) {
        if (isPlainObject(this.events)) {
          val.forEach((item) => {
            this.events[item.id] = item;
          });
        } else {
          this.events = [...this.val];
        }
      },
    },
  },
  created() {
    this.eventTypes = eventTypes;
  },
  mounted() {
    debug(`in EventsDesigner`, this.events);
  },
  methods: {},
};
</script>
