<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-pin-page-dialog>
      <DialogTitle @close="$emit('close')" title="Pin Page" />
      <v-card-text>
        <v-row dense>
          <v-col cols="12" class="py-0">
            <div class="d-flex">
              <v-text-field
                label="URL of page being pinned"
                hint="If the URL contains an ID and you want to pin the page for ALL IDs, please generalise the URL (replace the ID with '*')"
                persistent-hint
                clearable
                v-model="sourceURL"
              ></v-text-field>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mt-4"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :disabled="!sourceURL"
                    @click="sourceURL = generaliseURL(sourceURL)"
                    ><v-icon>mdi-pipe</v-icon></v-btn
                  >
                </template>
                Replace IDs with *
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-radio-group
              label="Target Panel"
              v-model="target"
              persistent-hint
              dense
              row
              hint="Choose the panel to pin this page to"
            >
              <v-radio
                v-for="target in targets"
                :key="target.id"
                :label="target.name"
                :value="target.id"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-radio-group
              v-model="width"
              label="Width"
              dense
              row
              persistent-hint
              hint="Choose how much width the pinned page should takeup"
            >
              <v-radio
                :aut-page-width="option.value"
                v-for="(option, i) in widths"
                :key="i"
                :label="option.label"
                :value="option.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0 pt-4">
            <div class="d-flex">
              <v-text-field
                v-if="dynamicTargetEnabled"
                label="Add Target URL(s)"
                hint="If targets are not specified, the pinned page will be visible on all screens. If the target URL has an ID and you want to pin to a page with any ID, generalise the URL (i.e. replace the ID with '*')"
                persistent-hint
                clearable
                v-model="newPage"
                v-on:keydown.enter.stop.prevent="addTarget"
              ></v-text-field>
              <div class="mt-4 d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!newPage"
                      @click="newPage = generaliseURL(newPage)"
                      ><v-icon>mdi-pipe</v-icon></v-btn
                    >
                  </template>
                  Replace IDs with *
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="addTarget"
                      :disabled="!newPage"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-tooltip-plus-outline</v-icon></v-btn
                    >
                  </template>
                  Add target URL
                </v-tooltip>
              </div>
            </div>
            <div class="mt-2">
              <v-chip
                class="mr-2"
                v-for="id in targetURLs"
                :key="id"
                close
                @click:close="removeTarget(id)"
              >
                {{ id }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="$emit('close')"
          aut-action-pin-page-cancel
        >
          Cancel
        </v-btn>
        <v-btn color="error" @click="remove" aut-action-pin-page-remove>
          Remove
        </v-btn>
        <v-btn
          color="primary"
          aut-action-pin-page-submit
          @click="submit"
          class="ml-2"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { widths, generaliseURL, isSamePageWildCard } from "@/util.js";
import { mapState, mapActions } from "vuex";
import { LAYOUTS } from "@/constants";
import { dialogMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.pin_page_dialog"); //eslint-disable-line

export default {
  name: "PinPageDialog",
  mixins: [dialogMixin],
  props: {
    url: {
      type: String,
    },
  },
  computed: {
    ...mapState(["skin"]),
    dynamicTargetEnabled() {
      return this.isFeatureEnabled("dynamic_pages.allow_target");
    },
  },
  data() {
    return {
      newPage: "",
      targetURLs: [],
      sourceURL: this.url,
      display: true,
      width: "6",
      target: "/dashboard",
      targets: [],
    };
  },
  created() {
    this.widths = widths;
    this.generaliseURL = generaliseURL;
  },
  mounted() {
    this.determineTargetPanels();
    this.determineCurrentConfiguration();
  },
  methods: {
    ...mapActions("preferences", ["addPinned"]),
    ...mapActions("preferences", ["removePinned"]),
    removeTarget(url) {
      this.$set(
        this,
        "targetURLs",
        this.targetURLs.filter((id) => id != url)
      );
    },
    addTarget() {
      this.targetURLs.push(this.newPage);
      this.$nextTick(() => {
        this.newPage = "";
      });
    },
    determineCurrentConfiguration() {
      let targetURLs = [];
      let width = "6";
      let target = "/dashboard";
      const pinnedPageDetails = this.skin.pinned.find(({ href }) => {
        return isSamePageWildCard(href, this.sourceURL);
      });
      if (pinnedPageDetails) {
        this.sourceURL = pinnedPageDetails.href;
        targetURLs = pinnedPageDetails.pages || targetURLs;
        width = pinnedPageDetails.width || width;
        target = pinnedPageDetails.target || target;
      }

      this.targetURLs = targetURLs;
      this.width = width;
      this.target = target;
    },
    determineTargetPanels() {
      const homePage = {
        id: "/dashboard",
        name: "Center Panel",
      };
      const leftPanel = {
        id: "/left_panel",
        name: "Left Panel",
      };
      const rightPanel = {
        id: "/right_panel",
        name: "Right Panel",
      };
      let targets = [];
      const currentLayout = this.skin.layout?.value || LAYOUTS.CLASSIC;
      const isThreePanelLayout = currentLayout === LAYOUTS.THREE_PANEL;
      const isTwoPanelLayout = currentLayout === LAYOUTS.TWO_PANEL;

      let secondPanel =
        this.getFeatureValue("layout.two_panel.position", "Left") == "Left"
          ? leftPanel
          : rightPanel;

      if (isThreePanelLayout) {
        targets = [homePage, leftPanel, rightPanel];
      } else if (isTwoPanelLayout) {
        targets = [homePage, secondPanel];
      } else {
        targets = [homePage];
      }
      this.targets = targets;
    },
    remove() {
      this.removePinned({ url: this.sourceURL });
      this.$emit("close");
    },
    submit() {
      const input = {
        url: this.sourceURL,
        width: this.width,
        target: this.target,
      };
      if (this.dynamicTargetEnabled) {
        input.pages = this.targetURLs;
      }
      this.addPinned(input);
      this.displaySuccessMessage("Page pinned");
      this.$emit("close");
    },
  },
};
</script>
