<template>
  <v-row dense>
    <v-col cols="12" aut-variables-config>
      <p v-if="!variables.length" aut-no-values-message>
        No variables added yet. Please click the "Add" button to add one
      </p>
      <div v-for="(keyValue, i) in variables" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card
            aut-workflow-variable
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            class="mt-2"
            outlined
          >
            <div class="float-right grey--text mt-n2">
              <EditorAction
                :action="designAction"
                @remove="removeKeyValue(i)"
              />
            </div>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" lg="6">
                  <v-text-field
                    dense
                    aut-workflow-variable-id
                    label="ID"
                    :rules="idRules"
                    v-model="keyValue.id"
                    required
                    :error-messages="getErrorMessageKey(i)"
                    @change="addKey(i)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    aut-workflow-variable-value
                    dense
                    small
                    required
                    v-model="keyValue.value"
                    :error-messages="getErrorMessageValue(i)"
                    label="Value"
                    @change="addValue(i)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </div>
      <div class="d-flex align-content-end">
        <v-spacer />
        <v-btn class="mt-2" @click="addKeyValue" aut-action-add-variable
          >Add</v-btn
        >
      </div>
    </v-col>
  </v-row>
</template>
<script>
import EditorAction from "@/components/editor/EditorActions/EditorAction.vue";

import { rules } from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/WorkflowEditor/store.js";
const debug = require("debug")("atman.components.workflow_variables");
debug(`workflow_variables`);
const isDuplicate = (variables, index, fieldName) => {
  const beingAdded = variables[index];
  let isADuplicate = false;
  variables.forEach((seedValue, i) => {
    if (index == i) {
      return;
    }
    if (seedValue[fieldName] == beingAdded[fieldName]) {
      isADuplicate = true;
      return false;
    }
  });
  return isADuplicate;
};

export default {
  name: "WorkflowVariables",
  components: {
    EditorAction,
  },
  data() {
    let workflowDefinition = {};
    if (this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE]) {
      workflowDefinition =
        this.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.WORKFLOW
        ];
    }
    let variables = workflowDefinition?.data?.variables || [];
    return {
      workflowDefinition,
      variables,
      idRules: [rules.mandatory()],
      errorMessages: {},
    };
  },
  created() {
    debug(`In created of WorkflowVariables`);
    this.designAction = {
      icon: "mdi-delete",
      id: "remove",
      confirmation: "Are you sure you want to continue?",
      label: "Remove",
      event: "remove",
    };
  },
  mounted() {
    debug(`In mounted of WorkflowVariables`);
  },
  methods: {
    addKeyValue() {
      this.variables.push({
        id: "",
        label: "",
      });
    },
    removeKeyValue(i) {
      this.variables.splice(i, 1);
      this.updateDefinition();
    },
    updateDefinition() {
      debug(`in updateDefinition`, this.variables);
      this.$store.commit(
        `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.UPDATE_NODE}`,
        {
          key: "variables",
          value: this.variables,
        }
      );
    },
    getErrorMessageKey(index) {
      return this.errorMessages[`${index}key`];
    },
    getErrorMessageValue(index) {
      return this.errorMessages[`${index}value`];
    },
    addKey(index) {
      const key = `${index}key`;
      this.$set(this.errorMessages, key, null);
      if (isDuplicate(this.variables, index, "id")) {
        this.$set(this.errorMessages, key, "Duplicate ID");
        return;
      }
      this.updateDefinition();
    },
    addValue(index) {
      const key = `${index}value`;
      this.$set(this.errorMessages, key, null);
      if (isDuplicate(this.variables, index, "value")) {
        this.$set(this.errorMessages, key, "Duplicate Value");
        return;
      }
      this.updateDefinition();
    },
  },
};
</script>
