<template>
  <div v-if="fieldValue" class="behavior_show_permissions">
    <v-dialog
      v-model="jsonEditor"
      width="800"
      @keydown.esc.stop.prevent="jsonEditor = false"
      @click:outside="jsonEditor = false"
    >
      <v-card aut-edit-permissions-dialog>
        <DialogTitle @close="jsonEditor = false" title="JSON Editor" />
        <v-card-text>
          <JSONEditor
            aut-editor-json
            v-model="jsonDefinition"
            :context="context"
            @update="handleJSONUpdate"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <EditorActions
      :actions="rootLevelActions"
      @show_json_editor="jsonEditor = !jsonEditor"
    />
    <Hyperlink
      aut-main-menu
      v-if="mainMenuPropsData"
      v-bind="mainMenuPropsData"
      :key="renderKey"
      v-on="$listeners"
      @change="updateMainMenu"
    />
    <PermissionEditor
      v-if="permissionDialogToggle"
      mode="menu"
      :context="context"
      :definition="menuDefinition"
      @close="permissionDialogToggle = false"
      @update="updateMenuPermissions"
    />
    <v-card
      v-if="fieldValue && fieldValue.menu && fieldValue.menu.child"
      class="px-3"
    >
      <v-card-title>Children</v-card-title>
      <v-card-text>
        <div v-for="(menu, i) in fieldValue.menu.child" :key="i">
          <div class="relative">
            <EditorActions
              v-if="designActions.length"
              :actions="designActions"
              v-on="$listeners"
              @remove_menu="deleteMenu(i)"
              @edit_permissions="displayPermissionDialog(i)"
            />
            <Hyperlink
              v-bind="childMenuPropsData(menu)"
              :key="`${i}-${renderKey}`"
              v-on="$listeners"
              @change="updateChildMenu(i, menu, $event)"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- <MenuOptions v-if="fieldValue" :menus="[fieldValue.menu]" :disabled="true"/> -->
  </div>
</template>
<script>
import { clone } from "@/util";
import { defaultsDeep } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import permissionsMixin from "@/components/permissionsMixin.js";
import { dialogMixin } from "@/components/mixin.js";
import EditorActions from "@/components/editor/EditorActions";
const debug = require("debug")("atman.components.application_menu_tree"); //eslint-disable-line

const designActions = [
  {
    id: "remove_menu",
    label: "Remove Menu",
    icon: "mdi-delete",
    confirmation: "Are you sure you want to continue?",
    event: "remove_menu",
  },
];

export default {
  name: "ApplicationMenuTree",
  mixins: [fieldMixin, permissionsMixin, dialogMixin],
  components: {
    EditorActions,
    JSONEditor: () => import("@/components/fields/JSONEditor/JSONEditor"),
    Hyperlink: () => import("@/components/fields/Hyperlink/Hyperlink"),
    // MenuOptions: () => import("@/components/pageContent/Sidebar/MenuOptions.vue"),
  },
  created() {
    this.rootLevelActions = [
      {
        id: "show_json_editor",
        label: "Show JSON Editor",
        icon: "mdi-code-braces",
        event: "show_json_editor",
      },
    ];
  },

  data() {
    return {
      mainMenuPropsData: null,
      menuDefinition: null,
      designActions: [],
      jsonEditor: false,
    };
  },
  computed: {
    jsonDefinition() {
      return {
        type: "json_editor",
        value: clone(this.fieldValue || {}),
        mode: "explicit",
      };
    },
  },
  mounted() {
    this.designActions = [...designActions, ...this.permissionsActions];
    debug(`designActions`, this.designActions);
  },
  methods: {
    handleJSONUpdate(val) {
      debug(`in handleJSONUpdate`, val);
      this.fieldValue = val;
      this.jsonEditor = false;
    },
    updateMenuPermissions(updatedMenu) {
      const fieldValue = clone(this.fieldValue);
      const menu = fieldValue.menu.child.find(
        (menu) => menu.href == updatedMenu.href
      );
      Object.assign(menu, updatedMenu);
      this.fieldValue = fieldValue;
      debug(`In updateMenuPermissions`, this.fieldValue);
      this.permissionDialogToggle = false;
    },
    displayPermissionDialog(index) {
      const menu = this.fieldValue?.menu?.child?.[index];
      debug(`In displayPermissionDialog`, menu);
      this.menuDefinition = defaultsDeep(
        {
          type: "application_menu_tree",
          _permissions: {
            enabled: true,
            permission: {},
            roles: {},
            users: {},
          },
        },
        menu
      );
      this.permissionDialogToggle = true;
    },
    updateMainMenu(value) {
      this.fieldValue = Object.assign({}, this.fieldValue, {
        menu: clone(value),
      });
      debug("Updated Main Menu value", this.fieldValue);
    },
    _derivePropsData() {
      const mainMenuPropsData = clone(this.$props);
      mainMenuPropsData.value.display = defaultsDeep(
        {},
        mainMenuPropsData.value.display
      );
      mainMenuPropsData.value.value = this?.fieldValue?.menu || {};
      if (this.mainMenuPropsData) {
        Object.assign(this.mainMenuPropsData, mainMenuPropsData);
      } else {
        this.mainMenuPropsData = mainMenuPropsData;
      }
    },
    childMenuPropsData(menu) {
      const menuPropsData = clone(this.$props);
      menuPropsData.value.type = "link";
      menuPropsData.value.display = defaultsDeep(
        {},
        menuPropsData.value.display
      );
      menuPropsData.value.value = clone(menu);
      return menuPropsData;
    },
    updateChildMenu(index, menu, updatedValue) {
      const fieldValue = clone(this.fieldValue);
      fieldValue.menu.child.splice(index, 1, clone(updatedValue));
      this.fieldValue = fieldValue;
      debug("Updated Child Menu value", this.fieldValue);
    },
    deleteMenu(index) {
      const fieldValue = clone(this.fieldValue);
      fieldValue.menu.child.splice(index, 1);
      this.fieldValue = fieldValue;
    },
  },
};
</script>
