<template>
  <v-autocomplete
    v-if="mode != 'display'"
    v-model="definition.constraint.type"
    :items="constraintTypes"
    item-text="name"
    item-value="id"
    label="Type*"
    required
    @blur="$emit('update', definition.constraint.type)"
    @change="$emit('update', definition.constraint.type)"
    :rules="[]"
  />
  <span v-else>
    {{ displayValue }}
  </span>
</template>
<script>
import { clone } from "@/util";
const debug = require('debug')('atman.components.field.feature.constraint_type')  // eslint-disable-line
export default {
  name: "ConstraintType",
  props: {
    value: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      definition: clone(this.value),
    };
  },
  computed: {
    displayValue() {
      const constraintObject = this.constraintTypes.find(
        (item) => item.id == this.definition.constraint.type
      );
      if (!constraintObject) {
        return "";
      }
      return constraintObject.name;
    },
  },
  created() {
    this.constraintTypes = [
      {
        id: "access",
        name: "Access",
      },
      {
        id: "limit",
        name: "Limit",
      },
    ];
  },
};
</script>
