<template>
  <div>
    <v-menu v-if="view == 'profile'" offset-y :nudge-left="14" :z-index="1000">
      <template v-slot:activator="{ on, attrs }">
        <div aut-user-menu>
          <v-avatar
            v-bind="attrs"
            v-on="on"
            size="36"
            :color="userMenuBackground"
            class="mr-2"
          >
            <v-img
              aut-user-image
              v-if="imageSource"
              :src="imageSource"
              lazy-src="/avatar.jpeg"
            />
            <span
              v-else
              :class="`${userMenuColor}--text text-h5`"
              aut-user-char
            >
              {{ userCharacter }}
            </span>
          </v-avatar>
        </div>
      </template>
      <v-list aut-user-menu-items v-if="userMenu.length" dense>
        <v-list-item v-if="my_profile">
          <v-list-item-title>
            <div class="text-body-1 text-center">{{ my_profile.id }}</div>
            <hr
          /></v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in userMenu[0].child"
          :key="index"
          dense
        >
          <v-btn
            plain
            block
            :aut-user-menu-item="item.title"
            :to="item.href"
            class="text-left"
          >
            <v-icon left v-if="item.icon">{{ item.icon }}</v-icon>
            <v-list-item-title class="behavior_overflow">
              {{ item.title }}
            </v-list-item-title>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            plain
            block
            aut-action-logout
            @click="exitApp"
            class="text-left"
          >
            <v-icon left>mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-if="view == 'list'">
      <MenuOptions :menus="userMenu" @click:logout="exitApp" />
    </div>
  </div>
</template>
<script>
import { clone } from "@/util";
import { sortBy, differenceBy, upperFirst } from "lodash";
import { mapActions, mapState } from "vuex";
import MenuOptions from "../Sidebar/MenuOptions.vue";

const addToMenu = (userMenu, additionalMenus) => {
  // IF the server wants to override any menus it can
  let clientMenus = differenceBy(userMenu[0].child, additionalMenus, "id");
  userMenu[0].child = [...clientMenus, ...additionalMenus];
};

const debug = require("debug")("atman.user-menu"); // eslint-disable-line

export default {
  name: "UserMenu",
  components: {
    MenuOptions,
  },
  props: {
    view: {
      type: String,
    },
  },
  computed: {
    ...mapState("user", ["my_profile"]),
    imageSource() {
      const result = this?.my_profile?.image?._file;
      debug(`imageSource`, result);
      return result;
    },
    userCharacter() {
      const char = (this.my_profile?.id || " ").charAt(0);
      return upperFirst(char);
    },
    userMenuBackground() {
      return this.getFeatureValue("header.user_menu.background") || "secondary";
    },
    userMenuColor() {
      return this.getFeatureValue("header.user_menu.color") || "white";
    },
  },
  data() {
    return {
      userMenu: [
        {
          title: "Setting",
          icon: "mdi-code-json",
          child: [
            {
              id: "profile",
              title: "Profile",
              href: "/me",
              icon: "mdi-account-cog",
            },
            {
              id: "preferences",
              title: "Preferences",
              href: "/preferences",
              icon: "mdi-palette",
            },
            {
              id: "change_password",
              title: "Set Password",
              href: "/change_password",
              icon: "mdi-account-key",
            },
          ],
        },
        {
          title: "Logout",
          icon: "mdi-logout",
          click: "logout",
        },
      ],
    };
  },
  mounted() {
    debug("profile", this.profile);
    this.fetchProfile();
    this.fetchMenu();
  },
  methods: {
    ...mapActions("user", ["fetchProfile", "logout", "fetchUserMenu"]),
    async addServerSideMenus(userMenu) {
      try {
        const additionalMenus = (await this.fetchUserMenu()) || [];
        addToMenu(userMenu, additionalMenus);
      } catch (e) {
        debug(`No user menu fetched`);
      }
    },
    addFAQ(userMenu) {
      let target = this.getFeatureValue("faq.target");
      if (!this.isFeatureEnabled("faq.enabled") || !target) {
        debug(`Feature is disabled. Aborting`);
        return;
      }
      addToMenu(userMenu, [
        {
          id: "faq",
          title: "FAQ",
          href: target,
          icon: "mdi-help",
        },
      ]);
    },
    async fetchMenu() {
      let userMenu = clone(this.userMenu);
      await this.addServerSideMenus(userMenu);
      this.addFAQ(userMenu);
      userMenu[0].child = sortBy(userMenu[0].child, ["title"]);
      this.userMenu = userMenu;
    },
    exitApp() {
      this.$emit("exit:app");
      this.logout();
      debug("Logout complete. Routing to home page");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-btn__content {
    justify-content: start !important;
  }
}
</style>
