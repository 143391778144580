<template>
  <div>
    <v-stepper non-linear v-model="stepperModel">
      <v-stepper-header>
        <template v-for="(step, i) in steps">
          <v-stepper-step
            :aut-wizard-step="step.id"
            :key="step.id"
            :complete="stepperModel > step.id"
            :step="step.id"
            editable
          >
            {{ step.label }}
          </v-stepper-step>

          <v-divider
            v-if="i < steps.length - 1"
            :key="`${i}-divider`"
          ></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <template v-for="(step, i) in steps">
          <v-stepper-content :step="step.id" :key="step.id">
            <component
              v-bind:is="step.component"
              @next="nextStep(i)"
            ></component>
            <v-row dense>
              <v-col cols="12" class="d-flex">
                <v-spacer />
                <v-btn
                  aut-wizard-next-btn
                  color="primary"
                  v-if="!step.buttons || step.buttons.next != false"
                  @click="nextStep(i)"
                >
                  {{ buttonLabel(i) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
const debug = require("debug")("atman.components.wizard");

export default {
  name: "AtmanWizard",

  props: {
    steps: {
      type: Array,
    },
  },
  data() {
    return {
      stepperModel: 1,
    };
  },
  computed: {},
  mounted() {
    debug("in wizard");
  },
  methods: {
    buttonLabel(index) {
      if (index < this.steps.length - 1) {
        return "Next";
      } else {
        return "Submit";
      }
    },
    nextStep(i) {
      if (i < this.steps.length - 1) {
        this.stepperModel = this.stepperModel + 1;
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>
