import Vue from "vue";

import { convertToNestedObject, getFeatureFlags } from "@/feature_flags";
import UnsupportedType from "@/components/fields/UnsupportedType";

import {
  forOwn,
  defaultsDeep,
  get,
  set,
  isEqual,
  isBoolean,
  isPlainObject,
} from "lodash";
const debug = require("debug")("atman.components.fields.store");
import {
  doProcessing,
  makeID,
  isDefaultValidKey,
  makeServerCall as utilMakeServerCall,
  clone,
  safeClone,
  replacePlaceholders,
  validateRule,
  dataIsReady,
  getFileFields,
  addParamToURL,
  getRuntimeVariables,
  getVariablesFromObj,
  getContextForPath,
  fetchSchema,
  isExternalLink,
  navigateTo,
  hasFiles,
  getURL,
  createContext,
} from "@/util.js";
import {
  STORE_CONSTS as CONST,
  DISPLAY_PAGE_EVENT,
  DISPLAY_DIALOG_EVENT,
  EMAIL_PAGE_EVENT,
  PUBLISH_EVENT,
  CLOSE_DIALOG,
  SHOW_DIALOG,
} from "@/constants";

import { isFeatureEnabled, featureFlagsObject } from "@/feature_flags";

const MAX_RETRY_INTERVAL = 100;
export const STORE_CONSTS = CONST;

const isSystemKey = (key) => {
  return key.startsWith("_") && key != "_page";
};

const addToData = (key, destination, source, ignoreFields = []) => {
  const methodDebug = debug.extend("addToData"); // eslint-disable-line
  // const key = field.name;
  if (ignoreFields.includes(key)) {
    return;
  }
  if (
    isSystemKey(key) &&
    isFeatureEnabled("form.strip_system_fields_during_submit")
  ) {
    return;
  }
  const sourceValue = source[key];
  if (typeof sourceValue != "undefined") {
    if (isPlainObject(destination)) {
      destination[key] = sourceValue;
    } else {
      if (isPlainObject(sourceValue) || Array.isArray(sourceValue)) {
        destination.append(key, JSON.stringify(sourceValue));
      } else {
        destination.append(key, sourceValue);
      }
    }
  }
};

export const store = {
  namespaced: true,
  state: () => ({
    id: "",
    context: {},
    definition: {},
    schema: {},
    data: null,
    original_data: null,
    design: false,
    selected: [],
    hashTags: {},
  }),
  getters: {
    displayVariables() {
      return {
        isDark: window.vue?.$vuetify?.theme?.isDark,
        isSmallScreen: window.vue?.$vuetify?.breakpoint?.smAndDown,
        isTabletOrLarger: window.vue?.$vuetify?.breakpoint?.mdAndUp,
        isTabletOrSmaller: window.vue?.$vuetify?.breakpoint?.mdAndDown,
        isLargeScreen: window.vue?.$vuetify?.breakpoint?.lgAndUp,
      };
    },
    routeVariables() {
      const route = window?.vue?.$route;
      let routePath = route.fullPath;
      debug(`route`, route);
      if (route.path == "/editor_page") {
        routePath = decodeURI(route.query.page);
      }
      const routeContext = createContext(routePath);
      debug(`routeContext`, routeContext);
      return routeContext;
    },
    settingsVariables() {
      let settings = clone(window?.vue?.$store?.getters?.domainFeatures || {});
      settings.custom_settings = settings.custom_settings || {};
      forOwn(settings.custom_settings, (value, key) => {
        settings.custom_settings[key] = value.value;
      });
      return defaultsDeep(settings, convertToNestedObject(getFeatureFlags()));
    },
    contextVariables(state) {
      const methodDebug = debug.extend(`contextVariables`); // eslint-disable-line
      let context = clone(state.context);
      return getVariablesFromObj({
        input: context,
        prefix: "_context->",
        category: "context",
      });
    },
    userVariables(state, getters, rootState) {
      const methodDebug = debug.extend(`userVariables`); // eslint-disable-line
      const profile = clone(rootState.user?.profile || {});
      return getVariablesFromObj({
        input: profile,
        prefix: "_profile->",
        category: "user",
      });
    },
    accessControlVariables(state, getters, rootState) {
      const methodDebug = debug.extend(`accessControlVariables`); // eslint-disable-line
      const access_control = clone(rootState.user?.access_control || {});
      return getVariablesFromObj({
        input: access_control,
        prefix: "_access_control->",
        category: "access_control",
      });
    },
    runtimeVariables() {
      const methodDebug = debug.extend(`runtimeVariables`); // eslint-disable-line
      const runtimeVariables = getRuntimeVariables();
      return getVariablesFromObj({
        input: runtimeVariables,
        prefix: "_runtime->",
        category: "runtime",
      });
    },
    isDirty(state) {
      const methodDebug = debug.extend(`isDirty`); // eslint-disable-line
      return !isEqual(state.data, state.original_data);
    },
    hasPresetValue:
      (state) =>
      (field = {}) => {
        const methodDebug = debug.extend(`hasPresetValue`); // eslint-disable-line
        const fieldName = field?.name;
        return (
          state.context?.params?.presets &&
          state.context?.params?.presets[fieldName]
        );
      },
    checkConstraint:
      (state) =>
      ({ constraint }) => {
        const methodDebug = debug.extend("checkConstraint"); // eslint-disable-line
        const dataEqualsOriginalData = isEqual(state.data, state.original_data);
        methodDebug(
          `in checkConstraint: [${constraint}]`,
          clone(state.data),
          clone(state.original_data),
          dataEqualsOriginalData
        );
        switch (constraint) {
          case "dirty": {
            const result = !dataEqualsOriginalData;
            methodDebug(`in dirty. returning ${result}`);
            return result;
          }
          case "not_dirty": {
            const result = dataEqualsOriginalData;
            methodDebug(`in not dirty. returning ${result}`);
            return result;
          }
          default: {
            return false;
          }
        }
      },
    checkConditions:
      (state, getters) =>
      ({ conditions }, { customFunctions, customVariables, debugKey }) => {
        let methodDebug = debug.extend(
          `checkConditions${debugKey ? "_" + debugKey : ""}`
        ); // eslint-disable-line

        let value;

        if (!conditions || !conditions.length) {
          return;
        }
        for (let i = 0; i < conditions.length; i++) {
          let condition = clone(conditions[i]);
          condition.rule = getters.dynamicText({
            url: condition.rule,
            customFunctions,
            customVariables,
            debugKey,
          });
          const outcome = validateRule(condition, state.data, { debugKey });
          methodDebug(`data`, state.data);
          methodDebug(`condition`, condition, `outcome`, outcome);
          if (outcome.result) {
            value = condition.value;
            break;
          }
        }
        if (typeof value == "string") {
          return getters.dynamicText({ url: value, customFunctions, debugKey });
        } else {
          return value;
        }
      },
    dynamicText:
      (state, getters, rootState) =>
      (inputs = {}) => {
        const {
          url,
          customFunctions,
          field = {},
          customVariables = {},
          isValidKey,
          debugKey,
        } = inputs;
        let methodDebug = debug.extend(
          `dynamicText${debugKey ? "_" + debugKey : ""}`
        ); // eslint-disable-line
        const data = state.data;
        const context = state.context;
        let result = url;
        if (typeof url != "string") {
          return result;
        }
        const fieldName = field?.name;
        if (!result && fieldName) {
          const presetValue =
            context?.params?.presets && context?.params?.presets[fieldName];
          const variableValue =
            context?.params?.variables && context?.params?.variables[fieldName];
          if (presetValue) {
            result = presetValue;
          } else if (variableValue) {
            result = variableValue;
          }
          return result;
        }
        result = (url || "").replace(/_row/g, "_data->[i]");
        if (typeof customFunctions == "function") {
          result = customFunctions(result);
        }
        const userProfile = rootState?.user?.profile || {};
        const userPermissions = rootState?.user?.access_control || {};

        const variables = Object.assign(
          {},
          { _context: context },
          { _profile: userProfile },
          { _access_control: userPermissions },
          { _data: data },
          {
            _runtime: getRuntimeVariables(),
          },
          {
            _route: getters.routeVariables,
          },
          {
            _features: featureFlagsObject,
          },
          {
            _settings: getters.settingsVariables,
          },
          {
            _display: getters.displayVariables,
          },
          clone(customVariables)
        );
        methodDebug(`variables`, variables);

        result = replacePlaceholders(result, variables, {
          isValidKey: (key) => {
            let result = isDefaultValidKey(key);
            if (result) {
              return result;
            }
            if (typeof isValidKey == "function") {
              return isValidKey(key);
            }
            return false;
          },
          debugKey,
        });
        methodDebug(`Returning ${result} from dynamicText`);
        return result;
      },
    fieldValue: (state) => (path) => {
      const methodDebug = debug.extend(`fieldValue:${path}`); // eslint-disable-line
      methodDebug("state.data", state.data);
      if (!path) {
        methodDebug(`No path specified. Returning all data`);
        return state.data;
      }
      if (!state.data) {
        methodDebug(`Data unavailable. Returning null`);
        return null;
      }
      const value = get(state.data, path);
      methodDebug("fetched value", path, value);
      return value;
    },
  },
  mutations: {
    EMPTY_STATE(state) {
      const methodDebug = debug.extend(`EMPTY_STATE`); // eslint-disable-line
      methodDebug(`Emptying state`);
      Vue.set(state, "data", null);
    },
    HASHTAGS(state, inputObject) {
      const methodDebug = debug.extend(`HASHTAGS`); // eslint-disable-line
      methodDebug(`in HASHTAGS`, inputObject);
      Vue.set(state.hashTags, inputObject.url, inputObject.value);
    },
    [STORE_CONSTS.ID](state, value) {
      const methodDebug = debug.extend(`${STORE_CONSTS.ID}`); // eslint-disable-line
      methodDebug(`in ${STORE_CONSTS.ID}`, value);
      Vue.set(state, "id", value);
    },
    [STORE_CONSTS.SCHEMA](state, value) {
      const methodDebug = debug.extend(`${STORE_CONSTS.SCHEMA}`); // eslint-disable-line
      methodDebug(`in ${STORE_CONSTS.SCHEMA}`, value);
      Vue.set(state, "schema", value);
    },
    [STORE_CONSTS.SELECTED](state, value) {
      const methodDebug = debug.extend(`${STORE_CONSTS.SELECTED}`); // eslint-disable-line
      methodDebug(`in ${STORE_CONSTS.SELECTED}`, value);
      Vue.set(state, "selected", value);
    },
    [STORE_CONSTS.DESIGN](state, value) {
      const methodDebug = debug.extend(`${STORE_CONSTS.DESIGN}`); // eslint-disable-line
      Vue.set(state, "design", value);
    },
    [STORE_CONSTS.CONTEXT](state, context) {
      const methodDebug = debug.extend(`${STORE_CONSTS.CONTEXT}`); // eslint-disable-line
      methodDebug(`in ${STORE_CONSTS.CONTEXT}`, context);
      if (isEqual(state.context, context)) {
        methodDebug(`Ignoring ${STORE_CONSTS.CONTEXT}`);
        return;
      }
      Vue.set(state, "context", context);
    },
    [STORE_CONSTS.DEFINITION](state, definition) {
      const methodDebug = debug.extend(`${STORE_CONSTS.DEFINITION}`); // eslint-disable-line
      if (isEqual(state.definition, definition)) {
        methodDebug(`Ignoring ${STORE_CONSTS.DEFINITION}`);
        return;
      }
      methodDebug(`in ${STORE_CONSTS.DEFINITION}`, definition);
      Vue.set(state, "definition", definition);
    },
    [STORE_CONSTS.DATA](state, data) {
      const methodDebug = debug.extend(`${STORE_CONSTS.DATA}`); // eslint-disable-line
      if (isEqual(state.data, data)) {
        methodDebug(`Ignoring ${STORE_CONSTS.DATA}`);
        return;
      }
      methodDebug(`in COMMIT ${STORE_CONSTS.DATA}`, data);
      if (data) {
        if (Array.isArray(data)) {
          Vue.set(
            state,
            "data",
            data.filter((item) => item != null)
          );
        } else {
          const originalData = clone(state.data);
          Vue.set(state, "data", Object.assign({}, originalData, data));
        }
      }
    },
    [STORE_CONSTS.ORIGINAL_DATA](state, data) {
      const methodDebug = debug.extend(`${STORE_CONSTS.ORIGINAL_DATA}`); // eslint-disable-line
      if (isEqual(state.original_data, data)) {
        methodDebug(`Ignoring ${STORE_CONSTS.ORIGINAL_DATA}`);
        return;
      }
      methodDebug("setting data", data);
      methodDebug(`in ${STORE_CONSTS.ORIGINAL_DATA}`, data);
      if (data) {
        if (Array.isArray(data)) {
          Vue.set(state, "original_data", data);
        } else {
          const originalData = clone(state.original_data);
          Vue.set(
            state,
            "original_data",
            Object.assign({}, originalData, data)
          );
        }
      }
    },
    [STORE_CONSTS.FIELD](state, { path, value }) {
      const methodDebug = debug.extend(`${STORE_CONSTS.FIELD}`); // eslint-disable-line
      methodDebug(`FIELD called with path: [${path}] value: [${value}] `);
      if (typeof path == "undefined") {
        // Is called from some composite fields like KeyValuePairs and DatePickers
        methodDebug(`Invalid path to ${STORE_CONSTS.FIELD}`);
        return;
      }
      state.data = state.data || {};
      const originalValue = get(state.data, path);
      if (isEqual(originalValue, value)) {
        methodDebug(`Ignoring ${STORE_CONSTS.FIELD}`);
        return;
      }
      methodDebug(`in ${STORE_CONSTS.FIELD}. path: [${path}], value`, value);
      set(state.data, path, safeClone(value));
      methodDebug(`data after update`, state.data);
    },
  },
  actions: {
    async getFieldValue({ state, dispatch }, params = {}) {
      params.iteration = params.iteration || 0;
      const iteration = params.iteration;
      let path = params.path;
      const methodDebug = debug.extend(`getFieldValue_${path}`); // eslint-disable-line
      methodDebug(`getFieldValue invoked with params`, params);
      const retryInterval = 1000;
      // HACK. Have to analyse why the data is prefilled with empty strings in some cases
      let isReady =
        state.data &&
        Object.keys(state.data).filter((key) => {
          if (state.data[key]) {
            return true;
          }
          return false;
        }).length;
      if (!isReady) {
        if (iteration >= retryInterval / MAX_RETRY_INTERVAL) {
          methodDebug(
            `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
          );
          return new Promise((resolve, reject) => {
            reject(
              `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
            );
          });
        }
        methodDebug(`data not available yet. stalling for time`);
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              params.iteration = iteration + 1;
              const result = await dispatch("getFieldValue", params);
              resolve(result);
            } catch (e) {
              debug(`exception caught. rejecting`);
              reject();
            }
          }, retryInterval);
        });
      }
      const value = get(state.data, path);
      methodDebug(`data is ready returning [${value}]`, state.data);
      return value;
    },
    async getDataObject({ state, dispatch }, params = {}) {
      params.iteration = params.iteration || 0;
      const iteration = params.iteration;
      const methodDebug = debug.extend(`getData`); // eslint-disable-line
      const retryInterval = 1000;
      // HACK. Have to analyse why the data is prefilled with empty strings in some cases
      let isReady = !!state.data;
      if (!isReady) {
        if (iteration >= retryInterval / MAX_RETRY_INTERVAL) {
          methodDebug(
            `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
          );
          return new Promise((resolve, reject) => {
            reject(
              `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
            );
          });
        }
        methodDebug(`data not available yet. stalling for time`);
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              params.iteration = iteration + 1;
              const result = await dispatch("getFieldValue", params);
              resolve(result);
            } catch (e) {
              debug(`exception caught. rejecting`);
              reject();
            }
          }, retryInterval);
        });
      }
      methodDebug(`data is ready returning`, state.data);
      return state.data;
    },
    async fetchSchema({ state, commit, getters }) {
      const methodDebug = debug.extend(`fetchSchema`); // eslint-disable-line
      let url =
        state?.definition?.apis?.data?.url ||
        (getContextForPath() || { path: "" }).path;

      const effectiveContext = getters.dynamicText({
        url: getters.dynamicText({ url, debugKey: "fetchSchema" }),
        debugKey: "fetchSchema",
      });
      const schema = await fetchSchema(effectiveContext);
      commit(STORE_CONSTS.SCHEMA, schema);
      return schema;
    },
    async deriveVariables({ dispatch }, options = {}) {
      const { condition = {}, /* customFunctions, */ debugKey } = options || {};
      let methodDebug = debug.extend(
        `deriveVariables${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      let variables = condition._variables;
      if (!variables) {
        methodDebug(`No variables defined for condition`);
        return;
      }
      let output = {};
      const variableKeys = Object.keys(variables);
      // Begin iterating through the conditions (NOTE: Don't change to .forEach etc - uses async-await internally)
      // Documentation: https://zellwk.com/blog/async-await-in-loops/
      for (var i = 0; i < variableKeys.length; i++) {
        const variableKey = variableKeys[i];
        const variableValue = variables[variableKey];
        if (
          typeof variableValue == "string" &&
          variableValue.indexOf("url://") == 0
        ) {
          let url = getURL(variableValue);
          methodDebug(`URL [${url}] found in variable. Will make API call`);
          try {
            const response = await dispatch("makeServerCall", { url });
            methodDebug(`response`, response);
            output[variableKey] = response?.data || {};
          } catch (e) {
            methodDebug(
              `Exception occurred when attempting to fetch variable through an API call`,
              e
            );
          }
        } else {
          methodDebug(`Static variable variableValue`);
          // TODO may one day need to invoke a getDynamicValue at this point
          output[variableKey] = variableValue;
        }
      }
      return output;
    },
    async evaluateCondition({ state, getters, dispatch }, options = {}) {
      const { condition, customFunctions, customVariables, debugKey } =
        options || {};
      let methodDebug = debug.extend(
        `evaluateCondition${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line

      let isReady = true;
      const variables = await dispatch("deriveVariables", {
        condition,
        options,
      });
      methodDebug(`variables`, variables);

      // If the data to evaluate this condition is not available, set a flag and abort (see code after the loop)
      isReady = dataIsReady(state.data, condition.rule);
      if (!isReady) {
        methodDebug(`data not ready. Aborting`);
        throw new Error("data_not_ready");
      }
      // Replace any placeholders in the rule
      condition.rule = getters.dynamicText({
        url: condition.rule,
        customVariables: Object.assign(customVariables || {}, {
          _variables: variables || {},
        }),
        customFunctions,
        debugKey,
      });
      // Evaluate the rule
      const outcome = validateRule(condition, state.data, { debugKey });
      methodDebug(`condition.rule: [${condition.rule}]. outcome: `, outcome);
      // IF the condition evaluated to false, return false
      if (!outcome?.result) {
        return { outcome: false };
      }
      // IF this condition evaluated to true
      // Assign the value
      let value = condition.value;
      // IF the value is a string, it could in-turn be a variable, so evaluate it
      if (typeof value == "string") {
        // If the data to evaluate this condition is not available, set a flag and abort (see code after the loop)
        isReady = dataIsReady(state.data, condition.value);
        if (!isReady) {
          throw new Error("data_not_ready");
        }
        value = getters.dynamicText({ url: value, customFunctions, debugKey });
      }
      // If the condition has any post_processors, run them on the value
      const processors = condition.post_processors;
      if (Array.isArray(processors)) {
        value = await doProcessing({
          processors,
          data: value,
          debugKey,
        });
      }
      // Abort processing any other rules
      return { outcome: outcome.result, value };
    },
    async checkConditions(
      { dispatch },
      { conditions, customFunctions, customVariables, iteration = 0, debugKey }
    ) {
      let methodDebug = debug.extend(
        `action_checkConditions${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      methodDebug(`in checkConditions`, conditions, debugKey);
      const retryInterval = 1000;
      let value;
      // IF no conditions are specified, abort
      if (!conditions || !conditions.length) {
        methodDebug(`no conditions defined. Aborting`);
        return;
      }

      // Begin iterating through the conditions (NOTE: Don't change to .forEach etc - uses async-await internally)
      // Documentation: https://zellwk.com/blog/async-await-in-loops/
      let isReady = true;
      for (var i = 0; i < conditions.length; i++) {
        let condition = clone(conditions[i]);
        try {
          const result = await dispatch("evaluateCondition", {
            condition,
            customFunctions,
            customVariables,
            debugKey,
          });
          methodDebug(`in outcome of condition`, condition, result);
          if (result.outcome) {
            value = result.value;
            break;
          }
        } catch (e) {
          methodDebug(
            `exception occurred when checking condition`,
            condition,
            e
          );
          isReady = false;
          break;
        }
      }
      // return the value if the conditions have been evaluated
      if (isReady) {
        return value;
      } else if (iteration > retryInterval / MAX_RETRY_INTERVAL) {
        methodDebug(
          `Giving up after ${
            retryInterval / MAX_RETRY_INTERVAL
          } attempts. Aborting`
        );
      }
      //Otherwise, the loop aborted because the data is not ready, return a promise which will retry
      return new Promise((resolve, reject) => {
        methodDebug(
          `data not available. will check conditions after an interval`
        );
        setTimeout(async () => {
          try {
            const result = await dispatch("checkConditions", {
              conditions,
              customFunctions,
              iteration: iteration + 1,
              debugKey,
            });
            resolve(result);
          } catch (e) {
            reject();
          }
        }, retryInterval);
      });
    },
    // This is not asynchronous. It is here because we need to invoke one getter from another
    deriveParams({ getters }, inputs = {}) {
      const methodDebug = debug.extend(`deriveParams`); // eslint-disable-line
      let {
        params = {},
        customFunctions,
        runtimeParams = {},
        customVariables,
      } = inputs;
      methodDebug(
        `params`,
        params,
        `runtimeParams`,
        runtimeParams,
        `customVariables`,
        customVariables
      );
      let output = {};
      const deriveDynamicInArray = (params = [], key) => {
        const methodDebug = debug.extend(`deriveDynamicInArray_${key}`); // eslint-disable-line
        let output = params.map((value) => {
          return deriveDynamicInObject(value);
        });
        return output;
      };
      const deriveDynamicInObject = (params = {}, key) => {
        const methodDebug = debug.extend(`deriveDynamicInObject_${key}`); // eslint-disable-line
        const output = {};
        if (!isPlainObject(params)) {
          return output;
        }
        Object.keys(params).forEach((key) => {
          const value = params[key];
          const dynamicKey = getters.dynamicText({
            url: key,
            customFunctions,
            customVariables,
            debugKey: key,
          });
          if (!value || isBoolean(value)) {
            output[dynamicKey] = value;
          } else if (Array.isArray(value)) {
            methodDebug(
              `deriveDynamicInObject being invoked for [${dynamicKey}]`
            );
            output[dynamicKey] = deriveDynamicInArray(value, dynamicKey);
          } else if (isPlainObject(value)) {
            methodDebug(
              `deriveDynamicInObject being invoked for [${dynamicKey}]`
            );
            output[dynamicKey] = deriveDynamicInObject(value, dynamicKey);
          } else {
            output[dynamicKey] = getters.dynamicText({
              url: params[key],
              customFunctions,
              customVariables,
              debugKey: key,
            });
          }
        });
        return output;
      };
      output = deriveDynamicInObject(params);
      // IF runtime params exist, they take precendence over configuration
      if (Object.keys(runtimeParams).length) {
        output = defaultsDeep(runtimeParams, output);
      }
      methodDebug(`output of deriveParams`, output);
      return output;
    },
    success({ dispatch }, params) {
      const methodDebug = debug.extend(`success`); // eslint-disable-line
      if (this._actions.success) {
        dispatch("success", params, { root: true });
      } else {
        console.error("action: [success] is not available");
      }
    },
    error({ dispatch }, params) {
      const methodDebug = debug.extend(`error`); // eslint-disable-line
      if (this._actions.error) {
        dispatch("error", params, { root: true });
      } else {
        console.error("action: [error] is not available");
      }
    },
    async getSeedData({ dispatch }, { definition, customFunctions }) {
      const methodDebug = debug.extend(`getSeedData`); // eslint-disable-line
      let seedDataDefinition = clone(definition?.options || {});
      if (!Object.keys(seedDataDefinition).length) {
        return;
      }
      let result = [];
      if (!seedDataDefinition.value && !seedDataDefinition?.apis?.data) {
        console.error(
          "Invalid definition for options for field. Neither[value] nor [apis.data] was present",
          definition
        );
        return result;
      }
      if (seedDataDefinition.value) {
        result = clone(seedDataDefinition.value);
        result = result.sort();
      } else {
        try {
          const apiDetails = seedDataDefinition.apis.data;
          apiDetails.customFunctions = customFunctions;
          const response = await dispatch("makeServerCall", apiDetails);
          if (!response) {
            debug(`makeServerCall aborted`);
            return;
          }
          result = clone(response.data || []);
          const processors = apiDetails.post_processors;
          if (Array.isArray(processors)) {
            result = await doProcessing({
              processors,
              data: result,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
      result = result || [];
      if (!Array.isArray(result)) {
        result = [result];
      }
      result = result
        .map((item) => {
          let itemObj;
          if (typeof item == "string") {
            itemObj = {
              id: item,
              name: item,
            };
          } else {
            itemObj = clone(item);
          }
          // Convert the ID to a string for comparison
          itemObj.id = `${itemObj.id}`;
          return itemObj;
        })
        .filter((item) => !item._deleted);
      if (seedDataDefinition.dynamic_label) {
        result.forEach((item) => {
          item.name = replacePlaceholders(
            seedDataDefinition.dynamic_label,
            item
          );
        });
      }
      methodDebug(`Returning seeddata`, result);
      return result;
    },
    async addSeedData({ dispatch }, { fieldDefinition, value }) {
      const methodDebug = debug.extend(`addSeedData`); // eslint-disable-line
      methodDebug("addSeedData triggered");
      const addSeedDataDefinition = clone(
        fieldDefinition?.options?.apis?.submit || {}
      );
      if (!Object.keys(addSeedDataDefinition).length) {
        methodDebug(
          `No API definition available for addition of seed data for ${fieldDefinition.name}`
        );
        return;
      }
      const apiInputs = Object.assign({}, addSeedDataDefinition, {
        params: { name: value, id: makeID(value) },
      });
      const response = await dispatch("makeServerCall", apiInputs);
      if (!response) {
        debug(`makeServerCall aborted`);
        return;
      }
      return response.data;
    },
    async getData({ state, dispatch, getters }, methodParams = {}) {
      const fieldParams = methodParams.field || {};
      const runtimeParams = methodParams.runtimeParams || {};
      const path = fieldParams.path;
      const debugKey = methodParams.debugKey || path || "";
      const definition = clone(fieldParams.definition || {});
      const customFunctions = fieldParams.customFunctions;
      const force = methodParams.force;
      const methodDebug = debug.extend(`getData_${debugKey}`); // eslint-disable-line
      methodDebug("In action [getData]", path, definition);
      let data = {};
      let apiDetails = {};
      if (path || definition?.apis?.data) {
        apiDetails = definition?.apis?.data || {};
      } else {
        apiDetails = state?.definition?.apis?.data || {};
      }

      if (
        !force &&
        state.design &&
        isFeatureEnabled("editor.abort_fetch_during_design", true)
      ) {
        methodDebug(`Ignoring get because this is design`);
        return;
      }

      if (Array.isArray(apiDetails.conditions)) {
        let outcome = getters.checkConditions(apiDetails, {});
        if (!isPlainObject(outcome)) {
          methodDebug(`Ignoring get because condition failed`);
          return;
        }
        apiDetails = outcome;
      }

      if (
        !Object.keys(apiDetails).length ||
        !apiDetails.type ||
        !apiDetails.url
      ) {
        methodDebug("API definition is not available. Skipping data fetch");
        return;
      }

      let params = await dispatch("deriveParams", {
        params: apiDetails.params,
        runtimeParams,
        customFunctions,
      });
      params = defaultsDeep({}, { params, customFunctions }, apiDetails);
      // HACK Need to fix properly
      if (params?.params?.filter) {
        params.filter = params?.params?.filter;
      }
      methodDebug(`params`, params);

      const response = await dispatch("makeServerCall", params);
      methodDebug(`after makeServerCall`, response);
      if (!response || !response.data) {
        return;
      }
      data = response.data;
      if (apiDetails.field) {
        data = get(data, apiDetails.field);
      }
      const processors = apiDetails.post_processors;
      if (Array.isArray(processors)) {
        data = await doProcessing({ processors, data });
        methodDebug(`data after post processing`, data);
      }

      return data;
    },

    async uploadFile({ state, dispatch }, { definition, customFunctions }) {
      const methodDebug = debug.extend(`uploadFile`); // eslint-disable-line
      methodDebug(
        `in upload file in store`,
        state.context,
        state.definition,
        definition,
        customFunctions
      );
      const actionDefinition = {
        submit: true,
        is_file_submission: true,
        value: {
          params: {
            action: "update_fields",
          },
          type: "post",
          url: state.context?.path || "",
          success: {
            message: "Upload successful",
          },
        },
      };
      dispatch(`triggerAction`, {
        actionDefinition,
      });
    },
    async updateFieldData(
      { dispatch },
      { definition, customFunctions, value }
    ) {
      const methodDebug = debug.extend(`updateFieldData`); // eslint-disable-line
      methodDebug("In action [updateFieldData]");
      let data = {
        [definition.name]: value,
      };
      const apiDetails = clone(definition?.apis?.submit || {});
      if (!Object.keys(apiDetails).length) {
        methodDebug(`No submit api defined. aborting`);
        return;
      }
      if (apiDetails.type == "event") {
        Object.assign(apiDetails.params, data);

        return dispatch(`triggerAction`, {
          actionDefinition: { value: apiDetails },
          customFunctions,
        });
      }
      apiDetails.params = apiDetails.params || {};
      apiDetails.params.data = data;

      return dispatch("triggerAction", {
        actionDefinition: {
          value: apiDetails,
        },
        customFunctions,
      });
    },
    async deriveURL({ state, getters, dispatch }, params) {
      const debugKey = params.debugKey;
      let target = params.url || params.target;
      let methodDebug = debug.extend(
        `deriveURL${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      const retryInterval = 1000;
      if (isPlainObject(target) && target.conditions) {
        const methodParams = {
          conditions: clone(target.conditions),
          customFunctions: params.customFunctions,
        };
        debug(`methodParams`, methodParams);
        target = await dispatch("checkConditions", methodParams);
      }
      const iteration = params.iteration || 0;
      if (!dataIsReady(state.data, target, true)) {
        if (iteration >= retryInterval / MAX_RETRY_INTERVAL) {
          methodDebug(
            `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
          );
          return new Promise((resolve, reject) => {
            reject(
              `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
            );
          });
        }
        methodDebug(`data not available yet. stalling for time`);
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              params.iteration = iteration + 1;
              const result = await dispatch("deriveURL", params);
              resolve(result);
            } catch (e) {
              debug(`exception caught. rejecting`);
              reject();
            }
          }, retryInterval);
        });
      }
      const result = getters.dynamicText({
        url: target,
        customFunctions: params.customFunctions,
        customVariables: params.customVariables,
        debugKey,
      });
      methodDebug(`data is ready returning [${result}]`, state.data);
      return result;
    },
    async getDynamicText({ state, getters, dispatch }, params = {}) {
      const debugKey = params.debugKey;
      let methodDebug = debug.extend(
        `getDynamicText${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      const retryInterval = 1000;
      const iteration = params.iteration || 0;
      if (!dataIsReady(state.data, params.expression)) {
        if (iteration >= retryInterval / MAX_RETRY_INTERVAL) {
          methodDebug(
            `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
          );
          return new Promise((resolve, reject) => {
            reject(
              `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
            );
          });
        }
        methodDebug(`data not available yet. stalling for time`);
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              params.iteration = iteration + 1;
              const result = await dispatch("getDynamicText", params);
              resolve(result);
            } catch (e) {
              reject();
            }
          }, 1000);
        });
      }
      methodDebug(`data is available or variable does not use data key`);
      let result = getters.dynamicText({
        url: params.expression,
        customFunctions: params.customFunctions,
        debugKey,
      });
      methodDebug(
        `Returning ${result} from getDynamicText for ${params.expression}`
      );
      return result;
    },
    async makeServerCall({ state, dispatch }, methodParams) {
      const methodDebug = debug.extend(`makeServerCall`); // eslint-disable-line
      const retryInterval = 1000;
      const { debugKey } = methodParams || {};
      const iteration = methodParams.iteration || 0;
      if (!dataIsReady(state.data, methodParams.url, true)) {
        if (iteration >= retryInterval / MAX_RETRY_INTERVAL) {
          methodDebug(
            `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
          );
          return new Promise((resolve, reject) => {
            reject(
              `Giving up after ${retryInterval / MAX_RETRY_INTERVAL} tries`
            );
          });
        }
        methodDebug(
          `data not available yet. Need it for ${methodParams.url}. stalling for time`
        );
        return new Promise((resolve, reject) => {
          const recursiveCall = async () => {
            try {
              methodParams.iteration = iteration + 1;
              const result = await dispatch("makeServerCall", methodParams);
              resolve(result);
            } catch (e) {
              reject();
            }
          };
          setTimeout(recursiveCall, retryInterval);
        });
      }
      let url = methodParams.url;
      methodParams.params = methodParams.params || {};
      if (methodParams.filter) {
        methodParams.params.filter = await dispatch("deriveURL", {
          url: methodParams.filter,
          customFunctions: methodParams.customFunctions,
          debugKey:
            debugKey ||
            `filter` + methodParams.filter.replace(/[=|\s{}()]/g, ""),
        });
        methodDebug(
          `After replacing filter variables`,
          methodParams.params.filter
        );
      }

      methodParams.url = await dispatch("deriveURL", {
        url,
        customFunctions: methodParams.customFunctions,
        debugKey,
      });

      // Add the sort order for data queries only
      if (
        !["post", "put", "delete"].includes(methodParams.type) &&
        methodParams.url.indexOf("page=") == -1 &&
        methodParams.url.indexOf("lookup=") == -1
      ) {
        let sort = `_created_date DESC`;
        if (Array.isArray(methodParams.sort)) {
          sort = methodParams.sort
            .map((sortField) => {
              return `${sortField.field} ${sortField.order || "DESC"}`;
            })
            .join(",");
        }
        methodParams.params.sort = sort;
        // If count is not already present (replaced as part of pagination), use the default
        if (methodParams.count) {
          methodParams.params.count =
            methodParams.params.count || methodParams.count;
        }
        // IF no count is specified, default to a large value
        if (!methodParams.params.count) {
          methodParams.params.count = 99;
        }
      }

      const response = await utilMakeServerCall(methodParams);
      methodDebug(`response`, response);
      if (typeof response?.action == "object") {
        let payload = {
          debugKey,
          actionDefinition: {
            value: {
              success: {
                action: response?.action,
              },
            },
          },
          response: response?.data,
        };
        methodDebug(`invoking processPostTriggers`, payload);
        try {
          await dispatch("processPostTriggers", payload);
        } finally {
          payload = null;
        }
        return;
      }
      return response;
    },
    triggerNavigation({ dispatch }, params) {
      const methodDebug = debug.extend(`triggerNavigation`); // eslint-disable-line
      if (this._actions.triggerNavigation) {
        return dispatch("triggerNavigation", params, { root: true });
      } else {
        console.error("action: [triggerNavigation] is not available");
      }
    },
    async deriveComponent({ dispatch }, definition) {
      const methodDebug = debug.extend(`deriveComponent`); // eslint-disable-line
      let component;
      if (this._actions.deriveComponent) {
        component = await dispatch("deriveComponent", definition, {
          root: true,
        });
        if (component) {
          return component;
        }
      }
      const { controls } = await import("@/components/fields");
      component = controls[definition.type];
      if (!component) {
        console.error(`No component defined for ${definition.type}`);
        return UnsupportedType;
      }
      return component;
    },
    async deriveDesignComponent({ dispatch }, definition) {
      const methodDebug = debug.extend(`deriveDesignComponent`); // eslint-disable-line

      let component;
      if (this._actions.deriveDesignComponent) {
        component = await dispatch("deriveDesignComponent", definition, {
          root: true,
        });
        if (component) {
          return component;
        }
      }
      const { designers } = await import("@/components/fields");
      return designers[definition.type];
    },
    async getFieldProperties(context, definition) {
      const methodDebug = debug.extend(`getFieldProperties`); // eslint-disable-line
      const { types } = await import("@/components/fields");
      let properties = [];
      if (!definition.type) {
        return properties;
      }
      properties = types[definition.type].properties || [];
      return properties.filter((property) => typeof property == "object");
    },
    async processPostAction(
      { dispatch },
      { definition = {}, customFunctions, isDialog, response = {}, debugKey }
    ) {
      const methodDebug = debug.extend(
        `processPostAction ${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      if (Array.isArray(definition.conditions)) {
        methodDebug(`Conditions exist. Checking them.`, definition.conditions);
        definition = await dispatch("checkConditions", {
          conditions: definition.conditions,
          customFunctions,
          customVariables: { _response: response },
        });

        if (!definition) {
          methodDebug(`conditions did not evaluate. Aborting post processing`);
          return;
        }
        methodDebug(`conditions existed. success is now:`, definition);
      }
      methodDebug("in processPostTriggers", definition);
      const postMessage = definition?.message;
      const postAction = definition?.action;
      if (postMessage) {
        methodDebug(`Message exists. Displaying it now`, postMessage);
        await dispatch("success", postMessage);
        if (postAction) {
          methodDebug(`Message exists. Displaying it now`, postMessage);
          /* Add a delay to ensure message is visible */
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        }
      }

      if (postAction) {
        return dispatch("triggerAction", {
          actionDefinition: {
            value: postAction,
          },
          customFunctions,
          isDialog,
          customVariables: { _response: response },
        });
      }
    },
    async processPostTriggers(
      { dispatch },
      { actionDefinition, customFunctions, isDialog, response = {}, debugKey }
    ) {
      const methodDebug = debug.extend(
        `processPostTriggers ${debugKey ? "_" + debugKey : ""}`
      ); // eslint-disable-line
      const actionDetails = actionDefinition.value;
      methodDebug(`Beginning processPostTriggers`, actionDetails);
      let successDefinition = actionDetails?.success;
      if (!successDefinition) {
        methodDebug(`No success triggers defined. Aborting`);
        return;
      }
      if (Array.isArray(successDefinition)) {
        const promises = [];
        while (successDefinition.length) {
          const definition = clone(successDefinition.shift());
          promises.push(
            dispatch("processPostAction", {
              definition,
              customFunctions,
              isDialog,
              response,
              debugKey,
            })
          );
        }
        return Promise.all(promises);
      } else {
        return dispatch("processPostAction", {
          definition: successDefinition,
          customFunctions,
          isDialog,
          response,
          debugKey,
        });
      }
    },
    async triggerAction({ dispatch, commit, getters, state }, actionInputs) {
      let {
        actionDefinition,
        customFunctions,
        isDialog = false,
        force = false,
        customVariables = {},
        resetState = false,
        debugKey = "",
      } = actionInputs || {};
      const methodDebug = debug.extend(`triggerAction`); // eslint-disable-line

      methodDebug(`Inputs to method: `, actionInputs);
      if (state.design && !force) {
        methodDebug(`Ignoring action because this is design`);
        return;
      }
      actionDefinition = clone(actionDefinition);
      let actionDetails = actionDefinition.value;
      if (!actionDetails) {
        console.error(`Invalid invocation of method`);
        return;
      }
      /* Handles scenarios where the entire action definition can vary based on conditions */
      if (Array.isArray(actionDetails.conditions)) {
        actionDetails = await dispatch("checkConditions", {
          conditions: actionDetails.conditions,
          customFunctions,
          customVariables,
        });
        if (!actionDetails) {
          methodDebug(`conditions did not evaluate. Aborting post processing`);
          return;
        }
      }
      let target = actionDetails.target || actionDetails.url;
      methodDebug(`Action`, actionDetails, "triggered");
      let response = {};
      if (!actionDetails.type) {
        return;
      }
      switch (actionDetails.type.toLowerCase()) {
        case "dialog": {
          methodDebug("in dialog flow");
          let targetURL = await dispatch("deriveURL", {
            url: target,
            customFunctions,
            customVariables,
          });
          methodDebug("effective URL ", targetURL);
          dispatch(SHOW_DIALOG, { url: targetURL }, { root: true });
          break;
        }
        case DISPLAY_PAGE_EVENT: {
          methodDebug(`In ${DISPLAY_PAGE_EVENT}`);
          dispatch(
            DISPLAY_PAGE_EVENT,
            { page: actionDetails.page },
            { root: true }
          );

          break;
        }
        case DISPLAY_DIALOG_EVENT: {
          methodDebug(`In ${DISPLAY_DIALOG_EVENT}`);
          dispatch(SHOW_DIALOG, { page: actionDetails.page }, { root: true });
          break;
        }
        case EMAIL_PAGE_EVENT: {
          methodDebug(`In ${EMAIL_PAGE_EVENT}`);
          dispatch(EMAIL_PAGE_EVENT, null, { root: true });
          break;
        }
        case "window": {
          methodDebug(`In window`);
          let targetURL = await dispatch("deriveURL", {
            url: target,
            customFunctions,
            customVariables,
          });

          if (!targetURL) {
            methodDebug(`invalid url`);
            return;
          }

          if (isExternalLink(targetURL)) {
            methodDebug(`external url`);
            navigateTo(null, targetURL, { newWindow: true });
            return;
          }
          break;
        }
        case "navigation": {
          methodDebug(`navigation`);
          let targetURL = await dispatch("deriveURL", {
            url: target,
            customFunctions,
            customVariables,
            debugKey,
          });
          if (!targetURL) {
            methodDebug(`invalid url`);
            return;
          }

          if (isExternalLink(targetURL)) {
            methodDebug(`external url`);
            navigateTo(null, targetURL);
            return;
          }

          methodDebug(`targetURL`, targetURL);

          let params = await dispatch("deriveParams", {
            params: actionDetails.params || {},
            customFunctions,
            customVariables,
          });
          methodDebug(`params`, params);

          const paramsString = new URLSearchParams(params).toString();
          if (paramsString) {
            if (targetURL.indexOf("?") != -1) {
              targetURL = `${targetURL}&${paramsString}`;
            } else {
              targetURL = `${targetURL}?${paramsString}`;
            }
          }
          const presets = await dispatch("deriveParams", {
            params: actionDetails.presets || {},
            customFunctions,
            customVariables,
          });
          methodDebug(`presets`, presets);
          targetURL = addParamToURL(targetURL, "presets", presets);

          const variables = await dispatch("deriveParams", {
            params: actionDetails.variables || {},
            customFunctions,
            customVariables,
          });
          targetURL = addParamToURL(targetURL, "variables", variables);

          methodDebug(`targetURL`, targetURL);

          dispatch(`triggerNavigation`, { url: targetURL });
          break;
        }
        case "event": {
          methodDebug(`In action - event handler`, actionDetails);
          // Convert the params into an object
          const effectiveParams = await dispatch("deriveParams", {
            params: actionDetails.params,
            customFunctions,
          });

          const payload = {
            store_id: state.id,
            key: actionDetails.name,
            value: effectiveParams,
          };

          if (actionDetails.data) {
            let effectiveData = await dispatch("deriveParams", {
              params: actionDetails.data,
              customFunctions,
            });
            payload.data = effectiveData;
          }

          methodDebug(`Triggering ${PUBLISH_EVENT}`, payload);
          dispatch(PUBLISH_EVENT, payload, { root: true });
          break;
        }
        case "logout": {
          dispatch("logout", null, { root: true });
          break;
        }
        case "put":
        case "post":
        case "delete":
        case "get":
          {
            methodDebug(`in ${actionDetails.type}`);
            // Initialise the request data
            const requestData = {
              params: {},
            };
            // Derive the params
            const paramsInConfig = await dispatch("deriveParams", {
              params: actionDetails.params,
              customVariables,
              customFunctions,
            });
            // IF this is a submit,
            if (actionDefinition.submit == true) {
              methodDebug(`in submit`);
              const fileFields = getFileFields(state.data);
              if (fileFields.length) {
                methodDebug(`file fields exist`, fileFields);
                const formData = new FormData();
                fileFields.forEach((fieldName) => {
                  const filesBeingUploaded = state.data[fieldName];
                  if (filesBeingUploaded instanceof File) {
                    formData.append(fieldName, filesBeingUploaded);
                  } else if (hasFiles(filesBeingUploaded)) {
                    for (var i = 0; i < filesBeingUploaded.length; i++) {
                      if (filesBeingUploaded[i] instanceof File) {
                        formData.append(fieldName, filesBeingUploaded[i]);
                      } else if (isPlainObject(filesBeingUploaded[i])) {
                        formData.append(
                          fieldName,
                          JSON.stringify(filesBeingUploaded[i])
                        );
                      }
                    }
                  }
                });

                /* const fieldsOnPage =
                  state?.definition?.definition?.fields || []; */
                const fieldsOnPage = Object.keys(state.data);
                fieldsOnPage.forEach((field) => {
                  addToData(field, formData, state.data, fileFields);
                });
                methodDebug(`after adding fields on page`, fieldsOnPage);

                let url =
                  actionDetails.url || state?.definition?.apis?.submit?.url;

                methodDebug("url", url);
                if (Object.keys(paramsInConfig).length) {
                  const params = [];
                  Object.keys(paramsInConfig).forEach((key) => {
                    if (key == "data") {
                      let dataInParam = paramsInConfig[key];
                      Object.keys(dataInParam).forEach((keyInData) => {
                        // Don't add system fields
                        if (
                          isSystemKey(keyInData) &&
                          isFeatureEnabled(
                            "form.strip_system_fields_during_submit"
                          )
                        ) {
                          return;
                        }
                        const value = dataInParam[keyInData];
                        if (typeof value != "undefined") {
                          formData.append(
                            keyInData,
                            isPlainObject(value) || Array.isArray(value)
                              ? JSON.stringify(value)
                              : value
                          );
                        }
                      });
                    } else {
                      let valueOfParam = paramsInConfig[key];
                      if (
                        isPlainObject(valueOfParam) ||
                        Array.isArray(valueOfParam)
                      ) {
                        valueOfParam = JSON.stringify(valueOfParam);
                      }
                      params.push(`${key}=${valueOfParam}`);
                    }
                  });
                  if (params.length) {
                    url += `?${params.join("&")}`;
                  }
                }
                formData.append("context", state.context?.path || "");
                methodDebug(`definition`, state.definition);
                let debugKey =
                  actionDetails.type || state.definition.apis.submit.type;
                response = await dispatch(`makeServerCall`, {
                  url,
                  type: debugKey,
                  params: formData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  options: {
                    noDataNode: true,
                  },
                  debugKey,
                });
                if (!response) {
                  return;
                }
                methodDebug("after getting response", response);
                await dispatch(`processPostTriggers`, {
                  actionDefinition: {
                    value: actionDetails,
                  },
                  response: response?.data,
                  customFunctions,
                  isDialog,
                });
                if (resetState) {
                  commit("EMPTY_STATE");
                }
                return;
              }
              let data = {};
              try {
                /* const fieldsOnPage =
                  state?.definition?.definition?.fields || []; */
                const fieldsOnPage = Object.keys(state.data);
                fieldsOnPage.forEach((field) => {
                  addToData(field, data, state.data);
                });
              } catch (e) {
                data = {};
              }
              requestData.params = defaultsDeep(
                {},
                {
                  data,
                }
              );
            }
            /* Don't change to else-if */
            // TODO replace with pre-processor
            /* IF the key 'data' is specified, pick only the fields there-in */
            if (actionDetails.data) {
              let data = get(state.data, actionDetails.data);
              requestData.params = defaultsDeep(
                {},
                {
                  data,
                }
              );
            }
            // If this is a bulk-operation, add all the IDs as an array
            if (actionDefinition.bulk_operation) {
              requestData.params = defaultsDeep(
                {
                  ids: state.selected.map(({ id }) => id),
                },
                requestData.params
              );
            }

            // If params are available, treat them as priority over the ones already available
            if (paramsInConfig) {
              requestData.params = defaultsDeep(
                {},
                paramsInConfig,
                requestData.params
              );
            }
            // Derive the URL
            actionDetails.url = getters.dynamicText({
              url: actionDetails.url,
              customFunctions,
              customVariables,
              debugKey,
            });

            const actionParam = requestData.params["action"];
            if (actionParam == "mark_for_deletion") {
              requestData.params = defaultsDeep(
                {},
                {
                  data: {},
                },
                requestData.params
              );
            }
            response = await dispatch(
              "makeServerCall",
              Object.assign({}, actionDetails, requestData)
            );
            if (resetState) {
              commit("EMPTY_STATE");
            }
          }
          break;
        case "_cancel_":
          if (isDialog) {
            commit(CLOSE_DIALOG, null, { root: true });
          } else {
            dispatch(`triggerNavigation`, { url: "BACK" });
          }
          break;
        default:
          console.error(
            `Unsupported action type`,
            actionDetails,
            `isDialog: ${isDialog}`
          );
      }
      await dispatch("processPostTriggers", {
        actionDefinition: {
          value: actionDetails,
        },
        customFunctions,
        isDialog,
        response: response?.data || {},
      });
    }, //triggerAction
    async fetchPermissionContexts({ state, dispatch }, params = {}) {
      const methodDebug = debug.extend(`fetchPermissionContexts`); // eslint-disable-line
      methodDebug(`in Fetch roles`);
      const contextPath = params.url || state.context?.path || "";
      const permissionContexts = (
        await dispatch("makeServerCall", {
          url: `${contextPath}/permissions`,
          type: "get",
        })
      )?.data;
      return permissionContexts;
    },
    async fetchPermissionRoles({ state, dispatch }, params = {}) {
      const methodDebug = debug.extend(`fetchPermissionRoles`); // eslint-disable-line
      methodDebug(`in Fetch roles`);
      const contextPath = params.url || state.context?.path || "";
      const permissionContexts = (
        await dispatch("makeServerCall", {
          url: `${contextPath}/roles`,
          type: "get",
        })
      )?.data;
      return permissionContexts;
    },
    async updateRole({ state, dispatch }, params = {}) {
      const methodDebug = debug.extend(`updateRole`); // eslint-disable-line
      debug(`In update role`, params);
      const contextPath = state.context?.path;
      methodDebug(`in updateRole roles`);
      const permissionContexts = (
        await dispatch("makeServerCall", {
          url: `${contextPath}/roles/${params.role.id}`,
          type: "post",
          params: {
            action: "update_fields",
            data: params.role,
          },
        })
      )?.data;
      return permissionContexts;
    },
    async deleteRole({ state, dispatch }, params = {}) {
      const methodDebug = debug.extend(`deleteRole`); // eslint-disable-line
      debug(`In delete role`, params);
      const contextPath = state.context?.path;
      methodDebug(`in deleteRole`);
      const permissionContexts = (
        await dispatch("makeServerCall", {
          url: `${contextPath}/roles/${params.role}`,
          type: "delete",
        })
      )?.data;
      return permissionContexts;
    },
    async fetchUsers({ state, dispatch }, params = {}) {
      const methodDebug = debug.extend(`fetchUsers`); // eslint-disable-line
      methodDebug(`in Fetch users`);
      const contextPath = params.url || state.context?.path || "";
      const url = !params.all ? `${contextPath}/users` : "/users";
      const permissionContexts = (
        await dispatch("makeServerCall", {
          url,
          type: "get",
        })
      )?.data;
      return permissionContexts;
    },
    async fetchHashtags({ state, commit, dispatch }, params = {}) {
      const methodDebug = debug.extend(`fetchHashtags`); // eslint-disable-line
      methodDebug(`in Fetch hashtags`);
      const contextPath = params.url || state.context.path;
      const url = `${contextPath}/hashtags`;
      const existingValue = state.hashTags?.[url];
      if (existingValue) {
        return existingValue;
      }
      const hashtagsContext = (
        await dispatch("makeServerCall", {
          url,
          type: "get",
        })
      )?.data;
      commit("HASHTAGS", {
        url,
        value: hashtagsContext,
      });
      return hashtagsContext;
    },
  },
};
