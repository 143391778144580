const debug = require("debug")("atman.components.duration"); // eslint-disable-line
export default {
  label: "Feature",
  type: "feature",
  description: "Displays a feature",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Feature Field",
      label: "Default",
      value: {
        name: "default",
        label: "Feature",
        type: "feature",
      },
    },
    {
      id: "display",
      label: "Display (Access constrained feature)",
      variables: {
        data: {
          display: {
            name: "Id aute nisi sit do duis.",
            description:
              "Lorem consequat incididunt sunt ea exercitation irure.",
            api: "/rules",
            constraint: {
              type: "access",
              value: "yes",
            },
          },
        },
      },
      value: {
        label: "Feature",
        type: "feature",
        name: "display",
        mode: "display",
      },
    },
    {
      id: "display2",
      label: "Display (Limit constrained numerical feature)",
      variables: {
        data: {
          display2: {
            name: "Id aute nisi sit do duis.",
            description:
              "Lorem consequat incididunt sunt ea exercitation irure.",
            api: "/rules",
            constraint: {
              type: "limit",
              value: 2,
              units: "number",
            },
          },
        },
      },
      value: {
        label: "Feature",
        type: "feature",
        name: "display2",
        mode: "display",
      },
    },
    {
      id: "display3",
      label: "Display (Access constrained size feature)",
      variables: {
        data: {
          display3: {
            name: "Id aute nisi sit do duis.",
            description:
              "Lorem consequat incididunt sunt ea exercitation irure.",
            api: "/rules",
            constraint: {
              type: "limit",
              value: 2,
              units: "MB",
            },
          },
        },
      },
      value: {
        label: "Feature",
        type: "feature",
        name: "display3",
        mode: "display",
      },
    },
  ],
};
