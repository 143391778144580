export default {
  start: {
    type: "trigger_event",
    params: {
      id: ["general-1"],
    },
  },
  categories: {
    general: {
      display: {
        classes: [],
        attributes: {
          color: "#C3DFE0",
        },
      },
      label: "General",
      events: {
        "general-1": {
          type: "display_question",
          question:
            "Questions can have some <code>preformatted</code> content?",
          answers: [
            {
              id: "wrong",
              weightage: 0,
              name: "<b>No!</b> <i>Really?</i>",
            },
            {
              id: "right",
              weightage: 1,
              name: "<b>Yes!</b> And <span class='red--text'> answers </span> can too",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["general-2"],
              },
            },
          ],
        },
        "general-2": {
          type: "display_question",
          question: "Question about fruits",
          answers: [
            {
              id: "no_fruits",
              weightage: 0,
              name: "User did not remember any fruits [0 points]",
            },
            {
              id: "1_fruit",
              weightage: 1,
              name: "User only remembered one fruit  [1 point]",
            },
            {
              id: "2_fruits",
              weightage: 2,
              name: "User only remembered two fruits  [2 points]",
            },
            {
              id: "3_fruits",
              weightage: 3,
              name: "User remembered all three fruits [3 points]",
            },
          ],
          next: [
            {
              type: "trigger_event",
              params: {
                id: ["display_summary"],
              },
            },
          ],
        },
      },
    },
  },
  events: {
    display_summary: {
      type: "display_summary",
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 0,
                path: "$.general.weightage",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "outcome_1",
                label: "Dementia",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 4,
                path: "$.general.weightage",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "outcome_2",
                label: "Normal",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "lessThan",
                value: 4,
                path: "$.general.weightage",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              _is_exclusive: true,
              target: "outcome",
              value: {
                id: "outcome_3",
                label:
                  "<div><p class='red--text'>Unknown</p><p class='red--text'>Unknown2</p></div>",
              },
            },
          },
        },
      ],
    },
  },

  summary: {
    title: "Diagnosis",
  },
};
