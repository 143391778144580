<template>
  <div>
    <CurrencyDisplay
      v-if="isDisplayMode"
      :label="label"
      :labelAttributes="labelAttributes"
      :amount="amount"
      :value="value"
    />
    <v-row dense no-gutters v-else>
      <v-col cols="12" lg="12">
        <InputText
          aut-amount-value
          v-if="propsData"
          v-bind="propsData"
          :key="renderKey"
          @change="updateAmount"
        />
      </v-col>
      <!-- 
        Can be uncommented when we support other currencies
        <v-col cols="12" lg="2" class="pl-2">
        <v-autocomplete
          class="pt-0 mt-0 behavior_currency"
          v-model="amount.currency"
          :items="currencies"
          hide-details
          :disabled="true"
          item-text="name"
          item-value="id"
        />
      </v-col> 
      -->
    </v-row>
  </div>
</template>

<script>
import { defaultsDeep } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import { clone, safeClone } from "@/util";
import CurrencyDisplay from "./CurrencyDisplay.vue";
import definition from "./definition";
const debug = require("debug")("atman.components.currency"); // eslint-disable-line

const isValidAmount = (input) => {
  const message = "Not a valid amount";
  let amount = input;

  if (!input || typeof input == "number") {
    return true;
  }

  const isPercentAmount = input.indexOf("%") == input.length - 1;

  if (isPercentAmount) {
    amount = input.substring(0, input.length - 1);
  }

  return /^[0-9]+\.?[0-9]*$/.test(amount) ? true : message;
};

export default {
  name: "Currency",
  mixins: [fieldMixin],
  components: {
    CurrencyDisplay,
    InputText: () => import("@/components/fields/InputText/InputText"),
  },
  data() {
    return {
      definition: definition,
      currencies: [
        {
          id: "INR",
          name: "INR",
        },
      ],
    };
  },
  computed: {
    amount: {
      get() {
        if (!this.fieldValue) {
          return {
            amount: null,
            currency: "INR",
          };
        }
        return this.fieldValue;
      },
      set(value) {
        this.fieldValue = value;
      },
    },
  },
  methods: {
    updateAmount(value) {
      if (isValidAmount(value) != true) {
        return;
      }
      debug(`updateAmount invoked before update`, safeClone(this.fieldValue));
      const amount = clone(this.amount);
      amount.amount = isNaN(value * 1) ? value : value * 1;
      this.amount = clone(amount);

      debug(`updateAmount invoked after update`, safeClone(this.fieldValue));

      this.$emit("change", clone(this.amount));
      this._derivePropsData();
      this.$forceUpdate();
    },
    _derivePropsData() {
      const propsData = clone(this.$props);
      propsData.value.display = defaultsDeep(propsData.value.display);
      propsData.value.rules = [isValidAmount];
      (propsData.value.name = this.value.name + `_text`),
        (propsData.value.value = this?.fieldValue?.amount);
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_currency {
  font-size: smaller;
}
</style>
