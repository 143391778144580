import { absoluteURL, makeServerCall } from "@/util.js";
const debug = require("debug")("atman.components.menu_util");

const getApplicationID = (url) => {
  const urlItems = url.split("?")[0].split("/");
  while (
    urlItems[0] == "" ||
    urlItems[0] == "applications" ||
    urlItems[0] == "public"
  ) {
    urlItems.shift();
  }
  const result = urlItems[0];
  debug(`url: [${url}]. result: [${result}] `);
  return result;
};

const getMenuItem = (menu, url) => {
  if (!menu) {
    return;
  }
  let menuDetails = {};
  let menuHref = getEffectiveHref(menu.href);
  if (menuHref == url) {
    menuDetails = {
      label: menu.title,
      icon: menu.icon,
    };
    return menuDetails;
  }
};

const getMenuDetails = async (url) => {
  const application = await getApplicationDetails(url);

  if (!application) {
    debug(`Application not found. Aborting`);
    return;
  }
  debug(`application`, application);

  let menuDetails = getMenuItem(application.menu, url);
  if (!menuDetails) {
    debug(`Is not home menu`, application);
    (application?.menu?.child || []).find((menu) => {
      menuDetails = getMenuItem(menu, url);
      return typeof menuDetails == "object";
    });
  }
  if (menuDetails) {
    menuDetails.isPresent = true;
  } else {
    menuDetails = {};
    if (isHomePage(getEffectiveHref(url))) {
      menuDetails.label = application.name;
    }
  }
  menuDetails.application = application;

  return menuDetails;
};

const updateApplication = (application) => {
  debug(`going to update`, application.menu);
  return makeServerCall({
    url: `applications/${application.id}`,
    type: "post",
    params: {
      action: "update_fields",
      data: application,
    },
  });
};

const isHomePage = (url) => {
  return url.indexOf("page=") == -1 || url.indexOf("page=home") != -1;
};

const getEffectiveHref = (href) => {
  if (!href) {
    return "";
  }
  let menuHref = href;
  if (menuHref.indexOf("page=") == -1) {
    menuHref = `${menuHref}?page=home`;
  }
  return absoluteURL(menuHref);
};

const updateMenu = ({ application, url, isPresent, label, icon }) => {
  application.menu = application.menu || {};
  const applicationMenu = application.menu;
  if (isPresent || isHomePage(url)) {
    const updateMenuFn = (menu, isRoot = false) => {
      if (isRoot && isHomePage(url)) {
        menu.href = url;
      }
      let menuHref = getEffectiveHref(menu.href);
      if (menuHref && menuHref == url) {
        menu.title = label;
        menu.icon = icon;
      }
    };
    updateMenuFn(applicationMenu, true);
    applicationMenu.child = applicationMenu?.child || [];
    applicationMenu.child.find((menu) => {
      updateMenuFn(menu);
    });
  } else {
    applicationMenu.child = applicationMenu?.child || [];
    applicationMenu.child.push({
      href: url,
      title: label,
      icon: icon,
    });
  }
  return updateApplication(application);
};

const getApplicationDetails = async (url) => {
  const applicationID = getApplicationID(url);
  if (!applicationID) {
    return;
  }
  debug(`applicationID`, applicationID);
  try {
    return (
      await makeServerCall({
        url: `applications/${applicationID}`,
        type: "get",
      })
    ).data;
  } catch (e) {
    console.error(`Could not get application details`);
  }
};

export {
  getMenuDetails,
  getApplicationDetails,
  getApplicationID,
  getMenuItem,
  isHomePage,
  getEffectiveHref,
  updateMenu,
};
