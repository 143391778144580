<template>
  <div class="md-2 pd-2" aut-code-editor>
    <div v-if="label" class="text-left">{{ label }}:</div>
    <div
      class="code-editor"
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
      aut-code-editor-text
    >
      <textarea ref="textarea" aut-field-value />
    </div>
    <v-btn
      v-if="isExplicitMode"
      aut-action-clear-code
      @click="clearContent"
      class="my-2"
      >Clear</v-btn
    >
    <v-btn
      v-if="isExplicitMode"
      aut-action-update-code
      @click="$emit('update', codeContent)"
      class="my-2"
      >Update</v-btn
    >
  </div>
</template>

<script>
import CodeMirror from "codemirror";
// import 'codemirror/addon/lint/lint.css'
import "codemirror/lib/codemirror.css";
import "codemirror/theme/rubyblue.css";
// require('script-loader!jsonlint')
import "codemirror/mode/coffeescript/coffeescript";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/json-lint";
import { fieldMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.code_editor");
debug(`code_editor`);
export default {
  name: "CodeEditor",
  data() {
    return {
      codeEditor: false,
      codeContent: {},
    };
  },
  mixins: [fieldMixin],
  mounted() {
    debug("in mounted of Code editor", this.value);
    this.initialiseEditor();
  },
  methods: {
    _afterPropertyUpdate() {
      const codeMirrorInstance = this.$el.querySelector(".CodeMirror");
      if (codeMirrorInstance) {
        codeMirrorInstance.parentElement.removeChild(codeMirrorInstance);
      }
      this.codeEditor = false;
      this.$nextTick(() => {
        this.initialiseEditor();
      });
    },
    initialiseEditor() {
      this.codeEditor = CodeMirror.fromTextArea(this.$refs.textarea, {
        // TODO temporarily setting as false to avoid the numbers being displayed even when the sidebar is hovered over.
        // NOTE: also see the style section and display the gutters
        lineNumbers: false,
        tabSize: 4,
        readOnly: this.isInputMode || this.isExplicitMode ? false : "nocursor",
        mode: "text/coffeescript",
        // theme: 'base16-dark',
        line: true,
        gutters: ["CodeMirror-lint-markers"],
        theme: "rubyblue",
        //   lint: true
      });
      this.setValue();
      this.codeEditor.on("change", (cm) => {
        const contentInTextArea = cm.getValue();
        if (contentInTextArea) {
          debug("updating in json editor");
          let parsedContent = contentInTextArea;
          this.$set(this, "codeContent", parsedContent);
          if (this.isInputMode) {
            this.triggerEvent();
          }
        }
      });
    },
    _afterValueUpdate() {
      if (typeof this.fieldValue != "undefined") {
        this.loading = false;
        this.setValue();
      }
    },
    triggerEvent() {
      this.$set(this, "fieldValue", this.codeContent);
      this.$emit("update", this.fieldValue);
    },
    getValue() {
      return this.codeEditor.getValue();
    },
    clearContent() {
      this.codeEditor.setValue("");
    },
    setValue() {
      if (!this?.codeEditor || !this.fieldValue) {
        return;
      }
      const valueInModel = this.fieldValue;
      const valueInEditor = this.codeEditor.getValue();
      if (!valueInEditor || valueInModel != valueInEditor) {
        this.codeEditor.setValue(this.fieldValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.code-editor {
  height: 100%;
  position: relative;
  ::v-deep {
    .CodeMirror {
      height: auto;
      min-height: 300px;
    }
    .CodeMirror-scroll {
      min-height: 300px;
    }
    .cm-s-rubyblue span.cm-string {
      color: #f08047;
    }
    pre {
      display: flex;
      span {
        text-align: left;
      }
    }
  }
}
</style>
<style>
/* See line numbers */
.CodeMirror-gutters {
  display: none;
}
</style>
