import AtmanTabs from "./AtmanTabs/AtmanTabs";
import AtmanTabsItems from "./AtmanTabs/AtmanTabsItems";
import ListField from "./ListField/ListField";
import FieldLabel from "./FieldLabel";
import FieldDisplayValue from "./FieldDisplayValue";

import Vue from "vue";

Vue.component("AtmanTabs", AtmanTabs);
Vue.component("ListField", ListField);
Vue.component("AtmanTabsItems", AtmanTabsItems);
Vue.component("FieldLabel", FieldLabel);
Vue.component("FieldDisplayValue", FieldDisplayValue);
