<template>
  <div class="text-left">
    <p class="text-body-2 my-0 py-0 grey--text text--darken-1" v-if="label">
      {{ label }}
    </p>
    <p
      v-if="value.hint"
      class="text-caption my-0 py-0 grey--text text--darken-1"
    >
      {{ value.hint }}
    </p>
    <v-card elevation="1" outlined class="mt-0 pt-0">
      <v-card-text>
        <v-row
          v-for="(item, i) in arrayEquivalent"
          :key="i"
          dense
          no-gutters
          class="px-1"
        >
          <key-field
            :definition="keyFieldDefinition(item.id, i)"
            :key="`key-${i}-${refreshKey}`"
            :context="context"
            @update="keyUpdated(i, $event)"
          />
          <value-field
            :definition="valueFieldDefinition(item.name, i)"
            :key="`value-${i}-${refreshKey}`"
            :context="context"
            @update="valueUpdated(i, $event)"
          />
          <v-col v-if="allowDeletion(item.id, i)">
            <v-icon aut-action-delete-key-value @click="deleteKey(i)"
              >mdi-delete</v-icon
            >
          </v-col>
        </v-row>
        <div class="d-flex" v-if="!isDisplayMode && allowAddition">
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small @click="updateObject([])">
                Clear
              </v-btn>
            </template>
            <span>Remove all key-value pairs</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                v-bind="attrs"
                v-on="on"
                small
                :disabled="isDisabled"
                @click="addKeyValue"
              >
                Add
              </v-btn>
            </template>
            <span>Add a new key-value pair</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { defaultsDeep } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import { rules } from "@/util.js";
import KeyField from "./KeyField.vue";
import ValueField from "./ValueField.vue";
const debug = require("debug")("atman.components.key_value_pair"); // eslint-disable-line
export default {
  name: "KeyValuePair",
  data() {
    return {
      rules: [rules.mandatory()],
      refreshKey: 1,
    };
  },
  watch: {
    mode() {
      this.refreshKey++;
    },
  },
  components: {
    KeyField,
    ValueField,
  },
  mixins: [fieldMixin],
  computed: {
    isArrayMode() {
      const dataType = this?.value?.data_type || "object";
      return dataType == "array";
    },
    allowAddition() {
      return (
        this.keyFieldDefinition(this.arrayEquivalent[0], 0).mode == "input"
      );
    },
    arrayEquivalent() {
      let arrayValue = [];
      const value = this.fieldValue;
      if (this.isArrayMode && Array.isArray(value)) {
        arrayValue = [...value];
      } else {
        Object.keys(value || {}).forEach((id) => {
          arrayValue.push({
            id,
            name: this.fieldValue[id],
          });
        });
      }
      debug(`arrayEquivalent`, arrayValue);
      return arrayValue;
    },
  },
  mounted() {
    debug("mounted of Key value pair", this.value);
  },
  methods: {
    allowDeletion(value, i) {
      return this.keyFieldDefinition(value, i).mode == "input";
    },
    commonProps() {
      return {
        mode: this.mode,
        display: {
          attributes: {
            clearable: true,
            dense: true,
          },
        },
      };
    },
    keyFieldDefinition(value, index) {
      const fieldDefinition = this.value?.fields?.key || {
        name: "key",
        label: "Key",
        value,
        mandatory: true,
        type: "text",
      };
      fieldDefinition.disabled =
        this.isDisabled && !!this.arrayEquivalent[index].id;
      const result = defaultsDeep(
        {},
        { value },
        fieldDefinition,
        this.commonProps(index)
      );
      debug(`Effective Key Definition`, result);
      return result;
    },
    valueFieldDefinition(value, index) {
      const fieldDefinition = this.value?.fields?.value || {
        name: "value",
        label: "Value",
        value,
        mandatory: true,
        type: "text",
      };
      const result = defaultsDeep(
        {},
        { value },
        fieldDefinition,
        this.commonProps(index)
      );
      debug(`Effective Value Definition`, result);
      return result;
    },
    valueUpdated(i, name) {
      debug(`in valueUpdated`, item, i, name);
      const item = this.arrayEquivalent[i];
      item.name = name;
      this.updateObject();
    },
    keyUpdated(i, id) {
      debug(`in keyUpdated`, item, i, id);
      const item = this.arrayEquivalent[i];
      item.id = id;
      this.updateObject();
    },
    deleteKey(index) {
      const arrayValue = [...this.arrayEquivalent];
      arrayValue.splice(index, 1);
      this.updateObject(arrayValue);
    },
    addKeyValue() {
      const arrayValue = [...this.arrayEquivalent];
      arrayValue.push({
        id: "",
        name: "",
      });
      this.updateObject(arrayValue);
    },

    updateObject(arrayValue) {
      arrayValue = [...(arrayValue || this.arrayEquivalent)];
      if (this.isArrayMode) {
        this.$set(this, "fieldValue", arrayValue);
      } else {
        const objValue = {};
        arrayValue.forEach((item) => {
          if (typeof objValue[item.id] == "undefined") {
            item.id = item.id || "";
            objValue[item.id] = item.name;
          }
        });
        this.$set(this, "fieldValue", objValue);
      }
    },
  },
};
</script>
