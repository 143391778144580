<template>
  <div v-if="isInputMode">
    <v-tooltip top v-if="value.hint">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          v-bind="getAttrs(attrs, displayAttributes)"
          v-on="on"
          :aut-action="value.name"
          :data-behavior-action="value.name"
          :icon="!!value.icon && !value.label"
          :loading="actionInProgress"
          :disabled="isButtonDisabled"
          v-bind:class="displayClasses"
          @click.stop="triggerAction"
          class="behavior-field-action"
        >
          <v-icon
            v-if="value.icon"
            :class="iconClasses"
            :aut-action-icon="value.icon"
            >{{ value.icon }}</v-icon
          >
          {{ label }}
        </v-btn>
      </template>
      {{ value.hint }}
    </v-tooltip>
    <v-btn
      block
      v-bind="displayAttributes"
      v-else
      :aut-action="value.name"
      :data-behavior-action="value.name"
      :loading="actionInProgress"
      :icon="!!value.icon && !value.label"
      :disabled="isButtonDisabled"
      v-bind:class="displayClasses"
      @click.stop="triggerAction"
      class="behavior-field-action"
    >
      <v-icon
        v-if="value.icon"
        :class="iconClasses"
        class="behavior_action_icon"
        :aut-action-icon="value.icon"
        >{{ value.icon }}</v-icon
      >
      {{ label }}
    </v-btn>
  </div>
</template>
<script>
import { registerToEvents, uniqueID } from "@/util";
import { defaultsDeep } from "lodash";
import { confirmationMixin, fieldMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.component.action"); // eslint-disable-line 


export default {
  mixins: [fieldMixin, confirmationMixin],
  name: "Action",
  data() {
    return {
      definition,
      actionInProgress: false,
    };
  },
  computed: {
    showLoader() {
      const result = !!this.displayAttributes?.show_loader;
      debug(`show_loader`, result);
      return result;
    },
    isButtonDisabled() {
      if (this.isDisabled) {
        return this.isDisabled;
      }
      const constraint = this?.value?.constraint;
      if (!constraint) {
        return false;
      }
      const constraintMet = this.$store.getters[
        `${this.context}/checkConstraint`
      ]({
        constraint,
      });
      return !constraintMet;
    },
  },
  mounted() {
    this.registerEvents();
  },
  methods: {
    registerEvents() {
      if (!this.showLoader) {
        return;
      }

      const component = this;
      this.actionID = `mark_done_${uniqueID()}`;
      const unsubscribe = registerToEvents({
        $store: component.$store,
        events: [
          {
            name: component.actionID,
          },
        ],
        callBackFn: (triggeredEvent, payload) => {
          debug(`In button`, triggeredEvent, payload);
          component.actionInProgress = false;
        },
      });
      component._subscribe(unsubscribe);
    },
    getAttrs(attrs, displayAttributes) {
      return defaultsDeep({}, attrs, displayAttributes);
    },
    emitEvent(triggerValue) {
      const methodDebug = debug.extend(
        `emitEvent_${this.value.id || this.value.name}`
      );
      let payload = {
        definition: triggerValue,
      };
      if (this.showLoader && this.actionID) {
        methodDebug(
          `Action configured to show loader. event: [${this.actionID}]`
        );
        this.actionInProgress = true;
        payload.eventID = this.actionID;
      }

      if (triggerValue.submit) {
        methodDebug(`Emitting trigger submit`);
        this.$emit("trigger-submit", payload);
      } else {
        methodDebug(`Emitting trigger action`);
        this.$emit("trigger-action", payload);
      }
    },
    triggerAction() {
      const confirmation = this?.value?.confirmation;
      const fields = this?.value?.fields || [];

      let triggerValue;
      if (fields.length) {
        triggerValue = this.addItem();
      }
      if (confirmation) {
        this.showConfirmation(confirmation, () => {
          this.emitEvent(triggerValue || this.value);
        });
        return;
      }
      this.emitEvent(triggerValue || this.value);
    },
    addItem() {
      const actionValue = {
        params: {
          data: {},
        },
      };

      const fieldsObject = {};
      this.value.fields.forEach(
        (field) => (fieldsObject[field] = this.getValue(field))
      );

      const dataId = this.getValue("id");
      actionValue.params.data[dataId] = fieldsObject;

      return defaultsDeep({}, { value: actionValue }, this.value);
    },
    getValue(fieldPath) {
      return this.$store.getters[`${this.context}/fieldValue`](fieldPath);
    },
  },
};
</script>
