const debug = require("debug")("atman.components.entity_field"); // eslint-disable-line
export default {
  label: "Entity",
  type: "entity",
  description: "Displays an entity",
  properties: ["name", "label", "type", "display", "block", "page"],
  templates: [
    {
      id: "default",
      description: "Default Entity Card.",
      label: "Default",
      variables: {
        data: {
          ticket: "0",
        },
      },
      value: {
        name: "default",
        label: "Title",
        type: "entity",
        dynamic_label: "{title} ({priority})",
        context: "/applications/ticket_management/tickets/{_data->ticket}",
      },
    },
    {
      id: "field",
      description: "Entity Card with field specified",
      label: "A Field can be specified",
      variables: {
        data: {
          ticket: "0",
        },
      },
      value: {
        name: "some_name",
        label: "Title",
        type: "entity",
        entity_field: {
          type: "address",
          name: "address",
          mode: "display",
        },
        context: "/applications/ticket_management/tickets/{_data->ticket}",
      },
    },
    {
      id: "dialog",
      description: "IF an action is specified, it is triggered",
      label: "Trigger Dialog",
      variables: {
        data: {
          ticket: "0",
        },
      },
      value: {
        name: "dialog",
        label: "Title",
        type: "entity",
        dynamic_label: "{title} ({priority})",
        context: "/applications/ticket_management/tickets/{_data->ticket}",
        action: {
          type: "dialog",
          url: "/applications/ticket_management/tickets/{_data->ticket}?page=edit",
        },
      },
    },
    {
      id: "navigation",
      description: "Navigates to another page when clicked.",
      label: "Trigger Navigation",
      variables: {
        data: {
          ticket: "0",
        },
      },
      value: {
        name: "navigation",
        label: "Title",
        type: "entity",
        dynamic_label: "{title} ({priority})",
        context: "/applications/ticket_management/tickets/{_data->ticket}",
        action: {
          type: "navigation",
          url: "/applications/ticket_management/tickets/{_data->ticket}?page=edit",
        },
      },
    },
  ],
};
