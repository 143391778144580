import datePicker from "../DatePicker";
import { defaultsDeep } from "lodash";
const debug = require("debug")("atman.components.due_date"); // eslint-disable-line

import { subDays, addDays } from "date-fns";
export default {
  label: "Due Date",
  type: "due_date",
  description: "Displays a due date field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
    {
      key: "near_limit",
      description:
        "Notifies the user when near_limit(number of the days from due date) is reached",
      affects_display: "yes",
    },
    {
      key: "show_overdue",
      description:
        "Controls whether or not to actually show the warning colors when nearing or passing the due date",
      affects_display: "yes",
      values: [
        {
          value: "true",
          hint: "User will be warned",
        },
        {
          value: "false",
          hint: "User will not be warned",
        },
      ],
    },
  ],
  searchField: (col) => {
    let result = defaultsDeep(
      {
        mode: "input",
        type: "date",
        dev_comment_type:
          "needs to be date since earlier dates can be selected when searching",
        label: col.label,
        display: {
          classes: ["pt-1"],
          width: "3",
          attributes: {
            store_date_only: true,
            clearable: true,
          },
        },
      },
      col
    );
    debug(`Search Text, search Field`, result);
    return result;
  },
  templates: [
    {
      id: "default",
      description: "The default Due Date field.",
      label: "Default",
      value: {
        label: "Due Date",
        name: "some_name",
        type: "due_date",
      },
    },
    {
      id: "due_date_not_nearing_limit",
      keyword: ["tutorial"],
      description:
        "Due Date field configured to display date when not nearing the limit.",
      label: "Due Date Display Not Nearing Limit",
      value: {
        mode: "display",

        label: "Due Date",
        type: "due_date",
        value: `${addDays(new Date(), 4).toISOString()}`,
        near_limit: "2",
      },
    },
    {
      id: "due_date_with_near_limit",
      keyword: ["tutorial"],
      description:
        "Due Date field configured to display date when nearing the limit.",
      label: "Due Date Display Nearing Limit",
      value: {
        mode: "display",

        label: "Due Date",
        type: "due_date",
        value: `${addDays(new Date(), 1).toISOString()}`,
        near_limit: "2",
      },
    },
    {
      id: "due_date_with_overdue",
      keyword: ["tutorial"],
      description: "Due Date field configured to display date when overdue.",
      label: "Due Date elapsed",
      value: {
        mode: "display",

        label: "Due Date",
        type: "due_date",
        value: `${subDays(new Date(), 1).toISOString()}`,
      },
    },
    {
      id: "due_date_with_overdue_but_overridden",
      description:
        "Due Date field configured to intelligently display a warning overdue based on another field's value",
      label: "Due Date elapsed but no warning",
      variables: {
        data: {
          status: "OK",
        },
      },
      value: {
        mode: "display",

        show_overdue: {
          conditions: [
            {
              rule: "'{_data->status}' == 'OK'",
              value: false,
            },
          ],
        },
        label: "Due Date",
        type: "due_date",
        value: `${subDays(new Date(), 1).toISOString()}`,
      },
    },
  ],
  attributes: datePicker.definition.attributes,
};
