<template>
  <div aut-application-editor-page>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails && currentContext"
      :page="flowDetails"
      :context="currentContext"
    />
  </div>
</template>

<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";
import edit_application_page from "./page_content.js";
const debug = require("debug")("atman.components.application_editor");
debug(`application_editor`);
export default {
  name: "ApplicationEditor",
  mixins: [systemPageMixin],
  data() {
    return {};
  },
  mounted() {
    debug(`in mounted of application editor`);
    this.renderPage();
  },
  methods: {
    renderPage() {
      const component = this;
      const applicationID = component.$route.params.id;
      const url = `/applications/${applicationID}?page=home`;
      component.currentContext = createContext(url);
      component.flowDetails = clone(edit_application_page);
    },
  },
};
</script>
