export default [
  {
    key: "name",
    mandatory: true,
    hint: "A unique key to identify this field",
    affects_display: "no",
  },
  {
    key: "label",
    mandatory: true,
    hint: "Is displayed as the label",
    affects_display: "yes",
  },
  {
    key: "type",
    mandatory: true,
    hint: "Control the type of field",
    affects_display: "yes",
    values: [],
  },
  {
    key: "value",
    mandatory: false,
    hint: "Control the default value of the field",
    affects_display: "yes",
  },
  {
    key: "mandatory",
    mandatory: true,
    hint: "Controls whether the user can empty this field",
    affects_display: "yes",
    values: [
      {
        value: "true",
        hint: "User will not be able to empty this field",
      },
      {
        value: "false",
        hint: "User will be able to empty this field",
      },
    ],
  },
  {
    key: "mode",
    mandatory: false,
    hint: "Controls how this component is displayed",
    affects_display: "yes",
    values: [
      {
        label: "Input",
        value: "input",
        hint: "Content will be editable by the user",
      },
      {
        label: "Display",
        value: "display",
        hint: "Content if available will be displayed",
      },
      {
        label: "Hidden",
        value: "hidden",
        hint: "The field will be available but hidden",
      },
      {
        label: "Ignored",
        value: "ignored",
        hint: "The field will be not be present",
      },
    ],
  },
  {
    key: "disabled",
    mandatory: false,
    hint: "Controls whether the input is disabled",
    affects_display: "yes",
    values: [
      {
        label: "true",
        value: true,
        hint: "Content will be disabled",
      },
      {
        label: "false",
        value: false,
        hint: "Content will not disabled",
      },
    ],
  },
  {
    key: "display",
    mandatory: false,
    hint: "Controls the display of this control",
    affects_display: "yes",
    properties: [
      {
        key: "width",
        mandatory: false,
        hint: "Controls the width of the displayed field",
        affects_display: "yes",
        values: [
          {
            value: "full",
            hint: "Occupies the entire row",
          },
          {
            value: "n: 1-12",
            hint: "occupy n columns",
          },
        ],
      },
      {
        key: "attributes",
        mandatory: false,
        hint: "All the keys and values in this object are added as attributes to the control",
        affects_display: "yes",
      },
      {
        key: "block",
        mandatory: false,
        hint: "Controls whether the field takes up the entire row",
        affects_display: "yes",
        values: [
          {
            value: "true",
            hint: "The field will take up the full row regardless of width",
          },
          {
            value: "false",
            hint: "The field will take up the full row only if the width needs it",
          },
        ],
      },
    ],
  },
  {
    key: "block",
    mandatory: false,
    hint: "Controls whether the field takes up the whole row",
    affects_display: "yes",
    values: [
      {
        value: "true",
        hint: "Field will take up the whole row",
      },
      {
        value: "false",
        hint: "Field will NOT take up the whole row",
      },
    ],
  },
  {
    key: "fields",
    mandatory: true,
    hint: "The fields in the object",
    affects_display: "yes",
  },
  {
    key: "options",
    mandatory: true,
    hint: "Tells the control how the options to be displayed will be fetched",
    affects_display: "yes",
    properties: [
      {
        key: "value",
        mandatory: true,
        hint: "Indicates that the list of options is a static array",
        properties: [
          {
            value: "An array of `id` and `label`s",
          },
        ],
      },
      {
        key: "apis",
        mandatory: true,
        hint: "Indicates that the list of options is a dynamic list to be fetched from the backend",
        properties: [
          {
            key: "url",
            mandatory: true,
            hint: "URL to invoke",
          },
          {
            key: "type",
            mandatory: true,
            hint: "Type of request to send",
            values: [
              {
                value: "get",
                hint: "GET request",
              },
              {
                value: "post",
                hint: "POST request",
              },
            ],
          },
        ],
      },
    ],
  },
];
