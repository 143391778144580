<template>
  <v-container fluid>
    <v-card aut-feature-flags>
      <v-card-title>Feature Flags</v-card-title>
      <v-card-text>
        <FeatureSwitches :features="feature_flags" @update="updateFeature" />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import permissionMixin from "@/views/permission_mixin.js";
import { feature_flags, updateFeatureFlag } from "@/feature_flags";
import FeatureSwitches from "./FeatureSwitches";
const debug = require("debug")("feature_flag_view"); // eslint-disable-line

export default {
  name: "FeatureFlags",
  mixins: [permissionMixin],
  components: {
    FeatureSwitches,
  },
  data() {
    return {
      feature_flags,
    };
  },
  methods: {
    updateFeature({ name, value }) {
      // feature.visibility(name, value);
      // debug(`feature ${name} is now ${feature.isVisible(name)}`)
      updateFeatureFlag(name, value);
    },
  },
};
</script>
