export default {
  _permissions: {
    enabled: true,
    permission: {
      value: "list_domains",
    },
  },
  content: [
    {
      apis: {
        data: {
          refresh_interval: 300000,
          type: "get",
          url: "/domains",
        },
      },
      definition: {
        actions: [],
        fields: [
          {
            label: "Name",
            mode: "display",
            name: "name",
            type: "text",
          },
          {
            label: "Self Registration",
            mode: {
              conditions: [
                {
                  rule: "{_access_control->change_domains||no} != yes",
                  value: "display",
                },
              ],
            },
            values: ["YES", "NO"],
            name: "registration_requires_approval",
            type: "switch",
            display: {
              checkbox: true,
            },

            apis: {
              submit: {
                params: {
                  action: "update_settings",
                },
                url: "/domains/{_row->id}",
                type: "post",
              },
            },
          },
          {
            label: "Created",
            mode: "display",
            name: "_created_date",
            type: "date",
          },
          {
            label: "Created By",
            mode: "display",
            name: "_created_by",
            type: "username",
          },
          {
            label: "Actions",
            name: "actions",
            type: "actions",
            value: [
              {
                name: "delete",
                hint: "Delete",
                icon: "mdi-delete",
                type: "action",
                permission: "change_domains",
                confirmation: "This is an irreversible action. Are you sure?",
                value: {
                  type: "delete",
                  url: "/domains/{_row->name}",
                  success: {
                    message: "Deletion successful",
                  },
                },
                display: {
                  attributes: {
                    color: "error",
                  },
                },
              },
            ],
          },
        ],
        title: "",
      },
      display: {
        width: "12",
      },
      id: "list",
      type: "list",
    },
  ],
  classes: "behavior-no-fav behavior-no-pin behavior-no-editor",
  id: "domains_list",
  title: "Domains",
};
