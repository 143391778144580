<template>
  <v-card
    v-if="displayAsChip"
    class="behavior_chip"
    :class="chipClasses"
    flat
    outlined
    :color="chipColor"
  >
    <v-row dense>
      <v-col cols="12" v-if="label">
        <div
          class="text-caption behavior_break_word white--text"
          aut-field-label
        >
          {{ label }}
        </div>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="white--text">
      <v-col class="pa-0">
        <slot></slot>
      </v-col>
    </v-row>
  </v-card>
  <div v-else :class="labelContainerClass">
    <label
      v-if="label && shouldDisplayLabel"
      :class="labelClasses"
      aut-field-label
    >
      <span v-if="isRawHTML" v-html="label"></span>
      <span v-if="!isRawHTML">{{ label }}{{ showColon ? ":" : "" }}&nbsp;</span>
    </label>
    <v-icon
      v-else-if="prefixIcon"
      :color="prefixIcon.color"
      aut-field-icon
      class="mr-1"
    >
      {{ prefixIcon.icon }}
    </v-icon>
    <slot></slot>
  </div>
</template>
<script>
import { isPlainObject, has } from "lodash";
const debug = require("debug")("atman.field_label"); // eslint-disable-line
const chipBackgrounds = ["chip_0", "chip_1", "chip_2", "chip_3"];
const badgeBackgrounds = [
  "badge_1",
  "badge_2",
  "badge_3",
  "badge_4",
  "badge_5",
];
export default {
  name: "FieldLabel",
  props: {
    label: {},
    mode: {
      type: String,
      default: "input",
    },
    attributes: {},
    definition: {
      type: Object,
    },
  },
  computed: {
    showColon() {
      return !this.definition?.display?.attributes?.label_hide_colon;
    },
    displayAsChip() {
      return this.chipAttributes?.enabled == true;
    },
    chipClasses() {
      const classes =
        this.definition?.display?.attributes?.display_as_chip?.classes;

      if (!classes || !classes.length) {
        return "text-center";
      }
      return classes;
    },
    chipAttributes() {
      return this.definition?.display?.attributes?.display_as_chip;
    },
    chipColor() {
      const value = this.definition.value;
      let noValueColor = this.isDark ? "#444" : "grey darken-1";
      const background = this.chipAttributes?.chip_background;
      const random = this.chipAttributes?.random_color;
      if (!value) {
        return noValueColor;
      }

      if (isPlainObject(background)) {
        if (has(background, value)) {
          const color_value = background[value];
          return chipBackgrounds.includes(color_value) ? color_value : "";
        }
      }
      if (random) {
        const randomIndex = Math.floor(Math.random() * badgeBackgrounds.length);
        return badgeBackgrounds[randomIndex];
      }
      return value && chipBackgrounds.includes(background) ? background : "";
    },
    classes() {
      return this.attributes?.classes || [];
    },
    effectiveMode() {
      return this.mode && this.attributes?.[this.mode];
    },
    isRawHTML() {
      return this.attributes?.raw_html;
    },
    prefixIcon() {
      const result = this.effectiveMode?.prefix_icon;
      debug(`prefixIcon`, result);
      return result;
    },
    shouldDisplayLabel() {
      let result = true;

      const mode = this.effectiveMode?.mode;
      // If no label is available, return false

      if (!this.label) {
        result = false;
      }
      // If the configuration specifies not to display then don't
      else if (mode) {
        if (mode == "hidden" || mode == "icon") {
          result = false;
        }
      }
      debug(`FieldLabel shouldDisplayLabel: [${result}]`);
      return result;
    },
    labelClasses() {
      let result = this.classes.join(" ");
      // IF the label and value are shown side-by-side then adding some padding before the value
      debug(`labelClasses: `, result);
      return result;
    },
    labelContainerClass() {
      return this.classes.includes("behavior_block")
        ? ""
        : this.label && this.shouldDisplayLabel
        ? "d-inline-flex"
        : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_chip {
  ::v-deep {
    .behaviour_display_value {
      font-size: smaller !important;
    }
  }
}
</style>
