<template>
  <div>
    <div>
      <BookSearch />
    </div>
    <div v-if="categories.length && !category">
      <div v-for="(categoryItem, i) in categories" :key="`category_${i}`">
        <div>
          <span class="text-h5 primary--text">{{ categoryItem.name }}</span>
        </div>
        <Books
          :attributes="displayAttributes"
          :filter="{ categories: `*${categoryItem.id}*` }"
          :context="context"
          mode="carousel"
          @show:category="showCategory(categoryItem)"
        />
      </div>
    </div>
    <div v-else-if="category">
      <p class="text-h5 primary--text">{{ category.name }}</p>
      <Books
        :attributes="displayAttributes"
        :filter="{ categories: `*${category.id}*` }"
        :context="context"
        mode="cards"
      />
    </div>
  </div>
</template>

<script>
import { clone } from "@/util";
import Books from "./Books.vue";
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.components.book_catalogue"); // eslint-disable-line

export default {
  name: "BookCatalogue",
  mixins: [fieldMixin],
  components: {
    Books,
    BookSearch: () => import("./BookSearch.vue"),
  },
  data() {
    return {
      definition: definition,
      categories: [],
      category: null,
    };
  },
  mounted() {
    this.updateCategories();
  },
  methods: {
    _afterFieldValueUpdate() {
      this.updateCategories();
    },
    updateCategories() {
      this.categories = this.fieldValue || [];
      const categoryID = this?.$route?.query?.category;
      if (categoryID && this.categories.length) {
        this.category = this.categories.find(({ id }) => id == categoryID);
      }
    },
    showCategory(category) {
      if (this.$route.query.category == category.id) {
        return;
      }
      const queryParams = Object.assign({}, clone(this.$route.query), {
        category: category.id,
      });
      this.$router.push({ query: queryParams });
    },
  },
};
</script>
