<template>
  <v-expansion-panels inset focusable>
    <v-expansion-panel>
      <v-expansion-panel-header aut-tab-rules> Rules </v-expansion-panel-header>
      <v-expansion-panel-content aut-tab-rules-content>
        <v-row dense class="mt-2">
          <v-col cols="12" lg="6" class="d-flex">
            <v-text-field
              aut-password-min-flag
              dense
              name="min"
              label="Minimum"
              v-model="min"
              clearable
              type="number"
              hint="Minimum characters"
              persistent-hint
              required
              @change="updateDefinition"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="d-flex">
            <v-text-field
              aut-password-max-flag
              dense
              name="max"
              label="Maximum"
              v-model="max"
              clearable
              type="number"
              hint="Maximum characters"
              persistent-hint
              required
              @change="updateDefinition"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-row dense>
              <v-col cols="12" lg="3">
                <v-switch
                  dense
                  label="Numbers"
                  aut-password-has-numbers
                  name="has_numbers"
                  v-model="has_numbers"
                  @change="updateDefinition"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="3">
                <v-switch
                  dense
                  label="Uppercase"
                  aut-password-has-uppercase-characters
                  name="has_upper_case"
                  v-model="has_upper_case"
                  @change="updateDefinition"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="3">
                <v-switch
                  dense
                  label="Lowercase"
                  aut-password-has-lowercase-characters
                  name="has_lower_case"
                  v-model="has_lower_case"
                  @change="updateDefinition"
                ></v-switch>
              </v-col>
              <v-col cols="12" lg="3">
                <v-switch
                  dense
                  label="Special"
                  aut-password-has-special-characters
                  name="has_special_characters"
                  v-model="has_special_characters"
                  @change="updateDefinition"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
const debug = require("debug")("atman.components.password_designer");
import { setRule, getRuleValue, clone } from "@/util.js";
debug(`password_designer`);
export default {
  name: "PasswordFieldDesigner",
  components: {},
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    const fieldDefinition = clone(this.definition || {});
    const rules = fieldDefinition.rules || [];
    const result = {
      fieldDefinition,
      min: getRuleValue(rules, "min_length"),
      max: getRuleValue(rules, "max_length"),
      has_numbers: getRuleValue(rules, "has_numbers"),
      has_upper_case: getRuleValue(rules, "has_upper_case"),
      has_lower_case: getRuleValue(rules, "has_lower_case"),
      has_special_characters: getRuleValue(rules, "has_special_characters"),
    };
    return result;
  },
  methods: {
    updateDefinition() {
      if (this.min) {
        setRule(this.fieldDefinition.rules, "min_length", this.min);
      }
      if (this.max) {
        setRule(this.fieldDefinition.rules, "max_length", this.max);
      }
      //TODO assert that max is not less than min
      if (typeof this.has_numbers !== "undefined") {
        setRule(this.fieldDefinition.rules, "has_numbers", this.has_numbers);
      }
      if (typeof this.has_upper_case !== "undefined") {
        setRule(
          this.fieldDefinition.rules,
          "has_upper_case",
          this.has_upper_case
        );
      }
      if (typeof this.has_lower_case !== "undefined") {
        setRule(
          this.fieldDefinition.rules,
          "has_lower_case",
          this.has_lower_case
        );
      }
      if (typeof this.has_special_characters !== "undefined") {
        setRule(
          this.fieldDefinition.rules,
          "has_special_characters",
          this.has_special_characters
        );
      }
      debug(`PasswordFieldDesigner updateDefinition`, this.fieldDefinition);
      this.$emit("update:definition", this.fieldDefinition);
    },
  },
};
</script>
