<template>
  <v-row dense>
    <v-col cols="12">
      <APIDesigner
        :definition.sync="fieldDefinition.apis.submit"
        @update:definition="updateDefinition"
        type="upload"
      />
    </v-col>
  </v-row>
</template>
<script>
import { defaultsDeep } from "lodash";
import { clone } from "@/util.js";
const debug = require("debug")("atman.components.file_field_designer");
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";
debug(`file_field_designer`);
export default {
  name: "FileFieldDesigner",
  components: {
    APIDesigner,
  },
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      fieldDefinition: defaultsDeep(clone(this.definition), {
        apis: {
          submit: {
            type: "post",
            url: "/api/fileupload",
          },
        },
      }),
    };
  },
  created() {
    debug(`In created of FileFieldDesigner`);
  },
  mounted() {
    debug(`In mounted of FileFieldDesigner`);
  },
  methods: {
    updateDefinition() {
      debug(`FileFieldDesigner updateDefinition`);
      this.$emit("update:definition", this.fieldDefinition);
    },
  },
};
</script>
