<template>
  <div>
    <div :class="`text-${effectiveSize}`" v-if="isHTML" v-html="content" />
    <div :class="`text-${effectiveSize}`" v-else>{{ content }}</div>
  </div>
</template>
<script>
export default {
  name: "ContentLabel",
  props: {
    content: {},
    effectiveSize: {
      type: String,
    },
  },
  computed: {
    isHTML() {
      return /<\/?[a-z][\s\S]*>/i.test(this.content);
    },
  },
};
</script>
