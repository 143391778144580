<template>
  <div class="relative">
    <EditorActions :actions="editorActions" @remove_action="$emit('remove')" />
    <v-row aut-action-designer>
      <v-col cols="12" lg="6">
        <v-text-field
          dense
          aut-action-label
          name="label"
          label="Label"
          hint="Choose the button label"
          persistent-hint
          v-model="action.label"
          @change="updateDefinition"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <IconField v-bind="propsData" @change="updateIcon" />
      </v-col>
      <v-col cols="12" v-if="isList">
        <v-switch
          dense
          aut-edit-action-bulk-operation
          name="bulk_operation"
          v-model="action.bulk_operation"
          label="Bulk Operation"
          persistent-hint
          hint="This button will only be enabled if at least one row is selected"
        ></v-switch>
      </v-col>
      <v-col cols="12">
        <v-textarea
          dense
          :rows="2"
          auto-grow
          aut-action-confirmation
          name="confirmation"
          label="Confirmation Message"
          hint="If a message is entered, it will be displayed as a confirmation before performing the action"
          persistent-hint
          v-model="action.confirmation"
          @change="updateDefinition"
          required
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          aut-action-constraint
          dense
          small
          clearable
          required
          :items="constraintTypes"
          label="Constraint"
          hint="Enable this action only if this constraint is met"
          persistent-hint
          item-value="id"
          item-text="label"
          v-model="action.constraint"
          @change="updateDefinition"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <APIDesigner
          :definition.sync="action.value"
          @update:definition="updateDefinition"
          type="action"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { snakeCase } from "lodash";
import { clone } from "@/util.js";
import EditorActions from "@/components/editor/EditorActions";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";

const debug = require("debug")("atman.components.action_designer"); // eslint-disable-line

export default {
  name: "ActionDesigner",
  props: {
    definition: {
      type: Object,
    },
    controls: {
      type: Array,
      default: () => [],
    },
    isList: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    APIDesigner,
    EditorActions,
    IconField: () => import("@/components/fields/IconField/IconField"),
  },
  data() {
    return {
      action: clone(this.definition),
      context: "",
      propsData: {
        value: {
          name: `action_icon`,
          mode: "input",
          type: "icon",
          value: {
            icon: this.definition?.icon,
          },
        },
      },
    };
  },
  computed: {
    editorActions() {
      return [...(this.controls || [])];
    },
  },
  created() {
    this.constraintTypes = [
      {
        label: "Dirty",
        id: "dirty",
      },
      {
        label: "Not Dirty",
        id: "not_dirty",
      },
    ];
  },
  methods: {
    updateIcon(value) {
      const newIcon = typeof value == "string" ? value : value?.icon;
      if (newIcon != this.action?.icon) {
        this.action.icon = newIcon;
        this.updateDefinition();
      }
    },
    updateDefinition() {
      this.action.name = snakeCase(this.action.label);
      this.$emit("update:definition", this.action);
    },
  },
};
</script>
