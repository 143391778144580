export default {
  classes:
    "behavior-dynamic-page behavior-no-editor behavior-no-fav behavior-no-pin",
  content: [
    {
      apis: {
        submit: {
          label: "Create",
          type: "post",
          url: "{_context->path}",
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Role added",
          },
        },
      },
      definition: {
        fields: [
          {
            label: "Name",
            name: "name",
            type: "text",
          },
        ],
      },
      id: "create_role",
      type: "form",
    },
  ],
  id: "create_role",
  name: "create_role_page",
  title: "Create a Role",
  type: "page",
};
