import { forOwn } from "lodash";
import domainSpecificCustomisation from "/customisation/domain_specific_customisation";
const debug = require("debug")("atman.customisation"); // eslint-disable-line

const createTag = (inputTag = {}) => {
  const element = document.createElement(inputTag.type);
  forOwn(inputTag.attributes || {}, (value, key) => {
    element.setAttribute(key, value);
  });
  (inputTag.tags || []).forEach((childTag) => {
    element.appendChild(createTag(childTag));
  });
  if (inputTag.innerHTML) {
    element.innerHTML = inputTag.innerHTML;
  }
  return element;
};

const addCustomTags = (tags = []) => {
  debug(`tags`, tags);
  tags.forEach((tagConfig) => {
    const tagElement = createTag(tagConfig);
    document[tagConfig.target].appendChild(tagElement);
  });
};
const loadCustomisation = () => {
  try {
    const currentDomain = window.location.hostname;
    const customContent = domainSpecificCustomisation[currentDomain];
    if (!customContent) {
      debug(`No custom content for domain`);
      return;
    }
    debug(`in loadCustomisation`, customContent);
    if (customContent.tags) {
      addCustomTags(customContent.tags);
    }
  } catch (e) {
    console.error(e);
  }
};

const triggerCustomEvents = (toPath) => {
  try {
    debug(`toPath`, toPath);
    const currentDomain = window.location.hostname;
    const customContent = domainSpecificCustomisation[currentDomain] || {};
    let customEvents = customContent.page_specific_events || [];
    customEvents = customEvents.filter((eventConfig) => {
      return toPath.fullPath.match(eventConfig.url);
    });
    if (!customEvents.length) {
      debug(`No custom events to trigger for path: [${toPath.fullPath}]`);
      return;
    }
    debug(`customEvents`, customEvents);
    customEvents.forEach((eventConfig) => {
      debug(`window[${eventConfig.event}]`, window[eventConfig.event]);
      if (typeof window[eventConfig.event] != "function") {
        debug(`custom event does not exist`);
        return;
      }
      const params = eventConfig.params || [];
      window[eventConfig.event](...params);
      debug(`Invoked ${eventConfig.event} with params`, ...params);
    });
  } catch (e) {
    console.error(`Error`, e);
  }
};

export { loadCustomisation, triggerCustomEvents };
