<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="display = false"
    @click:outside="display = false"
  >
    <v-card aut-tutorial-dialog v-if="tutorial">
      <v-card-title> {{ tutorial.title || "Tutorial" }} </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="tutorial.text">
            <div v-html="tutorial.text" />
          </v-col>
          <v-col cols="12" v-if="tutorial.gif">
            <v-img
              aut-tutorial-image
              class=""
              contain
              :src="tutorial.gif"
              :lazy-src="no_image"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          aut-action-edit-field-update
          @click="display = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tutorials from "./tutorials";
import { SHOW_TUTORIAL } from "@/constants";
import { mapState } from "vuex";
const debug = require("debug")("atman.components.tutorial"); // eslint-disable-line
export default {
  name: "Tutorial",
  data() {
    return {
      display: false,
      tutorial: null,
    };
  },
  computed: {
    ...mapState(["no_image"]),
  },
  mounted: async function () {
    debug("In mounted");
    this.registerEvents();
  },
  methods: {
    showTutorial(payload) {
      debug(`in show tutorial`, payload);
      this.tutorial = tutorials[payload];
      this.display = true;
    },
    registerEvents() {
      const component = this;
      const unsubscribe = component.$store.subscribeAction((action) => {
        if (action.type == SHOW_TUTORIAL) {
          component.showTutorial(action.payload);
        }
      });
      component._subscribe(unsubscribe);
    },
  },
};
</script>
