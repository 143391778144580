<template>
  <div v-if="displayValue">
    {{ displayValue }}
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import { STORE_CONSTS } from "@/components/fields/store.js";
import { isValid, intervalToDuration, formatDuration } from "date-fns";

const debug = require("debug")("atman.components.computed_duration"); // eslint-disable-line

export default {
  mixins: [fieldMixin],
  name: "ComputedDuration",
  mounted() {
    const component = this;
    setTimeout(() => {
      component.determineValue();
    }, 3000);
  },
  data() {
    return {
      displayValue: null,
      definition,
    };
  },
  methods: {
    setupWatch() {
      const component = this;
      const methodDebug = debug.extend(`setupWatch`); //eslint-disable-line
      const unsubscribe = component.$store.subscribe((mutation) => {
        const isDataUpdate =
          mutation.type == `${component.context}/${STORE_CONSTS.DATA}`;
        const isFieldUpdate =
          mutation.type == `${component.context}/${STORE_CONSTS.FIELD}`;
        if (!isDataUpdate && !isFieldUpdate) {
          return;
        }
        component.$nextTick(() => {
          component.determineValue();
        });
      });
      component._subscribe(unsubscribe);
    },

    determineValue() {
      const component = this;
      component.displayValue = null;
      const startTimeField = this.value.start_time;
      const endTimeField = this.value.end_time;
      const value1 =
        component.$store.getters[`${component.context}/fieldValue`](
          startTimeField
        );
      const value2 =
        component.$store.getters[`${component.context}/fieldValue`](
          endTimeField
        );
      if (!value1 || !value2) {
        debug(`Don't have start and end times to determine diff`);
        return;
      }
      debug(`In determine value`, value1, value2);
      let [startHour, startMinute] = value1.split(":");
      let [endHour, endMinute] = value2.split(":");

      // Convert to numbers
      startHour = startHour * 1;
      startMinute = startMinute * 1;
      endHour = endHour * 1;
      endMinute = endMinute * 1;

      if (
        isNaN(startHour) ||
        isNaN(startMinute) ||
        isNaN(endHour) ||
        isNaN(endMinute)
      ) {
        debug(`Invalid values for time`);
        return;
      }

      const today = new Date();
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        startHour,
        startMinute,
        0
      );
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        endHour,
        endMinute,
        0
      );
      debug(`In determine dates`, startDate, endDate);

      if (!isValid(startDate) || !isValid(endDate)) {
        debug(`Invalid values for time`);
        return;
      }

      debug(`In determine dates`, isValid(startDate), isValid(endDate));
      const duration = intervalToDuration({ start: startDate, end: endDate });
      const formattedDuration = formatDuration(duration, {
        format: ["hours", "minutes"],
      });
      debug(`In determine value`, formattedDuration);
      component.displayValue = formattedDuration;
    },
  },
};
</script>
