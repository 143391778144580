<template>
  <v-hover v-slot="{ hover }">
    <!-- 
      IMPORTANT: DONOT add styling or classes directly to this element. 

      1. It is a wrapper and is used in several layers: page, form, field
      2. The div has an if condition so any classes you do add should be added
      to the else too.
     -->
    <div class="relative" :class="fieldAttributes(hover)" v-if="is_design_mode">
      <EditorActions
        :classes="classes"
        :context="context"
        v-if="is_design_mode"
        :actions="editorActions"
        @toggle_json="closeJSONDialog"
        @show_variables="variablesDialog = true"
        v-on="$listeners"
      />
      <!-- The IF condition is necessary to reset the jsonEditor when it is cancelled and then re-rendered -->
      <v-dialog
        v-model="jsonMode"
        width="800"
        @keydown.esc.stop.prevent="cancel"
        @click:outside="cancel"
      >
        <v-card aut-edit-content-dialog>
          <DialogTitle @close="cancel" title="JSON Editor" />
          <v-card-text>
            <JSONEditor
              aut-editor-json
              v-model="jsonDefinition"
              :context="context"
              :key="jsonUpdateKey"
              @update="valid = true"
              @error="valid = false"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancel" aut-action-edit-form-cancel> Cancel </v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              @click="updateDefinition"
              aut-action-edit-content-update
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="variablesDialog"
        width="800"
        @keydown.esc.stop.prevent="variablesDialog = false"
        @click:outside="variablesDialog = false"
      >
        <v-card>
          <DialogTitle @close="variablesDialog = false" title="Variables" />
          <v-card-text>
            <VariablesTutorial
              :url="pageURL"
              v-model="variablesDefinition"
              :store_key="context"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <slot v-if="!jsonMode"></slot>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </v-hover>
</template>
<script>
import { clone, createContext } from "@/util.js";
import EditorActions from "@/components/editor/EditorActions";
import DialogTitle from "@/components/pageContent/DialogTitle.vue";

const debug = require("debug")("atman.components.editor_wrapper"); // eslint-disable-line

export default {
  name: "EditorWrapper",
  components: {
    DialogTitle,
    EditorActions,
    JSONEditor: () => import("@/components/fields/JSONEditor/JSONEditor"),
    VariablesTutorial: () =>
      import("@/components/fields/VariablesTutorial/VariablesTutorial"),
  },
  data() {
    return {
      pageURL: "",
      valid: true,
      jsonMode: false,
      variablesDialog: false,
      variablesDefinition: {},
      jsonUpdateKey: 1,
      jsonDefinition: {
        type: "json_editor",
        value: clone(this.definition),
        // mode: "explicit",
      },
    };
  },
  watch: {
    jsonDefinition() {
      debug(`jsonDefinition updated`, this.jsonDefinition);
    },
  },
  computed: {
    toggleIcon() {
      return this.jsonMode ? `mdi-code-tags` : `mdi-code-braces`;
    },
    toggleText() {
      return this.jsonMode ? "Switch to Display Mode" : "Switch to JSON Mode";
    },
    editorActions() {
      const result = [
        ...(this.actions || []),
        ...[
          {
            id: "toggle_json",
            label: this.toggleText,
            icon: this.toggleIcon,
            event: "toggle_json",
            param: "",
          },
          {
            id: "see_variables",
            label: "See Variables",
            icon: "mdi-variable-box",
            event: "show_variables",
            param: "",
          },
        ],
      ];
      debug(`editorActions`, result);
      return result;
    },
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    is_design_mode: {
      type: Boolean,
    },
    classes: {
      type: String,
    },
    context: {
      type: String,
    },
    definition: {
      type: Object,
    },
  },
  mounted() {
    this.pageURL =
      this?.$store?.state?.[this?.$context]?.context?.full_path ||
      createContext(this?.$route?.fullPath)?.params?.page;
  },
  methods: {
    cancel() {
      this.jsonDefinition = {
        type: "json_editor",
        value: clone(this.definition),
      };
      this.jsonUpdateKey++;
      this.closeJSONDialog();
    },
    closeJSONDialog() {
      this.jsonMode = !this.jsonMode;
    },
    updateDefinition() {
      this.$emit("update:definition", this.jsonDefinition.value);
      this.closeJSONDialog();
    },
    fieldAttributes(/* hover */) {
      let classes = ``;
      if (this.is_design_mode) {
        classes += ` designMode`;
        /* if(hover) {
          classes += ' elevation-2 '
        } */
      }
      return classes;
    },
  },
};
</script>
