import {
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  border_color,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "File",
  type: "file",
  description: "Displays a File control",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    {
      key: "mode",
      mandatory: false,
      description: "Controls how this component is displayed",
      affects_display: "yes",
      values: [
        {
          label: "Input",
          value: "input",
          hint: "Content will be editable by the user",
        },
        {
          label: "Embed",
          value: "embed",
          hint: "The file will be embedded on screen",
        },
        {
          label: "Icon",
          value: "icon",
          hint: "The file will be displayed as an icon",
        },
      ],
    },
    "display",
    "block",
  ],
  templates: [
    {
      variables: {
        data: {
          undefined_file: null,
        },
      },
      id: "embeded_file",
      label: "Embed_File",
      description: "Embed file",
      value: {
        label: "File",
        type: "file",
        mode: "embed",
        name: "undefined_file",
        display: {
          embed: {
            width: "600px",
            height: "400px",
          },
        },
      },
    },
    {
      id: "default",
      label: "Default",
      description: "The default file field.",
      value: {
        label: "File",
        type: "file",
        name: "file",
        fields: ["name", "description"],
      },
    },
    {
      id: "drag_and_drop",
      label: "Drag and Drop",
      description: "Files can be dragged and droped",
      variables: {
        data: {
          drag_drop_file: {
            id: "dummy.pdf",
            type: ".pdf",
            _file:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
        },
      },
      value: {
        label: "File",
        type: "file",
        name: "drag_drop_file",
        display: {
          attributes: {
            control: "drag_and_drop",
          },
        },
      },
    },
    {
      id: "embed_video",
      label: "Embed Video",
      keyword: ["tutorial"],
      description:
        'Use <code>mode: "embed"</code> to display an video (.mp4) in the application.',
      variables: {
        data: {
          video_file: {
            type: ".mp4",
            _file: "/sample_media/video1.mp4",
          },
        },
      },
      value: {
        label: "File",
        name: "video_file",
        type: "file",
        display: {
          embed: {
            width: "600px",
            height: "400px",
          },
        },
        mode: "embed",
      },
    },
    {
      id: "embed_pdf",
      label: "Embed PDF",
      keyword: ["tutorial"],
      description:
        'Use <code>mode: "embed"</code> to display the PDF in the application.',
      variables: {
        data: {
          embed_pdf: {
            name: "Lorem ipsum",
            description: "Dolore minim exercitation occaecat occaecat.",
            filename: "dummy.pdf",
            type: ".pdf",
            _file:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
        },
      },
      value: {
        label: "File",
        name: "embed_pdf",
        type: "file",

        mode: "embed",
      },
    },
    {
      id: "embed_image",
      label: "Embed Image",
      keyword: ["tutorial"],
      description:
        'Use <code>mode: "embed"</code> to display the image on the application.',
      variables: {
        data: {
          embed_image: {
            name: "Lorem ipsum",
            description:
              "Anim magna minim in anim ullamco laborum velit irure ipsum.",
            filename: "The_Wizard_of_Oz_Judy_Garland_1939.jpg",
            type: ".jpg",
            _file:
              "https://upload.wikimedia.org/wikipedia/commons/9/98/The_Wizard_of_Oz_Judy_Garland_1939.jpg",
          },
        },
      },
      value: {
        label: "File",
        type: "file",
        name: "embed_image",
        display: {
          embed: {
            width: "120",
          },
        },
        mode: "embed",
      },
    },
    {
      id: "icon_pdf",
      label: "PDF Icon",
      keyword: ["tutorial"],
      description:
        'Use <code>mode: "icon"</code> to display the PDF link along with its icon.',
      variables: {
        data: {
          icon_pdf: {
            name: "Lorem ipsum",
            description:
              "Nulla adipisicing dolor elit ut laboris laboris amet ut dolor.",
            filename: "dummy.pdf",
            type: ".pdf",
            _file:
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          },
        },
      },
      value: {
        label: "File",
        type: "file",
        name: "icon_pdf",

        mode: "icon",
      },
    },
    {
      id: "icon_image",
      label: "Image Icon",
      keyword: ["tutorial"],
      description:
        'Use <code>mode: "icon"</code> to display the image link along with its icon.',
      variables: {
        data: {
          icon_image: {
            name: "Lorem ipsum",
            description:
              "Est ex ut sit non ullamco veniam commodo enim nostrud excepteur occaecat aute qui sunt.",
            filename: "The_Wizard_of_Oz_Judy_Garland_1939.jpg",
            type: ".jpg",
            _file:
              "https://upload.wikimedia.org/wikipedia/commons/9/98/The_Wizard_of_Oz_Judy_Garland_1939.jpg",
          },
        },
      },
      value: {
        label: "File",
        type: "file",

        mode: "icon",
        name: "icon_image",
      },
    },
    {
      id: "word_doc",
      label: "Word doc",
      keyword: ["tutorial"],
      description: "This example displays a word document.",
      variables: {
        data: {
          word_doc: {
            name: "Lorem ipsum",
            description:
              "Est ex ut sit non ullamco veniam commodo enim nostrud excepteur occaecat aute qui sunt.",
            filename: "The_Wizard_of_Oz_Judy_Garland_1939.jpg",
            type: ".docx",
            _file:
              "http://ec2-3-109-59-89.ap-south-1.compute.amazonaws.com:8091/media/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIyMDIxMDgxNzA3MjQyMzc0NjczOCIsImlkIjoxMSwicGFyYW1ldGVycyI6eyJfbGluayI6Ii9yb290L3NlcnZlcmhvbWUvZGF0YS9fQ1VTVE9NXy9kZW1vL3Byb2R1Y3Rpb24vYXBwbGljYXRpb25zL3JlY3J1aXRtZW50X2FwcC5jYW5kaWRhdGVzLzIwMjEwODAzMDY0MjIzMjA1OTIzLmRvY3VtZW50cy8yMDIxMDgxNzA3MjMxMDM4MjA4OC5fbWVkaWEvRVVSVFJFRSBBQ1RJVklUWSBGTE9XIC0gS0QuZG9jeCIsIl90b2tlbiI6bnVsbH19.jzTxsSPrna4yBv5ecQBxpBikkl8gRz2IqVngjNtTx9s",
          },
        },
      },
      value: {
        label: "File",
        name: "word_doc",
        type: "file",

        mode: "embed",
      },
    },
    {
      id: "button_like",
      label: "File as Button",
      keyword: ["tutorial"],
      description: "This example displays a button",
      variables: {
        data: {
          button_like: {
            name: "Lorem ipsum",
            description:
              "Est ex ut sit non ullamco veniam commodo enim nostrud excepteur occaecat aute qui sunt.",
            filename: "The_Wizard_of_Oz_Judy_Garland_1939.jpg",
            type: ".docx",
            _file:
              "http://ec2-3-109-59-89.ap-south-1.compute.amazonaws.com:8091/media/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIyMDIxMDgxNzA3MjQyMzc0NjczOCIsImlkIjoxMSwicGFyYW1ldGVycyI6eyJfbGluayI6Ii9yb290L3NlcnZlcmhvbWUvZGF0YS9fQ1VTVE9NXy9kZW1vL3Byb2R1Y3Rpb24vYXBwbGljYXRpb25zL3JlY3J1aXRtZW50X2FwcC5jYW5kaWRhdGVzLzIwMjEwODAzMDY0MjIzMjA1OTIzLmRvY3VtZW50cy8yMDIxMDgxNzA3MjMxMDM4MjA4OC5fbWVkaWEvRVVSVFJFRSBBQ1RJVklUWSBGTE9XIC0gS0QuZG9jeCIsIl90b2tlbiI6bnVsbH19.jzTxsSPrna4yBv5ecQBxpBikkl8gRz2IqVngjNtTx9s",
          },
        },
      },
      value: {
        label: "File",
        name: "button_like",
        type: "file",
        display: {
          attributes: {
            button_label: "Download",
          },
        },
        mode: "button",
      },
    },
    {
      id: "drag_and_drop_multiple",
      label: "Drag and Drop Multiple",
      description: "Multiple Files can be dragged and droped",
      variables: {
        data: {
          drag_drop_file: [
            {
              id: "dummy.pdf",
              type: ".pdf",
              _file:
                "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            },
          ],
        },
      },
      value: {
        label: "File",
        type: "file",
        name: "drag_drop_file",
        display: {
          attributes: {
            multiple: true,
            control: "drag_and_drop",
          },
        },
      },
    },
    {
      id: "upload_button",
      label: "Upload Button",
      description:
        "For preference based icon use <code>icon</code> in Drag and Drop File upload field.",
      variables: {
        data: {
          upload_button: [
            {
              id: "dummy.pdf",
              type: ".pdf",
              _file:
                "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            },
          ],
        },
      },
      value: {
        label: "Photo/Video",
        type: "file",
        name: "upload_button",
        display: {
          icon: "mdi-video",
          attributes: {
            multiple: true,
            control: "drag_and_drop",
            button_type: "upload",
          },
        },
      },
    },
    {
      id: "file_source",
      label: "File with source",
      description: "File field with source input",
      variables: {
        data: {
          file_source: null,
          file_source_url: "dummyFiles",
        },
      },
      value: {
        label: "File",
        type: "file",
        name: "file_source",
        display: {
          attributes: {
            source: {
              url: "{_data->file_source_url}",
              field_name: "my_file",
            },
          },
        },
      },
    },
    {
      id: "file_format",
      label: "Restricted Format",
      description: "Restrict file formats",
      variables: {
        data: {
          file_format: null,
        },
      },
      value: {
        label: "Photo/Video",
        type: "file",
        name: "file_format",
        display: {
          icon: "mdi-video",
          attributes: {
            formats: ["video/*", "image/*"],
          },
        },
      },
    },
    {
      id: "icon_button",
      label: "Button/Icon",
      description: "Display upload as button or icon",
      variables: {
        data: {
          icon_button: null,
        },
      },
      value: {
        label: "Upload",
        type: "file",
        name: "icon_button",
        display: {
          attributes: {
            control: "icon_button",
            icon_button: {
              label: "Upload",
              "append-icon": {
                value: "mdi-upload",
                right: true,
              },
            },
          },
        },
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
    border_color,
  ],
};
