<template>
  <div>
    <AddMenuDialog
      v-if="showAddMenuDialog"
      :url="absoluteURL(pageURL)"
      :title="menuTitle"
      :label="pageLabel"
      @close="handleAddMenuDialogClosure"
    />
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails"
      :page="flowDetails"
      :context="currentContext"
      :nonDesignActions="nonDesignActions"
    />
  </div>
</template>
<script>
import { fetchTemplates } from "@/util.js";
import AddMenuDialog from "@/components/editor/AddMenuDialog";
import {
  absoluteURL,
  navigateTo,
  createContext,
  registerToEvents,
} from "@/util";
import { getApplicationDetails, isHomePage } from "@/components/editor/util.js";
import Page from "@/components/Page/Page";
import permissionMixin from "@/views/permission_mixin.js";

const debug = require("debug")("atman.components.editor_page"); // eslint-disable-line 


const PAGE_CREATED_EVENT = "_page_created";
export default {
  name: "EditorPage",
  mixins: [permissionMixin],
  components: {
    Page,
    AddMenuDialog,
  },
  data() {
    return {
      menuTitle: "",
      currentContext: null,
      flowDetails: null,
      operation: null,
      showAddMenuDialog: false,
      pageLabel: "",
      nonDesignActions: [
        {
          id: "show-page",
          label: "Toggle Editor",
          icon: "mdi-book-edit",
          event: "show-page",
        },
      ],
    };
  },
  created() {
    this.absoluteURL = absoluteURL;
  },
  mounted() {
    debug(`In mounted of EditorPage`);
    this.createFormPage();
    this.subscribeToEvents();
  },
  methods: {
    handleAddMenuDialogClosure() {
      navigateTo(this.$router, this.pageURL, { bypass: true });
    },
    subscribeToEvents() {
      const component = this;
      const unsubscribe = registerToEvents({
        $store: component.$store,
        events: [
          {
            name: PAGE_CREATED_EVENT,
          },
        ],
        callBackFn: (triggeredEvent, payload) => {
          debug(triggeredEvent, payload);
          component.pageCreated(payload?.value?.page || {});
        },
      });
      component._subscribe(unsubscribe);
    },
    async pageCreated(page) {
      const component = this;
      component.menuTitle = page.title || page.content?.[0]?.title || "";
      if (!component.operation) {
        this.displaySuccessMessage(`Page Updated`);
        debug(`Page Updated`);
        this.$store.dispatch(`triggerNavigation`, { url: "BACK" });
        return;
      }
      // If a form is being added, return to the list page
      if (this.pageURL.indexOf("/public/forms/") == 0) {
        this.displaySuccessMessage(`Form Created.`);
        this.$router.push("/forms");
        return;
      }
      this.displaySuccessMessage(`Page Created. Would you like to add a menu?`);
      if (!isHomePage(this.pageURL)) {
        this.showAddMenuDialog = true;
        return;
      }
      const application = await getApplicationDetails(this.pageURL);
      this.pageLabel = application?.name;
      this.showAddMenuDialog = true;
    },
    async createFormPage() {
      const pageURL = (this.pageURL = decodeURIComponent(
        this.$route.query.page
      ));
      const operation = (this.operation = this.$route.query.operation);

      this.currentContext = createContext(this.$route.fullPath);
      const templates = await fetchTemplates("page");
      let pageTemplate = "";
      const pageAssignment = "page=";
      if (operation && pageURL.indexOf(pageAssignment) != -1) {
        const pageInURL = pageURL.substring(
          pageURL.indexOf(pageAssignment) + pageAssignment.length
        );
        debug(`In EditorPage. pageURL is ${pageInURL}`);
        const template = templates.find(({ id }) => id == pageInURL);
        if (template) {
          debug(`In EditorPage. Found template`, template);
          pageTemplate = pageInURL;
        } else {
          debug(`In EditorPage. Did not find template`);
          pageTemplate = "empty";
        }
      }

      const submitParams = {};
      if (!operation) {
        submitParams.action = "update_fields";
      }

      const flowDetails = {
        id: "page_editor",
        title: "",
        subtitle: "",
        content: [
          {
            type: "form",
            apis: {
              submit: {
                icon: "mdi-content-save",
                url: pageURL,
                params: submitParams,
                label: operation ? "Create Page" : "Update Page",
                type: "post",
                data: "_page",
                success: {
                  action: {
                    type: "event",
                    name: PAGE_CREATED_EVENT,
                    params: {
                      page: "{_data->_page}",
                    },
                  },
                },
              },
            },
            definition: {
              fields: [
                {
                  name: "_page",
                  label: "Page Editor",
                  type: "page_editor",
                  template: pageTemplate,
                  url: pageURL,
                  display: {
                    width: "full",
                  },
                  apis: !operation && {
                    data: {
                      url: pageURL,
                      type: "get",
                    },
                  },
                },
              ],
              actions: [],
            },
          },
        ],
      };
      debug("flowDetails", flowDetails);
      this.flowDetails = flowDetails;
    },
  },
};
</script>
