<template>
  <div class="md-2 pd-2" aut-json-editor>
    <div v-if="isExplicitMode">
      <p>JSON Editor</p>
      <p class="text-caption font-italic red--text">
        Remember to click the button: Save Changes when done
      </p>
    </div>
    <div v-if="label" class="text-left">{{ label }}:</div>
    <div class="relative">
      <EditorActions
        class="pa-2"
        :actions="actions"
        @format_content="setValue(true)"
        @clear_content="clearContent"
      />
      <div
        class="json-editor pa-2"
        v-bind="value.display && value.display.attributes"
        v-bind:class="value.display && value.display.classes"
      >
        <textarea ref="textarea" aut-field-value />
      </div>
      <v-btn
        block
        elevation="3"
        color="orange"
        :disabled="hasErrors"
        v-if="isExplicitMode && !isDisabled"
        @click="$emit('update', jsonContent)"
        >Save Changes</v-btn
      >
    </div>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import CodeMirror from "codemirror";
// import 'codemirror/addon/lint/lint.css'
import "codemirror/lib/codemirror.css";
import "codemirror/theme/rubyblue.css";
// require('script-loader!jsonlint')
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/json-lint";
import EditorActions from "@/components/editor/EditorActions";

const debug = require("debug")("atman.components.json_editor");
debug(`json_editor`);
export default {
  name: "JSONEditor",
  mixins: [fieldMixin],
  data() {
    return {
      hasErrors: false,
      jsonEditor: false,
      jsonContent: {},
      jsonCursor: null,
    };
  },
  components: {
    EditorActions,
  },
  computed: {
    displayContent() {
      return JSON.stringify(this.fieldValue, null, 4).replace(
        /\s{4}/g,
        "<br/>"
      );
    },
    actions() {
      const results = [];
      results.push({
        id: "json_content_indicator",
        label: this.hasErrors ? "Has Errors" : "No Errors",
        icon: this.hasErrors ? "mdi-alert" : "mdi-check",
        classes: `mr-2 ${this.hasErrors ? "red--text" : "green--text"}`,
      });
      results.push({
        id: "clear_content",
        label: "Clear Content",
        icon: "mdi-cancel",
        event: "clear_content",
      });
      if (!this.hasErrors) {
        results.push({
          id: "format_content",
          label: "Format Content",
          icon: "mdi-format-align-left",
          event: "format_content",
        });
      }

      return results;
    },
  },
  mounted() {
    debug("in mounted of JSON editor", this.value);
    this.initialiseEditor();
  },
  methods: {
    _afterPropertyUpdate() {
      const codeMirrorInstance = this.$el.querySelector(".CodeMirror");
      if (codeMirrorInstance) {
        codeMirrorInstance.parentElement.removeChild(codeMirrorInstance);
      }
      this.jsonEditor = false;
      this.$nextTick(() => {
        this.initialiseEditor();
      });
    },
    initialiseEditor() {
      const codeMirrorConfiguration = {
        // TODO temporarily setting as false to avoid the numbers being displayed even when the sidebar is hovered over.
        // NOTE: also see the style section and display the gutters
        lineNumbers: false,
        tabSize: 4,
        autoRefresh: true,
        readOnly:
          this.isDisabled ||
          (this.isInputMode || this.isExplicitMode ? false : "nocursor"),
        mode: "text/javascript",
        // theme: 'base16-dark',
        line: true,
        // mode: 'application/json',
        gutters: ["CodeMirror-lint-markers"],
        theme: "rubyblue",
        //   lint: true
      };
      debug(`codeMirrorConfiguration: `, codeMirrorConfiguration);
      this.jsonEditor = CodeMirror.fromTextArea(
        this.$refs.textarea,
        codeMirrorConfiguration
      );
      this.setValue();
      this.jsonEditor.focus();
      this.jsonEditor.setCursor({ line: 0, ch: 0 });
      this.jsonEditor.on("change", (cm) => {
        this.updateValue(cm);
      });
    },
    _afterValueUpdate() {
      if (typeof this.fieldValue != "undefined") {
        this.setValue();
      }
    },
    updateValue(cm) {
      const component = this;
      component.hasErrors = false;
      const contentInTextArea = cm.getValue();
      if (!contentInTextArea) {
        return;
      }
      debug("updating in json editor");
      let parsedContent = "";
      try {
        parsedContent = JSON.parse(contentInTextArea);
        this.$set(component, "jsonContent", parsedContent);
        if (component.isInputMode) {
          component.triggerEvent();
        }
      } catch (e) {
        debug(`JSON couldn't be parsed`);
        component.hasErrors = true;
        this.$emit("error");
      }
    },
    triggerEvent() {
      this.$set(this, "fieldValue", this.jsonContent);
      this.$emit("update", this.fieldValue);
    },
    getValue() {
      return JSON.parse(this.jsonEditor.getValue());
    },
    clearContent() {
      this.jsonEditor.setValue("");
    },
    setValue(force = false) {
      if (!this?.jsonEditor || !this.fieldValue) {
        return;
      }
      this.jsonContent = this.fieldValue;
      const stringifiedVersion = JSON.stringify(this.fieldValue);
      const valueInEditor = this.jsonEditor.getValue();
      if (force) {
        if (this.isExplicitMode) {
          this.jsonEditor.setValue(
            JSON.stringify(JSON.parse(valueInEditor), null, 2)
          );
        } else {
          this.jsonEditor.setValue(JSON.stringify(this.fieldValue, null, 2));
        }
      } else if (
        !valueInEditor ||
        stringifiedVersion != JSON.stringify(JSON.parse(valueInEditor))
      ) {
        this.jsonEditor.setValue(JSON.stringify(this.fieldValue, null, 2));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.json-editor {
  height: 100%;
  position: relative;
  ::v-deep {
    .CodeMirror {
      height: auto;
      min-height: 300px;
    }
    .CodeMirror-scroll {
      min-height: 300px;
    }
    .cm-s-rubyblue span.cm-string {
      color: #f08047;
    }
    pre {
      display: flex;
      span {
        text-align: left;
      }
    }
  }
}
</style>
<style>
.json-editor {
  max-height: 50vh;
  overflow-y: scroll;
}
/* See line numbers */
.CodeMirror-gutters {
  display: none;
}
</style>
