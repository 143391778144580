<template>
  <div :class="`${dynamicClasses}`" v-if="!isIgnored">
    <div v-if="isDisplayMode">
      <v-expansion-panels
        v-if="isPanelMode"
        v-model="panelModel"
        aut-dropdown-panel
        class="py-1"
        :class="dynamicClasses"
      >
        <v-expansion-panel :key="index" :ref="`panel_${value.name}`">
          <v-expansion-panel-header>
            <span
              :aut-panel-label="value.label"
              class="font-weight-bold primary--text"
              >{{ value.label }}</span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul aut-field-value>
              <li
                v-for="(item, i) in displayItems"
                :key="i"
                aut-select-list-item
              >
                {{ item }}
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <FieldLabel
        v-else
        mode="display"
        class="text-left"
        :label="label"
        :definition="value"
        :attributes="labelAttributes"
      >
        <FieldDisplayValue :value="displayValue" :definition="value" />
      </FieldLabel>
    </div>
    <div v-else aut-select-input class="behavior_dropdown_container">
      <div v-if="displaySlider">
        <label v-if="label">{{ label }}</label>
        <v-slider
          :max="seedData.length - 1"
          v-model="optionIndex"
          :key="renderKey"
        >
          <template v-slot:append>
            {{ sliderValue }}
          </template>
        </v-slider>
      </div>
      <div v-else :class="displayClasses">
        <DropdownSelect
          v-if="displaySelect"
          v-model="effectiveValue"
          :rules="rules"
          :items="seedData"
          :definition="value"
          :label="label"
          :attributes="displayAttributes"
          :classes="displayClasses"
          @update="handleUpdate"
          @show_detailed_view="detailedView = true"
          @click:append="$emit('trigger-action', $event)"
        />
        <DropdownCombobox
          v-if="displayCombobox"
          v-model="effectiveValue"
          :rules="rules"
          :items="seedData"
          :definition="value"
          :label="label"
          :attributes="displayAttributes"
          :classes="displayClasses"
          @update="handleUpdate"
          @addSeedData="addSeedData"
        />
      </div>
    </div>
    <DetailedView
      v-if="showDetail"
      :dialog.sync="detailedView"
      :items="value.seedData"
      :multiple="value.multiple"
      v-model="effectiveValue"
    />
  </div>
</template>

<script>
import { rules } from "@/util.js";
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import DropdownSelect from "./DropdownSelect.vue";
import DropdownCombobox from "./DropdownCombobox.vue";
import DetailedView from "./DetailedView.vue";

const debug = require("debug")("atman.components.dropdown");
debug("dropdown");
export default {
  name: "Dropdown",
  mixins: [fieldMixin],
  components: {
    DetailedView,
    DropdownSelect,
    DropdownCombobox,
  },
  data() {
    return {
      definition,
      renderKey: 1,
      detailedView: false,
      displayAsRawHTML: false,
      displayValue: "",
      displayItems: [],
      panelModel: null,
    };
  },
  watch: {
    fieldValue() {
      this.determinDisplayValue();
    },
    seedData() {
      this.determinDisplayValue();
    },
  },
  computed: {
    isPanelMode() {
      const layout = this.displayAttributes?.layout;
      return layout == "open_panel" || layout == "closed_panel";
    },
    rules() {
      if (this.value.mandatory) {
        return [...[rules.mandatory({ debugKey: this.value.name })]];
      }
      return [];
    },
    effectiveValue: {
      get() {
        const methodDebug = debug.extend("effectiveValue");
        let result;
        if (!this.value.multiple) {
          result = this.fieldValue && `${this.fieldValue}`;
        } else {
          if (Array.isArray(this.fieldValue)) {
            result = this.fieldValue;
          } else {
            result = (this.fieldValue ? [this.fieldValue] : []).filter(
              (value) => !!value
            );
          }
        }
        methodDebug(`effectiveValue`, result, typeof result);
        return result;
      },
      set(value) {
        this.fieldValue = value;
      },
    },
    displaySelect() {
      if (this.isDisplayMode) {
        return false;
      }
      return !this?.value?.options?.apis?.submit && !this.isOtherSupported;
    },
    displayCombobox() {
      if (this.isDisplayMode) {
        return false;
      }
      return this.isOtherSupported || !!this?.value?.options?.apis?.submit;
    },
    displaySlider() {
      if (this.isDisplayMode) {
        return false;
      }
      return this?.displayAttributes?.slider || this?.value?.display?.slider;
    },
    isOtherSupported() {
      return !!this.displayAttributes?.other;
    },

    sliderValue() {
      const item = this.seedData.find(({ id }) => id == this.fieldValue);
      if (item && item.name) {
        return item.name;
      }
      return "";
    },
    optionIndex: {
      get() {
        return this.seedData.findIndex(({ id }) => id == this.fieldValue) || 0;
      },
      set(index) {
        this.fieldValue = this.seedData[index].id;
      },
    },
    showDetail() {
      const fields = this.displayAttributes?.detail?.fields;
      return Array.isArray(fields);
    },
  },
  mounted() {
    debug(
      `In mounted of Dropdown: [${this.label}-${this.value.name}]`,
      this.value,
      this.displayAttributes
    );
    this.determinDisplayValue();
    const layout = this.displayAttributes?.layout;
    if (layout == "open_panel") {
      this.panelModel = 0;
    }
  },
  methods: {
    determinDisplayValue() {
      const component = this;
      const value = component.value;

      if (typeof component.fieldValue == "undefined") {
        this.displayValue = "";
        return;
      }
      /* Sometimes when submitting a file with a single element, the form submission contains a string so the value is stored as a string  */
      if (value.multiple && !Array.isArray(component.fieldValue)) {
        component.fieldValue = [component.fieldValue];
      }

      if (!component.seedData) {
        this.displayValue = "";
        return;
      }
      //Single select
      if (!value.multiple) {
        const selectedItem = component.seedData.find(({ id }) => {
          return `${id}` == `${component.fieldValue}`;
        });
        // IF no matched item is found, this is an OTHER value
        if (!selectedItem) {
          this.displayValue = component.fieldValue;
          return;
        }
        this.displayValue = selectedItem.name;
        return;
      }

      // Multiple Select
      const items = (component.fieldValue || []).map((item) => {
        const id = typeof item == "string" ? item : item?.id;
        const inListItem = component.seedData.find(
          (seedItem) => seedItem.id == id
        );
        return inListItem ? inListItem.name : id;
      });
      this.displayItems = items.filter((item) => !!item);
      this.displayValue = items.join(", ");
    },
    handleUpdate(value) {
      if (!value) {
        value = "";
      }
      this.$set(this, "fieldValue", value);
      debug(`fieldValue`, this.fieldValue);
    },
  },
};
</script>
