<template>
  <div class="slots inputs">
    <div
      class="input slot"
      v-for="(slot, index) in inputs"
      :key="`${index}-input`"
    >
      <div
        class="circle inputSlot"
        :class="{ active: slot.active }"
        @mouseup="slotMouseUp($event, index)"
        @mousedown="slotBreak($event, index)"
      ></div>
      <!-- <span :class="`${display.text_color}--text`">{{ slot.label }}</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "InputSlots",
  props: {
    inputs: {
      type: Array,
    },
    display: {
      type: Object,
    },
  },
  methods: {
    // Slots
    slotMouseUp(e, index) {
      this.$emit("linking-stop", index);
      if (e.preventDefault) e.preventDefault();
    },
    slotBreak(e, index) {
      this.$emit("linking-break", index);
      if (e.preventDefault) e.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style.scss";
</style>
