export default {
  label: "Video",
  type: "video",
  description: "Displays a video field",
  properties: ["name", "label", "type", "mode", "display", "block"],
  templates: [
    {
      id: "default",
      description: "Video Field",
      label: "Default",
      variables: {
        data: {
          default: {
            type: ".mp4",
            _file: "/sample_media/video1.mp4",
          },
        },
      },
      value: {
        name: "default",
        label: "Video",
        type: "video",
        display: {
          embed: {
            width: 500,
            height: 350,
          },
        },
      },
    },
    {
      id: "input",
      description: "Input",
      label: "Input",
      variables: {
        data: {
          input: null,
        },
      },
      value: {
        name: "input",
        label: "Video",
        type: "video",
        display: {
          embed: {
            width: 500,
            height: 350,
          },
        },
      },
    },
  ],
};
