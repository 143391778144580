import {
  validate_on_blur,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  color,
  border_color,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Text Area",
  type: "textarea",
  description: "Displays a text area field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  searchField: (col, data = {}) => {
    let result = {
      mode: "input",
      name: col.name,
      label: col.label,
      type: "text",
      display: {
        classes: [
          "mt-7",
        ] /* Text fields are slightly out-of sync with the rest */,
        attributes: {
          clearable: true,
        },
      },
    };
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    return result;
  },
  templates: [
    {
      id: "default",
      description: "The default Text Area field.",
      label: "Default",
      value: {
        name: "text",
        label: "Text Area",
        type: "textarea",
      },
    },
    {
      id: "basic",
      keyword: ["tutorial"],
      description: "An example with data",
      label: "Basic",
      value: {
        name: "some_name",
        label: "Description",
        type: "textarea",
        value: "Labore anim quis ut excepteur consequat anim exercitation.",
      },
    },
    {
      id: "display",
      keyword: ["tutorial"],
      description: "An example with data",
      label: "Display",
      value: {
        name: "some_name",
        mode: "display",
        label: "Description",
        type: "textarea",
        value: "Labore anim quis ut excepteur consequat anim exercitation.",
      },
    },
  ],
  attributes: [
    validate_on_blur,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
    color,
    border_color,
    no_value_text,
    no_value_behavior,
    {
      name: "rows",
      type: "number",
      value: 5,
    },
    {
      name: "auto-grow",
      type: "switch",
      value: true,
    },
  ],
};
