<template>
  <v-container class="pa-0" v-if="effectiveEvents.length">
    <v-row v-if="label || isShortFormat">
      <v-col>
        <FieldLabel
          :definition="value"
          mode="display"
          :label="label"
          :attributes="labelAttributes"
        >
        </FieldLabel>
      </v-col>
      <template v-if="isShortFormat">
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            aut-more
            text
            v-bind="moreAttributes"
            @click="showExpanded = true"
          >
            more
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <Timeline
      :displayAttributes="displayAttributes"
      :classAttributes="displayClasses"
      :events="effectiveEvents"
    />
    <v-dialog v-model="showExpanded" max-width="800">
      <v-card class="pt-2" aut-events-expand>
        <DialogTitle @close="showExpanded = false" />
        <v-card-text>
          <Timeline
            :displayAttributes="expandAttributes"
            :classAttributes="displayClasses"
            :events="events"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { fieldMixin, dialogMixin } from "@/components/mixin.js";
import { isBefore } from "date-fns";
import definition from "./definition";

const debug = require("debug")("atman.components.events"); // eslint-disable-line
const dateCompare = (event1, event2) => {
  const start1 = new Date(event1.start);
  const start2 = new Date(event2.start);
  if (isBefore(start1, start2)) {
    return -1;
  }
  return 1;
};

export default {
  name: "Events",
  mixins: [fieldMixin, dialogMixin],
  components: {
    Timeline: () => import("./Timeline.vue"),
  },
  data() {
    return {
      definition: definition,
      showExpanded: false,
    };
  },
  computed: {
    effectiveEvents() {
      const events = this.events || [];
      if (
        this.maxCount == false ||
        this.maxCount > events.length ||
        this.maxCount < 0
      ) {
        return events;
      }

      return events.slice(0, this.maxCount);
    },
    events() {
      if (!Array.isArray(this.fieldValue)) {
        return [];
      }
      const events = [...this.fieldValue];
      events.sort(dateCompare);
      const result = events.map((event) => {
        const start = event?.start || "";
        const end = event?.end || "";
        const formatDate = this.$options.filters.date;
        const time = `${formatDate(start, { date_format: "hh:mmaaa" })}
        -
        ${formatDate(end, { date_format: "hh:mmaaa" })}
        \n
        ${formatDate(start, { date_format: "EEEE',' do MMM" })}`;

        const shortTime = `${formatDate(start, {
          date_format: "HH:mm",
        })}:${formatDate(end, { date_format: "HH:mm" })},${formatDate(start, {
          date_format: "do MMM",
        })}`;

        return Object.assign({}, event, { time, shortTime });
      });

      return result;
    },
    moreAttributes() {
      return this.displayAttributes?.more || {};
    },
    maxCount() {
      const max = this.displayAttributes?.max * 1;
      if (isNaN(max)) {
        return false;
      }

      return max;
    },
    isShortFormat() {
      return this.displayAttributes?.format == "short";
    },
    expandAttributes() {
      return Object.assign({}, this.displayAttributes, {
        format: "",
      });
    },
  },
};
</script>
