<template>
  <v-row dense>
    <v-col cols="12">
      <v-autocomplete
        aut-image-field-source
        clearable
        item-text="label"
        item-value="id"
        :items="sourceOptions"
        hint="Restrict image control to allow only"
        persistent-hint
        v-model="source"
        label="Source"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { clone } from "@/util";
const debug = require("debug")("atman.components.image_designer"); // eslint-disable-line camelcase

export default {
  name: "ImageDesigner",
  data() {
    return {
      source: this.definition.source,
      sourceOptions: [
        {
          id: "file",
          label: "File",
        },
        {
          id: "link",
          label: "Link",
        },
      ],
    };
  },
  props: {
    definition: {
      type: Object,
    },
  },
  watch: {
    source() {
      const definition = clone(this.definition);
      if (this.source) {
        definition.source = this.source;
      } else {
        delete definition.source;
      }
      this.updateDefinition(definition);
    },
  },
  methods: {
    updateDefinition(definition) {
      debug(`ImageDesigner updateDefinition`, definition);
      this.$emit("update:definition", definition);
    },
  },
};
</script>
