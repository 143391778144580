export default {
  label: "Processor Tutorial",
  type: "processor_tutorial",
  filters: ["form_editor", "list_editor", "field_tutorial"],
  description: "Examples of different processors",
  properties: [],
  templates: [
    {
      id: "processor_count",
      label: "Derived value [count]",
      value: {
        name: "label",
        label: "Label",
        type: "label",

        apis: {
          data: {
            url: "/users",
            type: "get",
            post_processors: [
              {
                count: true,
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_sum",
      label: "Derived value [sum]",
      value: {
        name: "label",
        label: "Label",
        type: "label",

        apis: {
          data: {
            url: "/users",
            type: "get",
            post_processors: [
              {
                sum: {
                  field: "amounts",
                },
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_field",
      label: "Derived value [field]",
      value: {
        name: "label",
        label: "Label",
        type: "label",

        apis: {
          data: {
            url: "/users",
            type: "get",
            post_processors: [
              {
                field: {
                  field: "[0]address.line1",
                },
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_random_value",
      label: "Derived value [Random Image]",
      value: {
        name: "label",
        label: "Random Image",
        type: "image",

        apis: {
          data: {
            url: "/get_posts",
            type: "get",
            post_processors: [
              {
                random_id: true,
              },
              {
                field: {
                  field: "image",
                },
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_custom_fn",
      label: "Derived value [Custom Function]",
      value: {
        name: "label",
        label: "Label",
        type: "label",

        apis: {
          data: {
            url: "/users",
            type: "get",
            post_processors: [
              {
                my_function:
                  "return `${data[0].first_name}-${data[0].last_name}`",
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_omit",
      label: "Derived value [omit]",
      description:
        "The 'omit' processor can be used to remove some fields from a response",
      value: {
        name: "user",
        label: "User",
        type: "label",

        apis: {
          data: {
            url: "/users/0",
            type: "get",
            post_processors: [
              {
                omit: {
                  fields: ["id"],
                },
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_to_array",
      label: "Derived value [to_array]",
      description:
        "The 'to_array' processor can be used to convert a response to an array",
      value: {
        name: "processor_to_array",
        label: "User",
        type: "label",

        apis: {
          data: {
            url: "/users/0",
            type: "get",
            post_processors: [
              {
                to_array: true,
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_keys_to_array",
      label: "Derived value [keys_to_array]",
      description:
        "The 'keys_to_array' processor can be used to convert a response to an array",
      value: {
        name: "processor_keys_to_array",
        label: "User",
        type: "label",

        apis: {
          data: {
            url: "/developer/feature_demos/user_collection",
            type: "get",
            post_processors: [
              {
                keys_to_array: true,
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_filter",
      label: "Derived value [filter]",
      value: {
        name: "user",
        label: "User",
        value: "1",
        type: "select",
        multiple: false,
        options: {
          dynamic_label: "{first_name} {last_name}",
          apis: {
            data: {
              url: "users",
              type: "get",
              post_processors: [
                {
                  filter: {
                    filter: "return data.age > 60",
                  },
                },
              ],
            },
          },
        },
      },
    },
    {
      id: "processor_greater_than",
      label: "Derived value [greater_than]",
      value: {
        name: "label",
        label: "Label",
        type: "switch",

        apis: {
          data: {
            url: "/users",
            type: "get",
            post_processors: [
              {
                count: true,
              },
              {
                greater_than: {
                  count: 0,
                },
              },
            ],
          },
        },
      },
    },
    {
      id: "processor_based_on_other_fields",
      label: "Deriving value from other fields - 1",
      variables: {
        data: {
          first_name: "Kate",
          last_name: "Griffith",
        },
      },
      value: {
        name: "label",
        label: "Label",
        type: "label",

        conditions: [
          {
            rule: "true == true",
            value: "{_data}",
            post_processors: [
              {
                my_function: "return `${data.first_name}-${data.last_name}`",
              },
            ],
          },
        ],
      },
    },
    {
      id: "processor_based_on_other_fields_2",
      label: "Deriving value from other fields - 2",
      variables: {
        data: {
          cost: 20,
          tax: 10,
        },
      },
      value: {
        name: "label",
        label: "Label",
        type: "label",

        conditions: [
          {
            rule: "true == true",
            value: "{_data}",
            post_processors: [
              {
                my_function: "return data.cost + ((data.tax/100)*data.cost)",
              },
            ],
          },
        ],
      },
    },
  ],
};
