<template>
  <div class="bannerContainer dark">
    <div v-if="isUnAuthenticated" class="bannerDiv grey darken-3 rounded-b-xl">
      <p
        class="text-h6 text-md-h5 text-lg-h4 text-capitalize text-center font-weight-thin"
      >
        Eblisher. Digital Books For the Whole World.
      </p>
    </div>
    <div v-else class="d-flex bannerDiv">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="`tabItem ${tabStyle(
          i,
          tab.key
        )} d-flex flex-column flex-grow-0 justify-space-around`"
        v-on:click.stop="filterByLevel(tab.key)"
      >
        <v-spacer />
        <p :class="`${tabContentStyle(tab.key)}`">
          {{ tab.label }}
        </p>
        <v-spacer />
      </div>
    </div>
  </div>
</template>

<script>
import { constructURL, isUnAuthenticated } from "@/util.js";

const debug = require("debug")("atman.components.eblisher_header");

export default {
  name: "EblisherHeader",
  data: () => {
    return {
      level: null,
      tabs: [
        { key: "early", label: "Early Readers" },
        { key: "middle", label: "Middle Readers" },
        { key: "advanced", label: "Advanced Readers" },
        { key: "adult", label: "Adult Readers" },
      ],
    };
  },
  computed: {
    bookFilter() {
      const filterString = this?.$route?.query?.filter;
      let filter = "";

      try {
        filter = JSON.parse(filterString);
      } catch {
        filter = "";
      }

      return filter;
    },
  },
  watch: {},
  created() {
    this.isUnAuthenticated = isUnAuthenticated(this);
  },
  methods: {
    tabStyle: function (index, key) {
      debug(index);
      let classlist = "flex-shrink-1 flex-grow-1 rounded-b-xl  mr-n9 grey";
      classlist += `${classlist} darken-${this.tabs.length - index}`;
      classlist += this.bookFilter.level == key ? " " : " behavior_clickable";
      return classlist;
    },
    tabContentStyle: function (inputLevel) {
      let classList =
        "text-caption text-sm-body-2 text-lg-h6 text-capitalize text-center mr-md-4 px-5 px-md-0 ml-lg-n8 pt-3";
      if (this.$vuetify.breakpoint.lgAndUp) {
        classList += " align-self-center";
      }
      classList +=
        this.bookFilter.level != inputLevel ? " font-weight-thin " : " ";
      return classList;
    },

    filterByLevel: function (level) {
      if (this.bookFilter.level == level) {
        return;
      }
      const url = constructURL({
        route: "/applications/eblisher?page=catalogue",
        queryParams: this.$route.query,
        newParams: { filter: { level } },
        mode: "reset",
      });
      debug(`filterByLevel`, url);
      this.$router.replace(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.bannerContainer {
  height: var(--header-height, 100%);
  .bannerDiv {
    height: 100%;
    .tabItem {
      p {
        position: relative;
        width: 60%;
      }
    }
  }
}
</style>
