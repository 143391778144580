<template>
  <v-btn
    v-if="mode == 'button'"
    :href="effectiveFileLink"
    target="_blank"
    :download="displayName"
  >
    {{ attributes && attributes.button_label }}
  </v-btn>
  <embedded-file
    v-else-if="mode == 'embed' && fieldValue"
    :fieldValue="fieldValue"
    :value="value"
  />

  <div v-else>
    <v-icon v-if="fileType" aut-file-icon>{{ fileType }}</v-icon>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <a
          aut-file-link
          v-bind="attrs"
          v-on="on"
          :href="effectiveFileLink"
          target="_blank"
          :download="displayName"
        >
          <span>{{ displayName }}</span>
        </a>
      </template>
      {{ displayName }}
    </v-tooltip>
  </div>
</template>
<script>
import EmbeddedFile from "./EmbeddedFile";

import fileMixin from "./mixin";
export default {
  name: "FileFieldDisplay",
  mixins: [fileMixin],
  components: {
    EmbeddedFile,
  },

  props: {
    value: {
      type: Object,
    },
    fieldValue: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  computed: {
    displayName() {
      return this.fieldValue?.id || this.value.label || this.value.name;
    },
    effectiveFileLink() {
      return this.fieldValue?._file || this.fieldValue?.link;
    },
  },
};
</script>
