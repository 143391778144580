<template>
  <div v-if="isEnabled">
    <v-icon>{{ icon }}</v-icon>
    <span v-html="content" class="font-italic text-caption"></span>
  </div>
</template>
<script>
export default {
  name: "TipText",
  props: {
    content: {},
    icon: {
      type: String,
      default: () => "mdi-information-outline",
    },
  },
  computed: {
    isEnabled() {
      return true;
      /* let result = this.isFeatureEnabled('questionaire_designer_tips_enabled');
      return result; */
    },
  },
};
</script>
