<template>
  <v-col cols="12" :lg="definition.width || '6'" :aut-feature="definition.name">
    <label class="text-body-1">{{ definition.label }}</label>
    <div class="d-flex flex-wrap" v-if="showSelect">
      <v-autocomplete
        dense
        outlined
        :aut-select="definition.name"
        :items="createOptionsObjects(definition.options)"
        :hint="typeof definition.hint == 'string' ? definition.hint : ''"
        persistent-hint
        item-value="id"
        item-text="label"
        v-model="selectValue"
        @blur="emitEvent"
      >
      </v-autocomplete>
    </div>
    <div class="d-flex flex-wrap" v-else-if="showToggle">
      <span class="mt-2" v-if="Array.isArray(definition.hint)">{{
        definition.hint[0]
      }}</span>
      <v-switch
        dense
        :aut-switch="definition.name"
        v-model="definition.value"
        @change="emitEvent"
        :hint="typeof definition.hint == 'string' ? definition.hint : ''"
        persistent-hint
        class="mt-0 px-2"
      ></v-switch>
      <span class="mt-2" v-if="Array.isArray(definition.hint)">{{
        definition.hint[1]
      }}</span>
    </div>
    <div class="d-flex flex-wrap" v-else-if="showTextArea">
      <v-textarea
        dense
        outlined
        :rows="2"
        auto-grow
        :hint="typeof definition.hint == 'string' ? definition.hint : ''"
        persistent-hint
        :aut-feature-text-value="definition.name"
        v-model="definition.value"
        v-on:keydown.enter.stop.prevent="emitEvent"
        @blur="emitEvent"
      />
    </div>
    <div class="d-flex flex-wrap" v-else-if="definition.control == 'color'">
      <v-combobox
        :aut-feature-value="definition.name"
        hide-selected
        clearable
        item-text="label"
        item-value="id"
        :items="colors"
        :hint="definition.hint"
        :persistent-hint="!!definition.hint"
        v-model="selectValue"
        v-on:keydown.enter.stop.prevent="emitEvent"
        @blur="emitEvent"
      >
      </v-combobox>
    </div>
    <div class="d-flex flex-wrap" v-else-if="definition.control == 'page'">
      <v-text-field
        :aut-feature-text-value="definition.name"
        v-model="definition.value"
        v-on:keydown.enter.stop.prevent="emitEvent"
        append-icon="mdi-navigation-variant"
        @click:append="navigateToPage(definition.value)"
        @blur="emitEvent"
        :hint="typeof definition.hint == 'string' ? definition.hint : ''"
        persistent-hint
      />
    </div>
    <div class="d-flex flex-wrap" v-else>
      <v-text-field
        dense
        outlined
        :aut-feature-text-value="definition.name"
        :hint="typeof definition.hint == 'string' ? definition.hint : ''"
        persistent-hint
        v-model="definition.value"
        v-on:keydown.enter.stop.prevent="emitEvent"
        @blur="emitEvent"
      />
    </div>
  </v-col>
</template>
<script>
import {
  TEXT_COLORS,
  COLORS,
} from "@/components/fields/Field/FieldSettings/util.js";
import { clone, createOptionsObjects } from "@/util";

const debug = require('debug')('atman.feature.switch')  // eslint-disable-line
export default {
  name: "FeatureSwitch",
  props: {
    feature: {
      type: Object,
    },
  },
  data() {
    return {
      definition: clone(this.feature),
    };
  },
  computed: {
    selectValue: {
      get() {
        return this.definition.value;
      },
      set(val) {
        this.definition.value = val?.id || val;
      },
    },
    showToggle() {
      return (
        this.definition.control == "toggle" ||
        typeof this.feature.value == "boolean"
      );
    },
    showSelect() {
      return this.definition.control == "select" || this.definition.options;
    },
    showTextArea() {
      return this.definition.control == "textarea";
    },
  },
  created() {
    this.colors = this.definition.constraint == "text" ? TEXT_COLORS : COLORS;
    this.createOptionsObjects = createOptionsObjects;
  },
  methods: {
    navigateToPage(path) {
      if (!path) {
        return;
      }
      this.$router.push({ path: path });
    },
    emitEvent() {
      this.$emit("update", this.definition);
    },
  },
};
</script>
