import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
const debug = require("debug")("atman.components.username"); // eslint-disable-line
export default {
  label: "UserName",
  type: "username",
  description: "Displays a User Name",
  properties: ["name", "label", "type", "display", "block", "mode", "value"],
  templates: [
    {
      id: "default",
      description: "Displays the username for a user",
      label: "Default",
      variables: {
        data: {
          default: "admin",
        },
      },
      value: {
        name: "default",
        type: "username",
      },
    },
    {
      id: "navigate_username",
      description: "Displays the username for a user",
      label: "Navigate",
      variables: {
        data: {
          navigate_username: "admin",
        },
      },
      value: {
        name: "navigate_username",
        type: "username",
        display: {
          attributes: {
            url: "users/{_user->id}?page=edit",
          },
        },
      },
    },
    {
      id: "display_formats",
      description: `Use <code>formats</code>, to set the order and format of display`,
      label: "Display Formats",
      variables: {
        data: {
          display_formats: "admin",
        },
      },
      value: {
        name: "display_formats",
        type: "username",
        display: {
          attributes: {
            formats: ["{last_name} {first_name}"],
          },
        },
      },
    },

    {
      id: "_created_by",
      keyword: ["skip_customisation"],
      label: "Created By",
      variables: {
        data: {
          _created_by: `admin`,
        },
      },
      value: {
        mode: "display",
        name: "_created_by",
        label: "Created By",
        type: "username",
      },
    },
    {
      id: "_updated_by",
      keyword: ["skip_customisation"],
      label: "Updated By",
      variables: {
        data: {
          _updated_by: `developer`,
        },
      },
      value: {
        mode: "display",
        name: "_updated_by",
        label: "Updated By",
        type: "username",
      },
    },
  ],
  attributes: [
    {
      name: "url",
      type: "text",
      hint: "Enter navigation URL",
      value: "",
    },
    no_value_text,
    no_value_behavior,
  ],
};
