<template>
  <v-row dense>
    <v-col cols="12">
      <FieldDefinition aut-field-definition type="rule" />
    </v-col>
  </v-row>
</template>

<script>
import FieldDefinition from "@/components/fields/Field/FieldDefinition";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.rule_tutorial");
debug("rule_tutorial");
export default {
  name: "RuleTutorial",
  mixins: [fieldMixin],
  components: {
    FieldDefinition,
  },
  data() {
    return {};
  },
};
</script>
