<template>
  <div :aut-attribute="control.name">
    <v-switch
      v-if="control.type == 'switch'"
      :label="control.name"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      v-model="controlValue"
      @change="$emit('change', controlValue)"
    ></v-switch>
    <v-combobox
      v-else-if="control.type == 'select'"
      hide-selected
      clearable
      item-text="label"
      item-value="id"
      :items="createOptionsObjects(control.options)"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      @change="$emit('change', controlValue && controlValue.id)"
      v-model="controlValue"
      :label="control.name"
    >
    </v-combobox>
    <v-combobox
      v-else-if="control.type == 'multi_select'"
      hide-selected
      clearable
      item-text="label"
      item-value="id"
      multiple
      :items="createOptionsObjects(control.options)"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      @change="$emit('change', controlValue && controlValue.id)"
      v-model="controlValue"
      :label="control.name"
    >
    </v-combobox>
    <v-combobox
      v-else-if="control.type == 'color'"
      hide-selected
      clearable
      item-text="label"
      item-value="id"
      :items="createOptionsObjects(colors)"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      @change="$emit('change', controlValue && controlValue.id)"
      v-model="controlValue"
      :label="control.name"
    >
    </v-combobox>
    <v-text-field
      v-else-if="control.type == 'number'"
      clearable
      v-model="controlValue"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      @change="$emit('change', controlValue)"
      :label="control.name"
      type="number"
    >
    </v-text-field>
    <v-text-field
      v-else-if="control.type == 'text'"
      clearable
      v-model="controlValue"
      :hint="control.hint"
      :persistent-hint="!!control.hint"
      @change="$emit('change', controlValue)"
      :label="control.name"
    >
    </v-text-field>
    <div v-else>
      <span>{{ control.name }}</span>
      <p>Needs to be edited via the JSON editor</p>
    </div>
  </div>
</template>
<script>
import { COLORS } from "@/components/fields/Field/FieldSettings/util.js";
import { createOptionsObjects } from "@/util";
export default {
  name: "AttributeControl",
  data() {
    return {
      controlValue: this.control.value,
    };
  },
  props: {
    control: {
      type: Object,
    },
  },
  created() {
    this.createOptionsObjects = createOptionsObjects;
    this.colors = COLORS;
  },
};
</script>
