<template>
  <v-tabs-items
    v-model="value"
    :class="backgroundColor"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-tabs-items>
</template>
<script>
import { getBackgroundColor } from "@/util.js";
export default {
  inheritAttrs: false,
  name: "AtmanTabsItems",
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    backgroundColor() {
      return getBackgroundColor();
    },
  },
};
</script>
<style lang="scss" scoped>
/* 
Added specifically for Dropdowns in FieldTutorial.  
The dropdown values are truncated at this element
*/

::v-deep {
  .v-window {
    overflow: visible !important;
  }
}
</style>
