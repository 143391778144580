import {
  color,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Date Range",
  type: "date_range",
  description: "Displays a date range field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Date Range field view.",
      label: "Default",
      value: {
        label: "Date Range",
        type: "date_range",
      },
    },
    {
      id: "date_with_format",
      keyword: ["tutorial"],
      description:
        'Use <code>"type": "date_range"</code> and <code>value</code> to display a period in <b>dd-MM-yyyy</b> format.',
      label: "Date Range with format",
      value: {
        label: "Date Range",
        type: "date_range",
        value: ["2021-03-11T08:34:33.670Z", "2021-03-11T08:34:33.670Z"],
        format: "dd-MM-yyyy",
      },
    },
    {
      id: "date_time",
      keyword: ["tutorial"],
      description:
        'Use <code>"type": "date_range"</code> and <code>value</code> to display a period in <b>yyyy-MM-dd HH:mm:ss z</b> format.',
      label: "DateRange & Time",
      value: {
        label: "Date Range",
        type: "date_range",
        value: ["2021-03-11T08:34:33.670Z", "2021-03-11T08:34:33.670Z"],
        format: "yyyy-MM-dd HH:mm:ss z",
      },
    },
  ],
  attributes: [
    color,
    {
      name: "field.color",
      type: "color",
      value: "accent",
    },
    {
      name: "header-color",
      type: "color",
      value: "secondary",
    },
    {
      name: "ok_color",
      type: "color",
      value: "primary",
    },
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
    no_value_text,
    no_value_behavior,
  ],
};
