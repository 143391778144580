import { makeServerCall } from "@/util.js";

const debug = require("debug")("atman.store.api"); // eslint-disable-line

const fetchSkin = async (options) => {
  let publicAPI =
    typeof options?.publicAPI == "undefined" ? true : options?.publicAPI;
  let url = "/skin";
  if (publicAPI) {
    url = "/skin";
  }
  return (
    await makeServerCall({
      url,
    })
  ).data;
};

const updateSkin = async (skin) => {
  const response = await makeServerCall({
    url: "/skin",
    type: "post",
    params: {
      action: "update_fields",
      data: skin,
    },
  });
  return response.data;
};

const fetchNotifications = async (timestamp) => {
  return (
    await makeServerCall({
      url: `system/notifications?timestamp=${timestamp}`,
    })
  ).data;
};
const fetchMenu = async () => {
  return (
    await makeServerCall({
      url: "menu",
    })
  ).data;
};

const fetchRoles = async () => {
  return (
    await makeServerCall({
      url: "/roles",
      params: {
        sort: "_created_date",
        count: 999,
      },
    })
  ).data;
};

const getLocalPreferences = () => {
  let savedPreferences = {};
  if (!localStorage.skin) {
    return savedPreferences;
  }
  try {
    savedPreferences = JSON.parse(localStorage.skin);
  } catch {
    debug("invalid stored theme");
  }
  return savedPreferences;
};

export {
  fetchMenu,
  fetchSkin,
  fetchNotifications,
  updateSkin,
  getLocalPreferences,
  fetchRoles,
};
