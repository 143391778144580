<template>
  <div>
    <small v-if="!actions.length" aut-no-values-message>
      No Actions added yet. Click the Add button to add one
    </small>
    <v-expansion-panels>
      <v-expansion-panel v-for="(action, i) in actions" :key="i">
        <v-expansion-panel-header aut-action-header ref="expansion-header">
          {{ action.label || action.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-hover v-slot="{ hover }">
            <v-card
              aut-action-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              class="mt-2"
              outlined
            >
              <v-card-text>
                <ActionDesigner
                  :isList="isList"
                  :controls="controls"
                  :definition.sync="actions[i]"
                  @update:definition="updateDefinition"
                  @remove="removeAction(i)"
                />
              </v-card-text>
            </v-card>
          </v-hover>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="smaller d-flex mt-3">
      <v-spacer />
      <v-autocomplete
        dense
        aut-action-template
        :items="templates"
        label="Template"
        item-value="id"
        item-text="label"
        v-model="template"
      />
      <v-btn class="ml-1" @click="addAction" aut-action-add-action>Add</v-btn>
    </div>
  </div>
</template>
<script>
import { snakeCase } from "lodash";
import { clone, fetchTemplates, uniqueID } from "@/util.js";
import Action from "@/components/fields/Action";

const debug = require("debug")("atman.components.actions_designer");
debug(`actions_designer`);
export default {
  name: "ActionsDesigner",
  props: {
    definition: {
      mandatory: true,
    },
    isList: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    let actions = [];
    if (Array.isArray(this.definition)) {
      actions = clone(this.definition);
    } else {
      actions = clone(this?.definition?.value || []);
    }
    return {
      fieldDefinition: clone(this.definition || {}),
      actions,
      templates: [],
      template: null,
      controls: [
        {
          icon: "mdi-delete",
          id: "remove_action",
          confirmation: "Are you sure you want to continue?",
          label: "Remove Action",
          event: "remove_action",
        },
      ],
    };
  },
  components: {
    ActionDesigner: Action.designer,
  },
  async mounted() {
    debug(`In mounted of ActionsDesigner`);
    const templates = Action.definition.templates || [];
    const dynamicTemplates = await fetchTemplates("action");
    this.templates = [...templates, ...dynamicTemplates];
    this.template = templates[0].id;
  },
  methods: {
    addAction() {
      debug(`Add Action clicked`);
      const component = this;
      const chosenTemplate = clone(
        component.templates.find(({ id }) => id == component.template).value
      );
      this.actions = this.actions || [];
      chosenTemplate.name = snakeCase(chosenTemplate.label);
      chosenTemplate.id = uniqueID();
      this.actions.push(chosenTemplate);
      this.$nextTick(() => {
        const expansionHeaders = this.$refs["expansion-header"];
        expansionHeaders[expansionHeaders.length - 1].$el.click();
        this.updateDefinition();
      });
    },
    removeAction(index) {
      this.actions.splice(index, 1);
      this.updateDefinition();
    },
    updateDefinition() {
      if (Array.isArray(this.fieldDefinition)) {
        this.$emit("update:definition", this.actions);
      } else if (Array.isArray(this.fieldDefinition.value)) {
        this.fieldDefinition.value = clone(this.actions);
        this.$emit("update:definition", this.fieldDefinition);
      }
    },
  },
};
</script>
