export default {
  html: "mdi-language-html5",
  js: "mdi-nodejs",
  json: "mdi-code-json",
  md: "mdi-language-markdown",
  pdf: "mdi-file-pdf-box",
  apng: "mdi-file-image",
  avif: "mdi-file-image",
  jpeg: "mdi-file-image",
  gif: "mdi-file-image",
  png: "mdi-file-image",
  svg: "mdi-file-image",
  webp: "mdi-file-image",
  jpg: "mdi-file-image",
  txt: "mdi-file-document-outline",
  xls: "mdi-file-excel",
  xlsx: "mdi-file-excel",
  csv: "mdi-file-excel",
  doc: "mdi-microsoft-word",
  docx: "mdi-microsoft-word",
};
