import Vue from "vue";
import { get, set, isEqual } from "lodash";
import { clone } from "@/util.js";
const debug = require("debug")("atman.workflow_editor.store");

export const STORE_CONSTS = {
  WORKFLOW_EDITOR_STORE: "workflow_editor",
  WORKFLOW: "workflow",
  DEFINITION: "definition",
  UPDATE_NODE: "UPDATE_NODE",
  UPDATE_STEP: "UPDATE_STEP",
  CONTEXT: "context",
};

export const store = {
  namespaced: true,
  state: () => ({
    definition: {},
    workflow: {
      name: "",
      description: "",
      steps: {},
    },
    context: "",
  }),
  getters: {},
  mutations: {
    [STORE_CONSTS.WORKFLOW](state, workflow) {
      debug(`in ${STORE_CONSTS.WORKFLOW}`);
      if (isEqual(state.workflow, workflow) || !workflow) {
        debug(`${STORE_CONSTS.WORKFLOW} ignored`);
        return;
      }
      Vue.set(state, "workflow", clone(workflow));
    },

    [STORE_CONSTS.DEFINITION](state, definition) {
      debug(`in ${STORE_CONSTS.DEFINITION}`);
      if (isEqual(state.definition, definition) || !definition) {
        debug(`${STORE_CONSTS.DEFINITION} ignored`);
        return;
      }
      Vue.set(state, "definition", clone(definition));
    },

    [STORE_CONSTS.CONTEXT](state, value) {
      debug(`in ${STORE_CONSTS.CONTEXT}`);
      const originalContent = get(state, "context");
      if (isEqual(originalContent, value)) {
        debug(`${STORE_CONSTS.CONTEXT} ignored`);
        return;
      }
      set(state, "context", value);
    },

    [STORE_CONSTS.UPDATE_NODE](state, { key, value }) {
      debug(`in ${STORE_CONSTS.UPDATE_NODE}`);
      const originalContent = get(state.workflow, key);
      if (isEqual(originalContent, value)) {
        debug(`${STORE_CONSTS.UPDATE_NODE} ignored`);
        return;
      }
      set(state.workflow, key, value);
    },

    [STORE_CONSTS.UPDATE_STEP](state, { index, value }) {
      debug(`in ${STORE_CONSTS.UPDATE_STEP}`);
      const steps = clone(state.workflow.steps);
      if (isEqual(steps[index], value)) {
        debug(`${STORE_CONSTS.UPDATE_STEP} ignored`);
        return;
      }
      steps[index] = value;
      Vue.set(state.workflow, "steps", steps);
    },
  },
  actions: {},
};
