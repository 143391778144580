<template>
  <div>
    <template v-if="mode == 'carousel'">
      <div v-if="seeAllVisible">
        <v-btn
          class="text-caption secondary--text px-0"
          small
          text
          @click="$emit('show:category')"
        >
          see all
        </v-btn>
      </div>
      <v-slide-group v-if="books.length" v-model="books" center-active>
        <v-slide-item v-for="(book, index) in books" :key="`book_${index}`">
          <BookContainer :context="context" :book="book" />
        </v-slide-item>
      </v-slide-group>
    </template>
    <div v-else class="d-flex flex-wrap justify-md-start justify-center">
      <div v-for="(book, index) in books" :key="`book_${index}`">
        <BookContainer :context="context" :book="book" />
      </div>
    </div>
  </div>
</template>
<script>
import { defaultsDeep } from "lodash";
import { makeServerCall, getFilterString } from "@/util";
import BookContainer from "./BookContainer.vue";
const debug = require("debug")("atman.component.books"); // eslint-disable-line
const SEE_ALL_LIMIT = {
  LG: 4,
  MD: 3,
};

export default {
  name: "Books",
  components: {
    BookContainer,
  },
  props: {
    filter: {},
    context: {},
    mode: {},
    attributes: {},
  },
  data() {
    return {
      books: [],
    };
  },
  watch: {
    $route() {
      this.fetchBooks();
    },
    filter() {
      this.fetchBooks();
    },
  },
  computed: {
    bookFilter() {
      const filterString = this?.$route?.query?.filter;
      let filter = {};

      try {
        filter = JSON.parse(filterString);
      } catch {
        filter = {};
      }

      return filter;
    },
    seeAllVisible() {
      if (this.isLargeScreen) {
        return this.books.length > SEE_ALL_LIMIT.LG;
      }

      if (this.isTabletOrLarger) {
        return this.books.length > SEE_ALL_LIMIT.MD;
      }

      return true;
    },
    search() {
      const search = this?.$route?.query?.search;

      if (search && typeof search == "string") {
        return search;
      }

      return "";
    },
  },
  mounted() {
    this.fetchBooks();
  },
  methods: {
    async fetchBooks() {
      const component = this;
      this.books = [];
      /* Filtering within arrays is not yet supported in json-server 
         so the filter is dropped altogether during local development
         TODO: Remove this and move to our json-server
            https://github.com/typicode/json-server/issues/654
        */
      debug(
        `filter`,
        this.filter,
        getFilterString(Object.assign(this.bookFilter, this.filter))
      );
      let filterString = getFilterString(
        Object.assign(this.bookFilter, this.filter)
      );
      let params = filterString
        ? {
            filter: filterString,
          }
        : {};

      if (this.search) {
        params.search = `"${this.search}"`;
      }

      const paramsInConfig = this.attributes?.books_query?.params || {};

      params = defaultsDeep(params, paramsInConfig);
      debug(`params`, params);
      try {
        let path =
          component.$store?.state[`${component.context}`]?.context?.path;
        if (!path) {
          return;
        }
        const response = await makeServerCall({
          url: `${path}/books`,
          type: "get",
          params,
        });
        component.books = response.data || [];
      } catch (e) {
        debug(`Could not get books`, e);
      }
    },
  },
};
</script>
