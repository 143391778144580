<template>
  <v-container fluid>
    <v-card aut-add-lookup-dialog>
      <v-card-title> Add a new Lookup </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                aut-lookup-name
                label="Name"
                hint="The Lookup Name"
                persistent-hint
                v-model="lookup"
                required
                :rules="mandatory"
                ref="focus"
                autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <StaticSeed
                @update:seed_data="lookupValues = $event.value"
                :data="{ value: lookupValues }"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" aut-action-add-lookup-cancel> Cancel </v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          @click="process"
          aut-action-add-lookup
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { rules, makeServerCall } from "@/util.js";
const debug = require("debug")("atman.components.lookup_creator");
debug(`lookup_creator`);
export default {
  components: {
    StaticSeed: () =>
      import("@/components/fields/PageEditor/SeedDataDesigner/StaticSeed.vue"),
  },
  name: "LookupCreator",
  data() {
    return {
      mandatory: [rules.mandatory()],
      lookup: "",
      lookupValues: [],
      valid: false,
    };
  },
  mounted() {},
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    async process() {
      const applicationID = (this.application = this.$route.params.id);
      await makeServerCall({
        url: `/applications/${applicationID}?lookup=${this.lookup}`,
        type: "post",
        params: {
          data: this.lookupValues,
        },
      });
      this.displaySuccessMessage("Lookup created");
      this.$router.go(-1);
    },
  },
};
</script>
