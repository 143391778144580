import ApplicationCreator from "./ApplicationCreator.vue";
import applicationEditRoutes from "./application/routes";
import ApplicationsList from "./ApplicationsList.vue";

import lookupRoutes from "./lookup/routes";
import reportRoutes from "./report/routes";

const routes = [
  ...lookupRoutes,
  ...reportRoutes,
  ...applicationEditRoutes,
  {
    path: "/create_application",
    name: "Create Application",
    component: ApplicationCreator,
    meta: { unauthenticated: false },
  },

  {
    path: "/applications",
    name: "Applications",
    component: ApplicationsList,
    meta: { unauthenticated: false },
  },
];

export default routes;
