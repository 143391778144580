const debug = require("debug")("atman.components.book"); // eslint-disable-line

const sampleBook = {
  title: "Srinivasa Ramanujan: Friend Of Numbers",
  id: "id_0",
  isbn: "193398862323",
  page_count: 28,
  published_date: "2020-10-01T09:19:53+00:00",
  cost: {
    unit_price: {
      label: "Unit Price",
      value: {
        amount: 28,
        currency: "INR",
      },
    },
  },
  front_cover: "/book/SR-01.jpg",
  back_cover: "id_0_id_31",
  blurb:
    '"What will happen if I add up all the numbers in the world?"<br/><br/>"Is there a last number?"<br/><br/>A toddler captivated by patterns... A little boy filling his slate with numbers, rubbing them out with his elbow and starting again... A teenager solving complex maths problems... A young man matching the best minds in Cambridge... Bringing the story of brilliant mathematician Srinivasa Ramanujan to children, the book follows his singular fascination with numbers! Satwik Gade’s strong visuals break out of literal depiction to evoke the mind and world of a genius, to whom numbers "made patterns only he could see"<br/><br/>2020 Neev Book Award Winner in the Emerging Readers category',
  status: "published",
  illustrators: ["id_1"],
  publishers: ["id_0"],
  authors: ["id_0", "id_2"],
  categories: ["open_source", "mobile"],
};
export default {
  label: "Book",
  type: "book",
  description: "Displays a book",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Book Field",
      label: "Default",
      variables: {
        data: {
          default: sampleBook,
        },
      },
      value: {
        name: "default",
        label: "Title",
        type: "book",
      },
    },
    {
      id: "expanded",
      label: "Expanded",
      variables: {
        data: {
          expanded: sampleBook,
        },
      },
      value: {
        name: "expanded",
        label: "Title",
        type: "book",
        display: {
          attributes: {
            mode: "expanded",
          },
        },
      },
    },
    {
      id: "with_cost",
      label: "With cost",
      variables: {
        data: {
          with_cost: sampleBook,
        },
      },
      value: {
        name: "with_cost",
        label: "Title",
        type: "book",
        display: {
          attributes: {
            cost: {
              display: true,
              label: "Credits Required",
            },
          },
        },
      },
    },
    {
      id: "navigate_to_another_page",
      label: "Navigate to another Page",
      variables: {
        data: {
          navigate_to_another_page: sampleBook,
        },
      },
      value: {
        name: "navigate_to_another_page",
        label: "Title",
        type: "book",
        display: {
          attributes: {
            cost_display: false,
            on_click: {
              type: "navigation",
              url: "{_context->path}/books/",
            },
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "image.width",
      type: "number",
      value: 300,
    },
    {
      name: "mode",
      type: "select",
      options: [
        {
          id: "minimal",
          label: "Minimal",
        },
        {
          id: "expanded",
          label: "Expanded",
        },
      ],
    },
    {
      name: "image.contain",
      type: "switch",
      value: true,
    },
    {
      name: "fullscreen",
      type: "switch",
      value: true,
    },
    {
      name: "cost_display",
      type: "switch",
      value: false,
    },
    {
      name: "details",
      value: {
        fields: [
          {
            label: "Written by",
            node: "authors",
            display_node: "name",
            api: {
              url: "/applications/eblisher/authors",
            },
          },
          {
            label: "Illustrated by",
            node: "illustrators",
            display_node: "name",
            api: {
              url: "/applications/eblisher/illustrators",
            },
          },
          {
            label: "Published by",
            node: "publishers",
            display_node: "name",
            api: {
              url: "/applications/eblisher/publishers",
            },
          },
          {
            label: "Number of pages:",
            node: "page_count",
          },
        ],
      },
    },
  ],
  classes: [],
};
