<template>
  <AtmanWizard :steps="steps" @submit="submit" />
</template>
<script>
import { isEqual } from "lodash";
import { clone, makeServerCall } from "@/util.js";
import AtmanWizard from "@/components/editor/AtmanWizard";
import WorkflowName from "./WorkflowName";
import WorkflowSteps from "./WorkflowSteps";
import { fieldMixin } from "@/components/mixin.js";
import WorkflowVariables from "./WorkflowVariables";
import { store, STORE_CONSTS } from "./store";
const debug = require("debug")("atman.components.workflow_editor");
debug("workflow_editor");
const steps = [
  {
    id: 1,
    label: "Name",
    component: WorkflowName,
  },
  {
    id: 2,
    label: "Variables",
    component: WorkflowVariables,
  },
  {
    id: 3,
    label: "Steps",
    component: WorkflowSteps,
  },
];

export default {
  name: "WorkflowEditor",
  mixins: [fieldMixin],
  components: {
    AtmanWizard,
  },
  data() {
    return {
      steps,
    };
  },
  created() {
    const component = this;
    component._createStore(STORE_CONSTS.WORKFLOW_EDITOR_STORE, store);
    component.$store.commit(
      `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.DEFINITION}`,
      clone(component.value)
    );
    this.setupWatch();
  },
  mounted() {
    debug("in workflow editor");
  },
  methods: {
    _afterValueUpdate() {
      if (typeof this.fieldValue == "undefined" || this.fieldValue === null) {
        return;
      }
      debug("value in workflow editor", this.fieldValue);
      this.updateLocalStore(this.fieldValue);
    },
    async submit() {
      const component = this;
      debug(`in submit of workflow editor`);
      const definition =
        component.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.DEFINITION
        ];
      const apiDetails = definition.apis.submit;
      const data = component.fieldValue;
      debug("Going to make a server call to", apiDetails, data);
      await makeServerCall({
        url: apiDetails.url,
        type: apiDetails.type,
        params: {
          data,
        },
      });
      component.displaySuccessMessage(`Workflow updated`);
    },
    setupWatch() {
      const component = this;
      component.unsubscribe = component.$store.subscribe((mutation) => {
        if (mutation.type.indexOf(STORE_CONSTS.WORKFLOW_EDITOR_STORE) === -1) {
          return;
        }
        const updatedValue = clone(
          component.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
            STORE_CONSTS.WORKFLOW
          ]
        );
        debug("workflow editor watch triggered", updatedValue);
        this.$set(component.value, "value", updatedValue);
      });
    },
    updateLocalStore(newValue) {
      const component = this;
      const workflowInStore =
        this?.$store?.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.WORKFLOW
        ] || {};
      if (isEqual(newValue, workflowInStore)) {
        return;
      }
      debug("Updating local store with fetched form data");
      component.$store.commit(
        `${STORE_CONSTS.WORKFLOW_EDITOR_STORE}/${STORE_CONSTS.WORKFLOW}`,
        newValue
      );
    },
  },
};
</script>
