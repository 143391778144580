import Vue from "vue";
import { formatDistance } from "date-fns";
Vue.filter("elapsedTime", function (value) {
  if (!value) return "";
  const today = new Date();
  try {
    const date = new Date(value);
    const result = formatDistance(date, today, { addSuffix: true });
    return result;
  } catch (e) {
    return value;
  }
});
