import { mapActions } from "vuex";

import fieldMixin from "./fieldMixin.js";
import componentWrapperMixin from "./componentWrapperMixin.js";
import confirmationMixin from "./confirmationMixin.js";
import componentDesignerMixin from "./componentDesignerMixin.js";
import componentMixin from "./componentMixin.js";
import pageMixin from "./pageMixin.js";
import dialogMixin from "./dialogMixin.js";

// import store from "@/store";
const debug = require("debug")("atman.component.mixin"); // eslint-disable-line

const storeEventsMixin = {};

const systemPageMixin = {
  components: {
    Page: () => import("@/components/Page/Page"),
  },
  created() {
    this.access_control = {
      edit_pages: "NO",
    };
  },
  data() {
    return {
      flowDetails: null,
      currentContext: null,
    };
  },
  methods: {
    ...mapActions(["fetchMenu"]),
  },
};

export {
  pageMixin,
  dialogMixin,
  storeEventsMixin,
  componentDesignerMixin,
  componentMixin,
  fieldMixin,
  confirmationMixin,
  systemPageMixin,
  componentWrapperMixin,
};
