import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";

const debug = require("debug")("atman.components.user_badge"); // eslint-disable-line
export default {
  label: "User Badge",
  type: "user_badge",
  description: "Displays a User Badge",
  properties: ["name", "type", "display", "block", "mode", "value"],
  templates: [
    {
      id: "default",
      description:
        "When invoked without any parameters, this field displays the logged in user's information",
      label: "Default",
      value: {
        name: "default",
        type: "user_badge",
      },
    },
    {
      id: "below",
      description:
        "Use the <code>username.position</code> attribute to position the name below",
      label: "Username Below",
      value: {
        name: "below",
        type: "user_badge",
        display: {
          attributes: {
            username: { position: "below" },
          },
        },
      },
    },
    {
      id: "other_user",
      description:
        "If a value is specified, that user's information is displayed",
      label: "Another User",
      variables: {
        data: {
          other_user: "admin",
        },
      },
      value: {
        name: "other_user",
        type: "user_badge",
      },
    },
    {
      id: "no_default",
      description: `Use attribute <code>"default": false</code> to disable default behaviour`,
      label: "No Default",
      value: {
        name: "no_default",
        type: "user_badge",
        display: {
          attributes: {
            default: false,
          },
        },
      },
    },
    {
      id: "navigation",
      description: `Use attribute <code>"navigation"</code> to set navigation on badge click`,
      label: "Navigation",
      variables: {
        data: {
          path: "/users",
        },
      },
      value: {
        name: "navigation",
        type: "user_badge",
        display: {
          attributes: {
            navigation: "{_data->path}/{_user->id}?page=edit",
          },
        },
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    {
      name: "username.position",
      type: "select",
      value: "right",
      options: [
        {
          id: "right",
          hint: "display username to the right",
          label: "right",
        },
        {
          id: "below",
          hint: "display username below badge",
          label: "below",
        },
      ],
    },
    {
      name: "navigation",
      type: "text",
      hint: "Accepts a path(url). Clicking on the badge will navigate to this url",
    },
    {
      name: "default",
      type: "select",
      hint: "Control whether the badge displays the logged-in user if the fetched information is not available",
      value: "me",
      options: [
        {
          id: false,
          hint: "Fetch another user's data",
          label: "Another user",
        },
        {
          id: "me",
          hint: "Fetch logged in user's data",
          label: "Logged in user",
        },
      ],
    },
  ],
};
