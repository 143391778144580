<template>
  <v-card raised ripple class="ma-2 pa-2" width="150px">
    <v-img contain :src="value.image._file" />
  </v-card>
</template>
<script>
export default {
  name: "BookPage",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.cardImage {
  height: 180px;
  max-height: 200px;
  // background-color: $background-color;
}
.cardTitle {
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  height: 50px;
  margin-top: 10px;
  line-height: 15px; // Ensures wrapped lines appear related and together
  margin-bottom: 10px;
}
</style>
