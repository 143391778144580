<template>
  <div aut-home-page>
    <LoaderContent :value="!currentContext" />
    <Page
      v-if="flowDetails && currentContext"
      :page="flowDetails"
      :context="currentContext"
    />
  </div>
</template>
<script>
import { createContext, clone } from "@/util";
import { systemPageMixin } from "@/components/mixin";

import domainListPage from "@/views/administration/domains/domains_list_page";
import applicationPermissionListPage from "@/views/applications/application/ApplicationPermissions/application_permissions_list";
import roleListPage from "@/views/administration/roles/role_list_page";
import permissionAddPage from "@/views/administration/permissions/add_permission_page";
import permissionListPage from "@/views/administration/permissions/permission_list_page";
import permissionEditPage from "@/views/administration/permissions/edit_permission_page";

const pageMap = {
  domain_list: domainListPage,
  application_permission_list: applicationPermissionListPage,
  role_list: roleListPage,
  permissions_list: permissionListPage,
  permissions_add: permissionAddPage,
  permissions_edit: permissionEditPage,
};

const debug = require("debug")("atman.components.administration.client_side_page"); // eslint-disable-line

export default {
  name: "ClientSidePage",
  mixins: [systemPageMixin],
  data() {
    return {};
  },
  watch: {
    $route() {
      this.renderRoute();
    },
  },
  mounted() {
    this.renderRoute();
  },
  methods: {
    renderRoute() {
      let pageKey = this.$route?.meta?.page_key;
      let pageContent;
      if (pageKey) {
        pageContent = pageMap[pageKey];
        if (!pageContent) {
          let view = this.$route?.query?.page;
          if (view) {
            pageKey = `${pageKey}_${view}`;
            pageContent = pageMap[pageKey];
          }
        }
      }
      if (pageContent) {
        this.pageContent = pageContent;
        this.renderPage();
      }
    },
    renderPage() {
      if (!this.pageContent) {
        return;
      }
      const component = this;
      const url = `/`;
      const context = createContext(url);
      context.path = "";
      context.full_path = "";
      component.currentContext = context;
      component.flowDetails = clone(this.pageContent);
    },
  },
};
</script>
