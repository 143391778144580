<template>
  <FieldLabel
    :definition="value"
    :mode="mode"
    :label="label"
    :attributes="labelAttributes"
  >
    <v-rating
      aut-rating
      v-if="!isNumerical"
      v-model="rating"
      :key="rating"
      dense
      small
      hover
      :length="length"
      :readonly="isDisplayMode"
      color="orange lighten-1"
      background-color="grey lighten-1"
      medium
    ></v-rating>
    <span v-if="isNumerical">{{ rating }}/{{ length }}</span>
  </FieldLabel>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.rating"); // eslint-disable-line

export default {
  name: "Rating",
  mixins: [fieldMixin],
  data() {
    return {
      definition,
    };
  },
  computed: {
    length() {
      return this.displayAttributes?.length || 5;
    },
    isNumerical() {
      return this.displayAttributes?.mode == "numerical";
    },
    rating: {
      get() {
        const rating = parseInt(this.fieldValue);
        debug("Rating is" + rating);
        return isNaN(rating) ? 0 : rating;
      },
      set(value) {
        this.fieldValue = value;
        debug(`Set Value`, value);
      },
    },
  },
};
</script>
