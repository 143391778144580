<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" class="d-flex">
        <v-combobox
          :items="CLASSES"
          item-text="label"
          item-id="id"
          label="Add Class"
          v-model="newClass"
          v-on:keydown.enter.stop.prevent="addClass(newClass.id)"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  <div class="d-flex">
                    <div>{{ item.id }} ({{ item.label }})</div>
                    <v-tooltip top>
                      <template
                        v-slot:activator="{ on, attrs }"
                        v-if="item.hint"
                      >
                        <v-icon v-bind="attrs" v-on="on" small
                          >mdi-information</v-icon
                        >
                      </template>
                      {{ item.hint }}
                    </v-tooltip>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <v-btn icon @click="addClass(newClass.id)"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-chip
          class="mr-1 mt-1"
          v-for="id in classes"
          :key="id"
          close
          @click:close="removeClass(id)"
        >
          {{ id }}
        </v-chip>
      </v-col>
      <v-col cols="12" class="d-flex">
        <SpacingControl @update="addClass($event)" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { clone } from "@/util";
import SpacingControl from "./SpacingControl.vue";
import { uniq } from "lodash";
import { CLASSES, removeIncompatibleClasses } from "./util";
export default {
  name: "ClassesControl",
  data() {
    return {
      newClass: null,
      definition: clone(this.field),
    };
  },
  props: {
    field: {},
  },
  components: {
    SpacingControl,
  },
  computed: {
    classes() {
      let result = this.definition?.classes || [];
      return result;
    },
  },
  created() {
    this.CLASSES = CLASSES;
  },
  methods: {
    addClass(id) {
      const classBeingAdded = id;
      let classesInDefinition = this.definition.classes;
      classesInDefinition = removeIncompatibleClasses(
        classesInDefinition,
        classBeingAdded
      );

      classesInDefinition.push(classBeingAdded);
      classesInDefinition = uniq(classesInDefinition);
      this.definition.classes = classesInDefinition;
      this.emitEvent();
    },
    removeClass(id) {
      this.definition.classes = this.definition.classes.filter((item) => {
        return item != id;
      });
      this.emitEvent();
    },
    emitEvent() {
      this.$emit("update", this.definition.classes);
    },
  },
};
</script>
