<template>
  <v-container v-if="isInputMode" class="pa-0 ma-0">
    <v-row dense>
      <v-col class="py-0 my-0" cols="12">
        <v-text-field
          v-model="effectiveValue.name"
          label="Name*"
          aut-feature-name
          @blur="updateValue"
          @change="updateValue"
          :rules="nameRules"
        />
      </v-col>
      <v-col class="py-0 my-0" cols="12">
        <v-text-field
          v-model="effectiveValue.description"
          label="Description*"
          :rules="descriptionRules"
        />
      </v-col>
      <!-- <v-col class="py-0 my-0" cols="12">
        <v-text-field
          v-model="effectiveValue.api"
          label="API*"
          :rules="apiRules"
        />
      </v-col> -->
    </v-row>
    <v-row dense>
      <v-col class="py-0 my-0" cols="12" lg="4" v-if="effectiveValue">
        <ConstraintType v-model="effectiveValue" @update="updateType" />
      </v-col>
      <v-col class="py-0 my-0" cols="12" lg="4">
        <ConstraintValue
          v-model="effectiveValue"
          :type="effectiveValue.constraint.type"
          @update="updateValue"
        />
      </v-col>
      <v-col
        class="py-0 my-0"
        cols="12"
        lg="4"
        v-if="effectiveValue.constraint.type != 'access'"
      >
        <ConstraintUnit v-model="effectiveValue" />
      </v-col>
    </v-row>
  </v-container>
  <v-row v-else-if="isDisplayMode" dense>
    <v-col cols="9">{{ effectiveValue.description }}</v-col>
    <v-col cols="3">
      <!-- <ConstraintType v-model="effectiveValue" mode="display" /> -->
      <ConstraintValue
        class="font-weight-bold"
        v-model="effectiveValue"
        :type="effectiveValue.constraint.type"
        mode="display"
      />
      <ConstraintUnit
        class="font-weight-bold"
        v-model="effectiveValue"
        :type="effectiveValue.constraint.type"
        mode="display"
        v-if="effectiveValue.constraint.type != 'access'"
      />
    </v-col>
  </v-row>
</template>

<script>
import { clone } from "@/util";
import ConstraintType from "./ConstraintType";
import ConstraintValue from "./ConstraintValue";
import ConstraintUnit from "./ConstraintUnit";
import { fieldMixin } from "@/components/mixin.js";
import { rules } from "@/rules.js";
const debug = require("debug")("atman.components.fields.feature"); // eslint-disable-line
export default {
  name: "FeatureField",
  mixins: [fieldMixin],
  components: {
    ConstraintType,
    ConstraintValue,
    ConstraintUnit,
  },
  data() {
    return {};
  },
  computed: {
    effectiveValue: {
      get() {
        debug(`fieldValue`, this.fieldValue);
        return (
          this.fieldValue || {
            name: "",
            description: "",
            constraint: {
              type: "",
              value: "",
              units: "",
            },
          }
        );
      },
      set(value) {
        this.fieldValue = value;
      },
    },
  },
  created() {
    this.nameRules = [rules.mandatory()];
    this.descriptionRules = [rules.mandatory()];
    this.apiRules = [rules.mandatory()];
  },
  methods: {
    updateType(type) {
      debug(`updateType`, type);
      const effectiveValue = clone(this.effectiveValue);
      effectiveValue.constraint.type = type;
      if (type == "access") {
        delete effectiveValue.constraint.unit;
      }
      this.effectiveValue = effectiveValue;
    },
    updateUnits() {
      debug(`updateUnits invoked`);
    },
    updateValue(value) {
      debug(`updateValue`, value);
      const effectiveValue = clone(this.effectiveValue);
      effectiveValue.constraint.value = value;
      this.effectiveValue = effectiveValue;
      debug(this.effectiveValue);
    },
    updateConstraintTypes() {
      debug(`updateConstraintTypes invoked`);
    },
  },
};
</script>
