import { defaultsDeep } from "lodash";
const debug = require('debug')('atman.fields.attributes')  // eslint-disable-line

const validate_on_blur = {
  name: "validate_on_blur",
  type: "switch",
  hint: "Only validate the value when the user tabs out of the control",
  value: true,
};
const rounded = {
  name: "rounded",
  type: "switch",
  hint: "Make the borders curved",
  value: false,
};
const flat = {
  name: "flat",
  type: "switch",
  hint: "Remove elevation",
  value: false,
};
const readonly = {
  name: "readonly",
  type: "switch",
  hint: "Make the input readonly",
  value: false,
};
const disabled = {
  name: "disabled",
  type: "switch",
  hint: "Disable the input",
  value: false,
};
const outlined = {
  name: "outlined",
  type: "switch",
  hint: "Add an outline around the field",
  value: true,
};
const filled = {
  name: "filled",
  type: "switch",
  hint: "Add a fill",
  value: true,
};
const dense = {
  name: "dense",
  type: "switch",
  hint: "Make the input more compact",
  value: true,
};

const color = {
  name: "color",
  type: "color",
  value: "primary",
  hint: "Control the text color",
};
const border_color = {
  name: "border_color",
  type: "color",
  hint: "Control the border color",
  value: "primary",
};
const background_color = {
  name: "background-color",
  type: "color",
  hint: "Control the background color",
  value: "",
};
const elevation = {
  name: "elevation",
  type: "number",
  hint: "Control the elevation",
  value: 0,
  min: 0,
  max: 24,
};

const height = {
  name: "height",
  type: "number",
  hint: "Control the height",
  value: 500,
};
const width = {
  name: "width",
  type: "number",
  hint: "Control the width",
  value: 500,
};

const min_height = {
  name: "min-height",
  type: "text",
  hint: "Control the min height",
  value: "",
};
const min_width = {
  name: "min-width",
  type: "text",
  hint: "Control the min width",
  value: "",
};
const max_height = {
  name: "max-height",
  type: "text",
  hint: "Control the max height",
  value: "",
};
const max_width = {
  name: "max-width",
  type: "text",
  hint: "Control the max width",
  value: "",
};
const clear_icon = {
  name: "clear-icon",
  type: "text",
  hint: "Icon to show as clearable. see https://pictogrammers.github.io/@mdi/font/6.4.95/",
  value: "$clear",
};
const append_icon = {
  name: "append-icon",
  type: "text",
  hint: "Append an icon to the input inside the outline, see https://pictogrammers.github.io/@mdi/font/6.4.95/",
  value: "",
};
const append_outer_icon = {
  name: "append-outer-icon",
  type: "text",
  hint: "Append an icon to the input outside the outline, see https://pictogrammers.github.io/@mdi/font/6.4.95/",
  value: "",
};
const prepend_icon = {
  name: "prepend-icon",
  type: "text",
  hint: "Prepend an icon to the component inside the outline, see https://pictogrammers.github.io/@mdi/font/6.4.95/",
  value: "",
};
const prepend_outer_icon = {
  name: "prepend-outer-icon",
  type: "text",
  hint: "Prepend an icon to the component outside the outline, see https://pictogrammers.github.io/@mdi/font/6.4.95/",
  value: "",
};
const autofocus = {
  name: "autofocus",
  type: "switch",
  hint: "Enable autofocus",
  value: false,
};
const clearable = {
  name: "clearable",
  type: "switch",
  hint: "Is clearable",
  value: false,
};
const solo = {
  name: "solo",
  type: "switch",
  hint: "Control the input style",
  value: false,
};
const solo_inverted = {
  name: "solo-inverted",
  type: "switch",
  hint: "Control the input style",
  value: false,
};
const single_line = {
  name: "single-line",
  type: "switch",
  hint: "Restrict the input to single line (label does not move)",
  value: false,
};
const shaped = {
  name: "shaped",
  type: "switch",
  hint: "Control the input style",
  value: false,
};
const placeholder = {
  name: "placeholder",
  type: "text",
  hint: "Placeholder value",
  value: "",
};
const persistent_placeholder = {
  name: "persistent-placeholder",
  type: "switch",
  hint: "Always display the placeholder",
  value: false,
};
const hint = {
  name: "hint",
  type: "text",
  hint: "Content of hint",
  value: "",
};
const persistent_hint = {
  name: "persistent-hint",
  type: "switch",
  hint: "Always display the hint",
  value: false,
};
const large = {
  name: "large",
  type: "switch",
  hint: "Make the component large",
  value: false,
};
const small = {
  name: "small",
  type: "switch",
  hint: "Make the component small",
  value: false,
};
const xlarge = {
  name: "x-large",
  type: "switch",
  hint: "Make the component extra large",
  value: false,
};
const xsmall = {
  name: "x-small",
  type: "switch",
  hint: "Make the component extra small",
  value: false,
};
const ripple = {
  name: "ripple",
  type: "switch",
  hint: "Ripple on click",
  value: true,
};

const counter = {
  name: "counter",
  type: "switch",
  hint: "Display a counter for numeric fields",
  value: false,
};

const no_value_text = {
  name: "no_value.text",
  hint: "Text to display when no value is available. Applicable only in 'display' mode",
  type: "text",
  value: "",
};

const no_value_behavior = {
  name: "no_value.behavior",
  hint: "How to behave when value is not present. Applicable only in 'display' mode",
  type: "select",
  value: "",
  options: ["hide_field"],
};

const behavior_centered = {
  name: "behavior_centered",
  type: "switch",
  hint: "Center align the value. NOTE: Can also be controlled by classes",
  value: false,
};

const behavior_size = {
  name: "behavior_size",
  type: "select",
  hint: "Size of text. NOTE: Can also be controlled by classes",
  value: "",
  options: [
    { id: "h1", label: "H1" },
    { id: "h2", label: "H2" },
    { id: "h3", label: "H3" },
    { id: "h4", label: "H4" },
    { id: "h5", label: "H5" },
    { id: "h6", label: "H6" },
    { id: "subtitle-1", label: "Subtitle-1" },
    { id: "subtitle-2", label: "Subtitle-2" },
    { id: "body-1", label: "Body-1" },
    { id: "body-2", label: "Body-2" },
    { id: "button", label: "Button" },
    { id: "caption", label: "Caption" },
    { id: "overline", label: "Overline" },
  ],
};

const min = {
  name: "min",
  type: "number",
  value: 0,
  hint: "Control the min value",
};

const max = {
  name: "max",
  type: "number",
  value: 100,
  hint: "Control the max value",
};

const icon_classes = {
  name: "icon.classes",
  type: "classes",
  value: 100,
  hint: "Add classes to control the behavior of the icon",
};

const attributes = {
  icon_classes,
  min,
  max,
  behavior_size,
  behavior_centered,
  no_value_text,
  no_value_behavior,
  counter,
  ripple,
  flat,
  large,
  small,
  xlarge,
  xsmall,
  validate_on_blur,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  color,
  border_color,
  background_color,
  elevation,
  min_height,
  min_width,
  max_height,
  max_width,
  clear_icon,
  append_icon,
  append_outer_icon,
  prepend_icon,
  prepend_outer_icon,
  autofocus,
  clearable,
  solo,
  solo_inverted,
  single_line,
  shaped,
  placeholder,
  persistent_placeholder,
  hint,
  persistent_hint,
  height,
  width,
};

const customiseAttribute = (attributeName, customProperties) => {
  let result = defaultsDeep(customProperties, attributes[attributeName]);
  debug(`customised attribute [${attributeName}]`, result);
  return result;
};

export {
  counter,
  ripple,
  flat,
  large,
  small,
  xlarge,
  xsmall,
  solo,
  shaped,
  hint,
  persistent_hint,
  single_line,
  solo_inverted,
  autofocus,
  append_icon,
  placeholder,
  persistent_placeholder,
  append_outer_icon,
  prepend_icon,
  prepend_outer_icon,
  validate_on_blur,
  background_color,
  clearable,
  clear_icon,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  dense,
  color,
  border_color,
  elevation,
  min_height,
  min_width,
  max_height,
  max_width,
  customiseAttribute,
  no_value_text,
  no_value_behavior,
  behavior_centered,
  behavior_size,
  min,
  max,
  icon_classes,
  height,
  width,
};
