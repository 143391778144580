<template>
  <div>
    <v-list-item
      dense
      v-if="mode == 'input'"
      @click.stop="addComponent"
      color="primary"
      class="pa-0"
    >
      <v-list-item-title aut-cost-sub-title>
        Add {{ title }}
      </v-list-item-title>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-on="on"
            v-bind="attrs"
            aut-add-cost-component
            class="ml-5"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </template>
        Add
      </v-tooltip>
    </v-list-item>
    <ul v-if="results && results.length">
      <li
        v-for="(item, i) in results"
        :key="`item-${i}`"
        aut-cost-component
        class="pt-2"
      >
        <v-card v-if="mode == 'input'" outlined class="pa-2 pt-6">
          <v-row class="d-flex dense">
            <v-col lg="5" cols="12">
              <v-text-field
                :hint="displayDate(item.valid_until)"
                aut-cost-component-label
                persistent-hint
                required
                :label="`${title} Label`"
                v-model="item.label"
                @blur="itemUpdated"
              >
                <template v-slot:append v-if="cost_component == 'deductions'">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on: menuOn, attrs }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on: tooltipOn }">
                          <v-icon
                            v-bind="attrs"
                            v-on="{ ...menuOn, ...tooltipOn }"
                            aut-valid-date
                            >mdi-calendar</v-icon
                          >
                        </template>
                        Select date for time bound Deduction
                      </v-tooltip>
                    </template>
                    <v-date-picker
                      aut-cost-date-picker
                      :value="item.valid_until | date({ today: true })"
                      @change="updateDate(i, $event)"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col lg="4" cols="12">
              <Currency
                :value="getFieldDefinition(item, i)"
                @change="itemValueUpdated(i, $event)"
              />
            </v-col>
            <v-col lg="3" cols="12">
              <v-autocomplete
                aut-type
                :items="componentTypes"
                item-text="label"
                item-value="type"
                menu-props="offset-y"
                dense
                class="pt-3"
                :value="item.type || 'custom'"
                label="Type"
                @change="itemTypeUpdated(i, $event)"
              ></v-autocomplete>
            </v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  aut-remove-cost-component
                  @click.stop="removeItem(i)"
                  small
                  color="error"
                  class="pl-1 item_action"
                  >mdi-delete</v-icon
                >
              </template>
              Remove
            </v-tooltip>
          </v-row>
        </v-card>
        <Currency :value="getFieldDefinition(item, i)" v-else />
      </li>
    </ul>
    <span v-else-if="displayNA">&nbsp;NIL</span>
  </div>
</template>
<script>
import { clone } from "@/util.js";

const debug = require("debug")("atman.components.field.cost.cost_components"); // eslint-disable-line

const customComponent = {
  label: "Custom",
  type: "custom",
};

export default {
  name: "CostComponents",
  components: {
    Currency: () => import("@/components/fields/Currency/Currency.vue"),
  },
  props: {
    definition: {
      type: Object,
    },
    cost_component: {
      type: String,
    },
    mode: {
      type: String,
    },
    items: {
      type: Array,
    },
    title: {
      type: String,
    },
    types: {
      type: Array,
    },
  },
  data() {
    return {
      results: this.getCostItems(),
      dateMenu: false,
    };
  },
  watch: {
    items() {
      this.results = this.getCostItems();
    },
  },
  computed: {
    displayNA() {
      if (this.results.length) {
        return false;
      }
      const attributes =
        this?.definition?.display?.attributes?.[this?.cost_component]?.no_value
          ?.behavior;
      const result = attributes != "hide";
      return result;
    },
    componentTypes() {
      return [...this.types, customComponent];
    },
  },
  methods: {
    removeItem(i) {
      this.results.splice(i, 1);
      debug(`after remove item`, this.results);
      this.emitUpdate(this.results);
    },
    emitUpdate(results) {
      debug(`emitting update`, results);
      this.$emit("change", clone(results));
    },
    itemValueUpdated(i, value) {
      this.results[i].value = clone(value);
      this.emitUpdate(this.results);
    },
    itemTypeUpdated(i, type) {
      if (type == "custom") {
        delete this.results[i].type;
      } else {
        this.results[i].type = clone(type);
      }
      this.emitUpdate(this.results);
    },
    itemUpdated() {
      let hasErrors = false;
      this.results.forEach((item) => {
        if (!item.label) {
          hasErrors = true;
        }
      });
      debug(`item updated`, this.results);
      if (!hasErrors) {
        this.emitUpdate(this.results);
      }
    },
    getFieldDefinition(item, index) {
      const result = {
        type: "currency",
        mode: this.mode,
        label: item.label,
        name: `${item.name}_${this.title}_${index}`,
        value: item.value,
        hint: `${this.title} Amount`,
        display: {
          attributes: {
            "persistent-hint": true,
          },
        },
      };
      debug(`field Definition`, result);
      return result;
    },
    addComponent() {
      debug(`Add components`);
      this.results.push({
        label: "",
        value: {
          amount: 0,
          currency: "INR",
        },
      });
      // this.emitUpdate(this.results);
    },
    addToTotal(value) {
      debug(`Add to total`, value);
    },
    updateDate(index, updatedDate) {
      this.dateMenu = false;
      const validDate = new Date(updatedDate).toISOString();
      this.results[index].valid_until = validDate;
      this.itemUpdated();
    },
    displayDate(date) {
      const dateFilter = this.$options?.filters?.date;
      if (typeof dateFilter == "function") {
        const validity = dateFilter(date);
        return validity ? `Valid until : ${validity}` : "";
      }
      return "";
    },
    getCostItems() {
      const results = clone([...(this.items || [])]);
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  .item_action {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }
}
</style>
