import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./filters";
import "@/components/pageElements/index.js";
import screenfull from "screenfull";

import { isFeatureEnabled, getFeatureValue } from "@/feature_flags.js";

const debug = require('debug')('atman.main')  // eslint-disable-line

Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

window.vue = vue;

Vue.mixin({
  components: {
    LoaderContent: () => import("@/components/pageContent/LoaderContent"),
    ToolTip: () => import("@/components/pageContent/ToolTip"),
    TutorialTrigger: () =>
      import("@/components/pageContent/Tutorial/TutorialTrigger.vue"),
  },
  computed: {
    isPassThrough() {
      let result = /^\/api.*/.test(this.$route.path);
      return result;
    },
    isStaticPath() {
      let result = /^\/static.*/.test(this.$route.path);
      return result;
    },
    isSmallScreen() {
      return this.$vuetify?.breakpoint?.smAndDown;
    },
    isTabletOrLarger() {
      return this.$vuetify?.breakpoint?.mdAndUp;
    },
    isTabletOrSmaller() {
      return this.$vuetify?.breakpoint?.mdAndDown;
    },
    isLargeScreen() {
      return this.$vuetify?.breakpoint?.lgAndUp;
    },
    isDark() {
      return this.$vuetify?.theme?.isDark;
    },
    defaultJustification() {
      return this.isSmallScreen ? "justify-space-around" : "justify-left";
    },
  },
  created() {
    this.intervals = [];
    this.unsubscribe = [];
    this.stores = [];
  },
  methods: {
    isFeatureEnabled,
    getFeatureValue,
    _subscribe(subscribeFn) {
      this.unsubscribe = this.unsubscribe || [];
      this.unsubscribe.push(subscribeFn);
    },
    cancel() {
      this.$router.go(-1);
    },
    _createStore(storeKey, store) {
      const component = this;
      if (!component.$store.hasModule(storeKey)) {
        component.$store.registerModule(storeKey, store);
        component.stores.push(storeKey);
      }
    },
    async _goFullScreen() {
      if (localStorage.no_full_screen) return;
      if (screenfull.isEnabled) {
        try {
          screenfull
            .request(document.getElementsByTagName("body")[0])
            .then(() => {})
            .catch((e) => {
              debug("screenfull error", e);
            });
        } catch (e) {
          debug("screenfull error", e);
        }
      }
    },
  },

  beforeDestroy() {
    if (Array.isArray(this.unsubscribe)) {
      (this.unsubscribe || []).forEach((removeSubcription) => {
        if (typeof removeSubcription == "function") {
          debug("Removing subscriptions");
          removeSubcription();
        }
      });
    }
    (this.intervals || []).forEach((interval) => {
      debug(`Clearing interval`);
      clearInterval(interval);
    });
    (this.stores || []).forEach((storeKey) => {
      debug(`Clearing store`);
      if (this.$store.hasModule(storeKey)) {
        this.$store.unregisterModule(storeKey);
      }
    });
  },
});
vue.$mount("#app");
