<template>
  <div v-if="fieldValue">
    <MinimalBook
      :attributes="displayAttributes"
      v-model="fieldValue"
      :context="context"
      v-if="displayMode == 'minimal'"
      @show_details="showDetails"
    />
    <ExpandedBook
      :attributes="displayAttributes"
      v-model="fieldValue"
      :context="context"
      v-else-if="displayMode == 'expanded'"
    />
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <DialogTitle @close="displayMode == 'minimal'" />
        <ExpandedBook
          :context="context"
          :attributes="displayAttributes"
          v-model="fieldValue"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
import MinimalBook from "./MinimalBook.vue";
import ExpandedBook from "./ExpandedBook.vue";

const debug = require("debug")("atman.components.book"); // eslint-disable-line

export default {
  name: "Book",
  mixins: [fieldMixin, dialogMixin],
  components: { MinimalBook, ExpandedBook },
  data() {
    return {
      dialog: false,
      definition,
    };
  },

  computed: {
    displayMode() {
      return this.displayAttributes?.mode || "minimal";
    },
  },
  methods: {
    _afterFieldValueUpdate() {
      debug(`fieldValue in after update`, this.fieldValue);
    },
    showDetails() {
      const onClick = this.displayAttributes?.on_click;
      if (onClick) {
        onClick.url = `${onClick.url}/${this.fieldValue.id}?page=details`;
        this.$emit("trigger-action", {
          definition: {
            value: onClick,
          },
        });
      } else {
        this.dialog = true;
      }
    },
  },
};
</script>
