<template>
  <div>
    <FieldLabel
      aut-file-label
      :definition="value"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
    </FieldLabel>
    <div v-if="mode == 'input'">
      <v-row dense>
        <v-col v-if="isDragAndDrop">
          <DragAndDrop
            @upload="addToUploadedFiles"
            v-model="value"
            :fieldValue="fieldValue"
            :fileFormats="formats"
          />
        </v-col>
        <v-col v-else-if="isIconButton">
          <v-btn
            @click="$refs.fileinput.click()"
            aut-upload-icon-button
            v-bind="iconButtonAttrs"
          >
            <v-icon
              v-if="iconButtonAttrs.iconAttrs"
              v-bind="iconButtonAttrs.iconAttrs"
            >
              {{ iconButtonAttrs.iconAttrs.value }}
            </v-icon>
            {{ iconButtonAttrs.label }}
            <v-icon
              v-if="!iconButtonAttrs.icon && iconButtonAttrs['append-icon']"
              v-bind="iconButtonAttrs['append-icon']"
            >
              {{ iconButtonAttrs["append-icon"].value }}
            </v-icon>
          </v-btn>
          <br />
          <v-chip
            v-if="fileObject"
            aut-uploaded-file
            outlined
            color="primary"
            class="ml-1 mt-2"
          >
            {{ fileDisplayName(fileObject) }}
          </v-chip>
          <input
            type="file"
            ref="fileinput"
            :accept="formats"
            aut-button-upload
            @click.stop
            style="display: none"
            @change="uploadFile"
          />
        </v-col>
        <v-col cols="12" v-else>
          <v-text-field
            aut-file-source
            v-if="sourceObj.url"
            v-model="source"
            label="Source"
          >
            <template v-slot:append>
              <v-menu offset-y min-width="200">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" aut-source-menu>
                    {{ sourceObj.icon || "mdi-link" }}
                  </v-icon>
                </template>
                <v-list dense>
                  <v-list-item
                    :aut-source-file="index"
                    v-for="(file, index) in sourceFiles"
                    :key="index"
                    @click="source = file"
                  >
                    <v-list-item-title> {{ file.id }} </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
          <v-file-input
            aut-field-file-upload
            ref="fileupload"
            :accept="formats"
            :multiple="isMultiple"
            :label="label"
            :name="value.name"
            v-bind="displayAttributes"
            v-bind:class="displayClasses"
            truncate-length="15"
            v-model="fileObject"
          >
          </v-file-input>
        </v-col>
      </v-row>
      <!-- @click:close="removeFile(i)"
        close -->
      <v-chip v-for="(uploadedFile, i) in uploadedFiles" :key="i">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a
              aut-file-link
              v-bind="attrs"
              v-on="on"
              :href="effectiveFileLink(uploadedFile)"
              target="_blank"
            >
              <span>{{ fileDisplayName(uploadedFile) }}</span>
            </a>
          </template>
          {{ fileDisplayName(uploadedFile) }}
        </v-tooltip>
      </v-chip>
    </div>
    <div v-else>
      <div v-if="isMultipleFilesDisplay">
        <FileFieldDisplay
          :mode="mode"
          v-for="(uploadedFile, i) in fieldValue"
          :key="i"
          :value="value"
          :fieldValue="uploadedFile"
        />
      </div>
      <FileFieldDisplay
        :mode="mode"
        v-else-if="fieldValue"
        :value="value"
        :fieldValue="fieldValue"
      />
    </div>
  </div>
</template>
<script>
import { isPlainObject } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import fileMixin from "./mixin";
import FileFieldDisplay from "./FileFieldDisplay";
import DragAndDrop from "./DragAndDrop";
import { makeServerCall } from "@/util";
import definition from "./definition.js";
const debug = require("debug")("atman.components.file_field"); // eslint-disable-line

export default {
  name: "FileField",
  mixins: [fieldMixin, fileMixin],
  components: {
    FileFieldDisplay,
    DragAndDrop,
  },
  data() {
    return {
      fileObject: null,
      sourceFiles: [],
      definition: definition,
    };
  },
  computed: {
    uploadedFiles() {
      let result = [];
      if (Array.isArray(this.fieldValue)) {
        result = this.fieldValue.filter((uploadedFile) =>
          isPlainObject(uploadedFile)
        );
      } else if (isPlainObject(this.fieldValue)) {
        result = [this.fieldValue];
      }
      debug(`uploaded Files`, result);
      return result;
    },
    isMultipleFilesDisplay() {
      return Array.isArray(this.fieldValue);
    },
    isDragAndDrop() {
      return this.displayAttributes?.control == "drag_and_drop";
    },
    isIconButton() {
      return this.displayAttributes?.control == "icon_button";
    },
    iconButtonAttrs() {
      const buttonAttributes = this.displayAttributes?.icon_button || {};

      const iconAttributes = buttonAttributes?.icon;
      const prependIcon = buttonAttributes["prepend-icon"];

      if (isPlainObject(prependIcon)) {
        buttonAttributes.iconAttrs = prependIcon;
      }

      if (isPlainObject(iconAttributes)) {
        buttonAttributes.label = "";
        buttonAttributes.iconAttrs = iconAttributes;
        buttonAttributes.icon = true;
      }
      if (this.isSmallScreen) {
        buttonAttributes.block = true;
      }
      return buttonAttributes;
    },
    sourceObj() {
      return this.displayAttributes?.source || {};
    },
    source: {
      get() {
        const fileSource = this.fileObject?._file || "";
        return fileSource;
      },
      set(input) {
        if (typeof input == "string") {
          this.fileObject = {
            _file: input,
          };
        } else {
          this.fileObject = input;
        }
      },
    },
    formats() {
      const formats = this.displayAttributes?.formats || [];
      if (!Array.isArray(formats)) {
        return "";
      }
      const result = formats.map((format) => format.trim()).join(", ");
      if (!result.length) {
        return "";
      }
      return result;
    },
  },
  watch: {
    fileObject() {
      this.fieldValue = this.fileObject;
    },
  },
  async mounted() {
    await this.fetchFiles();
  },
  methods: {
    addToUploadedFiles(newFiles) {
      this.fieldValue = newFiles;
      /* if (!this.isMultiple) {
        this.fieldValue = newFiles;
      } else {
        this.fieldValue = [...this.fieldValue, newFiles];
      } */
    },
    effectiveFileLink(uploadedFile) {
      return uploadedFile?._file || uploadedFile?.link;
    },
    fileDisplayName(uploadedFile) {
      return uploadedFile.id || uploadedFile?.name;
    },
    removeFile(index) {
      debug(`TBA`, index);
    },
    async fetchFiles() {
      const component = this;
      let payload = {
        url: this.sourceObj?.url || "",
        customFunctions: component.replaceIndex(),
      };

      let sourceUrl = "";

      try {
        sourceUrl =
          component.$store.getters[`${component.context}/dynamicText`](payload);
      } finally {
        payload = null;
      }
      if (!sourceUrl) {
        return;
      }

      let data = {};
      try {
        const response = await makeServerCall({
          url: sourceUrl,
          type: "get",
        });
        data = response?.data;
      } catch (e) {
        debug(`Could not get source files`, e);
      }

      if (!Array.isArray(data)) {
        return;
      }

      const fileKey = this.sourceObj?.field_name || "";

      const results = data
        .map((item) => {
          return item?.[fileKey];
        })
        .filter((fileObj) => fileObj?._file);

      this.sourceFiles = results;
    },
    uploadFile(e) {
      const files = e?.target?.files || [];
      if (files.length > 0) {
        this.fileObject = this.isMultiple ? files : files[0];
      }
    },
  },
};
</script>
