export default {
  id: "edit_application",
  title: "Edit Application",
  subtitle: "Here you can edit an application",
  classes:
    "behavior-dynamic-page behavior-no-editor behavior-no-fav behavior-no-pin",
  display: {
    tab: true,
  },
  content: [
    {
      type: "form",
      title: "Title/Description",
      apis: {
        data: {
          url: "/applications/{_context->id}",
          type: "get",
        },
        submit: {
          url: "/applications/{_context->id}",
          type: "post",
          params: {
            action: "update_fields",
          },
          success: {
            message: "Application updated successfully",
          },
        },
      },
      definition: {
        fields: [
          {
            name: "name",
            mandatory: true,
            label: "Name",
            type: "text",
          },
          {
            name: "description",
            mandatory: false,
            label: "Description",
            display: {
              attributes: {
                label: {
                  input: {
                    mode: "hidden",
                  },
                },
              },
            },
            placeholder: "Add description to your application...",
            type: "rich_text_editor",
          },
        ],
        actions: [],
      },
    },
    {
      id: "lookups",
      title: "Lookup",
      type: "list",
      display: {
        width: "12",
      },
      definition: {
        fields: [
          {
            label: "Name",
            name: "id",
            mode: "display",
            type: "text",
          },
          {
            label: "Actions",
            name: "actions",
            type: "actions",
            value: [
              {
                name: "edit",
                hint: "Edit",
                icon: "mdi-pencil",
                type: "action",
                value: {
                  type: "navigation",
                  url: "/edit_lookup/{_context->id}?lookup={_row->id}",
                },
              },
              {
                name: "delete",
                hint: "Delete",
                icon: "mdi-delete",
                type: "action",
                confirmation: "Are you sure?",
                value: {
                  type: "delete",
                  url: "{_context->path}?lookup={_row->id}",
                  success: {
                    action: {
                      type: "navigation",
                      url: "BACK",
                    },
                    message: "Deletion successful!",
                  },
                },
              },
            ],
          },
        ],
        actions: [
          {
            name: "add_lookup",
            label: "Add Lookup",
            type: "action",
            value: {
              type: "navigation",
              url: "/create_lookup/{_context->id}",
            },
            display: {
              attributes: {
                color: "primary",
              },
            },
          },
        ],
      },
      apis: {
        data: {
          url: "{_context->path}?lookup=*",
          refresh_interval: 300000,
          type: "get",
        },
      },
    },
    {
      type: "form",
      title: "Menu",
      apis: {
        submit: {
          url: "/applications/{_context->id}",
          type: "post",
          data: "application",
          params: {
            action: "update_fields",
          },
          success: {
            message: "Application updated successfully",
          },
        },
      },
      definition: {
        fields: [
          {
            display: {
              width: "12",
            },
            name: "application",
            mandatory: true,
            label: "Name",
            type: "application_menu_tree",
            apis: {
              data: {
                url: "/applications/{_context->id}",
                type: "get",
              },
            },
          },
        ],
        actions: [],
      },
    },
    {
      apis: {},
      definition: {
        actions: [],
        fields: [
          {
            name: "permission",
            type: "permissions_manager",
            label: "Permissions",
          },
        ],
        title: "",
      },
      display: {
        width: "12",
      },
      id: "application_permission_contexts",
      title: "Roles",
      type: "form",
    },
  ],
};
