const imageList = () => {
  const list = [
    { _file: "/test_images/image_640x480.jpeg" },
    { _file: "/test_images/image_640x680.jpeg" },
    { _file: "/test_images/image_640x480_2.jpeg" },
    { _file: "/test_images/image_640x1080.jpeg" },
  ];
  return list;
};

export default {
  label: "Image List",
  type: "imagelist",
  description: "Displays a image list field",
  properties: ["name", "label", "type", "mode", "display", "block"],
  templates: [
    {
      id: "default_image_list",
      description: `The default Image List Field <br/> Click on the image for expanded view`,
      label: "Default",
      variables: {
        data: {
          default_image_list: imageList(),
        },
      },
      value: {
        name: "default_image_list",
        label: "Image List",
        type: "imagelist",
        mode: "display",
        display: {
          attributes: {
            image: {
              width: "450",
            },
          },
        },
      },
    },
    {
      id: "tile",
      description: `The Image List Field in Tile layout <br/> Set <code>layout: "title"</code> for this view.`,
      label: "Tile",
      variables: {
        data: {
          default_image_list: imageList(),
        },
      },
      value: {
        name: "default_image_list",
        label: "Image List",
        type: "imagelist",
        mode: "display",
        display: {
          attributes: {
            layout: {
              name: "tile",
            },
            image: {
              width: "450",
            },
          },
        },
      },
    },
    {
      id: "input_image_list",
      description: "The Input mode for Image List Field",
      label: "Input Mode",
      variables: {
        data: {
          input_image_list: null,
        },
      },
      value: {
        name: "input_image_list",
        label: "Image List",
        type: "imagelist",
        mode: "input",
      },
    },
  ],
};
