import Vue from "vue";
import { isEqual } from "lodash";
import { clone } from "@/util.js";
const debug = require("debug")("atman.report_editor.store"); // eslint-disable-line

export const STORE_CONSTS = {
  REPORT_EDITOR_STORE: "report_editor",
  DEFINITION: "report",
};

export const store = {
  namespaced: true,
  state: () => ({
    report: {
      name: "",
      description: "",
      query: {
        url: "",
        type: "get",
      },
      list: [],
      summary: [],
      schedule: {},
    },
    context: "",
  }),
  getters: {},
  mutations: {
    [STORE_CONSTS.DEFINITION](state, report) {
      debug(`in ${STORE_CONSTS.DEFINITION}`);
      if (isEqual(state.report, report) || !report) {
        debug(`${STORE_CONSTS.DEFINITION} ignored`);
        return;
      }
      Vue.set(state, "report", clone(report));
    },
  },
  actions: {},
};
