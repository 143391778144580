<template>
  <v-container>
    <v-autocomplete
      :items="repeatTypes"
      label="Repeat"
      :return-object="true"
      v-model="selectedPattern"
    ></v-autocomplete>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <DialogTitle @close="$emit('close')" title="Custom Repeat" />
        <v-card-subtitle>
          <v-container>
            <v-row dense>
              <v-col cols="auto" class="label">
                <br />
                <span>Repeat every</span>
              </v-col>
              <v-col>
                <v-text-field
                  :disabled="selectedRecurrence == 'week'"
                  type="number"
                  v-model="count"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="recurrenceTypes"
                  v-model="selectedRecurrence"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="selectedRecurrence == 'week'">
              <v-col>
                <span class="label">Repeat on</span>
                <v-chip-group
                  multiple
                  active-class="primary"
                  v-model="selectedDays"
                >
                  <v-chip v-for="(day, i) in days" :key="i">
                    {{ day.charAt(0) | upperFirst }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row v-if="selectedRecurrence == 'month'">
              <v-col>
                <v-autocomplete
                  :items="monthlyPatterns"
                  v-model="selectedMonthPattern"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <span class="label">Ends</span>
                <v-radio-group v-model="selectedEnd">
                  <v-row dense>
                    <v-col cols="12" lg="3">
                      <v-radio label="Never"></v-radio>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" lg="3">
                      <v-radio label="On"></v-radio>
                    </v-col>
                    <v-col class="pa-0">
                      <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :disabled="selectedEnd != 1"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          @input="dateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" lg="3">
                      <v-radio label="After"></v-radio>
                    </v-col>
                    <v-col class="pa-0">
                      <v-text-field
                        :disabled="selectedEnd != 2"
                        type="number"
                        v-model="occurrences"
                        dense
                        suffix="occurrences"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-subtitle>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="primary darken-1" text @click="createExpression">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import {
  repeatTypes,
  recurrenceTypes,
  days,
  monthlyPatterns,
  isDayRecurrence,
  isMonthRecurrence,
  isYearRecurrence,
} from "./util.js";
import { range, defaultsDeep } from "lodash";
import { isDate, format } from "date-fns";

const [minute, hour, day_of_month, month, day_of_week] = range(5); // eslint-disable-line

export default {
  name: "Scheduler",
  mixins: [dialogMixin],
  props: {
    date: {
      type: Date,
      default: () => {
        return new Date();
      },
    },
    time: String,
    end: [String, Number, Date],
    cron: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scheduledDate: this.date,
      recurrenceTypes,
      dialog: false,
      previousPattern: "",
      count: 1,
      days,
      selectedRecurrence: "day",
      selectedDays: [this.date.getDay()],
      selectedMonthPattern: 1,
      selectedEnd: 0,
      occurrences: 5,
      dateMenu: false,
      endDate: "",
    };
  },
  computed: {
    cronExpression() {
      if (!this.cron) {
        return this.cron;
      }

      const cronArray = this.cron.split(" ");
      cronArray[minute] = cronArray[hour] = "*";
      const expression = cronArray.join(" ");
      this.setControlValues(expression);
      return expression;
    },
    selectedPattern: {
      get() {
        if (!this.cronExpression) {
          return this.repeatTypes[0];
        }

        const type = this.getSelectedType();
        return type || this.repeatTypes[0]; //this have to be a new option in v-autocomplete
      },
      set(option) {
        if (option.value != 7) {
          const schedule = defaultsDeep({}, { cron: option.cron });
          this.$emit("update", schedule);
        } else {
          this.dialog = true;
        }
      },
    },
    repeatTypes() {
      return repeatTypes(this.scheduledDate);
    },
    monthlyPatterns() {
      return monthlyPatterns(this.scheduledDate);
    },
  },
  watch: {
    date(value) {
      this.scheduledDate = value;
    },
  },
  methods: {
    createExpression() {
      this.dialog = false;
      const expression = "* * * * *";
      let expArray = expression.split(" ");

      if (this.selectedRecurrence == "day") {
        expArray[day_of_month] = `*/${this.count}`;
      } else if (this.selectedRecurrence == "week") {
        expArray[day_of_week] = this.selectedDays.join(",");
      } else if (this.selectedRecurrence == "month") {
        const pattern = this.repeatTypes.find(({ value }) => value == 4);
        expArray = pattern.cron.split(" ");
        expArray[month] = `*/${this.count}`;
      } else if (this.selectedRecurrence == "year") {
        expArray[month] = `*/${this.count * 12}`;
      }
      const newExpression = expArray.join(" ");
      const schedule = defaultsDeep(
        {},
        { cron: newExpression },
        { end: this.getEndValue() }
      );
      this.$emit("update", schedule);
    },
    getSelectedType() {
      const type = this.repeatTypes.find(({ regEx }) => {
        if (!regEx) {
          return false;
        }
        return regEx.test(this.cronExpression);
      });
      return type;
    },
    setControlValues(expression) {
      const expArray = expression.split(" ");
      if (isDayRecurrence(expression)) {
        const count = expArray[day_of_month].split("/")[1];
        this.selectedRecurrence = "day";
        this.count = count;
      } else if (isMonthRecurrence(expression)) {
        const count = expArray[month].split("/")[1];
        this.selectedRecurrence = "month";
        this.count = count;
      } else if (isYearRecurrence(expression)) {
        const date = expArray[day_of_month];
        this.selectedRecurrence = "year";
        if (date != "*") {
          this.count = 1;
        } else {
          const step = expArray[month].split("/")[1];
          this.count = step / 12;
        }
      }
      this.setEndValues();
    },
    setEndValues() {
      if (isDate(this.end)) {
        this.selectedEnd = 1;
        this.endDate = format(this.end, "yyyy-MM-dd");
      } else if (typeof this.end == "number") {
        this.selectedEnd = 2;
        this.occurrences = this.end;
      } else {
        this.selectedEnd = 0;
      }
    },
    getEndValue() {
      switch (this.selectedEnd) {
        case 0:
          return "";
        case 1:
          return new Date(this.endDate).toISOString();
        case 2:
          return this.occurrences;
      }
    },
  },
};
</script>
<style scoped>
.label {
  text-justify: center;
  font-size: medium;
}
</style>
