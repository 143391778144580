export default {
  label: "Spacer",
  type: "spacer",
  description: "Add spaces in a page",
  properties: ["type", "display", "block"],
  templates: [
    {
      id: "default",
      description: "Default Spacer",
      label: "Default",
      value: {
        type: "spacer",
      },
    },
  ],
};
