export default {
  label: "Captcha",
  type: "captcha",
  description: "Displays the captcha field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Captcha field.",
      label: "Default",
      value: {
        label: "Captcha",
        type: "captcha",
      },
    },
  ],
};
