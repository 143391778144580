import { subDays, addDays } from "date-fns";

const futureDate = addDays(new Date(), 2).toISOString();
const pastDate = subDays(new Date(), 2).toISOString();

const debug = require("debug")("atman.components.currency"); // eslint-disable-line

const costFieldExample1 = {
  unit_price: {
    label: "Unit Price",
    value: {
      amount: 2000,
      currency: "INR",
    },
  },
  deductions: [
    {
      label: "Discount",
      value: {
        amount: 10,
        currency: "INR",
      },
    },
  ],
  additions: [
    {
      label: "GST. 18% of cost",
      value: {
        amount: 100,
        currency: "INR",
      },
    },
    {
      label: "Service Tax. 10% of cost",
      value: {
        amount: 200,
        currency: "INR",
      },
    },
  ],
};

export default {
  label: "Cost",
  type: "cost",
  description: "Displays a cost field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "derived_cost",
      description: "Cost which uses some algorithm",
      label: "Items Cost",
      variables: {
        data: {
          unit_cost: { amount: 72, currency: "INR" },
          quantity: 10,
        },
      },
      value: {
        name: "display_currency",
        label: "Cost",
        type: "cost",
        mode: "display",
        rule: {
          operation: "ITEM_COST",
          cost_per_item: "unit_cost",
          quantity: "quantity",
        },
      },
    },
    {
      id: "input_currency",
      description: "Cost as drop-in replacement for currency",
      label: "Currency Drop in (input)",
      variables: {
        data: {
          input_currency: { amount: 72, currency: "INR" },
        },
      },
      value: {
        name: "input_currency",
        label: "Cost",
        type: "cost",
        mode: "input",
      },
    },
    {
      id: "input_breakdown",
      description:
        "A cost field allows users to specify additional details like discounts and taxes to add to the unit price",
      label: "Input (with Breakdown)",
      variables: {
        data: {
          input_breakdown: null,
        },
      },
      value: {
        display: {
          attributes: {
            show_breakdown: true,
          },
        },
        name: "input_breakdown",
        label: "Cost",
        type: "cost",
        mode: "input",
      },
    },
    {
      id: "default",
      description:
        "In its simplest form, a cost field is a drop-in replacement for Currency with no additional functionality",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        label: "Cost",
        type: "cost",
        mode: "input",
      },
    },

    {
      id: "display",
      description: "The default Cost field.",
      label: "Display",
      variables: {
        data: {
          display: costFieldExample1,
        },
      },
      value: {
        name: "display",
        label: "Cost",
        type: "cost",
        mode: "display",
      },
    },
    {
      id: "display_details",
      description: "Details display Cost field.",
      label: "Display Details",
      variables: {
        data: {
          display_details: costFieldExample1,
        },
      },
      value: {
        name: "display_details",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            show_breakdown: true,
          },
        },
      },
    },
    {
      id: "display_details_breakdown",
      description: "Details display Cost field (breakdown)",
      label: "Display Details (Expanded)",
      variables: {
        data: {
          display_details_breakdown: costFieldExample1,
        },
      },
      value: {
        name: "display_details_breakdown",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            show_breakdown: true,
            layout: "breakdown",
          },
        },
      },
    },
    {
      id: "display_details_discount",
      description: "Details display Cost field (discount)",
      label: "Display (Discount)",
      variables: {
        data: {
          display_details_discount: costFieldExample1,
        },
      },
      value: {
        name: "display_details_discount",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            layout: "discount",
          },
        },
      },
    },
    {
      id: "display_details_timebound_discount",
      label: "Timebound discount",
      variables: {
        data: {
          display_details_timebound_discount: {
            unit_price: {
              label: "Unit Price",
              value: {
                amount: 2000,
                currency: "INR",
              },
            },
            deductions: [
              {
                label: "Discount",
                valid_until: `${futureDate}`,
                value: {
                  amount: 1000,
                  currency: "INR",
                },
              },
            ],
          },
        },
      },
      value: {
        name: "display_details_timebound_discount",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            layout: "discount",
          },
        },
      },
    },
    {
      id: "display_details_elapsed_discount",
      label: "Timebound discount (Elapsed)",
      variables: {
        data: {
          display_details_elapsed_discount: {
            unit_price: {
              label: "Unit Price",
              value: {
                amount: 2000,
                currency: "INR",
              },
            },
            deductions: [
              {
                label: "Discount",
                valid_until: `${pastDate}`,
                value: {
                  amount: 1000,
                  currency: "INR",
                },
              },
            ],
          },
        },
      },
      value: {
        name: "display_details_elapsed_discount",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            layout: "discount",
          },
        },
      },
    },
    {
      id: "display_details_no_discount",
      description: "Discounts layout is ignored if no discounts are present",
      label: "Display (Invalid Discount)",
      variables: {
        data: {
          display_details_no_discount: {
            unit_price: {
              label: "Unit Price",
              value: {
                amount: 2000,
                currency: "INR",
              },
            },
            deductions: [
              {
                label: "Discount",
                value: {
                  amount: 0,
                  currency: "INR",
                },
              },
            ],
          },
        },
      },
      value: {
        name: "display_details_no_discount",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            layout: "discount",
          },
        },
      },
    },
    {
      id: "display_currency",
      description: "Cost as drop-in replacement for currency",
      label: "Currency Drop in (display)",
      variables: {
        data: {
          display_currency: { amount: 72, currency: "INR" },
        },
      },
      value: {
        name: "display_currency",
        label: "Cost",
        type: "cost",
        mode: "display",
      },
    },
    {
      id: "display_currency_details",
      description: "Cost as drop-in replacement for currency",
      label: "Currency Drop in (display) details",
      variables: {
        data: {
          display_currency_details: { amount: 72, currency: "INR" },
        },
      },
      value: {
        name: "display_currency_details",
        label: "Cost",
        type: "cost",
        mode: "display",
        display: {
          attributes: {
            show_breakdown: true,
          },
        },
      },
    },
    {
      id: "input_currency_details",
      description: "Cost as drop-in replacement for currency",
      label: "Currency Drop in (input) Details",
      variables: {
        data: {
          input_currency: { amount: 72, currency: "INR" },
        },
      },
      value: {
        name: "input_currency",
        label: "Cost",
        type: "cost",
        mode: "input",
        display: {
          attributes: {
            show_breakdown: true,
          },
        },
      },
    },
    {
      id: "mandatory_currency",
      description: "Mandatory cost field",
      label: "Mandatory",
      variables: {
        data: {
          mandatory_currency: { amount: 72, currency: "INR" },
        },
      },
      value: {
        name: "mandatory_currency",
        label: "Cost",
        type: "cost",
        mode: "input",

        mandatory: true,
      },
    },
  ],
  attributes: [
    {
      name: "show_breakdown",
      type: "switch",
      hint: "Display breakdown",
      value: false,
    },
    {
      name: "layout",
      type: "select",
      value: "",
      hint: "Select layout type",
      options: ["discount", "breakdown"],
    },
  ],
};
