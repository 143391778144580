<template>
  <v-col cols="12" md="3" :class="backgroundColor" class="pt-0">
    <v-autocomplete
      :label="label"
      v-if="isMobile"
      v-model="field"
      :items="dataList"
      item-text="label"
      item-value="id"
      @change="triggerEvent()"
      @blur="triggerEvent()"
    ></v-autocomplete>
    <v-list
      dense
      v-if="!isMobile"
      class="transparent"
      v-intersect.quiet="goToField"
      v-resize="goToField"
    >
      <list-title v-if="label" :title="label" />
      <v-list-item-group
        v-model="field"
        color="primary"
        :style="`max-height: ${listHeight}vh`"
        class="overflow-y-auto behavior-field-list"
      >
        <v-list-item
          :behavior-field="item.id"
          :behavior-field-label="item.label"
          v-for="(item, i) in dataList"
          aut-list-field-item
          :value="item.id"
          :key="`${item.id}-${i}`"
          @click="triggerEvent(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title :aut-field-list-item="item.id">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    item.label ? item.label : ""
                  }}</span>
                </template>
                {{ item.label }}
              </v-tooltip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="has_slot">
          <v-list-item-content>
            <slot></slot>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-col>
</template>
<script>
import { sortBy } from "lodash";
import { getBackgroundColor } from "@/util";
import ListTitle from "@/components/pageElements/ListTitle/ListTitle.vue";
const debug = require("debug")("atman.components.list_field");
export default {
  components: { ListTitle },
  name: "ListField",
  props: {
    has_slot: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    label: {
      type: String,
    },
    list: {
      type: Array,
    },
    value: {
      type: String,
    },
    max_height: {
      type: [Number, String],
      default: () => {
        return 50;
      },
    },
  },
  watch: {
    value() {
      this.goToField();
    },
    list() {
      this.goToField();
    },
  },
  data() {
    let field = this.value || (this.dataList && this.dataList[0]?.id);
    return {
      field,
    };
  },
  computed: {
    backgroundColor() {
      return getBackgroundColor();
    },
    dataList() {
      return sortBy(
        this.list.map((item) => {
          if (typeof item == "string") {
            return { id: item, label: item };
          }
          item.id = item.id || item.name;
          item.label = item.label || item.name;
          return item;
        }),
        ["label"]
      );
    },
    isMobile() {
      return this.isSmallScreen;
    },
    listHeight() {
      const height = parseInt(this.max_height, 10);
      return isNaN(height) ? 50 : height;
    },
  },
  mounted() {
    this.goToField();
  },
  methods: {
    goToField() {
      this.field = this.value;
      if (!this.field) {
        return;
      }
      const container = ".behavior-field-list";
      if (!this.$el.querySelector(container)) {
        return;
      }
      const target = `[behavior-field="${this.field}"]`;
      if (!this.$el.querySelector(target)) {
        return;
      }
      debug(`Attempting to scroll`);
      try {
        this.$vuetify.goTo(target, { container });
      } catch (e) {
        debug("unable to navigate", e);
      }
    },
    triggerEvent(item) {
      item = item || this.field;
      debug(`Emitting`, item);
      this.$emit("select", item);
    },
  },
};
</script>
