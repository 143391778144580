export default {
  label: "Event Scheduler",
  type: "event_scheduler",
  description: "Displays a event scheduler field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description: "The default event scheduler field.",
      label: "Default",
      value: {
        name: "schedule",
        label: "Schedule",
        type: "event_scheduler",
      },
    },
  ],
};
