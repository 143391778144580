<template>
  <v-row class="justify-center">
    <v-col cols="12" md="5">
      <v-data-table
        :items="leftItems"
        @input="leftSelected = $event"
        v-bind="tableAttributes"
      >
      </v-data-table>
    </v-col>
    <v-col cols="12" md="1" class="d-flex flex-md-column justify-end">
      <v-spacer />
      <v-btn
        icon
        @click="copyItems(leftSelected, 'leftItems', 'rightItems')"
        :disabled="!haveLeftItemsSelected"
      >
        <v-icon>
          {{
            isTabletOrLarger ? "mdi-arrow-right-bold" : "mdi-arrow-down-bold"
          }}</v-icon
        >
      </v-btn>
      <v-btn
        icon
        @click="copyItems(rightSelected, 'rightItems', 'leftItems')"
        :disabled="!haveRightItemsSelected"
      >
        <v-icon>
          {{
            isTabletOrLarger ? "mdi-arrow-left-bold" : "mdi-arrow-up-bold"
          }}</v-icon
        >
      </v-btn>
      <v-spacer />
    </v-col>
    <v-col cols="12" md="5">
      <v-data-table
        :items="rightItems"
        @input="rightSelected = $event"
        v-bind="tableAttributes"
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { defaultsDeep, differenceWith, isEqual } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.filtered_list");
debug("filtered_list");
export default {
  name: "FilteredList",
  components: {},
  mixins: [fieldMixin],
  data() {
    return {
      leftItems: [],
      rightItems: [],
      leftSelected: [],
      rightSelected: [],
      refreshListKey: 1,
    };
  },
  mounted() {
    this.fetchContent();
  },
  computed: {
    tableAttributes() {
      const result = defaultsDeep(
        { key: this.refreshListKey, "show-select": true },
        this.displayAttributes,
        {
          headers: [
            {
              text: "Name",
              value: "name",
            },
          ],
          "items-per-page": 10,
          height: "250px",
          class: "elevation-1",
        }
      );
      result.headers.forEach((header) => {
        Object.assign(header, {
          align: "start",
          sortable: false,
        });
      });
      return result;
    },
    haveLeftItemsSelected() {
      const result = this.leftSelected.length > 0;
      debug(`haveLeftItemsSelected`, result);
      return result;
    },
    haveRightItemsSelected() {
      const result = this.rightSelected.length > 0;
      debug(`haveRightItemsSelected`, result);
      return result;
    },
  },
  methods: {
    fetchItems(side) {
      const component = this;
      return component.$store.dispatch(`${component.context}/getSeedData`, {
        definition: component.value[side],
      });
    },
    updateValue() {
      this.$set(
        this.value,
        "value",
        this.rightItems.map(({ id }) => id)
      );
    },
    async fetchContent() {
      const component = this;
      const unfilteredList = await component.fetchItems("left");
      const rightList = (this.rightItems = await component.fetchItems("right"));
      const leftList = (this.leftItems = differenceWith(
        unfilteredList,
        rightList,
        isEqual
      ));
      debug(leftList, rightList);
      this.updateValue();
    },
    copyItems(itemsToMove, sourceName, targetName) {
      this[targetName] = [...this[targetName], ...itemsToMove];
      this[sourceName] = differenceWith(this[sourceName], itemsToMove, isEqual);
      this.leftSelected = [];
      this.rightSelected = [];
      this.refreshListKey++;
      this.updateValue();
    },
  },
};
</script>
