import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
const debug = require("debug")("atman.components.duration"); // eslint-disable-line
export default {
  label: "Duration",
  type: "duration",
  description: "Displays a duration",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  templates: [
    {
      id: "default",
      description: "The default Duration Field",
      label: "Default",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        label: "Duration",
        type: "duration",
      },
    },
  ],
  attributes: [no_value_text, no_value_behavior],
};
