<template>
  <v-sheet
    aut-drag-drop
    width="100%"
    height="100"
    class="pa-2 behaviour_file_box d-flex flex-column justify-center align-center"
    :class="{ behaviour_file_box_over: dragover }"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
    @dragend.prevent="dragover = false"
    @dragover.prevent="dragover = true"
    @drop.prevent="uploadDrop"
  >
    <!-- @click:close="removeFile(i)"
      close -->
    <v-chip v-for="(uploadedFile, i) in uploadedFiles" :key="i">
      {{ uploadedFile.name }}
    </v-chip>
    <label class="pt-2" v-if="!isUploadButton">
      <v-chip
        outlined
        small
        label
        color="primary"
        @click="$refs.fileinput.click()"
      >
        {{ !hasUploaded ? "Choose a file" : "Choose another file" }}
      </v-chip>
      <span v-if="!hasUploaded"> or drop it here</span>
    </label>
    <v-btn
      v-else
      class="font-weight-bold"
      @click="$refs.fileinput.click()"
      :text="true"
      color="secondary"
      outlined
    >
      <v-icon class="primary--text pr-2">{{ iconDisplay }}</v-icon>
      {{ labelDisplay }}
    </v-btn>
    <input
      type="file"
      ref="fileinput"
      :accept="fileFormats"
      :multiple="isMultiple"
      @click.stop
      style="display: none"
      @change="uploadInput"
    />
  </v-sheet>
</template>
<script>
const debug = require("debug")("atman.field.file.drag_and_drop"); // eslint-disable-line
import fileMixin from "./mixin";
export default {
  name: "DragAndDrop",
  mixins: [fileMixin],
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
    };
  },
  props: {
    fieldValue: {},
    value: {
      type: Object,
    },
    fileFormats: {
      type: String,
    },
  },
  computed: {
    hasUploaded() {
      return this.uploadedFiles.length > 0;
    },
    isUploadButton() {
      return this.value?.display?.attributes?.button_type == "upload";
    },
    labelDisplay() {
      return this.value?.label || "Upload Files";
    },
    iconDisplay() {
      return this.value?.display?.icon || "mdi-file-image-outline";
    },
  },
  methods: {
    effectiveFileLink(uploadedFile) {
      return uploadedFile?._file || uploadedFile?.link;
    },
    fileDisplayName(uploadedFile) {
      return uploadedFile.id;
    },
    emitEvent() {
      const payload = this.isMultiple
        ? this.uploadedFiles
        : this.uploadedFiles[0];
      debug(`in uploadDrop emit`, payload);
      this.$emit("upload", payload);
    },
    uploadDrop(e) {
      this.dragover = false;
      const files = e?.dataTransfer?.files;
      if (files.length > 0) {
        this.uploadedFiles = files;
        this.emitEvent();
      }
    },
    removeFile(i) {
      this.uploadedFiles.splice(i, 1);
      this.emitEvent();
    },
    uploadInput(e) {
      const files = e?.target?.files || [];
      if (files.length > 0) {
        this.uploadedFiles = files;
        this.emitEvent();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_file_box {
  outline: 2px dashed var(--v-primary-base);
  outline-offset: -10px;
  &.behaviour_file_box_over {
    background-color: var(--v-primary-lighten2);
  }
}
</style>
