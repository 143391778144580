import {
  no_value_text,
  no_value_behavior,
  behavior_centered,
  behavior_size,
} from "@/components/fields/attributes";
export default {
  type: "label",
  label: "Label",
  description: "Displays a label",
  properties: ["name", "label", "type", "display", "block"],
  templates: [
    {
      id: "default",
      label: "Default",
      description: "The default Label field.",
      variables: {
        data: {
          default: "Dolor proident esse",
        },
      },
      value: {
        name: "default",
        label: "Label",
        type: "label",
      },
    },
    {
      id: "hidden_label",
      description: "Text field with hidden label",
      label: "Hidden Label",
      variables: {
        data: {
          hidden_label: "Dolor proident esse",
        },
      },
      value: {
        name: "hidden_label",
        label: {
          text: "Title",
          display: false,
        },
        type: "label",
      },
    },
    {
      id: "heading1",
      label: "Heading 1",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 1",
      variables: {
        data: {
          heading1: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading1",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h1",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading2",
      label: "Heading 2",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 2",
      variables: {
        data: {
          heading2: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading2",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h2",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading3",
      label: "Heading 3",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 3",
      variables: {
        data: {
          heading3: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading3",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h3",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading4",
      label: "Heading 4",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 4",
      variables: {
        data: {
          heading4: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading4",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h4",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading5",
      label: "Heading 5",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 5",
      variables: {
        data: {
          heading5: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading5",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h5",
          },
        },
        mode: "display",
      },
    },
    {
      id: "heading6",
      label: "Heading 6",
      keyword: ["styles", "tutorial"],
      description: "Text field styled as a heading 6",
      variables: {
        data: {
          heading6: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "heading6",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "h6",
          },
        },
        mode: "display",
      },
    },
    {
      id: "subtitle",
      label: "Subtitle",
      keyword: ["styles", "tutorial"],
      description: `Use <code>"mode":"subtitle-1"</code> or <code>"mode":"subtitle-2"</code> to use subtitle styling. 
      The following is an example of <code>subtitle-1</code>.`,
      variables: {
        data: {
          subtitle: "Irure esse exercitation deserunt officia pariatur.",
        },
      },
      value: {
        name: "subtitle",
        label: "",
        type: "label",
        display: {
          attributes: {
            behavior_size: "subtitle-1",
          },
        },
        mode: "display",
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    behavior_centered,
    behavior_size,
  ],
};
