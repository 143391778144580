<template>
  <v-row dense justify-start>
    <v-col
      v-for="(theme, i) in themes"
      :key="i"
      class="pa-2"
      cols="12"
      lg="3"
      md="4"
    >
      <div v-if="mode == 'domain'">
        <v-checkbox
          dense
          :label="theme.label"
          :value="theme.name"
          aut-theme-checkbox
          v-model="selectedThemes"
        ></v-checkbox>
      </div>
      <span v-else>{{ theme.label }}</span>
      <v-hover>
        <template v-slot:default="{ hover }">
          <v-card
            width="200"
            height="150"
            outlined
            :aut-theme="theme.name"
            class="rounded elevation-2"
            @click="updateTheme(theme)"
          >
            <v-container class="container">
              <v-row dense>
                <v-col
                  :style="getStyle(theme, 'toolbar-color')"
                  :class="theme.isDark ? 'dark' : 'light'"
                ></v-col>
              </v-row>
              <v-row class="body">
                <v-col
                  cols="12"
                  lg="3"
                  class="sidebar pa-1"
                  :style="getStyle(theme, 'sidebar-color')"
                  :class="theme.isDark ? 'dark' : 'light'"
                >
                  <v-skeleton-loader
                    :boilerplate="true"
                    :dark="theme.isDark"
                    :light="!theme.isDark"
                    type="text@6"
                    width="80%"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="9"
                  class="mainBodyPreview"
                  :class="theme.isDark && 'dark'"
                >
                  <v-skeleton-loader
                    :dark="theme.isDark"
                    :light="!theme.isDark"
                    :boilerplate="true"
                    type="sentences@2"
                    width="80%"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-fade-transition>
              <v-overlay v-if="hover" absolute color="primary">
                <v-btn @click.stop="updateTheme(theme)">
                  {{ theme.name != activeTheme ? "Use Theme" : "Remove" }}
                </v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
</template>
<script>
import { clone } from "@/util";
export default {
  name: "ApplicationThemes",
  data() {
    return {
      selectedThemes: clone(this.allowed || []),
    };
  },
  props: {
    allowed: {
      type: Array,
    },
    themes: {
      type: Array,
    },
    mode: {
      type: String,
    },
    activeTheme: {
      type: String,
    },
  },
  watch: {
    selectedThemes() {
      this.$emit("themes", this.selectedThemes);
    },
  },
  methods: {
    getStyle(theme, colorName) {
      return `background: ${theme.colors[colorName]}`;
    },
    updateTheme(theme) {
      if (theme.name == this.activeTheme) {
        this.$emit("change", "");
      } else {
        this.$emit("change", theme);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: inherit;
  .body {
    height: 100%;
    .sidebar {
      border-right: 1px solid #eeeeee;
      .menus {
        background: rgba(0, 0, 0, 0.219);
        height: 5%;
      }
    }
    .mainBodyPreview {
      background-color: #eeeeee !important;
      border-color: #eeeeee !important;
      &.dark {
        background-color: map-get($material-dark, "cards") !important;
      }
    }
  }
}
.dark {
  background: map-get($material-dark, "cards");
}
.light {
  background: map-get($material-light, "cards");
}
</style>
