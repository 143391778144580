<template>
  <div @dblclick.stop="displayDialog = true">
    <component
      v-if="currentComponent"
      v-bind:is="currentComponent"
      :block.sync="block"
      :options="options"
      @select="$emit('select')"
      @update="$emit('update')"
      @linkingStart="$emit('linking-start', $event)"
      @linkingStop="$emit('linking-stop', $event)"
      @linkingBreak="$emit('linking-break', $event)"
      @delete="$emit('delete')"
      @display-node-dialog="displayDialog = true"
    ></component>
    <WorkflowDialog
      v-if="displayDialog"
      @close="displayDialog = false"
      :block.sync="blockDefinition"
      @update:block="updateNodeData"
    />
  </div>
</template>

<script>
import { clone } from "@/util.js";
import RectangularNode from "./RectangularNode";
import WorkflowDialog from "./WorkflowDialog/WorkflowDialog.vue";
const debug = require("debug")("atman.components.workflow_node");
export default {
  name: "WorkflowNode",
  components: {
    WorkflowDialog,
  },
  props: {
    block: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  watch: {
    block() {
      this.blockDefinition = clone(this.block);
    },
  },
  data() {
    return {
      blockDefinition: clone(this.block),
      displayDialog: false,
    };
  },

  created() {
    debug("in workflow node", this.block);
    const type = this?.block?.display?.type || "rectangle";
    switch (type) {
      case "rectangle":
        this.currentComponent = RectangularNode;
        return;
      case "circle":
        this.currentComponent = RectangularNode;
        return;
    }
  },
  methods: {
    updateNodeData() {
      this.displayDialog = false;
      this.$emit("update:block", this.blockDefinition);
      this.$emit("update");
    },
  },
};
</script>
