<template>
  <div class="relative" aut-api-designer>
    <EditorActions
      :actions="designActions"
      @remove_api="clearAPI"
      @edit_application="goToApplicationEdit"
    />
    <v-row dense>
      <v-col cols="12" v-if="message">
        <small>{{ message }}</small>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" lg="2" v-if="types.length > 1">
        <v-autocomplete
          aut-api-request-type
          dense
          small
          required
          :items="types"
          label="Type"
          hint="Type of request"
          persistent-hint
          item-value="value"
          item-text="label"
          @keydown.enter.prevent="dummyMethod"
          v-model="apiDefinition.type"
          @change="updateDefinition"
        ></v-autocomplete>
        <v-switch
          label="Is an update"
          dense
          v-if="apiDefinition.type == 'post'"
          v-model="isUpdate"
        />
      </v-col>

      <v-col :cols="types.length > 1 ? 10 : 12" v-if="urlDefinition">
        <LookUpField v-model="urlDefinition" @update="updateLookup" />
      </v-col>
      <v-col cols="12" lg="10">
        <v-text-field
          v-if="apiDefinition.type == 'event'"
          aut-action-event-name
          dense
          name="event_name"
          label="Event Name"
          v-model="apiDefinition.name"
          required
          @change="updateDefinition"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-if="label"
          aut-api-label
          placeholder="Submit"
          dense
          name="label"
          label="Button Label"
          v-model="apiDefinition.label"
          required
          @change="updateDefinition"
        ></v-text-field>
      </v-col>
    </v-row>
    <div v-if="apiDefinition.success">
      <v-row dense v-if="!internal">
        <v-col cols="12">
          <v-textarea
            :rows="1"
            auto-grow
            aut-api-success-message
            placeholder="Success Message"
            dense
            name="success_message"
            label="Success Message"
            v-model="apiDefinition.success.message"
            required
            @change="updateDefinition"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>

    <v-row dense v-if="showRefresh">
      <v-col cols="12">
        <v-text-field
          aut-api-refresh-interval
          placeholder="Refresh Interval"
          dense
          type="number"
          name="refresh_interval"
          label="Refresh Interval"
          hint="The call will be automatically repeated every n seconds"
          persistent-hint
          v-model="apiDefinition.refresh_interval"
          :rules="refreshInterval"
          @change="updateDefinition"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  goToApplicationEdit,
  clone,
  actionTypes,
  requestTypes,
  submitTypes,
} from "@/util.js";

import { forOwn, defaultsDeep } from "lodash";
import EditorActions from "@/components/editor/EditorActions";
import LookUpField from "@/components/fields/LookupField";
const debug = require("debug")("atman.components.api_designer");
debug(`url_designer`);
const designActions = [
  {
    id: "remove_api",
    label: "Clear API details",
    confirmation: "Are you sure you want to continue?",
    icon: "mdi-close-octagon",
    event: "remove_api",
    param: "",
  },
];
export default {
  name: "APIDesigner",
  components: {
    LookUpField: LookUpField.control,
    EditorActions,
  },
  computed: {
    showRefresh() {
      if (!this.isFeatureEnabled("refresh_interval.enabled")) {
        return false;
      }
      return this.apiDefinition.type == "get" && !this.internal;
    },
    types() {
      switch (this.type) {
        case "data":
          return requestTypes.filter(({ value }) => value == "get");
        case "submit":
          return submitTypes;
        case "action":
          return actionTypes;
        case "upload":
          return submitTypes.filter(({ value }) => value == "post");
        default:
          return [];
      }
    },
    urlDefinition() {
      const component = this;
      const apiDefinition = component.apiDefinition;
      if (
        !apiDefinition.type ||
        !component.type ||
        apiDefinition.type == "event"
      ) {
        return null;
      }
      const result = {
        name: "url",
        value: apiDefinition.url,
        type: "lookup",
        label: "URL",
        options: {
          apis: {
            data: {
              url: `${component.$route.path}/${component.type}/${apiDefinition.type}`,
            },
          },
        },
      };
      return result;
    },
    message() {
      switch (this.type) {
        case "data":
          return `Configure the API to be invoked to fetch data to be displayed`;
        case "submit":
          return `Configure the API to be invoked when data is submitted`;
        case "upload":
          return `Configure the API to be invoked when file is submitted`;
        /* case "action":
          return null;
        */
        default:
          return null;
      }
    },
  },
  props: {
    definition: {
      type: Object,
    },
    internal: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const types = this?.types;
    let type = "navigation";
    if (types && types.length == 1) {
      type = types[0];
    }
    const apiDefinition = defaultsDeep(clone(this.definition || {}), {
      url: "",
      type,
      success: {
        message: "",
      },
    });
    return {
      isUpdate: apiDefinition?.params?.action == "update_fields",
      apiDefinition,
      refreshInterval: [
        (val) => val > 0 || "Please enter a number larger than 0",
      ],
    };
  },
  created() {
    this.designActions = designActions;
    this.goToApplicationEdit = goToApplicationEdit;
  },
  watch: {
    isUpdate() {
      let api = clone(this.apiDefinition);
      if (this.isUpdate) {
        api = defaultsDeep(
          {
            params: {
              action: "update_fields",
            },
          },
          api
        );
      } else if (api.params.action == "update_fields") {
        delete api.params.action;
      }
      this.$set(this, "apiDefinition", api);
      this.updateDefinition();
    },
  },
  methods: {
    clearAPI() {
      const api = clone(this.apiDefinition);
      forOwn(api, (val, key) => {
        if (typeof val == "string") {
          api[key] = "";
        }
      });
      api.success = {};
      this.$set(this, "apiDefinition", api);
      this.updateDefinition();
    },
    dummyMethod() {},
    updateDefinition() {
      const output = clone(this.apiDefinition);
      const noMessage = !output?.success?.message;
      const noPostAction =
        !output?.success?.action?.url && !output?.success?.action?.type;
      if (noMessage && noPostAction) {
        delete output.success;
      }
      debug(`Emitting`, output);
      this.$emit("update:definition", output);
    },
    updateLookup(value) {
      this.apiDefinition.url = value;
      this.updateDefinition();
    },
  },
};
</script>
