<template>
  <v-container fill-height>
    <div class="behaviour_centered text-center">
      <v-icon color="green" x-large>mdi-palm-tree</v-icon>
      <p>Coming soon...</p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "UnderConstruction",
};
</script>
