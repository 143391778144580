import { subDays, addDays, subHours, addHours } from "date-fns";

const debug = require("debug")("atman.components.events"); //eslint-disable-line

export default {
  label: "Events",
  type: "events",
  description: "Displays an Events field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: `The default Events field`,
      label: "Default",
      variables: {
        data: {
          default: [
            {
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              name: "demo",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
            {
              name: "Updates",
              start: addHours(addDays(new Date(), 5), 1),
              end: addHours(addDays(new Date(), 5), 2),
            },
          ],
        },
      },
      value: {
        name: "default",
        label: "Events",
        type: "events",
      },
    },
    {
      id: "limited",
      description: `Use attribute <code>max</code> to set maximum number of events to display`,
      label: "limited",
      variables: {
        data: {
          limited: [
            {
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              name: "demo",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
            {
              name: "Updates",
              start: addHours(addDays(new Date(), 5), 1),
              end: addHours(addDays(new Date(), 5), 2),
            },
            {
              name: "demo meeting",
              start: subHours(addDays(new Date(), 2), 6),
              end: subHours(addDays(new Date(), 2), 4),
            },
          ],
        },
      },
      value: {
        name: "limited",
        label: "Events",
        type: "events",
        display: {
          attributes: {
            max: 2,
          },
        },
      },
    },
    {
      id: "short",
      description: `Use attribute <code>format:"short"</code> to display events in short format`,
      label: "Short",
      variables: {
        data: {
          short: [
            {
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
              details: "The daily meeting",
            },
            {
              name: "Updates",
              start: addHours(addDays(new Date(), 5), 1),
              end: addHours(addDays(new Date(), 5), 2),
            },
          ],
        },
      },
      value: {
        name: "short",
        label: "Events",
        type: "events",
        display: {
          attributes: {
            format: "short",
            item: {
              small: true,
            },
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "max",
      type: "text",
      hint: "Set display limit",
      value: "",
    },
    {
      name: "format",
      type: "select",
      hint: "Controls format",
      value: "",
      options: ["short"],
    },
  ],
};
