import {
  color,
  disabled,
  elevation,
  min_height,
  min_width,
  max_height,
  max_width,
  customiseAttribute,
  rounded,
  shaped,
  large,
  small,
  xlarge,
  xsmall,
  ripple,
  icon_classes,
} from "@/components/fields/attributes.js";

export default {
  type: "action",
  label: "Action",
  description: "Displays an action",
  properties: ["name", "label", "mode", "disabled", "type", "display", "block"],
  templates: [
    {
      id: "default",
      description: "The default Action field.",
      label: "Default",
      value: {
        label: "Action",
        name: "action_1",
        type: "action",
        value: {},
      },
    },
    {
      id: "action_icon",
      keyword: ["tutorial"],
      description:
        'Use <code>"icon": "mdi-play"</code> without a label to display an icon instead of text.',
      label: "Action as Icon",
      value: {
        icon: "mdi-play",
        name: "action_1",
        type: "action",
        value: {},
      },
    },
    {
      id: "action_icon_label",
      keyword: ["tutorial"],
      description:
        'Use <code>"icon": "mdi-play"</code> and a label to display an icon and the label',
      label: "Action with icon and label",
      value: {
        icon: "mdi-play",
        name: "action_1",
        label: "Play",
        type: "action",
        value: {},
      },
    },
    {
      id: "get_list",
      keyword: ["tutorial"],
      description: 'Use <code>"type": "get"</code> to fetch data.',
      label: "Fetch a list from the server",
      value: {
        label: "Fetch",
        name: "fetch",
        type: "action",
        value: {
          type: "get",
          url: "{_context->path}",
        },
      },
    },
    {
      id: "create_entity",
      description: 'Use <code>"type": "post"</code> to create something.',
      label: "Create something",
      value: {
        label: "Create",
        name: "create",
        type: "action",
        value: {
          type: "post",
          url: "{_context->path}",
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Creation successfull!",
          },
        },
      },
    },
    {
      id: "update_entity",
      keyword: ["tutorial"],
      description:
        'Use <code>"action": "update_fields"</code> value to update existing data.',
      label: "Update something",
      value: {
        label: "Update",
        name: "update",
        type: "action",
        value: {
          params: {
            action: "update_fields",
          },
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Update successfull!",
          },
          type: "post",
          url: "{_context->path}",
        },
      },
    },
    {
      id: "add_item",
      keyword: ["tutorial"],
      description: 'Use <code>"fields"</code> to add in items',
      label: "Add Item",
      variables: {
        data: {
          quantity: "20",
          color: "black",
          id: "20210917083806110850",
        },
      },
      value: {
        label: "Add Item",
        name: "add_item",
        type: "action",
        value: {
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Added successfull!",
          },
          params: {
            action: "update_fields",
          },
          type: "post",
          url: "{_context->path}/{id}",
        },
        fields: ["quantity", "color"],
      },
    },
    {
      id: "mark_as_delete",
      label: "Delete",
      description:
        "This example has: <ul>\
<li>a configurable confirmation message.</li>\
<li>a configurable success message.</li>\
<li>a configurable post action to perform after this action succeeds.</li>\
<ul>",
      value: {
        label: "Delete",
        name: "delete_action",
        type: "action",
        confirmation: "This is an irreversible action. Are you sure?",
        value: {
          type: "post",
          params: {
            action: "mark_for_deletion",
          },
          url: "{_context->path}",
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Deletion successful!",
          },
        },
      },
    },
    {
      id: "DELETE",
      keyword: ["tutorial"],
      label: "Delete Permanently",
      description: "This action will permanently delete the entity.",
      value: {
        label: "Purge",
        name: "delete_action",
        type: "action",
        confirmation: "This is an irreversible action. Are you sure?",
        value: {
          type: "delete",
          url: "{_context->path}",
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Deletion successful!",
          },
        },
      },
    },
    {
      id: "Navigation",
      description:
        'Use <code>"type": "navigation"</code> and <code>url</code> values to navigate to another location.',
      label: "Navigate to another page",
      value: {
        label: "Go",
        name: "navigate_action",
        type: "action",
        value: {
          type: "navigation",
          url: "/",
        },
      },
    },
    {
      id: "back_navigation",
      description:
        'Use <code>"type": "navigation"</code> and <code>"url": "BACK"</code> to go back to the previous location.',
      label: "Navigate back/Cancel",
      value: {
        label: "Back",
        name: "navigate_action",
        type: "action",
        display: {
          attributes: {
            color: "secondary",
          },
        },
        value: {
          type: "navigation",
          url: "BACK",
        },
      },
    },
    {
      id: "cancel_dialog",
      description:
        'Use <code>"type": "event"</code> and <code>"name": "_close_dialog" values to cancel updates.',
      label: "Cancel (Dialog)",
      value: {
        label: "Cancel",
        name: "cancel",
        type: "action",
        display: {
          attributes: {
            color: "secondary",
          },
        },
        value: {
          type: "event",
          name: "_close_dialog",
        },
      },
    },
    {
      id: "Dialog",
      description:
        'Use <code>"type": "dialog"</code> and <code>url</code> values to open a page.',
      label: "Open a page as a dialog",
      value: {
        label: "Action",
        name: "action_1",
        type: "action",
        value: {
          type: "dialog",
          url: "/applications/finserv_crm",
        },
      },
    },
    {
      id: "show_history",
      description:
        'Use <code>"type": "event"</code> and <code>"name": "_show_history"</code> to display previous actions.',
      label: "Show History",
      value: {
        label: "Show History",
        name: "show_history",
        type: "action",
        display: {
          attributes: {
            color: "secondary",
          },
        },
        value: {
          type: "event",
          name: "_show_history",
          params: {
            context: `{_context->path}`,
          },
        },
      },
    },
    {
      id: "edit_item_icon",
      description: "Edit icon to use in a list",
      label: "Edit Icon (List)",
      value: {
        name: "edit",
        hint: "Edit",
        icon: "mdi-pencil",
        type: "action",
        value: {
          type: "dialog",
          url: "{_row->_url}?page=edit",
        },
      },
    },
    {
      id: "delete_item_icon",
      description: "Delete icon to use in a list",
      label: "Delete Icon (List)",
      value: {
        name: "delete",
        hint: "Delete",
        icon: "mdi-delete",
        type: "action",
        confirmation: "This is an irreversible action. Are you sure?",
        value: {
          type: "post",
          params: {
            action: "mark_for_deletion",
          },
          url: "{_row->_url}",
          success: {
            message: "Deletion successful",
          },
        },
        display: {
          attributes: {
            color: "error",
          },
        },
      },
    },
    {
      id: "print_target",
      description:
        "Print a portion of a page. A valid css selector should be specified as the event 'target'.",
      label: "Print",
      value: {
        name: "print_target",
        hint: "Print",
        label: "Print",
        icon: "mdi-printer",
        type: "action",
        value: {
          type: "event",
          name: "_print",
          params: {
            target: "[aut-field-definition]",
          },
        },
      },
    },
  ],
  permission_types: ["NA", "E"],
  attributes: [
    {
      name: "show_loader",
      type: "switch",
      hint: "Should a loader be shown when the action is clicked",
      value: true,
    },
    {
      name: "block",
      type: "switch",
      hint: "Expands the button to 100% of available space.",
      value: false,
    },
    {
      name: "bottom",
      type: "switch",
      hint: "Aligns the button to the bottom",
      value: false,
    },
    {
      name: "depressed",
      type: "switch",
      hint: "Looks as if it is pressed",
      value: false,
    },
    {
      name: "fab",
      type: "switch",
      hint: "Will become round",
      value: false,
    },
    {
      name: "fixed",
      type: "switch",
      hint: "Applied position: fixed to the button",
      value: false,
    },
    {
      name: "absolute",
      type: "switch",
      hint: "Applied position: absolute to the button",
      value: false,
    },

    {
      name: "left",
      type: "switch",
      hint: "Aligns button to left [needs absolute or fixed]",
      value: false,
    },
    {
      name: "right",
      type: "switch",
      hint: "Aligns button to right [needs absolute or fixed]",
      value: false,
    },
    color,
    disabled,
    elevation,
    min_height,
    min_width,
    max_height,
    max_width,
    customiseAttribute("outlined", { value: false }),
    {
      name: "plain",
      type: "switch",
      hint: "Removes the default background change applied when hovering over the button",
      value: false,
    },
    {
      name: "retain-focus-on-click",
      type: "switch",
      hint: "Don't blur on click",
      value: false,
    },
    {
      name: "text",
      type: "switch",
      hint: "Makes the background transparent",
      value: false,
    },
    {
      name: "tile",
      type: "switch",
      hint: "Remove rounded border",
      value: false,
    },
    {
      name: "top",
      type: "switch",
      hint: "Align component to top",
      value: false,
    },
    rounded,
    shaped,
    large,
    small,
    xlarge,
    xsmall,
    ripple,
    icon_classes,
  ],
  classes: ["behavior_atman_button", "behavior_dont_include_in_email"],
};
