export default {
  label: "Key Value pair",
  type: "key_value",
  description: "Displays Key-Value pairs",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
    {
      key: "fields",
      mandatory: false,
      description: "allows display of custom fields within a key value pair",
      affects_display: "yes",
      values: [],
    },
    {
      key: "data_type",
      mandatory: false,
      description:
        "The input to the KeyValue Pair will be an array instead of an object",
      affects_display: "yes",
      values: [
        {
          value: "array",
          hint: "Input will be an array",
        },
        {
          value: "object",
          hint: "Default. Input will be an object",
        },
      ],
    },
  ],
  templates: [
    {
      id: "default",
      label: "Default",
      value: {
        name: "some_name",
        label: "Key Value",
        type: "key_value",
        value: { a: "b", c: "d" },
      },
    },
    {
      id: "display",
      label: "Display Mode",
      value: {
        name: "some_name",
        mode: "display",
        label: "Key Value",
        type: "key_value",
        value: { a: "b", c: "d" },
      },
    },
    {
      id: "array_input",
      label: "Handle Data as an Array",
      value: {
        name: "some_name",
        label: "Key Value",
        data_type: "array",
        type: "key_value",
        value: [
          {
            id: "a",
            name: "b",
          },
          {
            id: "c",
            name: "d",
          },
        ],
      },
    },
    {
      id: "field_types",
      label: "key and value as fields",
      value: {
        label: "Permissions",
        name: "permissions",
        type: "key_value",
        value: {
          edit_pages: "YES",
        },
        fields: {
          key: {
            mode: "display",
            name: "id",

            type: "text",
          },
          value: {
            label: "Value",
            name: "id",
            type: "select",
            options: {
              value: [
                {
                  id: "YES",
                  name: "YES",
                },
                {
                  id: "NO",
                  name: "NO",
                },
              ],
            },
          },
        },
      },
    },
  ],
};
