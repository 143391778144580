export default {
  type: "divider",
  label: "Divider",
  filters: ["existing_fields"],
  description: "Displays a divider",
  properties: ["type", "display", "block"],
  templates: [
    {
      id: "default",
      description: "The default Divider.",
      label: "Default",
      value: {
        display: {
          color: "purple",
        },
        type: "divider",
      },
    },
  ],
};
