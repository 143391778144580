<template>
  <v-card-title>
    <CloseButton
      :classes="title ? 'mt-n10 ml-n5 pt-n2' : ''"
      @close="$emit('close')"
    ></CloseButton>
    <p v-if="title">{{ title }}</p>
    <div v-else-if="content" v-html="content"></div>
  </v-card-title>
</template>
<script>
import CloseButton from "@/components/pageContent/CloseButton.vue";
export default {
  name: "DialogTitle",
  components: {
    CloseButton,
  },
  props: {
    title: {},
    content: {},
  },
};
</script>
