export default {
  _permissions: {
    enabled: true,
    permission: {
      value: "list_permissions",
    },
  },
  content: [
    {
      apis: {
        submit: {
          label: "Update",
          type: "post",
          url: "{_route->path}",
          params: {
            action: "update_fields",
          },
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Permission updated",
          },
        },
        data: {
          type: "get",
          url: "{_route->path}",
        },
      },
      definition: {
        fields: [
          {
            _system_constraints: {
              _primary_key: true,
            },
            display: {
              width: "12",
            },
            disabled: true,
            label: "ID",
            mandatory: true,
            mode: "input",
            name: "id",
            type: "text",
          },
          {
            name: "name",
            mandatory: true,
            label: "Name",
            type: "text",
          },
        ],
        actions: [
          {
            name: "delete",
            label: "Delete",
            type: "action",
            confirmation: "This is an irreversible action. Are you sure?",
            value: {
              type: "delete",
              url: "{_context->path}",
              success: {
                message: "Deletion successful",
                action: {
                  type: "navigation",
                  url: "BACK",
                },
              },
            },
            display: {
              attributes: {
                color: "error",
              },
            },
          },
        ],
      },
      id: "edit_permission",
      type: "form",
    },
  ],
  id: "edit_permission",
  name: "edit_permission_page",
  title: "Update Permission",
  type: "page",
};
