import questionaire from "./samples/epilepsy_questionaire";
import two_events_questionaire from "./samples/two_events_questionaire";
import two_exclusive_events_questionaire from "./samples/two_exclusive_events_questionaire";
import neuroscreen_questionaire from "./samples/neuroscreen_questionaire";
import nimhans_prod_questionnaire from "./samples/nimhans_prod_questionnaire";
import weighted_questionnaire from "./samples/weighted_questionnaire";
import stylised_content from "./samples/stylised_content";
import dialog_navigation_questionaire from "./samples/dialog_navigation_questionaire";
import two_outcomes from "./samples/two_outcomes";
import current_nimhans_questionaire from "./samples/current_nimhans_questionaire";
import { behavior_size } from "@/components/fields/attributes";

const debug = require("debug")("atman.components.questionaire"); // eslint-disable-line

export default {
  label: "Questionaire",
  type: "questionaire",
  description: "Displays a Questionaire",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Questionaire.",
      label: "Input (Hide Answered)",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        definition: questionaire,
        name: "default",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "disable_answered_question",
      description: "Answered questions can be disabled",
      label: "Input (Disabled answered)",
      variables: {
        data: {
          disable_answered_question: null,
        },
      },
      value: {
        definition: questionaire,
        name: "disable_answered_question",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "disable",
          },
        },
      },
    },
    {
      id: "display",
      description: "Display mode",
      label: "Display",
      variables: {
        data: {
          display: {
            responses: {
              "general-1": "yes",
              "epilepsy-1": "yes",
              "epilepsy-2": "yes",
              "epilepsy-3": "yes",
              "epilepsy-4": "yes",
              "epilepsy-5": "yes",
            },
            outcome: {
              id: "general_seizure",
              label: "Probably Generalised Seizure",
            },
          },
        },
      },
      value: {
        definition: questionaire,
        name: "display",
        label: "Questionaire",
        type: "questionaire",
        mode: "display",
        display: {
          attributes: {
            diagnosis_style: true,
          },
        },
      },
    },
    {
      id: "two_events",
      description:
        "Example of multiple events being triggered after a question is answered",
      label: "Two events Example",
      variables: {
        data: {
          two_events: null,
        },
      },
      value: {
        definition: two_events_questionaire,
        name: "two_events",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
      },
    },
    {
      id: "current_nimhans",
      description: "This is Example of Current Nimhans Questionaire",
      label: "Current Nimhans",
      variables: {
        data: {
          current_nimhans: null,
        },
      },
      value: {
        definition: current_nimhans_questionaire,
        name: "current_nimhans",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },

    {
      id: "dialog_navigation",
      description:
        "Example of Dialog events being triggered after a question is answered",
      label: "Dialog events Example",
      variables: {
        data: {
          two_events: null,
        },
      },
      value: {
        definition: dialog_navigation_questionaire,
        name: "dialog_navigation",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
      },
    },
    {
      id: "exclusive_events",
      description:
        "If an event is configured as exclusive, subsequent ones will not be triggered even if their conditions are satisfied",
      label: "Exclusive events Example",
      variables: {
        data: {
          exclusive_events: null,
        },
      },
      value: {
        definition: two_exclusive_events_questionaire,
        name: "exclusive_events",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
      },
    },
    {
      id: "neuroscreen",
      description: "Neuroscreen questionaire",
      label: "Neuroscreen",
      variables: {
        data: {
          neuroscreen: null,
        },
      },
      value: {
        definition: neuroscreen_questionaire,
        name: "neuroscreen",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "weighted_questionnaire",
      description: "Weighted questionaire",
      label: "Weighted",
      variables: {
        data: {
          neuroscreen: null,
        },
      },
      value: {
        definition: weighted_questionnaire,
        name: "neuroscreen",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "stylised_content",
      description: "Stylized content",
      label: "Stylized",
      variables: {
        data: {
          neuroscreen: null,
        },
      },
      value: {
        definition: stylised_content,
        name: "neuroscreen",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "two_outcomes",
      description: "Two outcomes example",
      label: "Two outcomes",
      variables: {
        data: {
          two_outcomes: null,
        },
      },
      value: {
        definition: two_outcomes,
        name: "two_outcomes",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
    {
      id: "nimhans_prod_questionnaire",
      description: "Nimhans prod questionaire",
      label: "Nimhans prod",
      variables: {
        data: {
          nimhans_prod_questionnaire: null,
        },
      },
      value: {
        definition: nimhans_prod_questionnaire,
        name: "nimhans_prod_questionnaire",
        label: "Questionaire",
        type: "questionaire",
        mode: "input",
        display: {
          attributes: {
            answered_questions: "hide",
          },
        },
      },
    },
  ],
  attributes: [
    behavior_size,
    {
      name: "answered_questions",
      type: "select",
      hint: "What to do with answered questions",
      value: "",
      options: ["hide"],
    },
    {
      name: "undo.display",
      type: "switch",
      hint: "Allow user to go back to previous question",
      value: true,
    },
    {
      name: "undo.tooltip",
      type: "text",
      hint: "tooltip to display",
      value: "Go Back",
    },
    {
      name: "undo.icon",
      type: "text",
      hint: "Icon to display",
      value: "mdi-backup-restore",
    },
    {
      name: "redo.text",
      type: "text",
      hint: "text to display in the redo button",
      value: "If you want to redo the questionnaire, click here",
    },
    {
      name: "redo.display",
      type: "switch",
      hint: "Display the redo button",
      value: true,
    },
    {
      name: "diagnosis_style",
      type: "switch",
      hint: "Diagnosis Style",
      value: true,
    },
    {
      name: "label_alignment",
      type: "select",
      hint: "Align the label of the Card",
      value: "",
      options: ["float-right"],
    },
  ],
};
