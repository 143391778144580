import { makeServerCall } from "@/util.js";
import savedColors from "./colors";
import { defaultsDeep } from "lodash";
import { getLocalPreferences } from "../api";

const debug = require("debug")("atman.api.preferences"); //eslint-disable-line

const fetchUserPreferences = async function () {
  debug("fetching profile");
  if (window?.vue?.$route?.meta?.unauthenticated === false) {
    return (await makeServerCall({ url: "/api/preferences" }))?.data || {};
  }
  return {};
};

const updateProfile = function (preferences) {
  debug("update Theme");
  makeServerCall({
    url: "/api/preferences",
    params: {
      action: "update_fields",
      data: preferences,
    },
    type: "post",
  });
};

const getVuetifyTheme = () => {
  const promise = new Promise((resolve) => {
    if (!window?.vue?.$vuetify?.theme) {
      setTimeout(() => {
        resolve(getVuetifyTheme());
      }, 10);
    } else {
      resolve(window.vue.$vuetify.theme);
    }
  });
  return promise;
};

const getColorSet = async () => {
  const vuetifyTheme = await getVuetifyTheme();
  const defaultTheme = defaultsDeep(
    {},
    {
      name: "default",
      label: "Default",
      colors: {
        "toolbar-color": vuetifyTheme.defaults.light.primary,
        ...vuetifyTheme.defaults.light,
      },
    },
    {
      ...vuetifyTheme.defaults,
    }
  );

  const defaultDarkTheme = defaultsDeep(
    {},
    {
      name: "default_dark",
      label: "Default Dark",
      isDark: true,
      colors: {
        "toolbar-color": vuetifyTheme.defaults.dark.primary,
        ...vuetifyTheme.defaults.dark,
      },
    },
    {
      ...vuetifyTheme.defaults,
    }
  );

  const colors = [defaultTheme, defaultDarkTheme, ...savedColors];

  return colors;
};

const vuetifyUpdate = async (selectedThemeName) => {
  if (!selectedThemeName) {
    return;
  }

  const vuetifyTheme = await getVuetifyTheme();
  const colors = await getColorSet();
  const selectedTheme = colors.find(({ name }) => name == selectedThemeName);

  if (selectedTheme) {
    const newTheme = {
      light: selectedTheme.colors,
      dark: selectedTheme.colors,
    };
    vuetifyTheme.themes = defaultsDeep(
      {},
      newTheme,
      selectedTheme,
      vuetifyTheme.defaults
    );

    vuetifyTheme.dark =
      typeof selectedTheme?.isDark == "boolean" ? selectedTheme.isDark : false;
  }
};

const getCachedPreferences = () => {
  let userPreferences = {};
  if (!localStorage.preferences) {
    return userPreferences;
  }
  try {
    userPreferences = JSON.parse(localStorage.preferences);
  } catch {
    debug("invalid stored theme");
  }
  return userPreferences;
};

const cacheUserPreferences = (preferences, updateProfileFlag = true) => {
  debug("preferences", preferences);
  localStorage.preferences = JSON.stringify(preferences);
  if (updateProfileFlag) {
    updateProfile(preferences);
  }
};

const updateTheme = () => {
  const userPreferences = getCachedPreferences();
  const domainPreferences = defaultsDeep(getLocalPreferences(), {
    theme: "default",
  });

  // Use defaults if not available
  const effectiveTheme = userPreferences?.theme || domainPreferences?.theme;

  vuetifyUpdate(effectiveTheme);
};

export {
  fetchUserPreferences,
  updateProfile,
  vuetifyUpdate,
  getCachedPreferences,
  cacheUserPreferences,
  getColorSet,
  updateTheme,
};
