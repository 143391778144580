<template>
  <div class="d-flex">
    <v-radio-group
      v-model="dataMode"
      label="Mode"
      dense
      row
      persistent-hint
      :hint="hint"
      @change="$emit('update:mode', dataMode)"
    >
      <v-radio
        :aut-edit-field-mode="option.value"
        :disabled="hasConditions"
        v-for="(option, i) in definition.values"
        :key="i"
        :label="option.label"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
    <ConditionsEditor
      name="mode"
      v-model="dataMode"
      :options="definition.values"
      @update="$emit('update:mode', $event)"
    />
  </div>
</template>
<script>
import ConditionsEditor from "@/components/fields/Field/FieldSettings/ConditionsEditor/ConditionsEditor.vue";
import { isPlainObject } from "lodash";
import { clone } from "@/util";

const debug = require("debug")("atman.components.mode_designer"); // eslint-disable-line
export default {
  name: "ModeDesigner",
  components: {
    ConditionsEditor,
  },
  props: {
    mode: {},
    definition: {
      type: Object,
    },
  },
  watch: {
    mode() {
      this.dataMode = isPlainObject(this.mode) ? clone(this.mode) : this.mode;
    },
  },
  data() {
    return {
      dataMode: isPlainObject(this.mode) ? clone(this.mode) : this.mode,
    };
  },
  computed: {
    hasConditions() {
      return !!this.mode?.conditions;
    },
    hint() {
      if (!this.dataMode) {
        return this.definition.hint;
      }
      const val = this.definition.values.find(
        ({ value }) => value == this.dataMode
      );
      return val?.hint;
    },
  },
  created() {
    this.modeOptions = this.definition.values;
  },
};
</script>
