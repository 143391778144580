const debug = require("debug")("atman.components.book_catalogue"); // eslint-disable-line

export default {
  label: "Book Catalogue",
  filters: ["list_editor", "field_tutorial", "form_editor"],
  type: "book_catalogue",
  description: "Displays a Book Catalogue field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Book Catalogue Field",
      label: "Default",
      value: {
        name: "some_name",
        label: "Title",
        type: "book_catalogue",
      },
    },
  ],
  attributes: [],
  classes: [],
};
