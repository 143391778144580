import {
  no_value_text,
  no_value_behavior,
  customiseAttribute,
} from "@/components/fields/attributes.js";

export default {
  label: "Grouped Fields",
  filters: [],
  type: "object",
  description: "Encapsulates an group of fields",
  templates: [
    {
      id: "default",
      label: "Empty",
      description: "An empty group",
      value: {
        label: "Group",
        type: "object",

        fields: [],
      },
    },
    {
      id: "address",
      label: "Address",
      keyword: ["tutorial"],
      description: "Example of an Address",
      value: {
        name: "address",
        label: "Address",
        type: "object",
        value: {
          address_line_1: "Est consectetur et est velit qui et.",
          address_line_2:
            "Dolore aute eu irure exercitation dolor eiusmod dolore esse laborum officia esse.",
          city: "Bengaluru",
          state: "ka",
          pin_code: "560023",
        },
        fields: [
          {
            name: "address_line_1",
            label: "Line 1",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "address_line_2",
            label: "Line 2",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "city",
            label: "City",
            type: "text",
            display: {
              width: "4",
            },
          },
          {
            name: "state",
            label: "State",
            type: "select",
            display: {
              width: "4",
            },
            options: {
              apis: {
                data: {
                  url: "/developer/states",
                  type: "get",
                },
              },
            },
          },
          {
            name: "pin_code",
            label: "Pin Code",
            type: "text",
            display: {
              width: "4",
            },
          },
        ],
      },
    },
    {
      id: "object_display",
      label: "Display",
      keyword: ["tutorial"],
      value: {
        type: "object",
        mode: "display",
        label: "Summary",
        name: "object_display",
        value: {
          po_amount: 60000,
        },
        fields: [
          {
            type: "text",
            mode: "display",
            label: "PO amount",
            name: "po_amount",
          },
        ],
      },
    },
    {
      id: "profile",
      label: "Profile",
      keyword: ["tutorial"],
      description:
        "This is an example of a profile. Some points to note:\
<ul>\
<li>The <code>is_container</code> indicates that the values themselves are not nested</li>\
<li>To achieve the necessary look-and-feel, this example uses two nested grouped fields</li>\
<li>Any field can have an <code>apis</code> node. If found, a call is made to the server and the returned information is displayed as the value</li>\
</ul>",
      value: {
        name: "profile",
        label: "Profile",
        type: "object",

        apis: {
          data: {
            url: "developer/users/1",
            type: "get",
          },
        },
        fields: [
          {
            name: "profile_details",
            type: "object",
            is_container: true,
            display: {
              width: "6",
            },
            fields: [
              {
                name: "first_name",
                type: "text",
                label: "First Name",
                mode: "input",
                display: {},
              },
              {
                name: "middle_name",
                type: "text",
                mode: "input",
                label: "Middle Name",
                display: {},
              },
              {
                name: "last_name",
                type: "text",
                mode: "input",
                label: "Last Name",
                display: {},
              },
              {
                name: "age",
                type: "text",
                mode: "input",
                label: "Age",
                display: {},
              },
            ],
          },
          {
            name: "image",
            mode: "display",
            display: {
              width: "6",
            },
            type: "image",
          },
        ],
      },
    },
    {
      id: "card_with_image",
      keyword: ["tutorial"],
      label: "Card With Image",
      description: "Example of a card with an image",
      value: {
        name: "card_with_image",
        label: "",
        type: "object",
        display: {
          width: "4",
          classes: ["outlined"],
          attributes: { flat: false },
        },
        fields: [
          {
            name: "title",
            label: "",
            mode: "display",
            type: "rich_text_editor",

            value: "<h3>Ipsum magna</h3><small>Some description</small>",
          },
          {
            name: "divider",
            label: "",
            mode: "display",
            type: "divider",
            display: {
              classes: ["mt-2"],
            },
          },
          {
            name: "some_name",
            label: "Some Label",
            mode: "display",
            type: "image",
            display: {
              embed: {
                width: "240",
              },

              classes: ["mt-16"],
            },
            click_action: {
              type: "navigation",
              url: "/dashboard",
            },
            value: "/test_images/image_640x480.jpeg",
          },
        ],
      },
    },
    {
      id: "card_with_video",
      keyword: ["tutorial"],
      label: "Card With Video",
      description: "Example of a card with a video",
      value: {
        name: "card_with_video",
        label: "",
        type: "object",
        display: {
          classes: ["outlined"],
          attributes: { flat: false },
        },
        fields: [
          {
            name: "title",
            label: "",
            mode: "display",
            type: "rich_text_editor",

            value: "<h3>Ipsum magna</h3>",
          },
          {
            name: "some_name",
            label: "Some Label",
            mode: "embed",
            type: "file",
            display: {
              classes: ["mt-16"],
            },
            click_action: {
              type: "navigation",
              url: "/dashboard",
            },
            value: {
              _file: "https://www.youtube.com/embed/9ng7SSG3TEE",
            },
          },
        ],
      },
    },
    {
      id: "landline_number",
      label: "Landline Number",
      keyword: ["tutorial"],
      description: "Landline number",
      variables: {
        data: {},
      },
      value: {
        label: "Landline Number",
        name: "contact_landline",
        type: "object",
        fields: [
          {
            display: {
              width: "1",
              classes: ["pt-2"],
            },
            label: "",
            mandatory: false,
            mode: "display",
            value: "+91",
            name: "country_code",
            type: "label",
          },
          {
            display: {
              width: "2",
              attributes: {
                type: "number",
              },
            },
            label: "STD Code",
            mandatory: false,
            mode: "input",
            name: "std_code",
            type: "text",
          },
          {
            display: {
              width: "5",
              attributes: {
                type: "number",
              },
            },
            label: "Number",
            mandatory: false,
            mode: "input",
            name: "number",
            type: "text",
          },
          {
            display: {
              width: "4",
              attributes: {
                type: "number",
              },
            },
            label: "Extension",
            mandatory: false,
            mode: "input",
            name: "extn",
            type: "text",
          },
        ],
      },
    },
    {
      id: "tab_display",
      label: "Tab Display",
      keyword: ["tutorial"],
      description: "Display fields in tabs",
      value: {
        name: "tab_display",
        label: "Address",
        type: "object",
        value: {
          address_line_1: "Est consectetur et est velit qui et.",
          address_line_2:
            "Dolore aute eu irure exercitation dolor eiusmod dolore esse laborum officia esse.",
          city: "Bengaluru",
          state: "ka",
          pin_code: "560023",
        },
        fields: [
          {
            name: "address_line_1",
            label: "Line 1",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "address_line_2",
            label: "Line 2",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "city",
            label: "City",
            type: "text",
            display: {
              width: "4",
            },
          },
          {
            name: "state",
            label: "State",
            type: "select",
            display: {
              width: "4",
            },
            options: {
              apis: {
                data: {
                  url: "/developer/states",
                  type: "get",
                },
              },
            },
          },
          {
            name: "pin_code",
            label: "Pin Code",
            type: "text",
            display: {
              width: "4",
            },
          },
        ],
        display: {
          attributes: {
            layout: "tab",
          },
        },
      },
    },
    {
      id: "panel_display",
      label: "Panel Display",
      keyword: ["tutorial"],
      description: "Display fields in panels",
      value: {
        name: "panel_display",
        label: "Address",
        type: "object",
        value: {
          address_line_1: "Est consectetur et est velit qui et.",
          address_line_2:
            "Dolore aute eu irure exercitation dolor eiusmod dolore esse laborum officia esse.",
          city: "Bengaluru",
          state: "ka",
          pin_code: "560023",
        },
        fields: [
          {
            name: "address_line_1",
            label: "Line 1",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "address_line_2",
            label: "Line 2",
            type: "text",
            display: {
              width: "full",
            },
          },
          {
            name: "city",
            label: "City",
            type: "text",
            display: {
              width: "4",
            },
          },
          {
            name: "state",
            label: "State",
            type: "select",
            display: {
              width: "4",
            },
            options: {
              apis: {
                data: {
                  url: "/developer/states",
                  type: "get",
                },
              },
            },
          },
          {
            name: "pin_code",
            label: "Pin Code",
            type: "text",
            display: {
              width: "4",
            },
          },
        ],
        display: {
          attributes: {
            layout: "panel",
          },
        },
      },
    },
    {
      id: "panel_display_2",
      label: "Panel Display 2",
      keyword: ["tutorial"],
      description: "Display fields in panels",
      value: {
        display: {
          attributes: {
            layout: "panel",
            no_value: {
              text: "Not Available",
            },
          },
          classes: ["pb-2"],
          width: "12",
        },
        fields: [
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  label: {
                    classes: ["font-weight-medium"],
                  },
                  width: "12",
                },
                label: "Complaint & Duration",
                mode: "display",
                name: "complaint",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "History & Examination",
                mode: "display",
                name: "history_examination",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "Illness Summary",
                mode: "display",
                name: "illness_summary",
                type: "textarea",
              },
            ],
            is_container: true,
            label: "Chief Complaints",
            mode: "input",
            name: "b397aa71-d290-5a1a-9838-11da0760c484",
            type: "object",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                    row: true,
                  },
                  width: "12",
                },
                label: "Diagnosis Type",
                mode: "display",
                name: "history_examination",
                options: {
                  value: ["Differential", "Provisional", "Tentative", "Final"],
                },
                type: "radio",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "ICD Description",
                mode: "display",
                name: "icd_description",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "ICD 10 Code",
                mode: "display",
                name: "icd_code",
                type: "text",
              },
            ],
            is_container: true,
            label: "Diagnosis",
            mode: "input",
            name: "d4550d62-9106-58ab-9e14-e0d381dadddacomplaint",
            type: "object",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                    row: true,
                  },
                  width: "12",
                },
                label: "Improvement Status",
                mode: "display",
                name: "improvement_status",
                options: {
                  value: [
                    "Condition Improved",
                    "Condition Worsened",
                    "Condition unchanged",
                  ],
                },
                type: "radio",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                hint: "Medicine Name, Dosage, Dosing Time, Duration, Remarks",
                label: "Prescribed Medicine",
                mode: "display",
                name: "prescribed_medicine",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "Treatment Instructions",
                mode: "display",
                name: "treatment_instructions",
                type: "textarea",
              },
            ],
            is_container: true,
            label: "Treatmen Plan/Advised",
            mode: "display",
            name: "489cc9c9-00cc-53ff-87ee-3348ba4d7262",
            type: "object",
          },
        ],
        is_container: true,
        label: "",
        mode: "display",
        name: "panel_display_2",
        type: "object",
      },
    },
    {
      id: "tab_display_2",
      label: "Tab Display 2",
      keyword: ["tutorial"],
      description: "Display fields in tabs",
      value: {
        display: {
          attributes: {
            layout: "tab",
            no_value: {
              text: "Not Available",
            },
          },
          classes: ["pb-2"],
          width: "12",
        },
        fields: [
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  label: {
                    classes: ["font-weight-medium"],
                  },
                  width: "12",
                },
                label: "Complaint & Duration",
                mode: "display",
                name: "complaint",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "History & Examination",
                mode: "display",
                name: "history_examination",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "Illness Summary",
                mode: "display",
                name: "illness_summary",
                type: "textarea",
              },
            ],
            is_container: true,
            label: "Chief Complaints",
            mode: "input",
            name: "b397aa71-d290-5a1a-9838-11da0760c484",
            type: "object",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                    row: true,
                  },
                  width: "12",
                },
                label: "Diagnosis Type",
                mode: "display",
                name: "history_examination",
                options: {
                  value: ["Differential", "Provisional", "Tentative", "Final"],
                },
                type: "radio",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "ICD Description",
                mode: "display",
                name: "icd_description",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "ICD 10 Code",
                mode: "display",
                name: "icd_code",
                type: "text",
              },
            ],
            is_container: true,
            label: "Diagnosis",
            mode: "input",
            name: "d4550d62-9106-58ab-9e14-e0d381dadddacomplaint",
            type: "object",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  input: {
                    mode: "hidden",
                  },
                },
                no_value: {
                  text: "Not Available",
                },
              },
              width: "12",
            },
            fields: [
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                    row: true,
                  },
                  width: "12",
                },
                label: "Improvement Status",
                mode: "display",
                name: "improvement_status",
                options: {
                  value: [
                    "Condition Improved",
                    "Condition Worsened",
                    "Condition unchanged",
                  ],
                },
                type: "radio",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                hint: "Medicine Name, Dosage, Dosing Time, Duration, Remarks",
                label: "Prescribed Medicine",
                mode: "display",
                name: "prescribed_medicine",
                type: "textarea",
              },
              {
                display: {
                  attributes: {
                    no_value: {
                      text: "Not Available",
                    },
                    readonly: true,
                  },
                  width: "12",
                },
                label: "Treatment Instructions",
                mode: "display",
                name: "treatment_instructions",
                type: "textarea",
              },
            ],
            is_container: true,
            label: "Treatmen Plan/Advised",
            mode: "display",
            name: "489cc9c9-00cc-53ff-87ee-3348ba4d7262",
            type: "object",
          },
        ],
        is_container: true,
        label: "",
        mode: "display",
        name: "tab_display_2",
        type: "object",
      },
    },
    {
      id: "hide_field_tab",
      keyword: ["tutorial"],
      label: "Hidden Tab",
      description: "Hide fields in tab with no value",
      variables: {
        data: {
          haddress_line_1: "Est consectetur et est velit qui et.",
          hcity: "Bengaluru",
          hpin_code: "560023",
        },
      },
      value: {
        name: "hide_field_tab",
        label: "Address",
        type: "object",
        is_container: true,
        fields: [
          {
            name: "haddress_line_1",
            label: "Line 1",
            type: "text",
            mode: "display",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "full",
            },
          },
          {
            name: "haddress_line_2",
            label: "Line 2",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "full",
            },
          },
          {
            name: "hcity",
            label: "City",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
          },
          {
            name: "hstate",
            label: "State",
            mode: "display",
            type: "select",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
            options: {
              apis: {
                data: {
                  url: "/developer/states",
                  type: "get",
                },
              },
            },
          },
          {
            name: "hpin_code",
            label: "Pin Code",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
          },
        ],
        display: {
          attributes: {
            layout: "tab",
          },
        },
      },
    },
    {
      id: "hide_field_panel",
      keyword: ["tutorial"],
      label: "Hidden Panel",
      description: "Hide fields in panel with no value",
      variables: {
        data: {
          paddress_line_1: "",
          paddress_line_2:
            "Dolore aute eu irure exercitation dolor eiusmod dolore esse laborum officia esse.",
          pcity: null,
          pstate: "ka",
        },
      },
      value: {
        name: "hide_field_panel",
        label: "Address",
        type: "object",
        is_container: true,
        fields: [
          {
            name: "paddress_line_1",
            label: "Line 1",
            type: "text",
            mode: "display",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "full",
            },
          },
          {
            name: "paddress_line_2",
            label: "Line 2",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "full",
            },
          },
          {
            name: "pcity",
            label: "City",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
          },
          {
            name: "pstate",
            label: "State",
            mode: "display",
            type: "select",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
            options: {
              apis: {
                data: {
                  url: "/developer/states",
                  type: "get",
                },
              },
            },
          },
          {
            name: "ppin_code",
            label: "Pin Code",
            mode: "display",
            type: "text",
            display: {
              attributes: {
                no_value: {
                  behavior: "hide_field",
                },
              },
              width: "4",
            },
          },
        ],
        display: {
          attributes: {
            layout: "panel",
          },
        },
      },
    },
  ],
  properties: ["name", "label", "type", "display", "block", "fields"],
  attributes: [
    no_value_text,
    no_value_behavior,
    {
      name: "layout",
      type: "select",
      hint: "Choose layout",
      value: "",
      options: ["tab", "panel", "card"],
    },
    customiseAttribute("flat", { value: true }),
  ],
};
