<template>
  <v-dialog
    v-model="displayDialog"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-workflow-saveas-dialog>
      <v-card-title class="headline grey lighten-2"> Save As </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              aut-wfnode-label
              dense
              name="label"
              label="Label"
              :rules="applicableRules"
              @change="
                blockDefinition.id = snakeCase(blockDefinition.display.label)
              "
              v-model="blockDefinition.display.label"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              aut-wfnode-description
              dense
              name="description"
              label="Description"
              :rules="applicableRules"
              v-model="blockDefinition.display.description"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-wfnode-cancel> Cancel </v-btn>
        <v-btn color="primary" @click="save" aut-action-edit-wfnode-save>
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { snakeCase, pick, defaultsDeep } from "lodash";
import { rules, clone, makeServerCall } from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/WorkflowEditor/store.js";
const debug = require("debug")("atman.components.workflow_saveas_dialog");
debug(`workflow_saveas_dialog`);

const taskTemplate = {
  display: {
    label: "",
    category: "Custom",
    type: "rectangle",
    width: 150,
    titleHeight: 40,
    icon: "",
    background_color: "blue-grey darken-1",
    link_color: "#2b1d81cc",
    text_color: "white",
    description: "",
    height: 150,
  },
  id: "",
  inputs: [
    {
      label: "",
    },
  ],
  outputs: [
    {
      label: "",
    },
  ],
};

export default {
  name: "WorkflowSaveAsDialog",
  props: {
    block: {
      type: Object,
    },
  },
  components: {},
  data() {
    debug(`block`, this.block);
    const blockDefinition = {};
    defaultsDeep(
      blockDefinition,
      taskTemplate,
      pick(clone(this.block), "data")
    );
    blockDefinition.display.icon = this.block.display.icon;
    debug(`blockDefinition`, blockDefinition);
    return {
      blockDefinition,
      displayDialog: true,
      applicableRules: [rules.mandatory()],
    };
  },
  created() {
    debug(`In created of WorkflowSaveAsDialog`);
    this.snakeCase = snakeCase;
  },
  mounted() {
    debug(`In mounted of WorkflowSaveAsDialog`);
  },
  methods: {
    async save() {
      const component = this;
      const definition =
        component.$store.state[STORE_CONSTS.WORKFLOW_EDITOR_STORE][
          STORE_CONSTS.DEFINITION
        ];
      const apiDefinition = clone(definition.apis.workflow_pallette);
      apiDefinition.type = "post";
      debug("Going to make a server call to", apiDefinition);
      await makeServerCall({
        url: apiDefinition.url,
        type: apiDefinition.type,
        params: {
          data: component.blockDefinition,
        },
      });
      this.$emit("close");
    },
  },
};
</script>
