<template>
  <InputText
    v-if="inputType == 'text' && inputPropsData"
    v-bind="inputPropsData"
    v-on="$listeners"
    @change="updateValue"
  />
  <DropDown
    v-else-if="dropdownPropsData"
    v-bind="dropdownPropsData"
    v-on="$listeners"
    @change="updateValue"
  />
</template>
<script>
import { snakeCase } from "lodash";
const debug = require("debug")("atman.component.fields.address.address_input"); // eslint-disable-line

export default {
  name: "AddressInput",
  components: {
    InputText: () => import("@/components/fields/InputText/InputText"),
    DropDown: () => import("@/components/fields/Dropdown/Dropdown"),
  },
  model: {
    prop: "value",
    event: "blur",
  },
  data() {
    return {
      inputPropsData: null,
      dropdownPropsData: null,
    };
  },
  props: {
    inputType: {
      type: String,
      default() {
        return "text";
      },
    },
    label: {
      type: String,
    },
    context: {
      type: String,
    },
    value: {
      type: String,
    },
    items: {
      type: Array,
    },
    noDataText: {
      type: String,
    },
    mandatory: {
      type: Boolean,
    },
    rules: {
      type: Array,
    },
  },
  watch: {
    items() {
      this.deriveDropdownPropsData();
    },
    value() {
      this.derivePropsData();
    },
  },
  mounted() {
    this.derivePropsData();
  },
  methods: {
    derivePropsData() {
      this.deriveInputPropsData();
      this.deriveDropdownPropsData();
    },
    deriveInputPropsData() {
      const inputPropsData = {
        value: {
          mandatory: this.mandatory,
          rules: this.rules || [],
        },
        context: this.context,
      };

      const name = snakeCase(this.label || "_addressInput");
      inputPropsData.value.name = name;
      inputPropsData.value.label = this.label || "";
      inputPropsData.value.path = `${
        inputPropsData?.value?.path || "innerText"
      }${name}`;

      inputPropsData.value.value = this.value || "";
      inputPropsData.value.type = "text";
      if (this.inputPropsData) {
        Object.assign(this.inputPropsData, inputPropsData);
      } else {
        this.inputPropsData = inputPropsData;
      }
    },
    deriveDropdownPropsData() {
      const dropdownPropsData = {
        context: this.context,
        value: {
          display: {
            attributes: {},
          },
          mandatory: this.mandatory && !!(this.items || []).length,
        },
      };

      dropdownPropsData.value.display.attributes = {
        "no-data-text": this.noDataText || "No Data Available",
      };

      const name = snakeCase(this.label || "_addressDropdown");
      dropdownPropsData.value.name = name;
      dropdownPropsData.value.label = this.label || "";
      dropdownPropsData.value.path = `${
        dropdownPropsData?.value?.path || "innerText"
      }${name}`;

      dropdownPropsData.value.value = this.value || "";
      dropdownPropsData.value.type = "select";
      dropdownPropsData.value.seedData = this.items || [];
      if (this.dropdownPropsData) {
        Object.assign(this.dropdownPropsData, dropdownPropsData);
      } else {
        this.dropdownPropsData = dropdownPropsData;
      }
    },
    updateValue(newValue) {
      debug(`emitting from Address Input`, newValue);
      this.$emit("change", newValue);
    },
  },
};
</script>
