export default {
  data() {
    return {
      permissionDialogToggle: false,
    };
  },
  components: {
    PermissionEditor: () => import("@/components/editor/PermissionEditor"),
  },
  created() {
    this.permissionsActions = [
      {
        id: "edit_permissions",
        label: "Change Permissions",
        icon: "mdi-lock",
        event: "edit_permissions",
        classes: "behavior_permission_control primary--text",
        param: "",
      },
    ];
  },
};
