<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      aut-district
      :context="context"
      v-if="isTextType || forceText"
      :label="label"
      v-model="district"
      :mandatory="isMandatory"
      @change="$emit('update', { district: $event })"
    />
    <AddressInput
      aut-district
      :context="context"
      v-else
      inputType="select"
      :label="label"
      :mandatory="isMandatory"
      :items="districts"
      noDataText="Please select a State first"
      v-model="district"
      @change="$emit('update', { district: $event })"
    />
  </v-col>
</template>
<script>
import address_mixin from "./address_mixin";
import { getDistricts } from "@/assets/jsons/india_districts.js";
const debug = require("debug")("atman.component.fields.address.district"); // eslint-disable-line
debug;
export default {
  name: "AddressDistrict",
  mixins: [address_mixin],
  props: {
    context: {},
    value: {},
    definition: {},
  },
  data() {
    return {
      selectedState: "",
      district: this._getValue("district"),
      districts: [],
      forceText: false,
    };
  },
  watch: {
    "value.state"() {
      debug(`in watch`);
      this.determineDistricts();
    },
    "value.district"() {
      this.district = this.value.district;
    },
  },
  computed: {
    fieldProperties() {
      const configuration = this.definition?.address_fields?.district || {
        mode: "hidden",
      };

      return configuration;
    },
  },
  mounted() {
    this.determineDistricts();
  },
  created() {
    this.defaults = {
      label: "District",
      display: {
        width: "6",
      },
    };
  },
  methods: {
    determineDistricts() {
      this.forceText = false;
      this.districts = [];
      const selectedCountry = this.value?.country || "";
      const selectedState = this.value?.state || "";
      if (this.selectedState && this.selectedState != selectedState) {
        this.district = "";
        this.$emit("update", { district: "" });
      }
      this.selectedState = selectedState;
      if (selectedCountry == "India" || selectedCountry == "IN") {
        this.districts = getDistricts(selectedState);
      }
      if (this.districts?.length == 1) {
        this.district = this.districts[0];
        this.$emit("update", { district: "" });
      } else if (!this.districts || !this.districts.length) {
        this.forceText = true;
      }
    },
  },
};
</script>
