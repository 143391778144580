import {
  readonly,
  disabled,
  dense,
  color,
  append_icon,
  background_color,
  hint,
  persistent_hint,
  prepend_icon,
  ripple,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";
export default {
  label: "Checklist",
  filters: [],
  type: "checklist",
  description: "Displays a Checklist",
  templates: [
    {
      id: "default",
      description: "The default Checklist field.",
      label: "Default",
      variables: {
        data: {
          checklist: {},
        },
      },
      value: {
        name: "checklist",
        label: "",
        type: "checklist",
        items: [
          {
            id: "some_id",
            label:
              "Aliqua nisi magna aute ipsum veniam veniam pariatur eu cillum excepteur cillum est.",
          },
        ],
      },
    },
    {
      id: "default_input",
      description: "The checklist field without a preexisting field value",
      label: "Input",
      value: {
        name: "checklist2",
        label: "",
        type: "checklist",
        items: [
          {
            id: "some_id",
            label:
              "Aliqua nisi magna aute ipsum veniam veniam pariatur eu cillum excepteur cillum est.",
          },
        ],
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  attributes: [
    readonly,
    disabled,
    dense,
    color,
    append_icon,
    prepend_icon,
    background_color,
    hint,
    persistent_hint,
    ripple,
    no_value_text,
    no_value_behavior,
  ],
};
