import actionDefinition from "@/components/fields/Action/definition.js";
import { defaultsDeep } from "lodash";

const editAction = actionDefinition.templates.find(
  ({ id }) => id == "edit_item_icon"
).value;
const deleteAction = actionDefinition.templates.find(
  ({ id }) => id == "delete_item_icon"
).value;
const showHistory = actionDefinition.templates.find(
  ({ id }) => id == "show_history"
).value;

export default {
  type: "actions",
  label: "Actions",
  filters: ["form_editor"],
  description: "This field displays a group of actions",
  properties: ["type", "display", "block", "name", "label"],
  templates: [
    {
      id: "default",
      description: "The default Actions field.",
      label: "Default",
      value: {
        type: "actions",
        name: "actions",
        value: [], //Don't remove this. Causes some tests to fail
      },
    },
    {
      id: "edit_delete",
      label: "Edit & Delete",
      value: {
        label: "Actions",
        display: {
          width: "15",
        },
        name: "actions",
        type: "actions",
        value: [editAction, deleteAction],
      },
    },
    {
      id: "actions_menu",
      label: "Menu actions",
      value: {
        label: "Actions",
        display: {
          classes: ["mt-4"],
          attributes: {
            mode: "menu",
            position: "top_right",
          },
        },
        name: "actions",
        type: "actions",
        value: [editAction, deleteAction].map((action) => {
          defaultsDeep(action, {
            label: action.hint,
            display: {
              attributes: {
                color: "primary",
                text: true,
              },
            },
          });
          return action;
        }),
      },
    },
    {
      id: "dropdown_menu",
      label: "Dropdown Menu Button",
      value: {
        label: "Actions",
        display: {
          attributes: {
            mode: "dropdown",
          },
        },
        name: "actions",
        type: "actions",
        value: [editAction, deleteAction, showHistory].map((action) => {
          defaultsDeep(action, {
            label: action.hint,
            display: {
              attributes: {
                color: "primary",
                text: true,
              },
            },
          });
          return action;
        }),
      },
    },
  ],
  attributes: [
    {
      name: "position",
      type: "select",
      hint: "Where to position the actions. Usually applicable in card mode",
      options: [
        "top_right",
        "bottom_right",
        "bottom",
        "bottom_center",
        "bottom_left",
      ],
      value: "",
    },
    {
      name: "mode",
      type: "select",
      hint: "How to display the actions. Usually applicable in card mode",
      options: ["menu", "dropdown"],
      value: "",
    },
  ],
  classes: ["behavior_dont_include_in_email", "behavior_atman_actions"],
};
