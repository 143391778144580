<template>
  <v-card aut-application-editor-page>
    <v-card-title> Add a new Application </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              aut-application-name
              label="Name *"
              hint="The Application Name"
              persistent-hint
              v-model="application"
              required
              :rules="mandatory"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              aut-application-description
              label="Description"
              v-model="description"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="mode != 'file' && isTemplateSupported">
            <v-autocomplete
              aut-column-application-template
              dense
              small
              clearable
              required
              :items="templates"
              hint="Select an application to clone"
              persistent-hint
              label="Application Template"
              item-value="id"
              item-text="name"
              v-model="template"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="mode == 'file' || isFileUploadSupported">
            <v-file-input
              aut-application-file-upload
              ref="fileupload"
              :accept="formats"
              :multiple="false"
              :rules="mode == 'file' ? mandatory : []"
              :required="mode == 'file'"
              :label="`File ${mode == 'file' ? '*' : ''}`"
              name="file"
              truncate-length="15"
              v-model="fileObject"
            >
            </v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="cancel" aut-action-add-application-cancel> Cancel </v-btn>
      <v-btn
        color="primary"
        :disabled="!valid"
        @click="process"
        :loading="flagCreatingApplication"
        aut-action-add-application
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import application_mixin from "./application_mixin";
import { navigateTo } from "@/util.js";

const debug = require("debug")( // eslint-disable-line
  "atman.components.bare_bones_application_creator"
);

export default {
  name: "BareBonesApplicationCreator",
  mixins: [application_mixin],
  props: {
    mode: {},
  },
  methods: {
    async process() {
      const component = this;
      component.flagCreatingApplication = true;
      try {
        const response = await this.createApplication();
        const url = response?.data?.id;
        if (!url) {
          return;
        }
        if (this.isFeatureEnabled("application_creation.home_page.create")) {
          navigateTo(this.$router, `applications/${url}?page=home`); // eslint-disable-line
        } else {
          const path = encodeURIComponent(`applications/${url}?page=home`); // eslint-disable-line
          navigateTo(this.$router, `/editor_page?page=${path}&operation=create`); // eslint-disable-line
        }
      } catch (e) {
        if (typeof e == "string") {
          component.displayErrorMessage(e);
        } else {
          component.displayErrorMessage(`Application Creation failed`, e);
        }
      } finally {
        component.flagCreatingApplication = false;
      }
    },
  },
};
</script>
