import { forOwn } from "lodash";
import { mapActions } from "vuex";
import { rules, makeServerCall } from "@/util.js";
const debug = require("debug")("atman.components.application_mixin"); // eslint-disable-line

import { pages } from "@/templates/application";

export default {
  data() {
    return {
      flagCreatingApplication: false,
      mandatory: [rules.mandatory()],
      application: "",
      templates: [],
      template: "",
      valid: false,
      description: "",
      formats: ["xls", "xlsx", "csv"],
      fileObject: null,
    };
  },
  mounted() {
    this.fetchTemplates();
  },
  computed: {
    isTemplateSupported() {
      return this.isFeatureEnabled("application_creation.templates.enabled");
    },
    isFileUploadSupported() {
      return this.isFeatureEnabled("application_creation.file_upload.enabled");
    },
  },
  methods: {
    ...mapActions(["fetchMenu"]),
    cancel() {
      this.$router.go(-1);
    },
    async fetchTemplates() {
      try {
        const applications = (
          await makeServerCall({
            url: `/applications`,
            type: "get",
          })
        ).data;
        this.templates = applications;
      } catch (e) {
        console.error(`no applications returned from the server`);
      }
    },
    async createApplication() {
      const component = this;
      let params = {
        name: component.application,
        description: component.description,
      };
      let response;
      if (component.isTemplateSupported && component.template) {
        params.template = component.template;
      }

      if (
        (component.mode == "file" || component.isFileUploadSupported) &&
        !!component.fileObject
      ) {
        const formData = new FormData();
        formData.append("action", "import");
        formData.append("pages", "generate");
        forOwn(params, (val, key) => {
          formData.append(key, val);
        });
        formData.append("file", component.fileObject);
        response = await makeServerCall({
          url: `/applications`,
          type: "post",
          params: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          options: {
            noDataNode: true,
          },
        });
      } else {
        response = await makeServerCall({
          url: `/applications`,
          type: "post",
          params,
        });
      }

      const url = response?.data?.id;
      if (!url) {
        return;
      }

      component.displaySuccessMessage("Application created");
      const featureValue = component.getFeatureValue(
        "application_creation.home_page.create"
      );
      debug(`feature value`, featureValue);
      if (component.isFeatureEnabled("application_creation.home_page.create")) {
        const homePage = pages[0];
        const homePageResponse = await makeServerCall({
          url: `/applications/${url}?page=home`,
          type: "post",
          params: {
            data: {
              id: `${url}_home_page`,
              type: "page",
              content: homePage.value.content,
            },
          },
        });
        debug(`homePageResponse`, homePageResponse);
      }

      component.fetchMenu({ force: true });
      return response;
    },
  },
};
