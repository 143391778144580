import { mapState } from "vuex";
const debug = require("debug")("atman.components.book"); // eslint-disable-line
export default {
  props: {
    attributes: {},
    value: {},
    context: {},
  },
  data() {
    return {
      frontCover: this.no_image,
    };
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  computed: {
    ...mapState(["no_image"]),
    imageAttributes() {
      return this.attributes.image || {};
    },
    effPath() {
      const component = this;
      const contextObj =
        component.$store?.state[`${component.context}`]?.context;
      let path = `{_context->path}`;
      if (contextObj.path.indexOf("/books") == -1) {
        path = `${path}/books/${component.value.id}`;
      }
      return path;
    },
    displayCost() {
      return this.attributes?.cost?.display == true;
    },
    costDefinition() {
      const component = this;
      if (!component.value) {
        return;
      }
      return {
        name: `${component.value?.name || "book"}_cost`,
        label: component?.attributes?.cost?.label,
        type: "cost",
        value: component.value?.cost,
        mode: "display",
        display: {
          width: "2",
        },
      };
    },
    blurb() {
      let result = this.value?.blurb;
      debug(`blurb`, result);
      return result;
    },
    title() {
      let result = this.value?.title || this.value?.name;
      debug(`title`, result);
      return result;
    },
  },
  mounted() {
    debug(`value in mounted of Book`, /* this.value, */ this.attributes);
    this.fetchFrontCover();
  },
  methods: {
    async fetchFrontCover() {
      const component = this;
      if (!component.value) {
        return;
      }
      // For Backward compatibility. Will not be true for 99% of cases
      if (component.value.front_cover.indexOf("/") != -1) {
        component.frontCover = component.value.front_cover;
        return;
      }
      try {
        const response = await component.$store.dispatch(
          `${component.context}/makeServerCall`,
          {
            url: `${component.effPath}/pages/${component.value.front_cover}`,
            type: "get",
            params: {},
          }
        );
        debug(`frontcover`, response.data);
        component.frontCover = response?.data?.image?._file;
      } catch (e) {
        console.error(`Could not get front page`, e);
      }
    },
  },
};
