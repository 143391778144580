<template>
  <v-dialog
    :value="true"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-form v-model="valid" ref="form">
      <v-card aut-editor-action-saveTemplate>
        <DialogTitle @close="$emit('close')" title="Save Template" />
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                aut-template-label
                label="Label"
                v-model="label"
                required
                :rules="applicableRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                aut-template-description
                label="Description"
                v-model="description"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')" aut-template_dialog-action-cancel>
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!valid"
            @click="saveTemplate"
            aut-editor-action-save-template
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { dialogMixin } from "@/components/mixin.js";
import { rules, makeServerCall } from "@/util.js";
export default {
  name: "SaveTemplateDialog",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      label: "",
      description: "",
      applicableRules: [rules.mandatory()],
    };
  },
  methods: {
    async saveTemplate() {
      const template = {
        label: this.label,
        description: this.description,
        value: this.definition,
      };
      await makeServerCall({
        url: "templates",
        type: "post",
        params: template,
      });
      this.displaySuccessMessage("Template added successfully");
      this.$emit("close");
    },
  },
};
</script>
