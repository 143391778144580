<template>
  <v-autocomplete
    v-if="mode != 'display'"
    v-model="definition.constraint.units"
    :items="constraintValues"
    item-text="name"
    item-value="id"
    label="Unit*"
    required
    @blur="$emit(definition.constraint.units)"
    @change="$emit(definition.constraint.units)"
    :rules="rules"
  />
  <span v-else>
    {{ displayValue }}
  </span>
</template>
<script>
import { rules } from "@/rules.js";
import { clone } from "@/util";
const debug = require('debug')('atman.component.fields.feature.constraint.unit')  // eslint-disable-line
export default {
  name: "ConstraintUnit",
  props: {
    value: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      definition: clone(this.value),
    };
  },
  computed: {
    displayValue() {
      debug(`this.constraintValues`, this.constraintValues);
      const constraintObject = this.constraintValues.find(
        (item) => item.id == this.definition.constraint.units
      );
      if (!constraintObject) {
        return "";
      }
      if (constraintObject.id == "number") {
        return "";
      }
      return constraintObject.name;
    },
    constraintValues() {
      let result = [
        {
          id: "number",
          name: "Number",
        },
        {
          id: "MB",
          name: "MB",
        },
        {
          id: "GB",
          name: "GB",
        },
      ];
      return result;
    },
  },
  created() {
    this.rules = [rules.mandatory()];
  },
};
</script>
