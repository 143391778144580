<template>
  <FieldLabel
    aut-address-label
    mode="display"
    :label="label"
    :attributes="labelAttributes"
  >
    <div aut-address-value class="mb-0">
      <p
        class="behaviour_address_line py-0 my-0"
        aut-address-line-1
        v-if="!isHidden('address_line_1')"
      >
        <span>{{ value.address_line_1 || "" }}</span>
      </p>

      <p
        class="behaviour_address_line py-0 my-0"
        aut-address-line-2
        v-if="!isHidden('address_line_2')"
      >
        <span>{{ value.address_line_2 || "" }}</span>
      </p>
      <p class="py-0 my-0">
        <span
          class="behaviour_address_line py-0 my-0"
          aut-address-city
          v-if="!isHidden('city')"
        >
          {{ value.city || "" }}
        </span>
        <span
          class="behaviour_address_line py-0 my-0"
          aut-address-district
          v-if="!isHidden('district')"
        >
          {{ value.district || "" }}
        </span>
      </p>
      <p class="py-0 my-0">
        <span
          class="behaviour_address_line"
          aut-address-state
          v-if="!isHidden('state')"
        >
          {{ value.state || "" }}
        </span>
        <span
          class="behaviour_address_line"
          aut-address-pincode
          v-if="!isHidden('pin_code')"
        >
          {{ value.pin_code || "" }}
        </span>
        <span
          class="behaviour_address_line"
          aut-address-country
          v-if="!isHidden('country')"
        >
          {{ value.country || "" }}
        </span>
      </p>
    </div>
  </FieldLabel>
</template>
<script>
import { isPlainObject } from "lodash";
const debug = require('debug')('atman.components.fields.address.display')  // eslint-disable-line
export default {
  name: "AddressDisplayValue",
  props: {
    label: {},
    value: {},
    attributes: {},
    definition: {},
    labelAttributes: {},
  },
  watch: {
    "value.city"() {
      debug(`value changed`, this.value);
    },
  },
  mounted() {
    debug("mounted", JSON.stringify(this.value));
  },
  computed: {
    noValueSpace() {
      return this.attributes?.no_value_space == true;
    },
  },
  methods: {
    isHidden(key) {
      const addressFields = this.definition?.address_fields;
      if (addressFields) {
        const fieldProperties = addressFields[key];
        if (!fieldProperties) {
          return true;
        }
        if (
          isPlainObject(fieldProperties) &&
          fieldProperties?.mode == "hidden"
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.behavior-list-card {
  .behaviour_address_line {
    min-height: 21px;
  }
}
</style>
