<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-switch
          dense
          aut-checkbox-single-toggle
          v-model="isSingleSelect"
          label="Single Select"
          persistent-hint
          hint="Choose whether this is a single select"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row dense>
      <SeedDataDesigner
        v-if="fieldDefinition"
        :definition="fieldDefinition"
        @update:definition="updateDefinition"
      />
    </v-row>
  </div>
</template>
<script>
import { defaultsDeep } from "lodash";
import { clone } from "@/util";
const debug = require("debug")("atman.components.checkbox_designer");

debug(`checkbox_designer`);
export default {
  name: "CheckboxFieldDesigner",
  components: {
    SeedDataDesigner: () =>
      import("@/components/fields/PageEditor/SeedDataDesigner"),
  },
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    const fieldDefinition = clone(this.definition);
    defaultsDeep(fieldDefinition, {
      display: {
        attributes: {
          multiple: true,
        },
      },
    });
    return {
      fieldDefinition,
    };
  },
  computed: {
    isSingleSelect: {
      get() {
        return !this?.fieldDefinition?.display?.attributes?.multiple;
      },
      set(value) {
        this.fieldDefinition = defaultsDeep(
          {},
          {
            display: {
              attributes: {
                multiple: !value,
              },
            },
          },
          clone(this.fieldDefinition)
        );
        this.updateDefinition(this.fieldDefinition);
      },
    },
  },
  methods: {
    updateDefinition(definition) {
      debug(`CheckboxFieldDesigner updateDefinition`, definition);
      this.$emit("update:definition", definition);
    },
  },
};
</script>
