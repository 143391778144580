/* 
  The colors are generated using online tools and following design guidelines from
  https://mycolor.space/
  https://material.io/design/color/the-color-system.html#tools-for-picking-colors
*/
/*
  colorObject = {
    name: ,         has to be unique name
    isDark: ,       true if it is a dark theme
    colors: {
      primary: ,                       // colors applied to various vuetify components
      secondary: ,                     // colors applied to various vuetify components
      accent: ,                        // colors applied to various vuetify components
      "toolbar-color": ,               // header color
      "sidebar-color": ,               // sidebar color
      "sidebar-active-text": ,         // text color of the active menu in sidebar
      "sidebar-active-group" ,         // text color for groups active in sidebar
      "table-header",                  // highlighted header color   
      "component-background",          // background color for vuetify components
      "sidebar-text",                  // text color of sidebar menus
    }
  }
*/

export default [
  {
    name: "theme1",
    label: "Nightshade",
    colors: {
      primary: "#4F0147",
      secondary: "#828C51",
      accent: "#58A4B0",
      error: "#DD6031",
      success: "#9E7682",
      "toolbar-color": "#4F0147",
      "sidebar-color": "#F9F5FF",
    },
  },
  {
    name: "theme2",
    label: "Brown Summer",
    colors: {
      primary: "#361500",
      secondary: "#BA5C12",
      accent: "#806443",
      error: "#C3423F",
      success: "#1E441E",
      "toolbar-color": "#361500",
      "sidebar-color": "#FFFCF9",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "theme3",
    label: "Cotton Candy",
    colors: {
      primary: "#CE2D4F",
      secondary: "#2E294E",
      error: "#A8763E",
      success: "#436436",
      "toolbar-color": "#F7F7F7",
      "sidebar-color": "#FFFFFF",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "dark_theme1",
    label: "Muted Lavender",
    isDark: true,
    colors: {
      pcrimary: "#F45B69",
      prcimary: "#606C38",
      primary: "#A67F8E",
      secondary: "#706993",
      error: "#9E2A2B",
      success: "#668F80",
      "toolbar-color": "#2E2E2E",
      "sidebar-color": "#1E1E1E",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "dark_theme2",
    label: "Sunflower",
    isDark: true,
    colors: {
      primary: "#ff9100",
      secondary: "#E4D6A7",
      error: "#A22C29",
      success: "#0E402D",
      "toolbar-color": "#2E2E2E",
      "sidebar-color": "#1E1E1E",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "dark_theme3",
    label: "Reader",
    isDark: true,
    colors: {
      primary: "#9c27b0",
      secondary: "#2196f3",
      error: "#A22C29",
      success: "#0E402D",
      "toolbar-color": "#000a22",
      "sidebar-color": "#000a22",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "theme4",
    label: "Autumn",
    colors: {
      primary: "#AC4B1C",
      secondary: "#32373B",
      error: "#524632",
      success: "#618B25",
      "toolbar-color": "#AC4B1C",
      "sidebar-color": "#FFFFFF",
    },
  },
  {
    name: "theme5",
    label: "Sapphire",
    colors: {
      primary: "#333C83",
      secondary: "#828E82",
      error: "#A93F55",
      success: "#0B6E4F",
      "toolbar-color": "#333C83",
      "sidebar-color": "#FFFFFF",
      "header-text": "#FFFFFF",
    },
  },
  {
    name: "theme6",
    label: "Lantern",
    isDark: true,
    colors: {
      primary: "#C84B31",
      secondary: "#346751",
      accent: "#ECDBBA",
      "toolbar-color": "#C84B31",
      "sidebar-color": "#346751",
    },
  },
  {
    name: "theme7",
    label: "Primrose",
    isDark: true,
    colors: {
      primary: "#FF1E56",
      secondary: "#FFAC41",
      accent: "#323232",
      "toolbar-color": "#FF1E56",
      "sidebar-color": "#FFAC41",
    },
  },
  {
    name: "theme8",
    label: "Woodfire",
    isDark: true,
    colors: {
      primary: "#2D2424",
      secondary: "#5C3D2E",
      accent: "#E0C097",
      "toolbar-color": "#2D2424",
      "sidebar-color": "#5C3D2E",
    },
  },
  {
    name: "theme9",
    label: "Bluebell",
    colors: {
      primary: "#005F7D",
      secondary: "#52C245",
      accent: "#6BC45D",
      "toolbar-color": "#005F7D",
      "header-text": "#FFFFFF",
      "sidebar-active-group": "#005F7D",
      "sidebar-active-text": "#005F7D",
    },
  },
  {
    name: "Red",
    label: "Red",
    colors: {
      primary: "#B0000B",
      "toolbar-color": "#B0000B",
      "header-text": "#FFFFFF",
      "sidebar-active-group": "#B0000B",
      "sidebar-active-text": "#B0000B",
    },
  },
  {
    name: "Dark Red",
    label: "Dark Red",
    isDark: true,
    colors: {
      primary: "#B0000B",
      secondary: "#426A5A",
      error: "#F2C57C",
      anchor: "#F2F2F2",
      "toolbar-color": "#B0000B",
      "sidebar-active-group": "#FFFFFF",
      "sidebar-active-text": "#FFFFFF",
      "list-title": "#FFFFFF",
      "on-primary": "#FFFFFF",
    },
  },
  {
    name: "Forest",
    label: "Forest",
    colors: {
      primary: "#3E5641",
      accent: "#3E5641",
      "toolbar-color": "#3E5641",
      success: "#3E5641",
      "header-text": "#FFFFFF",
      secondary: "#D36135",
      "table-header": "#94af76",
      "component-background": "#F1F8E9",
    },
  },
  {
    name: "ctrlh",
    label: "ctrlh",
    colors: {
      primary: "#f8464e",
      secondary: "#48b0a3",
      accent: "#036fde",
    },
  },
  {
    name: "facebook",
    label: "Facebook",
    colors: {
      primary: "#4267B2",
      secondary: "#898F9C",
      accent: "#000000",
    },
  },
];
