<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" lg="6">
        <DatePicker
          v-if="propsData"
          v-bind="propsData"
          :key="renderKey"
          v-on="$listeners"
          @change="updateDate"
        />
      </v-col>
      <v-col>
        <v-menu
          ref="menu"
          v-model="timeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="scheduledTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="scheduledTime"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker v-model="scheduledTime" full-width>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="timeMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(scheduledTime)">
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <Scheduler
          :date="schedulerDate"
          :time="scheduledTime"
          :cron="cronExpression"
          :end="endValue"
          @update="scheduleEvent"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Scheduler from "./Scheduler.vue";
import { fieldMixin } from "@/components/mixin.js";
import { clone } from "@/util";
import { defaultsDeep } from "lodash";

export default {
  name: "EventScheduler",
  mixins: [fieldMixin],
  components: {
    Scheduler,
    DatePicker: () => import("@/components/fields/DatePicker/DatePicker"),
  },
  data() {
    return {
      timeMenu: false,
    };
  },
  computed: {
    schedulerDate() {
      if (this.fieldValue?.date) {
        try {
          return new Date(this.fieldValue?.date);
        } catch (e) {
          console.error("Invalid date string", e);
          return new Date();
        }
      }
      return new Date();
    },
    cronExpression() {
      if (typeof this.fieldValue?.cron == "string") {
        return this.fieldValue?.cron;
      } else {
        return "";
      }
    },
    scheduledTime: {
      get() {
        return this.fieldValue?.time || "09:00";
      },
      set(time) {
        this.fieldValue = defaultsDeep({}, { time }, this.fieldValue);
      },
    },
    endValue() {
      const end = this.fieldValue?.end;
      if (typeof end == "number") {
        return end;
      } else {
        try {
          const date = new Date(end);
          return !isNaN(date.getTime()) ? date : "";
        } catch (e) {
          console.error("Invalid date string", e);
          return "";
        }
      }
    },
  },
  methods: {
    _derivePropsData() {
      const propsData = clone(this.$props);
      propsData.value.value = this.fieldValue?.date || new Date().toISOString();
      propsData.value.label = "Date";
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
    },
    updateDate(date) {
      const schedulerValue = defaultsDeep({}, { date }, this.fieldValue);
      this.fieldValue = schedulerValue;
      this._derivePropsData();
      this.$forceUpdate();
    },
    scheduleEvent(schedule) {
      const cron = schedule.cron;
      let newCron = "";
      if (cron) {
        const [min, hour] = this.getTimeArray();
        const cronArray = cron.split(" ");
        cronArray[0] = min;
        cronArray[1] = hour;
        newCron = cronArray.join(" ");
      }
      this.fieldValue = defaultsDeep({}, { cron: newCron }, schedule, {
        date: this.schedulerDate.toISOString(),
        time: this.scheduledTime,
      });
    },
    getTimeArray() {
      const time = this.scheduledTime;
      const [hour, min] = time.split(":").map((value) => parseInt(value));
      const cron = this.fieldValue?.cron || "* *";
      const cronArray = cron.split(" ");
      cronArray[1] = hour;
      cronArray[0] = min;
      return cronArray;
    },
  },
};
</script>
