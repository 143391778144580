import Vue from "vue";
import { get, set, isEqual } from "lodash";
import { clone } from "@/util.js";
const debug = require("debug")("atman.page_editor.store");

export const STORE_CONSTS = {
  PAGE_EDITOR_STORE: "page_editor",
  PAGE: "PAGE",
  ADD_CONTENT: "ADD_CONTENT",
  REMOVE_CONTENT: "REMOVE_CONTENT",
  UPDATE_NODE: "UPDATE_NODE",
  FETCH_TEMPLATES: "FETCH_TEMPLATES",
};

export const store = {
  namespaced: true,
  state: () => ({
    page: {
      title: "Page Title",
      subtitle: "Page sub title",
      content: [],
    },
  }),
  getters: {
    page(state) {
      return clone(state.page);
    },
  },
  mutations: {
    [STORE_CONSTS.PAGE](state, page) {
      debug(`in ${STORE_CONSTS.PAGE}`);
      if (isEqual(state.page, page) || !page) {
        debug(`${STORE_CONSTS.PAGE} ignored`);
        return;
      }
      Vue.set(state, "page", clone(page));
    },

    [STORE_CONSTS.UPDATE_NODE](state, { key, value }) {
      debug(`in ${STORE_CONSTS.UPDATE_NODE}`);
      const originalContent = get(state.page, key);
      if (isEqual(originalContent, value)) {
        debug(`${STORE_CONSTS.UPDATE_NODE} ignored`);
        return;
      }
      set(state.page, key, value);
    },
  },
  actions: {
    [STORE_CONSTS.REMOVE_CONTENT]({ state, commit }, index) {
      debug(`in ${STORE_CONSTS.REMOVE_CONTENT}`);
      const page = clone(state.page);
      page.content.splice(index, 1);
      commit(STORE_CONSTS.PAGE, page);
    },

    [STORE_CONSTS.ADD_CONTENT]({ state, commit }, template) {
      debug(`in ${STORE_CONSTS.ADD_CONTENT}`);
      const page = clone(state.page);
      page.content.push(clone(template));
      commit(STORE_CONSTS.PAGE, page);
    },
  },
};
