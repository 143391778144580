<template>
  <v-row v-if="isMenu" :class="classes">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :class="displayClasses"
          v-bind="attrs"
          v-on="on"
          aut-actions-menu
        >
          mdi-dots-vertical
        </v-icon>
      </template>
      <v-card class="behaviour_menu_actions pa-2">
        <v-list class="pa-0 ma-0" dense>
          <v-list-item
            class="pa-0 ma-0 behavior-action-list-item"
            v-for="(action, i) in fieldValue"
            :key="i"
          >
            <Field
              :definition="action"
              :context="context"
              :path="path"
              :index="index"
              @trigger-submit="$emit('trigger-submit', $event)"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-row>
  <v-row v-else-if="isDropdownMenu">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :ripple="false"
          v-bind="{ ...attrs, ...getAttributes(fieldValue[0]) }"
          class="pa-0"
          v-on="on"
          aut-action-main
        >
          <Field
            :definition="fieldValue[0]"
            :context="context"
            :path="path"
            :index="index"
            @trigger-submit="$emit('trigger-submit', $event)"
          />
          <v-divider vertical></v-divider>
          <v-icon aut-action-dropdown>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-card class="behaviour_menu_actions pa-2">
        <v-list class="pa-0 ma-0" dense>
          <v-list-item
            class="pa-0 ma-0 behavior-action-list-item"
            v-for="(action, i) in fieldValue.slice(1)"
            :key="i"
          >
            <Field
              :definition="action"
              :context="context"
              :path="path"
              :index="index"
              @trigger-submit="$emit('trigger-submit', $event)"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-row>
  <v-row v-else :class="classes">
    <Field
      v-for="(action, i) in fieldValue"
      :key="i"
      :definition="action"
      :context="context"
      :path="path"
      :index="index"
      @trigger-submit="$emit('trigger-submit', $event)"
    />
  </v-row>
</template>

<script>
import { isChildOf } from "@/util";
import { confirmationMixin, fieldMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.components.actions"); // eslint-disable-line

export default {
  name: "Actions",
  mixins: [fieldMixin, confirmationMixin],
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  data() {
    return {
      definition,
      classes: [],
    };
  },
  computed: {
    isMenu() {
      return this?.displayAttributes?.mode == "menu";
    },
    isDropdownMenu() {
      return this?.displayAttributes?.mode == "dropdown";
    },
  },
  mounted() {
    this.determinePosition();
  },
  methods: {
    getAttributes(field) {
      return { ...field?.display?.attributes, class: field?.display?.classes };
    },
    determinePosition() {
      let result = [];
      const attributeContainer =
        (isChildOf(this.$el, ".behavior_list_card")
          ? this.displayAttributes?.cards || this.displayAttributes
          : this.displayAttributes) || {};
      debug(`attribute container`, attributeContainer);
      switch (attributeContainer.position) {
        case "top_right": {
          result.push("behavior_position_top_right");
          break;
        }
        case "bottom_right": {
          result.push("behavior_position_bottom justify-end");
          break;
        }
        case "bottom":
        case "bottom_spread": {
          result.push("behavior_position_bottom justify-space-between");
          break;
        }
        case "bottom_center": {
          result.push("behavior_position_bottom justify-center");
          break;
        }
        case "bottom_left": {
          result.push("behavior_position_bottom justify-start");
          break;
        }
      }
      debug(`position class: `, result);
      this.classes += ` ${result.join(" ")} `;
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior-action-list-item {
  min-height: 0px !important;
}
.behavior_atman_actions {
  &.behavior_position_top_right {
    position: absolute;
    top: 4% !important;
    right: 4% !important;
  }
  /* &.behavior_position_bottom {
      position: absolute;
      bottom: 4% !important;
    } */
}
</style>
