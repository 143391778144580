<template>
  <v-navigation-drawer
    v-if="displaySidebar"
    app
    v-bind="displayAttributes"
    class="sidebar"
    aut-sidebar
    :mini-variant="miniVariant"
    :width="sidebarVariantWidth"
    v-model="drawer"
  >
    <div v-if="showImage && logo && logo.image">
      <SiteLogoLeft container="Sidebar" />
      <v-divider />
    </div>
    <div>
      <v-list flat nav expand>
        <MenuOptions :menus="menu" :variant="sidebarVariant" />
        <UserMenu view="list" v-if="showUserMenu" />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { defaultsDeep } from "lodash";
import { isUnAuthenticated } from "@/util";
import { mapActions, mapState, mapGetters } from "vuex";
import MenuOptions from "./MenuOptions.vue";
import SiteLogoLeft from "@/components/pageContent/SiteLogo/SiteLogoLeft.vue";
import UserMenu from "../Header/UserMenu.vue";

const debug = require("debug")("atman.components.sidebar");
debug("sidebar");
export default {
  name: "Sidebar",
  components: {
    MenuOptions,
    UserMenu,
    SiteLogoLeft,
  },
  data: () => ({
    sideMenuWidth: 40,
    sideMenuToggle: false,
    displaySidebar: false,
  }),
  computed: {
    ...mapState(["skin", "preferences"]),
    ...mapGetters(["isClassicLayout", "menu"]),
    ...mapGetters("user", ["displaySidebarForRole"]),
    displayAttributes() {
      let commonAttributes = {
        bottom:
          this.getFeatureValue("mobile.sidebar.position") == "Bottom" &&
          this.isSmallScreen,
        clipped: this.isHeaderDisplayed,
        color: "sidebar-color",
      };
      return defaultsDeep({}, commonAttributes);
    },
    showImage() {
      if (
        this.isSmallScreen &&
        this.getFeatureValue("logo.mobile.position") == "Sidebar"
      ) {
        return true;
      }
      return !this.isHeaderDisplayed;
    },
    isHeaderDisplayed() {
      return !!this?.skin?.header?.display;
    },
    menuAsString() {
      return JSON.stringify(this.menu);
    },
    background() {
      return this?.skin?.sidebar?.background || {};
    },
    backgroundImage() {
      return this?.skin?.sidebar?.background?.image || "";
    },
    logo() {
      return this?.skin?.logo;
    },
    drawer: {
      get() {
        return this.isFeatureEnabled("sidebar.display");
      },
      set(value) {
        this.setSidebarDisplay(value);
      },
    },
    showUserMenu() {
      return this.isFeatureEnabled("sidebar.user_menu.display");
    },
    miniVariant() {
      return this.isFeatureEnabled("sidebar.mini_variant");
    },
    sidebarVariant() {
      return this.isFeatureEnabled("sidebar.sidebar_variant");
    },
    sidebarVariantWidth() {
      return this.sidebarVariant == true ? "80" : undefined;
    },
  },
  mounted: async function () {
    debug("In mounted");
    await this.fetchSkin();
    this.determineSidebarVisibility();
    this.fetchSidebarMenu();
    this.registerToEvents();
  },
  watch: {
    $route() {
      this.determineSidebarVisibility();
      this.fetchSidebarMenu();
    },
  },
  methods: {
    ...mapActions(["fetchSkin", "fetchMenu", "setSidebarDisplay"]),
    registerToEvents() {
      const component = this;
      component._subscribe(
        component.$store.subscribe((mutation) => {
          if (
            mutation.type == "preferences/addFavourite" ||
            mutation.type == "preferences/removeFavourite"
          ) {
            setTimeout(() => {
              this.fetchMenu({ force: true });
            }, 1000);
          }
        })
      );
    },
    determineSidebarVisibility() {
      if (isUnAuthenticated(this)) {
        this.displaySidebar = false;
        return;
      }

      if (!this.isFeatureEnabled("sidebar.display")) {
        this.displaySidebar = false;
        return;
      }

      if (this.isClassicLayout) {
        this.displaySidebar = this.displaySidebarForRole;
        return;
      }
      this.displaySidebar = false;
    },
    async fetchSidebarMenu() {
      if (!this.displaySidebar) {
        return;
      }
      await this.fetchMenu();
      debug(`menu`, this.menu);
      this.$forceUpdate();
    },
    navigateTo(href) {
      if (href) {
        this.$router.push(href);
      }
    },
  },
};
</script>
