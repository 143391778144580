<template>
  <div v-if="html" v-html="html"></div>
</template>
<script>
import { omit } from "lodash";
import { createContext, makeServerCall } from "@/util";
const debug = require('debug')('atman.static_page')  // eslint-disable-line
export default {
  name: "StaticPage",
  data() {
    return {
      html: null,
    };
  },
  mounted() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      const component = this;
      const path = component.$route.fullPath;
      const context = createContext(path);
      debug(`context`, context);
      const response = await makeServerCall({
        url: context.path,
        type: "get",
        params: omit(context.params, "_ui_components"),
      });
      debug(`response`, response);
      component.html = response?.data?.html || "";
    },
  },
};
</script>
