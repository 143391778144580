// https://github.com/scurker/currency.js
import currency from "currency.js";

const getCurrencyRaw = (input) => {
  return currency(input.amount, { decimal: ".", symbol: "₹" });
};

const getCurrencyObj = (input) => {
  return { amount: input.value, currency: "INR" };
};

const add = (val1, val2) => {
  let valCur1 = getCurrencyRaw(val1);
  let valCur2 = getCurrencyRaw(val2);
  let result = valCur1.add(valCur2);
  return getCurrencyObj(result);
};

const subtract = (val1, val2) => {
  let valCur1 = getCurrencyRaw(val1);
  let valCur2 = getCurrencyRaw(val2);
  let result = valCur1.subtract(valCur2);
  return getCurrencyObj(result);
};

const multiply = (val1, noOfUnits) => {
  let valCur1 = getCurrencyRaw(val1);
  let result = valCur1.multiply(noOfUnits);
  return getCurrencyObj(result);
};

const isMoreThan = (val1, val2) => {
  const result = subtract(val1, val2);
  return result.amount > 0;
};

const format = (input) => {
  return currency(input.amount, { decimal: ".", symbol: "₹" }).format();
};

export default {
  isMoreThan,
  getCurrencyRaw,
  getCurrencyObj,
  add,
  subtract,
  format,
  multiply,
};
