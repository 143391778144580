export default {
  label: "Code Editor",
  filters: ["form_editor", "list_editor"],
  type: "code_editor",
  description: "Displays a Code editor",
  templates: [
    {
      id: "default",
      description: "The default Code Editor field.",
      label: "Default",
      value: {
        label: "Code Editor",
        type: "code_editor",
        value: "list = [1, 2, 3, 4, 5]\nlist[0]",
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
};
