<template>
  <div
    aut-username
    @click.stop="navigate"
    :class="{ behavior_clickable: navigationUrl }"
  >
    <FieldLabel
      :definition="value"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
      <FieldDisplayValue :value="displayValue" :definition="value" />
    </FieldLabel>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import { isPlainObject } from "lodash";
import { mapActions } from "vuex";
import { replacePlaceholders, hasAVariable, navigateTo } from "@/util";
import definition from "./definition";

const debug = require("debug")("atman.components.user"); // eslint-disable-line

const getDisplayValue = (formats, data) => {
  const validFormat = formats.find((format) => {
    const replacedValue = replacePlaceholders(format, data);
    return !hasAVariable(replacedValue);
  });

  if (!validFormat) {
    return "";
  }

  return replacePlaceholders(validFormat, data);
};
export default {
  name: "UserName",
  mixins: [fieldMixin],
  data() {
    return {
      definition: definition,
      user: {},
      navigationUrl: "",
    };
  },
  computed: {
    displayValue() {
      const user = this.user;
      const formats = this.displayAttributes?.formats || "";
      const defaultFormats = [
        "{nickname}",
        "{first_name} {last_name}",
        "{username}",
        "{id}",
      ];

      if (Array.isArray(formats) && formats.length) {
        return getDisplayValue(formats, user);
      }

      return getDisplayValue(defaultFormats, user);
    },
  },
  async mounted() {
    await this.fetchUser();
    this.determineUrl();
  },
  methods: {
    ...mapActions("user", ["fetchProfile"]),
    async fetchUser() {
      let user = this.fieldValue;

      if (isPlainObject(user)) {
        this.user = user;
        return;
      }

      if (!user || typeof user != "string") {
        this.user = {};
        return;
      }

      user = await this.fetchProfile({ user });

      if (isPlainObject(user)) {
        this.user = user;
        return;
      }

      this.user = {};
    },
    _afterFieldValueUpdate() {
      this.fetchUser();
    },
    determineUrl() {
      const url = this.displayAttributes?.url || "";
      const targetUrl = this.getDynamicValue(url);
      this.navigationUrl = targetUrl;
    },
    navigate() {
      if (!this.navigationUrl) {
        return;
      }
      navigateTo(this.$router, this.navigationUrl);
    },
    getDynamicValue(key) {
      const value = this.$store?.getters[`${this.context}/dynamicText`]({
        url: key || "",
        customVariables: {
          _user: this.user,
        },
        isValidKey: (key) => key.startsWith("_user"),
      });
      if (hasAVariable(value)) {
        debug(`Could not determine dynamic value for key ${key}`);
        return "";
      }
      return value;
    },
  },
};
</script>
