import { upperFirst } from "lodash";

const repeatTypes = (date) => {
  const day = date.getDay();
  const dateMonth = date.toLocaleDateString("default", {
    month: "long",
    day: "numeric",
  });
  return [
    {
      text: "Does not repeat",
      value: 1,
      cron: "",
    },
    {
      text: "Daily",
      value: 2,
      cron: "* * */1 * *",
      regEx: /\* \* \*\/1 \* \*/,
    },
    {
      text: `Weekly on ${upperFirst(days[day])}`,
      value: 3,
      cron: `* * * * ${day}`,
      regEx: /\* \* \* \* [0-6]$/,
    },
    {
      text: `Monthly on day ${date.getDate()}`,
      value: 4,
      cron: `* * ${date.getDate()} */1 *`,
      regEx: /\* \* ([1-9]|[1-2][0-9]|3[01]) \*\/1 \*/,
    },
    {
      text: `Annually on ${dateMonth}`,
      value: 5,
      cron: `* * ${date.getDate()} ${date.getMonth()} *`,
      regEx: /\* \* ([1-9]|[1-2][0-9]|3[01]) ([1-9]|1[0-2]) \*/,
    },
    {
      text: "Every Weekday (Mon to Fri)",
      value: 6,
      cron: "* * * * 1-5",
      regEx: /\* \* \* \* 1-5/,
    },
    {
      text: "Custom",
      value: 7,
    },
  ];
};

const monthlyPatterns = (date) => {
  return [
    {
      text: `Monthly on day ${date.getDate()}`,
      value: 1,
      cron: `* * ${date.getDate()} */1 *`,
    },
  ];
};

const recurrenceTypes = ["day", "week", "month", "year"];
const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

const dayPattern = /\* \* \*\/[1-9]\d* \* \*/;
const monthStepPattern = /\*\/[1-9]\d*/;
const yearPattern = /\* \* ([1-9]|[1-2][0-9]|3[01]) ([1-9]|1[0-2]) \*/;

const isDayRecurrence = (pattern) => {
  return dayPattern.test(pattern);
};

const isMonthRecurrence = (pattern) => {
  const patternArray = pattern.split(" ");
  const dayOfMonth = patternArray[2];
  const dayOfWeek = patternArray[4];
  if (monthStepPattern.test(patternArray[3])) {
    return dayOfMonth != "*" || dayOfWeek != "*";
  }
  return false;
};

const isYearRecurrence = (pattern) => {
  const patternArray = pattern.split(" ");
  const dayOfMonth = patternArray[2];
  const dayOfWeek = patternArray[4];
  const step = patternArray[3].split("/")[1];
  if (yearPattern.test(pattern)) {
    return true;
  }

  if (monthStepPattern.test(patternArray[3])) {
    return dayOfMonth == "*" && dayOfWeek == "*" && step % 12 == 0;
  }
  return false;
};

export {
  repeatTypes,
  recurrenceTypes,
  days,
  monthlyPatterns,
  isDayRecurrence,
  isMonthRecurrence,
  isYearRecurrence,
};
