<template>
  <PermissionContextManager :context="context" />
</template>

<script>
import PermissionContextManager from "./PermissionContextManager";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.permissions_manager"); // eslint-disable-line

export default {
  name: "PermissionsManager",
  mixins: [fieldMixin],
  data() {
    return {
      tabModel: null,
    };
  },
  components: {
    PermissionContextManager,
  },
};
</script>
