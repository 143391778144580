<template>
  <div v-if="isDisplayMode">
    <FieldLabel
      aut-duration-label
      v-if="isDisplayMode"
      mode="display"
      class="text-left"
      :label="label"
      :definition="value"
      :attributes="labelAttributes"
    >
      <FieldDisplayValue
        aut-field-value
        :value="displayValue"
        :definition="value"
      />
    </FieldLabel>
  </div>
  <v-row v-else>
    <v-col cols="12" lg="1" class="mt-4">
      <FieldLabel
        aut-duration-label
        class="text-left"
        :label="label"
        :definition="value"
        :attributes="labelAttributes"
      />
    </v-col>
    <v-col cols="12" lg="5" v-if="shouldDisplayValue">
      <v-text-field
        aut-duration-value
        v-model="effectiveValue.value"
        label="Value"
        type="number"
        @blur="updateValue"
        :rules="valueRules"
        v-bind="displayAttributes"
        v-bind:class="displayClasses"
        :hint="value.hint"
      />
    </v-col>
    <v-col cols="12" lg="5">
      <v-autocomplete
        aut-duration-unit
        v-model="effectiveValue.unit"
        :items="items"
        item-text="name"
        item-value="id"
        label="Units"
        required
        @change="updateUnit"
        v-bind="displayAttributes"
        v-bind:class="displayClasses"
        :rules="unitRules"
      />
    </v-col>
  </v-row>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import { rules } from "@/rules.js";
import { clone } from "@/util.js";
const debug = require("debug")("atman.components.fields.duration"); // eslint-disable-line
export default {
  name: "TimeDuration",
  mixins: [fieldMixin],
  data() {
    return {
      effectiveValue: {
        value: "",
        unit: "",
      },
      items: [
        { id: "day", name: "Day(s)" },
        { id: "month", name: "Month(s)" },
        { id: "year", name: "Year(s)" },
        { id: "forever", name: "Forever" },
      ],
      unitRules: [rules.mandatory()],
    };
  },
  computed: {
    isForever() {
      return this.effectiveValue.unit == "forever";
    },
    valueRules() {
      return this.isForever
        ? []
        : [rules.mandatory(), rules.min_value({ min: 1 })];
    },
    shouldDisplayValue() {
      debug(`shouldDisplayValue: [${this.effectiveValue.unit}]`);
      return !this.isForever;
    },
    displayValue() {
      if (!this.fieldValue) {
        return "Forever";
      }
      if (this.isForever) {
        return this.fieldValue?.unit;
      }
      let isMoreThanOne = (this.fieldValue?.value || 0) > 1;
      return `${this.fieldValue?.value} ${this.fieldValue?.unit}${
        isMoreThanOne ? "s" : ""
      }`;
    },
  },
  mounted() {
    this.determineEffectiveValue();
  },
  methods: {
    _afterFieldValueUpdate() {
      this.determineEffectiveValue();
    },
    determineEffectiveValue() {
      debug(`fieldValue`, this.fieldValue);
      this.effectiveValue = this.fieldValue
        ? clone(this.fieldValue)
        : {
            value: "",
            unit: "",
          };
    },
    updateValue() {
      this.$set(this, "fieldValue", clone(this.effectiveValue));
      debug(`in updateValue`, this.fieldValue);
    },
    updateUnit() {
      if (this.isForever) {
        delete this.effectiveValue.value;
      }
      this.$set(this, "fieldValue", clone(this.effectiveValue));
      debug(`in updateUnit`, this.fieldValue);
    },
  },
};
</script>
