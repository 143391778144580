import {
  placeholder,
  filled,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Rich Text (WYSIWYG) Editor",
  type: "rich_text_editor",
  description: "Displays a Rich Text editor",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "value",
    "disabled",
  ],
  searchField: (col, data) => {
    let result = {
      mode: "input",
      label: col.label,
      name: col.name,
      type: "text",
      display: {
        classes: [
          "mt-7",
        ] /* Text fields are slightly out-of sync with the rest */,
        attributes: {
          clearable: true,
        },
      },
    };
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    return result;
  },
  templates: [
    {
      id: "default",
      label: "Default",
      description: "The default WYSIWYG Editor field.",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        mode: "input",
        label: "Description",
        display: {
          attributes: {
            placeholder: "Insert some text...",
          },
        },
        type: "rich_text_editor",
      },
    },
    {
      id: "rte_filled",
      label: "RTE (Filled)",
      description: "Rich text editor with filled attribute.",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "default",
        mode: "input",
        label: "Description",
        type: "rich_text_editor",

        display: {
          attributes: {
            placeholder: "Insert some text...",
            filled: true,
          },
        },
      },
    },
    {
      id: "disabled",
      label: "Disabled",
      description: "The disabled WYSIWYG Editor field.",
      variables: {
        data: {
          disabled:
            "<p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        disabled: true,
        name: "disabled",
        mode: "input",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
          },
        },
      },
    },
    {
      id: "no_toolbar",
      label: "Editor w/o Toolbar",
      description:
        "Use <code>no_toolbar: true</code> to display WYSIWYG editor without a toolbar.",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        display: {
          attributes: {
            no_toolbar: true,
            placeholder: "Insert some text...",
          },
        },
        name: "default",
        mode: "input",
        label: "Description",
        type: "rich_text_editor",
      },
    },
    {
      id: "mentions",
      label: "Tagging Mode",
      description:
        "The default WYSIWYG Editor field with tagging mode. Use <code>_user_id</code> as a placeholder for the ID.",
      variables: {
        data: {
          mentions:
            "<p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "mentions",
        mode: "input",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            tagging: {
              mentions: {
                allow: true,
                link: "/users/_user_id?page=edit",
              },
            },
          },
        },
        label: "Description",
        type: "rich_text_editor",
      },
    },
    {
      id: "minimal_mode",
      label: "Minimal Mode",
      description:
        "If the attribute `behavior_no_controls: true` is set, the controls are hidden by default",
      variables: {
        data: {
          minimal_mode:
            "<p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "minimal_mode",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            behavior_no_controls: true,
          },
        },
      },
    },
    {
      id: "display",
      label: "Display Mode",
      description:
        'Use <code>mode: "display"</code> to display WYSIWYG Editor in <b>readonly</b> mode.',
      variables: {
        data: {
          display:
            "</strong></h3><p><br></p><p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "display",
        mode: "display",
        label: "Description",
        display: {
          attributes: {
            placeholder: "Insert some text...",
          },
        },

        type: "rich_text_editor",
      },
    },
    {
      id: "display_no_value",
      label: "Display Mode [No Value]",
      keyword: ["tutorial"],
      description:
        "Use the no_value attribute to control the text displayed when there no value",
      value: {
        name: "display_no_value",
        mode: "display",
        label: "Description",
        display: {
          attributes: {
            no_value: {
              text: "Not Available",
            },
          },
        },
        type: "rich_text_editor",
      },
    },
    {
      id: "preview",
      label: "Preview",
      description:
        'Use <code>"preview"</code> attribute to display a preview of WYSIWYG Editor',
      variables: {
        data: {
          preview:
            "</strong></h3><p><br></p><p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "preview",
        mode: "display",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            preview: {
              show: true,
              height: "100px",
              width: "80px",
            },
          },
        },
      },
    },
    {
      id: "clamped",
      label: "Clamped Mode",
      description: "In clamped mode, the content is truncated to a few lines",
      variables: {
        data: {
          clamped:
            "<p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "clamped",
        mode: "display",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            clamped: {
              size: 100,
            },
          },
        },
      },
    },
    {
      id: "clamped_no_toggle",
      label: "Clamped Mode (w/o toggle)",
      description: "Example of Clamped Mode without toggle option",
      variables: {
        data: {
          clamped_no_toggle:
            "<p>Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem.</p><p><br></p><p><em><u>Fugiat incididunt consequat exercitation nulla laboris ipsum sint officia exercitation incididunt anim. Anim aute tempor pariatur elit commodo incididunt eu cupidatat anim. Eiusmod nostrud laboris fugiat tempor enim adipisicing nostrud sunt reprehenderit et nostrud. Reprehenderit eu laborum aliqua non. Occaecat occaecat ipsum dolor exercitation exercitation laborum eiusmod ex proident voluptate proident. Adipisicing velit pariatur exercitation pariatur.</u></em></p><p><br></p><h4>Non occaecat exercitation enim minim amet</h4><p><br></p><p>Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat",
        },
      },
      value: {
        name: "clamped_no_toggle",
        mode: "display",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            clamped: {
              size: 100,
              show_icon: false,
            },
          },
        },
      },
    },
    {
      id: "expanded",
      label: "Expanded",
      description: "Editor with increased input height",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        name: "expanded",
        mode: "input",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
            height: "50vh",
          },
        },
      },
    },
    {
      id: "with_image",
      label: "With Image",
      description: "Display with image",
      variables: {
        data: {
          with_image:
            '<p> Nisi magna consectetur commodo ex reprehenderit cupidatat laborum adipisicing culpa cupidatat magna pariatur ea laborum. Veniam et labore irure eu fugiat. Irure mollit ad anim commodo in. Veniam eu laboris proident duis consequat mollit ut laboris. Id incididunt aute sint anim nostrud aute non pariatur minim voluptate labore esse qui aliqua. Cupidatat esse quis sit adipisicing deserunt dolor ullamco adipisicing duis id. Duis exercitation laborum in consequat laborum qui sunt et exercitation non ex Lorem. </p> <p><br /></p> <p><img src="/advertisement.png"></p> <p><br /></p> <p> Qui magna enim cillum amet ea <strong>exercitation nisi qui nisi</strong>. Dolor mollit laborum proident minim. Ut officia ipsum dolore ex aliquip sint esse est culpa. Reprehenderit do anim non deserunt ad duis dolore ullamco. Irure id deserunt et deserunt dolore occaecat eu. Cillum occaecat adipisicing et labore est id officia consequat officia reprehenderit eiusmod officia. Proident eiusmod sint dolore ullamco id anim irure reprehenderit et sint eiusmod aute consequat", </p> ',
        },
      },
      value: {
        name: "with_image",
        mode: "display",
        label: "Description",
        type: "rich_text_editor",
        display: {
          attributes: {
            placeholder: "Insert some text...",
          },
        },
      },
    },
  ],
  attributes: [
    {
      name: "behavior_no_controls",
      type: "switch",
      hint: "Don't display controls by default. Allows user to toggle",
      value: false,
    },
    {
      name: "no_toolbar",
      type: "switch",
      hint: "Don't display controls by default.",
      value: false,
    },
    {
      name: "height",
      type: "text",
      value: "100%",
    },
    {
      name: "preview.height",
      type: "text",
      value: "150",
    },
    {
      name: "preview.width",
      type: "text",
      value: "120",
    },
    filled,
    placeholder,
    no_value_text,
    no_value_behavior,
    {
      name: "tagging.mentions.allow",
      type: "switch",
      hint: "Allow tagging",
      value: true,
    },
    {
      name: "tagging.mentions.link",
      type: "text",
      hint: "API to fetch users from",
      value: "",
    },
  ],
  classes: [],
};
