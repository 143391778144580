const debug = require("debug")("atman.components.currency"); // eslint-disable-line
import {
  solo,
  shaped,
  solo_inverted,
  background_color,
  clearable,
  rounded,
  readonly,
  disabled,
  outlined,
  filled,
  no_value_text,
  no_value_behavior,
  dense,
} from "@/components/fields/attributes.js";
export default {
  label: "Currency",
  type: "currency",
  description: "Displays a currency field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Currency field.",
      label: "Default",
      variables: {
        data: {
          some_name: {
            currency: "INR",
            amount: 90,
          },
        },
      },
      value: {
        name: "some_name",
        label: "Cost",
        type: "currency",
      },
    },
    {
      id: "default_display",
      description:
        'Use <code>"mode": "display"</code> to view Currency field in <b>readonly</b> mode.',
      label: "Default (Display)",
      variables: {
        data: {
          some_name2: {
            currency: "INR",
            amount: 90,
          },
        },
      },
      value: {
        name: "some_name2",
        label: "Cost",
        mode: "display",
        type: "currency",
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    solo,
    shaped,
    solo_inverted,
    background_color,
    clearable,
    rounded,
    readonly,
    disabled,
    outlined,
    filled,
    dense,
  ],
  classes: [],
};
