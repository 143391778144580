import ClientSidePage from "@/views/ClientSidePage.vue";

export default [
  {
    path: "/permissions",
    name: "Permissions",
    component: ClientSidePage,
    meta: { unauthenticated: false, page_key: "permissions" },
  },
  {
    path: "/permissions/:id",
    name: "PermissionsDetails",
    component: ClientSidePage,
    meta: { unauthenticated: false, page_key: "permissions" },
  },
];
