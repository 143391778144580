<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        >Checks ({{ checks.length }})</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="12" v-for="(check, i) in checks" :key="i">
            <div v-if="getType(check) == 'fact'">
              <RuleCheck :index="i" :check="check" />
            </div>
            <div v-else-if="getType(check) == 'condition'">
              <TipText content="This is a nested condition" />
              <RuleConditionDesigner :index="i" :condition="check" />
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RuleCheck from "./RuleCheck.vue";
import TipText from "../../TipText.vue";
export default {
  name: "RuleChecks",
  components: {
    RuleCheck,
    RuleConditionDesigner: () =>
      import(
        "@/components/fields/Questionaire/designer/rules/RuleConditionDesigner.vue"
      ),
    TipText,
  },
  props: {
    checks: {},
  },
  methods: {
    getType(check) {
      let result = check.fact ? "fact" : "condition";
      return result;
    },
  },
};
</script>
