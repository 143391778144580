<template>
  <v-tabs
    v-model="tabModel"
    :show-arrows="true"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- :background-color="headerBackgroundColor" -->
    <slot></slot>
  </v-tabs>
</template>
<script>
import { getHeaderBackgroundColor } from "@/util.js";
export default {
  inheritAttrs: false,
  name: "AtmanTabs",
  props: {
    value: {
      type: Number,
    },
  },
  watch: {
    value() {
      this.tabModel = this.value;
    },
  },
  data() {
    return {
      tabModel: this.value,
    };
  },
  computed: {
    headerBackgroundColor() {
      return getHeaderBackgroundColor();
    },
  },
};
</script>
