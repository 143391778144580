<template>
  <div>
    <v-overlay v-if="showPreview" :absolute="true" opacity="0.01" :value="true">
    </v-overlay>
    <FieldLabel
      :label="label"
      :definition="value"
      :attributes="labelAttributes"
      v-if="label"
      class="text-left"
    ></FieldLabel>
    <div
      class="behavior_rte_container mb-2 pb-2"
      :class="behaviorClasses"
      ref="rte_container"
      v-if="!isDisplayMode"
    >
      <quill-editor
        v-if="editorOption"
        aut-rich-text-editor-input
        class="quillEditor"
        ref="quillEditor"
        :attributes="displayAttributes"
        :class="filledClass"
        :disabled="isReadOnly || isDisabled"
        v-model="fieldValue"
        :options="{ ...editorOption, placeholder: placeholder }"
        @change="onEditorChange($event)"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            aut-toolbar-toggle
            class="behavior_more"
            @click="toggleControls"
            >mdi-dots-horizontal</v-icon
          >
        </template>
        Toggle Controls
      </v-tooltip>
    </div>
    <div
      v-else
      aut-rich-text-editor-display
      class="d-flex display_container"
      :class="{ preview: showPreview }"
    >
      <v-icon v-if="value.icon">mdi {{ value.icon }}</v-icon>
      <ClampedContainer
        :clamp="displayAttributes.clamped"
        class="behaviour_rich_text_display_container"
      >
        <!-- NOTE: The class "ql-editor" ensures that any font styles specified in the rich text editor are respected-->
        <div
          class="behavior_reset_margins ql-editor pa-0"
          :class="htmlClasses"
          aut-html-value
          v-html="effectiveDisplayValue"
        ></div>
      </ClampedContainer>
      <!-- <VMessages :value="errorBucket" color="error"/> -->
    </div>
  </div>
</template>
<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill-emoji/dist/quill-emoji.css";
import { ImageDrop } from "quill-image-drop-module";
import BlotFormatter from "quill-blot-formatter";

import { fieldMixin } from "@/components/mixin.js";

import definition from "./definition";

import * as Emoji from "quill-emoji";
import MagicUrl from "quill-magic-url";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/emoji", Emoji);
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/blotFormatter", BlotFormatter);

const debug = require("debug")("atman.components.rich_text_editor"); // eslint-disable-line

export default {
  name: "RichTextEditor",
  components: {
    quillEditor,
    ClampedContainer: () =>
      import("@/components/pageElements/ClampedContainer"),
  },
  mixins: [fieldMixin],
  data() {
    return {
      definition,
      editorOption: null,
      allUsers: [],
      allHashtags: [],
    };
  },
  watch: {
    preview() {
      this.setPreviewSize();
    },
  },
  mounted() {
    this.initialiseEditor();
    this.determineHeight();
    this.setPreviewSize();
    this.hideToolbar();
  },
  computed: {
    effectiveDisplayValue() {
      if (this.fieldValue) {
        return this.fieldValue;
      }
      const noValueText = this.displayAttributes?.no_value?.text;
      if (noValueText) {
        return `<span>${noValueText}</span>`;
      }
      return "";
    },
    placeholder() {
      return this.value?.placeholder || this.displayAttributes?.placeholder;
    },
    htmlClasses() {
      return this.isDisplayMode ? "behavior_editor_container" : "";
    },
    behaviorClasses() {
      return this?.displayAttributes?.behavior_no_controls
        ? "behavior_no_controls"
        : "";
    },
    preview() {
      return this.displayAttributes?.preview;
    },
    showPreview() {
      return this.preview?.show == true;
    },
    noToolbar() {
      return this.displayAttributes?.no_toolbar;
    },
    filledClass() {
      const filled = this.displayAttributes?.filled;
      if (filled) {
        return "filled";
      }
      return "";
    },
  },
  methods: {
    hideToolbar() {
      const moreToolbar = this.$el.querySelector(".behavior_more");
      if (this.noToolbar) {
        moreToolbar.classList.add("hidden");
      }
    },
    initialiseEditor() {
      /* DON'T Load the editor in display mode */
      if (this.value.mode == "display" || this.isDisplayMode) {
        return;
      }
      const allowTagging = this.displayAttributes?.tagging;
      let initialiseMention;
      if (allowTagging) {
        initialiseMention = this.initialiseMentionModule();
        if (allowTagging?.mentions?.allow) {
          this.getUsers();
        }
        if (allowTagging?.hashtags?.allow) {
          this.getHashtags();
        }
      }
      this.editorOption = this.noToolbar
        ? {
            theme: "snow",
            readOnly: true, //NOTE: This has no effect. See the disabled prop
            modules: {
              // Reference: https://quilljs.com/docs/modules/toolbar/
              toolbar: false,
              "emoji-shortname": true,
              "emoji-textarea": false, //Adds an emoji icon on the textarea. The menu does not look good
              mention: initialiseMention,
              magicUrl: true,
              imageDrop: true,
              blotFormatter: {},
            },
          }
        : {
            theme: "snow",
            readOnly: true, //NOTE: This has no effect. See the disabled prop
            modules: {
              // Reference: https://quilljs.com/docs/modules/toolbar/

              toolbar: [
                "bold",
                "italic",
                "strike",
                "underline",
                "code",
                { color: [] },
                { background: [] },
                // { font: [] }, - Removing support for changing font. At some point it may be brought back
                { align: [] },
                { list: "ordered" },
                { list: "bullet" },
                { script: "sub" },
                { script: "super" },
                { header: [1, 2, 3, 4, 5, 6] },
                { size: ["small", false, "large", "huge"] },
                "emoji",
                "clean",
              ],
              "emoji-toolbar": true,
              "emoji-shortname": true,
              "emoji-textarea": false, //Adds an emoji icon on the textarea. The menu does not look good
              mention: initialiseMention,
              magicUrl: true,
              imageDrop: true,
              blotFormatter: {},
            },
          };
    },
    initialiseMentionModule() {
      const component = this;
      const mentionModule = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        linkTarget: "_blank",
        source: function (searchTerm, renderList, mentionChar) {
          let matchedMentions;
          if (mentionChar === "@") {
            matchedMentions = component.filterUser(searchTerm);
          } else {
            matchedMentions = component.filterTag(searchTerm);
          }
          renderList(matchedMentions);
        },
      };
      return mentionModule;
    },
    async getUsers() {
      const allUsers = await this.$store.dispatch(
        `${this.context}/fetchUsers`,
        { all: true }
      );
      const link = this.displayAttributes?.tagging?.mentions?.link;
      this.allUsers = allUsers.map((user) => {
        user.value = user.first_name;
        user.link = link.includes("_user_id")
          ? link.replace("_user_id", `${user.id}`)
          : "";
        return user;
      });
      debug(`users`, allUsers);
    },
    async getHashtags() {
      const allHashtags = await this.$store.dispatch(
        `${this.context}/fetchHashtags`
      );
      this.allHashtags = allHashtags;
      debug(`hashtags`, allHashtags);
    },
    filterTag(searchTerm) {
      const searchTermCase = (searchTerm || "").toLowerCase();
      if (!searchTermCase) {
        return this.allHashtags;
      }
      const filteredTags = this.allHashtags.filter((tags) => {
        const tagVal = (tags.value || []).toString().toLowerCase();
        return tagVal.includes(searchTermCase);
      });
      debug(`Filtered Tags`, filteredTags);
      return filteredTags;
    },
    filterUser(searchTerm) {
      const searchTermCase = (searchTerm || "").toLowerCase();
      if (!searchTermCase) {
        return this.allUsers;
      }
      const filteredUsers = this.allUsers.filter((user) => {
        const userVal = (user.value || []).toString().toLowerCase();
        return userVal.includes(searchTermCase);
      });
      debug(`Filtered Users`, filteredUsers);
      return filteredUsers;
    },
    onEditorChange({ html }) {
      debug("in RichTextEditor editorChange", html);
      this.$set(this, "fieldValue", html);
      this.$emit("update", html);
    },
    toggleControls() {
      this.$refs.rte_container.classList.toggle("behavior_no_controls");
    },
    determineHeight() {
      const height = this.displayAttributes?.height;
      debug(`setting height as`, height);
      this.$el.style.setProperty("--editor-height", height);
    },
    setPreviewSize() {
      const height = this.preview?.height;
      const width = this.preview?.width;

      if (height) {
        this.$el.style.setProperty("--preview-height", `${height}`);
      }
      if (width) {
        this.$el.style.setProperty("--preview-width", `${width}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// HACK to fix https://github.com/feldspartech/atman-ui/issues/1983
.behavior_editor_container {
  overflow: hidden;
}
.behavior_rte_container {
  position: relative;
  &.behavior_reset_margins {
    ::v-deep {
      p {
        margin-bottom: 0px; //vuetify adds a largish margin below paragraph tags which is not necessary here
      }
    }
  }
  .behavior_more {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 8px 8px 0px 0px;
    &:focus::after {
      opacity: 0 !important;
    }
  }
  &.behavior_no_controls {
    ::v-deep {
      .ql-toolbar {
        border-bottom: none;
        .ql-formats {
          display: none;
        }
      }
    }
  }
  .quillEditor ::v-deep {
    .ql-editor {
      height: var(--editor-height);
    }
  }
}
.theme--light {
  .filled {
    background: #f1f1f1;
  }
}
.theme--dark {
  .filled {
    background: #0f0f0f;
  }
}
.preview {
  width: var(--preview-width, 120px);
  height: var(--preview-height, 150px);
  font-size: 0.3em;
}
.display_container ::v-deep {
  img {
    max-width: 100%;
  }
}
</style>
