import { subSeconds, subHours, subMinutes, addSeconds, addHours, addMinutes, subDays, addDays, addYears, subYears, addMonths, subMonths } from "date-fns"; // eslint-disable-line

export default {
  type: "count_down",
  label: "CountDown",
  description: "Displays a CountDown",
  properties: ["type", "display", "block"],
  templates: [
    {
      id: "default",
      label: "Default",
      value: {
        name: "some_name",

        type: "count_down",
      },
    },

    {
      id: "few_seconds_ago",
      label: "Few seconds Ago",
      variables: {
        data: {
          some_name: `${subSeconds(new Date(), 40).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_seconds_from_now",
      label: "Few seconds From Now",
      variables: {
        data: {
          some_name: `${addSeconds(new Date(), 40).toISOString()}`,
        },
      },
      value: {
        format: "minutes",
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_minutes_ago",
      label: "Few minutes Ago",
      variables: {
        data: {
          some_name: `${subMinutes(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_Minutes_from_now",
      label: "Few Minutes From Now",
      variables: {
        data: {
          some_name: `${addMinutes(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_Hours_ago",
      label: "Few Hours Ago",
      variables: {
        data: {
          some_name: `${subHours(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_Hours_from_now",
      label: "Few Hours From Now",
      variables: {
        data: {
          some_name: `${addHours(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_days_ago",
      label: "Few Days Ago",
      variables: {
        data: {
          some_name: `${subDays(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_days_from_now",
      label: "Few Days From Now",
      variables: {
        data: {
          some_name: `${addDays(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_months_ago",
      label: "Few months Ago",
      variables: {
        data: {
          some_name: `${subMonths(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_months_from_now",
      label: "Few Months From Now",
      variables: {
        data: {
          some_name: `${addMonths(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_years_ago",
      label: "Few years Ago",
      variables: {
        data: {
          some_name: `${subYears(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        format: "years months",
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "few_years_from_now",
      label: "Few years From Now",
      variables: {
        data: {
          some_name: `${addYears(new Date(), 4).toISOString()}`,
        },
      },
      value: {
        format: "years",
        name: "some_name",

        type: "count_down",
      },
    },
    {
      id: "custom_label",
      label: "Custom Label",
      variables: {
        data: {
          custom_label: `${subSeconds(new Date(), 40).toISOString()}`,
        },
      },
      value: {
        name: "custom_label",
        display: {
          attributes: {
            past_label: "spent",
          },
        },
        type: "count_down",
      },
    },
    {
      id: "countdown_limit",
      label: "Countdown with Limit",
      variables: {
        data: {
          countdown_limit: `${addSeconds(new Date(), 5).toISOString()}`,
        },
      },
      value: {
        name: "countdown_limit",
        display: {
          attributes: {
            limit: "future",
          },
        },
        type: "count_down",
      },
    },
  ],
};
