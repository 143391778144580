<template>
  <FieldLabel
    mode="display"
    :label="label"
    :attributes="labelAttributes"
    aut-amt-display
  >
    <FieldDisplayValue
      v-if="amount"
      :value="displayValue"
      :definition="value"
    />
  </FieldLabel>
</template>

<script>
import money from "@/money";
export default {
  name: "CurrencyDisplay",
  props: {
    label: {
      type: String,
    },
    labelAttributes: {
      type: Object,
    },
    amount: {
      type: Object,
    },
    value: {
      type: Object,
    },
  },
  computed: {
    displayValue() {
      const result = money.format(this.amount);
      return result;
    },
  },
};
</script>
