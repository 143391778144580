import { safeClone } from "@/util";
import { isPlainObject } from "lodash";
const debug = require("debug")("atman.field.mixin:dropdown"); // eslint-disable-line
export default {
  data() {
    return {
      fieldValue: safeClone(this.value),
    };
  },
  watch: {
    value() {
      this.fieldValue = safeClone(this.value);
    },
  },
  props: {
    value: {},
    definition: {
      type: Object,
    },
    attributes: {
      type: Object,
    },
    classes: {},
    items: {
      type: Array,
    },
    rules: {
      type: Array,
    },
    label: {},
  },
  computed: {
    showDetail() {
      const fields = this.attributes?.detail?.fields;
      return Array.isArray(fields);
    },
    appendAttributes() {
      const attributes = this.attributes?.["append"] || {};
      if (isPlainObject(attributes)) {
        return attributes;
      }

      return {};
    },
  },
  methods: {
    emitFieldValue() {
      this.$emit("update", this.fieldValue);
    },
    appendIconAction() {
      const action = this.appendAttributes.action || {};

      if (!isPlainObject(action)) {
        return;
      }
      if (!Object.keys(action).length) {
        return;
      }

      this.$emit("click:append", { definition: { value: action } });
    },
  },
};
