var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":_vm.CLASSES,"item-text":"label","item-id":"id","label":"Add Class"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.addClass(_vm.newClass.id)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(item.id)+" ("+_vm._s(item.label)+")")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(item.hint)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}:null],null,true)},[_vm._v(" "+_vm._s(item.hint)+" ")])],1)])],1)],1)]}}]),model:{value:(_vm.newClass),callback:function ($$v) {_vm.newClass=$$v},expression:"newClass"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addClass(_vm.newClass.id)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.classes),function(id){return _c('v-chip',{key:id,staticClass:"mr-1 mt-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeClass(id)}}},[_vm._v(" "+_vm._s(id)+" ")])}),1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('SpacingControl',{on:{"update":function($event){return _vm.addClass($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }