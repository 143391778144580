<template>
  <v-col cols="12" lg="6" aut-key-value-pair-key>
    <Field :definition="definition" :context="context" v-on="$listeners" />
  </v-col>
</template>
<script>
export default {
  name: "KeyField",
  props: {
    definition: {},
    context: {
      type: String,
    },
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
};
</script>
