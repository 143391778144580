<template>
  <v-card>
    <v-card-title>
      <v-btn elevation="2" @click="getPrevDay">
        <v-icon left> mdi-chevron-left </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div>{{ formattedDate(chosenDate) }}</div>
      <v-spacer></v-spacer>
      <v-btn elevation="2" @click="getNextDay">
        <v-icon right> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="fieldValue">
      <v-chip
        @click="selectSlot(slot)"
        :color="isSelected(slot)"
        v-for="(slot, i) in value.seedData"
        :key="i"
        aut-chips
      >
        <span>{{ slot.start_time }}-{{ slot.end_time }}</span>
      </v-chip>
      <v-text-field
        v-model="fieldValue.name"
        hint="Enter the name"
        label="Name"
        aut-scheduler-name
      ></v-text-field>
      <v-text-field
        v-model="fieldValue.description"
        hint="Enter the description"
        label="Description"
        aut-scheduler-desc
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>
<script>
import { addDays, subDays, format, parseISO } from "date-fns";
import { makeServerCall } from "@/util.js";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.appointment_scheduler");// eslint-disable-line

export default {
  name: "AppointmentScheduler",
  mixins: [fieldMixin],
  data() {
    return {
      chosenDate: new Date(),
    };
  },
  methods: {
    isSelected(slot) {
      return slot.start_time == this.fieldValue?.start_time ? "green" : "grey";
    },
    selectSlot(slot) {
      this.fieldValue.start_time = slot.start_time;
      this.fieldValue.end_time = slot.end_time;
      this.fieldValue.date = this.chosenDate;
    },
    formattedDate(value) {
      try {
        return format(parseISO(new Date(value).toISOString()), "yyyy-MM-dd");
      } catch (e) {
        console.error(e);
      }
      return value;
    },
    async getSlots(/* inputDate */) {
      const component = this;
      const apiDetails = component?.value?.options?.apis?.data;
      if (!apiDetails) {
        return;
      }
      const result = await makeServerCall(apiDetails);
      this.value.seedData = result.data;
    },
    getNextDay() {
      this.chosenDate = addDays(this.chosenDate, 1);
      this.getSlots(this.chosenDate);
    },
    getPrevDay() {
      this.chosenDate = subDays(this.chosenDate, 1);
      this.getSlots(this.chosenDate);
    },
  },
};
</script>
