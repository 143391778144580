import ZoomIntegration from "./ZoomIntegration";

export default [
  {
    path: "/integrations/zoom",
    name: "ZoomIntegration",
    component: ZoomIntegration,
    meta: { unauthenticated: false },
  },
];
