export default {
  type: "page",
  id: "application_list",
  title: "Applications",
  _permissions: {
    enabled: true,
    permission: {
      value: "add_applications",
    },
  },
  classes: "behavior-no-editor behavior-no-fav behavior-no-pin",
  content: [
    {
      id: "list",
      type: "list",
      display: {
        width: "12",
      },
      definition: {
        mode: "display",
        fields: [
          {
            label: "Name",
            name: "name",
            mode: "display",
            type: "text",
          },
          {
            label: "Description",
            name: "description",
            mode: "display",
            type: "rich_text_editor",
            display: {
              width: "12",
              attributes: {
                clamped: "100",
              },
            },
          },
          {
            label: "Actions",
            name: "actions",
            type: "actions",
            value: [
              {
                name: "view",
                hint: "View",
                icon: "mdi-eye",
                type: "action",
                value: {
                  type: "navigation",
                  url: "/applications/{_row->id}",
                },
                display: {
                  attributes: {},
                },
              },
              {
                name: "edit",
                hint: "Edit",
                icon: "mdi-pencil",
                permission: "add_applications",
                type: "action",
                value: {
                  type: "navigation",
                  url: "/edit_application/{_row->id}",
                },
                display: {
                  attributes: {},
                },
              },
              {
                name: "delete",
                hint: "Delete",
                icon: "mdi-delete",
                permission: "add_applications",
                type: "action",
                confirmation: "Are you sure?",
                value: {
                  type: "delete",
                  url: "{_context->path}/{_row->id}",
                  success: {
                    message: "Deletion successful!",
                  },
                },
                display: {
                  attributes: {
                    color: "error",
                  },
                },
              },
            ],
          },
        ],
        actions: [
          {
            name: "add_application",
            label: "Add Application",
            type: "action",
            permission: "add_applications",
            value: {
              type: "navigation",
              url: "/create_application",
            },
            display: {
              attributes: {
                color: "primary",
              },
            },
          },
          {
            name: "add_application_wizard",
            label: "Add Application (Wizard)",
            mode: {
              conditions: [
                {
                  rule: "{_settings->application_creation.wizard.enabled} != true",
                  value: "ignored",
                },
              ],
            },
            type: "action",
            permission: "add_applications",
            value: {
              type: "navigation",
              url: "/create_application?mode=wizard",
            },
            display: {
              attributes: {
                color: "primary",
              },
            },
          },
        ],
      },
      apis: {
        data: {
          url: "{_context->path}",
          refresh_interval: 300000,
          type: "get",
        },
      },
    },
  ],
};
