<template>
  <v-container>
    <v-card v-if="!isDisplayMode" flat outlined class="date-time-card">
      <v-card-title class="ma-0">
        <v-btn color="transparent" rounded depressed @click="getPrevDay">
          <v-icon left> mdi-chevron-left </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :aut-date-time="value.name"
              label
              color="transparent"
              class="date"
              rounded
              depressed
              v-on="on"
              v-bind="attrs"
              >{{ formattedDate }}</v-btn
            >
          </template>
          <v-date-picker
            :aut-date-time-picker="value.name"
            v-model="formattedDate"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn color="transparent" rounded depressed @click="getNextDay">
          <v-icon right> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-chip
          @click="selectSlot(slot)"
          class="mx-1 white--text"
          color="primary"
          :outlined="!isSelected(slot)"
          label
          v-for="(slot, i) in value.seedData"
          :key="i"
          :aut-date-time-slot="value.name"
        >
          <span>{{ slot.start_time }} - {{ slot.end_time }}</span>
        </v-chip>
      </v-card-text>
    </v-card>
    <div v-if="isDisplayMode" class="text-left">
      <label v-if="label" class="text-left"> {{ label }}: </label>
      <span class="text-left">{{ displayValue }}</span>
    </div>
  </v-container>
</template>
<script>
import { addDays, subDays, format, parseISO } from "date-fns";
import { fieldMixin } from "@/components/mixin.js";
import { defaultsDeep } from "lodash";
const debug = require("debug")("atman.components.date_time_picker"); // eslint-disable-line

export default {
  name: "DateTimePicker",
  mixins: [fieldMixin],
  data() {
    return {
      dateMenu: false,
    };
  },
  computed: {
    formattedDate: {
      get() {
        const date = this.fieldValue?.date || new Date().toISOString();
        try {
          return format(parseISO(date), "yyyy-MM-dd");
        } catch (e) {
          console.error(`Unable to parse date`, e);
        }
        return format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
      },
      set(date) {
        const dateTimeValue = defaultsDeep(
          {},
          { date: new Date(date).toISOString() },
          this.fieldValue || {}
        );
        this.$set(this.value, "value", dateTimeValue);
      },
    },
    displayValue() {
      if (!this.fieldValue) {
        return "";
      }
      return `${this.formattedDate} ${this.fieldValue?.start_time} - ${this.fieldValue?.end_time}`;
    },
  },
  methods: {
    isSelected(slot) {
      return slot?.start_time == this.fieldValue?.start_time;
    },
    selectSlot(slot) {
      const timeSlot = defaultsDeep({}, slot, this.fieldValue || {});
      this.$set(this.value, "value", timeSlot);
    },
    getNextDay() {
      this.formattedDate = addDays(new Date(this.formattedDate), 1);
    },
    getPrevDay() {
      this.formattedDate = subDays(new Date(this.formattedDate), 1);
    },
  },
};
</script>
<style scoped>
.date-time-card {
  border: solid grey 1.5px;
}
.date {
  font-size: medium;
}
</style>
