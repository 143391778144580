import { height } from "@/components/fields/attributes";
export default {
  label: "Iframe Field",
  type: "iframe",
  description: "Displays a Iframe field",
  properties: ["name", "label", "type", "mode", "display", "block", "value"],
  templates: [
    {
      id: "default",
      label: "Default",
      variables: {
        data: {
          default: "",
        },
      },
      value: {
        display: {
          attributes: {
            width: "100%",
            height: 500,
          },
        },
        name: "default",
        label: "iFrame",
        type: "iframe",
      },
    },
    {
      id: "input_with_value",
      keyword: ["tutorial"],
      description: "Will display iFrame even in input mode if value is present",
      label: "Display",
      variables: {
        data: {
          input_with_value: "https://www.youtube.com/embed/cbvkbogYyN0",
        },
      },
      value: {
        name: "input_with_value",
        label: "Some Label",
        mode: "input",
        type: "iframe",
      },
    },
  ],
  attributes: [
    height,
    {
      name: "width",
      type: "text",
      hint: "IFrame Width in Percent",
      value: "100%",
    },
    {
      name: "frameborder",
      type: "text",
      hint: "IFrame Border",
      value: "0",
    },
    {
      name: "add_iframe_button_color",
      type: "color",
      hint: "choose color",
      value: "primary",
    },
  ],
};
