<template>
  <v-col cols="12" lg="6">
    The field type [{{ value.type }}] is not supported
  </v-col>
</template>
<script>
export default {
  name: "UnsupportedType",
  props: {
    value: {
      type: Object,
    },
  },
};
</script>
