<template>
  <div>
    <FieldLabel
      :definition="value"
      :mode="value.mode"
      :label="label"
      :attributes="labelAttributes"
    >
      <v-row
        v-if="isInputMode"
        dense
        no-gutters
        class="mt-0 pa-0"
        :key="arrayKey"
      >
        <draggable v-model="arrayItems">
          <v-col :cols="12" v-for="(arrayItem, i) in arrayItems" :key="i">
            <v-hover v-slot="{ hover }">
              <div :class="`${hover ? 'behaviour_hover' : ''} elevation-1`">
                <slot v-bind:item="arrayItem" v-bind:index="i"></slot>
              </div>
            </v-hover>
          </v-col>
        </draggable>
      </v-row>
      <div v-else>
        <v-row
          v-if="noLayout"
          dense
          no-gutters
          class="mt-0 pa-0"
          :key="arrayKey"
        >
          <v-col :cols="12" v-for="(arrayItem, i) in arrayItems" :key="i">
            <slot v-bind:item="arrayItem" v-bind:index="i"></slot>
          </v-col>
        </v-row>
        <ul v-if="bulletedLayout">
          <li v-for="(arrayItem, i) in arrayItems" :key="i">
            <slot v-bind:item="arrayItem" v-bind:index="i"></slot>
          </li>
        </ul>
      </div>
    </FieldLabel>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "ArrayFieldLayout",
  components: {
    draggable,
  },
  props: {
    value: {
      type: Object,
    },
    items: {
      type: Array,
    },
    arrayKey: {
      type: Number,
    },
  },
  computed: {
    arrayItems: {
      get() {
        return this.items || [];
      },
      set(items) {
        this.$emit("update:items", items);
      },
    },
    label() {
      return this.value.label;
    },
    labelAttributes() {
      return this.value?.display?.attributes?.label || {};
    },
    isInputMode() {
      return this.value.mode == "input";
    },
    noLayout() {
      return !this.value?.display?.attributes?.layout;
    },
    bulletedLayout() {
      return this.value?.display?.attributes?.layout == "unordered_list";
    },
  },
};
</script>
