<template functional>
  <div :class="data.class" class="d-flex">
    <span class="text-left" :class="textColorClass" aut-field-value>
      <div v-if="props.format == 'elapsed'">
        {{ props.date | elapsedTime }}
      </div>
      <div v-else-if="props.format">
        {{ props.date | date({ date_format: props.format }) }}
      </div>
      <div v-else>{{ props.date | date }}</div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
    },
    format: {
      type: String,
    },
  },
};
</script>
