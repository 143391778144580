<template>
  <v-row dense>
    <v-col cols="12">
      <v-textarea
        dense
        rows="1"
        auto-grow
        aut-lookup-text
        :label="label"
        v-model="value.value"
        :hint="value.hint"
        :persistent-hint="true"
        required
        :rules="applicableRules"
        @change="updateDefinition"
      ></v-textarea>
    </v-col>
  </v-row>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.lookup_field"); // eslint-disable-line 

export default {
  name: "LookupField",
  mixins: [fieldMixin],
  methods: {
    updateDefinition() {
      this.$emit("update", this.value.value);
    },
  },
};
</script>
