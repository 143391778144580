<template>
  <div :class="badgeClasses">
    <v-badge
      bordered
      bottom
      :color="userStatusIndicator"
      dot
      offset-x="10"
      offset-y="10"
    >
      <ImageField v-if="imageData" v-bind="imageData" :key="imageKey" />
      <v-avatar v-else size="40" color="primary">
        <v-icon large>mdi-account</v-icon>
      </v-avatar>

      <v-overlay
        @click.stop="navigate"
        :class="{ behavior_clickable: navigationUrl }"
        :value="true"
        absolute
        color="transparent"
      >
      </v-overlay>
    </v-badge>
    <div class="" v-bind:class="userNameClasses">
      <UserName v-bind="propsData" v-if="propsData" />
    </div>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import { mapState, mapActions } from "vuex";
import { isPlainObject, defaultsDeep } from "lodash";
import { clone } from "@/util";
import { hasAVariable, navigateTo, getFileSrc } from "@/util";
import definition from "./definition";

const debug = require("debug")("atman.components.user_badge"); // eslint-disable-line
export default {
  name: "UserBadge",
  components: {
    UserName: () => import("@/components/fields/UserName/UserName.vue"),
    ImageField: () => import("@/components/fields/ImageField/ImageField.vue"),
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      profile: null,
      navigationUrl: "",
      imageData: "",
      imageKey: 1,
      definition,
    };
  },
  mixins: [fieldMixin],
  computed: {
    ...mapState(["user"]),
    badgeClasses() {
      return this.displayAttributes?.username?.position == "right"
        ? "d-flex"
        : "";
    },
    userNameClasses() {
      let result =
        this.displayAttributes?.username?.position == "right"
          ? "mt-2 ml-2"
          : "";
      return `${result} ${this.displayClasses}`;
    },
    userStatusIndicator() {
      return this.profile?.online_status == "online"
        ? `green accent-4`
        : "grey accent-4";
    },
    imageSrc() {
      const image = this.profile?.image;
      return getFileSrc(image);
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.setupBadge();
      }
    },
    profile() {
      this._derivePropsData();
    },
  },
  mounted() {
    this.setupBadge();
  },
  methods: {
    ...mapActions("user", ["fetchProfile"]),
    _afterFieldValueUpdate() {
      this.setupBadge();
    },
    async setupBadge() {
      await this.loadProfile();
      this.deriveImage();
      this.determineUrl();
    },
    async loadProfile() {
      let user = this.id || this.fieldValue;
      const defaultValue = this.displayAttributes?.default;

      if (!user) {
        if (defaultValue != "me") {
          this.profile = "";
          return;
        }

        debug(`Will fetch for logged in user`);
      }
      this.profile = await this.fetchProfile({
        user,
      });
      debug(
        "user",
        `id: [${this.id}]`,
        `fieldValue: [${this.fieldValue}]`,
        this.profile
      );
    },
    _derivePropsData() {
      const propsData = clone(this.$props);
      if (!isPlainObject(this.profile)) {
        this.propsData = "";
        return;
      }

      propsData.value.name = `${propsData.value.name}_username`;
      propsData.value.path = `${
        propsData?.value?.path || "innerText"
      }_username`;
      delete propsData.value.conditions;

      propsData.value.value = this.profile;
      propsData.value.type = "username";
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
      debug(this.propsData);
    },
    determineUrl() {
      const url = this.displayAttributes?.navigation || "";
      const targetUrl = this.getDynamicValue(url);
      this.navigationUrl = targetUrl;
    },
    deriveImage() {
      if (!isPlainObject(this.profile)) {
        this.imageData = "";
        return;
      }

      const imageData = clone(this.$props);
      const imageAttributes = this.displayAttributes?.image || {};

      if (imageAttributes?.character) {
        const character = imageAttributes.character;
        imageAttributes.character = this.getDynamicValue(character);
      }

      if (imageAttributes?.placeholder) {
        const placeholder = imageAttributes.placeholder;
        imageAttributes.placeholder = this.getDynamicValue(placeholder);
      }

      const attributes = {
        avatar: {
          width: "40",
          classes: ["primary"],
        },
        if_missing: "character",
        character: this.profile?.id,
      };

      imageData.value.display = {
        mode: "avatar",
        attributes: defaultsDeep({}, imageAttributes, attributes),
      };

      imageData.value.name = `${imageData.value.name}_avatar`;
      imageData.value.path = `${imageData?.value?.path || "innerText"}_avatar`;

      imageData.value.value = this.imageSrc;
      imageData.value.type = "image";
      imageData.value.mode = "display";
      this.imageData = imageData;
      this.imageKey++;
    },
    navigate() {
      if (!this.navigationUrl) {
        return;
      }

      navigateTo(this.$router, this.navigationUrl);
    },
    getDynamicValue(key) {
      const value = this.$store?.getters[`${this.context}/dynamicText`]({
        url: key || "",
        customVariables: {
          _user: this.profile,
        },
        isValidKey: (key) => key.startsWith("_user"),
      });

      if (hasAVariable(value)) {
        debug(`Could not determine dynamic value for key ${key}`);
        return "";
      }
      return value;
    },
  },
};
</script>
