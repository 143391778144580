<template>
  <div aut-captcha>
    <v-text-field
      aut-captcha-input
      class="mx-auto"
      v-model="answer"
      :label="`${num1} + ${num2} =`"
      :error="hasError"
      :error-messages="errorMessage"
      :rules="rules"
      required
      outlined
      @blur="checkCaptcha"
      @keydown.enter.prevent="checkCaptcha"
      append-icon="mdi-reload"
      @click:append="generateCaptcha"
    ></v-text-field>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import { rules } from "@/util.js";
const debug = require("debug")("captcha");
export default {
  name: "Captcha",
  mixins: [fieldMixin],
  data() {
    return {
      rules: [rules.mandatory()],
      num1: 0,
      num2: 0,
      sumOfNum: 0,
      answer: "",
      hasError: false,
      errorMessage: "",
    };
  },
  mounted() {
    this.generateCaptcha();
  },
  methods: {
    generateCaptcha() {
      this.answer = "";
      this.num1 = Math.floor(Math.random() * 9 + 1);
      this.num2 = Math.floor(Math.random() * 9 + 1);
      this.sumOfNum = this.num1 + this.num2;
    },
    checkCaptcha() {
      this.hasError = false;
      debug("The entered answer is: " + this.answer);
      debug("The calculated answer is: " + this.sumOfNum);
      if (this.sumOfNum === this.answer * 1) {
        this.hasError = false;
        this.errorMessage = "";
      } else {
        this.hasError = true;
        this.errorMessage = "Enter the correct value.";
      }
    },
  },
};
</script>
