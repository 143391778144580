<template>
  <div class="behavior_clickable" @click="openFile">
    <embed
      :type="mimeType"
      v-if="displayMode == 'embed'"
      aut-file-embed
      class="behavior-embedded-object"
      :src="effectiveSource"
      :width="effectiveWidth"
      :height="effectiveHeight"
    />
    <object
      v-if="displayMode == 'object'"
      aut-file-embed
      class="behavior-embedded-object"
      object
      :type="mimeType"
      :data="effectiveSource"
      :width="effectiveWidth"
      :height="effectiveHeight"
    >
      <v-icon aut-file-icon>{{ fileType }}</v-icon>
      <v-tooltip top v-if="fieldValue">
        <template v-slot:activator="{ on, attrs }">
          <a
            aut-file-link
            v-bind="attrs"
            v-on="on"
            :href="effectiveSource"
            target="_blank"
          >
            <span>{{ effectiveName }}</span>
          </a>
        </template>
        {{ effectiveName }}
      </v-tooltip>
    </object>
    <iframe :src="iframeSrc" v-if="isWordDoc" frameborder="0" class="wordEmbed">
    </iframe>
  </div>
</template>
<script>
import fileMixin from "../mixin";
import { isChildOf } from "@/util";

export default {
  name: "BinaryFile",
  mixins: [fileMixin],
  props: {
    fieldValue: {
      type: Object,
    },

    value: {
      type: Object,
    },
  },

  computed: {
    displayMode() {
      return this.getFeatureValue("binaries.display_mode") || "object";
    },
    iframeSrc() {
      return `https://docs.google.com/gview?url=${this.effectiveSource}&embedded=true`;
    },
    effectiveSource() {
      return this.fieldValue?._file || this.fieldValue?.link;
    },
    effectiveName() {
      return this.fieldValue?.name || this.fieldValue?.id;
    },
  },
  mounted() {
    this.resizeContent();
  },
  methods: {
    openFile() {
      window.open(this.effectiveSource);
    },
    resizeContent() {
      if (this.mode != "embed") {
        return;
      }
      const object = this.$el.querySelector(".behavior-embedded-object");
      const isInAList = isChildOf(this.$el, ".v-data-table");
      const isInACard = isChildOf(this.$el, ".behavior_list_card");

      if (object) {
        if (isInAList) {
          object.setAttribute("width", "100px");
          object.style["object-fit"] = "contain";
        }
        if (isInACard) {
          object.setAttribute("width", "100%");
        }
      }
    },
  },
};
</script>
