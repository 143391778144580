const debug = require("debug")("atman.components.icon"); // eslint-disable-line
export default {
  label: "Icon",
  type: "icon",
  description: "Displays an Icon",
  properties: ["name", "label", "type", "display", "block", "page"],
  templates: [
    {
      id: "default",
      label: "Default",
      value: {
        name: "some_name",
        type: "icon",
        mode: "display",
        value: {
          icon: "mdi-domain",
          color: "green",
        },
      },
    },
    {
      id: "default_input",
      label: "Input Mode",
      variables: {
        data: {
          icon_input: {
            icon: "mdi-domain",
            color: "green",
          },
        },
      },
      value: {
        name: "icon_input",
        type: "icon",
      },
    },
    {
      id: "conditional_1",
      label: "Conditional Example 1",
      variables: {
        data: {
          status: "OK",
        },
      },
      value: {
        name: "some_name",
        type: "icon",
        mode: "display",
        rules: [
          {
            rule: "'{_data->status}' == 'OK'",
            value: {
              icon: "mdi-domain",
              color: "green",
            },
          },
        ],
      },
    },
    {
      id: "conditional_2",
      label: "Conditional Example 2",
      variables: {
        data: {
          status: "NOT_OK",
        },
      },
      value: {
        name: "some_name",
        type: "icon",
        mode: "display",
        rules: [
          {
            rule: "'{_data->status}' == 'NOT_OK'",
            value: {
              icon: "mdi-domain",
              color: "red",
            },
          },
        ],
      },
    },
  ],
};
