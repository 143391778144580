import Vue from "vue";
import { isEqual } from "lodash";
import { clone } from "@/util.js";
const debug = require("debug")("atman.application_wizard.store"); // eslint-disable-line

export const STORE_CONSTS = {
  APPLICATION_WIZARD_STORE: "application_wizard",
  MUTATIONS: {
    DEFINITION: "mutations_definition",
  },
};

export const store = {
  namespaced: true,
  state: () => ({
    application: {
      name: "",
      description: "",
    },
  }),
  getters: {},
  mutations: {
    [STORE_CONSTS.MUTATIONS.DEFINITION](state, application) {
      debug(`in ${STORE_CONSTS.MUTATIONS.DEFINITION}`);
      if (isEqual(state.application, application) || !application) {
        debug(`${STORE_CONSTS.MUTATIONS.DEFINITION} ignored`);
        return;
      }
      Vue.set(state, "application", clone(application));
    },
  },
  actions: {},
};
