<template>
  <div>
    <FieldLabel
      aut-person-name-label
      mode="display"
      v-bind="displayAttributes"
      :label="label"
      :attributes="labelAttributes"
      v-if="isDisplayMode"
    >
      <template v-for="(value, index) in effectiveValue">
        <span aut-person-name-value v-if="value" :key="index">
          {{ value }}&nbsp;
        </span>
      </template>
    </FieldLabel>
    <v-row v-else-if="isFullName">
      <v-text-field
        aut-full-name
        v-model="effectiveValue"
        :label="label"
        @blur="saveName"
      ></v-text-field>
    </v-row>
    <v-row v-else dense>
      <v-col cols="12" lg="2">
        <v-text-field
          aut-salutation
          clearable
          v-model="effectiveValue.salutation"
          label="Salutation"
          @blur="saveName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
          aut-first-name
          clearable
          v-model="effectiveValue.first_name"
          label="First Name"
          @blur="saveName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
          aut-middle-name
          clearable
          v-model="effectiveValue.middle_name"
          label="Middle Name"
          @blur="saveName"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field
          aut-last-name
          clearable
          v-model="effectiveValue.last_name"
          label="Last Name"
          @blur="saveName"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.person_name");
debug("person_name");
export default {
  name: "PersonName",
  mixins: [fieldMixin],
  data() {
    return {
      effectiveValue: {
        salutation: "",
        first_name: "",
        middle_name: "",
        last_name: "",
      },
    };
  },
  computed: {
    hasFields() {
      const fields = this.value?.name_fields || {};
      return !!Object.keys(fields).length;
    },
    isFullName() {
      return typeof this.fieldValue == "string";
    },
  },
  created() {
    this._derive_field_name = "name_fields";
  },
  mounted() {
    debug(`Deriving effective value in mounted`);
    this._deriveEffectiveValue();
  },
  methods: {
    _afterFieldValueUpdate() {
      debug(`Deriving effective value after fieldValue update`);
      this._deriveEffectiveValue();
    },
    saveName() {
      if (this.isFullName) {
        this.fieldValue = this.effectiveValue;
        return;
      }
      if (!this.hasFields) {
        this.fieldValue = Object.assign({}, this.effectiveValue);
        return;
      }
      const fields = this.value.name_fields;
      Object.keys(fields).forEach((key) => {
        this._setDataValue(fields[key], this.effectiveValue[key]);
      });
    },
  },
};
</script>
