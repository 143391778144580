const debug = require('debug')('atman.view.permissions_mixin')  // eslint-disable-line

export default {
  data() {
    return {
      hasPermissions: true,
    };
  },
  mounted() {
    this.checkRoutePermissions();
  },
  watch: {
    hasPermissions() {
      if (this.hasPermissions === false) {
        this.$router.go(-1);
        this.displayErrorMessage("Insufficient permissions to view page");
      }
    },
  },
  methods: {
    checkRoutePermissions() {
      const permission = this.$route.meta?.permission;
      if (
        !permission ||
        !this.isFeatureEnabled("permissions.check_route_permissions")
      ) {
        this.hasPermissions = true;
        return;
      }
      const isAllowed =
        this.$store.getters[`user/canPerformAction`](permission);
      debug(
        `Permission [${permission}] specified at route level. [${isAllowed}]`
      );
      if (isAllowed) {
        this.hasPermissions = true;
        return;
      }
      this.hasPermissions = false;
    },
  },
};
