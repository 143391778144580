import { isPlainObject } from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import { mapActions } from "vuex";
import user from "./user";
import preferences from "./preferences";
import html2canvas from "html2canvas";
import {
  fetchSkin,
  fetchMenu,
  fetchRoles,
  fetchNotifications,
  updateSkin,
} from "./api";
import {
  invalidateCache,
  makeServerCall as utilMakeServerCall,
  constructQueryParam,
  clone,
  createContext,
  checkPermissions,
} from "@/util.js";

import router from "@/router";

const debug = require("debug")("atman.store"); // eslint-disable-line

const concatFavourites = (menu, rootState) => {
  // Append favourites from the user preferences
  const favourites = rootState?.preferences?.favourites || [];
  if (!favourites.length) {
    return menu;
  }
  const result = clone(menu);
  result.splice(1, 0, {
    title: "Favourites",
    icon: "mdi-star",
    child: favourites,
  });
  return result;
};

import {
  PUBLISH_EVENT,
  LAYOUTS,
  SHOW_DIALOG,
  CLOSE_DIALOG,
  ADD_MESSAGE,
  CONTEXT_UPDATE,
  CHOSEN_VALUE,
  REFRESH_SKIN,
  SET_SKIN,
  SHOW_CONFIRMATION,
  CONFIRMATION_CANCEL,
  CONFIRMATION_CONFIRM,
  EMAIL_PAGE,
  SHOW_HISTORY,
  LOGIN_EVENT,
} from "@/constants.js";
import { defaultsDeep } from "lodash";
import { isSamePageWildCard } from "../util";

export {
  PUBLISH_EVENT,
  SHOW_HISTORY,
  SHOW_DIALOG,
  CLOSE_DIALOG,
  CONTEXT_UPDATE,
  CHOSEN_VALUE,
  REFRESH_SKIN,
  SET_SKIN,
  EMAIL_PAGE,
  SHOW_CONFIRMATION,
  CONFIRMATION_CANCEL,
  CONFIRMATION_CONFIRM,
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    preferences,
  },
  state: {
    skin: (() => {
      let skin = {};
      if (localStorage.skin) {
        try {
          skin = JSON.parse(localStorage.skin);
        } catch {
          debug("skin in localStorage is invalid");
        }
      }
      return skin;
    })(),
    menu: [],
    notifications: {
      display: false,
      unread: 0,
      content: [],
    },
    confirmationContent: "",
    dialog: {
      display: false,
      showUnderConstruction: false,
      content: null,
      context: null,
    },
    displayConfirmation: false,
    historyParams: null,
    displayHistory: false,

    email: {
      dialog: false,
      content: null,
    },
    link_user: {
      dialog: false,
      path: null,
    },
    no_image: "/no_image.jpg",
  },
  getters: {
    defaultPage: (state) => (role) => {
      const methodDebug = debug.extend("defaultPage");
      if (!role) {
        methodDebug(`No role sent. Returning default`);
        return "/";
      }
      const configuredPage = state?.skin?.roles?.[role]?.home_page;
      methodDebug(`default Page configured for role is: ${configuredPage}`);
      const result = configuredPage || "/";
      return result;
    },
    isClassicLayout: (state) => {
      let layout = state.skin?.layout;
      if (isPlainObject(layout)) {
        layout = layout.value;
      }
      return !layout || layout == LAYOUTS.CLASSIC;
    },
    header: (state) => state.skin.header,
    menu: (state, getters, rootState) => {
      return concatFavourites(state.menu, rootState);
    },
    isPinned: (state) => (url) => {
      debug(state?.skin?.pinned, url);
      return (state?.skin?.pinned || []).find(({ href }) => {
        return isSamePageWildCard(href, url);
      });
    },
    pagesPinnedToDomain: (state) => (url) => {
      debug(`pinned pages`, state?.skin?.pinned);
      return (state.skin?.pinned || []).filter(({ target }) => {
        return target == url;
      });
    },
    domainFieldSettings: (state) => {
      return state?.skin?.fields || {};
    },
    domainFeatures: (state) => {
      const savedFeatures = state?.skin || {};
      debug(`domainFeatures returning`, savedFeatures);
      return savedFeatures;
    },
  },
  mutations: {
    togglePageDialog(state, toggle) {
      Vue.set(state.dialog, "display", toggle);
    },
    toggleEmailPageDialog(state, toggle) {
      Vue.set(state.email, "dialog", toggle);
    },
    toggleHistoryDialog(state, toggle) {
      Vue.set(state, "displayHistory", toggle);
    },
    [EMAIL_PAGE](state, pageContent) {
      debug(`In EMAIL_PAGE`, pageContent);
      Vue.set(state.email, "dialog", true);
      Vue.set(state.email, "content", pageContent);
    },
    [CLOSE_DIALOG](state, params) {
      debug(`In CLOSE_DIALOG`, params);
      Vue.set(state, "dialog", {});
    },
    [SET_SKIN](state, skin) {
      if (!skin) {
        return;
      }
      localStorage.skin = JSON.stringify(skin);
      Vue.set(state, "skin", skin);
    },
    setMenu(state, menu) {
      Vue.set(state, "menu", menu);
    },
    toggleNotificationDisplay(state) {
      const currentValue = state.notifications.display || false;
      Vue.set(state.notifications, "display", !currentValue);
      debug(`Notification display`, state.notifications.display);
    },

    addNotifications(state, newNotifications = []) {
      const notifications = [
        ...newNotifications,
        ...(state.notifications?.content || []),
      ];
      debug(
        `Adding notifications`,
        newNotifications,
        state.notifications.content
      );
      Vue.set(state.notifications, "content", notifications);
      const result = (state.notifications?.content || []).filter(
        (notification) => !notification.status
      ).length;
      debug(`unread notifications`, result);
      Vue.set(state.notifications, "unread", result);
    },
    markNotificationRead(state, readNotification) {
      const notifications = state.notifications.content || [];
      const notification = notifications.find((notification) => {
        return notification.id == readNotification.id;
      });
      debug(`marking [${notification.notification}] as read`);
      notification.status = "read";
      const result = (state.notifications?.content || []).filter(
        (notification) => !notification.status
      ).length;
      debug(`unread notifications`, result);
      Vue.set(state.notifications, "unread", result);
    },
    markNotificationsRead(state) {
      const notifications = clone(state.notifications?.content || []);
      notifications.forEach((notification) => {
        notification.status = "read";
      });
      debug(`Marking all notifications as read`, notifications);
      Vue.set(state, "notifications", notifications);
    },
    [ADD_MESSAGE]() {
      debug(`ADD_MESSAGE invoked`);
    },
    hideMessage(state) {
      Vue.set(state, "displayMessage", false);
    },
    [SHOW_CONFIRMATION](state, message) {
      Vue.set(state, "confirmationContent", message);
      Vue.set(state, "displayConfirmation", true);
    },
    [CONFIRMATION_CANCEL](state) {
      Vue.set(state, "displayConfirmation", false);
    },
    [CONFIRMATION_CONFIRM](state) {
      Vue.set(state, "displayConfirmation", false);
    },
    [CONTEXT_UPDATE](state, context) {
      const methodDebug = debug.extend("context_update");
      methodDebug(`context updated`, context);
    },
    logout(state) {
      invalidateCache();
      // Don't clear skin. It should be remembered even after logout. Vue.set(state, "skin", {});
      Vue.set(state, "menu", []);
      Vue.set(state, "user", {});
      Vue.set(state, "preferences", {});
      Vue.set(state, "notifications", {});
      Vue.set(state, "messageContent", "");
      Vue.set(state, "confirmationContent", "");
      Vue.set(state, "messageType", "success");
      Vue.set(state, "displayMessage", false);
      Vue.set(state, "displayConfirmation", false);
      Vue.set(state, "dialog", {});
      Vue.set(state, "pageRoute", null);
    },
  },
  actions: {
    async fetchPage(context, route) {
      const pageContext = createContext(route);
      try {
        let url = `${pageContext.full_path}`;
        if (url.indexOf("page=") == -1) {
          url = `${url}?page=home`;
        }
        return await utilMakeServerCall({
          url,
          type: "get",
        });
      } catch (e) {
        return false;
      }
    },
    async [SHOW_DIALOG]({ state, dispatch }, params) {
      debug(`In SHOW_DIALOG`, params);
      Vue.set(state.dialog, "showUnderConstruction", false);
      const route = params.url || params.target;
      if (route) {
        if (route == "/under_construction") {
          Vue.set(state.dialog, "content", null);
          Vue.set(state.dialog, "showUnderConstruction", true);
          Vue.set(state.dialog, "display", true);
          Vue.set(state.dialog, "context", createContext(route));
          return;
        }
        Vue.set(state.dialog, "content", null);
        const response = await dispatch("fetchPage", route);
        if (
          typeof response == "undefined" ||
          response?._server_actions?.navigation
        ) {
          Vue.set(state.dialog, "content", null);
          Vue.set(state.dialog, "showUnderConstruction", false);
          Vue.set(state.dialog, "display", false);
          return;
        }
        Vue.set(state.dialog, "context", createContext(route));
        Vue.set(state.dialog, "content", response);
        Vue.set(state.dialog, "display", true);
      } else if (params.page) {
        Vue.set(state.dialog, "content", {
          data: params.page,
        });
        Vue.set(state.dialog, "display", true);
      }
    },
    show_tutorial() {
      debug(`SHOW_TUTORIAL invoked`);
    },
    updatePinnedList({ state, dispatch }, { url, pinnedList }) {
      if (!(pinnedList || []).length) {
        debug(`pinned list`, pinnedList);
        return;
      }
      const skin = clone(state.skin);
      const notReorderedList = skin.pinned.filter(({ target }) => {
        return target != url;
      });
      skin.pinned = [...notReorderedList, ...pinnedList];
      dispatch("updateSkin", skin);
    },
    updatePinnedToDomain({ state, dispatch }, { url, width }) {
      const skin = clone(state.skin);
      skin.pinned = skin.pinned || [];
      const widget = skin.pinned.find(({ href }) => {
        return href == url;
      });
      if (!widget) {
        return;
      }
      widget.width = width;
      dispatch("updateSkin", skin);
    },
    addPinnedToDomain(
      { state, dispatch },
      { url, target = "/dashboard", width = "auto", pages }
    ) {
      const skin = clone(state.skin);
      skin.pinned = skin.pinned || [];

      const pinnedItem = skin.pinned.find(({ href }) => {
        return href == url;
      });
      if (pinnedItem) {
        Object.assign(pinnedItem, { href: url, target, width, pages });
      } else {
        skin.pinned.push({
          width,
          target,
          href: url,
          pages,
        });
      }
      dispatch("updateSkin", skin);
    },
    removePinnedFromSkin({ state, dispatch }, { url }) {
      if (!url) {
        console.error("Invalid method invocation");
        return;
      }
      const skin = clone(state.skin);
      skin.pinned = skin.pinned || [];
      skin.pinned = skin.pinned.filter(({ href }) => {
        return href != url;
      });
      dispatch("updateSkin", skin);
    },
    toggleSidebar({ state }) {
      debug(`In action toggleSidebar`);
      Vue.set(state.skin.sidebar, "display", !state.skin.sidebar.display);
      localStorage.skin = JSON.stringify(state.skin);
    },
    setSidebarDisplay({ state }, value) {
      const skin = state.skin;
      skin.sidebar = skin?.sidebar || {};
      skin.sidebar.display = value;
      Vue.set(state, "skin", skin);
      localStorage.skin = JSON.stringify(state.skin);
    },
    _display_page(page) {
      debug(`In root display page`, page);
    },
    _display_dialog(page) {
      debug(`In root display page`, page);
    },
    _email_page() {
      debug(`In root display page`);
    },
    [PUBLISH_EVENT]({ state, dispatch, commit }, event) {
      debug(`event triggered`, event);
      switch (event.key) {
        case CLOSE_DIALOG:
          commit(CLOSE_DIALOG);
          break;
        case REFRESH_SKIN:
          {
            debug(`In refresh skin handler`);
            dispatch("fetchSkin", { force: true });
          }
          break;
        case SHOW_HISTORY:
          {
            Vue.set(state, "displayHistory", true);
            Vue.set(state, "historyParams", event.value);
          }
          break;
        case LOGIN_EVENT: {
          debug(`In ${LOGIN_EVENT}`, event.value);
          dispatch("user/login", event.value, { root: true });
          break;
        }
        case "_print": {
          debug(`Handling print event`, event);
          const target = event?.value?.target;
          if (!target) {
            console.error(`No target found for print`);
            return;
          }
          document.querySelector("body").classList.add("printPreview");
          let prtContent = document.querySelector(target);
          if (!prtContent) {
            console.error(`No target found in DOM for selector [${target}]`);
            return;
          }
          html2canvas(prtContent).then((canvas) => {
            document.querySelector("body").classList.remove("printPreview");
            let WinPrint = window.open(
              "",
              "",
              "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
            );
            WinPrint.document.body.appendChild(canvas);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
          });

          break;
        }

        default:
          debug(`Not handling event`, event);
      }
    },
    error({ commit }, message) {
      commit(ADD_MESSAGE, {
        type: "error",
        content: message,
      });
    },
    warning({ commit }, message) {
      commit(ADD_MESSAGE, {
        type: "warning",
        content: message,
      });
    },
    success({ commit }, message) {
      commit(ADD_MESSAGE, {
        type: "success",
        content: message,
      });
    },
    info({ commit }, message) {
      commit(ADD_MESSAGE, {
        type: "info",
        content: message,
      });
    },
    hideMessage({ commit }) {
      commit("hideMessage");
    },
    async makeServerCall(context, params) {
      return (await utilMakeServerCall(params)).data;
    },
    triggerNavigation({ state, commit }, params) {
      const url = params.target || params.url;
      const dialogIsOpen = state.dialog.display;
      if (dialogIsOpen) {
        commit(CLOSE_DIALOG, null);
      }
      if (url == "BACK") {
        if (!dialogIsOpen) {
          router.go(-1);
        }
        return;
      }
      const target = constructQueryParam(url, params.params);
      router.push(target);
    },
    async fetchSkin({ state, /*  dispatch, */ commit }, options) {
      if (Object.keys(state.skin).length && !options?.force) {
        return;
      }
      const skin = await fetchSkin(options);
      commit(SET_SKIN, skin);
    },
    async fetchNotifications({ state, commit }) {
      const lastNotification = state.notifications?.content?.[0];
      let timestamp = new Date().toISOString();
      if (lastNotification) {
        timestamp = lastNotification.timestamp;
      }
      try {
        const notifications = await fetchNotifications(timestamp);
        debug(`Triggering addNotifications`, clone(notifications));
        commit("addNotifications", notifications || []);
      } catch (e) {
        console.error(`Error when attempting to fetch notifications`, e);
      }
    },

    async fetchMenu({ state, commit, getters }, options = {}) {
      const methodDebug = debug.extend(`fetchMenu`); // eslint-disable-line
      if (!options.force && state.menu.length) {
        return getters.menu;
      }
      let menu = checkPermissions(await fetchMenu());
      methodDebug(`menu after check permissions`, menu);
      commit("setMenu", menu);
      return getters.menu;
    },
    fetchRoles() {
      return fetchRoles();
    },
    async fetchAllPermissions() {
      const methodDebug = debug.extend(`fetchAllPermissions`); // eslint-disable-line
      methodDebug(`in Fetch ALL permissions`);
      const permissions = (
        await utilMakeServerCall({
          url: `/permissions`,
          params: {
            sort: "_created_date",
            count: 999,
          },
          type: "get",
        })
      )?.data;
      return permissions;
    },
    updateSkin({ state, commit }, updatedSkin) {
      const skin = clone(state.skin);
      Object.keys(updatedSkin).forEach((key) => {
        skin[key] = updatedSkin[key];
      });
      commit(SET_SKIN, skin);
      updateSkin(state.skin);
    },
    saveThemeToDomain({ state, dispatch }, theme) {
      const skin = clone(state.skin);
      skin.theme = theme;
      dispatch("updateSkin", skin);
    },
    saveFieldsSettingsToDomain({ state, dispatch }, fields) {
      const skin = clone(state.skin);
      const newPreferences = defaultsDeep({}, { fields }, skin);
      dispatch("updateSkin", newPreferences);
    },
    saveSettingsToDomain({ state, dispatch }, settings) {
      const skin = defaultsDeep({}, settings, clone(state.skin));
      dispatch("updateSkin", skin);
    },
    logout({ dispatch }) {
      dispatch("user/logout", null, { root: true });
      window.vue.$router.push("/login");
    },
    changeNoImage({ state }, isDark) {
      state.no_image = isDark == true ? "/no_image_dark.jpg" : "/no_image.jpg";
    },
  },
});

Vue.mixin({
  methods: mapActions({
    displaySuccessMessage: "success",
    displayErrorMessage: "error",
    displayWarningMessage: "warning",
    displayInfoMessage: "info",
  }),
});
