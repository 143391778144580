import {
  readonly,
  disabled,
  dense,
  flat,
  ripple,
  append_icon,
  prepend_icon,
  background_color,
  customiseAttribute,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

const debug = require("debug")("atman.components.switch"); // eslint-disable-line
export default {
  label: "Switch",
  type: "switch",
  description: "Displays a switch toggle",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  disabled_search: (options = {}) => {
    const { key, searchData, resultObj } = options || {};
    if (searchData[key].includes("")) {
      return true;
    }
    const searchValues = searchData[key].map((value) => {
      return value === "true" ? true : false;
    });
    const result = searchValues.includes(resultObj[key]);
    debug(key, searchData, resultObj, result);
    return result;
  },
  disabled_searchField: (col, data = {}) => {
    let trueLabel = "true",
      falseLabel = "false";
    const labels = col.labels;
    if (labels) {
      [falseLabel, trueLabel] = labels;
    }
    let result = {
      name: col.name,
      label: col.label,
      type: "checkbox",
      value: ["true", "false"],
      display: {
        width: "3",
        attributes: {
          row: true,
        },
      },
      options: {
        value: [
          {
            label: falseLabel,
            id: "false",
          },
          {
            label: trueLabel,
            id: "true",
          },
        ],
      },
    };
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    return result;
  },
  templates: [
    {
      id: "default",
      label: "Default",
      description: "The default Switch field.",
      value: {
        label: "Switch",
        type: "switch",
      },
    },
    {
      id: "display",
      label: "Display",
      description: 'Use <code>mode: "display"</code> for <b>readonly</b> mode.',
      value: {
        name: "display",
        label: "Eligible",
        mode: "display",
        type: "switch",
        value: false,
      },
    },
    {
      id: "labels",
      label: "Labels",
      description: "An example of Switch field with labels.",
      value: {
        name: "labels",
        labels: ["Cold", "Interested"],
        type: "switch",
        value: false,
        display: {
          attributes: {},
        },
      },
    },
    {
      id: "checkbox",
      label: "Display as Checkbox",
      description:
        'Use <code>checkbox: "true"</code> to display Switch field as checkbox.',
      value: {
        name: "married",
        label: "Married",
        type: "switch",
        value: true,
        display: {
          attributes: {
            behavior_checkbox: true,
          },
        },
      },
    },
    {
      id: "readonly_checkbox",
      label: "Readonly Checkbox",
      description:
        'Use <code>mode: "display"</code> to display checkbox Switch field as <b>readonly</b> mode.',
      value: {
        name: "married",
        label: "Married",
        mode: "display",
        type: "switch",
        value: true,
        display: {
          attributes: {
            behavior_checkbox: true,
          },
        },
      },
    },
    {
      id: "flags",
      label: "Use Flags (YES/NO) instead of booleans as the value",
      description:
        "Switch field with values as <code>YES</code> and <code>NO.</code>",
      keyword: ["tutorial"],
      value: {
        name: "married",
        label: "Married",
        values: ["NO", "YES"],
        type: "switch",
        value: "YES",
        display: {
          attributes: {
            behavior_checkbox: true,
          },
        },
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    readonly,
    disabled,
    dense,
    flat,
    ripple,
    append_icon,
    prepend_icon,
    background_color,
    {
      name: "inset",
      type: "switch",
      hint: "Inset",
      value: false,
    },
    customiseAttribute("color", { value: "info" }),
    {
      name: "behavior_checkbox",
      type: "switch",
      hint: "Display as checkbox",
      value: false,
    },
  ],
};
