<template>
  <div :aut-field-value="value.name" :class="displayClasses">
    <label v-if="label"> {{ label }}: </label>
    <span>{{ displayValue }}</span>
  </div>
</template>

<script>
import { get } from "lodash";
import { fieldMixin } from "@/components/mixin.js";

const debug = require("debug")("atman.components.fields.label"); // eslint-disable-line

export default {
  name: "LabelField",
  mixins: [fieldMixin],
  data() {
    return {
      displayValue: "",
    };
  },
  mounted() {
    this.determineDisplayValue();
  },
  methods: {
    _afterFieldValueUpdate() {
      this.determineDisplayValue();
    },
    async determineDisplayValue() {
      const component = this;

      const rule = component.value?.rule;
      if (rule) {
        switch (rule.operation) {
          case "TOTAL": {
            const data = await component.$store.dispatch(
              `${component.context}/getDataObject`
            );
            debug(`data`, data);
            const field = rule.field;
            const reducer = (accumulator, item) => {
              let itemValue = get(item, field);
              if (!itemValue) {
                return accumulator;
              }
              accumulator = accumulator + itemValue * 1;
              return accumulator;
            };

            if (!Array.isArray(data)) {
              component.fieldValue = 0;
            } else {
              component.fieldValue = data.reduce(reducer, 0);
            }
            break;
          }
          default: {
            console.error(`Unsupported rule`);
          }
        }
      }
      const fieldValue = component.fieldValue;
      this.displayValue = fieldValue;
    },
  },
};
</script>
