<template>
  <div>
    <FieldLabel
      v-if="isDisplayMode"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
      <FieldDisplayValue
        aut-landline-value
        :value="displayValue"
        :definition="value"
      />
    </FieldLabel>
    <v-row v-else-if="isFullNumber">
      <v-text-field
        aut-full-number
        v-model="effectiveValue"
        :label="label"
        @blur="saveNumber"
      ></v-text-field>
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="2">
        <v-text-field
          aut-country-code
          v-model="effectiveValue.country_code"
          label="Country Code"
          @blur="saveNumber"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
          aut-std-code
          @blur="saveNumber"
          v-model="effectiveValue.std_code"
          label="STD Code"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          aut-landline
          @blur="saveNumber"
          v-model="effectiveValue.landline"
          label="Landline No"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
          aut-extension
          @blur="saveNumber"
          v-model="effectiveValue.extension"
          label="Extension"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import { mapValues, forOwn } from "lodash";
import { STORE_CONSTS } from "@/constants";
const debug = require("debug")("atman.components.landline_number");
debug("landline_number");
export default {
  name: "LandlineNumber",
  mixins: [fieldMixin],
  data() {
    return {
      effectiveValue: {
        country_code: "",
        std_code: "",
        landline: "",
        extension: "",
      },
    };
  },
  computed: {
    hasFields() {
      const fields = this.value?.landline_fields || {};
      return !!Object.keys(fields).length;
    },
    isFullNumber() {
      return typeof this.fieldValue == "string";
    },
    displayValue() {
      if (typeof this.effectiveValue == "string") {
        return this.effectiveValue;
      }
      if (!this.effectiveValue?.landline) {
        return "";
      }
      return Object.values(this.effectiveValue)
        .filter((val) => val != "")
        .join("-");
    },
  },
  mounted() {
    this.deriveEffectiveValue();
  },
  methods: {
    async getDataValue(dataPath) {
      const component = this;
      if (!dataPath) {
        return "";
      }
      const value = await component.$store.dispatch(
        `${component.context}/getFieldValue`,
        { path: dataPath }
      );
      debug(`dataPath: [${dataPath}]. value: [${value}]`);
      return value;
    },
    setDataValue(dataPath, newValue) {
      const component = this;
      const mutation = `${component.context}/${STORE_CONSTS.FIELD}`;
      component.$store.commit(mutation, {
        path: dataPath,
        value: newValue,
      });
    },
    saveNumber() {
      if (this.isFullNumber) {
        this.fieldValue = this.effectiveValue;
        return;
      }
      if (!this.hasFields) {
        this.fieldValue = Object.assign({}, this.effectiveValue);
        return;
      }
      const fields = this.value.landline_fields;
      Object.keys(fields).forEach((key) => {
        this.setDataValue(fields[key], this.effectiveValue[key]);
      });
    },
    async deriveEffectiveValue() {
      if (!this.hasFields) {
        if (typeof this.fieldValue == "string") {
          this.effectiveValue = this.fieldValue;
          return this.effectiveValue || "";
        }
        this.effectiveValue = Object.assign({}, this.fieldValue || {});
        return;
      }

      const component = this;
      const fields = component.value?.landline_fields || {};

      const fieldNames = mapValues(this.effectiveValue, (value, key) => {
        const field = fields[key] || "";
        return typeof field == "string" ? field : field?.name || "";
      });

      let result = {};
      forOwn(fieldNames, async (value, key) => {
        const path = fields[key]?.name || fields[key];
        let effectiveValue = fields?.[key]?.value || "";
        result[key] = effectiveValue;
        this.effectiveValue[key] = effectiveValue;
        if (value) {
          try {
            const dataValue = await component.getDataValue(value);
            if (dataValue) {
              result[key] = dataValue;
              this.effectiveValue[key] = dataValue;
            }
          } catch (e) {
            console.error("Error", e);
          }
        }
        this.setDataValue(path, result[key]);
      });
    },
  },
};
</script>
