export default {
  label: "Date & Time",
  type: "date_time",
  description: "Displays a due date field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description: "The default Date & Time field.",
      label: "Default",
      variables: {
        data: {
          date: {
            date: `${new Date().toISOString()}`,
            start_time: "2:00",
            end_time: "4:00",
          },
        },
      },
      value: {
        label: "Date",
        name: "date",
        type: "date_time",
        options: {
          value: [
            {
              start_time: "12:00",
              end_time: "1:00",
            },
            {
              start_time: "2:00",
              end_time: "4:00",
            },
            {
              start_time: "4:00",
              end_time: "6:00",
            },

            {
              start_time: "6:00",
              end_time: "8:00",
            },
          ],
        },
      },
    },
  ],
};
