<template>
  <v-app-bar
    app
    v-if="displayHeader"
    flat
    clipped-left
    class="header"
    aut-header
    :height="headerHeight"
    :color="headerColor"
    v-bind="headerAttributes"
  >
    <template v-if="headerWithImage && headerImageSrc" v-slot:img="{ props }">
      <v-img
        aut-header-banner
        v-bind="props"
        :height="headerHeight"
        :src="headerImageSrc"
        contain
      ></v-img>
    </template>
    <template v-else v-slot>
      <v-row dense no-gutters>
        <v-col :cols="left_spacer" aut-col-spacer-left v-if="left_spacer > 0" />
        <v-col :class="logoClasses" aut-col-logo>
          <SiteLogoLeft container="Header" :max_height="headerHeight" />
        </v-col>
        <v-col
          :class="headerPadding"
          v-if="userMenuInLeft && showUserMenu && isAuthenticated"
        >
          <user-menu class="mt-3 ml-2" view="profile" />
        </v-col>
        <v-col
          cols="10"
          lg="8"
          v-if="customHeader == 'eblisher'"
          class="flex-grow-1 flex-shrink-1"
          aut-col-header-content
        >
          <EblisherHeader />
        </v-col>
        <v-col v-else-if="headerText">
          <v-toolbar-title
            aut-header-title
            v-if="showHeaderText"
            class="text-md-h4 pt-2 text-center"
          >
            <span :class="headerTextColor">
              {{ headerText }}
            </span>
          </v-toolbar-title>
        </v-col>
        <!-- 
          pr-2 : To ensure the user logo lines up with the right panel
         -->
        <v-col :class="headerPadding" aut-col-menu>
          <div class="d-flex justify-end">
            <HeaderMenuOptions v-if="isAuthenticated" aut-header-menu />
            <v-badge
              v-if="showNotifications && isAuthenticated"
              :value="notifications.unread"
              :content="notifications.unread"
              color="green"
              class="mt-4 mr-2"
              overlap
            >
              <v-icon @click="toggleNotificationDisplay" aut-notification-icon>
                mdi-bell
              </v-icon>
            </v-badge>
            <user-menu
              v-if="!userMenuInLeft && showUserMenu && isAuthenticated"
              class="mt-2 pr-2"
              view="profile"
            />
            <SiteLogoRight :max_height="headerHeight" />
          </div>
        </v-col>
        <v-col
          :cols="right_spacer"
          aut-col-spacer-left
          v-if="right_spacer > 0"
        />
      </v-row>
    </template>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { storeEventsMixin } from "@/components/mixin";
import HeaderMenuOptions from "./HeaderMenuOptions";
import EblisherHeader from "./EblisherHeader";
import SiteLogoLeft from "@/components/pageContent/SiteLogo/SiteLogoLeft.vue";
import SiteLogoRight from "@/components/pageContent/SiteLogo/SiteLogoRight.vue";
import UserMenu from "./UserMenu.vue";
import { isUnAuthenticated } from "@/util";

const debug = require("debug")("atman.components.header");

debug("header");
export default {
  name: "Header",
  mixins: [storeEventsMixin],
  components: {
    UserMenu,
    EblisherHeader,
    SiteLogoLeft,
    SiteLogoRight,
    HeaderMenuOptions,
  },
  props: {
    left_spacer: {
      type: Number,
    },
    right_spacer: {
      type: Number,
    },
    main_panel_width: {
      type: Number,
    },
    left_panel_width: {
      type: Number,
    },
    left_panel_visible: {
      type: Boolean,
    },
    right_panel_visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      defaultHeight: 50,
      isAuthenticated: !isUnAuthenticated(this),
    };
  },
  watch: {
    $route() {
      this.isAuthenticated = !isUnAuthenticated(this);
    },
  },
  computed: {
    ...mapState(["skin", "notifications", "no_image"]),
    customHeader() {
      return this.skin?.header?.custom_header;
    },
    logoClasses() {
      return `${this.headerPadding} ${this.skin?.logo?.classes || ""}`;
    },
    headerPadding() {
      let classes = "flex-shrink-1 flex-grow-1 ";
      if (this.left_panel_visible) {
        classes += "pl-4 ";
      }
      if (this.right_panel_visible) {
        classes += "pr-4 ";
      }
      return classes;
    },
    headerColor() {
      return this.getFeatureValue("header.color") || "toolbar-color";
    },
    headerTextColor() {
      return this.getFeatureValue("header.text.color") || "";
    },
    showHeaderText() {
      if (this.isFeatureEnabled("mobile.header.hide_text")) {
        if (this.isSmallScreen) {
          return false;
        }
      }
      return !!this.headerText;
    },
    showUserMenu() {
      const result = this.isFeatureEnabled("header.user_menu.display", true);
      debug(`showUserMenu`, result);
      return result;
    },
    showNotifications() {
      const result = this.isFeatureEnabled("notifications.enabled", true);
      debug(`showNotifications`, result);
      return result;
    },
    headerText() {
      return this?.skin?.header?.text?.content || "";
    },
    headerHeight() {
      const height = this?.skin?.header?.height || this.defaultHeight;
      const root = document.querySelector(":root");
      root.style.setProperty("--header-height", `${height}px`);
      return height;
    },

    header() {
      return this?.skin?.header;
    },
    displayHeader() {
      return this?.skin?.header?.display;
    },
    headerWithImage() {
      return this.getFeatureValue("header.content") == "Image";
    },
    headerImageSrc() {
      return this?.skin?.header?.image || "";
    },
    headerAttributes() {
      const defaultAttributes = {
        "elevate-on-scroll": true,
      };

      const scrollAttributes = {
        "hide-on-scroll": true,
        "scroll-threshold": "0",
      };

      return this.isFeatureEnabled("header.hide_during_scroll")
        ? scrollAttributes
        : defaultAttributes;
    },
    userMenuInLeft() {
      return this.getFeatureValue("header.user_menu.position") == "Left";
    },
  },
  async mounted() {
    debug(`Mounted of Header. props`, this.$props);
    await this.fetchSkin();
    document.title = this.skin?.document?.title || this.headerText;
  },
  methods: {
    ...mapMutations(["toggleNotificationDisplay"]),
    ...mapActions(["fetchSkin"]),
    goToHomePage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
.headerText {
  color: var(--v-header-text-base);
}
</style>
