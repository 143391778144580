export default {
  _permissions: {
    enabled: true,
    permission: {
      value: "list_permissions",
    },
  },
  content: [
    {
      apis: {
        submit: {
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Permission added",
          },
          type: "post",
          url: "/permissions",
        },
      },
      definition: {
        actions: null,
        fields: [
          {
            _system_constraints: {
              _primary_key: true,
            },
            display: {
              width: "12",
            },
            label: "ID",
            mandatory: true,
            mode: "input",
            name: "id",
            type: "text",
          },
          {
            name: "name",
            mandatory: true,
            label: "Name",
            type: "text",
          },
        ],
      },
      subtitle: "",
      title: "Add Permission",
      type: "form",
    },
  ],
  classes: ["behaviour_block_centered-15"],
  id: "add_permission",
  name: "add_permission",
  type: "page",
};
