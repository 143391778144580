<template>
  <v-fab-transition v-if="shouldDisplay">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          fab
          fixed
          dark
          small
          class="behavior_feedback_button"
          @click="showFeedback"
        >
          <v-icon color="white">mdi-message</v-icon>
        </v-btn>
      </template>
      Give us Feedback
    </v-tooltip>
  </v-fab-transition>
</template>
<script>
import { isUnAuthenticated } from "@/util";
import { SHOW_DIALOG } from "@/constants";
const debug = require("debug")("atman.components.feedback"); // eslint-disable-line

export default {
  name: "FeedbackButton",
  computed: {
    target() {
      return this.getFeatureValue("feedback.target");
    },
    shouldDisplay() {
      return (
        !isUnAuthenticated(this) &&
        this.isFeatureEnabled("feedback.enabled") &&
        !!this.target
      );
    },
  },
  methods: {
    showFeedback() {
      if (!this.target) {
        console.error(`No feedback target defined.`);
        return;
      }
      this.$store.dispatch(SHOW_DIALOG, { url: this.target });
    },
  },
};
</script>
<style lang="scss" scoped>
/* Positioning of the feedback button */
.behavior_feedback_button {
  bottom: 2% !important;
  right: 0.3% !important;
}
@media only screen and (max-width: 600px) {
  .behavior_feedback_button {
    bottom: 4% !important;
    right: 4% !important;
  }
}
</style>
