<template>
  <div>
    <div v-if="isInputMode">
      <FieldLabel
        :definition="value"
        :label="label"
        :attributes="labelAttributes"
      />
      <div
        aut-all-checkbox
        dense
        v-if="value.seedData && !isDisplayMode"
        :class="isRow ? 'd-flex justify-start flex-wrap' : ''"
      >
        <div
          v-for="(item, i) in checkBoxItems"
          aut-checkbox-wrapper
          :key="i"
          v-bind:class="colClasses"
          class="mr-1"
        >
          <v-row class="pa-0">
            <v-col
              ><v-checkbox
                dense
                class="behavior_checkbox mt-0"
                aut-checkbox
                @change="validateCheckbox"
                :error="errorState"
                v-model="fieldValue"
                v-bind="displayAttributes"
                :value="item.id"
                :key="`checkbox-${item.id}`"
              >
                <template v-slot:label>
                  <v-tooltip bottom v-if="item.hint">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"> {{ item.name }}</span>
                    </template>
                    <span>{{ item.hint }}</span>
                  </v-tooltip>
                  <span v-else>{{ item.name }}</span>
                </template></v-checkbox
              >
            </v-col>
            <v-col v-if="item.name == 'Other'" cols="12" lg="8">
              <OtherOption
                :checked="isOtherChecked"
                :text="otherOption.id"
                @update:text="otherOption.id = $event"
                :fieldValue="fieldValue"
                @update:value="fieldValue = $event"
                :seedData="value.seedData"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div aut-checkbox-error v-if="errorMessage" class="behavior_message">
        {{ errorMessage }}
      </div>
    </div>
    <div v-if="isDisplayMode">
      <FieldLabel
        :definition="value"
        :label="label"
        mode="display"
        :attributes="labelAttributes"
      >
        <FieldDisplayValue
          aut-checkbox-value
          :value="displayValue"
          :definition="value"
        />
      </FieldLabel>
    </div>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import { clone } from "@/util.js";
import OtherOption from "./OtherOption.vue";
import { isPlainObject } from "lodash";
import definition from "./definition";
const debug = require("debug")("atman.components.checkbox_field"); // eslint-disable-line

export default {
  name: "Checkbox",
  mixins: [fieldMixin],
  components: {
    OtherOption,
  },
  created() {
    debug(`In created of Checkbox`);
  },
  data() {
    return {
      definition,
      errorMessage: "",
      errorState: false,
      otherOption: {
        name: "Other",
        id: "_other_",
      },
    };
  },
  computed: {
    displayValue() {
      const component = this;
      const value = component.value;
      //Single select
      // Multiple Select
      let selectedItems = [];
      // IF no value or seedData is fetched, return an empty array
      if (typeof this.fieldValue == "undefined" || !value.seedData) {
        return selectedItems;
      }

      if (value.seedData) {
        selectedItems = this.checkBoxItems.filter(({ id }) => {
          return (this.fieldValue || [])
            .map((item) => `${item}`)
            .includes(`${id}`);
        });

        const displayNames = selectedItems.map(({ name, id }) => {
          if (name != "Other") {
            return name;
          }
          if (id != "_other_") {
            return id;
          }
          return;
        });

        return displayNames.join(", ");
      }
      return null;
    },
    colClasses() {
      return this.isRow ? ["align-self-start", "flex-shrink-1"] : [];
    },
    isRow() {
      const row = this.displayAttributes?.row;

      if (!row) {
        return false;
      }

      return row;
    },
    getCol() {
      return this.isRow ? "2" : "12";
    },
    showOtherOption() {
      const other = this.displayAttributes?.other;
      return isPlainObject(other);
    },
    isOtherChecked() {
      return (this.fieldValue || []).includes(this.otherOption.id);
    },
    checkBoxItems() {
      const seedData = this.value?.seedData || [];

      if (!this.showOtherOption) {
        return seedData;
      }

      return [...seedData, this.otherOption];
    },
  },
  mounted() {
    debug(`In mounted of Checkbox`, this.value);
  },
  methods: {
    validateCheckbox() {
      const fieldValue = clone(this.fieldValue);
      const value = this.applicableRules.map((rule) => rule(fieldValue));
      this.errorState = false;
      this.errorMessage = "";
      if (!value.length) {
        return;
      }
      const result = value.find((val) => val !== true);
      if (!result) {
        return;
      }
      if (typeof result == "string") {
        this.errorMessage = result;
        this.errorState = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_checkbox {
  ::v-deep .v-messages {
    display: none;
    min-height: 0px !important;
  }
}
.behavior_message {
  color: var(--v-error-base);
}
</style>
