<template>
  <div>
    <div class="d-flex" v-if="displayCheckbox">
      <v-checkbox
        :aut-switch="value.name"
        v-model="effectiveValue"
        v-bind="displayAttributes"
        :label="label"
      ></v-checkbox>
    </div>
    <div class="d-flex flex-wrap" v-else>
      <span
        class="mt-lg-1 mt-3 mr-2"
        v-if="falseLabel"
        aut-switch-label-false
        >{{ falseLabel }}</span
      >
      <v-switch
        dense
        :disabled="!isInputMode"
        :hide-details="!isInputMode"
        :aut-switch="value.name"
        v-model="effectiveValue"
        :label="(!trueLabel && !falseLabel && label) || ''"
        :hint="value.hint"
        v-bind="displayAttributes"
        @change="setFieldValue(effectiveValue)"
        v-bind:class="displayClasses"
        class="mt-2 mt-md-0"
      ></v-switch>
      <span class="mt-lg-1 mt-3" v-if="trueLabel" aut-switch-label-true>{{
        trueLabel
      }}</span>
    </div>
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.switch"); // eslint-disable-line

export default {
  name: "ToggleSwitch",
  mixins: [fieldMixin],
  data() {
    let trueLabel, falseLabel;
    const labels = this.value.labels;
    if (labels) {
      [falseLabel, trueLabel] = labels;
    }
    return {
      definition,
      trueLabel,
      falseLabel,
    };
  },
  computed: {
    displayCheckbox() {
      return (
        this?.displayAttributes?.behavior_checkbox ||
        this?.value?.display?.behavior_checkbox
      );
    },
    effectiveValue: {
      get() {
        const values = this.value.values || [false, true];
        return this.fieldValue === values[1];
      },
      set(value) {
        this.setFieldValue(value);
      },
    },
  },
  methods: {
    setFieldValue(value) {
      const values = this.value.values || [false, true];
      const effectiveValue = value ? values[1] : values[0];
      this.$set(this, "fieldValue", effectiveValue);
      debug(`Effective value: [${effectiveValue}]`);
      return effectiveValue;
    },
  },
};
</script>
