<template>
  <v-expansion-panels :value="open_on_load ? 0 : null">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <p class="pb-0 mb-0">Event</p>
          <TipText :content="eventDescription" />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="6" v-if="multiple">
            <v-switch
              class="mt-n1"
              label="Is Exclusive"
              hint="If this event is triggered, all others will be aborted"
              persistent-hint
              v-model="event.params._is_exclusive"
            ></v-switch>
          </v-col>
          <v-col cols="6" v-if="!fixed_type">
            <v-autocomplete
              dense
              label="Type"
              hint="Type of event"
              item-text="label"
              item-value="id"
              persistent-hint
              clearable
              v-model="definition.type"
              :items="eventTypes"
              @blur="updateEvent"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="definition.type == 'trigger_event'">
            <v-card class="transparent">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <label>Trigger Events:</label>
                    <v-chip
                      class="ml-2"
                      v-for="id in ids"
                      :key="id"
                      close
                      @click:close="removeID(id)"
                    >
                      {{ id }}
                    </v-chip>
                  </v-col>
                  <v-col cols="6" v-if="allowAddition">
                    <v-text-field
                      dense
                      hint="Add ID of event to trigger"
                      persistent-hint
                      clearable
                      v-model="id"
                      v-on:keydown.enter.stop.prevent="addID"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-else-if="definition.type == 'assign'">
            <v-card class="transparent">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Target"
                      hint="Target Variable name"
                      persistent-hint
                      clearable
                      v-model="definition.params.target"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      :rows="1"
                      auto-grow
                      label="Target"
                      hint="Value"
                      persistent-hint
                      clearable
                      v-model="eventValue"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-else-if="definition.type == 'display_message'">
            <v-card class="transparent">
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Message"
                      hint="message to display"
                      persistent-hint
                      clearable
                      v-model="definition.params.message"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { defaultsDeep } from "lodash";
import { clone } from "@/util";
import { eventTypes, getEventDescription } from "../util";
import TipText from "@/components/fields/Questionaire/designer/TipText.vue";
const debug = require('debug')('atman.components.questionnaire.designer.trigger_event')  // eslint-disable-line
export default {
  name: "TriggerEventDesigner",
  components: { TipText },
  props: {
    event: {},
    open_on_load: {
      type: Boolean,
      default: () => false,
    },
    fixed_type: {
      type: Boolean,
      default: () => false,
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    let definition = defaultsDeep(clone(this.event), {
      params: {},
      type: eventTypes[0].id,
    });
    let ids = [];
    let idInParams = definition?.params?.id;
    if (idInParams) {
      if (typeof idInParams == "string") {
        ids = [idInParams];
      } else if (Array.isArray(idInParams)) {
        ids = idInParams;
      }
    }
    return {
      definition,
      id: null,
      ids,
    };
  },
  computed: {
    eventDescription() {
      return getEventDescription(this.definition);
    },
    eventValue: {
      get() {
        return JSON.stringify(this.definition?.params?.value || {});
      },
      set(val) {
        this.definition.params.value = JSON.parse(val);
      },
    },
    allowAddition() {
      if (this.multiple) {
        return true;
      }
      return this.ids.length == 0;
    },
  },
  created() {
    this.eventTypes = eventTypes;
  },
  mounted() {
    debug(`trigger event`, this.definition);
  },
  methods: {
    removeID(idToRemove) {
      debug(`in removeID`, idToRemove);
      let ids = this.definition.params.id;
      ids = ids.filter((id) => id == idToRemove);
      this.definition.params.id = ids;
      this.updateEvent();
    },
    updateEvent() {
      debug(`updateEvent Invoked`, this.definition);
      this.$emit("update:event", clone(this.definition));
    },
  },
};
</script>
