<template>
  <v-card class="transparent">
    <v-card-title>
      <h1 :class="`display-2 font-weight-thin text-uppercase ${titleDisplay}`">
        {{ title }}
      </h1>
    </v-card-title>
    <v-card-subtitle>
      <EntityDetails
        :class="`${titleDisplay} mb-5`"
        v-model="value"
        :context="context"
        :attributes="attributes"
      />
    </v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="4">
          <v-img
            :lazy-src="no_image"
            :src="frontCover"
            class="behaviour_centered behavior_clickable"
            @click="startReading"
          />
        </v-col>
        <v-col cols="12" lg="8">
          <p class="text-h5 text-left font-weight-thin" v-html="blurb"></p>
          <Field
            v-if="displayCost && costDefinition"
            class="text-h5 font-weight-thin"
            :definition="costDefinition"
            :context="context"
          />
          <v-btn @click="startReading" class="pa-3 mt-2"
            ><v-icon class="pr-1">mdi-book-open-page-variant</v-icon>Read</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import bookMixin from "./bookMixin";
export default {
  name: "ExpandedBook",
  components: {
    EntityDetails: () =>
      import("@/components/pageElements/EntityDetails/EntityDetails.vue"),
  },
  mixins: [bookMixin],
  props: {},
  computed: {
    titleDisplay() {
      return this.isTabletOrSmaller ? `align-self-center` : `align-self-start`;
    },
    authors() {
      let authors = this.value?.authors || [];
      return authors.map(({ name }) => name).join(", ");
    },
    publisher() {
      let publishers = this.value?.publishers || [];
      return publishers.map(({ name }) => name).join(", ");
    },
    illustrators() {
      let illustrators = this.value?.illustrators || [];
      return illustrators.map(({ name }) => name).join(", ");
    },
    noOfPages() {
      return this.value?.page_count || 0;
    },
  },
  methods: {
    startReading() {
      if (this.attributes?.fullscreen) {
        this._goFullScreen();
      }

      this.$store.dispatch(`${this.context}/triggerAction`, {
        actionDefinition: {
          value: {
            type: "navigation",
            url: `/read_book/${this.effPath}`,
          },
        },
      });
    },
  },
};
</script>
