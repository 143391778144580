import LookupEditor from "./LookupEditor.vue";
import CreateLookup from "./CreateLookup.vue";

const routes = [
  {
    path: "/edit_lookup/:id",
    name: "Edit Lookup",
    component: LookupEditor,
    meta: { unauthenticated: false },
  },
  {
    path: "/create_lookup/:id",
    name: "Create Lookup",
    component: CreateLookup,
    meta: { unauthenticated: false },
  },
];

export default routes;
