<template>
  <div aut-page-editor>
    <ChooseTemplateDialog
      v-if="displayDialog"
      :type="newType"
      @close="displayDialog = false"
      @select="useTemplate"
    />
    <Page
      class="behavior_page_editor"
      v-if="pageDefinition"
      :page="pageDefinition"
      :key="refreshKey"
      :context="pageContext"
      :url="value.url"
      :editor="true"
      :design="true"
      @update:page="updateLocalStore"
      @update:definition="updateDefinition"
      @remove_node="removeNode"
      @add_item="addNode"
    />
  </div>
</template>
<script>
import { isEqual } from "lodash";
import { clone, fetchTemplates, createContext } from "@/util.js";
import { store, STORE_CONSTS } from "./store.js";
import ChooseTemplateDialog from "./ChooseTemplateDialog";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.page_editor"); //eslint-disable-line

export default {
  name: "PageEditor",
  components: {
    Page: () => import("@/components/Page/Page"),
    ChooseTemplateDialog,
  },
  mixins: [fieldMixin],
  data() {
    const payload = {
      pageDefinition:
        this.$store.getters[`${STORE_CONSTS.PAGE_EDITOR_STORE}/page`],
      pageContext: {},
      visualiserKey: 1,
      displayDialog: false,
      newType: null,
      refreshKey: 1,
    };
    debug(`data in pageEditor: `, payload);
    return payload;
  },
  props: {
    value: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  created() {
    debug("in created of Page Editor");
    const component = this;
    component._createStore(STORE_CONSTS.PAGE_EDITOR_STORE, store);
    this.setupWatch();
  },
  mounted() {
    debug("mounted of PageEditor", this.value);
    const originalContext = this.$store.state[this.context].context;
    const originalPath = originalContext.params.page;
    if (originalPath) {
      this.pageContext = createContext(originalPath);
    }
    if (this.fieldValue) {
      this.updateLocalStore(this.fieldValue);
    }
    this.fetchPageTemplate();
    debug(
      `mounted of pageEditor`,
      this.pageContext,
      this.fieldValue,
      this.$store.getters[`${STORE_CONSTS.PAGE_EDITOR_STORE}/page`]
    );
    // Attempting to fix bug with this value not being available when data is being constructed
    this.pageDefinition =
      this.$store.getters[`${STORE_CONSTS.PAGE_EDITOR_STORE}/page`];
  },
  methods: {
    _afterFieldValueUpdate() {
      debug(`in _afterFieldValueUpdate`);
      debug(`_afterFieldValueUpdate: this.fieldValue`, this.fieldValue);
    },
    _afterValueUpdate() {
      debug(`in _afterValueUpdate`);
      this.updateLocalStore(this.fieldValue);
      if (typeof this.fieldValue != "undefined") {
        debug("value in page editor", this.fieldValue);
      }
    },
    async fetchPageTemplate() {
      debug(`in fetchPageTemplate`);
      const component = this;
      const templateID = component?.value?.template;
      if (!templateID) {
        return;
      }
      const templates = await fetchTemplates("page");
      let template = (templates || []).find(({ id }) => {
        return id == templateID;
      });
      if (!template) {
        return;
      }
      this.fieldValue = template.value;
      this.updateLocalStore(this.fieldValue);
    },
    updateLocalStore(newValue) {
      debug(`in updateLocalStore`);
      const component = this;
      const pageInLocalStore =
        this?.$store?.state[STORE_CONSTS.PAGE_EDITOR_STORE]?.page;
      if (isEqual(newValue, pageInLocalStore)) {
        return;
      }
      debug("Updating local store with fetched form data");
      component.$store.commit(
        `${STORE_CONSTS.PAGE_EDITOR_STORE}/${STORE_CONSTS.PAGE}`,
        clone(newValue)
      );
      this.pageDefinition = clone(newValue);
      this.refreshKey++;
    },
    setupWatch() {
      const component = this;
      component.unsubscribe = component.$store.subscribe((mutation) => {
        if (mutation.type.indexOf(STORE_CONSTS.PAGE_EDITOR_STORE) == -1) {
          return;
        }
        debug(`page editor mutation triggered`);
        const page = clone(
          component.$store.state[STORE_CONSTS.PAGE_EDITOR_STORE]?.page
        );
        debug("page editor watch triggered", page);
        if (!isEqual(this.pageDefinition, page)) {
          this.pageDefinition = clone(page);
        }
        if (!isEqual(component.fieldValue, page)) {
          this.$set(component.value, "value", clone(page));
        }
      });
    },
    updateDefinition(path, value) {
      debug(`updateDefinition invoked with path: [${path}]`, value);
      this.$store.commit(
        `${STORE_CONSTS.PAGE_EDITOR_STORE}/${STORE_CONSTS.UPDATE_NODE}`,
        { key: path, value }
      );
    },
    removeNode(index) {
      debug(`removeNode invoked with index: [${index}]`);
      this.$store.dispatch(
        `${STORE_CONSTS.PAGE_EDITOR_STORE}/${STORE_CONSTS.REMOVE_CONTENT}`,
        index
      );
    },
    addNode(type) {
      debug(`addItem invoked with type: [${type}]`);
      this.newType = type;
      this.displayDialog = true;
    },
    useTemplate(template) {
      this.$store.dispatch(
        `${STORE_CONSTS.PAGE_EDITOR_STORE}/${STORE_CONSTS.ADD_CONTENT}`,
        template
      );
    },
  },
};
</script>
<style lang="scss" scoped>
//  Background created using https://www.picturetopeople.org/p2p/text_effects_generator.p2p/transparent_text_effect
.behavior_page_editor {
  background-image: url("/page_editor_background.png");
  background-repeat: repeat;
  ::v-deep .behavior_atman_button:hover {
    cursor: not-allowed;
  }
  ::v-deep .hiddenInPage {
    display: revert;
  }
}
</style>
