<template>
  <v-container fluid>
    <v-card aut-settings-page>
      <atman-tabs v-model="settingTabs" @change="settingTabs = $event">
        <v-tab aut-tab-themes> Themes </v-tab>
      </atman-tabs>
      <atman-tabs-items v-model="settingTabs">
        <v-tab-item>
          <ThemeControl />
        </v-tab-item>
      </atman-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import ThemeControl from "@/components/pageContent/ThemeControl/ThemeControl";

export default {
  name: "UserPreferences",
  components: {
    ThemeControl,
  },
  data() {
    return {
      settingTabs: 0,
    };
  },
};
</script>
