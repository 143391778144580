<template>
  <v-dialog
    v-model="display"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-page-dialog>
      <DialogTitle @close="$emit('close')" title="Configure Page" />
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              label="Mode"
              clearable
              v-model="displayMode"
              :items="modes"
              item-text="label"
              item-value="id"
              persistent-hint
              dense
              row
              hint="Choose the type of display"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <PermissionNode :permissions.sync="pageDefinition._permissions" />
        <FieldSettings
          :preview="false"
          :field="pageTypeDefinition"
          :definition.sync="pageDefinition"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-edit-page-cancel>
          Cancel
        </v-btn>
        <v-btn color="primary" aut-action-edit-page-update @click="emitUpdate">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldSettings from "@/components/fields/Field/FieldSettings/FieldSettings.vue";
import { dialogMixin } from "@/components/mixin.js";
import { defaultsDeep } from "lodash";
import { clone } from "@/util.js";

const debug = require("debug")("atman.components.edit_page_dialog"); // eslint-disable-line

export default {
  name: "EditPageDialog",
  mixins: [dialogMixin],
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  components: {
    FieldSettings,
    PermissionNode: () => import("@/components/editor/PermissionNode"),
  },
  data() {
    return {
      pageDefinition: clone(this.definition),
      display: true,
    };
  },
  computed: {
    displayMode: {
      get() {
        if (this?.pageDefinition?.display?.tab == true) {
          return "tab";
        }
        let mode = this?.pageDefinition?.display?.mode;
        return mode || "grid";
      },
      set(val) {
        this.pageDefinition = defaultsDeep(
          {
            display: {
              mode: val,
            },
          },
          this.pageDefinition
        );
        delete this.pageDefinition.display.tab;
      },
    },
  },
  created() {
    this.modes = [
      {
        id: "grid",
        label: "Regular",
      },
      {
        id: "tab",
        label: "Tab",
      },
      {
        id: "wizard",
        label: "Wizard",
      },
    ];
    this.pageTypeDefinition = {
      attributes: [
        {
          name: "wizard.editable",
          type: "switch",
          value: false,
          hint: "Make the wizard editable",
        },
      ],
    };
  },
  mounted() {
    debug(`In mounted of EditFieldDialog`, this.pageDefinition);
  },
  methods: {
    emitUpdate() {
      this.$emit("update:definition", this.pageDefinition);
      this.$emit("close");
    },
  },
};
</script>
