import SettingsPage from "./SettingsPage/SettingsPage.vue";
import FeatureFlags from "./FeatureFlags/FeatureFlags.vue";
import EditorPage from "./EditorPage.vue";
import AdministrationHome from "./AdministrationHome.vue";
import DeveloperHome from "./DeveloperHome.vue";
import ClientSidePage from "@/views/ClientSidePage.vue";

import rolesRoutes from "./roles/routes";
import permissionsRoutes from "./permissions/routes.js";
import integrationRoutes from "./integrations/routes";

const routes = [
  {
    path: "/administration",
    name: "Administration",
    component: AdministrationHome,
    meta: { unauthenticated: false },
  },
  {
    path: "/developer",
    name: "Developer",
    component: DeveloperHome,
    meta: { unauthenticated: false },
  },
  {
    path: "/editor_page",
    name: "Edit Page",
    component: EditorPage,
    meta: { unauthenticated: false, permission: "edit_pages" },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
    meta: { unauthenticated: false, permission: "list_settings" },
  },
  {
    path: "/domains",
    name: "Domains",
    component: ClientSidePage,
    meta: { unauthenticated: false, page_key: "domain_list" },
  },

  {
    path: "/feature_flags",
    name: "FeatureFlags",
    component: FeatureFlags,
    meta: { unauthenticated: false, permission: "list_settings" },
  },
  ...rolesRoutes,
  ...integrationRoutes,
  ...permissionsRoutes,
];

export default routes;
