<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header
        >Rules ({{
          rules && rules.length ? rules.length : "None"
        }})</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-row dense>
          <v-col cols="12">
            <RuleDesigner
              :index="i"
              :rule="rule"
              v-for="(rule, i) in rules"
              :key="'rule' + i"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import RuleDesigner from "./RuleDesigner.vue";
export default {
  name: "RulesDesigner",
  props: {
    rules: [],
  },
  components: { RuleDesigner },
};
</script>
