<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      aut-address-pincode
      :context="context"
      :label="label"
      v-model="pinCode"
      :mandatory="isMandatory"
      :rules="rules"
      @change="$emit('update', { pin_code: $event })"
    />
  </v-col>
</template>
<script>
import address_mixin from "./address_mixin";
export default {
  name: "AddressPincode",
  mixins: [address_mixin],
  props: {
    context: {},
    value: {},
    definition: {},
  },
  watch: {
    "value.pin_code"() {
      this.pinCode = this.value.pin_code;
    },
  },
  data() {
    return {
      pinCode: this.value.pin_code,
      rules: [
        {
          regex: {
            message: "Please enter a valid Pincode",
            regex: "^[0-9]{6,}$",
          },
        },
      ],
    };
  },
  computed: {
    fieldProperties() {
      return this.definition?.address_fields?.pin_code || {};
    },
  },
  created() {
    this.defaults = {
      label: "Pincode",
      display: {
        width: "4",
      },
    };
  },
};
</script>
