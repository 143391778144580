<template>
  <v-row dense>
    <v-col cols="12">
      <v-textarea
        :rows="1"
        auto-grow
        label="Question"
        clearable
        v-model="event.question"
        @blur="$emit('update:event', event)"
      ></v-textarea>
    </v-col>
    <v-col> </v-col>
    <v-col cols="12">
      <v-row dense>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Answers ({{ event.answers.length }})</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-col cols="12" v-for="answer in event.answers" :key="answer.id">
                <v-row dense>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="ID"
                      clearable
                      v-model="answer.id"
                      @blur="updateAnswer(answer)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      label="Label"
                      clearable
                      v-model="answer.name"
                      @blur="updateAnswer(answer)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
const debug = require('debug')('atman.components.questionnaire.designer.events')  // eslint-disable-line
export default {
  name: "QuestionEventDesigner",
  props: {
    event: {},
  },
  computed: {
    eventDefinition() {
      return this.event;
    },
  },
  methods: {
    updateEvent(event) {
      debug(`updateEvent Invoked`, event);
    },
    updateAnswer(answer) {
      debug(`updateAnswer Invoked`, answer);
    },
  },
};
</script>
