export default {
  label: "Embed Widget",
  type: "embed_widget",
  description: "Displays a embeddable field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Embeddable field.",
      label: "Default",
      value: {
        name: "default",
        label: "",
        type: "embed_widget",
      },
    },
    {
      id: "embed_youtube",
      description: "Embed a YouTube video",
      label: "YouTube",
      value: {
        icon: "mdi-youtube",
        display: {
          attributes: {
            button_label: "Youtube",
            placeholder:
              "Paste the embeddable content including <iframe>...</iframe> here. ",
          },
        },
        name: "embed_youtube",
        label: "",
        type: "embed_widget",
      },
    },
    {
      id: "embed_kindle",
      description: "Embed a Kindle book",
      label: "Kindle",
      value: {
        icon: "mdi-book-open",
        display: {
          attributes: {
            button_label: "Kindle",
          },
        },
        name: "embed_kindle",
        label: "",
        type: "embed_widget",
      },
    },
    {
      id: "display",
      label: "Display (Youtube)",
      variables: {
        data: {
          display:
            '<iframe width="560" height="315" src="https://www.youtube.com/embed/RWjnC8HSRdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        },
      },
      value: {
        name: "display",
        mode: "display",
        label: "",
        type: "embed_widget",
      },
    },
    {
      id: "display_kindle",
      label: "Display (Kindle)",
      variables: {
        data: {
          display_kindle:
            '<iframe height="800" width="600" frameborder="0" src="http://kindleweb.s3.amazonaws.com/app/1.0.11.053.093655/KindleReaderApp.html?asin=B000JQUT8S&assoctag=labnol-20"></iframe>',
        },
      },
      value: {
        name: "display_kindle",
        mode: "display",
        label: "",
        type: "embed_widget",
      },
    },
  ],
  attributes: [
    {
      name: "button_label",
      type: "select",
      hint: "Add label to the button",
      value: "Add Embed",
      options: ["Youtube", "Kindle", "Add Embed"],
    },
    {
      name: "placeholder",
      type: "text",
      hint: "Add placeholder in the input dialog",
      value: "",
    },
  ],
};
