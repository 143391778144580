import { color } from "@/components/fields/attributes.js";

import { subDays, addDays, subHours, addHours } from "date-fns";

const debug = require("debug")("atman.components.calendar"); //eslint-disable-line

export default {
  label: "Calendar",
  type: "calendar",
  description: "Displays a calendar field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],
  templates: [
    {
      id: "default",
      description: "The default Calendar field is displayed in monthly format.",
      label: "Default",
      variables: {
        data: {
          default: [
            {
              id: "id-0",
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              id: "id-1",
              name: "Updates",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
          ],
        },
      },
      value: {
        name: "default",
        label: "Calendar",
        type: "calendar",
      },
    },
    {
      id: "default_with_day",
      description: "The Calendar field displayed in daily format.",
      label: "Default With Day",
      variables: {
        data: {
          default_with_day: [
            {
              id: "id-0",
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              id: "id-1",
              name: "Updates",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
          ],
        },
      },
      value: {
        name: "default_with_day",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "day",
            first_time: "09:00",
            interval_time: "60",
          },
        },
      },
    },
    {
      id: "default_with_week",
      description: "The Calendar field displayed in weekly format.",
      label: "Default With Week",
      variables: {
        data: {
          default_with_week: [
            {
              id: "id-0",
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              id: "id-1",
              name: "Updates",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
          ],
        },
      },
      value: {
        name: "default_with_week",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "week",
          },
        },
      },
    },
    {
      id: "default_with_month",
      keyword: ["tutorial"],
      description: "The Calendar field displayed in monthly format.",
      label: "Default With Month",
      variables: {
        data: {
          default_with_month: [
            {
              id: "id-0",
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              id: "id-1",
              name: "Updates",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
          ],
        },
      },
      value: {
        name: "default_with_month",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "month",
          },
        },
      },
    },
    {
      id: "inline",
      description: `Use <code>"mode"</code> to set calendar in inline`,
      label: "Inline",
      variables: {
        data: {
          inline: [
            {
              id: "id-0",
              name: "daily Meeting",
              start: subHours(new Date(), 5),
              end: subHours(new Date(), 4),
            },
            {
              id: "id-1",
              name: "demo",
              start: subHours(subDays(new Date(), 4), 2),
              end: subHours(subDays(new Date(), 4), 1),
            },
            {
              id: "id-2",
              name: "Updates",
              start: addHours(addDays(new Date(), 5), 1),
              end: addHours(addDays(new Date(), 5), 2),
            },
          ],
        },
      },
      value: {
        name: "inline",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "month",
            mode: "inline",
            card: {
              classes: ["transparent"],
              flat: true,
              height: 400,
            },
          },
        },
      },
    },
    {
      id: "event_source",
      description: `Use <code>"source"</code> attribute for dynamic events`,
      label: "Event Source",
      variables: {
        data: null,
      },
      value: {
        name: "event_source",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "month",
            mode: "inline",
            source: "/all_events",
            card: {
              classes: ["transparent"],
              flat: true,
              height: 400,
            },
          },
        },
      },
    },
    {
      id: "type_control",
      description: `Use <code>"type_control"</code> to allow calendar type change`,
      label: "Type Control",
      variables: {
        data: null,
      },
      value: {
        name: "type_control",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "month",
            mode: "inline",
            type_control: true,
            day_view: true,
            card: {
              classes: ["transparent"],
              flat: true,
              height: 400,
            },
          },
        },
      },
    },
    {
      id: "expand",
      description: `Use <code>"expand"</code> to allow calendar expand option`,
      label: "Expandable",
      variables: {
        data: null,
      },
      value: {
        name: "expand",
        label: "Calendar",
        type: "calendar",
        display: {
          attributes: {
            type: "month",
            mode: "inline",
            expand: true,
            label: {
              classes: ["text-h6"],
            },
            card: {
              classes: ["transparent"],
              flat: true,
            },
          },
        },
      },
    },
  ],
  attributes: [
    color,
    {
      name: "type",
      type: "select",
      value: "month",
      hint: "Calendar Type",
      options: ["day", "week", "month"],
    },
    {
      name: "nav.color",
      type: "color",
      value: "success",
      hint: "Calendar Nav color",
    },
    {
      name: "nav.icon",
      type: "switch",
      value: true,
      hint: "Calendar Nav as icon",
    },
    {
      name: "events.color",
      type: "color",
      value: "info",
      hint: "Calendar events color",
    },
    {
      name: "activator.color",
      type: "color",
      value: "primary",
      hint: "Calendar activator color",
    },
  ],
};
