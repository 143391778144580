export default {
  id: "administration_home_page",
  title: "Administration",
  classes: "behavior-no-fav behavior-no-pin behavior-no-editor",
  content: [
    {
      display: {
        attributes: {
          "disable-filtering": true,
        },
      },
      definition: {
        actions: [],
        fields: [
          {
            click_action: {
              type: "navigation",
              url: "{_data->[i].href}",
            },
            name: "menu",
            type: "application_menu",
          },
        ],
        mode: "cards",
      },
      title: "Administration",
      type: "list",
      data: [
        {
          href: "/permissions?page=list",
          icon: "mdi-sign-direction",
          title: "Permissions",
          _permissions: {
            enabled: true,
            permission: {
              value: "list_permissions",
            },
          },
        },
        {
          href: "/domains",
          icon: "mdi-domain",
          title: "Domains",
          _permissions: {
            enabled: true,
            permission: {
              value: "list_domains",
            },
          },
        },
        {
          href: "/roles",
          icon: "mdi-account",
          title: "Roles",
          _permissions: {
            enabled: true,
            permission: {
              value: "list_roles",
            },
          },
        },
        {
          href: "/users?page=list",
          icon: "mdi-account-multiple",
          title: "Users",
          _permissions: {
            enabled: true,
            permission: {
              value: "list_users",
            },
          },
        },
        {
          href: "/settings",
          icon: "mdi-cog",
          title: "Settings",
          _permissions: {
            enabled: true,
            permission: {
              value: "list_settings",
            },
          },
        },
      ],
    },
  ],
};
