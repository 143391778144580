const masterList = [
  {
    id: "an",
    name: "Andaman and Nicobar Islands",
  },
  {
    id: "ap",
    name: "Andhra Pradesh",
  },
  {
    id: "ar",
    name: "Arunachal Pradesh",
  },
  {
    id: "as",
    name: "Assam",
  },
  {
    id: "br",
    name: "Bihar",
  },
  {
    id: "cg",
    name: "Chandigarh",
  },
  {
    id: "ch",
    name: "Chhattisgarh",
  },
  {
    id: "dh",
    name: "Dadra and Nagar Haveli",
  },
  {
    id: "dd",
    name: "Daman and Diu",
  },
  {
    id: "dl",
    name: "Delhi",
  },
  {
    id: "ga",
    name: "Goa",
  },
  {
    id: "gj",
    name: "Gujarat",
  },
  {
    id: "hr",
    name: "Haryana",
  },
  {
    id: "hp",
    name: "Himachal Pradesh",
  },
  {
    id: "jk",
    name: "Jammu and Kashmir",
  },
  {
    id: "jh",
    name: "Jharkhand",
  },
  {
    id: "ka",
    name: "Karnataka",
  },
  {
    id: "kl",
    name: "Kerala",
  },
  {
    id: "ld",
    name: "Lakshadweep",
  },
  {
    id: "mp",
    name: "Madhya Pradesh",
  },
  {
    id: "mh",
    name: "Maharashtra",
  },
  {
    id: "mn",
    name: "Manipur",
  },
  {
    id: "ml",
    name: "Meghalaya",
  },
  {
    id: "mz",
    name: "Mizoram",
  },
  {
    id: "nl",
    name: "Nagaland",
  },
  {
    id: "or",
    name: "Odisha",
  },
  {
    id: "py",
    name: "Puducherry",
  },
  {
    id: "pb",
    name: "Punjab",
  },
  {
    id: "rj",
    name: "Rajasthan",
  },
  {
    id: "sk",
    name: "Sikkim",
  },
  {
    id: "tn",
    name: "Tamil Nadu",
  },
  {
    id: "ts",
    name: "Telangana",
  },
  {
    id: "tr",
    name: "Tripura",
  },
  {
    id: "uk",
    name: "Uttarakhand",
  },
  {
    id: "up",
    name: "Uttar Pradesh",
  },
  {
    id: "wb",
    name: "West Bengal",
  },
];

const names = masterList.map((item) => item.name);

export { masterList, names };
