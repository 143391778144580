import {
  readonly,
  disabled,
  dense,
  color,
  append_icon,
  background_color,
  hint,
  persistent_hint,
  prepend_icon,
  ripple,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Checkboxes",
  description: "Displays checkboxes",
  type: "checkbox",

  templates: [
    {
      id: "default",
      description:
        "The default Checkboxes field view. It also showcases data in static form.",
      label: "Default/Static",
      variables: {
        data: {
          level: ["early", "middle"],
        },
      },
      value: {
        name: "level",
        default_value: "early",
        label: "Level",
        type: "checkbox",
        display: {
          attributes: {
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "hidden_label",
      description: "Use label attributes to hide the label.",
      label: "Hidden Label",
      variables: {
        data: {
          level: ["early", "middle"],
        },
      },
      value: {
        name: "level",
        label: "Level",
        type: "checkbox",
        display: {
          attributes: {
            label: {
              input: {
                mode: "hidden",
              },
            },
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
          ],
        },
      },
    },
    {
      id: "display",
      description:
        'Use <code>"mode": "display"</code> to display a label instead.',
      label: "Display Mode",
      variables: {
        data: {
          level: ["early", "middle"],
        },
      },
      value: {
        name: "level",
        mode: "display",
        label: "Level",
        type: "checkbox",
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
        display: {
          attributes: {
            row: true,
          },
        },
      },
    },
    {
      id: "checkbox_hint",
      description:
        "The Checkbox field displaying static data along with a hint.",
      label: "Checkbox With Hint",
      variables: {
        data: {
          level: ["early", "middle"],
        },
      },
      value: {
        name: "level",
        default_value: "early",
        label: "Level",
        type: "checkbox",
        display: {
          attributes: {
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
              hint: "Early Readers",
            },
            {
              name: "Middle Readers",
              id: "middle",
              hint: "Middle Readers",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
              hint: "Advanced Readers",
            },
            {
              name: "Adults",
              id: "adult",
              hint: "Adults",
            },
          ],
        },
      },
    },
    {
      id: "dynamic_checkbox",
      description:
        "Use <code>type</code> and <code>url</code> to display dynamic checkboxes.",
      label: "Display a Dynamic Checkbox",
      value: {
        name: "assignee",
        display: {
          width: "6",
        },
        value: ["1"],
        label: "Assignee",
        type: "checkbox",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "other_checkbox",
      description: `Add attribute <code>"show_other"=true</code> to display other option`,
      label: "Checkbox with other option",
      value: {
        name: "assignee",
        display: {
          width: "6",
          attributes: {
            other: {},
          },
        },
        value: ["1"],
        label: "Assignee",
        type: "checkbox",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
    {
      id: "single_select",
      description:
        "Use the <code>multiple</code> attribute to control whether to support multiple/single selection. <br/>\
Default value: <code>true</code><br/>\
When in single select mode, the value will be a simple string (and not an array of values)",
      label: "Single Select",
      variables: {
        data: {
          level: ["early"],
        },
      },
      value: {
        name: "level",
        default_value: "early",
        label: "Level",

        type: "checkbox",
        display: {
          attributes: {
            multiple: false,
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "checklist_item",
      description:
        "A single-select configured to be mandatory can function like a checklist item",
      label: "Checklist Item",
      variables: {
        data: {},
      },
      value: {
        name: "item_1",
        type: "checkbox",
        mandatory: true,
        display: {
          attributes: {
            multiple: false,
          },
        },
        options: {
          value: [
            {
              name: "Non nisi amet nulla commodo voluptate labore in eu duis ut.",
              id: "id_1",
            },
          ],
        },
      },
    },
    {
      id: "mandatory_checkbox",
      description: `Use <code>"mandatory"=true</code> maake checkboxes mandatory`,
      label: "Mandatory Checkboxes",
      value: {
        name: "mandatory_checkbox",
        display: {
          width: "6",
        },
        mandatory: true,
        label: "Assignee",
        type: "checkbox",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
          },
        },
      },
    },
  ],
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  attributes: [
    readonly,
    disabled,
    dense,
    color,
    append_icon,
    prepend_icon,
    background_color,
    hint,
    persistent_hint,
    ripple,
    no_value_text,
    no_value_behavior,
    {
      name: "multiple",
      type: "switch",
      value: true,
    },
    {
      name: "row",
      type: "switch",
      value: true,
    },
  ],
};
