import {
  readonly,
  disabled,
  dense,
  color,
  append_icon,
  background_color,
  hint,
  persistent_hint,
  prepend_icon,
  ripple,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";
import { defaultsDeep } from "lodash";

export default {
  label: "Radio Button",
  type: "radio",
  description: "Displays a radio button",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
    "disabled",
    "value",
  ],
  searchField: (col, data = {}) => {
    let result = defaultsDeep(
      {
        mode: "input",
        label: col.label,
        multiple: true,
        type: "select",
        display: {
          classes: ["pt-1"],
          width: "3",
          attributes: {
            atman_truncate: true,
          },
        },
      },
      col
    );
    const existingVal = data?.[col.name];
    if (existingVal) {
      result.value = existingVal;
    }
    return result;
  },
  search: ({ key, searchData, resultObj }) => {
    let meetsCriteria;
    const searchKeyValues = searchData[key];
    if (searchKeyValues.length == 0) {
      meetsCriteria = true;
    } else {
      meetsCriteria = searchKeyValues.includes(resultObj[key]);
    }
    return meetsCriteria;
  },
  templates: [
    {
      id: "default",
      description: "The default Radio Button field.",
      label: "Default",
      variables: {
        data: {
          default: "early",
        },
      },
      value: {
        label: "Levels",
        type: "radio",
        name: "default",
        display: {
          attributes: {
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
          ],
        },
      },
    },
    {
      id: "fa654c38-af98-5e6e-a134-960e7f0da29a",
      description: "Configure to display as chip",
      label: "Chip Display",
      variables: {
        data: {
          "fa654c38-af98-5e6e-a134-960e7f0da29a": "early",
        },
      },
      value: {
        label: "Levels",
        type: "radio",
        mode: "display",
        name: "fa654c38-af98-5e6e-a134-960e7f0da29a",
        display: {
          attributes: {
            row: true,
            display_as_chip: {
              enabled: true,
              label: true,
              color: "green",
              icon: "mdi-label",
            },
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
          ],
        },
      },
    },
    {
      id: "mandatory",
      description: "A mandatory Radio Button field.",
      label: "Mandatory",
      variables: {
        data: {
          mandatory: null,
        },
      },
      value: {
        label: "Levels",
        type: "radio",
        name: "mandatory",
        mandatory: true,
        display: {
          attributes: {
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
          ],
        },
      },
    },
    {
      id: "display",
      keyword: ["tutorial"],
      description: 'Use <code>"mode": "display"</code> to display data.',
      label: "Display Mode",
      variables: {
        data: {
          display: "early",
        },
      },
      value: {
        mode: "display",
        label: "Level",
        type: "radio",
        name: "display",
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
          ],
        },
        display: {
          attributes: {
            row: true,
          },
        },
      },
    },
    {
      id: "static",
      keyword: ["tutorial"],
      description:
        "Use <code>radio</code> and <code>value</code> to display a Static Radio button.",
      label: "A Static Radio",
      value: {
        name: "static",
        label: "Level",
        type: "radio",
        display: {
          attributes: {
            row: true,
          },
        },
        options: {
          value: [
            {
              name: "Early Readers",
              id: "early",
            },
            {
              name: "Middle Readers",
              id: "middle",
            },
            {
              name: "Advanced Readers",
              id: "advanced",
            },
            {
              name: "Adults",
              id: "adult",
            },
          ],
        },
      },
    },
    {
      id: "dynamic",
      description:
        "Use <code>type</code> and <code>get</code> to display a Dynamic Radio button.",
      label: "Display a Dynamic Radio",
      value: {
        name: "dynamic",
        display: {
          attributes: {
            row: true,
          },
        },
        label: "Assignee",
        type: "radio",
        options: {
          apis: {
            data: {
              url: "users",
              type: "get",
            },
            submit: {
              url: "users",
              type: "post",
            },
          },
        },
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    readonly,
    disabled,
    dense,
    color,
    append_icon,
    prepend_icon,
    background_color,
    hint,
    persistent_hint,
    ripple,
    {
      name: "row",
      type: "switch",
      value: true,
    },
  ],
};
