import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
export default {
  label: "Person Name",
  type: "person_name",
  description: "Displays a name",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description: "The default Person Name field",
      label: "Default",
      variables: {
        data: {
          first_name: "Warren",
          middle_name: "Greg",
          last_name: "Koepp",
          prefix: "Mr.",
        },
      },
      value: {
        type: "person_name",
        name: "default",

        name_fields: {
          salutation: "prefix",
          first_name: "first_name",
          middle_name: "middle_name",
          last_name: "last_name",
        },
      },
    },
    {
      id: "full_name",
      description: "Person name as a single string",
      label: "Full Name",
      variables: {
        data: {
          full_name: "Mr. Gregory Stanton",
        },
      },
      value: {
        label: "Full Name",
        type: "person_name",
        name: "full_name",
      },
    },
    {
      id: "display",
      description: "The default Person Name display field",
      label: "Display",
      variables: {
        data: {
          first_name: "Warren",
          middle_name: "Greg",
          last_name: "Koepp",
          prefix: "Mr.",
        },
      },
      value: {
        label: "Full Name",
        type: "person_name",
        name: "default",
        mode: "display",

        name_fields: {
          salutation: "prefix",
          first_name: "first_name",
          middle_name: "middle_name",
          last_name: "last_name",
        },
      },
    },
    {
      id: "nested_value",
      description: "Name from nested fields",
      label: "Nested Value",
      variables: {
        data: {
          first_name: "Warren",
          last_name: "Koepp",
          detail: {
            last_name: "Erdman",
          },
        },
      },
      value: {
        label: "Full Name",
        type: "person_name",
        name: "nested_value",
        mode: "display",

        name_fields: {
          first_name: "first_name",
          last_name: "detail.last_name",
        },
      },
    },
  ],
  attributes: [no_value_text, no_value_behavior],
};
