export default {
  _permissions: {
    enabled: true,
    permission: {
      value: "list_permissions",
    },
  },
  content: [
    {
      apis: {
        data: {
          refresh_interval: 300000,
          type: "get",
          url: "/permissions",
        },
      },
      definition: {
        actions: [
          {
            display: {
              attributes: {
                color: "primary",
              },
            },
            label: "Add Permission",
            name: "add_permission",
            type: "action",
            value: {
              type: "navigation",
              url: "/permissions?page=add",
            },
          },
        ],
        fields: [
          {
            display: {
              width: "12",
            },
            label: "Name",
            mode: "display",
            name: "name",
            type: "text",
          },
          {
            display: {
              width: "12",
            },
            label: "Created",
            mode: "display",
            name: "_created_date",
            type: "date",
          },
          {
            display: {
              width: "12",
            },
            label: "Created By",
            mode: "display",
            name: "_created_by",
            type: "username",
          },
          {
            label: "Actions",
            name: "actions",
            type: "actions",
            value: [
              {
                icon: "mdi-pencil",
                hint: "Edit",
                name: "edit",
                type: "action",
                value: {
                  type: "navigation",
                  url: "/permissions/{_row->id}?page=edit",
                },
              },
              {
                name: "delete",
                hint: "Delete",
                icon: "mdi-delete",
                type: "action",
                confirmation: "This is an irreversible action. Are you sure?",
                value: {
                  type: "post",
                  params: {
                    action: "mark_for_deletion",
                  },
                  url: "/permissions/{_row->name}",
                  success: {
                    message: "Deletion successful",
                  },
                },
                display: {
                  attributes: {
                    color: "error",
                  },
                },
              },
            ],
          },
        ],
        title: "",
      },
      display: {
        width: "12",
      },
      id: "list",
      type: "list",
    },
  ],
  id: "permissions_list",
  title: "Permissions",
};
