<template>
  <div class="spacer d-flex justify-center align-center" v-if="design">
    Spacer
  </div>
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
export default {
  name: "SpacerField",
  mixins: [fieldMixin],
  props: {
    design: {
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
.spacer {
  height: 3em;
  border-style: solid;
  border-width: 0.1em;
  border-color: black;
}
</style>
