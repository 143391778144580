<template>
  <v-container fluid class="px-3">
    <v-card aut-settings-page>
      <v-card-title aut-settings-page-title>Settings</v-card-title>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" v-if="skin">
            <v-tabs v-model="tabModel" @change="handleTabChange" vertical>
              <v-tab aut-tab="layout"> Layout </v-tab>
              <v-tab aut-tab="logo"> Logo </v-tab>
              <v-tab aut-tab="header"> Header </v-tab>
              <v-tab aut-tab="sidebar"> Sidebar </v-tab>
              <v-tab aut-tab="footer"> Footer </v-tab>
              <v-tab aut-tab="preferences"> Theme </v-tab>
              <v-tab aut-tab="fields"> Fields </v-tab>
              <v-tab aut-tab="roles"> Role Based </v-tab>
              <v-tab aut-tab="custom"> Custom </v-tab>
              <v-tab
                v-for="category in featureCategories"
                :aut-tab="category.id"
                :key="category.id"
                >{{ category.label }}</v-tab
              >
              <v-tab-item>
                <SettingsLayout :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsLogo :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsHeader :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsSidebar :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsFooter :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsTheme :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsFields :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <RoleBasedSettings :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item>
                <SettingsCustom :definition.sync="skin" />
              </v-tab-item>
              <v-tab-item
                v-for="category in featureCategories"
                :key="category.id"
              >
                <SettingsCategory
                  :features="category.features"
                  :definition.sync="skin"
                />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import permissionMixin from "@/views/permission_mixin.js";
import { mapState } from "vuex";
import { clone } from "@/util.js";
import { featureCategories } from "@/settings";
const debug = require('debug')('atman.administration.settings')  // eslint-disable-line

export default {
  name: "SettingsPage",
  mixins: [permissionMixin],
  components: {
    SettingsHeader: () => import("./components/SettingsHeader"),
    SettingsSidebar: () => import("./components/SettingsSidebar"),
    SettingsCategory: () => import("./components/SettingsCategory"),
    SettingsCustom: () => import("./components/SettingsCustom"),
    SettingsFooter: () => import("./components/SettingsFooter"),
    SettingsLogo: () => import("./components/SettingsLogo"),
    SettingsTheme: () => import("./components/SettingsTheme"),
    SettingsLayout: () => import("./components/SettingsLayout"),
    SettingsFields: () => import("./components/SettingsFields"),
    RoleBasedSettings: () => import("./components/RoleBasedSettings"),
  },
  data() {
    return {
      tabModel: (this?.$route?.query?.tab || 0) * 1,
    };
  },
  computed: {
    ...mapState(["skin"]),
  },
  created() {
    this.featureCategories = featureCategories;
  },
  mounted() {
    this.hasPermissions = this.checkRoutePermissions();
  },
  methods: {
    handleTabChange(index) {
      this.tabModel = index;
      if (this.$route.query.tab == this.tabModel) {
        return;
      }
      const queryParams = Object.assign({}, clone(this.$route.query), {
        tab: index,
      });
      this.$router.replace({ query: queryParams });
    },
  },
};
</script>
