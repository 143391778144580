<template>
  <div>
    <v-menu
      v-if="!isDisplayMode && !isReadOnly"
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          :label="label"
          :color="fieldColor"
          readonly
          aut-daterange-field
          :hint="`${dateFormat}`"
          persistent-hint
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        aut-daterange-picker
        :range="true"
        v-bind="displayAttributes"
      >
        <v-spacer></v-spacer>
        <v-btn aut-daterange-btn text :color="okColor" @click="menu = false">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-text-field
      :value="formattedDate"
      :label="label"
      :color="fieldColor"
      readonly
      :hint="`${dateFormat}`"
      persistent-hint
      prepend-icon="mdi-calendar"
      v-if="!isDisplayMode && isReadOnly"
    ></v-text-field>

    <div v-if="isDisplayMode" class="text-left">
      <label v-if="label" class="text-left"> {{ label }}: </label>
      <span class="text-left" aut-field-value>{{ formattedDate }}</span>
    </div>
  </div>
</template>
<script>
import { format, parseISO } from "date-fns";
import definition from "./definition";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.date_picker");

export default {
  name: "DateRange",
  data() {
    const result = {
      dateFormat: this?.value?.format || "yyyy-MM-dd",
      menu: false,
      definition,
    };
    return result;
  },
  components: {},
  mixins: [fieldMixin],
  computed: {
    okColor() {
      return this.displayAttributes?.ok_color || "";
    },
    fieldColor() {
      return this.displayAttributes?.field?.color || "";
    },
    date: {
      get() {
        let dateValues;
        if (!this.fieldValue) {
          dateValues = [new Date()];
        } else {
          dateValues = (this.fieldValue || []).map((date) => new Date(date));
        }

        let date;
        try {
          date = dateValues.map((value) =>
            format(parseISO(value.toISOString()), "yyyy-MM-dd")
          );
        } catch (e) {
          console.error(`Unable to parse date`, e);
          date = [format(parseISO(new Date().toISOString()), "yyyy-MM-dd")];
        }
        return date;
      },
      set(value) {
        let dates = value;
        const dateValue = dates.map((date) => new Date(date).toISOString());
        debug(`in date setter`, dateValue);
        this.$set(this, "fieldValue", this.orderDateRange(dateValue));
      },
    },
    formattedDate() {
      let result;
      try {
        if (this.fieldValue) {
          let dates = (this.fieldValue || []).map((date) =>
            format(parseISO(date), this.dateFormat)
          );
          result = dates.join("~");
        } else {
          result = "";
        }
      } catch (e) {
        console.error(e);
      }
      return result;
    },
  },
  methods: {
    orderDateRange(dates) {
      if (dates[0] > dates[1]) {
        dates.reverse();
      } else if (dates[0] == dates[1]) {
        dates.pop();
      }
      return dates;
    },
  },
  mounted() {
    debug("mounted of DatePicker", this.value);
  },
};
</script>
