<template>
  <v-col :cols="width" v-if="!isHidden">
    <AddressInput
      :context="context"
      aut-country
      v-if="isTextType"
      :label="label"
      v-model="country"
      :mandatory="isMandatory"
      @change="emitUpdate"
    />
    <AddressInput
      :context="context"
      v-else-if="countries"
      aut-country
      :label="label"
      :item-text="countryHasId ? 'name' : null"
      :item-value="countryHasId ? 'id' : null"
      inputType="select"
      :mandatory="isMandatory"
      :items="countries"
      v-model="country"
      @change="emitUpdate"
    />
  </v-col>
</template>
<script>
import {
  country2 as country_list,
  names as country_names,
} from "@/assets/jsons/countries.js";

import address_mixin from "./address_mixin";

const debug = require('debug')('atman.component.fields.address.country')  // eslint-disable-line

export default {
  name: "AddressCountry",
  mixins: [address_mixin],
  props: {
    context: {},
    value: {},
    definition: {},
  },
  watch: {
    "value.country"() {
      if (this.country != this.value.country) {
        this.country = this.value.country;
      }
    },
  },
  data() {
    const country = this._getValue("country");
    return {
      country,
    };
  },
  computed: {
    fieldProperties() {
      const configuration = this.definition?.address_fields?.country || {};

      return configuration;
    },
    countries() {
      let result = [];
      if (this.countryHasId) {
        result = country_list;
      } else {
        result = country_names;
      }
      debug(`countries`, result);
      return result;
    },
  },
  created() {
    this.defaults = {
      label: "Country",
      display: {
        width: "4",
      },
    };
  },
  methods: {
    emitUpdate($event) {
      debug(`Emitting update from country`);
      this.$emit("update", { country: $event });
    },
  },
};
</script>
