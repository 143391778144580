<template>
  <v-tooltip top v-if="hasPermission">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="action.event"
        v-bind="attrs"
        v-on="on"
        :disabled="action.disabled"
        :aut-editor-action="action.id"
        class="behavior_action_icon py-1 pr-1"
        :class="action.classes || 'primary--text'"
        @click="emitEvent"
        >{{ action.icon }}</v-icon
      >
      <v-icon
        v-else
        v-bind="attrs"
        v-on="on"
        :aut-editor-action="action.id"
        class="behavior_action_icon py-1 pr-1"
        :class="action.classes || 'grey--text'"
        >{{ action.icon }}</v-icon
      >
    </template>
    {{ action.label }}
  </v-tooltip>
</template>
<script>
import { confirmationMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.EditorAction");
debug(`EditorAction`);
export default {
  name: "EditorAction",
  props: {
    action: {
      type: Object,
    },
    context: {
      type: String,
    },
  },
  computed: {
    hasPermission() {
      const permission = this.action?.permission;
      if (typeof permission == "undefined") {
        return true;
      }
      if (this.context) {
        debug(`Checking permission on store ${this.context}`);
        const canPerformActionOnContext =
          this.$store.getters[`${this.context}/canPerformAction`];
        if (typeof canPerformActionOnContext === "function") {
          return canPerformActionOnContext(permission);
        }
      }
      const canUserPerformAction = this.$store.getters[`user/canPerformAction`];
      if (typeof canUserPerformAction != "function") {
        return true;
      }
      return canUserPerformAction(permission);
    },
  },
  mixins: [confirmationMixin],
  methods: {
    emitEvent() {
      if (this.action?.confirmation) {
        this.showConfirmation(this.action.confirmation, () => {
          this.$emit(this.action.event, this.action.param);
        });
        return;
      }
      this.$emit(this.action.event, this.action.param);
    },
  },
};
</script>
<style lang="scss" scoped>
.dragger {
  cursor: grab;
}
</style>
