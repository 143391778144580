import { get, defaultsDeep } from "lodash";
import { clone } from "@/util";

import {
  fetchUserPreferences,
  updateTheme,
  getCachedPreferences,
  cacheUserPreferences,
} from "./api";
import Vue from "vue";

const debug = require("debug")("atman.preferences");

export default {
  namespaced: true,
  state: (() => {
    // Fetch the preferences in localStorage
    const userPreferences = getCachedPreferences();
    updateTheme();
    return userPreferences;
  })(),
  getters: {
    getPreference: (state) => (key) => {
      return get(state, key);
    },
    defaultPage: (state, getters, rootState, rootGetters) => {
      return rootGetters["defaultPage"](
        rootState?.user?.profile?.default_profile?.role_id
      );
    },
    fieldAttributeValue: (state) => (type, attribute) => {
      const fields = state?.fields || {};
      const currentValueInStore = fields?.[type]?.attributes?.[attribute];
      debug(
        `Returning value [${currentValueInStore}] for attribute[${attribute}] of type: [${type}]`
      );
      return currentValueInStore;
    },
    isAFavourite: (state) => (url) => {
      debug(state.favourites, url);
      return !!(state.favourites || []).find((fav) => {
        return fav.href == url;
      });
    },
    favourites: (state) => {
      return state?.favourites || [];
    },
    isPinned: (state, getters, rootState, rootGetters) => (url) => {
      const isPinnedToDomain = rootGetters["isPinned"](url);
      if (isPinnedToDomain) {
        return isPinnedToDomain;
      }
      return (state.pinned || []).find(({ href }) => {
        return href == url;
      });
    },
    pinnedPages: (state) => (url) => {
      debug(`pinned pages`, state.pinned);
      return (state.pinned || []).filter(({ target }) => {
        return target == url;
      });
    },
    activeTheme(state, getters, rootState) {
      debug(`activeTheme`, {
        preferences: state?.theme,
        domainPreferences: rootState?.skin?.theme,
      });
      return state?.theme || "";
    },
    fieldSettings(state, getters, rootState) {
      return state?.fields || rootState?.skin?.fields || {};
    },
  },
  mutations: {
    setFields(state, fields) {
      Vue.set(state, "fields", fields);
      cacheUserPreferences(state);
    },
    updatePreferences(state, preferences) {
      Object.assign(state, preferences);
      updateTheme();
      // Not saving to local/ server intentionally
    },
    setActiveColor(state, color) {
      state.theme = color;
      cacheUserPreferences(state);
      updateTheme();
    },
    addFavourite(state, favourite) {
      state.favourites = state.favourites || [];
      state.favourites.push(favourite);
      cacheUserPreferences(state);
    },
    removeFavourite(state, url) {
      state.favourites = state.favourites || [];
      state.favourites = state.favourites.filter(({ href }) => {
        return href != url;
      });
      cacheUserPreferences(state);
    },
  },
  actions: {
    updatePinned({ state, rootGetters, dispatch }, { url, width }) {
      const isPinnedToDomain = rootGetters["isPinned"](url);
      const canPinToSkin = rootGetters["user/canPerformAction"]("pin_pages");
      if (isPinnedToDomain) {
        if (canPinToSkin) {
          return dispatch(
            "updatePinnedToDomain",
            { url, width },
            { root: true }
          );
        }
        return;
      }
      state.pinned = state.pinned || [];
      const widget = state.pinned.find(({ href }) => {
        return href == url;
      });
      if (!widget) {
        return;
      }
      widget.width = width;
      cacheUserPreferences(state);
    },
    addPinned(
      { state, rootGetters, dispatch },
      { url, target = "/dashboard", width = "6", pages }
    ) {
      const canPinToSkin = rootGetters["user/canPerformAction"]("pin_pages");
      if (canPinToSkin) {
        return dispatch(
          "addPinnedToDomain",
          { url, target, width, pages },
          { root: true }
        );
      }
      state.pinned = state.pinned || [];
      const pinnedItem = state.pinned.find((item) => item.href == url);
      if (pinnedItem) {
        Object.assign(pinnedItem, { href: url, target, width, pages });
      } else {
        state.pinned.push({ href: url, target, width, pages });
      }
      cacheUserPreferences(state);
    },
    removePinned({ state, rootGetters, dispatch }, { url }) {
      if (!url) {
        console.error("Invalid method invocation");
        return;
      }
      const canPinToSkin = rootGetters["user/canPerformAction"]("pin_pages");
      if (canPinToSkin) {
        return dispatch("removePinnedFromSkin", { url }, { root: true });
      }
      state.pinned = state.pinned || [];
      state.pinned = state.pinned.filter(({ href }) => {
        return href != url;
      });
      cacheUserPreferences(state);
    },
    updateFieldSettings({ commit, state }, inputFields) {
      debug(`updateFieldSettings invoked with `, inputFields);
      const fieldsInStore = state.fields;
      const fields = defaultsDeep({}, inputFields, fieldsInStore);
      commit("setFields", fields);
    },
    async fetchUserPreferences({ state, commit }) {
      const preferences = await fetchUserPreferences();
      commit("updatePreferences", preferences);
      updateTheme();
      cacheUserPreferences(state, false);
    },
    savePreferences({ state }, preferences) {
      const updatedPreferences = defaultsDeep({}, preferences, clone(state));
      cacheUserPreferences(updatedPreferences, { updateProfileFlag: true });
    },
  },
};
