export default {
  label: "Availability",
  filters: ["form_editor"],
  type: "availability",
  description: "Displays a Availability",
  properties: ["name", "label", "type", "mode", "display", "block"],
  templates: [
    {
      id: "default",
      description: "The field is configured to showcase availability.",
      label: "Default",
      variables: {
        data: {
          check: {
            total: 10,
            available: 9,
          },
        },
      },
      value: {
        name: "check",
        label: "Availability",
        type: "availability",
      },
    },
    {
      id: "availability_warning",
      description:
        "The field is configured to showcase <b>low</b> availability.",
      label: "Availability With Warning",
      variables: {
        data: {
          check: {
            total: 10,
            available: 2,
          },
        },
      },
      value: {
        name: "check",
        label: "Availability",
        type: "availability",
      },
    },
    {
      id: "availability_error",
      description:
        "The field is configured to showcase <b>no</b> availability.",
      label: "Availability With Error",
      variables: {
        data: {
          check: {
            total: 10,
            available: 0,
          },
        },
      },
      value: {
        name: "check",
        label: "Availability",
        type: "availability",
      },
    },
  ],
  attributes: [
    {
      name: "colors.primary",
      value: "primary",
      type: "color",
    },
    {
      name: "colors.warning",
      value: "warning",
      type: "color",
    },
    {
      name: "colors.error",
      value: "error",
      type: "color",
    },
  ],
};
