export default {
  label: "ImageSlider",
  type: "imageslider",
  description: "Displays a ImageSlider field",
  properties: ["name", "label", "type", "mode", "display", "block", "value"],
  templates: [
    {
      id: "default",
      label: "Default",

      value: {
        display: {
          atrributes: {},
        },
        name: "default",
        label: "Image Slider",
        type: "imageslider",
        img_data: [
          "https://cdn.pixabay.com/photo/2016/12/04/19/30/berlin-cathedral-1882397_1280.jpg",
          "/atmanlogo.png",
        ],
      },
    },
  ],
  attributes: [{ name: "img-height", type: "text", value: "600px" }],
};
