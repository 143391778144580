import ApplicationEditor from "./ApplicationEditor";
import ClientSidePage from "@/views/ClientSidePage.vue";

export default [
  {
    path: "/edit_application/:id",
    name: "Edit Application",
    component: ApplicationEditor,
    meta: { unauthenticated: false },
  },
  {
    path: "/applications/:id/_permissions",
    name: "Manage Permissions for application",
    component: ClientSidePage,
    meta: { unauthenticated: false, page_key: "application_permission_list" },
  },
];
