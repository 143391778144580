<template>
  <v-container>
    <v-row dense class="smaller mb-0 pb-0">
      <v-col>
        <v-text-field
          hide-details
          dense
          prepend-icon="mdi-magnify"
          aut-select-template
          placeholder="Search"
          v-model="searchValue"
          @keyup="performSearch"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <list-field
        label="Template"
        :list="filteredTemplates"
        :value="chosenTemplate"
        @select="chosenTemplate = $event"
      />
      <v-col cols="12" md="9" v-if="chosenTemplate">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              class="pa-2 transparent elevation-1"
              @click="useTemplate"
              aut-content-template
            >
              <Page
                class="templatePage"
                aut-page-template-page
                :page="getPageDefinition()"
                :context="getPageContext()"
                :nonDesignActions="[]"
              />
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="primary">
                  <v-btn @click="useTemplate">Use Template</v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getMatches } from "@/util.js";
import { fetchTemplates } from "@/util.js";
export default {
  name: "ContentTemplates",
  components: {
    Page: () => import("@/components/Page/Page"),
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      filteredTemplates: [],
      chosenTemplate: "",
      searchValue: "",
    };
  },
  mounted() {
    this.fetchTemplates();
  },
  methods: {
    performSearch() {
      if (!this.searchValue) {
        this.filteredTemplates = this.templates;
        return;
      }
      const searchKey = this.searchValue.toLowerCase();
      const matchedFields = getMatches(this.templates, searchKey, "filter");
      if (matchedFields.length) {
        this.filteredTemplates = matchedFields;
        return;
      }
      this.filteredTemplates = [];
    },
    async fetchTemplates() {
      const component = this;
      component.templates = component.filteredTemplates = await fetchTemplates(
        component.type
      );
      this.chosenTemplate = component.templates[0].id;
    },
    getPageDefinition() {
      const template = this.getChosenTemplate();
      return {
        title: template.label,
        content: [template.value],
      };
    },
    getChosenTemplate() {
      const template = this.templates.filter(
        ({ id }) => id == this.chosenTemplate
      )[0];
      return template;
    },
    getPageContext() {
      return {};
    },
    useTemplate() {
      const template = this.getChosenTemplate();
      this.$emit("select", template.value);
    },
  },
};
</script>
<style scoped lang="scss">
.templatePage {
  transform: scale(0.65);
}
</style>
