<template>
  <div>
    <v-btn-toggle tile v-for="(menu, index) in menus" :key="index" group>
      <v-btn text :color="color" v-if="menu && !menu.child" :to="menu.href">
        {{ menu.title }}
      </v-btn>
      <v-menu offset-y v-if="menu && menu.child">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text :color="color" v-bind="attrs" v-on="on" :to="menu.href">
            {{ menu.title }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menu.child" :key="index">
            <v-btn plain block :to="item.href" class="text-left">
              <v-list-item-title class="behavior_overflow">
                {{ item.title }}
              </v-list-item-title>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  name: "MenuOptions",
  props: {
    menus: {
      type: Array,
    },
    color: {
      type: String,
    },
  },
  methods: {
    getMenuTarget(menu) {
      if (!menu.href || menu.disabled) {
        return;
      }
      return menu.href;
    },
    navigateTo(menu) {
      if (this.disabled || menu.disabled) {
        return;
      }
      const href = menu.href;
      const currentPath = this.$route.path;
      if (href && href != currentPath) {
        this.$router.push(href);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
