import {
  color,
  min,
  max,
  readonly,
  background_color,
  append_icon,
  prepend_icon,
  disabled,
  dense,
  persistent_hint,
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes.js";

export default {
  label: "Slider",
  type: "slider",
  description: "Displays a Slider",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  templates: [
    {
      id: "default",
      description: "The default Slider.",
      label: "Default",
      value: {
        label: "Slider",
        type: "slider",
        min: 0,
        max: 100,
      },
    },
    {
      id: "basic",
      keyword: ["tutorial"],
      description: "An example with values",
      label: "Basic",
      variables: {
        data: {
          basic: 50,
        },
      },
      value: {
        name: "basic",
        label: "Age",
        type: "slider",
        min: 0,
        max: 100,
      },
    },
    {
      id: "hide_text_input",
      keyword: ["tutorial"],
      description: "An example where the text input is hidden",
      label: "Hide Input",
      variables: {
        data: {
          basic: 50,
        },
      },
      value: {
        display: {
          attributes: {
            behaviour_display_value: false,
          },
        },
        name: "hide_text_input",
        label: "Age",
        type: "slider",
        min: 0,
        max: 100,
      },
    },
    {
      id: "display",
      keyword: ["tutorial"],
      description: "An example with values",
      label: "Display",
      variables: {
        data: {
          display: 50,
        },
      },
      value: {
        name: "display",
        label: "Age",
        mode: "display",
        type: "slider",
        min: 0,
        max: 100,
      },
    },
  ],
  attributes: [
    min,
    max,
    {
      name: "step",
      type: "number",
      value: 1,
      hint: "Controls the interval",
    },
    {
      name: "thumb-color",
      type: "color",
      value: "",
      hint: "Controls the Thumb color",
    },
    {
      name: "thumb-size",
      type: "number",
      value: 32,
      hint: "Controls the Thumb size",
    },
    {
      name: "ticks",
      type: "switch",
      value: true,
      hint: "Display the track ticks",
    },
    {
      name: "tick-size",
      type: "number",
      value: 2,
      hint: "Controls the tick size",
    },
    color,
    background_color,
    readonly,
    append_icon,
    prepend_icon,
    disabled,
    dense,
    persistent_hint,
    no_value_text,
    no_value_behavior,
    {
      name: "behaviour_display_value",
      type: "switch",
      value: true,
      hint: "Display the value",
    },
    {
      name: "vertical",
      type: "switch",
      value: false,
      hint: "Change slider direction to vertical",
    },
    {
      name: "track-color",
      type: "color",
      value: "var(--v-primary-lighten3)",
      hint: "Controls the color",
    },

    {
      name: "track-fill-color",
      type: "color",
      value: "var(--v-primary-darken2)",
      hint: "Controls the color",
    },
  ],
};
