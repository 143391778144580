export default {
  label: "Tree View",
  type: "tree_view",
  description: "Displays a tree view",
  properties: ["name", "label", "type", "mandatory", "display", "block"],
  templates: [
    {
      id: "default",
      description: "The default Tree View field.",
      label: "Default",
      value: {
        label: "Tree View",
        type: "tree_view",

        value: {},
      },
    },
    {
      id: "example with value",
      keyword: ["tutorial"],
      description: "An example with data",
      label: "Basic",
      value: {
        name: "some_name",
        label: "Age",
        type: "tree_view",
        value: {
          id: "calendar",
          name: "Calendar",
          icon: "mdi-calendar",
          rat: {
            name: "rat",
            icon: "mdi-rodent",
            canada: {
              icon: "mdi-leaf-maple",
              name: "canada",
              rocket: {
                icon: "mdi-rocket",
                name: "rocket",
              },
              launch: {
                icon: "mdi-rocket-launch",
                name: "rocket launch",
              },
            },
          },
        },
      },
    },
  ],
};
