<template>
  <AtmanWizard :steps="steps" @submit="process" />
</template>
<script>
import { STORE_CONSTS, store } from "./store";
const debug = require('debug')('atman.wizard.application_creation')  // eslint-disable-line
import { AWStep1, AWStep2 } from "./index";

let i = 1;
const steps = [
  {
    id: i++,
    label: "Step 1",
    component: AWStep1,
    buttons: {
      next: false,
    },
  },
  {
    id: i++,
    label: "Step 2",
    component: AWStep2,
    buttons: {
      next: false,
    },
  },
];

export default {
  name: "ApplicationWizard",
  components: {
    AtmanWizard: () => import("@/components/editor/AtmanWizard"),
  },
  created() {
    const component = this;
    this.steps = steps;
    component._createStore(STORE_CONSTS.APPLICATION_WIZARD_STORE, store);
  },
  methods: {
    process() {
      debug(`in process method`);
    },
  },
};
</script>
