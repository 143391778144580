export default {
  categories: {
    dementia: {
      display: {
        attributes: {
          color: "#E0E2DB",
        },
        classes: [],
      },
      events: {
        "dementia-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Deterioration of memory within the last five years",
          type: "display_question",
        },
        "dementia-10": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: [
                  "dementia-test-1",
                  "dementia-test-2",
                  "dementia-test-3",
                  "dementia-test-4",
                  "dementia-test-5",
                  "dementia-test-6",
                  "dementia-test-7",
                  "dementia-test-8",
                ],
              },
              type: "trigger_event",
            },
          ],
          question: "Poor self care and hygiene",
          type: "display_question",
        },
        "dementia-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Way finding problem",
          type: "display_question",
        },
        "dementia-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Difficulty in speaking, understanding, naming, reading or writing",
          type: "display_question",
        },
        "dementia-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Recent change in behavior/personality (apathy, aggression, depression, hallucinations, wandering, disinhibition)",
          type: "display_question",
        },
        "dementia-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Poor planning, disorganized in daily activities",
          type: "display_question",
        },
        "dementia-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Forgetting daily skilled activities or handling tools",
          type: "display_question",
        },
        "dementia-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Walking difficulty/falls",
          type: "display_question",
        },
        "dementia-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Sleep disturbances",
          type: "display_question",
        },
        "dementia-9": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Urinary incontinence",
          type: "display_question",
        },
        "dementia-final-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                _is_exclusive: true,
                id: ["display_summary"],
              },
              type: "trigger_event",
            },
          ],
          question: "1. Dependant for activities of daily living on caregivers",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "unilateral",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_2",
                    value: "flashing_lights",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_3",
                    value: "vomiting",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "dementia-final-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                _is_exclusive: true,
                id: ["display_summary"],
              },
              type: "trigger_event",
            },
          ],
          question: "2. Dependant for activities of daily living on caregivers",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "unilateral",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_2",
                    value: "flashing_lights",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_3",
                    value: "vomiting",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "dementia-test-1": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          question: "1. Is it morning, afternoon or evening",
          type: "display_question",
        },
        "dementia-test-10": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-11",
              },
              type: "trigger_event",
            },
          ],
          question: "10. Which place is this?",
          type: "display_question",
        },
        "dementia-test-11": {
          answers: [
            {
              id: "wrong",
              name: " all wrong",
              weightage: 0,
            },
            {
              id: "correct_1",
              name: "only 1 correct",
              weightage: 1,
            },
            {
              id: "correct_2",
              name: "only 2 correct",
              weightage: 2,
            },
            {
              id: "correct_3",
              name: "all 3 correct",
              weightage: 3,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-12",
              },
              type: "trigger_event",
            },
          ],
          question:
            "11. I went to Delhi and brought three things- Mango, chair and coin. Can you tell me what are the three things I brought from Delhi?",
          type: "display_question",
        },
        "dementia-test-12": {
          answers: [
            {
              id: "wrong",
              name: " all wrong",
              weightage: 0,
            },
            {
              id: "correct_1",
              name: "only 1 correct",
              weightage: 1,
            },
            {
              id: "correct_2",
              name: "only 2 correct",
              weightage: 2,
            },
            {
              id: "correct_3",
              name: "all 3 correct",
              weightage: 3,
            },
            {
              id: "correct_4",
              name: "all 4 correct",
              weightage: 4,
            },
            {
              id: "correct",
              name: "all 5 correct",
              weightage: 5,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-13",
              },
              type: "trigger_event",
            },
          ],
          question:
            "12. Can you tell me the names of the days of week starting from Sunday backwards?",
          type: "display_question",
        },
        "dementia-test-13": {
          answers: [
            {
              id: "wrong",
              name: " all wrong",
              weightage: 0,
            },
            {
              id: "correct_1",
              name: "only 1 correct",
              weightage: 1,
            },
            {
              id: "correct_2",
              name: "only 2 correct",
              weightage: 2,
            },
            {
              id: "correct_3",
              name: "all 3 correct",
              weightage: 3,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-14",
              },
              type: "trigger_event",
            },
          ],
          question:
            "13. What are the names of the three things, which I told you have brought from Delhi?",
          type: "display_question",
        },
        "dementia-test-14": {
          answers: [
            {
              id: "wrong",
              name: " all wrong",
              weightage: 0,
            },
            {
              id: "correct_1",
              name: "only 1 correct",
              weightage: 1,
            },
            {
              id: "correct_2",
              name: "only 2 correct",
              weightage: 2,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-15",
              },
              type: "trigger_event",
            },
          ],
          question:
            "14. Show the subject the wrist watch and pen. Can you tell me about these objects?",
          type: "display_question",
        },
        "dementia-test-15": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-16",
              },
              type: "trigger_event",
            },
          ],
          question:
            "15. Now I am going to say something, listen carefully and repeat it exactly as I say after I finish phrase: ‘NEITHER THIS NOR THAT’",
          type: "display_question",
        },
        "dementia-test-16": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-17",
              },
              type: "trigger_event",
            },
          ],
          question:
            "16. Now look at my face and do exactly what I do. “CLOSE YOUR EYES’",
          type: "display_question",
        },
        "dementia-test-17": {
          answers: [
            {
              id: "wrong",
              name: " all wrong",
              weightage: 0,
            },
            {
              id: "correct_1",
              name: "only 1 correct",
              weightage: 1,
            },
            {
              id: "correct_2",
              name: "only 2 correct",
              weightage: 2,
            },
            {
              id: "correct_3",
              name: "all 3 correct",
              weightage: 3,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-18",
              },
              type: "trigger_event",
            },
          ],
          question:
            "17. First you take the paper in your right hand, then with your both hands, fold it into half once and then give the paper back to me.",
          type: "display_question",
        },
        "dementia-test-18": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-19",
              },
              type: "trigger_event",
            },
          ],
          question: "18. Now say a line about your house?",
          type: "display_question",
        },
        "dementia-test-19": {
          answers: [
            {
              id: "first_ans",
              name: "User only draw two four sided figure ",
              weightage: 1,
            },
            {
              id: "second_ans",
              name: "One figure should be mostly inside the other",
              weightage: 2,
            },
            {
              id: "third_ans",
              name: "Orientation of the figures should be appropriate",
              weightage: 3,
            },
          ],
          media: [
            {
              display: {
                attributes: {
                  width: "200px",
                },
              },
              src: "https://lh3.googleusercontent.com/drive-viewer/AJc5JmQxYcqk6ogpKaqOZmGeBzd0pM1GzVCS4pwmCyeKsPisp-nxdfz4vPK83GmEfykGRCP6S_wbk5k=w1920-h913",
              type: "image",
            },
          ],
          question:
            "19. Here is a drawing, you must copy this drawing exactly as shown",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "lessThan",
                    path: "$.dementia.weightage",
                    value: 19,
                  },
                ],
              },
              event: {
                params: {
                  id: "dementia-final-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.dementia.weightage",
                    value: 18,
                  },
                ],
              },
              event: {
                params: {
                  id: "dementia-final-2",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "dementia-test-2": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          question: "2. What day of the week is today",
          type: "display_question",
        },
        "dementia-test-3": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-4",
              },
              type: "trigger_event",
            },
          ],
          question: "3. What date is it today?",
          type: "display_question",
        },
        "dementia-test-4": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-5",
              },
              type: "trigger_event",
            },
          ],
          question: "4. Which month is today",
          type: "display_question",
        },
        "dementia-test-5": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-6",
              },
              type: "trigger_event",
            },
          ],
          question: "5. What season of the year is this?",
          type: "display_question",
        },
        "dementia-test-6": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-7",
              },
              type: "trigger_event",
            },
          ],
          question: "6. Under which post office does your village come?",
          type: "display_question",
        },
        "dementia-test-7": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-8",
              },
              type: "trigger_event",
            },
          ],
          question: "7. What district does your village fall under?",
          type: "display_question",
        },
        "dementia-test-8": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-9",
              },
              type: "trigger_event",
            },
          ],
          question: "8. Which village are you from?",
          type: "display_question",
        },
        "dementia-test-9": {
          answers: [
            {
              id: "wrong",
              name: "wrong",
              weightage: 0,
            },
            {
              id: "correct",
              name: "correct",
              weightage: 1,
            },
          ],
          next: [
            {
              params: {
                id: "dementia-test-10",
              },
              type: "trigger_event",
            },
          ],
          question:
            "9. Which block (if the village has only blocks) OR which numbered area is this?",
          type: "display_question",
        },
      },
      label: "Dementia",
    },
    epilepsy: {
      display: {
        attributes: {
          color: "#FBD1A2",
        },
        classes: [],
      },
      events: {
        "epilepsy-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-2",
              },
              type: "trigger_event",
            },
          ],
          question:
            "1. Did they pass urine or stool in his/her clothes during an episode?",
          type: "display_question",
        },
        "epilepsy-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "2. Did they ever injure himself/herself or have tongue bite during an episode?",
          type: "display_question",
        },
        "epilepsy-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "3. Was there frothing from mouth during an episode?",
          type: "display_question",
        },
        "epilepsy-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "4. Did they have such an episode while asleep?",
          type: "display_question",
        },
        "epilepsy-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "5. Was the patient completely unconscious during the episode?",
          type: "display_question",
        },
        "epilepsy-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "6. Has an episode ever occurred WITHOUT preceding emotional or mental stress",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.epilepsy.yes",
                    value: 3,
                  },
                ],
              },
              event: {
                params: {
                  message: "Probably Generalised Seizure",
                },
                type: "display_message",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.epilepsy.yes",
                    value: 3,
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-age",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "lessThan",
                    path: "$.epilepsy.yes",
                    value: 4,
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-unprovoked",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "equal",
                    path: "$.epilepsy.no",
                    value: 6,
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-unprovoked",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-age": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Is the patient below 10 years old?",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-age",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-fever",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-age",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-duration",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-duration": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "Does the patient have more than 1 unprovoked seizure 24 hours apart?",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "stroke-1",
                    "stroke-2",
                    "stroke-3",
                    "stroke-4",
                    "stroke-5",
                    "stroke-6",
                    "stroke-7",
                    "stroke-8",
                    "stroke-9",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["display_summary"],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    any: [
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-9",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-13",
                        value: "yes",
                      },
                    ],
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "dementia-1",
                    "dementia-2",
                    "dementia-3",
                    "dementia-4",
                    "dementia-5",
                    "dementia-6",
                    "dementia-7",
                    "dementia-8",
                    "dementia-9",
                    "dementia-10",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "no",
                  },
                  {
                    any: [
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-11",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-12",
                        value: "unilateral",
                      },
                    ],
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-fever": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Did the patient have fever?",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-no-fever-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-yes-fever-1",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-fever-age": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Is the patient below 5 years old?",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "stroke-1",
                    "stroke-2",
                    "stroke-3",
                    "stroke-4",
                    "stroke-5",
                    "stroke-6",
                    "stroke-7",
                    "stroke-8",
                    "stroke-9",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["display_summary"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-no-fever-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Were the events brief (less than a few seconds)",
          type: "display_question",
        },
        "epilepsy-no-fever-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Symptoms: Blinking/Staring",
          type: "display_question",
        },
        "epilepsy-no-fever-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Symptoms: Multiple per day",
          type: "display_question",
        },
        "epilepsy-no-fever-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-5",
              },
              type: "trigger_event",
            },
          ],
          question: "Precipitated at bedside by hyperventilation",
          type: "display_question",
        },
        "epilepsy-no-fever-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-no-fever-6",
              },
              type: "trigger_event",
            },
          ],
          question: "No developmental delay/regression",
          type: "display_question",
        },
        "epilepsy-no-fever-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-duration",
              },
              type: "trigger_event",
            },
          ],
          question: "Onset after 3 years",
          type: "display_question",
        },
        "epilepsy-unprovoked": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "1. Unprovoked stereotyped motor or sensory events, involving only one part of the body, with or without impaired awareness of surroundings?",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  message: "Probably Focal Seizure",
                },
                type: "display_message",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "epilepsy-duration",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    any: [
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-4",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-5",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-6",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-7",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-8",
                        value: "yes",
                      },
                    ],
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "stroke-1",
                    "stroke-2",
                    "stroke-3",
                    "stroke-4",
                    "stroke-5",
                    "stroke-6",
                    "stroke-7",
                    "stroke-8",
                    "stroke-9",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["display_summary"],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    any: [
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-9",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-13",
                        value: "yes",
                      },
                    ],
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "dementia-1",
                    "dementia-2",
                    "dementia-3",
                    "dementia-4",
                    "dementia-5",
                    "dementia-6",
                    "dementia-7",
                    "dementia-8",
                    "dementia-9",
                    "dementia-10",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-unprovoked",
                    value: "no",
                  },
                  {
                    any: [
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-11",
                        value: "yes",
                      },
                      {
                        fact: "questionnaire",
                        operator: "equal",
                        path: "$.general-12",
                        value: "unilateral",
                      },
                    ],
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "epilepsy-yes-fever-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Generalised Convulsive?",
          type: "display_question",
        },
        "epilepsy-yes-fever-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Single Episode?",
          type: "display_question",
        },
        "epilepsy-yes-fever-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Lasts <5 minutes?",
          type: "display_question",
        },
        "epilepsy-yes-fever-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-5",
              },
              type: "trigger_event",
            },
          ],
          question: "No post ictal weakness?",
          type: "display_question",
        },
        "epilepsy-yes-fever-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-yes-fever-6",
              },
              type: "trigger_event",
            },
          ],
          question: "No developmental delay/regression?",
          type: "display_question",
        },
        "epilepsy-yes-fever-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "epilepsy-fever-age",
              },
              type: "trigger_event",
            },
          ],
          question: "Regains consciouness completely within 5 min?",
          type: "display_question",
        },
      },
      label: "Epilepsy",
    },
    general: {
      display: {
        attributes: {
          color: "#C3DFE0",
        },
        classes: [],
      },
      events: {
        "general-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "1. Have you or any other member of your family suffered from loss of consciousness",
          type: "display_question",
        },
        "general-10": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "temporary",
              name: "Temporary (for a few hours)",
            },
            {
              id: "permanent",
              name: "Permanent",
            },
          ],
          next: [
            {
              params: {
                id: "general-11",
              },
              type: "trigger_event",
            },
          ],
          question:
            "10. Have you or any other member of your family suffered from Drooping of eyelids",
          type: "display_question",
        },
        "general-11": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-12",
              },
              type: "trigger_event",
            },
          ],
          question:
            "11. Have you or any other member of your family suffered from Episodes of severe facial pain",
          type: "display_question",
        },
        "general-12": {
          answers: [
            {
              id: "unilateral",
              name: "Unilateral",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          next: [
            {
              params: {
                id: "general-12_2",
              },
              type: "trigger_event",
            },
          ],
          question:
            "12.1 Have you or any other member of your family suffered from Headache?",
          type: "display_question",
        },
        "general-12_2": {
          answers: [
            {
              id: "flashing_lights",
              name: "Associated with flashes of light or circles",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          next: [
            {
              params: {
                id: "general-12_3",
              },
              type: "trigger_event",
            },
          ],
          question:
            "12.2 Have you or any other member of your family suffered from Headache?",
          type: "display_question",
        },
        "general-12_3": {
          answers: [
            {
              id: "vomiting",
              name: "Associated with vomiting",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          next: [
            {
              params: {
                id: "general-13",
              },
              type: "trigger_event",
            },
          ],
          question:
            "12.3 Have you or any other member of your family suffered from Headache?",
          type: "display_question",
        },
        "general-13": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-14",
              },
              type: "trigger_event",
            },
          ],
          question:
            "13. Have you or any other member of your family suffered from Any recent change in behavior or personality",
          type: "display_question",
        },
        "general-14": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-14_1",
              },
              type: "trigger_event",
            },
          ],
          question:
            "14. Have you or any other member of your family experienced Slowness of movement",
          type: "display_question",
        },
        "general-14_1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-14_2",
              },
              type: "trigger_event",
            },
          ],
          question:
            "15. Does the person require help from others to perform everyday activities such as eating, getting dressed, grooming, bathing, walking, or using the toilet ?",
          type: "display_question",
        },
        "general-14_2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-15",
              },
              type: "trigger_event",
            },
          ],
          question:
            "16. Is there physical disability that restricts movements?",
          type: "display_question",
        },
        "general-15": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "17. Have you or any other member of your family experienced Tremors",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-3",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "epilepsy-1",
                    "epilepsy-2",
                    "epilepsy-3",
                    "epilepsy-4",
                    "epilepsy-5",
                    "epilepsy-6",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-6",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-7",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-8",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "stroke-1",
                    "stroke-2",
                    "stroke-3",
                    "stroke-4",
                    "stroke-5",
                    "stroke-6",
                    "stroke-7",
                    "stroke-8",
                    "stroke-9",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "dementia-1",
                    "dementia-2",
                    "dementia-3",
                    "dementia-4",
                    "dementia-5",
                    "dementia-6",
                    "dementia-7",
                    "dementia-8",
                    "dementia-9",
                    "dementia-10",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "unilateral",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_2",
                    value: "flashing_lights",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_3",
                    value: "vomiting",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.general.all",
                    value: 1,
                  },
                ],
              },
              event: {
                params: {
                  id: "display_summary",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "general-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "2. Have you or any other member of your family suffered from sudden episode of loss of contact with surroundings",
          type: "display_question",
        },
        "general-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "3. Have you or any other member of your family suffered from uncontrollable shaking of head, arms or legs",
          type: "display_question",
        },
        "general-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "4. Have you or any other member of your family suffered from Abnormal speech",
          type: "display_question",
        },
        "general-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "5. Have you or any other member of your family suffered from Facial paralysis with sideways deviation of the mouth",
          type: "display_question",
        },
        "general-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "6. Have you or any other member of your family suffered from Weakness or Paralysis of arms or legs ",
          type: "display_question",
        },
        "general-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-8",
              },
              type: "trigger_event",
            },
          ],
          question:
            "7. Have you or any other member of your family suffered from Difficulty in walking (exclude individuals with pain or swelling of joints of lower limbs)",
          type: "display_question",
        },
        "general-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-9",
              },
              type: "trigger_event",
            },
          ],
          question:
            "8. Have you or any other member of your family suffered from Any loss of sensation or any abnormal sensation in the face, arms and legs",
          type: "display_question",
        },
        "general-9": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "general-10",
              },
              type: "trigger_event",
            },
          ],
          question:
            "9. Have you or any other member of your family suffered from Deterioration of memory within the last five years (applicable to subjects above 30 years of age)",
          type: "display_question",
        },
      },
      label: "General",
    },
    headache: {
      display: {
        attributes: {
          color: "#BCD979",
        },
        classes: [],
      },
      events: {
        "headache-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Age more than 50 years:",
          type: "display_question",
        },
        "headache-10": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "H/O cancer:",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.headache.yes",
                    value: 0,
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["display_summary"],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "equal",
                    path: "$.headache.no",
                    value: 10,
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_primary-1",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "headache-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-3",
              },
              type: "trigger_event",
            },
          ],
          question: "New-onset:",
          type: "display_question",
        },
        "headache-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Systemic symptoms (fever, weight-loss):",
          type: "display_question",
        },
        "headache-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-5",
              },
              type: "trigger_event",
            },
          ],
          question: "Superficial temporal artery tenderness:",
          type: "display_question",
        },
        "headache-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-6",
              },
              type: "trigger_event",
            },
          ],
          question: "Neurological deficits on clinical examination:",
          type: "display_question",
        },
        "headache-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-7",
              },
              type: "trigger_event",
            },
          ],
          question: "Papilledema:",
          type: "display_question",
        },
        "headache-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-8",
              },
              type: "trigger_event",
            },
          ],
          question: "Positional/Exertion induced:",
          type: "display_question",
        },
        "headache-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-9",
              },
              type: "trigger_event",
            },
          ],
          question: "Recent change in pattern:",
          type: "display_question",
        },
        "headache-9": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache-10",
              },
              type: "trigger_event",
            },
          ],
          question: "New or abrupt onset:",
          type: "display_question",
        },
      },
      label: "Headache",
    },
    headache_main: {
      display: {
        attributes: {
          color: "#BCD979",
        },
        classes: [],
      },
      events: {
        "headache_final-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_final-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Did you feel Tearing",
          type: "display_question",
        },
        "headache_final-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_final-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have Redness of eyes",
          type: "display_question",
        },
        "headache_final-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_final-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Stuffiness of nose",
          type: "display_question",
        },
        "headache_final-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_final-5",
              },
              type: "trigger_event",
            },
          ],
          question: "Blocked ears automatically",
          type: "display_question",
        },
        "headache_final-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Drooping of lids",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-5",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_freq-3",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-3",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_final-5",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "display_summary",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "headache_freq-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Frequency of the headache More than 15 days/month or daily",
          type: "display_question",
        },
        "headache_freq-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Frequency of the headache More than 15 days/month or daily",
          type: "display_question",
        },
        "headache_freq-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question:
            "Frequency of the headache More than 15 days/month or daily",
          type: "display_question",
        },
        "headache_primary-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Greater than 5 episodes of headache",
          type: "display_question",
        },
        "headache_primary-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Unilateral and throbbing",
          type: "display_question",
        },
        "headache_primary-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Associated nausea/vomiting",
          type: "display_question",
        },
        "headache_primary-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary-5",
              },
              type: "trigger_event",
            },
          ],
          question: "photophobia/phonophobia",
          type: "display_question",
        },
        "headache_primary-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary-6",
              },
              type: "trigger_event",
            },
          ],
          question: "Aura",
          type: "display_question",
        },
        "headache_primary-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "Worsens with physical activity",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-6",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_freq-1",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-3",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-4",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary-6",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_primary_no-1",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "headache_primary_no-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "headache_primary_no-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Nature of headache: tight, pressure like, band like",
          type: "display_question",
        },
        "headache_primary_no-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question: "Nature of headache: stabbing, retro orbital, hemicramial",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "no",
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_freq-2",
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  id: "headache_final-1",
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
      },
      label: "Headache",
    },
    stroke: {
      display: {
        attributes: {
          color: "#FFB3B3",
        },
        classes: [],
      },
      events: {
        "stroke-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question:
            "1. Weakness or loss of sensation on one side of body with deviation of face to one side",
          type: "display_question",
        },
        "stroke-10": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-1",
              },
              type: "trigger_event",
            },
          ],
          question: "8. Vision impairment",
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    path: "$.stroke.yes",
                    value: 0,
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["stroke-duration"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
        "stroke-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "2. Impaired consciouness",
          type: "display_question",
        },
        "stroke-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "3. Speech difficulty",
          type: "display_question",
        },
        "stroke-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "3.1 Slurred Speech",
          type: "display_question",
        },
        "stroke-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          question: "3.2 Difficulty in speaking or understanding",
          type: "display_question",
        },
        "stroke-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-7",
              },
              type: "trigger_event",
            },
          ],
          question: "4. Loss of balance or incoordination while walking",
          type: "display_question",
        },
        "stroke-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-8",
              },
              type: "trigger_event",
            },
          ],
          question: "5. Dizziness",
          type: "display_question",
        },
        "stroke-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-9",
              },
              type: "trigger_event",
            },
          ],
          question: "6. Swallowing problems",
          type: "display_question",
        },
        "stroke-9": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-10",
              },
              type: "trigger_event",
            },
          ],
          question: "7. Double vision",
          type: "display_question",
        },
        "stroke-duration": {
          answers: [
            {
              id: "less-1",
              name: "Less than 1 hour",
            },
            {
              id: "bet-1_4",
              name: "Between 1 hour to 2 hours",
            },
            {
              id: "gre-4",
              name: "Greater than 4 hours",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-1",
              },
              type: "trigger_event",
            },
          ],
          question: "Stroke Duration",
          type: "display_question",
        },
      },
      label: "Stroke",
    },
    "stroke-symptoms": {
      display: {
        attributes: {
          color: "#FFB3B3",
        },
        classes: [],
      },
      events: {
        "stroke-mimics-1": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-2",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Seizure.",
          type: "display_question",
        },
        "stroke-mimics-2": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-3",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of syncope.",
          type: "display_question",
        },
        "stroke-mimics-3": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-4",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Migraine with aura",
          type: "display_question",
        },
        "stroke-mimics-4": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-5",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Neuro Infection",
          type: "display_question",
        },
        "stroke-mimics-5": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-6",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Hypoglycemia",
          type: "display_question",
        },
        "stroke-mimics-6": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-7",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Hyperglycemia",
          type: "display_question",
        },
        "stroke-mimics-7": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "stroke-mimics-8",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Metabolic Disorders",
          type: "display_question",
        },
        "stroke-mimics-8": {
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          next: [
            {
              params: {
                id: "display_summary",
              },
              type: "trigger_event",
            },
          ],
          question: "Does Patient have symptoms of Psychogenic",
          rules: [
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-9",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-13",
                    value: "yes",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: [
                    "dementia-1",
                    "dementia-2",
                    "dementia-3",
                    "dementia-4",
                    "dementia-5",
                    "dementia-6",
                    "dementia-7",
                    "dementia-8",
                    "dementia-9",
                    "dementia-10",
                  ],
                },
                type: "trigger_event",
              },
            },
            {
              conditions: {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-11",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12",
                    value: "unilateral",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_2",
                    value: "flashing_lights",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.general-12_3",
                    value: "vomiting",
                  },
                ],
              },
              event: {
                params: {
                  _is_exclusive: true,
                  id: ["headache-1"],
                },
                type: "trigger_event",
              },
            },
          ],
          type: "display_question",
        },
      },
      label: "Stroke Symptoms",
    },
  },
  events: {
    display_summary: {
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                path: "$.general.no",
                value: 19,
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;  Inconclusive</p>",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-unprovoked",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams; Uncertain for seizure</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams; Level 1 diagnosis - Probably Generalised Seizure / Final diagnosis - Uncertain for Epilepsy</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Probably Generalised Seizure / Final diagnosis - Epilepsy Diagonosis</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-unprovoked",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Probably Focal Seizure / Final diagnosis - Uncertain for Epilepsy</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-unprovoked",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Probably Focal Seizure / Final diagnosis - Epilepsy Diagonosis</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever-age",
                    value: "yes",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Typical Febrile Seizure</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-4",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-yes-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever-age",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Atypical Febrile Seizure</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-1",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-2",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-3",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-4",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-5",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-yes-fever-6",
                value: "no",
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever-age",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-fever-age",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Atypical Febrile Seizure</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-duration",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Typical Absence Seizures / Final diagnosis - Uncertain for Epilepsy</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-duration",
                    value: "yes",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Typical Absence Seizures / Final diagnosis -  Epilepsy diagnosis</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "yes",
                  },
                ],
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Probably Absence Seizures  / Final diagnosis -  Epilepsy diagnosis</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "yes",
                  },
                ],
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Level 1 diagnosis - Probably Absence Seizures  / Final diagnosis -  Uncertain for Epilepsy</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Generalised Seizure</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "greaterThan",
                path: "$.stroke.yes",
                value: 0,
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Stroke</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                path: "$.stroke.no",
                value: 10,
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Uncertain Stroke</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-1",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Dementia</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-1",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Early Dementia</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-2",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Early Dementia </p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-2",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value:
                "<p> &diams;Subjective Memory Impairement or Mild Cognitive Impairement</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "greaterThan",
                path: "$.headache.yes",
                value: 0,
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Secondary Headache Syndrome</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-1",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Episodic Migraine</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-1",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Chronic Migraine</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-2",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Episodic Tension Type Headache</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-2",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Chronic Tension Type Headache</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-3",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Trigeminal autonomic cephalagias</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-3",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Chronic Trigeminal autonomic cephalagias</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-1",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-2",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-3",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-4",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-5",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Uncertain for Headache</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            any: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "outcome",
              value: "<p> &diams;Uncertain for Headache</p>",
            },
            type: "update",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "greaterThan",
                path: "$.headache.yes",
                value: 0,
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-1",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-1",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-2",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-2",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-3",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_freq-3",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "yes",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-1",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-2",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-3",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-4",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.headache_final-5",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "no",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            any: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.headache_primary_no-2",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "headache_diagonosis",
              value: "no",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "UNCERTAIN EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-age",
                value: "no",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-unprovoked",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "UNCERTAIN EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-unprovoked",
                value: "yes",
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-duration",
                    value: "no",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "UNCERTAIN EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "yes",
                  },
                ],
              },
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-duration",
                    value: "yes",
                  },
                ],
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "yes",
                  },
                ],
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-1",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-2",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-5",
                    value: "no",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-6",
                    value: "no",
                  },
                ],
              },
              {
                any: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-3",
                    value: "yes",
                  },
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    path: "$.epilepsy-no-fever-4",
                    value: "yes",
                  },
                ],
              },
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.epilepsy-duration",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "epilepsy_diagonosis",
              value: "UNCERTAIN EPILEPSY",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "greaterThan",
                path: "$.stroke.yes",
                value: 0,
              },
            ],
          },
          event: {
            params: {
              target: "stroke_diagonosis",
              value: "STROKE",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                path: "$.stroke.no",
                value: 10,
              },
            ],
          },
          event: {
            params: {
              target: "stroke_diagonosis",
              value: "UNCERTAIN STROKE",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-1",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "dementia_diagonosis",
              value: "DEMENTIA",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-1",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "dementia_diagonosis",
              value: "EARLY DEMENTIA",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-2",
                value: "yes",
              },
            ],
          },
          event: {
            params: {
              target: "dementia_diagonosis",
              value: "EARLY DEMENTIA",
            },
            type: "assign",
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "questionnaire",
                operator: "equal",
                path: "$.dementia-final-2",
                value: "no",
              },
            ],
          },
          event: {
            params: {
              target: "dementia_diagonosis",
              value: "DEMENTIA",
            },
            type: "assign",
          },
        },
      ],
      type: "display_summary",
    },
  },
  start: {
    params: {
      id: [
        "general-1",
        "general-2",
        "general-3",
        "general-4",
        "general-5",
        "general-6",
        "general-7",
        "general-8",
      ],
    },
    type: "trigger_event",
  },

  summary: {
    title: "Diagnosis",
    score_title: "Dementia Score",
  },
};
