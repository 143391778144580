export default {
  start: {
    type: "trigger_event",
    params: {
      id: ["general-1"],
    },
  },
  events: {
    display_summary: {
      type: "display_summary",
      rules: [
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 13,
                path: "$.general.no",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: {
                id: "no_issues",
                label: "Inconclusive",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "lessThan",
                value: 3,
                path: "$.epilepsy.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: {
                id: "Uncertain for seizure",
                label: "Uncertain for seizure",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "equal",
                value: 3,
                path: "$.epilepsy.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: {
                id: "Probably Focal Seizure",
                label: "Probably Focal Seizure",
              },
            },
          },
        },
        {
          conditions: {
            all: [
              {
                fact: "stats",
                operator: "greaterThan",
                value: 4,
                path: "$.epilepsy.yes",
              },
            ],
          },
          event: {
            type: "assign",
            params: {
              target: "outcome",
              value: {
                id: "Probably Generalised Seizure",
                label: "Probably Generalised Seizure",
              },
            },
          },
        },
      ],
    },
  },
  categories: {
    general: {
      display: {
        classes: [],
        attributes: {
          color: "#C3DFE0",
        },
      },
      label: "General",
      events: {
        "general-1": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from loss of consciousness",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-1",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-1",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-2"],
                },
              },
            },
          ],
        },
        "general-2": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from sudden episode of loss of contact with surroundings",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-2",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-2",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-3"],
                },
              },
            },
          ],
        },
        "general-3": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from uncontrollable shaking of head, arms or legs",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-3",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-3",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-4"],
                },
              },
            },
          ],
        },
        "general-4": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Abnormal speech",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-4",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["stroke-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-4",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-5"],
                },
              },
            },
          ],
        },
        "general-5": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Facial paralysis with sideways deviation of the mouth",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-5",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["stroke-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-5",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-6"],
                },
              },
            },
          ],
        },
        "general-6": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Weakness or Paralysis of arms or legs ",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-6",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["stroke-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-6",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-7"],
                },
              },
            },
          ],
        },
        "general-7": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Difficulty in walking (exclude individuals with pain or swelling of joints of lower limbs)",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-7",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["stroke-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-7",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-8"],
                },
              },
            },
          ],
        },
        "general-8": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Any loss of sensation or any abnormal sensation in the face, arms and legs",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-8",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["stroke-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-8",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-9"],
                },
              },
            },
          ],
        },
        "general-9": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Deterioration of memory within the last five years (applicable to subjects above 30 years of age)",
          answers: [
            {
              id: "yes",
              name: "Yes",
            },
            {
              id: "no",
              name: "No",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-9",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["dementia-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-9",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-10"],
                },
              },
            },
          ],
        },
        "general-10": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Drooping of eyelids",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "temporary",
              name: "Temporary (for a few hours)",
            },
            {
              id: "permanent",
              name: "Permanent",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-10",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-11"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "temporary",
                    path: "$.general-10",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-11"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "permanent",
                    path: "$.general-10",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-11"],
                },
              },
            },
          ],
        },
        "general-11": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Episodes of severe facial pain",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-11",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-11",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-12"],
                },
              },
            },
          ],
        },
        "general-12": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Headache",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "unilateral",
              name: "Unilateral",
            },
            {
              id: "flashing_lights",
              name: "Associated with flashes of light or circles",
            },
            {
              id: "vomiting",
              name: "Associated with vomiting",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "unilateral",
                    path: "$.general-12",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "flashing_lights",
                    path: "$.general-12",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "vomiting",
                    path: "$.general-12",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-12",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["general-13"],
                },
              },
            },
          ],
        },
        "general-13": {
          type: "display_question",
          question:
            "Have you or any other member of your family suffered from Any recent change in behavior or personality",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.general-13",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["headache-1"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.general-13",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["display_summary"],
                },
              },
            },
          ],
        },
      },
    },
    epilepsy: {
      display: {
        classes: [],
        attributes: {
          color: "#FBD1A2",
        },
      },
      label: "Epilepsy",
      events: {
        "epilepsy-1": {
          type: "display_question",
          question:
            "Did they pass urine or stool in his/her clothes during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-1",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-2"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-1",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-2"],
                },
              },
            },
          ],
        },
        "epilepsy-2": {
          type: "display_question",
          question:
            "Did they ever injure himself/herself or have tongue bite during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-2",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-3"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-2",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-3"],
                },
              },
            },
          ],
        },
        "epilepsy-3": {
          type: "display_question",
          question: "Was there frothing from mouth during an episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-3",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-4"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-3",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-4"],
                },
              },
            },
          ],
        },
        "epilepsy-4": {
          type: "display_question",
          question: "Did they have such an episode while asleep?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-4",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-5"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-4",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-5"],
                },
              },
            },
          ],
        },
        "epilepsy-5": {
          type: "display_question",
          question:
            "Was the patient completely unconscious during the episode?",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "yes",
                    path: "$.epilepsy-5",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-6"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "questionnaire",
                    operator: "equal",
                    value: "no",
                    path: "$.epilepsy-5",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["epilepsy-6"],
                },
              },
            },
          ],
        },
        "epilepsy-6": {
          type: "display_question",
          question:
            "Has an episode ever occurred WITHOUT preceding emotional or mental stress",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
          rules: [
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "lessThan",
                    value: 3,
                    path: "$.epilepsy.yes",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["display_summary"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "equal",
                    value: 3,
                    path: "$.epilepsy.yes",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["display_summary"],
                },
              },
            },
            {
              conditions: {
                all: [
                  {
                    fact: "stats",
                    operator: "greaterThan",
                    value: 4,
                    path: "$.epilepsy.yes",
                  },
                ],
              },
              event: {
                type: "trigger_event",
                params: {
                  id: ["display_summary"],
                },
              },
            },
          ],
        },
      },
    },

    dementia: {
      label: "Dementia",
      events: {
        "dementia-1": {
          type: "display_question",
          question: "A question related to Dementia will appear here",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
      },
    },
    headache: {
      label: "Headache",
      events: {
        "headache-1": {
          type: "display_question",
          question: "A question related to Headache will appear here",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
      },
    },
    stroke: {
      label: "Stroke",
      events: {
        "stroke-1": {
          type: "display_question",
          question: "A question related to Stroke will appear here",
          answers: [
            {
              id: "no",
              name: "No",
            },
            {
              id: "yes",
              name: "Yes",
            },
          ],
        },
      },
    },
  },
  summary: {
    title: "Diagnosis",
  },
};
