export default {
  type: "computed_duration",
  label: "Computed Duration",
  description: "Displays a computed duration",
  properties: ["type", "display", "block"],
  templates: [
    {
      id: "default",
      label: "Default",
      variables: {
        data: {
          variable1: "10:22",
          variable2: "12:20",
        },
      },
      value: {
        name: "default",
        start_time: "variable1",
        end_time: "variable2",
        type: "computed_duration",
      },
    },
  ],
};
