import { getFeatureValue } from "@/util";
const debug = require("debug")("atman.store.page");// eslint-disable-line
export const store = {
  namespaced: true,
  state: () => ({
    access_control: {},
  }),
  getters: {
    canPerformAction: (state, getters, rootState, rootGetters) => (action) => {
      if (!action) {
        debug(`No action passed to canPerformAction. Returning true`);
        return true;
      }

      const bypassRoles = (
        getFeatureValue("permissions.bypass_roles") || ""
      ).split(",");
      const userRole = rootState?.user.profile?.default_profile?.role_id;
      if (userRole && bypassRoles.includes(userRole)) {
        return true;
      }

      if (state.access_control[action]) {
        debug(
          `Permission available on page. ${
            state.access_control[action]
          }. Returning [${state.access_control[action] === "YES"}]`
        );
        return state.access_control[action] === "YES";
      }
      return rootGetters["user/canPerformAction"](action);
    },
  },
  mutations: {
    setAccessControl(state, access_control) {
      state.access_control = access_control;
    },
  },
  actions: {},
};
