import { omit } from "lodash";

const determineFormat = (duration) => {
  let formatObject = {
    years: true,
    months: true,
    weeks: true,
    days: true,
    hours: true,
    minutes: true,
    seconds: true,
  };

  if (duration.years) {
    formatObject = omit(formatObject, ["seconds", "minutes", "hours"]);
  }
  if (duration.months) {
    formatObject = omit(formatObject, ["seconds", "minutes", "hours"]);
  }
  if (duration.weeks) {
    formatObject = omit(formatObject, ["seconds", "minutes", "hours"]);
  }
  if (duration.days) {
    formatObject = omit(formatObject, ["minutes", "seconds"]);
  }
  return Object.keys(formatObject);
};

export { determineFormat };
