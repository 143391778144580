<template>
  <div v-if="shouldDisplay">
    <label v-if="label">{{ label }}</label>
    <AtmanComponent
      aut-field-table
      :context="listContext"
      :definition="getListDefinition()"
      :data="fieldValue"
      :storeKeyProp="storeKey"
    />
  </div>
</template>
<script>
import { store, STORE_CONSTS } from "@/components/fields/store";
import { fieldMixin } from "@/components/mixin.js";
import { clone, uniqueID } from "@/util.js";
import definition from "./definition";
const debug = require("debug")("atman.components.table_field"); // eslint-disable-line

export default {
  name: "TableField",
  mixins: [fieldMixin],
  data() {
    return {
      definition: definition,
    };
  },
  components: {
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
  },
  computed: {
    shouldDisplay() {
      return this.mode == "display" || this.mode == "input";
    },
    listContext() {
      const result = this.$store?.state?.[this.context]?.context || {};
      debug(`listContext`, result);
      return result;
    },
  },
  created() {
    const component = this;
    const storeKey = (this.storeKey = `table_field_${
      this.value?.name || uniqueID()
    }`);
    component._createStore(storeKey, store);
  },
  mounted() {
    debug(`fieldValue`, this.fieldValue);
    this.setupSubscriptions();
  },
  methods: {
    setupSubscriptions() {
      const component = this;
      if (!this.displayAttributes?.propagate_data_up) {
        debug(`Not going to propagate`);
        return;
      }
      debug(`Going to propagate`);
      const storeKey = component.storeKey;
      component._subscribe(
        component.$store.subscribe((mutation) => {
          if (
            mutation.type == `${storeKey}/${STORE_CONSTS.DATA}` ||
            mutation.type == `${storeKey}/${STORE_CONSTS.FIELD}`
          ) {
            const updatedData = component.$store.state[storeKey].data;
            debug(`list data updated. Updating field`, updatedData);
            component.handleDataUpdate(updatedData);
          }
        })
      );
    },

    handleDataUpdate(data) {
      const component = this;
      component.$set(component, "fieldValue", data);
    },
    getListDefinition() {
      const component = this;
      let definition = this.value?.definition || {};
      debug(`Before replacing variables`, definition);
      let stringifiedDefinition = JSON.stringify(definition);
      stringifiedDefinition = component.$store.getters[
        `${component.context}/dynamicText`
      ]({ url: stringifiedDefinition });
      definition = JSON.parse(stringifiedDefinition);
      debug(`after replacing variables`, definition);
      const result = Object.assign(
        {
          type: "list",
        },
        clone(definition)
      );
      debug(`list definition`, result);
      return result;
    },
  },
};
</script>
