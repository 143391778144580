<template>
  <v-container fluid>
    <v-card aut-lookup-editor-page>
      <v-card-title>{{ lookup }}</v-card-title>
      <v-card-text>
        <StaticSeed
          @update:seed_data="updateDefinition"
          :data="lookupValues"
          :key="renderKey"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="goBack">Cancel</v-btn>
        <v-btn aut-submit-lookup color="primary" @click="saveLookup"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { makeServerCall } from "@/util.js";
const debug = require("debug")("atman.components.lookup_editor");
debug(`lookup_editor`);
export default {
  name: "LookupEditor",
  components: {
    StaticSeed: () =>
      import("@/components/fields/PageEditor/SeedDataDesigner/StaticSeed.vue"),
  },
  data() {
    return {
      renderKey: 1,
      lookup: null,
      lookupValues: {},
      updatedValues: [],
    };
  },
  async mounted() {
    this.application = this.$route.params.id;
    this.lookup = this.$route.query.lookup;
    const data = await this.getLookupValues();
    this.lookupValues = { value: data.data || [] };
    this.renderKey++;
    debug(`lookupValues`, this.lookupValues);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getLookupValues() {
      return makeServerCall({
        url: `/applications/${this.application}?lookup=${this.lookup}`,
        type: "get",
      });
    },
    updateDefinition(seedData) {
      this.updatedValues = seedData.value;
    },
    saveLookup() {
      const data = [...this.updatedValues];
      makeServerCall({
        url: `/applications/${this.application}?lookup=${this.lookup}`,
        type: "post",
        params: {
          action: "update_fields",
          data,
        },
      }).then(() => {
        this.displaySuccessMessage("Lookup updated");
      });
    },
  },
};
</script>
