<template>
  <div class="relative">
    <EditorActions
      class="pa-2"
      :actions="actions"
      @toggle_content="toggleTree"
    />
    <v-treeview
      ref="treeView"
      dense
      activatable
      v-model="selectedValue"
      :items="treeValue"
      item-key="id"
      :return-object="true"
      item-text="name"
      hoverable
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
      @update:active="activeChanged"
      @update:open="openNodes = $event"
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="item.icon">
          {{ item.icon }}
        </v-icon>
      </template>
    </v-treeview>
  </div>
</template>
<script>
import { CHOSEN_VALUE } from "@/store";
import { clone } from "@/util.js";
import { fieldMixin } from "@/components/mixin.js";
import { defaults } from "lodash";
import EditorActions from "@/components/editor/EditorActions";
const debug = require("debug")("atman.components.tree_view");
debug(`tree_view`);

const getVuetifyStructure = (inputObject, path = []) => {
  const childObject = {
    id: inputObject.id,
    name: inputObject.name,
    icon: inputObject.icon,
    path,
  };
  const childKeys = Object.keys(inputObject).filter(
    (key) => !["name", "id", "icon"].includes(key)
  );

  path.push(inputObject.id);
  const result = [];
  result.push(childObject);
  if (!childKeys.length) {
    return result;
  }

  childObject.children = [];
  childKeys.forEach((key) => {
    const value = inputObject[key];
    if (typeof value == "object") {
      const childArray = getVuetifyStructure(
        defaults(value, { id: key }),
        clone(path)
      );
      childObject.children = childObject.children.concat(childArray);
    }
  });
  return result;
};

export { getVuetifyStructure };

export default {
  name: "TreeView",
  mixins: [fieldMixin],
  data() {
    return {
      openNodes: [],
      isOpen: false,
      selectedValue: [],
    };
  },
  components: {
    EditorActions,
  },
  computed: {
    actions() {
      const component = this;
      return [
        {
          id: "toggle_content",
          label: component.isOpen ? "Collapse All Nodes" : "Expand All Nodes",
          icon: component.isOpen
            ? "mdi-arrow-collapse-all"
            : "mdi-arrow-expand-all",
          event: "toggle_content",
        },
      ];
    },
    treeValue() {
      let result = [];
      if (!this.fieldValue) {
        return result;
      }
      result = getVuetifyStructure(this.fieldValue);
      debug(`vuetify structure`, result);
      return result;
    },
  },
  created() {},
  methods: {
    expandTree() {
      const component = this;
      const treeView = component.$refs.treeView.$el;
      let unopenedNodes = treeView.querySelectorAll(
        ".v-treeview-node__toggle:not(.v-treeview-node__toggle--open)"
      );
      if (unopenedNodes.length == 0) {
        component.isOpen = true;
        return;
      }
      unopenedNodes.forEach((element) => {
        element.click();
        setTimeout(() => {
          component.expandTree();
        }, 1);
      });
    },
    collapseTree() {
      const component = this;
      const treeView = component.$refs.treeView.$el;
      let openedNodes = treeView.querySelectorAll(
        ".v-treeview-node__toggle.v-treeview-node__toggle--open"
      );
      if (openedNodes.length == 0) {
        component.isOpen = false;
        return;
      }
      openedNodes.forEach((element) => {
        element.click();
        setTimeout(() => {
          component.collapseTree();
        }, 1);
      });
    },
    toggleTree() {
      if (this.isOpen) {
        this.collapseTree();
      } else {
        this.expandTree();
      }
    },
    activeChanged(activeNodes) {
      const component = this;
      const selectedNode = activeNodes[0];

      let path = "";
      const pathSeparator = component?.value?.path_separator;
      if (pathSeparator) {
        path = selectedNode.path.join(pathSeparator);
      }
      let actions = [
        {
          value: {
            type: "event",
            name: CHOSEN_VALUE,
            params: {
              id: `${selectedNode.id}`,
              path: `${path}`,
            },
          },
        },
      ];
      if (Array.isArray(selectedNode.actions)) {
        actions = [...actions, ...selectedNode.actions];
      }
      this.triggerActions(actions);
    },
  },
};
</script>
