<template>
  <v-divider
    v-bind="displayAttributes"
    v-bind:class="displayClasses"
    :color="dividerColor"
  />
</template>
<script>
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.divider");
debug("atman.components.divider");
debug(`divider`);
export default {
  mixins: [fieldMixin],
  name: "Divider",
  mounted() {
    debug("mounted of Divider", this.value);
  },
  data() {
    const componentClasses = ["mt-6", "mb-6"];
    return {
      componentClasses,
    };
  },
  computed: {
    dividerColor() {
      return this.value?.display?.color || "grey";
    },
  },
};
</script>
