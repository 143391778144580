<template>
  <v-card
    aut-nav-bar
    :color="bgColorAppBar"
    class="px-0 d-flex align-center mb-4"
  >
    <div>
      <v-app-bar-nav-icon
        aut-menu
        v-if="displayMenuButton"
        @click.stop="toggleSidebar"
        class="mr-n2"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="menuButtonColor"
              v-bind="attrs"
              v-on="on"
              class="behavior_action_icon"
              >mdi-menu</v-icon
            >
          </template>
          Toggle Sidebar
        </v-tooltip>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon
        v-if="displayApplicationHome"
        @click.stop="goToApplicationHome"
        class="mr-n2"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="applicationHomeColor"
              class="behavior_action_icon"
              v-bind="attrs"
              v-on="on"
              >mdi-home</v-icon
            >
          </template>
          Application Home
        </v-tooltip>
      </v-app-bar-nav-icon>

      <v-app-bar-nav-icon v-if="displayBackButton" @click.stop="goBack">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="backButtonColor"
              class="behavior_action_icon"
              v-bind="attrs"
              v-on="on"
              >mdi-backburger</v-icon
            >
          </template>
          Back
        </v-tooltip>
      </v-app-bar-nav-icon>
    </div>

    <div v-if="!isSmallScreen && displayMenus" flat nav expand class="px-1">
      <MenuOptions :menus="menu" :color="menuTextColor" />
    </div>
  </v-card>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { createContext, goToApplicationHome } from "@/util";
import MenuOptions from "./MenuOptions.vue";

const debug = require("debug")("atman.app_bar"); // eslint-disable-line
export default {
  name: "Appbar",
  components: {
    MenuOptions,
  },
  data() {
    return {
      context: null,
    };
  },
  watch: {
    $route() {
      this.createContext();
      this.fetchAppbarMenu();
    },
  },
  computed: {
    ...mapState(["skin", "preferences"]),
    ...mapGetters(["isClassicLayout", "menu"]),
    displayAppBar() {
      return this.isFeatureEnabled("appbar.display");
    },
    displayMenus() {
      return this.isFeatureEnabled("appbar.menus_display");
    },
    bgColorAppBar() {
      return this.getFeatureValue("appbar.color") || "white";
    },
    menuTextColor() {
      return this.getFeatureValue("appbar.menu_text.color");
    },
    displayBackButton() {
      return (
        this.displayAppBar &&
        this.isFeatureEnabled("appbar.back_button.display")
      );
    },
    displayApplicationHome() {
      const display = this.isFeatureEnabled("appbar.application_home.display");
      if (!display) {
        return false;
      }
      const target = this.getFeatureValue(
        "appbar.application_home.target",
        "home"
      );
      if (target == "home") {
        return true;
      }
      return !!this.context?.application;
    },
    backButtonColor() {
      return (
        this.displayAppBar && this.getFeatureValue("appbar.back_button.color")
      );
    },
    applicationHomeColor() {
      return this.getFeatureValue("appbar.application_home.color");
    },
    containerClasses() {
      return "ma-0 pa-0 ";
    },
    displayMenuButton() {
      return this.isFeatureEnabled("appbar.menu_button.display");
    },
    menuButtonColor() {
      return this.getFeatureValue("appbar.menu_button.color");
    },
  },
  mounted: async function () {
    await this.fetchSkin();
    this.createContext();
    this.fetchAppbarMenu();
  },
  methods: {
    ...mapActions(["toggleSidebar", "fetchSkin", "fetchMenu"]),
    createContext() {
      const path = this.$route.fullPath;
      this.context = createContext(path);
    },
    goToApplicationHome() {
      const target = this.getFeatureValue(
        "appbar.application_home.target",
        "home"
      );
      if (target == "application_home") {
        goToApplicationHome();
      } else {
        this.$router.push({ path: "/" });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchAppbarMenu() {
      if (!this.displayMenus) {
        return;
      }
      await this.fetchMenu();
      debug(`Appbarmenu`, this.menu);
      this.$forceUpdate();
    },
  },
};
</script>
