<template>
  <v-card transparent flat>
    <v-card-text class="ma-0 pa-0">
      <v-row class="mt-0 pb-0" dense>
        <list-field
          label="Role"
          :list="filteredRoles"
          :value="selectedRole"
          max_height="140"
          @select="selectRole"
          :has_slot="true"
        >
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="dialog = true">
                Add a role
              </v-btn>
            </template>
            <v-card>
              <DialogTitle @close="dialog = false" title="Add a role" />
              <v-card-text>
                <v-form v-model="formModel" ref="form" class="pa-2">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="ID*"
                        required
                        clearable
                        :rules="idRules"
                        v-model="newRole.id"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        label="Label*"
                        :rules="labelRules"
                        required
                        clearable
                        v-model="newRole.label"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="addRole" :disabled="!formModel" aut-add-role
                  >Add</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </list-field>
        <v-col cols="12" md="9" class="pa-2">
          <v-row dense class="smaller mb-0 pb-0">
            <v-col class="pa-0">
              <v-text-field
                hide-details
                dense
                prepend-icon="mdi-magnify"
                aut-role-search
                placeholder="Search"
                clearable
                v-model="searchValue"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-container>
            <label v-if="!selectedRole">Choose a role on the left</label>
            <div v-else>
              <v-text-field
                dense
                aut-role-name
                placeholder="Role Name"
                clearable
                label="Label"
                v-model="selectedRoleObject.label"
              ></v-text-field>
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                  v-for="(permission, i) in rolePermissions(selectedRole)"
                  :key="i"
                >
                  <div class="d-flex">
                    <label>
                      <div class="text-primary">
                        {{ permission.label }}
                      </div>
                      <div class="behaviour_metadata">
                        ({{ permission.id }})
                      </div>
                    </label>
                    <v-switch
                      dense
                      :disabled="permission.disabled"
                      :aut-permission-switch="permission.id"
                      v-model="permission.value"
                      class="mt-0"
                      @change="updateRole(permission, $event)"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="selectedRole">
                <v-spacer />
                <v-btn color="primary" @click="updateRoleToServer"
                  >Update Role</v-btn
                >
                <v-btn class="ml-2" color="error" @click="deleteConfirmation"
                  >Delete Role</v-btn
                >
              </v-row>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { sortBy } from "lodash";
import { dialogMixin } from "@/components/mixin.js";
import { rules } from "@/rules";
import { confirmationMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.permission_roles_manager"); // eslint-disable-line

export default {
  name: "PermissionRolesManager",
  mixins: [confirmationMixin, dialogMixin],
  props: {
    context: {
      type: String,
    },
  },
  data() {
    const idRules = [
      rules.mandatory(),
      rules.regex({
        regex: /^[a-z_0-9]{4,}/,
        message:
          "A role needs to contain at least 4 alphanumeric characters and should be in lowercase",
      }),
      (val) =>
        !this.roles.includes((val || "").toLowerCase()) ||
        "This name has already been used",
    ];
    return {
      roles: [],
      labelRules: [rules.mandatory()],
      idRules,
      newRole: {},
      formModel: null,
      dialog: false,
      selectedRole: null,
      searchValue: null,
      allPermissions: [],
      selectedRoleObject: {},
    };
  },
  computed: {
    filteredRoles() {
      let result = [...this.roles];
      if (this.searchValue) {
        result = result.filter(({ id }) =>
          id.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      }
      return result;
    },
  },
  components: {},
  mounted() {
    this.fetchAllPermissions();
    this.fetchPermissionRoles();
  },
  methods: {
    deleteConfirmation() {
      const component = this;
      component.showConfirmation(
        "This is an irreversible action. Are you sure?",
        () => {
          component.deleteRole();
        }
      );
    },
    async deleteRole() {
      try {
        const params = { role: this.selectedRole };
        await this.$store.dispatch(`${this.context}/deleteRole`, params);
        this.displaySuccessMessage("Role Deleted successfully");
        let roles = [...this.roles];
        roles = roles.filter((role) => {
          return role.id != this.selectedRole;
        });
        this.selectedRole = null;
        this.$nextTick(() => {
          this.$set(this, "roles", roles);
        });
      } catch (e) {
        console.error(e);
        this.displayErrorMessage("Delete unsuccessful");
      }
    },
    selectRole(roleID) {
      this.selectedRole = roleID;
      this.selectedRoleObject = this.roles.find(({ id }) => id == roleID);
    },
    addRole() {
      const newRoleID = this.newRole.id;
      if (!newRoleID) {
        return;
      }
      const newRole = {
        id: newRoleID,
        name: this.newRole.label,
        label: this.newRole.label,
        _permissions: {},
      };
      (this.allPermissions || []).forEach((permission) => {
        newRole._permissions[permission.id] = "NO";
      });
      this.roles.push(newRole);
      this.selectedRoleObject = newRole;

      this.selectedRole = newRoleID;
      this.dialog = false;
      debug(`new Roles Object`, this.roles);
      this.updateRoleToServer();
    },
    async updateRoleToServer() {
      try {
        const params = { role: this.selectedRoleObject };
        await this.$store.dispatch(`${this.context}/updateRole`, params);
        this.displaySuccessMessage("Role Updated successfully");
      } catch (e) {
        console.error(e);
        this.displayErrorMessage("Update unsuccessful");
      }
    },
    updateRole(permission, updated) {
      debug(`In updateRole`, permission, updated);
      const roleObject = this.roles.find(({ id }) => id == this.selectedRole);
      roleObject._permissions[permission.id] = updated ? "YES" : "NO";
      this.selectedRoleObject = roleObject;
    },
    rolePermissions(selectedRole) {
      if (!selectedRole) {
        return [];
      }
      debug(`Selected Role`, selectedRole);

      const bypassRoles = (
        this.getFeatureValue("permissions.bypass_roles") || ""
      ).split(",");

      const selectedRolePermissions = this.roles.find(
        ({ id }) => id == selectedRole
      )._permissions;

      const permissions = this.allPermissions.map((masterPermission) => {
        const label = masterPermission?.name;
        const key = masterPermission.id;
        return {
          id: key,
          disabled: bypassRoles.includes(selectedRole),
          label,
          value:
            bypassRoles.includes(selectedRole) ||
            selectedRolePermissions[key] == "YES",
        };
      });
      debug(`permissions`, permissions);
      return sortBy(permissions, ["label"]);
    },
    performSearch() {},
    async fetchAllPermissions() {
      this.allPermissions = await this.$store.dispatch(`fetchAllPermissions`);
      debug(`all permissions`, this.allPermissions);
    },
    async fetchPermissionRoles() {
      this.roles = await this.$store.dispatch(
        `${this.context}/fetchPermissionRoles`
      );
    },
  },
};
</script>
