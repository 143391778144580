<template>
  <div>
    <v-textarea
      :aut-textarea="value.name"
      v-if="!isDisplayMode"
      :name="value.name"
      v-model="fieldValue"
      :label="label"
      :hint="value.hint"
      v-bind="displayAttributes"
      v-bind:class="displayClasses"
      :rules="applicableRules"
      :loading="loading"
    ></v-textarea>
    <div v-if="isDisplayMode">
      <FieldLabel
        aut-text-label
        :definition="value"
        mode="display"
        :label="label"
        :attributes="labelAttributes"
      >
        <FieldDisplayValue
          aut-text-value
          :value="fieldValue"
          :definition="value"
        />
      </FieldLabel>
    </div>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";

const debug = require("debug")("atman.components.textarea"); // eslint-disable-line 

export default {
  name: "TextArea",
  mixins: [fieldMixin],
  data() {
    return {
      definition,
    };
  },
};
</script>
