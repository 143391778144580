<template>
  <div class="mb-2">
    <v-menu
      v-if="!isDisplayMode && !isReadOnly && !isDisabled && propsData"
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on }">
        <InputText
          v-bind="propsData"
          :key="renderKey"
          :listners="on"
          @typed="updateDate"
        />
      </template>
      <v-date-picker
        v-model="date"
        :aut-date-picker="value.name"
        :range="value.range"
        @input="menu = false"
        :allowed-dates="allowedDates"
        v-bind="displayAttributes"
      ></v-date-picker>
    </v-menu>
    <v-text-field
      :value="formattedDate"
      :label="label"
      :key="refreshKey"
      :color="fieldColor"
      readonly
      :disabled="isDisabled"
      :hint="`${effectiveFormat}`"
      persistent-hint
      prepend-inner-icon="mdi-calendar"
      v-if="!isDisplayMode && (isReadOnly || isDisabled)"
    ></v-text-field>
    <FieldLabel
      v-if="isDisplayMode"
      :definition="value"
      mode="display"
      :label="label"
      :attributes="labelAttributes"
    >
      <ADate
        :key="refreshKey"
        :date="fieldValue"
        :class="displayClasses"
        :format="effectiveFormat"
      />
    </FieldLabel>
  </div>
</template>
<script>
import ADate from "./ADate.vue";
import { defaultsDeep } from "lodash";
import { clone, hasAVariable } from "@/util";
import { format, parseISO, parse, isMatch } from "date-fns";
import { fieldMixin } from "@/components/mixin.js";
import InputText from "@/components/fields/InputText/InputText";
import definition from "./definition";
const debug = require("debug")("atman.components.date"); // eslint-disable-line

export default {
  name: "DatePicker",
  data() {
    const result = {
      refreshKey: 1,
      menu: false,
      definition,
    };
    return result;
  },
  components: {
    InputText,
    ADate,
  },
  watch: {
    effectiveFormat() {
      this.refreshKey++;
      this.determineRule();
    },
  },
  mixins: [fieldMixin],
  computed: {
    fieldColor() {
      return this.displayAttributes?.field?.color || "";
    },
    effectiveFormat() {
      const result =
        this.displayAttributes?.format || this.value?.format || "yyyy-MM-dd";
      debug(`Effective format is: `, result);
      return result;
    },
    date: {
      get() {
        let value = "";
        if (!hasAVariable(this.fieldValue)) {
          value = this.fieldValue;
        }
        const dateValue = value ? new Date(value) : new Date();
        try {
          return format(parseISO(dateValue.toISOString()), "yyyy-MM-dd");
        } catch (e) {
          console.error(`Unable to parse date: [${value}]`, dateValue, e);
          return format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        }
      },
      set(date) {
        const dateValue = new Date(date).toISOString();
        debug(`in date setter`, dateValue);
        let effectiveValue = dateValue;
        if (this.displayAttributes?.store_date_only === true) {
          effectiveValue = format(parseISO(dateValue), "yyyy-MM-dd");
        }
        this.$set(this.value, "value", effectiveValue);
        this.$emit("change", effectiveValue);
      },
    },
    formattedDate() {
      try {
        if (
          this.fieldValue &&
          this.effectiveFormat &&
          this.effectiveFormat != "elapsed"
        ) {
          debug(`Attempting to format: [${this.fieldValue}]`);
          return format(parseISO(this.fieldValue), this.effectiveFormat);
        }
      } catch (e) {
        console.error(
          e,
          `attempting to format: [${this.fieldValue}] using format: [${this.effectiveFormat}]`
        );
      }
      return this.fieldValue;
    },
  },
  methods: {
    determineRule() {
      this.formatRule = (date) => {
        if (this.effectiveFormat == "elapsed") {
          return true;
        }
        return (
          !date ||
          isMatch(date, this.effectiveFormat) ||
          `Format: ${this.effectiveFormat}`
        );
      };
    },
    allowedDates(val) {
      const rules = this.applicableRules || [];
      let result = true;
      for (var ruleToApply of rules) {
        result = ruleToApply(val);
        if (result == true) {
          continue;
        }
        break;
      }
      return typeof result == "string" ? false : true;
    },
    _derivePropsData() {
      if (!this.isInputMode) {
        debug(`Aborting props data`);
        return;
      }
      const propsData = clone(this.$props);

      const fieldAttributes = this.displayAttributes?.field || {};

      const attributes = {
        "persistent-hint": true,
        "prepend-inner-icon": "mdi-calendar",
        ...fieldAttributes,
        "validate-on-blur": true,
      };

      propsData.value.display.attributes = defaultsDeep(
        {},
        attributes,
        propsData.value.display.attributes
      );

      // The name and path should not be that of the existing field or it will get updated
      propsData.value.name = `${propsData.value.name}_field`;
      propsData.value.path = `${propsData?.value?.path || "innerText"}_field`;
      // Remove any configured rules so that they don't again get executed
      delete propsData.value.conditions;

      propsData.value.value = this.formattedDate;
      propsData.value.hint = this.effectiveFormat;
      propsData.value.type = "text";
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
      propsData.value.rules = [
        ...(propsData.value.rules || []),
        this.formatRule,
      ];
      debug(this.propsData);
    },
    updateDate(date) {
      this.date = parse(date, this.effectiveFormat, new Date());
      this.menu = false;
    },
  },
  mounted() {
    debug("mounted of DatePicker", this.value);
    this.determineRule();
  },
};
</script>
