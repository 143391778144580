var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mode == 'input')?_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addComponent.apply(null, arguments)}}},[_c('v-list-item-title',{attrs:{"aut-cost-sub-title":""}},[_vm._v(" Add "+_vm._s(_vm.title)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"icon":"","aut-add-cost-component":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,2606827480)},[_vm._v(" Add ")])],1):_vm._e(),(_vm.results && _vm.results.length)?_c('ul',_vm._l((_vm.results),function(item,i){return _c('li',{key:("item-" + i),staticClass:"pt-2",attrs:{"aut-cost-component":""}},[(_vm.mode == 'input')?_c('v-card',{staticClass:"pa-2 pt-6",attrs:{"outlined":""}},[_c('v-row',{staticClass:"d-flex dense"},[_c('v-col',{attrs:{"lg":"5","cols":"12"}},[_c('v-text-field',{attrs:{"hint":_vm.displayDate(item.valid_until),"aut-cost-component-label":"","persistent-hint":"","required":"","label":(_vm.title + " Label")},on:{"blur":_vm.itemUpdated},scopedSlots:_vm._u([(_vm.cost_component == 'deductions')?{key:"append",fn:function(){return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"aut-valid-date":""}},'v-icon',attrs,false),Object.assign({}, menuOn, tooltipOn)),[_vm._v("mdi-calendar")])]}}],null,true)},[_vm._v(" Select date for time bound Deduction ")])]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"aut-cost-date-picker":"","value":_vm._f("date")(item.valid_until,{ today: true })},on:{"change":function($event){return _vm.updateDate(i, $event)}}})],1)]},proxy:true}:null],null,true),model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}})],1),_c('v-col',{attrs:{"lg":"4","cols":"12"}},[_c('Currency',{attrs:{"value":_vm.getFieldDefinition(item, i)},on:{"change":function($event){return _vm.itemValueUpdated(i, $event)}}})],1),_c('v-col',{attrs:{"lg":"3","cols":"12"}},[_c('v-autocomplete',{staticClass:"pt-3",attrs:{"aut-type":"","items":_vm.componentTypes,"item-text":"label","item-value":"type","menu-props":"offset-y","dense":"","value":item.type || 'custom',"label":"Type"},on:{"change":function($event){return _vm.itemTypeUpdated(i, $event)}}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1 item_action",attrs:{"aut-remove-cost-component":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem(i)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" Remove ")])],1)],1):_c('Currency',{attrs:{"value":_vm.getFieldDefinition(item, i)}})],1)}),0):(_vm.displayNA)?_c('span',[_vm._v(" NIL")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }