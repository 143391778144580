<template>
  <v-col cols="12">
    <p class="text-h6">{{ label }}</p>
    <p>Number of Pages: {{ noOfPages }} | Billable {{ billablePages }}</p>
    <draggable
      v-model="pages"
      class="d-flex flex-wrap"
      @end="handleOrderChange"
    >
      <BookPage
        v-for="(page, index) in pages"
        :key="`${index}-${page.url}`"
        v-model="pages[index]"
        :page_num="index + 1"
      />
    </draggable>
  </v-col>
</template>
<script>
import { pick } from "lodash";
import BookPage from "./BookPage.vue";
import draggable from "vuedraggable";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.page_manager");

const FIRST = 0;

const reducer = (accumulator, page) => {
  if (page.billable == "yes") {
    accumulator++;
  }
  return accumulator;
};
export default {
  name: "PageManager",
  components: {
    BookPage,
    draggable,
  },
  mixins: [fieldMixin],
  computed: {
    pages: {
      get() {
        return Array.isArray(this.fieldValue) ? this.fieldValue : [];
      },
      set(input) {
        this.fieldValue = input;
      },
    },
    noOfPages() {
      return this?.pages?.length || 0;
    },
    billablePages() {
      return this.pages && this.pages.reduce(reducer, 0);
    },
  },
  mounted() {
    debug("In mounted of PageManager", this.value);
  },
  methods: {
    handleOrderChange(evt) {
      const methodDebug = debug.extend("handleOrderChange");
      const list = this.pages;
      const item = list[evt.newIndex];
      methodDebug(
        "item moved was",
        item,
        `moved from: [${evt.oldIndex}] to [${evt.newIndex}]`
      );
      let api = this.value?.apis?.data;
      if (!api) {
        methodDebug(`no data api defined. Skipping order update`);
        return;
      }
      api = pick(api, ["url", "conditions"]);
      api.type = "post";
      api.url = `${api.url}/${item.id}`;
      api.params = { data: {} };
      let payload = { actionDefinition: { value: api } };
      if (evt.newIndex == FIRST) {
        methodDebug(`IN FIRST FLOW`);
        const itemAfter = list[evt.oldIndex];
        payload.actionDefinition.value.params.action = "move_before";
        payload.actionDefinition.value.params.node = itemAfter.id;
        methodDebug("item after is", itemAfter);
      } else {
        methodDebug(`IN OTHER INDEX FLOW`);
        const itemBefore = list[evt.oldIndex];
        payload.actionDefinition.value.params.action = "move_after";
        payload.actionDefinition.value.params.node = itemBefore.id;
        methodDebug("item before is", itemBefore);
      }
      methodDebug(`payload`, payload);
      return this.$store.dispatch(`${this.context}/triggerAction`, payload);
    },
  },
};
</script>
