<template>
  <v-container class="pa-0">
    <div class="d-flex justify-space-between">
      <label
        class="text-left"
        aut-label
        :class="labelClasses"
        @click="dialog = true"
      >
        <v-icon aut-icon> mdi-calendar </v-icon>
        {{ label }}
      </label>
      <v-spacer></v-spacer>
      <div class="text-right mt-n3">
        <v-btn icon @click="createEvent">
          <v-icon class="secondary--text">mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="text-right mt-n3">
        <v-btn icon @click="dialog = true">
          <v-icon class="secondary--text">mdi-arrow-expand-all</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog width="800" eager v-model="dialog">
      <v-card>
        <DialogTitle @close="dialog = false" />
        <CalendarView
          :events="events"
          :attributes="expandAttributes"
          :classes="displayClasses"
          :mode="mode"
          @event:show="showEvent"
          @event:create="createEvent"
        />
      </v-card>
    </v-dialog>
    <CalendarView
      v-if="isInline"
      :events="events"
      :attributes="displayAttributes"
      :classes="displayClasses"
      :mode="mode"
      @event:show="showEvent"
      @event:create="createEvent"
    />
    <EventDisplay
      :event="selectedEvent"
      :activator="selectedElement"
      v-model="selectedOpen"
      :mode="mode"
      @edit="editEvent"
      @remove="removeEvent"
    />
    <EventInput
      v-model="showEventInput"
      @update="updateEvents"
      :event="selectedEvent"
    />
  </v-container>
</template>
<script>
import { fieldMixin, dialogMixin } from "@/components/mixin.js";
import { makeServerCall, clone } from "@/util";
import { defaultsDeep } from "lodash";
import definition from "./definition";

const debug = require("debug")("atman.components.calendar"); // eslint-disable-line

const getUniqueID = () => {
  return new Date().getTime().toString();
};

export default {
  mixins: [fieldMixin, dialogMixin],
  name: "Calender",
  components: {
    CalendarView: () => import("./CalendarView.vue"),
    EventDisplay: () => import("./EventDisplay.vue"),
    EventInput: () => import("./EventInput.vue"),
  },
  watch: {
    $route() {
      this.handleRouteChange();
    },
  },
  data() {
    return {
      definition,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      showEventInput: false,
      events: [],
      dialog: false,
    };
  },
  computed: {
    labelClasses() {
      const isDark = this.isDark;
      let result = (this.displayAttributes?.label?.classes || []).join(" ");
      result += !isDark ? ` ${this.getFeatureValue("title.color")}--text ` : "";
      return result;
    },
    isInline() {
      return this.displayAttributes?.mode == "inline";
    },
    sourceUrl() {
      return this.displayAttributes?.source || "";
    },
    isExpandable() {
      return !!this.displayAttributes?.expand;
    },
    expandAttributes() {
      if (!this.isExpandable) {
        return this.displayAttributes;
      }

      return defaultsDeep(
        {},
        {
          type_control: true,
          day_view: true,
          expand: false,
          card: {
            classes: "pt-4",
            flat: false,
          },
        },
        this.displayAttributes
      );
    },
  },
  mounted() {
    this.setUpEvents();
  },
  methods: {
    handleRouteChange() {
      // Does not handle conditions
      if (this.sourceUrl.indexOf("_route") != -1) {
        this.setUpEvents();
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    createEvent(dateOptions) {
      let { date } = dateOptions || {};
      if (!date) {
        const formatDate = this.$options.filters.date;
        const today = formatDate(new Date());
        date = today;
      }
      const event = {
        name: "",
        id: getUniqueID(),
        start: new Date(`${date} 09:30`).toISOString(),
        end: new Date(`${date} 10:30`).toISOString(),
      };
      this.selectedEvent = event;
      this.showEventInput = true;
    },
    updateEvents(event) {
      this.showEventInput = false;
      let events = clone(this.events || []);

      if (!Array.isArray(events)) {
        events = [];
      }

      const eventIndex = events.findIndex(({ id }) => id == event?.id);

      if (eventIndex == -1) {
        events.push(event);
      } else {
        events.splice(eventIndex, 1, event);
      }

      if (!this.sourceUrl) {
        this.fieldValue = events;
      } else {
        this.events = events;
        this.saveEvent(event, event?.id);
      }
    },
    editEvent(event) {
      this.selectedOpen = false;
      this.selectedEvent = event;
      this.showEventInput = true;
    },
    async setUpEvents() {
      debug(`In setUpEvents`);
      if (!this.sourceUrl) {
        this.events = this.fieldValue || [];
        return;
      }

      const events = await this.makeRequest(this.sourceUrl, "get");

      if (!Array.isArray(events)) {
        this.events = [];
        return;
      }

      this.events = events;
    },
    async saveEvent(event, id) {
      if (!this.sourceUrl || !event) {
        return;
      }

      const params = {
        action: "update_fields",
        data: event,
      };

      let url = this.sourceUrl;
      if (id) {
        url = `${url}/${id}`;
      }

      await this.makeRequest(url, "post", params);
    },
    async removeEvent(event) {
      if (!event || !event?.id) {
        return;
      }

      const events = clone(this.events);

      const eventIndex = events.findIndex(({ id }) => id == event.id);

      if (eventIndex == -1) {
        return;
      }
      this.selectedOpen = false;
      events.splice(eventIndex, 1);

      if (!this.sourceUrl) {
        this.fieldValue = events;
        return;
      }

      this.events = events;

      const url = `${this.sourceUrl}/${event.id}`;

      await this.makeRequest(url, "delete");
    },
    async makeRequest(url, type, params = {}) {
      const component = this;
      let payload = {
        url,
        customFunctions: component.replaceIndex(),
      };

      let sourceUrl = "";
      try {
        sourceUrl =
          component.$store.getters[`${component.context}/dynamicText`](payload);
      } finally {
        payload = null;
      }
      if (!sourceUrl) {
        return;
      }

      let data = {};
      try {
        const response = await makeServerCall({
          url: sourceUrl,
          type,
          params,
        });
        data = response?.data;
      } catch (e) {
        debug(`Could not get source events`, e);
      }

      return data;
    },
    _afterFieldValueUpdate() {
      if (Array.isArray(this.fieldValue)) {
        this.events = this.fieldValue;
      }
    },
  },
};
</script>
