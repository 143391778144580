import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
export default {
  label: "Rating",
  type: "rating",
  description: "Displays the rating field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    {
      key: "length",
      description: "Controls the length of the rating field",
      affects_display: "yes",
    },
  ],
  templates: [
    {
      id: "default",
      description: "Rating field in Input Mode.",
      label: "Input Mode",
      variables: {
        data: {
          default: null,
        },
      },
      value: {
        mode: "input",
        name: "default",
        label: "Rating",
        type: "rating",
      },
    },
    {
      id: "display_mode",
      description: "Rating field in Display Mode.",
      label: "Display Mode",
      variables: {
        data: {
          display_mode: 4,
        },
      },
      value: {
        mode: "display",
        name: "display_mode",
        label: "Rating",
        type: "rating",
      },
    },
    {
      id: "display_mode_numerical",
      description: "Rating field in Display Mode (minimal)",
      label: "Numerical Display Mode",
      variables: {
        data: {
          display_mode_numerical: 4,
        },
      },
      value: {
        display: {
          attributes: {
            mode: "numerical",
          },
        },
        mode: "display",
        name: "display_mode_numerical",
        label: "Rating",
        type: "rating",
      },
    },

    {
      id: "length",
      description: "Add a length attribute to tweak the number of stars",
      label: "Customised # of stars",
      variables: {
        data: {
          length: null,
        },
      },
      value: {
        display: {
          attributes: {
            length: 8,
          },
        },
        mode: "input",
        name: "length",
        label: "Rating",
        type: "rating",
      },
    },
  ],
  attributes: [
    no_value_text,
    no_value_behavior,
    {
      name: "mode",
      type: "select",
      hint: "choose the mode of display",
      options: [
        {
          id: "stars",
          label: "Regular (Default)",
        },
        {
          id: "numerical",
          label: "Numerical",
        },
      ],
    },
    {
      name: "length",
      hint: "choose the total (Default: 5)",
      type: "number",
      value: 5,
    },
  ],
};
