import colors from "vuetify/lib/util/colors";
import { startCase } from "lodash";

let COLORS = [
  {
    id: "primary",
    label: "Primary",
  },
  {
    id: "secondary",
    label: "Secondary",
  },
  {
    id: "success",
    label: "Success",
  },
  {
    id: "error",
    label: "Error",
  },
  {
    id: "info",
    label: "Info",
  },
  {
    id: "warning",
    label: "Warning",
  },
  {
    id: "accent",
    label: "Accent",
  },
];

COLORS = [
  ...COLORS,
  { id: "transparent", label: "transparent" },
  ...Object.keys(colors).map((item) => {
    return {
      id: item,
      label: `${startCase(item)}`,
    };
  }),
];

let ELEVATIONS = [];
for (let i = 0; i < 25; i++) {
  ELEVATIONS.push({
    id: `elevation-${i}`,
    label: `Elevation-${i}`,
  });
}

const TEXT_COLORS = COLORS.map((item) => {
  return {
    id: `${item.id}--text`,
    label: `${startCase(item.id)} Text`,
  };
});

const FLOATS = [
  { id: "float-left", label: "Float Left" },
  { id: "float-right", label: "Float Right" },
  { id: "float-none", label: "No Float" },
];

const TEXT_CASES = [
  { id: "text--lowercase", label: "Lowercase" },
  { id: "text--uppercase", label: "Uppercase" },
  { id: "text--capitalize", label: "Capitalize" },
];

const TEXT_OPACITY = [
  { id: "text--primary", label: "Primary opacity" },
  { id: "text--secondary", label: "Secondary opacity" },
  { id: "text--disabled", label: "Disabled opacity" },
  { id: "behaviour_metadata", label: "Metadata like opacity" },
];

const TEXT_WEIGHTS = [
  { id: "font-weight-thin", label: "Thin Text" },
  { id: "font-weight-light", label: "Light weight text" },
  { id: "font-weight-regular", label: "Normal weight text" },
  { id: "font-weight-medium", label: "Medium weight text" },
  { id: "font-weight-black", label: "Black text" },
  { id: "font-weight-bold", label: "Bold text" },
  { id: "font-italic", label: "Italic text" },
];

const TEXT_DECORATIONS = [
  { id: "text-decoration-none", label: "No decoration" },
  { id: "text-decoration-overline", label: "Overline" },
  { id: "text-decoration-underline", label: "Underline" },
  { id: "text-decoration-line-through", label: "Line through" },
];

const JUSTIFICATIONS = [
  { id: "justify-start", label: "justify-start" },
  { id: "justify-end", label: "justify-end" },
  { id: "justify-center", label: "justify-center" },
  { id: "justify-space-between", label: "justify-space-between" },
  { id: "justify-space-around", label: "justify-space-around" },
];

const BORDER_RADIUS = [
  { id: "rounded-0", label: "rounded-0 (none)" },
  { id: "rounded-sm", label: "rounded-sm (small)" },
  { id: "rounded", label: "rounded (regular)" },
  { id: "rounded-lg", label: "rounded-lg (large)" },
  { id: "rounded-xl", label: "rounded-xl (extra large)" },
  { id: "rounded-pill", label: "rounded-pill (pill)" },
  { id: "rounded-circle", label: "rounded-circle (circle)" },
];

const TEXT_SIZES = [
  ...(() => {
    let result = [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle-1",
      "subtitle-2",
      "body-1",
      "body-2",
      "button",
      "caption",
      "overline",
    ];

    return result.map((item) => ({
      id: `text-${item}`,
      label: `${item[0] == "h1" ? "Heading" : ""} ${item}`,
    }));
  })(),
  { id: "behavior_blurb_text", label: "Blurb" },
];

const TEXT_WRAP = [
  { id: "text-no-wrap", label: "Prevent Wrapping of text" },
  { id: "behavior_single_line", label: "Prevent Wrapping of text (Alt)" },
  { id: "text-truncate", label: "Truncate Text" },
];

const TEXT_ALIGNMENT = [
  { id: "text-center", label: "Center" },
  { id: "text-left", label: "Left" },
  { id: "text-right", label: "Right" },
  { id: "text-start", label: "Start" },
  { id: "text-end", label: "End" },
];

const ALIGNMENT_CLASSES = [
  {
    id: "behaviour_centered",
    label: "Center align",
  },
  {
    id: "behaviour_block_centered-30",
    label: "Centered with 30% padding on either side",
  },
  {
    id: "behaviour_block_centered-15",
    label: "Centered with 15% padding on either side",
  },
  {
    id: "behaviour_block_centered-10",
    label: "Centered with 10% padding on either side",
  },
];

const CATEGORIES = [
  {
    id: "BORDER_RADIUS",
    label: "Border radius",
    category: BORDER_RADIUS,
    tooltip: `<p>Controls the border radius. <a target="_blank" href="https://vuetifyjs.com/en/styles/border-radius">Reference</a>`,
  },
  {
    id: "ELEVATIONS",
    label: "Elevation",
    category: ELEVATIONS,
    tooltip: `<p>Controls the elevation. <a target="_blank" href="https://vuetifyjs.com/en/styles/elevation/">Reference</a>`,
  },
  {
    id: "JUSTIFICATIONS",
    label: "Justifications",
    category: JUSTIFICATIONS,
    tooltip: `<p>Controls the justification. <a target="_blank" href="https://vuetifyjs.com/en/styles/flex/#flex-order">Reference</a>`,
  },
  {
    id: "TEXT_WEIGHTS",
    label: "Text weight",
    category: TEXT_WEIGHTS,
    tooltip: `<p>Controls the font emphasis. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#font-emphasis">Reference</a>`,
  },
  {
    id: "TEXT_SIZES",
    label: "Text size",
    category: TEXT_SIZES,
    tooltip: `<p>Controls the text size. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#typography">Reference</a>`,
  },
  {
    id: "TEXT_COLORS",
    label: "Text color",
    category: TEXT_COLORS,
    tooltip: `<p>Controls the text colors. <a target="_blank" href="https://vuetifyjs.com/en/styles/colors/">Reference</a>`,
  },
  {
    id: "TEXT_DECORATIONS",
    label: "Text decorations",
    category: TEXT_DECORATIONS,
    tooltip: `<p>Controls the text decoration. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#decoration">Reference</a>`,
  },
  {
    id: "TEXT_OPACITY",
    label: "Text opacity",
    category: TEXT_OPACITY,
    tooltip: `<p>Controls the text opacity. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#opacity">Reference</a>`,
  },
  {
    id: "TEXT_CASES",
    label: "Text case",
    category: TEXT_CASES,
    tooltip: `<p>Controls the text case. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#transform">Reference</a>`,
  },
  {
    id: "COLORS",
    label: "Background Colors",
    category: COLORS,
    tooltip: `<p>Controls the colors. <a target="_blank" href="https://vuetifyjs.com/en/styles/colors/">Reference</a>`,
  },
  {
    id: "TEXT_WRAP",
    label: "Text wrap",
    category: TEXT_WRAP,
    tooltip: `<p>Controls text wrapping. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#wrapping-and-overflow">Reference</a>`,
  },
  {
    id: "TEXT_ALIGNMENT",
    label: "Text alignment",
    category: TEXT_ALIGNMENT,
    tooltip: `<p>Controls the text alignment. <a target="_blank" href="https://vuetifyjs.com/en/styles/text-and-typography/#alignment">Reference</a>`,
  },
  {
    id: "ALIGNMENT_CLASSES",
    label: "Alignment",
    category: ALIGNMENT_CLASSES,
    tooltip: `<p>Controls the alignment</p>`,
  },
  {
    id: "FLOATS",
    label: "Float",
    category: FLOATS,
    tooltip: `<p>Controls the float. <a target="_blank" href="https://vuetifyjs.com/en/styles/float/">Reference</a>`,
  },
];

let CLASSES = [
  ...TEXT_COLORS,
  ...TEXT_SIZES,
  ...TEXT_WEIGHTS,
  ...TEXT_DECORATIONS,
  ...TEXT_OPACITY,
  ...TEXT_CASES,
  ...TEXT_WRAP,
  ...TEXT_ALIGNMENT,
  ...COLORS,
  ...FLOATS,
  ...ALIGNMENT_CLASSES,
  ...JUSTIFICATIONS,
  ...BORDER_RADIUS,
  ...ELEVATIONS,
  {
    id: "behavior_block",
    label: "Behave as Block",
  },

  {
    id: "behavior_atman_button",
    label: "Button styling",
    hint: "Items with this class will not be printed",
  },
  {
    id: "behavior_dont_include_in_email",
    label: "Hide In Email",
    hint: "Items with this class will not be included in emails",
  },
];

const removeIncompatibleClasses = (classesInDefinition, classBeingAdded) => {
  let output = [...classesInDefinition];
  CATEGORIES.forEach(({ category }) => {
    const classesInCategory = category.map(({ id }) => id);
    // IF a class related to text size is being added, remove the ones already added
    if (classesInCategory.includes(classBeingAdded)) {
      output = output.filter((id) => !classesInCategory.includes(id));
    }
  });
  return output;
};

export {
  JUSTIFICATIONS,
  FLOATS,
  ALIGNMENT_CLASSES,
  TEXT_ALIGNMENT,
  TEXT_WRAP,
  COLORS,
  TEXT_CASES,
  TEXT_OPACITY,
  TEXT_COLORS,
  TEXT_DECORATIONS,
  TEXT_SIZES,
  TEXT_WEIGHTS,
  CLASSES,
  CATEGORIES,
  removeIncompatibleClasses,
};
