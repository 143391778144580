import { values } from "lodash";
import money from "@/money";
import { clone } from "@/util";
import { compareDesc, isBefore } from "date-fns";
const debug = require("debug")("atman.components.fields.cost"); // eslint-disable-line

const noAmount = { amount: 0, currency: "INR" };

const isPercentValue = (value) => {
  if (typeof value == "number") {
    return false;
  }

  return value.indexOf("%") == value.length - 1;
};

const resolvePercentCost = (percentCost, cost) => {
  const value = percentCost?.amount || 0;
  if (!cost || !isPercentValue(value)) {
    return percentCost;
  }

  const costAmount = cost?.amount || 0;
  const percent = value.substring(0, value.length - 1) * 1;
  const resultAmount = (percent / 100) * costAmount;

  debug("resolve percent cost", { costAmount, percent, resultAmount });
  return {
    amount: resultAmount,
    currency: percentCost.currency,
  };
};

const isValid = (costComponent) => {
  if (!costComponent?.value) {
    return false;
  }
  let validUntil = costComponent.valid_until;
  if (validUntil) {
    const hasElapsed = isBefore(new Date(validUntil), new Date());
    debug(`hasElapsed`, new Date(), new Date(validUntil), hasElapsed);
    if (hasElapsed) {
      debug(`Time has elapsed`);
      return false;
    }
  }
  return true;
};

const getTotalValue = (fieldValue, options = {}) => {
  debug(`getTotalValue invoked with`, fieldValue);
  const { ignoreAdditions = false, ignoreDeductions = false } = options || {};
  let amount = clone(noAmount);
  if (!fieldValue) {
    amount = clone(noAmount);
  } else if (fieldValue.amount) {
    amount = fieldValue;
  } else {
    // IF not, calculate the total
    amount = money.add(amount, fieldValue?.unit_price?.value || noAmount);
    const originalAmount = amount;
    if (!ignoreDeductions) {
      values(fieldValue?.deductions || []).forEach((deduction) => {
        debug(`Deductions`, deduction.value);
        if (isValid(deduction)) {
          const deductionValue = resolvePercentCost(
            deduction.value,
            originalAmount
          );
          debug("Resolving percent deduction", { deduction, deductionValue });
          amount = money.subtract(amount, deductionValue);
        }
      });
    }
    if (!ignoreAdditions) {
      values(fieldValue?.additions || []).forEach((addition) => {
        debug(`Additions`, addition);
        if (isValid(addition)) {
          const addedValue = resolvePercentCost(addition.value, originalAmount);
          debug("Resolving percent addition", { addition, addedValue });
          amount = money.add(amount, addedValue);
        }
      });
    }
  }
  return amount;
};

const getValidUntil = (fieldValue) => {
  if (!fieldValue) {
    return false;
  }
  const deductions = fieldValue.deductions || [];
  let allDates = deductions
    .filter((deduction) => deduction.valid_until)
    .map((deduction) => deduction.valid_until);
  if (!allDates) {
    return false;
  }
  if (allDates.length > 1) {
    allDates = allDates.sort(function (date1, date2) {
      return compareDesc(new Date(date1), new Date(date2));
    });
  }

  return allDates[0];
};

export { getTotalValue, getValidUntil };
