<template>
  <v-card>
    <v-card-title> Add a new Application </v-card-title>
    <v-card-text>Ut voluptate est ut sit.</v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "AWStep2",
  methods: {},
};
</script>
