<template>
  <div class="relative">
    <v-tabs v-model="tabModel">
      <v-tab aut-tab-default>Generic</v-tab>
      <v-tab aut-tab-field-specific v-if="designComponent"
        >Field Specific</v-tab
      >
      <v-tab aut-tab-look>Look and Feel</v-tab>
      <v-tab aut-tab-permissions v-if="nonEditorPermissionsAllowed"
        >Permissions</v-tab
      >
      <v-tab aut-tab-data-api>Data API</v-tab>
      <v-tab aut-tab-submit-api>Submit API</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabModel">
      <v-tab-item>
        <v-container>
          <v-row dense>
            <v-col cols="12" v-if="displayLabel">
              <v-text-field
                aut-edit-field-label
                dense
                name="label"
                label="Label"
                v-model="fieldLabel"
                clearable
                hint="Choose the label displayed to the user"
                persistent-hint
                required
                @change="updateDefinition"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="toggleLabelDisplay"
                        aut-display-label
                      >
                        {{ displayOptions.icon }}
                      </v-icon>
                    </template>
                    {{ displayOptions.text }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                aut-column-field-type
                dense
                small
                required
                :rules="applicableRules"
                :items="fieldTypes"
                label="Type"
                item-value="id"
                item-text="label"
                hint="Choose the type of the field"
                persistent-hint
                v-model="chosenFieldType"
                @change="changeFieldType"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                aut-edit-field-name
                dense
                name="name"
                label="Name"
                v-model="fieldDefinition.name"
                clearable
                hint="Choose the internal name of the field"
                persistent-hint
                required
                @change="updateDefinition"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="hasProperty('value')" class="d-flex">
              <v-text-field
                v-if="
                  !fieldDefinition.value ||
                  typeof fieldDefinition.value == 'string'
                "
                aut-edit-field-value
                dense
                name="value"
                label="Value"
                v-model="fieldDefinition.value"
                clearable
                hint="Add default value"
                persistent-hint
                required
                @change="updateDefinition"
              ></v-text-field>
              <ConditionsEditor
                name="conditions"
                v-model="fieldDefinition"
                @update="updateDefinition($event)"
              />
            </v-col>
            <v-col cols="12" v-if="hasProperty('block')">
              <v-switch
                dense
                aut-edit-field-block
                name="block"
                v-model="blockField"
                label="Block Display"
                persistent-hint
                hint="Field takes up the whole width"
              ></v-switch>
            </v-col>
            <v-col cols="12" v-if="hasProperty('mode')">
              <ModeDesigner
                :key="keyIncrement"
                :definition="getProperty('mode')"
                :mode.sync="fieldDefinition.mode"
                @update:mode="updateDefinition"
              />
            </v-col>
            <v-col cols="12" v-if="hasProperty('disabled')" class="d-flex">
              <v-switch
                dense
                aut-edit-field-disabled
                name="disabled"
                :disabled="disabledIsConditional"
                v-model="disabled"
                label="Disabled"
                persistent-hint
                hint="Field is disabled"
              ></v-switch>
              <ConditionsEditor
                name="disabled"
                v-model="fieldDefinition.disabled"
                @update="updateDisabled($event)"
                :options="getProperty('disabled').values"
                :key="refreshKey"
              />
            </v-col>
            <v-col cols="12" v-if="showMandatory">
              <v-switch
                dense
                aut-edit-field-mandatory
                name="mandatory"
                v-model="fieldDefinition.mandatory"
                label="Mandatory"
                persistent-hint
                hint="Choose if the field is mandatory or not"
                @change="updateDefinition"
              ></v-switch>
            </v-col>
            <v-col
              cols="12"
              v-if="hasProperty('display') && container != 'list'"
            >
              <v-radio-group
                v-model="fieldWidth"
                label="Width"
                dense
                row
                persistent-hint
                hint="Choose how much width the field takes up"
                @change="updateDefinition"
              >
                <v-radio
                  aut-edit-field-width="option.value"
                  v-for="(option, i) in widths"
                  :key="i"
                  :label="option.label"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-switch
                label="Is Dynamic"
                v-model="fieldDefinition.is_dynamic"
                hint="Set to true if this field needs to re-render itself based on any conditions"
                persistent-hint
                dense
                @change="updateDefinition"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item v-if="designComponent">
        <component
          class="mt-2"
          v-bind:is="designComponent"
          :definition.sync="fieldDefinition"
          @update:definition="updateDefinition"
        >
        </component>
      </v-tab-item>
      <v-tab-item>
        <FieldSettings
          v-if="activeField"
          :field="activeField"
          :definition.sync="fieldDefinition"
          @update:definition="updateDefinition"
        />
      </v-tab-item>
      <v-tab-item v-if="nonEditorPermissionsAllowed">
        <PermissionNode
          :permissions.sync="fieldDefinition._permissions"
          @update:permissions="updateDefinition"
        />
      </v-tab-item>
      <v-tab-item>
        <APIDesigner
          :definition.sync="apiData"
          type="data"
          @update:definition="updateDefinition"
          @remove_api="removeData()"
        />
      </v-tab-item>
      <v-tab-item>
        <APIDesigner
          :definition.sync="apiSubmit"
          type="submit"
          @update:definition="updateDefinition"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { widths, clone, rules } from "@/util.js";
import { defaultsDeep, isPlainObject } from "lodash";
import {
  getFieldDefinition,
  getFieldDefinitions,
  FIELDS_FILTER_LIST,
  FIELDS_FILTER_FORM,
} from "@/components/fields/util.js";
import ConditionsEditor from "@/components/fields/Field/FieldSettings/ConditionsEditor/ConditionsEditor.vue";
import ModeDesigner from "./ModeDesigner.vue";
import FieldSettings from "@/components/fields/Field/FieldSettings/FieldSettings.vue";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";

const debug = require("debug")("atman.components.field_designer");
debug("field_designer");

const hideOptions = {
  hidden: {
    icon: "mdi-eye-off",
    text: "Unhide Label",
  },
  unhidden: {
    icon: "mdi-eye",
    text: "Hide Label",
  },
};

export default {
  name: "FieldDesigner",
  components: {
    ConditionsEditor,
    ModeDesigner,
    FieldSettings,
    APIDesigner,
    PermissionNode: () => import("@/components/editor/PermissionNode"),
  },
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
    container: {
      type: String,
    },
  },
  data() {
    let defaultDisplay =
      this.container == "list" ? { display: {} } : { display: {} };

    const activeField = getFieldDefinition(this.definition.type);
    return {
      refreshKey: 1,
      fieldDefinition: defaultsDeep({}, this.definition, defaultDisplay),
      tabModel: null,
      fieldTypes: [],
      keyIncrement: 1,
      designComponent: null,
      display: true,
      properties: [],
      activeField,
      chosenFieldType: this.definition.type || "label",
      applicableRules: [rules.mandatory()],
    };
  },
  computed: {
    nonEditorPermissionsAllowed() {
      return this.isFeatureEnabled("permissions.non_editor.enabled");
    },
    disabled: {
      get() {
        let result = this.fieldDefinition.disabled;
        return typeof result == "boolean" ? result : false;
      },
      set(val) {
        this.fieldDefinition.disabled = val;
      },
    },
    disabledIsConditional() {
      return Array.isArray(this.fieldDefinition?.disabled?.conditions);
    },
    apiData: {
      get() {
        return this.fieldDefinition?.apis?.data;
      },
      set(definition) {
        definition.type = "get";
        this.fieldDefinition = Object.assign({}, this.fieldDefinition, {
          apis: {
            data: definition,
          },
        });
        debug("Setter API Data", this.fieldDefinition);
      },
    },
    apiSubmit: {
      get() {
        return this.fieldDefinition?.apis?.submit;
      },
      set(definition) {
        this.fieldDefinition = Object.assign({}, this.fieldDefinition, {
          apis: {
            submit: definition,
          },
        });
        debug("Setter API Submit", this.fieldDefinition);
      },
    },
    isAListField() {
      return this.container == "list";
    },
    showMandatory() {
      return (
        this.fieldDefinition.mode == "input" && this.hasProperty("mandatory")
      );
    },
    displayLabel() {
      return (
        !this.isAListField &&
        this.hasProperty("label") &&
        this.fieldDefinition.type != "action"
      );
    },
    fieldWidth: {
      get() {
        const width = this.fieldDefinition?.display?.width || "auto";
        return width == "auto"
          ? this.getFeatureValue("form.default_field_width")
          : width;
      },
      set(width) {
        this.fieldDefinition.display = this.fieldDefinition.display || {};
        this.fieldDefinition.display.width = width;
      },
    },
    blockField: {
      get() {
        const block = this.fieldDefinition?.display?.block;
        if (typeof block != "boolean") {
          return false;
        }

        return block;
      },
      set(block) {
        const display = {
          block,
        };
        this.fieldDefinition = defaultsDeep(
          {},
          {
            display,
          },
          this.fieldDefinition
        );
        this.updateDefinition();
      },
    },
    fieldLabel: {
      get() {
        const label = this.fieldDefinition.label;
        if (!isPlainObject(label)) {
          return label;
        }

        return this.fieldDefinition.label.text;
      },
      set(newLabel) {
        const label = this.fieldDefinition.label;
        if (!isPlainObject(label)) {
          this.fieldDefinition.label = newLabel;
        } else {
          this.fieldDefinition.label = defaultsDeep(
            {},
            { text: newLabel },
            label
          );
        }
      },
    },
    displayOptions() {
      const display = this.fieldDefinition.label?.display;
      if (typeof display != "boolean") {
        return hideOptions.unhidden;
      }

      return display ? hideOptions.unhidden : hideOptions.hidden;
    },
  },
  created() {
    this.widths = widths;
  },
  watch: {
    "fieldDefinition.type"() {
      this.getDesignComponent();
      this.getFieldProperties();
    },
  },
  mounted() {
    debug(`In mounted of FieldDesigner`, this.fieldDefinition);

    this.getDesignComponent();
    this.getFieldProperties();
    this.fieldTypes = getFieldDefinitions(
      this.isAListField ? FIELDS_FILTER_LIST : FIELDS_FILTER_FORM
    );
  },
  methods: {
    updateDisabled(disabledProperty) {
      this.fieldDefinition.disabled = disabledProperty;
      debug(`in updateDisabled`, disabledProperty, this.fieldDefinition);
      this.refreshKey++;
    },
    async changeFieldType() {
      const component = this;
      let definition = clone(component.fieldDefinition);
      const fieldType = component.chosenFieldType;
      debug(`changeFieldType`, component.chosenFieldType);

      const fieldDefinition = this.fieldTypes.find((field) => {
        return field.id == fieldType;
      });
      debug(`fieldDefinition`, fieldDefinition);
      let defaultTemplate = fieldDefinition.templates.find(
        ({ id }) => id == "default"
      );
      if (!defaultTemplate?.value) {
        console.error(`No default template found for type: ${fieldType}`);
        defaultTemplate = { value: {} };
      }
      definition = clone(
        defaultsDeep(
          {
            type: fieldType,
          },
          definition,
          defaultTemplate.value
        )
      );
      this.fieldDefinition = definition;
      this.$emit("update:definition", this.fieldDefinition);
    },
    async getDesignComponent() {
      const component = this;
      component.designComponent = await component.$store.dispatch(
        `${component.context}/deriveDesignComponent`,
        component.fieldDefinition
      );
    },

    async getFieldProperties() {
      const component = this;
      const properties = await component.$store.dispatch(
        `${component.context}/getFieldProperties`,
        component.fieldDefinition
      );

      component.properties = properties;
    },

    getProperty(propertyKey) {
      debug(`all properties`, this.properties);
      return this.properties.find(({ key }) => key == propertyKey);
    },

    hasProperty(key) {
      debug("in hasProperty", key);
      const propertyKeys = this.properties.map(({ key }) => key);
      return propertyKeys.includes(key);
    },

    updateDefinition(val) {
      if (isPlainObject(val)) {
        this.fieldDefinition = defaultsDeep({}, val, this.fieldDefinition);
      }
      this.$emit("update:definition", this.fieldDefinition);
      debug("UpdateDefinition", this.fieldDefinition);
    },

    toggleLabelDisplay() {
      const label = this.fieldDefinition.label;
      if (typeof label?.display == "boolean") {
        this.fieldDefinition.label.display = !label.display;
      } else if (!isPlainObject(label)) {
        this.fieldDefinition.label = {
          text: label,
          display: false,
        };
      } else {
        this.fieldDefinition.label = defaultsDeep(
          {},
          { display: false },
          label
        );
      }

      this.updateDefinition();
    },
  },
};
</script>
