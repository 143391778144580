export default {
  type: "form",
  title: "{_data->name} Home Page",
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
    },
  },
  definition: {
    fields: [
      {
        name: "description",

        mode: "display",
        type: "rich_text_editor",
      },
    ],
    actions: [],
  },
};
