import { types } from "@/components/fields";
import { getFeatureValue } from "@/feature_flags";
import { clone } from "@/util.js";

const FIELDS_FILTER_FORM = "form_editor";
const FIELDS_FILTER_LIST = "list_editor";
const FIELDS_FILTER_TUTORIAL = "field_tutorial";

const getWidth = function (field) {
  if (window?.vue?.$vuetify?.breakpoint?.smAndDown) {
    return 12;
  }
  const width = field?.display?.width;
  const result =
    width == "full"
      ? 12
      : isNaN(width * 1)
      ? getFeatureValue("form.default_field_width") * 1
      : width * 1;
  return result;
};

const getComponentWidth = function (field) {
  if (window?.vue?.$vuetify?.breakpoint?.smAndDown) {
    return 12;
  }
  const width = field?.display?.width;
  const result = width == "full" ? 12 : isNaN(width * 1) ? 12 : width * 1;
  return result;
};

const getFieldDefinitions = (filterKey) => {
  let results = [];
  Object.keys(types).forEach((type) => {
    const fieldDefinition = clone(types[type]);
    results.push(
      Object.assign(
        {
          id: type,
        },
        fieldDefinition
      )
    );
  });
  if (filterKey) {
    results = results.filter((typeDefinition) => {
      return (typeDefinition.filters || []).indexOf(filterKey) == -1;
    });
  }
  return results;
};

const getFieldDefinition = (fieldType) => {
  return getFieldDefinitions().filter(({ type }) => type == fieldType)[0];
};

export {
  getWidth,
  getComponentWidth,
  getFieldDefinitions,
  getFieldDefinition,
  FIELDS_FILTER_FORM,
  FIELDS_FILTER_LIST,
  FIELDS_FILTER_TUTORIAL,
};
