export default {
  type: "form",
  apis: {
    data: {
      type: "get",
      url: "{_context->path}",
    },
    submit: {
      url: "{_context->path}",
      type: "post",
      params: {
        action: "update_fields",
      },
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Update Successful",
      },
    },
  },
  definition: {
    actions: [],
    fields: [
      {
        display: {
          width: "6",
        },
        label: "Name",
        mandatory: true,
        mode: "input",
        name: "name",
        type: "text",
      },
      {
        display: {
          attributes: {},
          classes: [],
          width: "6",
        },
        label: "Start Date",
        mandatory: false,
        mode: "input",
        name: "start_date",
        type: "date",
      },
      {
        display: {
          width: "6",
        },
        label: "Funding Agency",
        mandatory: true,
        mode: "input",
        name: "funding_agency",
        type: "text",
      },

      {
        display: {
          width: "6",
        },
        label: "End Date",
        mode: "input",
        name: "end_date",
        type: "date",
      },
      {
        display: {
          width: "6",
        },
        label: "Remarks",
        mode: "input",
        name: "remarks",
        type: "text",
      },
      {
        display: {
          width: "6",
        },
        label: "Status",
        mode: "input",
        name: "status",
        options: {
          value: [
            {
              id: "active",
              name: "Active",
            },
            {
              id: "inactive",
              name: "Inactive",
            },
          ],
        },
        type: "select",
      },
    ],
  },
};
