const debug = require("debug")("atman.components.appointment_scheduler");
debug("appointment_scheduler");
export default {
  label: "Appointment Scheduler",
  type: "appointment_scheduler",
  description: "Displays a an appointment scheduler",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
  ],

  templates: [
    {
      id: "default",
      description: "The default Appointment Scheduler field.",
      label: "Default",
      value: {
        name: "some_name",
        label: "Title",
        type: "appointment_scheduler",
        value: {
          date: "",
          start_time: "2:00",
          end_time: "4:00",
          name: "Some name",
          description:
            "Consectetur laboris labore nulla amet dolor ad nulla qui velit in elit sint est quis.",
        },
        options: {
          value: [
            {
              start_time: "12:00",
              end_time: "1:00",
            },
            {
              start_time: "2:00",
              end_time: "4:00",
            },
            {
              start_time: "4:00",
              end_time: "6:00",
            },

            {
              start_time: "6:00",
              end_time: "8:00",
            },
          ],
        },
        display: {
          width: "6",
        },
      },
    },
    {
      id: "dynamic_api",
      description:
        "Use <code>start_time</code> and <code>end_time</code> values to display time slots by preference.",
      label: "Dynamic API",
      value: {
        name: "some_name",
        label: "Title",
        type: "appointment_scheduler",
        value: {
          date: "",
          start_time: "2:00",
          end_time: "4:00",
          name: "Some name",
          description:
            "Consectetur laboris labore nulla amet dolor ad nulla qui velit in elit sint est quis.",
        },
        options: {
          apis: {
            data: {
              url: "/developer/time_slots",
              type: "get",
            },
          },
        },
        display: {
          width: "6",
        },
      },
    },
  ],
};
