<template>
  <div>
    <v-snackbar
      v-for="(message, i) in messages"
      :key="i"
      app
      top
      tile
      :timeout="4000"
      v-model="message.display"
    >
      <v-alert
        aut-message
        v-model="message.display"
        dismissible
        :type="message.type"
        border="left"
        elevation="24"
      >
        <div v-html="message.content"></div>
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import { ADD_MESSAGE } from "@/constants";
const debug = require("debug")("atman.components.message");
export default {
  name: "Message",
  data() {
    return {
      messages: [],
    };
  },
  mounted: async function () {
    debug("In mounted");
    this.registerEvents();
  },
  methods: {
    showMessage(payload) {
      this.messages.push(Object.assign(payload, { display: true }));
    },
    registerEvents() {
      const component = this;
      const unsubscribe = component.$store.subscribe((mutation) => {
        if (mutation.type == ADD_MESSAGE) {
          component.showMessage(mutation.payload);
        }
      });
      component._subscribe(unsubscribe);
    },
  },
};
</script>
<style lang="css">
.v-snack__wrapper.v-sheet.theme--dark {
  background-color: transparent !important;
  border: 0px;
  box-shadow: none !important;
}
</style>
