<template>
  <div style="display: inline-block">
    <div
      class="behaviour_display_value"
      v-if="raw_html"
      aut-field-value
      v-html="displayValue"
    ></div>
    <div v-else>
      <span
        class="behaviour_display_value"
        aut-field-value
        :class="valueClasses"
      >
        {{ displayValue }}
      </span>
      <v-icon
        aut-masked-display-toggle
        class="ml-1"
        v-if="!!iconToAppend"
        @click="showContent = !showContent"
        >{{ iconToAppend }}</v-icon
      >
    </div>
  </div>
</template>
<script>
const debug = require("debug")("atman.field_display_value"); // eslint-disable-line
export default {
  name: "FieldDisplayValue",
  data() {
    return {
      showContent: false,
      classes: "",
    };
  },
  props: {
    raw_html: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    value: {},
    definition: {
      type: Object,
    },
  },
  computed: {
    displayAttributes() {
      return this.definition?.display?.attributes?.clamped;
    },
    isEllipsis() {
      return this.definition?.display?.attributes?.long_text == "ellipsis";
    },
    effectiveValue() {
      const noValueText =
        this.definition?.display?.attributes?.no_value?.text ||
        this.definition?.display?.attributes?.no_value_text ||
        "";
      return this.value ? this.value : noValueText;
    },
    displayValue() {
      let result;
      if (!this.definition.masked || this.showContent) {
        result = this.effectiveValue;
      } else {
        result = "************";
      }
      return result;
    },
    iconToAppend() {
      if (!this.definition.masked) {
        return null;
      }
      return !this.showContent ? "mdi-eye" : "mdi-eye-off";
    },
    colorClass() {
      const color =
        this.definition?.display?.attributes?.value_color?.[this.displayValue];
      return color ? `${color}--text` : "";
    },
    valueClasses() {
      const classes = [];
      if (this.isEllipsis) {
        classes.push("behavior_single_line");
      }
      classes.push(this.colorClass);
      return classes;
    },
  },
};
</script>
