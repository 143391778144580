<template>
  <InputText
    aut-check-other
    v-if="propsData"
    v-bind="propsData"
    v-on="$listeners"
    @change="updateOtherValue"
  />
</template>
<script>
import { clone } from "@/util";
export default {
  name: "OtherOption",
  components: {
    InputText: () => import("@/components/fields/InputText/InputText"),
  },
  data() {
    return {
      otherText: "",
      propsData: null,
    };
  },
  props: {
    checked: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    fieldValue: {},
    seedData: {
      type: Array,
    },
  },
  watch: {
    fieldValue() {
      this.getOtherValue();
    },
    seedData() {
      this.getOtherValue();
    },
    text() {
      this.otherText = this.text && this.text != "_other_" ? this.text : "";
      this.derivePropsData();
    },
    checked() {
      this.derivePropsData();
    },
  },
  mounted() {
    this.getOtherValue();
    this.derivePropsData();
  },
  methods: {
    getOtherValue() {
      const seedIds = (this.seedData || []).map(({ id }) => id);
      const fieldValue = this.fieldValue || [];
      if (fieldValue.includes(this.text) || !seedIds.length) {
        return;
      }
      const newOtherText = fieldValue.find(
        (valueId) => !seedIds.includes(valueId)
      );
      if (newOtherText && newOtherText != "_other_") {
        this.$emit("update:text", newOtherText);
      }
    },
    updateOtherValue(newText) {
      if (!newText.trim()) {
        return;
      }
      this.$emit("update:text", newText);
      const index = (this.fieldValue || []).findIndex(
        (value) => value == this.text
      );

      if (index != -1) {
        const fieldValue = clone(this.fieldValue);
        fieldValue.splice(index, 1, newText);
        this.$emit("update:value", fieldValue);
      }
    },
    derivePropsData() {
      const propsData = {
        value: {
          display: {
            attributes: {},
          },
        },
      };

      propsData.value.display.attributes = {
        disabled: !this.checked,
      };

      // The name and path should not be that of the existing field or it will get updated
      propsData.value.name = `_other`;
      propsData.value.label = `Other`;
      propsData.value.path = `${propsData?.value?.path || "innerText"}_other`;
      // Remove any configured rules so that they don't again get executed
      delete propsData.value.conditions;

      propsData.value.value = this.otherText;
      propsData.value.type = "text";
      if (this.propsData) {
        Object.assign(this.propsData, propsData);
      } else {
        this.propsData = propsData;
      }
    },
  },
};
</script>
