<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" class="d-flex">
        <v-spacer />
        <v-form v-model="valid" ref="form">
          <v-card>
            <v-card-title>
              <span class="headline">{{ cardLabel }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-password
                      ref="password"
                      name="password"
                      autocomplete="current-password"
                      v-model="password"
                      :rules="passwordRules"
                      label="Password*"
                      required
                      @change="validate"
                      :type="showContent ? 'text' : 'password'"
                      :append-icon="showContent ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showContent = !showContent"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-confirm-password
                      ref="confirmPassword"
                      name="password"
                      autocomplete="current-password"
                      v-model="confirmPassword"
                      :rules="confirmPasswordRules"
                      label="Confirm Password*"
                      required
                      :type="showContent2 ? 'text' : 'password'"
                      :append-icon="showContent2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showContent2 = !showContent2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                aut-action-set-password-cancel
                @click="cancelPasswordReset"
              >
                Cancel
              </v-btn>
              <v-btn
                aut-action-set-password-submit
                color="primary"
                :disabled="!valid"
                :loading="showLoader"
                @click="updatePassword"
              >
                {{ cardLabel }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-spacer />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { navigateTo, rules } from "@/util.js";
const debug = require("debug")("atman.components.set_password");
debug("set_password");
export default {
  name: "SetPassword",
  data() {
    const component = this;
    return {
      showContent: false,
      showContent2: false,
      showLoader: false,
      valid: false,
      cardLabel: "Set Password",
      passwordRules: [rules.mandatory()],
      confirmPasswordRules: [
        rules.mandatory(),
        (val) => val == component.password || "Mismatch between passwords",
      ],
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapActions("user", ["setPassword"]),
    validate() {
      this.$refs.form.validate();
    },
    cancelPasswordReset() {
      this.$refs.form.reset();
      navigateTo(this.$router, "/login");
    },
    async updatePassword() {
      const component = this;
      const token = component?.$route?.query?.token;
      try {
        component.showLoader = true;
        const credentials = {
          password: component.password,
        };
        if (component.$route?.meta?.unauthenticated) {
          credentials.token = token;
        }
        await component.setPassword(credentials);
        component.displaySuccessMessage("Password set successfully");
        component.$refs.form.reset();
        if (token) {
          navigateTo(component.$router, "/login");
        }
      } catch (e) {
        console.error(e);
        component.displayErrorMessage("Password set unsuccessful");
      } finally {
        component.showLoader = false;
      }
    },
  },
};
</script>
