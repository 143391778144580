import {
  no_value_text,
  no_value_behavior,
} from "@/components/fields/attributes";
export default {
  label: "Landline Number",
  type: "landline_number",
  description: "Displays landline number",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "options",
  ],
  templates: [
    {
      id: "default",
      description: "The default Landline Number field",
      label: "Default",
      variables: {
        data: {
          default: {
            extension: "01",
            std_code: "080",
            country_code: "91",
            landline: "543210",
          },
        },
      },
      value: {
        label: "Landline Number",
        type: "landline_number",
        name: "default",
      },
    },
    {
      id: "full_number",
      description: "Landline Number as single string",
      label: "Full Number",
      variables: {
        data: {
          full_number: "0108091543210",
        },
      },
      value: {
        label: "Landline Number",
        type: "landline_number",
        name: "full_number",
      },
    },
    {
      id: "landline_from_field",
      description: "Landline Number from different fields",
      label: "From Fields",
      variables: {
        data: {
          landline_from_field: null,
          ext: "01",
          std_no: "080",
          country_no: "91",
          landline_no: "543210",
        },
      },
      value: {
        label: "Landline Number",
        type: "landline_number",
        name: "landline_from_field",

        landline_fields: {
          landline: "landline_no",
          country_code: "country_no",
          std_code: "std_no",
          extension: "ext",
        },
      },
    },
    {
      id: "display_no",
      description: "Display mode of Landline Number field",
      label: "Display",
      variables: {
        data: {
          display_no: null,
          ext: "01",
          std_no: "080",
          country_no: "91",
          landline_no: "543210",
        },
      },
      value: {
        label: "Landline Number",
        type: "landline_number",
        name: "display_no",
        mode: "display",

        landline_fields: {
          landline: "landline_no",
          country_code: "country_no",
          std_code: "std_no",
          extension: "ext",
        },
      },
    },
    {
      id: "nested_value",
      description: "Number from nested fields",
      label: "Nested Value",
      variables: {
        data: {
          country_code: "456",
          number: "00000",
          detail: {
            code: "123",
            number: "43210",
          },
        },
      },
      value: {
        label: "Landline Number",
        type: "landline_number",
        name: "nested_value",

        landline_fields: {
          landline: "detail.number",
          std_code: "detail.code",
          country_code: "country_code",
        },
      },
    },
  ],
  attributes: [no_value_text, no_value_behavior],
};
