<template>
  <div class="mb-2" v-if="!isIgnored">
    <label v-if="value.label">{{ value.label }}: </label>
    <span class="pl-2">
      <v-progress-circular
        v-if="!entity"
        indeterminate
        color="primary"
        :size="25"
        :width="3"
      ></v-progress-circular>
      <a v-if="hasURL" href="#" @click.stop.prevent="showEntity">{{
        displayValue
      }}</a>
      <span v-else-if="!isField">{{ displayValue }}</span>
      <Field
        aut-entity-field
        v-else-if="isField && entityFieldDefinition"
        :definition="entityFieldDefinition"
        :context="context"
      />
    </span>
  </div>
</template>

<script>
import { defaultsDeep } from "lodash";
import { createContext, makeServerCall, replacePlaceholders } from "@/util";
import { fieldMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.entity_card"); // eslint-disable-line

export default {
  name: "EntityField",
  mixins: [fieldMixin],
  data() {
    return {
      entity: null,
    };
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  mounted() {
    this.getEntityDetails();
  },
  computed: {
    entityFieldDefinition() {
      if (!this.value?.entity_field || !this.entity) {
        return null;
      }
      return defaultsDeep(
        {
          value: this.entity[this.value.entity_field.name],
        },
        this.value?.entity_field
      );
    },
    isField() {
      return !!this.value?.entity_field;
    },
    hasURL() {
      return !!this.value?.action?.url;
    },
    displayValue() {
      if (!this.entity) {
        return "";
      }
      if (!this?.value?.dynamic_label && this?.entity?.name) {
        return this.entity.name;
      }
      const result = replacePlaceholders(
        this?.value?.dynamic_label,
        this.entity
      );
      return result;
    },
  },
  methods: {
    async getEntityDetails() {
      const component = this;
      const context = createContext(
        component.value.context || component.value.page
      );
      let contextURL;
      try {
        contextURL = await this.$store.dispatch(`${this.context}/deriveURL`, {
          url: context.path,
          customFunctions: this.replaceIndex(),
        });
      } catch (e) {
        debug(`Could not resolve variable [${context.path}]`, e);
      }
      if (!contextURL) {
        return;
      }
      try {
        const response = await makeServerCall({
          url: contextURL,
          type: "get",
        });
        component.entity = response.data;
      } catch (e) {
        debug(`Could not get entity details`, e);
      }
    },
    showEntity() {
      this.$emit("trigger-action", {
        definition: { value: this.value.action },
      });
    },
  },
};
</script>
