<template>
  <div aut-editable-field>
    <div v-if="design">
      <!-- Adding this solely for testing so we can focus on it -->
      <button aut-editor-focus hidden v-if="design" />
      <div v-if="editToggle" class="designMode d-flex">
        <slot></slot>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              aut-editable-field-edit
              @click="toggleTextField"
              class="designAction grey--text mt-n3 ml-1"
              >mdi-pencil</v-icon
            >
          </template>
          {{ definition.label }}
        </v-tooltip>
      </div>
      <div v-else class="d-flex">
        <v-text-field
          ref="textField"
          aut-editable-field-input
          v-model="value"
          :label="definition.label"
          clearable
          @blur="updateValue"
          v-on:keydown.enter.stop.prevent="updateValue"
          v-on:keydown.escape.stop.prevent="toggleTextField"
          :hint="`${definition.hint}. Press Enter when done`"
        ></v-text-field>
      </div>
    </div>
    <slot v-else></slot>
  </div>
</template>
<script>
const debug = require("debug")("atman.components.editable_field");
debug("editable_field");
export default {
  name: "EditableField",
  props: {
    text: {
      type: String,
    },
    design: {
      type: Boolean,
    },
    definition: {
      type: Object,
    },
  },
  data() {
    return {
      value: this.text,
      editToggle: true,
    };
  },
  mounted() {
    debug("in editable field", this.text);
  },
  methods: {
    toggleTextField() {
      this.editToggle = !this.editToggle;
      this.$nextTick(() => {
        if (this.$refs.textField) {
          this.$refs.textField.focus();
        }
      });
    },
    updateValue() {
      this.editToggle = !this.editToggle;
      this.$emit("update:text", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.designMode {
  min-width: 50px;
  min-height: 40px;
  > .designAction {
    display: none !important;
  }
  &:hover > {
    .designAction {
      display: inherit !important;
      position: relative;
    }
  }
  .designActions {
    z-index: 100;
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
</style>
