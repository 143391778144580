<template>
  <v-card aut-address v-bind="addressAttributes" :class="addressClasses">
    <AddressDisplayValue
      :attributes="displayAttributes"
      :label="label"
      :definition="value"
      :labelAttributes="labelAttributes"
      :value="effectiveValue"
      :key="refreshKey"
      v-if="isDisplayMode"
    />
    <div v-else>
      <v-row dense v-if="showLabel" aut-address-label>
        <v-col>
          <FieldLabel
            :definition="value"
            mode="display"
            :label="label"
            :attributes="labelAttributes"
          >
          </FieldLabel>
        </v-col>
      </v-row>
      <v-row dense>
        <AddressLine
          :key="`AddressLine1-${refreshKey}`"
          lineID="address_line_1"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressLine
          :key="`AddressLine2-${refreshKey}`"
          lineID="address_line_2"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressCity
          :key="`AddressCity-${refreshKey}`"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressDistrict
          :key="`AddressDistrict-${refreshKey}`"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressPincode
          :key="`AddressPincode-${refreshKey}`"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressState
          :key="`AddressState-${refreshKey}`"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
        <AddressCountry
          :key="`AddressCountry-${refreshKey}`"
          :context="context"
          :value="effectiveValue"
          :definition="value"
          @update="updateAddress"
        />
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { isChildOf } from "@/util.js";
import { hasFields } from "./definition";
import definition from "./definition";
import { fieldMixin } from "@/components/mixin.js";
import { mapValues, defaultsDeep, forOwn } from "lodash";
import { STORE_CONSTS } from "@/constants";
import AddressDistrict from "./AddressDistrict.vue";
import AddressCity from "./AddressCity.vue";
import AddressLine from "./AddressLine.vue";
import AddressState from "./AddressState.vue";
import AddressPincode from "./AddressPincode.vue";
import AddressCountry from "./AddressCountry.vue";
import AddressDisplayValue from "./AddressDisplayValue.vue";

const debug = require("debug")("atman.components.fields.address"); // eslint-disable-line

export default {
  name: "AddressField",
  mixins: [fieldMixin],
  components: {
    AddressLine,
    AddressDisplayValue,
    AddressCity,
    AddressDistrict,
    AddressPincode,
    AddressState,
    AddressCountry,
  },
  data() {
    return {
      definition,
      showLabel: true,
      refreshKey: 1,
      effectiveValue: {
        address_line_1: "",
        address_line_2: "",
        city: "",
        pin_code: "",
        district: "",
        state: "",
        country: "",
      },
    };
  },
  computed: {
    addressClasses() {
      let classes = "behavior_address";
      if (!this.addressAttributes?.background_color) {
        classes += " transparent";
      }
      return classes;
    },
    addressAttributes() {
      let result = defaultsDeep(
        {
          color: this.allAttributes?.background_color,
        },
        this.allAttributes || {}
      );
      return result;
    },
    hasFields() {
      return hasFields(this.value);
    },
    isMandatory() {
      return this.value?.mandatory == true;
    },
  },
  mounted() {
    this.deriveEffectiveValue();
    const isInsideSearch =
      this.$el && isChildOf(this.$el, ".behaviour_search_helper");
    debug(`showLabel : ${!isInsideSearch}`);
    this.showLabel = !isInsideSearch;
  },
  methods: {
    _afterFieldValueUpdate() {
      this.deriveEffectiveValue();
    },
    async getDataValue(dataPath) {
      const component = this;
      if (typeof this.index == "number" && this.index > -1) {
        dataPath = `${this.index}.${dataPath}`;
      }
      const value = await component.$store.dispatch(
        `${component.context}/getFieldValue`,
        { path: dataPath }
      );
      debug(`dataPath: [${dataPath}]. value: [${value}]`);
      return value;
    },
    setDataValue(dataPath, newValue) {
      const component = this;
      const mutation = `${component.context}/${STORE_CONSTS.FIELD}`;
      debug(`setDataValue. Updating [${dataPath}] as [${newValue}]`);
      component.$store.commit(mutation, {
        path: dataPath,
        value: newValue,
      });
    },
    updateAddress(input) {
      debug(`in updateAddress `, input);
      const component = this;
      forOwn(input, (value, key) => {
        component.$set(this.effectiveValue, key, value);
        this.saveAddress(key);
      });
    },

    saveAddress(key) {
      if (!this.hasFields) {
        this.fieldValue = Object.assign({}, this.effectiveValue);
        return;
      }

      const fields = this.value?.address_fields || {};

      let path;
      if (typeof fields[key] == "string") {
        path = fields[key];
      } else if (fields[key]?.name) {
        path = fields[key]?.name;
      } else {
        path = `${this.path}.${key}`;
      }
      this.setDataValue(path, this.effectiveValue[key]);
    },

    async deriveEffectiveValue() {
      const component = this;
      if (!component.hasFields) {
        component.effectiveValue = Object.assign(
          {},
          component.fieldValue || {}
        );
        return;
      }

      const fields = component.value?.address_fields || {};

      const fieldNames = mapValues(component.effectiveValue, (value, key) => {
        const field = fields[key] || "";
        return typeof field == "string" ? field : field?.name || "";
      });

      let result = {};
      forOwn(fieldNames, async (value, key) => {
        let path = key;
        if (fields[key]?.name) {
          path = fields[key]?.name;
        }
        let effectiveValue = fields?.[key]?.value || "";
        result[key] = effectiveValue;
        debug("setting value 1", key, effectiveValue);
        component.$set(component.effectiveValue, key, effectiveValue);
        if (value) {
          try {
            debug(`Getting data value`, value);
            const dataValue = await component.getDataValue(value);

            if (dataValue) {
              result[key] = dataValue;
              debug("setting value 2", key, dataValue);
              component.$set(component.effectiveValue, key, dataValue);
            } else {
              debug(`No data value found for `, value);
            }
          } catch (e) {
            debug(
              `Exception occurred when attempting to fetch data value for `,
              value,
              "error",
              e
            );
          }
        } else {
          debug(`no value for ${key}`);
          const valueInDefinition = component.value?.value?.[key];
          if (valueInDefinition) {
            result[key] = valueInDefinition;
            debug("setting value 3", key, valueInDefinition);
            component.$set(component.effectiveValue, key, valueInDefinition);
          }
        }
        component.setDataValue(path, result[key]);
      });
      debug(`After determining effective value`, component.ef);
      component.refreshKey++;
    },
  },
};
</script>
<style lang="scss">
.behaviour_search_helper {
  .behavior_address {
    /* Hide messages when in search mode */
    .v-text-field__details {
      display: none !important;
    }
  }
}
</style>
