<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <v-row dense no-gutters v-if="fieldValue">
      <v-col
        cols="12"
        class="py-0 my-0"
        v-for="(item, i) in value.items"
        :key="i"
      >
        <v-checkbox
          dense
          aut-checklist-item
          :rules="rules"
          v-model="fieldValue[item.id]"
          v-bind="displayAttributes"
          hide-details
          @change="$emit('change', fieldValue)"
        >
          <template v-slot:label>
            <v-tooltip bottom v-if="item.hint">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"> {{ item.label }}</span>
              </template>
              <span>{{ item.hint }}</span>
            </v-tooltip>
            <span v-else>{{ item.label }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fieldMixin } from "@/components/mixin.js";
import definition from "./definition";
const debug = require("debug")("atman.components.checklist_field"); // eslint-disable-line
export default {
  name: "ChecklistField",
  data() {
    return {
      rules: [
        (val) => {
          return val || "";
        },
      ],
      definition: definition,
    };
  },
  components: {},
  mixins: [fieldMixin],
  mounted() {
    this.initialiseField();
  },
  methods: {
    initialiseField() {
      if (this.isInputMode && !this.fieldValue) {
        this.$set(this, "fieldValue", {});
      }
    },
    _derivePropsData() {
      this.initialiseField();
    },
    _afterFieldValueUpdate() {
      this.initialiseField();
    },
  },
};
</script>
