export default {
  data() {
    return {
      propsData: null,
    };
  },
  mounted() {
    if (typeof this._derivePropsData == "function") {
      this._derivePropsData();
    }
  },
};
