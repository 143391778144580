<template>
  <v-container class="pa-0">
    <v-row align="center" :class="imageSources.length ? 'pa-3' : ''">
      <template v-if="!isExpandedView || !isDisplayMode">
        <template v-if="!tileLayout">
          <v-col cols="12" lg="2">
            <v-list-item-group v-model="activeImage">
              <v-list-item
                v-for="(source, i) in imageSources"
                :key="i"
                class="pa-1"
                :value="source"
                aut-image-list-item
              >
                <div class="behavior_image_container">
                  <v-btn
                    :aut-remove-image="i"
                    v-if="!isDisplayMode"
                    icon
                    x-small
                    color="primary"
                    class="remove_button"
                    @click.stop="removeImage(i)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                  <ImageFile :fieldValue="{ link: source }" />
                </div>
              </v-list-item>
              <v-list-item
                class="pa-1 justify-center grey lighten-3"
                @click="$refs.fileinput.click()"
                v-if="!isDisplayMode"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-list-item>
            </v-list-item-group>
          </v-col>
          <v-col @click="isExpandedView = true" aut-active-image>
            <ImageFile
              :fieldValue="{
                link: activeImage || imageSources[0] || '/no_image.jpg',
              }"
              :value="imageValue"
              class="active_image"
            />
          </v-col>
        </template>
        <template v-if="tileLayout">
          <v-col
            v-for="index in imageTiles"
            :key="index"
            class="pa-1 ma-0"
            :cols="tileWidth"
            :value="imageSources[index]"
          >
            <v-img
              :src="imageSources[index]"
              :lazy-src="no_image"
              class="grey lighten-2 active_image"
              :max-height="imageHeight"
              cover
              @click="showPic(imageSources[index])"
            >
              <v-overlay
                v-if="imageSources.length > imageTiles.length"
                :value="index == 3"
                absolute
              >
                <span class="text-md-h4 text-subtitle-1">
                  {{ imageSources.length - imageTiles.length }}+ more
                </span>
              </v-overlay>
            </v-img>
          </v-col>
        </template>
      </template>
      <v-col v-else>
        <v-carousel
          hide-delimiters
          v-model="activeImage"
          aut-image-carousel
          :show-arrows="imageSources.length > 1"
        >
          <v-carousel-item
            v-for="(source, i) in imageSources"
            :key="i"
            :src="source"
            :value="source"
            :contain="true"
          >
          </v-carousel-item>
          <v-btn
            icon
            class="close_icon"
            @click="isExpandedView = false"
            aut-close-carousel
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-carousel>
      </v-col>
    </v-row>
    <input
      type="file"
      ref="fileinput"
      :multiple="true"
      aut-upload-image-list
      @click.stop
      style="display: none"
      @change="uploadFiles"
    />
  </v-container>
</template>
<script>
import { defaultsDeep, isPlainObject, range } from "lodash";
import { fieldMixin } from "@/components/mixin.js";
import ImageFile from "@/components/fields/FileField/EmbeddedFile/ImageFile.vue";
import { mapState } from "vuex";
import definition from "./definition";

const debug = require("debug")("atman.field.imagelist"); // eslint-disable-line
export default {
  name: "ImageListField",
  mixins: [fieldMixin],
  components: {
    ImageFile,
  },
  data() {
    const filesValue = {
      display: {
        width: "12",
        attributes: {
          multiple: true,
        },
      },
    };
    return {
      definition,
      activeImage: "",
      filesValue,
      isExpandedView: false,
      layout: "",
    };
  },
  computed: {
    ...mapState(["no_image"]),
    tileLayout() {
      const layoutName = this.displayAttributes?.layout?.name;
      return layoutName == "tile";
    },
    imageSources() {
      let files = this.fieldValue || [];

      if (isPlainObject(files)) {
        files = [files];
        debug("Array containing single value", files);
      }

      if (typeof files == "string") {
        files = [
          {
            _file: files,
          },
        ];
      }

      if (!Array.isArray(files)) {
        return [];
      }

      const imageList = Array.from(files).map((imageFile) => {
        if (imageFile instanceof File) {
          return this.getURL(imageFile);
        }

        if (typeof imageFile?._file == "string") {
          return imageFile._file;
        }

        return "";
      });

      return imageList;
    },
    imageValue() {
      const defaultValue = {
        display: {
          embed: {
            width: "500",
          },
        },
      };
      return defaultsDeep(
        {},
        {
          display: {
            embed: {
              width: this?.displayAttributes?.image?.width,
            },
          },
        },
        defaultValue
      );
    },
    imageTiles() {
      const imageCount = this.imageSources.length;
      const maxTiles = 4;
      const tileCount = imageCount > maxTiles ? maxTiles : imageCount;

      return range(tileCount);
    },
    tileWidth() {
      return this.imageSources.length == 1 ? "12" : "6";
    },
    imageHeight() {
      const layoutHeight = this.displayAttributes?.layout?.height || "300";
      if (this.imageSources.length > 2) {
        return layoutHeight / 2;
      }
      return layoutHeight;
    },
  },
  methods: {
    showPic(src) {
      this.activeImage = src;
      this.isExpandedView = true;
    },
    uploadFiles(e) {
      const files = e?.target?.files || [];

      if (!files.length) {
        return;
      }

      const results = Array.from(files);

      if (!this.fieldValue) {
        this.fieldValue = results;
        return;
      }

      if (Array.isArray(this.fieldValue)) {
        this.fieldValue = [...this.fieldValue, ...results];
        return;
      }

      this.fieldValue = [this.fieldValue, ...results];
    },
    getURL(file) {
      try {
        const url = URL.createObjectURL(file);
        URL.revokeObjectURL(file);
        return url;
      } catch (e) {
        debug(`Failed to preview file`, e, file);
        return "";
      }
    },
    removeImage(index) {
      this.fieldValue.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.behavior_image_container {
  overflow: hidden;
}
.close_icon {
  position: absolute;
  right: 0.2em;
  background: rgba(0, 0, 0, 0.267);
}
.active_image:hover {
  cursor: pointer;
}
.remove_button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;
}
</style>
