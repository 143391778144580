<template>
  <v-dialog v-model="showDialog" width="500">
    <v-card class="pa-2" aut-dropdown-detail>
      <DialogTitle @close="showDialog = false" title="Detailed View" />
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
          aut-dropdown-search
        ></v-text-field>
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="items"
          :single-select="!multiple"
          item-key="id"
          show-select
          :items-per-page="5"
          :search="search"
          aut-dropdown-table
        ></v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" @click="selectItems" aut-dropdown-ok>Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
export default {
  name: "DetailedView",
  mixins: [dialogMixin],
  data() {
    return {
      headers: [{ text: "Name", value: "name" }],
      selectedItems: [],
      search: "",
    };
  },
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    selected: {
      type: [Array, String],
    },
  },
  watch: {
    selected() {
      this.loadSelectedItems();
    },
    dialog(value) {
      if (value) {
        this.loadSelectedItems();
        this.search = "";
      }
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  mounted() {
    this.loadSelectedItems();
  },
  methods: {
    loadSelectedItems() {
      if (typeof this.selected == "string") {
        const item = this.items.find(({ id }) => id == this.selected);
        this.selectedItems = item ? [item] : [];
        return;
      }

      if (this.selected?.length > 0) {
        const items = this.selected
          .map((key) => {
            return this.items.find(({ id }) => id == key);
          })
          .filter((item) => item);
        this.selectedItems = items;
        return;
      }

      this.selectedItems = [];
    },
    selectItems() {
      this.showDialog = false;
      const items = this.selectedItems;
      if (!this.multiple) {
        this.$emit("change", items[0].id);
      } else {
        this.$emit(
          "change",
          items.map(({ id }) => id)
        );
      }
    },
  },
};
</script>
