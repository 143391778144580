import { color, elevation, flat } from "@/components/fields/attributes.js";

const debug = require("debug")("atman.components.time_picker"); //eslint-disable-line

export default {
  label: "Time Picker",
  type: "time_picker",

  description: "Displays a time picker field",
  properties: [
    "name",
    "label",
    "type",
    "mandatory",
    "mode",
    "display",
    "block",
    "disabled",
    "value",
  ],
  templates: [
    {
      id: "default",
      description: "The default Time Picker field.",
      label: "Default",
      value: {
        name: "some_name",
        label: "Title",
        type: "time_picker",
      },
    },
    {
      id: "default_with_value",
      keyword: ["tutorial"],
      description:
        'Use <code>"type": "time_picker"</code> and <code>value</code> to preconfigure data. In this example, it is set to <b>10:34</b>.',
      label: "Default With Value",
      variables: {
        data: {
          some_name: "10:34",
        },
      },
      value: {
        name: "some_name",
        label: "Title",
        type: "time_picker",
      },
    },
    {
      id: "display",
      keyword: ["tutorial"],
      label: "Display",
      variables: {
        data: {
          some_name: "10:34",
        },
      },
      value: {
        name: "some_name",
        mode: "display",
        label: "Title",
        type: "time_picker",
      },
    },
  ],
  attributes: [
    {
      name: "ampm-in-title",
      hint: "Place AM/PM switch in title, not near the clock.",
      type: "switch",
      value: false,
    },
    color,
    elevation,
    flat,
    {
      name: "format",
      type: "select",
      options: [
        { id: "ampm", label: "12 hour" },
        { id: "24hr", label: "24 hour" },
      ],
    },
    {
      name: "header-color",
      type: "color",
      hint: "Control the header color",
      value: "secondary",
    },
    {
      name: "landscape",
      hint: "Orients picker horizontal",
      type: "switch",
      value: false,
    },
    {
      name: "max",
      hint: "Maximum allowed time",
      type: "text",
    },
    {
      name: "min",
      hint: "Minimum allowed time",
      type: "text",
    },
    {
      name: "no-title",
      hint: "Hide the picker title",
      type: "switch",
      value: false,
    },
    {
      name: "scrollable",
      hint: "Allows changing hour/minute with mouse scroll",
      type: "switch",
      value: false,
    },
    {
      name: "use-seconds",
      hint: "Toggles the use of seconds in picker",
      type: "switch",
      value: false,
    },
  ],
};
